import { css } from '@emotion/core'
import { colors } from '@bonitour/components'
import styled from '@emotion/styled'

export const flex = css`
  display: flex;
`

export const upperRowStyle = css`
  width: 90%;
  margin: 0 auto;
`

export const cardStyle = css`
  padding: 8px 25px;
  justify-content: space-between;
`

export const columnStyle = css`
  flex-direction: column;
  line-height: 0;
`

export const containerContent = css`
  flex-direction: row;
  margin: 0;
  padding: 0;
  margin-right: 30px;
  padding-right: 15px;
  width: fit-content;
`

export const titleContent = css`
  color: ${colors.gray2};
  font-size: 13px;
  margin: 15px 15px;
`

export const labelContent = css`
  color: ${colors.gray5};
  font-size: 13px;
  font-weight: normal;
`

export const uppercase = css`
  text-transform: uppercase;
`

export const IconStatus = styled.div`
  height: 8px;
  width: 8px;
  background-color: var(${({ statusColor }) => statusColor});
  border-radius: 4px;
  margin-right: 8px;
`

export const iconInfoStyle = css`
  width: 18px;
  height: 18px;
  cursor: pointer;
  align-self: center;
`

export const tooltipStyle = css`
  padding: 10px;
  font-size: 12px;
  word-break: break-word;
  white-space: normal;
  width: 160px;
  text-align: justify;
  line-height: 15px;
`
