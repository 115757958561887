import { parseTemplateString, pluralize } from 'Shared/language/utils/dictionaryUtils'

export const ConciliationsHooksTranslator = (
  dictionary,
  locale
) => {
  const templatePluralizeWithNumber = (qty, dictKey) => {
    const templateString = pluralize(
      dictKey,
      qty,
      locale
    )
    return parseTemplateString(templateString, {
      NUMBER: qty
    })
  }

  const automaticConciliation = (qty) => {
    return templatePluralizeWithNumber(qty, dictionary?.conciliation_automaticConciliationCreate_label)
  }

  const manualConciliation = dictionary?.conciliation_manualConciliationCreate_label

  const conciliationCreateAutomicError = dictionary?.conciliation_errorCreatingAutomaticConciliation_toast
  const conciliationCreateManualError = dictionary?.conciliation_errorCreatingManualConciliation_toast
  const deletedConciliation = (qty) => {
    return templatePluralizeWithNumber(qty, dictionary?.conciliation_deletedConciliation_label)
  }
  const deleteConciliationsError = dictionary?.conciliation_deleteConciliations_error
  const errorLoadingConciliations = dictionary?.conciliation_errorLoadingConciliations_toast

  const apiCodesMappingForBankConciliation = {
    'conciliation::conciliation_item::cannot_conciliate_canceled_account_movements': dictionary?.conciliation_reconcilingCanceledSystemPostingsIsNotPermitted_label
  }

  const apiCodesMappingForCreditCardConciliation = {
    'creditcard_conciliation::conciliation_item::cannot_conciliate_canceled_account_forecasts': dictionary?.conciliation_reconcilingCanceledSystemPostingsIsNotPermitted_label
  }

  return {
    automaticConciliation,
    manualConciliation,
    conciliationCreateAutomicError,
    conciliationCreateManualError,
    deletedConciliation,
    deleteConciliationsError,
    errorLoadingConciliations,
    apiCodesMappingForBankConciliation,
    apiCodesMappingForCreditCardConciliation
  }
}
