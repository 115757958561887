import { useMemo } from 'react'
import { useForm } from '@bonitour/app-functions'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { useBankAccountsOptions } from 'Domains/BankAction/BankAccounts/hooks/useBankAccountsOptions'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'
import { destinationBankAccount, endDate, getSchema, startDate, status } from '../../Form/__CashDepositBaseInputs'
import { CashDepositsCommonParams } from 'Core/Types/CashDeposit'
import { CashDepositsInputsTranslator } from '../../i18n/CashDepositsInputsI18n'

export const useCashDepositFilters = (
  filters: CashDepositsCommonParams
) => {
  const {
    bankAccountsOptionsWithAll
  } = useBankAccountsOptions()

  const inputsVals = useTranslatedInputs([
    startDate,
    endDate,
    status,
    destinationBankAccount(bankAccountsOptionsWithAll),
    getSchema
  ], CashDepositsInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsVals),
    [inputsVals]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(filters, schema)

  const formErrorsTranslteds = useTranslatedFormErrors(
    errors, CashDepositsInputsTranslator
  )

  return {
    errors: formErrorsTranslteds,
    form,
    onInputChange,
    onInputBlur,
    inputsValid: inputsVals,
    onSubmit
  }
}
