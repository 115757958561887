import React from 'react'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useGetConfiguration } from './hooks/useGetConfiguration'
import { ConfigurationForm } from './Form/ConfigurationForm'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConfigurationTranslator } from './i18n/ConfigurationI18n'

export const Configuration = (): React.Component => {
  const i18n = useTranslator(ConfigurationTranslator)

  const {
    getConfigurationData,
    getConfiguration
  } = useGetConfiguration()

  return (
    <>
      <PageTitle
        title={i18n.pageTitleConfiguration}
      />
      <ConfigurationForm
        getConfiguration={getConfiguration}
        configurationData={getConfigurationData}
      />
    </>
  )
}
