import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const listContainer = css`

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  * {
    box-sizing: border-box;
  }

  .section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.5rem 0 1.25rem;

    .button__delete {
      display: flex;
      align-items: center;
      padding: 0;

      .button__text {
        display: none;
        color: ${colors.gray3};

      }

      &:hover {
        transition: 1s color;
        .button__text {
          display: flex;
        }

        .button__icon {
          i {
            margin-right: 0.625rem;
            color: ${colors.gray3};
          }
        }
      }
    }

    h3 {
      font-size: 0.875rem;
      font-weight: 700;
      color: ${colors.gray3};
    }

    i {
      color: ${colors.gray7};
      font-size: 1.375rem;
    }
  }

  .message__select_period {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 8.75rem;
    font-size: 0.875rem;
    color: ${colors.gray3};
  }

  .value_color {
    font-weight: 700;
  }

  .value_color__negative {
    color: var(--clr-red2);
  }

  .value_color__positive {
    color: var(--clr-green2);
  }

  .transaction_description__column {
    width: 35rem;
    max-width: none;
  }

  .document_number__column {
    width: 7rem;
    max-width: none;
  }
`
