import React, { useCallback, useMemo, useState } from 'react'
import {
  AuditContainer as Container,
  AuditDetailsWrapper,
  AuditDetailsContainer,
  customModal
} from './Audit.styles'
import {
  AuditIcon,
  LoadingAnimation,
  Modal
} from '@bonitour/components'
import { identity } from 'Shared/utils/functions'
import { loadingAnimation } from 'Shared/styles/global'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { useTranslator } from 'Shared/language/i18nHandler'
import { AuditsTranslator } from './i18n/AuditsI18n'

/**
 * @typedef AuditProps
 * @prop { string } documentId
 * @prop { { key: string, value: SafeDate | string | number }[][] } auditDatas
 * @prop { (id: string) => void } fetchAuditDatas
 * @prop { boolean } auditLoading
 * @prop { string } modalTitle
 * @prop { Record<string, string> } [i18n]
 * @prop { { class: string, label: string} } [auditButton]
 */

/** @type { React.FC<AuditProps> } */
export const Audit = ({
  documentId = '',
  auditDatas = [],
  fetchAuditDatas = identity,
  auditLoading = false,
  modalTitle,
  params = {},
  i18n,
  auditButton = {}
}) => {
  const i18nAudit = useTranslator(AuditsTranslator)
  const [isOpen, setIsOpen] = useState(false)
  const mergedAuditLabels = useMemo(() => {
    return {
      ...i18n,
      ...i18nAudit?.auditLabels
    }
  }, [i18n, i18nAudit?.auditLabels])

  const toggleModal = useCallback(() => setIsOpen(value => !value), [])

  const handleFetchDatas = useCallback(() => {
    fetchAuditDatas(documentId, params)
    toggleModal()
  }, [documentId, fetchAuditDatas, toggleModal, params])

  return (
    <Container>
      <button className={auditButton?.class} onClick={handleFetchDatas}>
        {auditButton?.label}
        <AuditIcon />
      </button>
      <Modal
        onCloseClick={toggleModal}
        isVisible={isOpen}
        title={modalTitle || i18nAudit?.defaultModalTitle}
        maxHeight='95vh'
        customCss={[customModal]}
      >
        <AuditDetailsContainer>
          {auditLoading ? <LoadingAnimation customCss={[loadingAnimation]} size={40}/>
            : auditDatas?.length ? (
              auditDatas.map((data, index) => (
                <AuditDetailsWrapper key={index}>
                  {data.map(({ key, value }) => (
                    <div className={`audit_details__${key} audit_details__content`} key={key}>
                      <p className={`item__${key} item__label`}>
                        {mergedAuditLabels[key] || key}:
                      </p>
                      <SafeOverflow
                        text={i18nAudit?.auditDictValues(value)}
                        className={`item__${key} custom__safe_overflow`}
                        alignment='left'
                      />
                    </div>
                  ))}
                </AuditDetailsWrapper>
              ))) : (
              <p className='audit_deatils__message_empty'>{i18nAudit?.emptyAuditData}</p>
            )
          }
        </AuditDetailsContainer>
      </Modal>
    </Container>
  )
}
