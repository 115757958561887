import { useCallback, useState } from 'react'
import { EventService } from 'Core/Service/event'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsHooksTranslator } from '../i18n/EventsHooksI18n'

export const useGetEventRequestDetails = () => {
  const [loading, setLoading] = useState(false)
  const [eventRequestDetails, setEventRequestDetails] = useState()

  const { addToast } = useToast()

  const i18n = useTranslator(EventsHooksTranslator)

  const fetchEventRequestDetails = useCallback((eventId) => {
    setLoading(true)
    EventService.getEventRequestDetails(eventId).then(
      (eventData) => {
        setEventRequestDetails(eventData)
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorFechtingEventRequestDetails
      })
    }).finally(
      () => setLoading(false)
    )
  }, [
    addToast,
    i18n?.errorFechtingEventRequestDetails
  ])

  return {
    loading,
    eventRequestDetails,
    fetchEventRequestDetails
  }
}
