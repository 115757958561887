import { useCallback, useEffect, useState } from 'react'
import { EventsProcessorsService } from 'Core/Service/eventsProcessors'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'

export const useEventProcessorUpdate = (eventProcessorData) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)

  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const updateEventProcessor = useCallback((payload, eventProcessorId) => {
    setLoading(true)
    return EventsProcessorsService.updateEventProcessor(payload, eventProcessorId)
      .then((data) => {
        addToast({
          typeToast: 'success',
          defaultMessage: i18n?.eventProcessorUpdatedSuccess
        })
        return data
      }).catch((errors) => {
        console.error(errors?.data)
        addToast({
          dictTranslation: i18n?.apiCodesMappingsForEventsProcessors,
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorUpdatingEventProcessor
        })
        throw errors
      })
      .finally(() => setLoading(false))
  }, [
    addToast,
    i18n?.errorUpdatingEventProcessor,
    i18n?.eventProcessorUpdatedSuccess,
    i18n?.apiCodesMappingsForEventsProcessors
  ])

  const handleUpdateStatus = useCallback((payload, eventProcessorId) => {
    setStatus(!status)
    updateEventProcessor({ ...payload, status: !status }, eventProcessorId).catch(
      () => setStatus(status)
    )
  }, [status, updateEventProcessor])

  useEffect(() => {
    if (eventProcessorData?.status === 'enabled') {
      setStatus(true)
    }
  }, [eventProcessorData?.status])

  return {
    updateEventProcessor,
    loading,
    status,
    handleUpdateStatus
  }
}
