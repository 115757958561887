import { useCallback, useState } from 'react'
import { ExpenseService } from 'Core/Service/expense'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesHooksTranslator } from '../i18n/ExpensesHooksI18n'
import { BackendErrorFormat } from 'Core/Types/Error'

export const useGetExpenseAudits = () => {
  const [datas, setDatas] = useState([])
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(ExpensesHooksTranslator)

  const fetchListExpenseAudit = useCallback((expenseId: string) => {
    if (!expenseId) return
    setLoading(true)
    return ExpenseService.getExpenseAudits(expenseId)
      .then(setDatas)
      .catch((errors: BackendErrorFormat) => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorLoadingAccountsPayableHistory
          }
        )
      }).finally(() => {
        setLoading(false)
      })
  }, [
    addToast,
    i18n?.errorLoadingAccountsPayableHistory
  ])

  return {
    fetchListExpenseAudit,
    datas,
    loading
  }
}
