import { useTranslatedPaymentMethods } from 'Shared/language/hooks/useTranslatedPaymentMethods'
import { parseTemplateString } from 'Shared/language/utils/dictionaryUtils'

export const ExpensesHooksTranslator = (
  dictionary
) => {
  const accountsPayableSuccessfullyRegistered = dictionary?.expense_accountsPayableSuccessfullyRegistered_toast
  const errorRegisteringAccountsPayable = dictionary?.expense_errorRegisteringAccountsPayable_toast
  const errorWhenSearchingForAccountsPayable = dictionary?.expense_errorWhenSearchingForAccountsPayable_toast
  const accountsPayableUpdatedSuccessfully = dictionary?.expense_accountsPayableUpdatedSuccessfully_toast
  const errorUpdatingAccountsPayable = dictionary?.expense_errorUpdatingAccountsPayable_toast

  const apiCodesMappingForExpense = {
    'expense::date::only_be_payed_present': dictionary?.expense_dateOnlyByPayedPresent_toast,
    'expense::date::only_be_scheduled_future': dictionary?.expense_dateOnlyBeScheduledFuture_toast,
    'expense::status::only_with_scheduled_or_canceled_status': dictionary?.expense_onlyWithScheduledOrCanceledStatus_toast,
    'expense::ocurrences::exceeds_limit': (maxOcurrences) => {
      return parseTemplateString(
        dictionary.expense_repetitionLimitIs_label,
        {
          MAX_OCURRENCES: maxOcurrences
        }
      )
    }
  }

  const errorDeletingReceipts = dictionary?.expense_errorDeletingReceipts_toast
  const errorLoadingAvailableStatus = dictionary?.expense_errorLoadingAvailableStatus_toast
  const errorLoadingForgottenAccountsPayable = dictionary?.expense_errorLoadingForgottenAccountsPayable_toast
  const errorLoadingAccountsPayableHistory = dictionary?.expense_errorLoadingAccountsPayableHistory_toast
  const errorLoadingAccountsPayable = dictionary?.expense_errorLoadingAccountsPayable_toast
  const errorLoadingRecurringAccountsPayableSimulation = dictionary?.expense_errorLoadingRecurringAccountsPayableSimulation_toast

  const csvHeadersExpenses = [
    { key: 'date', label: dictionary?.common_date_label },
    { key: 'referenceDate', label: dictionary?.common_referenceDate_label },
    { key: 'serviceName', label: dictionary?.expense_productAndService_label },
    { key: 'expenseRevenue', label: dictionary?.expense_typeOfExpense_label },
    { key: 'bankAccount', label: dictionary?.common_bankAccount_label },
    { key: 'paymentMethod', label: dictionary?.expense_paymentMethod_label },
    { key: 'value', label: dictionary?.common_value_label },
    { key: 'taxes', label: dictionary?.expense_bankFee_label },
    { key: 'status', label: dictionary?.common_status_label },
    { key: 'supplier', label: dictionary?.expense_supplier_label },
    { key: 'observation', label: dictionary?.common_observation_label },
    { key: 'createdAt', label: dictionary?.expense_registrationDate_label }
  ]

  const dateFormat = dictionary?.common_dateFormat_template

  const { paymentMethodMapped } = useTranslatedPaymentMethods({ dictionary })

  const fileNameExpense = (startDate, endDate) => parseTemplateString(
    dictionary.expense_fileNameExpense_label,
    {
      START_DATE: startDate,
      END_DATE: endDate
    }
  )

  return {
    accountsPayableSuccessfullyRegistered,
    errorRegisteringAccountsPayable,
    errorWhenSearchingForAccountsPayable,
    accountsPayableUpdatedSuccessfully,
    errorUpdatingAccountsPayable,
    apiCodesMappingForExpense,
    errorDeletingReceipts,
    errorLoadingAvailableStatus,
    errorLoadingForgottenAccountsPayable,
    errorLoadingAccountsPayableHistory,
    errorLoadingAccountsPayable,
    errorLoadingRecurringAccountsPayableSimulation,
    csvHeadersExpenses,
    dateFormat,
    paymentMethodMapped,
    fileNameExpense
  }
}
