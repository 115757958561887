import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const wrapperName = css`
  .event_processor_safe_overflow {
    max-width: 30rem;
  }
`

export const ConditionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  position: relative;

  .circle_conditions {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100vw;
    height: 1.25rem;
    width: 1.25rem;
    color: var(--clr-gray3);
    border: 1px solid  var(--clr-gray6);
  }
`

export const ConditionsDetailsWrapper = styled.div`
  .content_conditions_details {
    display: flex;
    justify-content: center;
    gap: 0.625rem;
    box-shadow: 0px 0px 0.375rem #0000001a;
    padding: 0.625rem;
    margin-bottom: 0.625rem;
    border-radius: 0.3125rem;
  }
`
