import axios from 'axios'
import { AppStore } from 'Core/Store/App'
import { UserStore } from 'Core/Store/User'
import { identity } from '@bonitour/common-functions'

export const OrbService = axios.create({
  baseURL: process.env.REACT_APP_ORB_API,
  responseType: 'json'
})

OrbService.interceptors.request.use(
  config => {
    if (UserStore.authKey && !config.headers.Authorization) {
      config.headers.Authorization = UserStore.authKey
    }

    return config
  },
  error => Promise.reject(error)
)

OrbService.interceptors.response.use(identity, error => {
  if (error.response && error.response.status === 401) {
    AppStore.clean()
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }
  return Promise.reject(error)
})

export const extractData = ({ data }) => data
