
export const es_ES = {
  common_dateFormat_template: 'DD/MM/YYYY',
  common_flagVisa_label: 'Visa',
  common_flagAmericanExpress_label: 'American express',
  common_flagElo_label: 'Elo',
  common_flagHipercard_label: 'Hipercard',
  common_flagMastercard_label: 'Mastercard',
  common_flagDiners_label: 'Diners',
  common_flagDiscover_label: 'Discover',
  common_credit_label: 'Crédito',
  common_debit_label: 'Débito',
  common_endDatemustBeGreaterOrEqualStartDate_toast: 'La fecha de finalización debe ser superior o igual a la fecha de inicio',
  common_startDateMustBeLessThanOrEqualEndDate_label: 'La fecha de inicio debe ser menor o igual a la fecha de finalización',
  common_selectPeriod_toast: 'Seleccione un período',
  common_save_label: 'Guardado',
  common_cancel_label: 'Cancelar',
  common_searchButton_label: 'Buscar',
  common_all_label: 'Todos',
  common_aut_label: 'Aut',
  common_doc_label: 'Doc',
  common_yes_label: 'Sí',
  common_no_label: 'No',
  common_originTypeReservationPayment_label: 'Pago de Reserva',
  common_originTypeReservationRefund_label: 'Reembolso de Reserva',
  common_originTypeTicket_label: 'Entrada',
  common_originTypeInvoice_label: 'Factura',
  common_originTypePartnershipAccountTransactionCredit_label: 'Crédito de Asociación',
  common_originTypePartnershipAccountTransactionDebit_label: 'Débito de Asociación',
  common_originTypeCashierHandling_label: 'Entrada y Salida de Caja',
  common_originTypeCashierHandlingEntry_label: 'Entrada de Caja',
  common_originTypeCashierHandlingExit_label: 'Salida de Caja',
  common_originTypePaymentBatch_label: 'Lote de Pago Offline/Afiliados',
  common_originTypeExpense_label: 'Cuentas por Pagar',
  common_originTypeRevenue_label: 'Cuentas por Cobrar',
  common_originTypeBillingGatewayPayment_label: 'Lanzamiento en Cuentas por Cobrar',
  common_pix_label: 'Pix',
  common_bankBillet_label: 'Boleto Bancario',
  common_cash_label: 'Efectivo',
  common_bankTransfer_label: 'Transferencia Bancaria',
  common_discount_label: 'Descuento',
  common_noInformation_label: 'No hay información',
  common_brand_label: 'Marca',
  common_statusActive_label: 'Activo',
  common_statusInactive_label: 'Inactivo',
  common_afterConfirmationNoWayToUndoAction_label: 'Después de la confirmación, no habrá forma de deshacer la acción.',
  common_continue_label: 'Continuar',
  common_viewMoreDetails_label: 'Ver más detalles',
  common_back_label: 'Volver',
  common_invalid_label: 'Inválidas',
  common_valid_label: 'Válidas',
  common_finalize_label: 'Finalizar',
  common_conciliate_label: 'Conciliado',
  common_notConciliate_label: 'No Conciliado',
  common_canceledStatus_label: 'Cancelado',
  common_realizedStatus_label: 'Realizado',
  common_foreseenStatus_label: 'Previsto',
  common_withoutStatus_label: 'Sin Estado',
  common_withoutTypeOfOperation_label: 'Sin Tipo de Operación',
  common_startDate_label: 'Fecha de Inicio',
  common_endDate_label: 'Fecha de Fin',
  common_dateFormat_label: 'La fecha debe tener el formato dd/mm/yyyy',
  common_netValue_label: 'Valor Neto',
  common_description_label: 'Descripción',
  common_scheduling_label: 'Programación',
  common_cancellation_label: 'Cancelación',
  common_payment_label: 'Pago',
  common_receivement_label: 'Recepción',
  common_refunded_label: 'Reembolso',
  common_formOfPayment_label: 'Forma de Pago',
  common_reservation_label: 'Reserva',
  common_dateWithHour_template: 'DD/MM/YYYY HH:mm',
  common_braspag_label: 'Braspag',
  common_acquirer_label: 'Adquiriente',
  common_cielo_label: 'Cielo',
  common_lien_credit_label: 'Crédito de Gravamen',
  common_lien_debit_label: 'Débito de Gravamen',
  common_pos_monthly_fee_label: 'Mensualidad de POS',
  common_startDateRequired_label: 'La fecha de inicio es obligatoria',
  common_endDateRequired_label: 'La fecha final es obligatoria',
  common_status_label: 'Estado',
  common_selectStatuses_label: 'Seleccione los estados',
  common_selectAccount_label: 'Seleccione la cuenta',
  common_date_label: 'Fecha',
  common_value_label: 'Valor',
  common_user_label: 'Usuario',
  common_actions_label: 'Acciones',
  common_selectPeriodToSearch_label: 'Seleccione un período para buscar',
  common_selectStatus_label: 'Seleccione el estado',
  common_userName_label: 'Nombre de Usuario',
  common_registrationDate_label: 'Fecha de Registro',
  common_editDate_label: 'Fecha de edición',
  common_selectTheDate_label: 'Seleccione la fecha',
  common_bankFee_label: 'Tasa Bancaria',
  common_observation_label: 'Observación',
  common_enterObservation_label: 'Ingrese la observación',
  common_statusRequired_label: 'Estado obligatorio',
  common_observationRequired_label: 'Observación obligatoria',
  common_total_label: 'Total',
  common_none_label: 'Ninguno',
  common_bankAccount_label: 'Cuenta Bancaria',
  common_valueMustBeANumber_label: 'El Valor Debe Ser un Número',
  common_fillInTheValue_label: 'Rellena el Valor',
  common_register_label: 'Registrar',
  common_referenceDate_label: 'Fecha de Referencia',
  common_exit_label: 'Salir',
  common_guideBatch_label: 'Lote de Guía',
  common_itMustBeNumber_label: 'Debe ser un número',
  common_numberOfRepetitionsMustBeLessThanOrEqual240_label: 'El número de repeticiones debe ser menor o igual a 240',
  common_numberOfRepetitionsMustBeGreaterThanOrEqualTo12_label: 'El número de repeticiones debe ser mayor o igual a 12',
  common_numberOfRepetitionsMustBeGreaterThanOrEqualTo2_label: 'El número de repeticiones debe ser mayor o igual a 2',
  common_noFutureDateAllowed_label: 'No se permite fecha futura',
  common_totalizers_label: 'Totalizadores',
  common_attachTheFile_label: 'Adjuntar el archivo',
  common_fileRequired_label: 'Archivo obligatorio',
  common_bankAccountRequired_label: 'Cuenta bancaria obligatoria',
  common_pagar_me_label: 'Pagar.me',

  receivables_scheduled_label: 'Agendado',
  receivables_peding_label: 'En espera de confirmación de liquidación',
  receivables_settled_label: 'Liquidado',
  receivables_payed_label: 'Pagado',
  receivables_errorSettledFinancialInstitution_label: 'Error de liquidación en la entidad financiera',
  receivables_waitingForAdjustmentDebit_label: 'En espera de liquidación del ajuste de débito asociado',
  receivables_anticipated_label: 'Evento anticipado',
  receivables_eventDcpCredit_label: 'Contabilización del crédito de los plazos de una transacción',
  receivables_eventDcpFeeCredit_label: 'Lanzamiento de crédito a tasa fija pactado entre Marketplace y Braspag (Facilitador)',
  receivables_eventDcpRefundCredit_label: 'Liberación de crédito por cancelación',
  receivables_eventDcpChargebackCredit_label: 'Liberación de crédito debido a una devolución de cargo',
  receivables_eventDcpUndoCargebackCredit_label: 'Liberación de crédito por reversión de un contracargo',
  receivables_eventDcpAntifraudFeeCredit_label: 'Asiento de crédito relacionado con la transacción antifraude',
  receivables_eventDcpAntiFraudFeeWithReviewCredit_label: 'Entrada de crédito relacionada con la transacción antifraude con revisión manual',
  receivables_eventDcpAdjustmentCredit_label: 'Contabilización de un crédito como un ajuste',
  receivables_evetDcpChargebackReversalCredit_label: 'Asiento de crédito relacionado con la reversión de un contracargo',
  receivables_eventDcpAnticipationCredit_label: 'Entrada de crédito relacionado con la anticipación',
  receivables_eventDcpAnticipationCommissionCredit_label: 'Entrada de crédito referente a la comisión de un anticipo',
  receivables_eventDcpAnticipatedInstallmentsCredit_label: 'Liberación de crédito referente a la anticipación de cuotas de una transacción',
  receivables_eventDcpRefundReversalCredit_label: 'Contabilización de crédito debido a una anulación de anulación',
  receivables_eventDcpReversalFeeCredit_label: 'Liberación de crédito por reversión de la Tarifa Fija pactada entre el Marketplace y Braspag',
  receivables_eventDcpBankSlipFeeCredit_label: 'Asiento de crédito relacionado con la reversión de una transacción antifraude con revisión manual',
  receivables_eventDcpBalanceCompensationCredit_label: 'Asiento de crédito referente a la compensación de saldo con el propio saldo del comercio (transferir saldo de un arreglo positivo a otro negativo)',
  receivables_eventDcpReversalAntiFraudFeeCredit_label: 'Asiento de crédito relacionado con la reversión de una transacción antifraude',
  receivables_eventDcpReversalBankSlipFeeCredit_label: 'Asiento de crédito relacionado con la reversión de una transacción antifraude con revisión manual',
  receivables_eventDcpScheduleBalanceCredit_label: 'Lanzamiento de crédito programado',
  receivables_eventDcpDebit_label: 'Contabilización del débito de las cuotas de una transacción',
  receivables_eventDcpFeeDebit_label: 'Lanzamiento del débito de la tarifa fija pactada entre Marketplace y Braspag (Facilitador)',
  receivables_eventDcpRefundDebit_label: 'Contabilización de un cargo por cancelación',
  receivables_eventDcpChargebackDebit_label: 'Contabilización de un débito debido a una devolución de cargo',
  receivables_eventDcpUndoChargebackDebit_label: 'Publicar un débito para revertir una devolución de cargo',
  receivables_eventDcpAntiFraudFeeDebit_label: 'Asiento de débito relacionado con la transacción antifraude',
  receivables_eventDcpAntiFraudFeeWithReviewDebit_label: 'Asiento de débito relacionado con la transacción antifraude con revisión manual',
  receivables_eventDcpAdjustmentDebit_label: 'Contabilización de un débito como ajuste',
  receivables_eventDcpChargebackReversalDebit_label: 'Publicación de un débito relacionado con la reversión de un contracargo',
  receivables_eventDcpAnticipationCommissionDebit_label: 'Entrada de adeudo referente a la comisión de un anticipo',
  receivables_eventDcpAnticipatedInstallmentsDebit_label: 'Contabilización de un débito referente a la anticipación de cuotas de una operación',
  receivables_eventDcpRefundReversalDebit_label: 'Contabilización de débito debido a una anulación de cancelación',
  receivables_eventDcpReversalPayoutDebit_label: 'Contabilización de débito debido a una anulación de pago',
  receivables_eventDcpReversalFeeDebit_label: 'Asiento de débito por reversión de la Cuota Fija pactada entre el Mercado',
  receivables_eventDcpBankSlipFeeDebit_label: 'Lanzamiento del débito de Tarifa Fija para el producto boleto pactado entre Marketplace y Braspag',
  receivables_eventDcpBalanceCompensationDebit_label: 'Asiento de débito referente a compensación de saldo con saldo propio del comercio (transferir saldo de un arreglo positivo a otro negativo)',
  receivables_eventDcpReversalAntiFraudFeeDebit_label: 'Asiento de débito relacionado con la reversión de una transacción antifraude',
  receivables_eventDcpAnticipationDebit_label: 'Entrada de débito referente a la anticipación',
  receivables_eventDcpCompensationBetweenSamePaymentArrangementDebit_label: 'Publicación de un débito relacionado con la compensación dentro del mismo pago',
  receivables_eventDcpScheduleBalanceDebit_label: 'Entrada de débito programada',
  receivables_tableColumnTitleDate_label: 'Fecha',
  receivables_tableColumnTitleFlag_label: 'Bandera',
  receivables_tableColumnTitleAutDoc_label: 'AUT / DOC',
  receivables_tableColumnTitleOperationType_label: 'Tipo de operación',
  receivables_tableColumnTitleGrossAmountInstallment_label: 'Valor bruto de la cuota',
  receivables_tableColumnTitleNetAmountInstallment_label: 'Valor neto de la cuota',
  receivables_tableColumnTitleStatus_label: 'Status',
  receivables_csvColumnTitlePaymentDate_label: 'Día de paga',
  receivables_csvColumnTitleAcquirer_label: 'Adquirente',
  receivables_csvColumnTitleFlag_label: 'Bandera',
  receivables_csvColumnTitleParcel_label: 'Plazos',
  receivables_csvColumnTitleAmountInstallments_label: 'Número de plazos',
  receivables_csvColumnTitleOperationType_label: 'Tipo de operación',
  receivables_csvColumnTitleAuthorizationCode_label: 'Código de autorización (AUT)',
  receivables_csvColumnTitleDOCNSU_label: 'DOC/NSU',
  receivables_csvColumnTitleGrossAmount_label: 'Valor bruto',
  receivables_csvColumnTitleNetAmount_label: 'Valor neto',
  receivables_csvColumnTitleMdr_label: 'MDR (%)',
  receivables_csvColumnTitleMdrFee_label: 'MDR (R$)',
  receivables_csvColumnTitleBinamikFee_label: 'Tasa binamik (R$)',
  receivables_csvColumnTitleCardNumber_label: 'Número de tarjeta',
  receivables_csvColumnTitleSaleDate_label: 'Fecha de venta',
  receivables_csvColumnTitleSaleGrossAmount_label: 'Valor bruto de venta',
  receivables_csvColumnTitleStatus_label: 'Status',
  receivables_csvColumnTitleEventTypeAcquirer_label: 'Tipo de evento en adquirente',
  receivables_csvColumnTitleDescriptionEventAcquirer_label: 'Descripción del evento en el adquirente',
  receivables_csvColumnTitleConciliated_label: 'Reconciliado',
  receivable_selectPeriodToImport_toast: 'Seleccione un período para realizar la importación',
  receivable_paymentImportPeriodLimitToOneMonth_toast: 'Límite de importación de un mes (31 días) a la vez',
  receivable_paymentNoFutureReceivablesAllowed_toast: 'No está permitido importar cuentas por cobrar para hoy o fechas futuras',
  receivable_itIsNotAllowedDeleteReceivablesAlreadyConciliated_toast: 'No se permite eliminar recibos ya conciliados',
  receivable_deleteReceivable_toast: [
    '<<NUMBER>> recibo eliminado',
    '<<NUMBER>> recibos eliminados'
  ],
  receivable_invalidFileFormat_label: 'Formato de archivo inválido',

  revenue_statusScheduled_label: 'Programado',
  revenue_statusCanceled_label: 'Programación Cancelada',
  revenue_statusReceived_label: 'Recibido',
  revenue_statusRefunded_label: 'Recibo Revertido',
  revenue_accountsReceivableSuccessfullyRegistered_toast: 'Cuentas por Cobrar registradas exitosamente',
  revenue_errorRegisteringAccountsReceivable_toast: 'Error al registrar Cuentas por Cobrar',
  revenue_accountsReceivableUpdatedSuccessfully_toast: 'Cuentas por Cobrar actualizadas exitosamente',
  revenue_errorUpdatingAccountsReceivable_toast: 'Error al actualizar Cuentas por Cobrar',
  revenue_errorFetchingAccountsReceivable_toast: 'Error al buscar Cuentas por Cobrar',
  revenue_dateOnlyByReceivedPresent_toast: 'No se pueden generar ingresos pagados para fechas futuras',
  revenue_dateOnlyBeScheduledFuture_toast: 'La programación de ingresos solo se puede realizar para fechas futuras',
  revenue_errorLoadingAccountsReceivableHistory_toast: 'Error al cargar el historial de cuentas por cobrar',
  revenue_errorLoadingAccountsReceivable_toast: 'Error al cargar las cuentas por cobrar',
  revenue_errorLoadingAvailableStatus_toast: 'Error al cargar los estados disponibles',
  revenue_errorLoadingForgottenReceivables_toast: 'Error al cargar los recibos olvidados',
  revenue_auditModalTitle_label: 'Historial de cambios Cuentas por cobrar',

  expense_statusScheduled_label: 'Programado',
  expense_statusCanceled_label: 'Programación Cancelada',
  expense_statusPayed_label: 'Pagado',
  expense_statusRefunded_label: 'Pago Revertido',
  expense_accountsPayableSuccessfullyRegistered_toast: 'Cuentas por Pagar registradas exitosamente',
  expense_errorRegisteringAccountsPayable_toast: 'Error al registrar Cuentas por Pagar',
  expense_errorWhenSearchingForAccountsPayable_toast: 'Error al buscar Cuentas por Pagar',
  expense_accountsPayableUpdatedSuccessfully_toast: 'Cuentas por Pagar actualizadas exitosamente',
  expense_errorUpdatingAccountsPayable_toast: 'Error al actualizar Cuentas por Pagar',
  expense_errorDeletingReceipts_toast: 'Error al eliminar los recibos',
  expense_dateOnlyByPayedPresent_toast: 'Los pagos de gastos no pueden realizarse para fechas futuras',
  expense_dateOnlyBeScheduledFuture_toast: 'La programación de gastos solo se puede hacer para fechas futuras',
  expense_onlyWithScheduledOrCanceledStatus_toast: 'Los cambios en otras cuentas por pagar solo están permitidos con estado programado o cancelado',
  expense_errorLoadingAvailableStatus_toast: 'Error al cargar los estados disponibles',
  expense_errorLoadingForgottenAccountsPayable_toast: 'Error al cargar las cuentas por pagar olvidadas',
  expense_errorLoadingAccountsPayableHistory_toast: 'Error al cargar el historial de cuentas por pagar',
  expense_errorLoadingAccountsPayable_toast: 'Error al cargar las cuentas por pagar',
  expense_errorLoadingRecurringAccountsPayableSimulation_toast: 'Error al cargar la simulación de cuentas por pagar recurrentes',
  expense_auditModalTitle_label: 'Historial de cambios de Cuentas por pagar.',
  expense_accountsPayable_label: 'Cuentas por Pagar',
  expense_newAccount_label: 'Nueva cuenta',
  expense_newRecurringAccount_label: 'Nueva cuenta recurrente',
  expense_accountPayableScheduledUnpaid_label: [
    'Hay <<OLVIDADO>> Cuenta por Pagar programada y no pagada',
    'Hay <<OLVIDADOS>> Cuentas por Pagar programadas y no pagadas'
  ],
  expense_onTheLastDay_label: [
    ' en el último día.',
    ' en los últimos <<DAYS_AGO>> días.'
  ],
  expense_typeOfExpense_label: 'Tipo de gasto',
  expense_productAndService_label: 'Producto/Servicio',
  expense_supplier_label: 'Proveedor',
  expense_selectASupplier_label: 'Seleccione un proveedor',
  expense_payment_label: 'Pago',
  expense_paymentMethod_label: 'Forma de pago',
  expense_registrationDate_label: 'Fecha de registro',
  expense_selectPaymentDate_label: 'Seleccione la fecha de pago',
  expense_selectReferenctDate_label: 'Seleccione la fecha de referencia',
  expense_selectExpenseType_label: 'Seleccione el tipo de gasto',
  expense_paymentDayOfTheMonth_label: 'Día del mes de pago',
  expense_selectThePaymentDayOfTheMonth_label: 'Seleccione el día del mes de pago',
  expense_bankFee_label: 'Tasa bancaria',
  expense_recurrenceType_label: 'Tipo de recurrencia',
  expense_informationOnTypesOfRecurrences_label: 'Información de los tipos de recurrencias',
  expense_recurringPayment_label: 'Pago recurrente',
  expense_paymentAmountWillBeTheSameAsTheSchedulingAmount_label: 'El valor de los pagos será el mismo valor de la programación',
  expense_installment_label: 'Financiación',
  expense_appointmentCostPerRepetition_label: 'El valor de los pagos será el valor de la programación dividido por el número de repeticiones',
  expense_selectTheTypeOfRecurrence_label: 'Seleccione el tipo de recurrencia',
  expense_numberOfRepetitions_label: 'Número de repeticiones',
  expense_fillInNumberOfRepetitions_label: 'Ingrese el número de repeticiones',
  expense_changeInformation_label: 'Información de los cambios',
  expense_replicateChange_label: 'Replicar cambio',
  expense_applyChangesToOtherRecurringPayments_label: 'Aplicar los cambios a otros pagos recurrentes',
  expense_doNotReplicateChanges_label: 'No replicar cambios',
  expense_theEditOnlyAffectsTheCurrentPayment_label: 'La edición afecta solo el pago actual',
  expense_selectTheChange_label: 'Seleccione el cambio',
  expense_addedReceipt_label: [
    '<<NUMBER>> comprobante añadido',
    '<<NUMBER>> comprobantes añadidos'
  ],
  expense_receipts_label: 'Comprobantes',
  expense_addOrRemoveReceitps_label: 'Añadir o quitar comprobantes',
  expense_dragTheImageOrClickToAddMaximum1Mb_label: 'Arrastre la imagen o haga clic para añadir máximo 1Mb',
  expense_simulationOfRecurringPayments_label: 'Simulación de pagos recurrentes',
  expense_datesOfEachPaymentFromThisCurrentOne_label: 'Fechas de cada pago a partir de esta actual',
  expense_amountOfEachPayment_label: 'Valor de cada pago:',
  expense_downloadYouReceipts_label: 'Descargue sus comprobantes',
  expense_downloadAllReceitps_label: 'Descargue todos los comprobantes',
  expense_accountPayableRegistration_label: 'Registro de Cuenta por Pagar',
  expense_editPayableAccount_label: 'Editar Registro de Cuenta por Pagar',
  expense_recurringPayableAccountRegistration_label: 'Registro de Cuenta por Pagar Recurrente',
  expense_editRecurringPayableAccountRegistration_label: 'Editar Registro de Cuenta por Pagar Recurrente',
  expense_fillOuTheSimulationForm_label: 'Complete el Formulario de Simulación',
  expense_mandatoryPaymentDayOfTheMonth_label: 'Día Obligatorio de Pago del Mes',
  expense_mandatoryRecurrenceType_label: 'Tipo de Recurrencia Obligatoria',
  expense_replicateMandatoryChange_label: 'Replicar Cambio Obligatorio',
  expense_viewPayableAccount_label: 'Ver Cuenta por Pagar',
  expense_fileNameExpense_label: 'cuentas_por_pagar_<<START_DATE>>-<<END_DATE>>',
  expense_repetitionLimitIs_label: 'El límite de repeticiones es <<MAX_OCURRENCES>>',
  expense_editBatch_label: 'Editar lote',
  expense_selectedPayableAccount_label: [
    'Cuenta a pagar seleccionada',
    'Cuentas a pagar seleccionadas'
  ],
  expense_editPayablesInBatch_label: 'Editar cuentas a pagar en lote',
  expense_accountsPayableUpdatedSuccessfully_label: 'Cuentas a pagar actualizadas con éxito',
  expense_addOrRemoveReceipts_label: 'Añadir o eliminar recibos',
  expense_attachmentWilNotBeAppliedToOtherInstallmentsRecurrences_label: 'Este anexo no se aplicará a otras cuotas/recurrencias',
  expense_attachmentWillAppearInAllInstallmentsRecurrences_label: 'Este anexo aparecerá en todas las cuotas/recurrencias',
  expense_totalBatchAccounts_label: 'Total de cuentas por lotes',
  expense_addReceipts_label: 'Añada sus recibos',
  expense_attachmentWillAppearOnAllTheAccountsInTheBatch_label: 'Este anexo aparecerá en todas las cuentas del lote',

  expense_revenue_typeOfRevenueExpenseSuccessfullyRegistered_toast: 'Tipo de Ingreso/Gasto registrado exitosamente',
  expense_revenue_errorWhenRegisteringTypeOfRevenueExpense_toast: 'Error al registrar Tipo de Ingreso/Gasto',
  expense_revenue_typeOfRevenueExpenseUpdatedSuccessfully_toast: 'Tipo de Ingreso/Gasto actualizado exitosamente',
  expense_revenue_errorWhenUpdatingRevenueExpenseType_toast: 'Error al actualizar Tipo de Ingreso/Gasto',
  expense_revenue_errorWhenSearchingForRevenueExpenseType_toast: 'Error al buscar Tipo de Ingreso/Gasto',
  expense_revenue_typeOfRevenueExpenseSuccessfullyRemoved_toast: 'Tipo de Ingreso/Gasto eliminado exitosamente',
  expense_revenue_errorRemovingRevenueExpenseType_toast: 'Error al eliminar Tipo de Ingreso/Gasto',
  expense_revenue_errorLoadingReveueExpenseTypeHistory_toast: 'Error al cargar el historial de tipos de ingresos/gastos',
  expense_revenue_errorLoadingTypesOfRevenuesExpenses_toast: 'Error al cargar los tipos de ingresos/gastos',
  expense_revenue_errorLoadingRevenueExpenseTypeOptions_toast: 'Error al cargar las opciones de tipo de ingreso/gasto',
  expense_revenue_expenseRevenueCannotDestroy_toast: 'No se puede eliminar el Ingreso/Gasto en uso',
  expense_revenue_auditModalTitle_label: 'Historial de cambios de tipo de gastos/ingresos',

  conciliation_pageTitleBankConciliations_label: 'Conciliaciones Bancaria',
  conciliation_tabsTitleConciliations_label: 'Conciliado',
  conciliation_tabsTitleNotConciliations_label: 'No Conciliado',
  conciliation_tabsTitlePartiallyConciliations_label: 'Parcialmente Conciliado',
  conciliation_tableTitleImportedBankPostings_label: 'Asientos bancarios importados',
  conciliation_tableTitleSystemReleases_label: 'Lanzamientos del sistema',
  conciliation_fillInFieldsFromTheStartBar_label: 'Para enumerar las conciliaciones, primero complete los campos de filtro del informe',
  conciliation_tableColumnTitleDate_label: 'Fecha',
  conciliation_tableColumnTitleValue_label: 'Valor',
  conciliation_tableColumnTitleHistoric_label: 'Histórico',
  conciliation_tableColumnTitleDocument_label: 'Documento',
  conciliation_tableColumnTitleDescription_label: 'Descripción',
  conciliation_startDate_inputLabel: 'La fecha de inicio',
  conciliation_endDate_inputLabel: 'Fecha final',
  conciliation_dateFormat_formErrorLabel: 'La fecha debe tener el formato dd/mm/yyyy',
  conciliation_startDateRequired_formErrorLabel: 'La fecha de inicio es obligatoria',
  conciliation_endDateRequired_formErrorLabel: 'La fecha de finalización es obligatoria',
  conciliation_startDate_placeholderLabel: 'La fecha de inicio',
  conciliation_endDate_placeholderLabel: 'Data final',
  conciliation_bankAccountId_inputLabel: 'Fecha final',
  conciliation_bankAccountId_placeholderLabel: 'Seleccione la cuenta',
  conciliation_bankAccountRequired_formErrorLabel: 'Se requiere cuenta bancaria',
  conciliation_endDateMustBeAfterOrEqualStartDate_formErrorLabel: 'La fecha de finalización debe ser posterior o igual a la fecha de inicio',
  conciliation_listButton_label: 'Lista',
  conciliation_noItemsToConciliation_label: 'No hay elementos para conciliar',
  conciliation_itemSelected_label: ['Item seleccionado', 'Ítems Seleccionados'],
  conciliation_makeAutomaticConciliation_label: 'Hacer conciliación automática',
  conciliation_makeManualConciliation_label: 'Hacer conciliación manuale',
  conciliation_automaticConciliationCreate_label: [
    '<<NUMBER>> conciliación automática creada',
    '<<NUMBER>> conciliaciones automáticas creadas'
  ],
  conciliation_manualConciliationCreate_label: 'Conciliación manual creada',
  conciliation_deletedConciliation_label: [
    '<<NUMBER>> conciliación eliminada',
    '<<NUMBER>> conciliaciones eliminadas'
  ],
  conciliation_deleteConciliations_error: 'Error al eliminar conciliaciones',
  conciliation_deleteConciliation_label: [
    'Eliminar conciliación',
    'Eliminar conciliaciones'
  ],
  conciliation_errorLoadingConciliations_toast: 'Error al cargar las conciliaciones',
  conciliation_errorCreatingAutomaticConciliation_toast: 'Error al crear la conciliación automática',
  conciliation_errorCreatingManualConciliation_toast: 'Error al crear la conciliación manual',
  conciliation_divergentValue_label: 'Valor Divergente',
  conciliation_divergentDate_label: 'Fecha Divergente',
  conciliation_details_label: 'Detalles',
  conciliation_conciliationBy_label: 'Conciliado por',
  conciliation_creationDate_label: 'Fecha de Creación',
  conciliation_noConciliationItems_label: 'No hay elementos conciliados',
  conciliation_tableTitleReceivablesReleases_label: 'Lanzamientos de cuentas por cobrar',
  conciliation_pageTitleCreditCardConciliations_label: 'Conciliaciones de tarjetas de crédito',
  conciliation_selectAcquirer_placeholderLabel: 'Seleccione la adquirente',
  conciliation_reconcilingCanceledSystemPostingsIsNotPermitted_label: 'No está permitido conciliar lanzamientos del sistema cancelados.',
  conciliation_totalAmountOfReceivablesNotReconciled_label: 'Valor total de los recibos no conciliados',
  conciliation_totalSystemValueNotReconciled_label: 'Valor total del sistema no conciliado',
  conciliation_totalValueOfReconciledReceivables_label: 'Valor total de los recibos conciliados',

  bank_account_auditModalTitle_label: 'Historial de cambios Cuentas Bancarias',
  bank_account_editModalTitle_label: 'Editar Cuenta Bancaria',
  bank_account_tableColumnTitleBankName_label: 'Banco',
  bank_account_tableColumnTitleBranchNumber_label: 'Agencia',
  bank_account_tableColumnTitleAccountNumber_label: 'Cuenta',
  bank_account_tableColumnTitleStatus_label: 'Estado',
  bank_account_tableColumnTitleAction_label: 'Acción',
  bank_account_statusValueEnabled_label: 'Activo',
  bank_account_statusValueDisabled_label: 'Desactivado',
  bank_account_pageTitleBankAccounts_label: 'Cuentas Bancarias',
  bank_account_bankName_inputLabel: 'Banco',
  bank_account_branchNumber_inputLabel: 'Agencia',
  bank_account_accountNumber_inputLabel: 'Cuenta',
  bank_account_status_inputLabel: 'Estado',
  bank_account_accountablePlanId_inputLabel: 'Plan de cuenta',
  bank_account_accountablePlanId_placeholderLabel: 'Seleccione el plan de cuenta',
  bank_account_errorLoadBankAccountOptions_toast: 'Error al cargar las opciones de la cuenta bancaria',
  bank_account_errorLoadingBankAccountList_toast: 'Error al cargar la lista de cuentas bancarias',
  bank_account_errorLoadingBankAccount_toast: 'Error al cargar la cuenta bancaria',
  bank_account_errorUpdatingBankAccount_toast: 'Error al actualizar la cuenta bancaria',
  bank_account_errorLoadingBankAccountHistory_toast: 'Error al cargar el historial de la cuenta bancaria',
  bank_account_bankAccountHasBeenUpdatedSuccessfully_toast: 'La cuenta bancaria se ha actualizado correctamente',
  bank_account_incompatibleDifferentBankAccount_toast: 'Bancos diferentes',

  bank_statement_errorFetchingBankStatements_toast: 'Error al obtener extractos bancarios',
  bank_statement_deletedBankStatement_toast: [
    'Extracto bancario eliminado: <<NUMBER>>',
    'Extractos bancarios eliminados: <<NUMBER>>'
  ],
  bank_statement_errorDeletingBankStatements_toast: 'Error al eliminar extractos bancarios',
  bank_statement_errorExportBankStatementFile_toast: 'Error al exportar archivo de extracto bancario',
  bank_statement_importCorruptedFile_toast: 'Archivo de extracto bancario corrupto',
  bank_statement_importWrongNumberOrFile_toast: 'Código de banco desconocido o archivo incorrecto',
  bank_statement_importEmptyFieldFormats_toast: 'Campos no registrados para este formato de archivo',
  bank_statement_errorFetchingImportedBankStatements_toast: 'Error al cargar la lista de extractos bancarios importados',
  bank_statement_imported_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['lanzamiento importado', 'lanzamientos importados']
    }
  },
  bank_statement_transactions_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['lanzamientos en el extracto', 'lanzamientos en los extractos']
    }
  },
  bank_statement_ignored_current_date_transactions_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['lanzamiento ignorado en la fecha actual', 'lanzamientos ignorados en la fecha actual']
    }
  },
  bank_statement_already_imported_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['lanzamiento ya había sido importado', 'lanzamientos ya habían sido importados']
    }
  },
  bank_statement_not_imported_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['lanzamiento no importado', 'lanzamientos no importados']
    }
  },
  bank_statement_date_releases: 'Fecha <<DATE>>:',
  bank_statement_importing_bank_statements_label: 'Importación de Extractos Bancarios',
  bank_statement_bank_account_label: 'Cuenta Bancaria',
  bank_statement_accepted_file_formats_label: 'Formatos de Archivos Aceptados:',
  bank_statement_statement_import_completed_label: '¡Importación de Extracto Completada!',
  bank_statement_waiting_bank_statement_imported_label: 'Esperando la Importación del Extracto Bancario!',
  bank_statement_latest_imported_extracts_label: 'Últimos Extractos Importados',
  bank_statement_details_label: 'Detalles:',
  bank_statement_select_account_label: 'Seleccione una Cuenta',
  bank_statement_itIsNotAllowedDeleteBankStatementAlreadyConciliated_toast: 'No está permitido eliminar extractos bancarios que ya hayan sido conciliados',
  bank_statement_errorWhenImportingBankStatements_toast: 'Error al importar los extractos bancarios',
  bank_statement_bankAccountNotAllowed_toast: 'Cuenta bancaria no permitida',
  bank_statement_onlyPastDatesAllowed_label: 'Solo se permiten fechas anteriores al día de hoy',
  bank_statement_performImport_label: 'Realizar importación',
  bank_statement_pixPaymentPagarme_label: 'Pago pix Pagar.me',
  bank_statement_transferFromPagarme_label: 'Transferencia de Pagar.me',
  bank_statement_startAndEndDateMustBeDifferent_label: 'La fecha de inicio y finalización deben ser diferentes.',

  account_plan_errorFecthingAccountsPlans_toast: 'Error al buscar planes de cuentas',
  account_plan_errorFecthingAccountPlan_toast: 'Error al obtener el plan de cuenta',
  account_plan_errorFecthingAccountsPlansOptions_toast: 'Error al buscar opciones de plan de cuentas',
  account_plan_errorDeleteAccountPlan_toast: 'Error al eliminar el plan de cuenta',
  account_plan_successDeletedAccountPlan_toast: 'Plan de cuenta eliminado con éxito',
  account_plan_accountPlanSucessRegistered_toast: 'El plan de cuenta se ha registrado correctamente',
  account_plan_errorRegisteredAccountPlan_toast: 'Error al registrar el plan de cuenta',
  account_plan_accountPlanSucessUpdated_toast: 'El plan de cuenta se ha actualizado correctamente',
  account_plan_errorUpdateAccountPlan_toast: 'Error al actualizar plan de cuenta',

  event_menuTitleEvents_label: 'Eventos',
  event_eventType_inputLabel: 'Tipo de Evento',
  event_selectEventType_placeholderLabel: 'Seleccionar tipo de evento',
  event_pageTitleEvents_label: 'Eventos',
  event_tableColumnTitleEventType_label: 'Tipo de Evento',
  event_tableColumnTitleEventData_label: 'Datos del Evento',
  event_errorFetchingEvents_toast: 'Error al obtener los eventos',
  event_errorFecthingEventsTypes_toast: 'Error al obtener los tipos de eventos',
  event_errorFechtingEventRequestDetails_toast: 'Error al buscar los detalles de la solicitud de eventos',
  event_errorFetchingEventFieldNames_toast: 'Error al obtener nombres de campos de evento',
  // event type
  event_partnershipCredit_label: 'Crédito de Socio',
  event_schedulingAccountsPayable_label: 'Programación de Cuentas por Pagar',
  event_schedulingAccountsReceivable_label: 'Programación de Cuentas por Cobrar',
  event_writeOffExternalBillet_label: 'Anulación de Boleto Externo',
  event_writeOffAccountsPayable_label: 'Anulación de Cuentas por Pagar',
  event_writeOffAccountsReceivable_label: 'Anulación de Cuentas por Cobrar',
  event_writeOffExternalBilletFee_label: 'Anulación de Tarifa de Boleto Externo',
  event_digitalAccountMovement_label: 'Movimiento de Cuenta Digital',
  event_overdueBillet_label: 'Boleto Vencido',
  event_onlinePaymentCancellationCreditCard_label: 'Anulación de Pago en Línea con Tarjeta de Crédito',
  event_invoicePaymentCancellationSeller_label: 'Anulación de Pago de Factura - Vendedor',
  event_cancellationAccountsPayable_label: 'Anulación de Cuentas por Pagar',
  event_cancellationAccountsReceivable_label: 'Anulación de Cuentas por Cobrar',
  event_ISSPaymentCancellation_label: 'Anulación de Pago de Impuesto ISS',
  event_ticketCancellation_label: 'Anulación de Entrada',
  event_supplierISSpaymentCancellation_label: 'Anulación de Pago de Impuesto ISS de Proveedor',
  event_customerInvoiceCancellation_label: 'Anulación de Factura de Cliente',
  event_offlinePaymentCancellation_label: 'Anulación de Pago sin Conexión',
  event_onlinePaymentCreditCard_label: 'Pago en Línea con Tarjeta de Crédito',
  event_onlinePaymentDebitCard_label: 'Pago en Línea con Tarjeta de Débito',
  event_invoiceReceivement_label: 'Recepción de Factura',
  event_bankAccountRegistration_label: 'Registro de Cuenta Bancaria',
  event_gravameDebit_label: 'Débito de Gravamen',
  event_checkDeposit_label: 'Depósito de Cheque',
  event_cashDeposit_label: 'Depósito en Efectivo',
  event_checkReturned_label: 'Cheque Devuelto',
  event_billetIssue_label: 'Emisión de Boleto',
  event_ISSPaymentScheduling_label: 'Programación de Pago de Impuesto ISS',
  event_supplierISSPaymentSchedule_label: 'Programación de Pago de Impuesto ISS de Proveedor',
  event_issuanceInvoiceCustomer_label: 'Emisión de Factura al Cliente',
  event_agentBoxEntry_label: 'Entrada en Caja del Agente',
  event_partnershipDebit_label: 'Débito de Socio',
  event_refundAccountsPayable_label: 'Reembolso de Cuentas por Pagar',
  event_refundAccountsReceivable_label: 'Reembolso de Cuentas por Cobrar',
  event_checkDespostiRefund_label: 'Reembolso de Depósito de Cheque',
  event_ISSPaymentRefund_label: 'Reembolso de Pago de Impuesto ISS',
  event_supplierISSPaymentRefund_label: 'Reembolso de Pago de Impuesto ISS de Proveedor',
  event_invoicePaymentRefund_label: 'Reembolso de Pago de Factura',
  event_POSMonthlyFee_label: 'Cuota Mensual POS',
  event_invoicePayment_label: 'Pago de Factura',
  event_paymentOfISSTax_label: 'Pago de Impuesto ISS',
  event_supplierISSTaxPayment_label: 'Pago de Impuesto ISS de Proveedor',
  event_offlinePayment_label: 'Pago sin Conexión',
  event_tourTaken_label: 'Tour Realizado',
  event_guideTourOwnTicket_label: 'Tour Realizado - Entrada Propia',
  event_transferSupplier_label: 'Transferencia a Proveedor',
  event_cancelattionTransferSupplier_label: 'Anulación de Transferencia a Proveedor',
  event_transferPaymentDebitCard_label: 'Transferencia de Pago con Tarjeta de Débito',
  event_confirmedReservation_label: 'Reserva Confirmada',
  event_confirmedBookingOwnTicket_label: 'Reserva Confirmada - Entrada Propia',
  event_withdrawalCurrentAccount_label: 'Retiro de Cuenta Corriente',
  event_agentCashOut_label: 'Salida de Efectivo de Agente',
  event_transferBetweenBankAccounts_label: 'Transferencia entre Cuentas Bancarias',
  // event_request
  event_info_label: 'Información',
  event_clientName_label: 'Nombre del Cliente',
  event_partnerName_label: 'Nombre del Socio',
  event_reservationCode_label: 'Código de Reserva',
  event_fee_label: 'Tarifa',
  event_type_label: 'Tipo',
  event_brand_label: 'Marca',
  event_installments_label: 'Cuotas',
  event_operator_label: 'Operador',
  event_multipoint_label: 'Multipunto',
  event_originType_label: 'Tipo de Origen',
  event_datePayment_label: 'Fecha de Pago',
  event_paymentMethod_label: 'Método de Pago',
  event_paymentValue_label: 'Valor del Pago',
  event_cashierReopened_label: 'Caja Reabierta',
  event_guideValue_label: 'Valor de la Guía',
  event_tourDate_label: 'Fecha del Tour',
  event_activityName_label: 'Nombre de la Actividad',
  event_ticketCode_label: 'Código de Entrada',
  event_cardNumber_label: 'Número de Tarjeta',
  event_operatorName_label: 'Nombre del Operador',
  event_userName_label: 'Nombre del Usuario',
  event_valueOfTaxIss_label: 'Valor del Impuesto (ISS)',
  event_attractiveValue_label: 'Valor del Atractivo',
  event_advanceAmount_label: 'Monto Anticipado',
  event_agencyValue_label: 'Valor de la Agencia',
  event_agencyFee_label: 'Tarifa de la Agencia',
  event_operatorFee_label: 'Tarifa del Operador',
  event_antifraudFee_label: 'Tarifa Antifraude',
  event_mdrFee_label: 'Tarifa de MDR',
  event_payFee_label: 'Tarifa de Pay',
  event_cancelattionDate_label: 'Fecha de Anulación',
  event_creditDate_label: 'Fecha de Crédito',
  event_debitDate_label: 'Fecha de Débito',
  event_dateOfReturn_label: 'Fecha de Devolución',
  event_amountCanceled_label: 'Monto Cancelado',
  event_amountReturned_label: 'Monto Devuelto',
  event_amountDeposited_label: 'Monto Depositado',
  event_agencyCancellationFee_label: 'Tarifa de Anulación de Agencia',
  event_partnerCancellationFee_label: 'Tarifa de Anulación de Socio',
  event_canceledCardFee_label: 'Tarifa de Tarjeta Anulada',
  event_feeCharged_label: 'Tarifa Cobrada',
  event_attractiveCancellationFee_label: 'Tarifa de Anulación del Atractivo',
  event_attractiveFee_label: 'Tarifa del Atractivo',
  event_orbFee_label: 'Tarifa de Orb',
  event_tuitionAmount_label: 'Monto de Matrícula',
  event_ticketBySplit_label: 'Entrada por Separado',
  event_paymentInTheAttractive_label: 'Pago en el Atractivo',
  event_invoiceIssuanceDate_label: 'Fecha de Emisión de Factura',
  event_emissionDate_label: 'Fecha de Emisión',
  event_amountOfTaxToBeCollected_label: 'Monto de Impuesto a Cobrar',
  event_invoiceTaxAmount_label: 'Monto de Impuesto de Factura',
  event_advanceValue_label: 'Monto Anticipado',
  event_ticketOriginType_label: 'Tipo de Origen de la Entrada',
  event_tickets_label: 'Entradas',
  event_ticketPrice_label: 'Precio de la Entrada',
  event_invoiceCancellationDate_label: 'Fecha de Anulación de Factura',
  event_refundDate_label: 'Fecha de Reembolso',
  event_transferDate_label: 'Fecha de Transferencia',
  event_dueDate_label: 'Fecha de Vencimiento',
  event_invoiceDueDate_label: 'Fecha de Vencimiento de Factura',
  event_invoiceAmount_label: 'Monto de la Factura',
  event_transferAmount_label: 'Monto de la Transferencia',
  event_billetValue_label: 'Valor del Boleto',
  event_description_label: 'Descripción',
  event_amountPaid_label: 'Monto Pagado',
  event_advanceDate_label: 'Fecha de Anticipo',
  event_depositDate_label: 'Fecha de Depósito',
  event_CCChartOfAccounts_label: 'Plan de Cuentas CC',
  event_CCOriginChartOfAccounts_label: 'Plan de Cuentas CC de Origen',
  event_CCDestinationChartOfAccounts_label: 'Plan de Cuentas CC de Destino',
  event_machineNumber_label: 'Número de Máquina',
  event_amountRefund_label: 'Monto Reembolsado',
  event_billetFee_label: 'Tarifa de Boleto',
  event_noteIssuanceDate_label: 'Fecha de Emisión de Nota',
  event_date_label: 'Fecha',
  event_profit_label: 'Ganancia',
  event_dateReceivement_label: 'Fecha de Recepción',
  event_withdrawalDate_label: 'Fecha de Retiro',
  event_referenceDate_label: 'Fecha de Referencia',
  event_amountReceivable_label: 'Monto a Cobrar',
  event_value_label: 'Valor',
  event_withdrawalValue_label: 'Valor del Retiro',
  event_amountReceived_label: 'Monto Recibido',
  event_amountPayable_label: 'Monto a Pagar',
  event_bankFee_label: 'Tarifa Bancaria',
  event_bankNumber_label: 'Número de Banco',
  event_bankName_label: 'Nombre del Banco',
  event_agencyNumber_label: 'Número de Agencia',
  event_accountNumber_label: 'Número de Cuenta',
  event_accountType_label: 'Tipo de Cuenta',
  event_originSystem_label: 'Sistema de Origen',
  event_enabled_label: 'Habilitado',
  event_paymentMethods_label: 'Métodos de Pago',
  event_operationType_label: 'Tipo de Operación',
  event_installmentsBeignPaid_label: 'Cuotas en Proceso de Pago',
  event_numberOfInstallmentsOfTheSale_label: 'Cantidad de Cuotas de la Venta',
  event_saleDate_label: 'Fecha de Venta',
  event_grossValue_label: 'Valor Bruto',
  event_netValue_label: 'Valor Neto',
  event_totalAmountOfFees_label: 'Monto Total de Tarifas',
  event_breakdownOfFees_label: 'Desglose de Tarifas',
  event_xpertFee_label: 'Tarifa de Xpert',
  event_transactionFee_label: 'Tarifa por Transacción',

  supplier_pageTitleSuppliers_label: 'Proveedores',
  supplier_contactDetailsMenuTitle_label: 'Detalles de contacto',
  supplier_addressMenuTitle_label: 'Dirección',
  supplier_bankAccountMenuTitle_label: 'Datos bancarios',
  supplier_tableColumnTitleCorporateName_label: 'Razón Social',
  supplier_tableColumnTitleSupplierDocument_label: 'Documento',
  supplier_tableColumnTitleFancyName_label: 'Nombre de Fantasía',
  supplier_tableColumnTitleStatus_label: 'Estado',
  supplier_tableColumnTitleAction_label: 'Acción',
  supplier_statusValueDisabled_label: 'Inactivo',
  supplier_statusValueEnabled_label: 'Activo',
  supplier_statusValueAll_label: 'Todos',
  supplier_newSupplierButton_label: 'Registrar nuevo proveedor',
  supplier_modalNewSupplierTitle_label: 'Registrar proveedor',
  supplier_modalEditSupplierTitle_label: 'Editar proveedor',
  supplier_listButton_label: 'Buscar',
  supplier_errorfecthingSuppliers_toast: 'Error al obtener proveedores',
  supplier_createSupplier_toast: 'El proveedor fue creado con éxito',
  supplier_errorCreateSupplier_toast: 'Error al crear proveedor',
  supplier_legalPerson_label: 'Persona Jurídica',
  supplier_naturalPerson_label: 'Persona Física',
  supplier_corporateNameRequired_formErrorLabel: 'Se requiere el nombre corporativo',
  supplier_supplierTypeRequired_formErrorLabel: 'El tipo de proveedor es obligatorio',
  supplier_zipCodeMaxCharacters_formErrorLabel: 'El código postal debe tener un máximo de 8 caracteres',
  supplier_stateMaxCharacters_formErrorLabel: 'El estado debe tener solo 2 caracteres',
  supplier_corporateName_inputLabel: 'Razón social',
  supplier_fancyName_inputLabel: 'Nombre de fantasía',
  supplier_supplierDocument_inputLabel: 'Documento',
  supplier_supplierType_inputLabel: 'Tipo de proveedor',
  supplier_municipalRegistration_inputLabel: 'Registro municipal',
  supplier_address_inputLabel: 'Dirección',
  supplier_neighborhood_inputLabel: 'Barrio',
  supplier_cityName_inputLabel: 'Ciudad',
  supplier_state_inputLabel: 'Estado',
  supplier_country_inputLabel: 'País',
  supplier_zipCode_inputLabel: 'Código postal',
  supplier_emails_inputLabel: 'Correos electrónicos',
  supplier_phoneNumbers_inputLabel: 'Números de contacto',
  supplier_contactNames_inputLabel: 'Personas de contacto',
  supplier_observation_inputLabel: 'Observación',
  supplier_corporateName_placeholderLabel: 'Introduzca el nombre de la empresa',
  supplier_fancyName_placeholderLabel: 'Introduce un nombre elegante',
  supplier_supplierDocument_placeholderLabel: 'Introduzca el CPF/CNPJ',
  supplier_supplierType_placeholderLabel: 'Seleccionar tipo de proveedor',
  supplier_enabled_placeholderLabel: 'Selecciona el estado',
  supplier_municipalRegistration_placeholderLabel: 'Entrar en el registro municipal',
  supplier_address_placeholderLabel: 'Introduce la dirección',
  supplier_neighborhood_placeholderLabel: 'Entra en el barrio',
  supplier_cityName_placeholderLabel: 'Entra en la ciudad',
  supplier_state_placeholderLabel: 'Introduzca el estado',
  supplier_country_placeholderLabel: 'Entra en el país',
  supplier_zipCode_placeholderLabel: 'Ingresa tu código postal',
  supplier_emails_placeholderLabel: 'Ingrese los correos electrónicos de contacto',
  supplier_phoneNumbers_placeholderLabel: 'Introduce los teléfonos de contacto',
  supplier_contactNames_placeholderLabel: 'Introduzca las personas de contacto',
  supplier_observation_placeholderLabel: 'Ingresa la nota',
  supplier_errorGetByIdSupplier_toast: 'Error al cargar proveedor',
  supplier_editSupplier_toast: 'Proveedor editado con éxito',
  supplier_errorEditSupplier_toast: 'Error al editar proveedor',
  supplier_createButton_label: 'Registrar',
  supplier_removeDialogTitle_label: 'Eliminar',
  supplier_removeDialogMessage_label: '¿Está seguro de que desea eliminar el proveedor?',
  supplier_removeSupplier_toast: 'Proveedor eliminado con éxito',
  supplier_errorRemoveSupplier_toast: 'Error al eliminar al proveedor',
  supplier_modalAuditSupplierTitle_label: 'Historial de cambios Proveedores',
  supplier_errorAuditSupplier_toast: 'Error al cargar el historial de proveedores',
  supplier_listSupplierBankAccounts_label: 'Listar cuentas bancarias',
  supplier_errorLoadSupplierOptions_toast: 'Error al cargar opciones de proveedor',
  supplier_supplierCannotDestroy_toast: 'El proveedor en uso en Cuentas por Pagar no se puede eliminar',
  supplier_supplierAlreadyRegistered_toast: 'Este documento ya ha sido registrado con otro proveedor',

  supplier_bank_account_pageTitleSupplierBankAccounts_label: 'Cuentas bancarias de',
  supplier_bank_account_newSupplierBankAccountButton_label: 'Registre una nueva cuenta bancaria de proveedor',
  supplier_bank_account_modalAuditSupplierBankAccountTitle_label: 'Historial de cambios Cuentas Bancarias de Proveedores',
  supplier_bank_account_tableColumnTitleBankName_label: 'Banco',
  supplier_bank_account_tableColumnTitleBranch_label: 'Agencia',
  supplier_bank_account_tableColumnTitleAccountNumber_label: 'Cuenta',
  supplier_bank_account_tableColumnTitleStatus_label: 'Estado',
  supplier_bank_account_tableColumnTitleAction_label: 'Acción',
  supplier_bank_account_statusValueDisabled_label: 'Inactivo',
  supplier_bank_account_statusValueEnabled_label: 'Activo',
  supplier_bank_account_accountTypeValueCheckingAccount_label: 'Cuenta corriente',
  supplier_bank_account_accountTypeValueSavingsAccount_label: 'Cuenta de ahorros',
  supplier_bank_account_modalCreateSupplierBankAccountTitle_label: 'Registrar nueva cuenta bancaria',
  supplier_bank_account_bankName_inputLabel: 'Banco',
  supplier_bank_account_branch_inputLabel: 'Agencia',
  supplier_bank_account_accountNumber_inputLabel: 'Cuenta',
  supplier_bank_account_accountType_inputLabel: 'Tipo de cuenta',
  supplier_bank_account_enabled_inputLabel: 'Estado',
  supplier_bank_account_bankName_placeholderLabel: 'Entrar al banco',
  supplier_bank_account_branch_placeholderLabel: 'Ingrese a la agencia',
  supplier_bank_account_accountNumber_placeholderLabel: 'Ingrese el número de cuenta',
  supplier_bank_account_accountType_placeholderLabel: 'Seleccione el tipo de cuenta',
  supplier_bank_account_enabled_placeholderLabel: 'Seleccione el estado',
  supplier_bank_account_bankNameRequired_formErrorLabel: 'El banco es obligatorio',
  supplier_bank_account_branchRequired_formErrorLabel: 'La agencia es obligatoria',
  supplier_bank_account_accountNumberRequired_formErrorLabel: 'La cuenta es obligatoria',
  supplier_bank_account_accountTypeRequired_formErrorLabel: 'Se requiere tipo de cuenta',
  supplier_bank_account_enabledRequired_formErrorLabel: 'El estado es obligatorio',
  supplier_bank_account_errorFecthingSupplierBankAccounts_toast: 'Error al buscar cuentas bancarias de proveedores',
  supplier_bank_account_createSupplierBankAccount_toast: 'Cuenta bancaria del proveedor creada exitosamente',
  supplier_bank_account_errorCreateSupplierBankAccount_toast: 'Error al crear la cuenta bancaria del proveedor',
  supplier_bank_account_editSupplierBankAccount_toast: 'Cuenta bancaria del proveedor editada correctamente',
  supplier_bank_account_errorEditSupplierBankAccount_toast: 'Error al editar la cuenta bancaria del proveedor',
  supplier_bank_account_modalEditSupplierBankAccountTitle_label: 'Editar cuenta bancaria del proveedor',
  supplier_bank_account_removeSupplierBankAccount_toast: 'La cuenta bancaria del proveedor se eliminó correctamente',
  supplier_bank_account_errorRemoveSupplierBankAccount_toast: 'Error al eliminar la cuenta bancaria del proveedor',
  supplier_bank_account_removeDialogTitle_label: 'Borrar',
  supplier_bank_account_removeDialogMessage_label: '¿Está seguro de que desea eliminar la cuenta bancaria del proveedor?',

  event_processor_menuTitleEventsProcessors_label: 'Procesadores de Eventos',
  event_processor_pageTitleEventsProcessors_label: 'Procesadores de Eventos',
  event_processor_tableColumnTitleEventType_label: 'Nombre del procesador',
  event_tableColumnTitleEntryCondition_label: 'Condición de entrada',
  event_processor_tableColumnTitleStatus_label: 'Estado',
  event_processor_tableColumnTitleActions_label: 'Acciones',
  event_processor_errorFetchingEventsProcessors_toast: 'Error al buscar los procesadores de eventos',
  event_processor_errorRemoveEventProcessor_toast: 'Error al eliminar el procesador de eventos',
  event_processor_successDeletedEventProcessor_toast: 'Procesador de eventos eliminado correctamente',
  event_processor_areYouSureToDeleteProcessors_label: '¿Estás seguro de que deseas eliminar el procesador?',
  event_processor_processorName_inputLabel: 'Nombre del procesador',
  event_processor_entryConditionField_inputLabel: 'Campo de la condición de entrada',
  event_processor_entryConditionValue_inputLabel: 'Valor de la condición de entrada',
  event_processor_status_inputLabel: 'Estado',
  event_processor_enterProcessorName_placeholderLabel: 'Ingresa el nombre del procesador',
  event_processor_selectInputConditionField_placeholderLabel: 'Selecciona el campo de la condición de entrada',
  event_processor_selectInputConditionValue_placeholderLabel: 'Selecciona el valor de la condición de entrada',
  event_processor_selectTheStatus_placeholderLabel: 'Selecciona el estado',
  event_processor_condition_label: [
    '<<NUMBER>> condición',
    '<<NUMBER>> condiciones'
  ],
  event_processor_entryConditions_label: [
    'Condición de entrada',
    'Condiciones de entrada'
  ],
  event_processor_newProcessor_label: 'Nuevo procesador',
  event_processor_nameAndDescription_label: 'Nombre y Descripción',
  event_processor_saveAndContinue_label: 'Guardar y Continuar',
  event_processor_selectionAndValidationEvents_label: 'Selección y Validación de Eventos',
  event_processor_automationRules_label: 'Reglas de Automatización',
  event_processor_nameRequired_formErrorLabel: 'Nombre es obligatorio',
  event_processor_sucessRegisteredEventProcessor_toast: 'Procesador de Eventos registrado exitosamente',
  event_processor_errorRegisteringdEventProcessor_toast: 'Error al registrar el procesador de eventos',
  event_processor_description_inputLabel: 'Descripción',
  event_processor_enterDescription_placeholderLabel: 'Ingrese una descripción',
  event_processor_editProcessor_label: 'Editar procesador',
  event_processor_finishTheFisrtSession_toast: 'Finalizar la primera sesión',
  event_processor_selectEventDetails_label: 'Seleccione un evento para ver los detalles',
  event_processor_eventWithoutDetails_label: 'Evento sin detalles',
  event_processor_listEvents_label: 'Listar eventos',
  event_processor_tableColumnTitleField_label: 'Campo',
  event_processor_tableColumnTitleValue_label: 'Valor',
  event_processor_tableColumnTitleAction_label: 'Acción',
  event_processor_addInProcessor_label: 'Agregar al procesador',
  event_processor_pageTitleSelectAnEvent_label: 'Seleccione un evento',
  event_processor_selectedEvent_label: 'Evento seleccionado',
  event_processor_selectEvent_label: 'Seleccionar evento',
  event_processor_createEventEntryConditions_label: 'Crear condiciones de entrada del evento',
  event_processor_selectingFieldAddConditions_label: 'Al seleccionar un campo, agregar las condiciones a las que debe someterse.',
  event_processor_conditionAddedToProcessor_toast: 'Condición agregada al procesador',
  event_processor_errorAddingConditionInProcessor_toast: 'Error al agregar la condición al procesador',
  event_processor_eventProcessorUpdatedSuccess_toast: 'Procesador de Eventos actualizado exitosamente',
  event_processor_errorUpdatingEventProcessor_toast: 'Error al actualizar el Procesador de Eventos',
  event_processor_errorFetchingCondition_toast: 'Error al obtener la condición',
  event_processor_errorFetchingConditions_toast: 'Error al obtener las condiciones',
  event_processor_errorFectingEventProcessor_toast: 'Error al obtener el procesador de eventos',
  event_processor_conditionRemovedSuccess_toast: 'Condición eliminada exitosamente',
  event_processor_errorRemovingCondition_toast: 'Error al eliminar la condición',
  event_processor_conditionSuccessUpdated_toast: 'Condición actualizada exitosamente',
  event_processor_errorUpdatingCondition_toast: 'Error al actualizar la condición',
  event_processor_uniqueConditionsMessage_label: 'Algunas condiciones de entrada ya están siendo procesadas por uno o más procesadores de eventos existentes.',
  event_processor_processorWithSimilarConditions_label: [
    'Procesador con condiciones similares',
    'Procesadores con condiciones similares'
  ],
  event_processor_eventServicedByProcessor_label: [
    'Evento atendido por el procesador',
    'Eventos atendidos por el procesador'
  ],
  event_processor_matchedEventsSubtitle_label: 'Para listar los eventos procesados, debe registrar al menos 1 condición de entrada.',
  event_processor_doesNotHaveEventsServicedByProcessor_label: 'No tiene eventos atendidos por el procesador',
  event_processor_errorFetchingMatchingEvents_toast: 'Error al buscar eventos coincidentes',
  event_processor_erroFetchingUniqueConditions_toast: 'Error al buscar condiciones únicas',
  event_processor_tableColumnTitleOperationType_label: 'Tipo de Operación',
  event_processor_tableColumnTitleDate_label: 'Fecha',
  event_processor_tableColumnTitleDestinationAccount_label: 'Cuenta de Destino',
  event_processor_registeredAutomationRules_label: 'Reglas de Automatización Registradas',
  event_processor_tableColumnTitleRealeaseDate_label: 'Fecha de Lanzamiento',
  event_processor_automationRulesSimulator_label: 'Simulador de Reglas de Automatización',
  event_processor_theAutomationRulesAre_label: 'Las reglas de automatización son',
  event_processor_matchedEventsTiteInAutomationRules_label: 'Para crear las reglas de automatización, seleccione el evento',
  event_processor_errorFetchingAutomationRules_toast: 'Error al obtener las reglas de automatización',
  event_processor_errorWhenFetchingSimulationsAutomationRules_toast: 'Error al obtener simulaciones de reglas de automatización',
  event_processor_errorRemovingAutomationRule_toast: 'Error al eliminar la regla de automatización',
  event_processor_automationRuleSuccessRemoved_toast: 'Regla de automatización eliminada con éxito',
  event_processor_action_inputLabel: 'Acción',
  event_processor_selectTheAction_placeholderLabel: 'Seleccione la acción',
  event_processor_value_inputLabel: 'Valor',
  event_processor_enterTheValue_placeholderLabel: 'Ingrese el valor',
  event_processor_for_inputLabel: 'Para',
  event_processor_selectTheComparator_placeholderLabel: 'Seleccione el comparador',
  event_processor_operationType_inputLabel: 'Tipo de operación',
  event_processor_selectTheTypeOfOperation_placeholderLabel: 'Seleccione el tipo de operación',
  event_processor_date_inputLabel: 'Fecha',
  event_processsor_enterTheDateFormula_placeholderLabel: 'Ingrese la fórmula de la fecha',
  event_processor_referenceDate_inputLabel: 'Fecha de referencia',
  event_processor_enterTheReferenceDateFormula_placeholderLabel: 'Ingrese la fórmula de la fecha de referencia',
  event_processor_account_inputLabel: 'Cuenta',
  event_processor_selectTheAccount_placeholderLabel: 'Seleccione la cuenta',
  event_processor_accountViaFormula_inputLabel: 'Cuenta a través de fórmula',
  event_processor_selectTheAccountFormula_placeholderLabel: 'Seleccione la fórmula de la cuenta',
  event_processor_installmentDate_label: 'Crea las fechas para los plazos según las reglas de la bandera de la tarjeta de crédito',
  event_processor_installmentValue_label: 'Redondea el valor del plazo según las reglas de redondeo de la pasarela (operador)',
  event_processor_installmentFeeValue_label: 'Redondea el valor de la tarifa del plazo según las reglas de redondeo de la pasarela (operador)',
  event_processor_getBankAccount_label: 'Devuelve la cuenta bancaria a ser movida del campo del evento',
  event_processor_getAccountFromExpenseRevenue_label: 'Devuelve la cuenta asociada al plan de cuentas (si lo hay) del Tipo de Gasto/Ingreso informado en el campo del evento',
  event_processor_binamikFee_label: 'Calcula el valor total de la tarifa de Binamik para la venta y/o devolución',
  event_processor_fromCents_label: 'Convierte un valor en centavos a monetario (divide por 100), ej: from_cents(450) = 4.50',
  event_processor_refundDate_label: "Calcula la fecha de reembolso (día hábil) según las reglas del Operador y de la bandera de la Tarjeta de Crédito. Esta fórmula solo se puede usar en las acciones 'Crear Registro Futuro a través de fórmula para reembolso en Tarjeta de Crédito' y 'Adelantar plazos para reembolso en Tarjeta de Crédito'.",
  event_processor_toDate_label: 'Convierte event_field en una fecha, si es posible',
  event_processor_durationOneMonth_label: 'Duración de 1 mes, considera el número de días del mes',
  event_processor_durationTwentySixDays_label: 'Duración de 26 días',
  event_processor_nextBusinessDays_label: 'Devuelve el siguiente día hábil calculado, si no es un día hábil',
  event_processor_nextBusinessDaysOneMoreMonth_label: 'Día hábil de: fecha en event_field más un mes',
  event_processor_operationIfElse_label: 'SI inc < event_field la siguiente fórmula devuelve event_field_2, SI NO devuelve round(event_field_2)',
  event_processor_roundValue_label: 'Redondea el valor de event_field',
  event_processor_roundValueWithTwoDecimalPlaces_label: 'Redondeo con 2 decimales',
  event_processor_roundValueUp_label: 'Redondea hacia arriba (entero siguiente)',
  event_processor_roundValueDown_label: 'Redondea hacia abajo (entero anterior)',
  event_processor_allowedFormulas_label: 'Fórmulas permitidas',
  event_processor_description_label: 'Descripción',
  event_processor_formula_label: 'Fórmula',
  event_processor_operation_label: 'Operación',
  event_processor_newAutomationRule_label: 'Nueva regla de automatización',
  event_processor_pageTitleEditingAutomationRule_label: 'Editando regla de automatización',
  event_processor_pageTitleNewAutomationRule_label: 'Nueva regla de automatización',
  event_processor_rules_label: 'Reglas',
  event_processor_fieldOfAllowedEvents_label: 'Campo de eventos permitidos',
  event_processor_automationRuleActionRequired_formErrorLabel: 'La acción es obligatoria',
  event_processor_operationTypeRequired_formErrorLabel: 'El tipo de operación es obligatorio',
  event_processor_dateWithFormulaRequired_formErrorLabel: 'La fecha es obligatoria',
  event_processor_referenceDateWithFormulaRequired_formErrorLabel: 'La fecha de referencia es obligatoria',
  event_processor_automationRuleStatusRequired_formErrorLabel: 'El estado es obligatorio',
  event_processor_accountIdRequired_formErrorLabel: 'La cuenta es obligatoria',
  event_processor_accountFormulaRequired_formErrorLabel: 'La cuenta a través de fórmula es obligatoria',
  event_processor_valueRequired_formErrorLabel: 'El valor es obligatorio',
  event_processor_automationRuleCreatedSuccessfully_toast: 'Regla de automatización creada exitosamente',
  event_processor_errorCreatingAutomationRule_toast: 'Error al crear la regla de automatización',
  event_processor_errorFetchingAutomationRuleActions_toast: 'Error al obtener las acciones de la regla de automatización',
  event_processor_errorFetchingAccountFormulasForAutomationRule_toast: 'Error al obtener las fórmulas de las cuentas para la regla de automatización',
  event_processor_errorFetchingAccountsForAutomationRule_toast: 'Error al obtener las cuentas para la regla de automatización',
  event_processor_automationRuleUpdatedSuccessfully_toast: 'Regla de automatización actualizada exitosamente',
  event_processor_errorUpdatingAutomationRule_toast: 'Error al actualizar la regla de automatización',
  event_processor_errorFetchingAutomationRule_toast: 'Error al obtener la regla de automatización',
  event_processor_uniqueConditionsEntryConditionsNotUnique_toast: 'No se puede activar el procesador, ya que las condiciones de entrada no son únicas',
  event_processor_simulatonsInvalidAutomationRules_toast: 'No se puede activar el procesador, hay reglas de automatización inválidas',
  event_processor_conditionsNoEntryConditions_toast: 'No se puede activar el procesador, no hay condiciones de entrada',
  event_processor_cashFlow_label: 'Flujo de efectivo',
  event_processor_iterationField_inputLabel: 'Campo de iteración',
  event_processor_enterTheIterationField_placeholderLabel: 'Ingrese el campo de iteración',
  event_processor_descriptionRequired_formErrorLabel: 'Descripción requerida',
  event_processor_automationRuleAccountIdIsMissing_toast: 'Account is required',
  event_processor_automationRuleAccountFormulaIsMissing_toast: 'Account via formula is required',
  // options account formulas for automation rule
  event_processor_getEventBankAccount_label: 'Cuenta Bancaria del Evento',
  event_processor_getAccountablePlanFromBankAccount_label: 'Plan de Cuentas de la Cuenta Bancaria del Evento',
  event_processor_getAccountblePlanFromEventRevenue_label: 'Plan de Cuentas del Tipo de Ingreso del Evento',
  event_processor_getAccountblePlanFromEventExpense_label: 'Plan de Cuentas del Tipo de Gasto del Evento',
  // automation rule status
  event_processor_automationRuleWaitingStatus_label: 'Esperando fecha de registro futuro',
  event_processor_automationRuleNeedConfirmationStatus_label: 'Necesita confirmación',
  event_processor_automationRuleProcessedStatus_label: 'Procesado',
  event_processor_automationRuleBilledStatus_label: 'Facturado',
  event_processor_automationRuleCanceledStatus_label: 'Cancelado',
  event_processor_automationRuleDoneStatus_label: 'Realizado',
  // comparion_type
  event_processor_equal_label: 'Igual a',
  event_processor_lessThan_label: 'Menor que',
  event_processor_greaterThan_label: 'Mayor que',
  event_processor_in_label: 'Uno de',
  event_processor_notEqual_label: 'Diferente de',
  event_processor_notIn_label: 'Ninguno de',
  // actions
  event_processor_moveAccountForecastWithFormula_label: 'Crear Registro Futuro',
  event_processor_moveAccountWithFormula_label: 'Crear Movimiento de Cuenta',
  event_processor_moveAccountForecastWithFormulaForRefund_label: 'Crear Registro Futuro para Devolución de Tarjeta de Crédito',
  event_processor_updateAccountForecastWithFormula_label: 'Actualizar Estado del Registro Futuro',
  event_processor_updateAccountMovement_label: 'Actualizar Estado del Movimiento de Cuenta',
  event_processor_accelerateInstallmentsForRefund_label: 'Adelantar plazos para devolución de Tarjeta de Crédito',
  event_processor_updateTicketStatus_label: 'Actualizar Estado de la Entrada',
  event_processor_analyzeInvoicePartialPayment_label: 'Analizar pago parcial de Factura',
  event_processor_createUpdateBankAccount_label: 'Crear/Actualizar Cuenta Bancaria',
  event_processor_moveBankAccount_label: 'Registrar movimiento en Cuenta Digital',
  event_processor_initBilling_label: 'Inicializar Billing para una empresa',
  event_processor_updateBilling_label: 'Actualizar Eventos de modelo y Procesadores',
  event_processor_deleteBilling_label: 'Eliminar Eventos de modelo y Procesadores',

  creditcard_movement_errorLoadingTheFinancialCardsSchedule_toast: 'Error al cargar el programa de tarjetas financieras',
  creditcard_movement_errorWhenExportingFinancialCards_toast: 'Error al exportar el programa de tarjetas financieras',

  flow_movment_errorLoadingCompleteFinancialSchedule_toast: 'Error al cargar el programa financiero completo',
  flow_movment_errorWhenExportingCompleteFinancialSchedule_toast: 'Error al exportar el programa financiero completo',
  flow_movement_date_label: 'Fecha',
  flow_movement_description_label: 'Descripción',
  flow_movement_releaseType_label: 'Tipo de Lanzamiento',
  flow_movement_operation_label: 'Operación',
  flow_movement_value_label: 'Valor',
  flow_movement_status_label: 'Estado',
  flow_movement_totalPayable_label: 'Total a Pagar',
  flow_movement_totalReceivable_label: 'Total a Recibir',
  flow_movement_pageTitleFullFinancialSchedule_label: 'Programa Financiero Completo',
  flow_movement_movements_label: 'Movimientos',
  flow_movement_viewCanceledReleases_label: 'Ver Lanzamientos Cancelados',
  flow_movement_hideCanceledReleases_label: 'Ocultar Lanzamientos Cancelados',
  flow_movement_selectAperiodToViewMovements_label: 'Seleccione un Período para Ver Movimientos',
  flow_movement_searchByKeywordOrDescription_label: 'Buscar por Palabra Clave o Descripción',
  flow_movement_conciliate_label: 'Conciliado',

  creditcard_dashboard_menuTitleCards_label: 'Tarjetas',
  creditcard_dashboard_receivables_label: 'Cuentas por cobrar',
  creditcard_dashboard_creditCardConciliations_label: 'Conciliaciones de Tarjetas de Crédito',

  bank_action_dashboard_titleBankConciliations_label: 'Conciliaciones Bancarias',
  bank_action_dashboard_titleBankStatements_label: 'Extractos Bancarios',
  bank_action_dashboard_menuTitleBankActions_label: 'Acciones Bancarias',

  accounting_function_dashboard_titleAccountingsFunctions_label: 'Funciones Contables',
  accounting_function_dashboard_titleAccountsPlan_label: 'Plan de Cuentas',

  apiCodeMapping_billingUnmapped_toast: 'Error no mapeado en el servidor',
  apiCodeMapping_orbUserUnauthorized_toast: 'Token expirado o no válido, por favor inicie sesión nuevamente en la plataforma',
  apiCodeMapping_internalServerError_toast: 'Error interno del servidor',
  apiCodeMapping_orbUserForbidden_toast: 'No tienes permiso',

  audit_value_label: 'Valor',
  audit_serviceName_label: 'Nombre del servicio',
  audit_paymentMethod_label: 'Tipo de pago',
  audit_date_label: 'Fecha',
  audit_taxes_label: 'Tasas',
  audit_referenceDate_label: 'Fecha de referencia',
  audit_observation_label: 'Observación',
  audit_status_label: 'Estado',
  audit_createdAt_label: 'Fecha de modificación',
  audit_event_label: 'Tipo de modificación',
  audit_userName_label: 'Nombre del usuario',
  audit_paymentDay_label: 'Día de pago',
  audit_enabled_label: 'Estado',
  audit_expenseRevenueType_label: 'Tipo',
  audit_description_label: 'Descripción',
  audit_bankAccountName_label: 'Cuenta bancaria',
  audit_expenseName_label: 'Tipo de gasto',
  audit_revenueName_label: 'Tipo de ingreso',
  audit_accountablePlanName_label: 'Plan de cuentas',
  audit_municipalRegistration_label: 'Registro municipal',
  audit_supplierType_label: 'Tipo de proveedor',
  audit_supplierDocument_label: 'Documento',
  audit_corporateName_label: 'Razón social',
  audit_fancyName_label: 'Nombre comercial',
  audit_address_label: 'Dirección',
  audit_neighborhood_label: 'Barrio',
  audit_state_label: 'Estado',
  audit_country_label: 'País',
  audit_zipCode_label: 'Código postal',
  audit_cityName_label: 'Ciudad',
  audit_emails_label: 'Correos electrónicos',
  audit_phoneNumbers_label: 'Teléfonos',
  audit_contactNames_label: 'Personas de contacto',
  audit_bankName_label: 'Banco',
  audit_branch_label: 'Agencia',
  audit_accountType_label: 'Tipo de cuenta',
  audit_accountNumber_label: 'Cuenta',
  audit_emptyData_toast: 'No tiene historial de cambios',
  audit_pixValue_label: 'PIX',
  audit_bankBilletValue_label: 'Boleto Bancario',
  audit_bankTransferValue_label: 'Transferencia Bancaria',
  audit_creditValue_label: 'Tarjeta de Crédito',
  audit_debitValue_label: 'Tarjeta de Débito',
  audit_cashValue_label: 'Efectivo',
  audit_createValue_label: 'Registro',
  audit_updateValue_label: 'Edición',
  audit_expenseValue_label: 'Gasto',
  audit_revenueValue_label: 'Ingreso',
  audit_savingsAccountValue_label: 'Cuenta de Ahorros',
  audit_checkingAccountValue_label: 'Cuenta Corriente',
  audit_scheduledValue_label: 'Programado',
  audit_canceledValue_label: 'Cancelado',
  audit_payedValue_label: 'Pagado',
  audit_receivedValue_label: 'Recibido',
  audit_refundedValue_label: 'Reembolsado',
  audit_enabledValue_label: 'Activo',
  audit_disabledValue_label: 'Inactivo',
  audit_defaultModalTitle_label: 'Cambia la historia',
  audit_transferMethod_label: 'Método de transferencia',
  audit_originAccount_label: 'Cuenta de origen',
  audit_destinationAccount_label: 'Cuenta de destino',
  audit_invoiceType_label: 'Tipo de Factura',
  audit_guideInvoice_label: 'Factura Guía',
  audit_affiliateInvoice_label: 'Factura de Afiliado',
  audit_affiliatePayment_label: 'Pago de Afiliado',
  audit_offlineInvoice_label: 'Factura Offline',
  audit_offlinePayment_label: 'Pago Offline',
  audit_invoicePayment_label: 'Pago de Factura',
  audit_invoice_label: 'Factura',
  audit_forgottenExpenses_label: 'Cuentas a pagar',
  audit_forgottenRevenues_label: 'Cuentas a recibir',
  audit_maxRecurrences_label: 'Pagos recurrentes',
  audit_expenseRevenueName_label: 'Tipo de gasto del comercio turístico',

  bank_transfer_originAccount_label: 'Cuenta de Origen',
  bank_transfer_destinationAccount_label: 'Cuenta de Destino',
  bank_transfer_noTransfersFoundBetweenBankAccounts_label: 'No se Encontraron Transferencias entre Cuentas Bancarias',
  bank_transfer_transferBetweenBankAccount_label: 'Transferencia entre Cuentas Bancarias',
  bank_transfer_errorWhenSearchingForTransfersBetweenBankAccounts_toast: 'Error al Buscar Transferencias entre Cuentas Bancarias',
  bank_transfer_newTransfer_label: 'Nueva Transferencia',
  bank_transfer_editBankTransfer_label: 'Editar Transferencia Bancaria',
  bank_transfer_registerBankTransfer_label: 'Registrar Transferencia Bancaria',
  bank_transfer_transferBetweenAccountCreatedSuccess_toast: 'Transferencia entre Cuentas creada con éxito',
  bank_transfer_errorCreatingTransferBetweenAccount_toast: 'Error al crear la transferencia entre cuentas',
  bank_transfer_errorUpdatingTransferBetweenAccount_toast: 'Error al actualizar la transferencia entre cuentas',
  bank_transfer_transferBetweenAccountUpdatedSuccess_toast: 'Transferencia entre Cuentas actualizada con éxito',
  bank_transfer_errorUpdatingAccountTransfer_toast: 'Error al actualizar la transferencia de cuenta',
  bank_transfer_transferMethod_label: 'Método de Transferencia',
  bank_transfer_selectTheMethod_label: 'Seleccione el método',
  bank_transfer_transferDate_label: 'Fecha de Transferencia',
  bank_transfer_originAccountRequired_label: 'Cuenta de Origen obligatoria',
  bank_transfer_destinationAccountRequired_label: 'Cuenta de Destino obligatoria',
  bank_transfer_transferMethodRequired_label: 'Método de Transferencia obligatorio',
  bank_transfer_transferDateRequired_label: 'Fecha de Transferencia obligatoria',
  bank_transfer_changeHistoryTransferBetweenAccounts_label: 'Historial de cambios Transferencia entre Cuentas',
  bank_transfer_errorWhenSearchingForTransferHistoryBetweenAccounts_toast: 'Error al buscar historial de transferencia entre cuentas',
  bank_transfer_theSourceAccountMustBeDifferentFromTheTargetAccount_label: 'La cuenta de origen debe ser diferente de la cuenta de destino',
  bank_transfer_transferBetweenAccounts_label: 'Transferencia entre Cuentas',
  bank_transfer_transferSentTo_label: 'Transferencia enviada a',
  bank_transfer_transferReceivedFrom_label: 'Transferencia recibida de',

  expense_revenue_dashboard_accountsToPayAndReceive_label: 'Cuentas por Pagar y por Cobrar',
  expense_revenue_dashboard_accountsToPay_label: 'Cuentas por Pagar',
  expense_revenue_dashboard_accountsReceivable_label: 'Cuentas por Cobrar',
  expense_revenue_dashboad_typeOfExpenseRevenue_label: 'Tipo de Gasto/Ingreso',

  configuration_dashboard_menuTitleConfigurations_label: 'Ajustes',
  configuration_pageTitleConfiguration_label: 'Ajustes',
  configuration_forgottenExpenses_inputLabel: 'Deudas programadas no pagadas en los últimos:',
  configuration_forgottenRevenues_inputLabel: 'Cuentas por cobrar programadas no recibidas en los últimos:',
  configuration_maxRecurrences_inputLabel: 'Número máximo de pagos recurrentes hasta:',
  configuration_expenseTradeId_inputLabel: 'Tipo de gastos del comercio turístico',
  configuration_updatedConfiguration_toast: 'Actualización exitosa de la configuración de la empresa',
  configuration_errorWhenSearchingForConfigurations_toast: 'Error al buscar configuración de empresa',
  configuration_errorUpdatingConfiguration_toast: 'Error al actualizar la configuración de la empresa',
  configuration_forgottenExpensesExceedsLimit_label: 'Se excedió el límite de cuentas por pagar impagas',
  configuration_forgottenRevenuesExceedsLimit_label: 'Límite excedido de Cuentas por Cobrar no recibidas',
  configuration_maxRecurrencesExceedsLimit_label: 'Se superó el límite en el número máximo de Pagos Recurrentes',
  configuration_auditButton_label: 'Cambia la historia',
  configuration_numberOfDaysMustBeGreaterThanOrEqualTo1_label: 'El número de días debe ser mayor o igual a 1',
  configuraiton_numberOfDaysMustBeLessThanOrEqualTo31_label: 'El número de días debe ser menor o igual a 31',

  cash_deposit_errorWhenSearchingForCashDeposits_toast: 'Error al buscar depósitos en efectivo',
  cash_deposit_noCashDepositsFound_label: 'No se encontraron depósitos en efectivo',
  cash_deposit_cashDeposit_label: 'Depósito en Efectivo',
  cash_deposit_newDeposit_label: 'Nuevo Depósito en Efectivo',
  cash_deposit_originAccount_label: 'Cuenta de Origen',
  cash_deposit_destinationAccount_label: 'Cuenta de Destino',
  cash_deposit_dateFormat_formErrorLabel: 'La fecha debe tener el formato dd/mm/aaaa',
  cash_deposit_startDateRequired_formErrorLabel: 'La fecha de inicio es obligatoria',
  cash_deposit_endDateMustBeafterOrEqualStartDate_formErrorLabel: 'La fecha de finalización debe ser posterior o igual a la fecha de inicio'
}
