/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { InfoTooltip } from 'Shared/components/InfoTooltip/InfoTooltip'
import { LabelWithInfoContainer } from './LabelWithInfo.styles'

export const LabelWithInfo = ({ label, title, info }) => {
  return (
    <LabelWithInfoContainer>
      <p className='label__info'>{label}</p>
      <InfoTooltip
        title={title}
        info={info}
      />
    </LabelWithInfoContainer>

  )
}
