
import { useState } from 'react'
import { ExpenseService } from 'Core/Service/expense'
import { useUser } from 'Shared/contexts/User'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesHooksTranslator } from '../i18n/ExpensesHooksI18n'
import { PayloadCreateExpense } from 'Core/Types/Expense'
import { BackendErrorFormat } from 'Core/Types/Error'

interface UseCreateExpenseProps {
  recurrence?: boolean
}

export const useCreateExpense = ({
  recurrence = false
}: UseCreateExpenseProps) => {
  const [loading, setLoading] = useState(false)
  const { user } = useUser()
  const { addToast } = useToast()

  const i18n = useTranslator(ExpensesHooksTranslator)

  const createExpense = async (expenseData: PayloadCreateExpense) => {
    setLoading(true)
    return await ExpenseService.create({ recurrence, ...expenseData }, user)
      .then(() => {
        addToast({
          defaultMessage: i18n?.accountsPayableSuccessfullyRegistered,
          typeToast: 'success'
        })
        return true
      })
      .catch((errors: BackendErrorFormat) => {
        console.error(errors?.data)
        addToast(
          {
            dictTranslation: i18n?.apiCodesMappingForExpense,
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorRegisteringAccountsPayable,
            extra_data: errors?.data?.extra_data
          }
        )
        return false
      })
      .finally(() => setLoading(false))
  }

  return {
    onCreateExpense: createExpense,
    loading
  }
}
