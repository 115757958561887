import { useState, useCallback, useMemo } from 'react'
import { RevenueService } from 'Core/Service/revenue'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { RevenuesHooksTranslator } from '../i18n/RevenuesHooksI18n'
import { apiCodesTranslations } from 'Shared/language/utils/functions'

export const useRevenueAllowedStatus = () => {
  const { addToast } = useToast()
  const [allowedStatus, setAllowedStatus] = useState()

  const i18n = useTranslator(RevenuesHooksTranslator)

  const fetchAllowedStatus = useCallback((expenseId) => {
    return RevenueService.allowedStatus(expenseId)
      .then(setAllowedStatus)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorLoadingAvailableStatus
        })
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const allowedStatusOptions = useMemo(() => {
    if (!allowedStatus) return []

    return allowedStatus.map((status) => {
      const label = apiCodesTranslations(
        {
          apiCode: status,
          codesMapped: i18n?.revenuecodesMapped
        }
      )

      return {
        label,
        value: status
      }
    })
  }, [allowedStatus, i18n?.revenuecodesMapped])

  return {
    allowedStatusOptions,
    fetchAllowedStatus
  }
}
