/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  EventsRequestDetailsContainer as Container
} from './EventsRequestDetails.styles'
import { Button, TableBody, TableBodyCell, TableBodyRow, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow } from '@bonitour/components'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsI18n'
import { EventsTranslator } from 'Domains/Events/i18n/EventsI18n'
import { useGetEventRequestDetails } from 'Domains/Events/hooks/useGetEventRequestDetails'
import { Fragment, useCallback, useEffect, useMemo } from 'react'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { apiCodesTranslations } from 'Shared/language/utils/functions'
import { useHistory } from 'react-router-dom'

/**
 * @typedef EventsRequestDetailsListProps
 * @prop { string } eventProcessorId
 * @prop { string } eventId
 * @prop { (key: string, value: string | boolean | number) => void } onCreateCondition
 */

/** @type { React.FC<EventsRequestDetailsListProps> } */
export const EventsRequestDetailsList = ({
  onCreateCondition,
  eventProcessorId,
  eventId
}) => {
  const i18n = useTranslator(EventsProcessorsTranslator)

  const { push } = useHistory()

  const redirectSelectEvents = useCallback(() => {
    push(`/events/select/${eventProcessorId}${eventId ? `/${eventId}` : ''}`)
  }, [eventId, eventProcessorId, push])

  return (
    <Container>
      <Button
        onClick={redirectSelectEvents}
        className='btn__consult_event'
      >
        {i18n?.listEvents}
      </Button>
      <EventDetailsTable
        i18n={i18n}
        onCreateCondition={onCreateCondition}
        eventId={eventId}
      />
    </Container>
  )
}

/**
 * @typedef EventDetailsTableProps
 * @prop { Record<string, string> } i18n
 * @prop { string } eventId
 * @prop { (key: string, value: string | boolean | number) => void } onCreateCondition
 */

/** @type { React.FC<EventDetailsTableProps> } */
export const EventDetailsTable = (
  {
    i18n,
    onCreateCondition,
    eventId
  }
) => {
  const i18nEvent = useTranslator(EventsTranslator)

  const {
    eventRequestDetails,
    fetchEventRequestDetails,
    loading
  } = useGetEventRequestDetails()

  useEffect(() => {
    if (!eventId || eventRequestDetails) return
    fetchEventRequestDetails(eventId)
  }, [
    eventRequestDetails,
    eventId,
    fetchEventRequestDetails
  ])

  const fallbackMessage = useMemo(() => {
    if (!eventRequestDetails?.length && !loading) {
      return i18n?.selectEventDetails
    } else if (!eventRequestDetails?.length) {
      return i18n?.eventWithoutDetails
    }
  }, [eventRequestDetails?.length,
    i18n?.eventWithoutDetails,
    i18n?.selectEventDetails,
    loading
  ])

  return (
    <div className='container__table'>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>

            {
              i18n?.entryConditionsTableColumnsTitles?.map(
                (item, idx) => (
                  <TableHeaderCell key={idx}>{item}</TableHeaderCell>
                )
              )
            }
          </TableHeaderRow>
        </TableHeader>
        <TableBody
          loading={loading}
          fallbackMessage={fallbackMessage}
        >
          {eventRequestDetails?.map(({ key, value }, idx) => (
            <Fragment key={idx}>
              <TableBodyRow>
                <TableBodyCell>
                  <SafeOverflow
                    text={apiCodesTranslations({
                      apiCode: key,
                      codesMapped: i18nEvent?.eventCodesMapped
                    })}
                  />
                </TableBodyCell>
                <TableBodyCell>
                  <SafeOverflow
                    text={
                      apiCodesTranslations(
                        {
                          apiCode: key,
                          codesMapped: i18nEvent?.eventCodesMapped,
                          value
                        }
                      )
                    }
                  />
                </TableBodyCell>
                <TableBodyCell className='td_btn__add_processor'>
                  <Button
                    onClick={() => onCreateCondition({ key, value })}
                  >
                    {i18n?.addInProcessor}
                  </Button>
                </TableBodyCell>
              </TableBodyRow>
            </Fragment>
          ))}
        </TableBody>
      </TableContainer>
    </div>
  )
}
