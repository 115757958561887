import {
  BANKBILLET,
  BANKTRANSFER,
  CASH,
  CREDIT,
  DEBIT,
  PARTNERSHIPACCOUNTCREDIT,
  PIX
} from './paymentMethodAndOperationType'

export const paymentMethodsOptions = [
  CREDIT,
  DEBIT,
  BANKTRANSFER,
  PIX,
  BANKBILLET,
  CASH,
  PARTNERSHIPACCOUNTCREDIT
]
