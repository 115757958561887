import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParamsFilter } from 'Shared/hooks/useParamsFilter'
import { usePagination } from '@bonitour/app-functions'
import { BankTransfersService } from 'Core/Service/bankTransfers'
import { useToast } from 'Shared/hooks/useToast'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { BankTransfersCommonParams, GetBankTransferParsed } from 'Core/Types/BankTransfer'
import { BankTransfersHooksTranslator } from '../i18n/BankTransfersHooksI18n'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SafeDate } from '@binamik/js-providers'

export interface FetchBankTransfersProps {
  newFilters?: BankTransfersCommonParams
  newPage?: number
  newPerPage?: number
  page?: number
  finallyCallback?: () => void
}

export const useGetBankTransfers = () => {
  const [filters, setFilters] = useParamsFilter<BankTransfersCommonParams>()
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()
  const [bankTransfersData, setBankTransfersData] = useState<GetBankTransferParsed>(
    {
      bankTransfers: [],
      meta: {}
    }
  )

  const {
    initStatePagination,
    newPerPageOptions
  } = usePaginationOptions()

  const defaultAndCurrentFilters = useMemo(() => ({
    ...filters,
    startDate: filters?.startDate || new SafeDate().isoDate,
    endDate: filters?.endDate || new SafeDate().isoDate
  }), [filters])

  const {
    pagination,
    setPagination,
    handleOnPageChange,
    handleOnPerPageChange
  } = usePagination(
    {
      startingPage: initStatePagination.currentPage,
      perPage: initStatePagination.perPage
    }
  )

  const i18n = useTranslator(BankTransfersHooksTranslator)

  const fetchBankTransfers = useCallback(async (
    {
      newFilters,
      newPage,
      newPerPage,
      page,
      finallyCallback
    }: FetchBankTransfersProps = {}
  ) => {
    setLoading(true)
    return await BankTransfersService.getBankTransfers({
      filters: newFilters || defaultAndCurrentFilters,
      pagination: {
        currentPage: newPage || page || pagination.page,
        perPage: newPerPage || pagination.perPage
      }
    }).then((data: GetBankTransferParsed) => {
      setBankTransfersData(data)
      setPagination({
        page: newPage || page || pagination.page,
        perPage: newPerPage || pagination.perPage,
        totalItems: data?.meta?.total || 0,
        totalPages: data?.meta?.totalPages || 0
      })
    }).catch(errors => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorWhenSearchingForTransfersBetweenBankAccounts
      })
    }).finally(() => {
      setLoading(false)
      finallyCallback && finallyCallback()
    })
  }, [
    defaultAndCurrentFilters,
    pagination.page,
    pagination.perPage,
    setPagination,
    addToast,
    i18n?.errorWhenSearchingForTransfersBetweenBankAccounts
  ])

  const handleFiltersChange = useCallback((newFilters: BankTransfersCommonParams) => {
    fetchBankTransfers(
      {
        newFilters,
        newPage: 1,
        finallyCallback: () => setFilters(newFilters)
      }
    )
  }, [fetchBankTransfers, setFilters])

  useEffect(() => {
    if (
      pagination.isActive ||
      (!defaultAndCurrentFilters?.startDate && !defaultAndCurrentFilters?.endDate)
    ) return
    fetchBankTransfers({
      newPage: 1,
      newPerPage: pagination.perPage
    })
  }, [
    defaultAndCurrentFilters?.endDate,
    defaultAndCurrentFilters?.startDate,
    fetchBankTransfers, filters,
    pagination.isActive,
    pagination.perPage
  ])

  return {
    filters: defaultAndCurrentFilters,
    handleFiltersChange,
    handleOnPageChange: handleOnPageChange(fetchBankTransfers),
    handleOnPerPageChange: handleOnPerPageChange(fetchBankTransfers),
    pagination,
    bankTransfers: bankTransfersData?.bankTransfers,
    loading,
    newPerPageOptions,
    fetchBankTransfers
  }
}
