/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory, NavLink } from 'react-router-dom'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { CardButton } from 'Shared/components/TitleMenus/CardButton'
import {
  ExpenseRevenueDashboardContainer,
  labelCardButton
} from './ExpenseRevenueDashboard.style'
import {
  Label,
  HandshakeIcon
} from '@bonitour/components'
import expensesIcon from 'Shared/icons/expensesIcon.png'
import revenuesIcon from 'Shared/icons/revenuesIcon.png'
import expenseRevenueTypeIcon from 'Shared/icons/expenseRevenueTypeIcon.png'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpenseRevenueDashboardTranslator } from './i18n/ExpenseRevenueDashboardI18n'
import { CustomFeatureEnabler } from 'Shared/components/CustomFeatureEnabler/CustomFeatureEnabler'

export const ExpenseRevenueDashboard = () => {
  const { push } = useHistory()
  const i18n = useTranslator(ExpenseRevenueDashboardTranslator)
  const onReturnToDashboard = () => push('/dashboard')

  return (
    <>
      <PageTitle
        title={i18n?.accountsToPayAndReceive}
        onClickReturn={onReturnToDashboard}
      />
      <ExpenseRevenueDashboardContainer>
        <NavLink to='/expense-revenue-dashboard/expenses'>
          <CardButton>
            <img className='card-img' src={expensesIcon} />
            <Label css={labelCardButton}>{i18n?.accountsToPay}</Label>
          </CardButton>
        </NavLink>
        <NavLink to='/expense-revenue-dashboard/revenues'>
          <CardButton>
            <img className='card-img' src={revenuesIcon} />
            <Label css={labelCardButton}>{i18n?.accountsReceivable}</Label>
          </CardButton>
        </NavLink>
        <NavLink to='/expense-revenue-dashboard/expense-revenue-type'>
          <CardButton>
            <img className='card-img' src={expenseRevenueTypeIcon} />
            <Label css={labelCardButton}>{i18n?.typeOfExpenseRevenue}</Label>
          </CardButton>
        </NavLink>
        <CustomFeatureEnabler featureKey='billing-suppliers'>
          <NavLink to='/expense-revenue-dashboard/suppliers'>
            <CardButton>
              <HandshakeIcon className='card-icon' />
              <Label css={labelCardButton}>{i18n.pageTitleSuppliers}</Label>
            </CardButton>
          </NavLink>
        </CustomFeatureEnabler>
      </ExpenseRevenueDashboardContainer>
    </>
  )
}
