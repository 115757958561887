/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback } from 'react'
import { jsx, css } from '@emotion/core'
import { Button, GhostButton, Column, Input, InputFormGroup, Modal, Row, Select, useToast, LoadingAnimation } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { RegisterEspenseRevenueSchema } from './RegisterEspenseRevenue.schema'
import { defineChangeModalTitle, expenseRevenueTypeSelectOptions, expenseRevenueTypeStatusSelectOptions } from '../constants/expenseRevenueType'
import { loadingContainer, customSelect } from 'Shared/styles/global'
import { selectAccountsPlan } from '../ExpenseRevenueType.styles'

const ghostButton = css`
  margin: 0 1em 0 12px;
`

const marginTop = css`
  margin-top: 6em;
`

export const RegisterExpenseRevenuesModal = ({
  isVisible = false,
  toggleHidden,
  expenseRevenueId,
  defaultValues,
  onEditExpenseRevenue,
  onCreateExpenseRevenue,
  filters,
  pagination,
  loading,
  accountsPlanOptions
}) => {
  const onEdit = !!expenseRevenueId
  const { add: addToast } = useToast()

  const buttonTitle = onEdit ? 'Salvar' : 'Cadastrar'

  const onValidationError = useCallback(() => {
    addToast('Preencha corretamente o formulário')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(defaultValues, RegisterEspenseRevenueSchema)

  const { description, expenseRevenueType, enabled, accountPlanId } = form

  const onValidationEditSuccess = useCallback((data) => {
    onEditExpenseRevenue(data, filters, pagination)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onValidationCreateSuccess = useCallback((data) => {
    onCreateExpenseRevenue(data, filters, pagination)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmitEdit = onSubmit(onValidationEditSuccess, onValidationError)

  const handleSubmitCreate = onSubmit(onValidationCreateSuccess, onValidationError)

  return (
    <Modal
      title={defineChangeModalTitle[onEdit]}
      isVisible={isVisible}
      onCloseClick={toggleHidden}
    >
      {loading
        ? (
          <LoadingAnimation css={loadingContainer} />
        )
        : (
          <>
            <Row>
              <Column desktop={6} phone={12}>
                <InputFormGroup label='Descrição' errorMessage={errors.description}>
                  <Input
                    id='description'
                    value={description || ''}
                    onChange={onInputChange('description')}
                    onBlur={onInputBlur('description')}
                    error={errors.description}
                    placeholder='Insira a descrição'
                  />
                </InputFormGroup>
              </Column>
              <Column desktop={3} phone={12}>
                <InputFormGroup label='Tipo' errorMessage={errors.expenseRevenueType}>
                  <Select
                    id='expense-revenue-type'
                    options={expenseRevenueTypeSelectOptions}
                    value={expenseRevenueType}
                    onChange={onInputChange('expenseRevenueType')}
                    error={errors.expenseRevenueType}
                    placeholder='Selecione um tipo'
                  />
                </InputFormGroup>
              </Column>
              <Column desktop={3} phone={12}>
                <InputFormGroup label='Status' errorMessage={errors.enabled}>
                  <Select
                    id='enabled'
                    options={expenseRevenueTypeStatusSelectOptions}
                    value={enabled}
                    onChange={onInputChange('enabled')}
                    error={errors.enabled}
                    placeholder='Selecione um status'
                  />
                </InputFormGroup>
              </Column>

              <Column desktop={3} phone={12}>
                <InputFormGroup label='Plano de Contas' errorMessage={errors.accountPlanId}>
                  <Select
                    id='accountPlanId'
                    options={accountsPlanOptions}
                    value={accountPlanId}
                    onChange={onInputChange('accountPlanId')}
                    error={errors.accountPlanId}
                    placeholder='Selecione um plano de conta'
                    customCss={[customSelect, selectAccountsPlan]}
                  />
                </InputFormGroup>
              </Column>
            </Row>
            <Row css={marginTop}>
              <GhostButton onClick={toggleHidden} css={ghostButton}>Cancelar</GhostButton>
              <Button onClick={onEdit ? handleSubmitEdit : handleSubmitCreate}>{buttonTitle}</Button>
            </Row>
          </>
        )}
    </Modal>
  )
}
