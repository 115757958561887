import { useMemo } from 'react'
import { useForm } from '@bonitour/app-functions'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import {
  getSchema,
  date,
  originBankAccountRequired,
  destinationBankAccountRequired,
  taxe,
  observation,
  transferMethod,
  statusRequired,
  value,
  userName,
  createdAt
} from '../../Form/__BankTransfersBaseInputs'
import { useBankAccountsOptions } from 'Domains/BankAction/BankAccounts/hooks/useBankAccountsOptions'
import { BankTransfersInputsTranslator } from '../../i18n/BankTransfersInputsI8n'
import { BankTransferParsed } from 'Core/Types/BankTransfer'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'
import { bankTransferStatus } from '../../Constants/bankTransferStatus'

interface BankTransferInputs {
  bankTransferData: BankTransferParsed,
  isEdit: boolean
}

export const useBankTransferInputs = (
  {
    bankTransferData,
    isEdit
  }: BankTransferInputs
) => {
  const {
    bankAccountsOptions
  } = useBankAccountsOptions()

  const inputsVals = useTranslatedInputs([
    date,
    originBankAccountRequired(bankAccountsOptions),
    destinationBankAccountRequired(bankAccountsOptions),
    transferMethod,
    value,
    taxe,
    statusRequired,
    observation,
    userName,
    createdAt
  ], BankTransfersInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsVals),
    [inputsVals]
  )

  const currentFormData = useMemo(
    () => (bankTransferData || { statusRequired: bankTransferStatus.done }
    ),
    [bankTransferData]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(currentFormData, schema)

  const formErrorsTranslteds = useTranslatedFormErrors(
    errors, BankTransfersInputsTranslator
  )

  const filteredInputAndOptions = useMemo(() => {
    return inputsVals.reduce((acc, input) => {
      if ((input.value === 'userName' || input.value === 'createdAt') && !isEdit) {
        return acc
      }

      if (input.value === 'statusRequired' && !isEdit) {
        acc.push({
          ...input,
          options: input.options?.filter(({ value }) => {
            return [bankTransferStatus.done].includes(value)
          }),
          disabled: true
        })
      } else {
        acc.push({ ...input })
      }

      return acc
    }, [])
  }, [inputsVals, isEdit])

  return {
    errors: formErrorsTranslteds,
    form,
    onInputChange,
    onInputBlur,
    inputsValid: filteredInputAndOptions,
    onSubmit
  }
}
