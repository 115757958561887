import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SupplierBankAccountService } from 'Core/Service/SupplierBankAccount'
import { useQueryClient } from 'react-query'
import { SupplierBankAccountsHooksTranslator } from '../i18n/SupplierBankAccountsHooksI18n'

export const useEditSupplierBankAccount = () => {
  const [editLoading, setEditLoading] = useState(false)
  const [supplierBankAccount, setSupplierBankAccount] = useState()
  const { addToast } = useToast()
  const i18n = useTranslator(SupplierBankAccountsHooksTranslator)
  const queryClient = useQueryClient()

  const fetchSupplierBankAccount = useCallback((supplierId, supplierBankAccountId) => {
    setEditLoading(true)
    SupplierBankAccountService.get({ supplierId, supplierBankAccountId })
      .then(setSupplierBankAccount)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorGetByIdSupplier
        })
      }).finally(() => { setEditLoading(false) })
  }, [addToast, i18n])

  const editSupplierBankAccount = useCallback((params, supplierId) => {
    const { id: supplierBankAccountId } = params
    setEditLoading(true)
    return SupplierBankAccountService.update(supplierId, params, supplierBankAccountId).then(() => {
      queryClient.invalidateQueries({
        queryKey: ['supplierBankAccountList', supplierId]
      })
      addToast({
        defaultMessage: i18n?.editSupplierBankAccount,
        typeToast: 'success'
      })
    }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorEditSupplierBankAccount
      })
    }).finally(() => {
      setEditLoading(false)
    })
  }, [
    addToast,
    i18n?.editSupplierBankAccount,
    i18n?.errorEditSupplierBankAccount,
    queryClient
  ])

  return {
    onEditSupplierBankAccount: editSupplierBankAccount,
    editLoading,
    supplierBankAccount,
    fetchSupplierBankAccount,
    setSupplierBankAccount
  }
}
