import { useMemo } from 'react'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import {
  endDate,
  getSchema,
  startDate,
  expenseRevenuesNotRequired,
  suppliers,
  serviceNameNotRequired,
  multipleStatus,
  bankAccountsWithAll,
  multiSelectPaymentMethods
} from '../../Form/__BaseInputs'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'
import { ExpensesInputsTranslator } from '../../Expenses/i18n/ExpensesInputsI18n'
import { ExpensesCommonParams } from 'Core/Types/Expense'
import { useForm } from '@bonitour/app-functions'
import { RevenueInputsTranslator } from 'Domains/ExpenseRevenue/Revenues/i18n/RevenueInputsI18n'

export interface UseExpensesRevenuesFiltersProps {
  filters: ExpensesCommonParams
  expenseRevenuesOptions: { label: string, value: string }[]
  suppliersOptions: { label: string, value: string }[]
  statusOptions: { label: string, value: string }[]
  bankAccountsOptions?: { label: string, value: string }[]
  typeExpenseOrRevenueLabel: string
  isExpense: boolean
}

export const useExpensesRevenuesFilters = (
  {
    filters,
    expenseRevenuesOptions,
    suppliersOptions,
    statusOptions,
    typeExpenseOrRevenueLabel,
    bankAccountsOptions,
    isExpense = false
  }: UseExpensesRevenuesFiltersProps
) => {
  const inputsVals = useTranslatedInputs([
    startDate,
    endDate,
    serviceNameNotRequired,
    multipleStatus(statusOptions),
    expenseRevenuesNotRequired(typeExpenseOrRevenueLabel, expenseRevenuesOptions),
    suppliers(suppliersOptions),
    bankAccountsWithAll(bankAccountsOptions),
    multiSelectPaymentMethods
  ], isExpense ? ExpensesInputsTranslator : RevenueInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsVals),
    [inputsVals]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(filters, schema)

  const inputsFiltereds = useMemo(() => {
    return inputsVals?.filter(({ value }) => {
      switch (value) {
        case 'supplierId':
        case 'bankAccountId':
        case 'paymentMethods':
          return isExpense
        default:
          return true
      }
    })
  }, [inputsVals, isExpense])

  const formErrorsTranslteds = useTranslatedFormErrors(
    errors, ExpensesInputsTranslator
  )

  return {
    errors: formErrorsTranslteds,
    form,
    onInputChange,
    onInputBlur,
    inputsValid: inputsFiltereds,
    onSubmit
  }
}
