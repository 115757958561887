/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, H4, Dialog, Row, flexCenteredSpaceBetweenRow } from '@bonitour/components'
import { useListMoves } from './hooks/useListMoves'
import { DateUtils } from './utils/DateUtils'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { FinancialMovements } from './FinancialMovements/FinancialMovements'
import { FinancialScheduleFilters } from './Filter/FinancialScheduleFilters'
import { width100, fontWeight, partnerItem } from './FinancialSchedule.styles'
import { FinancialScheduleTableBody } from './FinancialMovements/FinancialScheduleTableBody'
import { MovementsCard } from './MovementsCard/MovementsCard'

export const FinancialSchedule = () => {
  const {
    onSearch,
    handlePagination,
    meta,
    loading,
    moveData,
    moveFilters,
    perPageOptions,
    pagination
  } = useListMoves()

  const { push } = useHistory()
  const { moves } = moveData ?? []
  const [isVisible, setVisibility] = useState(false)
  const [modalData, setModalData] = useState()

  const hasDate = useMemo(
    () => Boolean(moveFilters.startDate && moveFilters.endDate), [moveFilters.endDate, moveFilters.startDate]
  )
  const toggleVisibleModal = useCallback(() => {
    setVisibility(!isVisible)
  }, [isVisible])

  const returnToDashboard = () => push('/dashboard')

  const columns = ['Data', 'Reserva', 'Parceiro', 'Operação', 'AUT', 'DOC', 'Valor', '']

  const tableBody = useCallback(() => {
    return (
      <FinancialScheduleTableBody
        data={moves}
        loading={loading}
        toggleVisibleModal={toggleVisibleModal}
        setModalData={setModalData}
      />
    )
  }
  , [loading, moves, toggleVisibleModal])

  return (
    <>
      <Dialog title='Parceiro' isVisible={isVisible} onClose={toggleVisibleModal}>
        {Array.isArray(modalData)
          ? modalData.map((item) => <p css={partnerItem} key={item}>{item}</p>)
          : <p css={partnerItem}>{modalData}</p>}
      </Dialog>
      <PageTitle title='Agenda Financeira Cartões' onClickReturn={returnToDashboard} />
      <MovementsCard
        balanceMoves={meta}
      />
      <Card>
        <Row css={flexCenteredSpaceBetweenRow}>
          <H4 css={fontWeight}>
            {
              `Movimentações 
                ${DateUtils.getTextBetweenDates(moveFilters?.startDate, moveFilters?.endDate)} 
                ${DateUtils.getDateMoves(moveFilters?.startDate, moveFilters?.endDate)}
              `
            }
          </H4>
        </Row>
        <FinancialScheduleFilters
          onSearch={onSearch}
          initialMoveFilters={moveFilters}
        />
        <div css={width100}>
          <FinancialMovements
            hasDate={hasDate}
            handlePagination={handlePagination}
            meta={meta}
            columns={columns}
            perPageOptions={perPageOptions}
            pagination={pagination}
          >
            {tableBody()}
          </FinancialMovements>
        </div>
      </Card>
    </>
  )
}
