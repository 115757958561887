import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const containerContent = css`
  display: flex;
  flex-direction: column;
  justify-self: center;
`

export const labelStyle = (size, color, top, bottom, left, right) => css`
  color: ${color};
  font-size: ${size}px;
  margin-bottom: ${bottom}px;
  margin-top: ${top}px;
  margin-left: ${left}px;
  margin-right: ${right}px;
`

export const bold = css`
  font-weight: bold;
`

export const border = css`
  border: 2px dashed ${colors.gray8};
  border-radius: 8px;
  padding: 25px;
  width: 300px;
`

export const iconStyle = (size, color) => css`
  cursor: pointer;
  font-size: ${size}px;
  color: ${color};
`

export const marginTop10 = css`
  margin-top: 10px;
`

export const marginBottom40 = css`
  margin-bottom: 40px;
`

export const marginRight20 = css`
  margin-right: 20px;
`

export const paddingHorizontal = css`
  padding: 10px 40px;
`

export const width60 = css`
  width: 60%;
`

export const height10 = css`
  height: 4px;
`
