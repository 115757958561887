
export const ToastTranslator = (dictionary) => {
  const billingApiCodesMappingCommons = {
    'billing::unmapped::unmapped_error': dictionary?.apiCodeMapping_billingUnmapped_toast,
    'billing::user::unauthorized': dictionary?.apiCodeMapping_orbUserUnauthorized_toast,
    'billing::user::forbidden': dictionary?.apiCodeMapping_orbUserForbidden_toast,
    'billing::server::internal_server_error': dictionary?.apiCodeMapping_internalServerError_toast
  }

  return {
    billingApiCodesMappingCommons
  }
}
