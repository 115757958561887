
export const RevenuesHooksTranslator = (
  dictionary
) => {
  const revenuecodesMapped = {
    scheduled: dictionary?.revenue_statusScheduled_label,
    canceled: dictionary?.revenue_statusCanceled_label,
    refunded: dictionary?.revenue_statusRefunded_label,
    received: dictionary?.revenue_statusReceived_label
  }

  const accountsReceivableSuccessfullyRegistered = dictionary?.revenue_accountsReceivableSuccessfullyRegistered_toast
  const errorRegisteringAccountsReceivable = dictionary?.revenue_errorRegisteringAccountsReceivable_toast
  const accountsReceivableUpdatedSuccessfully = dictionary?.revenue_accountsReceivableUpdatedSuccessfully_toast
  const errorUpdatingAccountsReceivable = dictionary?.revenue_errorUpdatingAccountsReceivable_toast
  const errorFetchingAccountsReceivable = dictionary?.revenue_errorFetchingAccountsReceivable_toast

  const apiCodesMappingForRevenue = {
    'revenue::date::only_be_received_present': dictionary?.revenue_dateOnlyByReceivedPresent_toast,
    'revenue::date::only_be_scheduled_future': dictionary?.revenue_dateOnlyBeScheduledFuture_toast
  }

  const errorLoadingAccountsReceivableHistory = dictionary?.revenue_errorLoadingAccountsReceivableHistory_toast
  const errorLoadingAccountsReceivable = dictionary?.revenue_errorLoadingAccountsReceivable_toast
  const errorLoadingAvailableStatus = dictionary?.revenue_errorLoadingAvailableStatus_toast
  const errorLoadingForgottenReceivables = dictionary?.revenue_errorLoadingForgottenReceivables_toast

  return {
    revenuecodesMapped,
    accountsReceivableSuccessfullyRegistered,
    errorRegisteringAccountsReceivable,
    accountsReceivableUpdatedSuccessfully,
    errorUpdatingAccountsReceivable,
    errorFetchingAccountsReceivable,
    apiCodesMappingForRevenue,
    errorLoadingAccountsReceivableHistory,
    errorLoadingAccountsReceivable,
    errorLoadingAvailableStatus,
    errorLoadingForgottenReceivables
  }
}
