/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useMemo, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { SimpleTooltip } from 'Shared/components/Tooltip/index'

import { useOnClickOutside } from 'Shared/utils/utilityHooks/useOnClickOutside'
import { identity, preventPropagation } from 'Shared/utils/functions'
import { keyboardSelectHandler } from 'Shared/utils/handlers/keyboardSelectHandler'

import {
  MultiSelectorButton,
  SelectContainer,
  SelectControl,
  SelectOptionsContainer
} from './styles'
import { AngleDownIcon, CheckIcon } from '@bonitour/components'
import { FilterSelect } from './FilterSelect'
import { OptionButtonAnimation } from './Constants/Select'
import { normalizeString } from 'Shared/utils/string/normalizeString'

/**
 * @typedef MultiSelectProps
 * @prop { Path<T> } name
 * @prop { string } label
 * @prop { string[] } value
 * @prop { boolean } disabled
 * @prop { string } className
 * @prop { name } string
 * @prop {{value: string, label: string}[]} options
 * @prop { boolean } required
 * @prop { (value: string[]) => void } onChange
 * @prop { FieldError } error
 * @prop { 'top' | 'bottom' } yPlacement
 * @prop { string } selectAllLabel
 * @prop { boolean } hasSelectAll
 * @prop { boolean } withFilter
 */

/** @type { React.FC<MultiSelectProps> } */
export const MultiSelect = ({
  name,
  label,
  error = null,
  disabled: isDisabled = false,
  value = [],
  required,
  className = '',
  options = [],
  yPlacement = 'bottom',
  hasSelectAll = false,
  selectAllLabel = 'Todos',
  withFilter = false,
  onChange = _v => console.error('onChange not implemented'),
  placeholder = 'Selecione'
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const multiSelectRef = useRef(null)

  const toggleSelectMenu = useCallback(() => {
    setIsOpen(currState => {
      if (!currState) {
        setTimeout(() =>
          multiSelectRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        )
      }
      return !currState
    })
  }, [multiSelectRef])

  const handleCloseSelectMenu = useCallback(() => {
    setIsOpen(false)
  }, [])

  const currentlySelected = useMemo(() => {
    return options
      .filter(option => value.includes(option.value))
      .map(option => option.value)
  }, [options, value])

  const checkIfIsSelected = useCallback(
    (option) => {
      return currentlySelected.includes(option)
    },
    [currentlySelected]
  )

  const handleSelectOption = useCallback(
    (e, optionValue) => {
      e.stopPropagation()

      const newValue = currentlySelected.includes(optionValue)
        ? currentlySelected.filter(item => item !== optionValue)
        : [...currentlySelected, optionValue]

      onChange(newValue)
    },
    [currentlySelected, onChange]
  )

  const handleSelectAll = useCallback(
    (e) => {
      e.stopPropagation()

      const areAllSelected = currentlySelected.length === options.length

      const newValue = areAllSelected ? [] : options.map(option => option.value)

      onChange(newValue)
    },
    [currentlySelected.length, onChange, options]
  )

  const [filter, setFilter] = useState('')

  const filteredOptions = useMemo(
    () =>
      options.filter(option =>
        normalizeString(filter)
          .trim()
          .split(' ')
          .every(word => normalizeString(option.label).includes(word))
      ),
    [filter, options]
  )

  useOnClickOutside(multiSelectRef, handleCloseSelectMenu)

  const disabled = useMemo(() => {
    return isDisabled || !options?.length
  }, [isDisabled, options?.length])

  return (
    <AnimatePresence exitBeforeEnter>
      <SelectControl className={className}>
        {!!label && (
          <label htmlFor={`select-${name}`}>
            {label}
            {required ? (
              <span className='input__required_tag'>(Obrigatório)</span>
            ) : null}
          </label>
        )}
        <SelectContainer
          ref={multiSelectRef}
          isDisabled={disabled}
          hasError={!!error}
          onClick={toggleSelectMenu}
          role='button'
          className={`${yPlacement === 'top' ? 'open_above' : ''} ${
            isOpen ? 'open' : ''
          }`}
          onKeyDown={keyboardSelectHandler(toggleSelectMenu)}
          tabIndex={0}
          aria-pressed={isOpen}
        >
          <p>{placeholder}</p>
          <span className='select__chevron'>
            <AngleDownIcon />
          </span>
          {error ? (
            <SimpleTooltip
              label={error?.message || ''}
              tooltipClass='input-tooltip'
              verticalPosition='top'
            />
          ) : null}
          <AnimatePresence>
            {isOpen && (
              <SelectOptionsContainer
                layout
                initial={{
                  opacity: 0,
                  y: 20 * (yPlacement === 'top' ? 1 : -1),
                  scaleY: 0
                }}
                animate={{ opacity: 1, y: 0, scaleY: 1 }}
                exit={{
                  opacity: 0,
                  y: 20 * (yPlacement === 'top' ? 1 : -1),
                  scaleY: 0
                }}
                className={yPlacement === 'top' ? 'on_top' : ''}
                onKeyDown={preventPropagation}
                onClick={preventPropagation}
              >
                {withFilter && (
                  <FilterSelect
                    name={name}
                    disabled={disabled}
                    setFilter={setFilter}
                    filter={filter}
                  />
                )}
                <div className='select_options__content'>
                  <AnimatePresence>
                    {hasSelectAll && (
                      <MultiSelectorButton
                        key='Select_all_btn'
                        onClick={handleSelectAll}
                        type='button'
                        className={
                          currentlySelected.length === options.length
                            ? 'selected'
                            : ''
                        }
                        {...OptionButtonAnimation}
                      >
                        <span className='selector__icon' aria-hidden>
                          <CheckIcon />
                        </span>
                        <span className='selector_label'>{selectAllLabel}</span>
                      </MultiSelectorButton>
                    )}
                    {filteredOptions.map(option => (
                      <MultiSelectorButton
                        key={option.value}
                        onClick={e => handleSelectOption(e, option.value)}
                        type='button'
                        className={
                          checkIfIsSelected(option.value) ? 'selected' : ''
                        }
                        {...OptionButtonAnimation}
                      >
                        <span className='selector__icon' aria-hidden>
                          <CheckIcon />
                        </span>
                        <span className='selector_label'>{option.label}</span>
                      </MultiSelectorButton>
                    ))}
                  </AnimatePresence>
                </div>
              </SelectOptionsContainer>
            )}
          </AnimatePresence>
        </SelectContainer>
      </SelectControl>
    </AnimatePresence>
  )
}

export const SingleMultiSelect = ({
  children,
  checked,
  onClick = identity,
  ...other
}) => (
  <MultiSelectorButton
    onClick={onClick}
    type='button'
    {...other}
    className={[
      other?.className,
      'single',
      checked === 'partial' ? 'partial' : checked ? 'selected' : ''
    ].join(' ')}
  >
    <span className='selector__icon'>
      <CheckIcon />
    </span>
    <span className='selector_label'>{children}</span>
  </MultiSelectorButton>
)
