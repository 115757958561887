import { useCallback, useEffect, useRef, useState } from 'react'
import { useParamsFilter } from 'Shared/hooks/useParamsFilter'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { exportCsv } from '@bonitour/common-functions'
import { ReceivablesHooksTranslator } from '../i18n/ReceivablesHooksI18n'
import { ReceivablesService } from 'Core/Service/receivables'

export const useListReceivables = () => {
  const { addToast } = useToast()
  const [receivablesFilters, setReceivablesFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [receivablesData, setReceivablesData] = useState([])
  const { initStatePagination, perPageOptions } = usePaginationOptions()
  const initPagination = useRef(false)
  const [pagination, setPagination] = useState(initStatePagination)
  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false)

  const handlePagination = useCallback((currentPage, pageLimit) => {
    if (!initPagination.current) {
      initPagination.current = true
      return
    }

    const paginationInfo = {}
    paginationInfo.currentPage = currentPage
    paginationInfo.perPage = pageLimit
    setPagination(paginationInfo)
  }, [])

  const fetchReceivables = useCallback(() => {
    if (!(
      receivablesFilters?.startDate &&
      receivablesFilters?.endDate &&
      receivablesFilters?.gateway
    )) return

    const filters = {
      ...receivablesFilters,
      ...pagination
    }

    setLoading(true)
    return ReceivablesService.list({ filters }).then((data) => {
      setReceivablesData(data)
    }).catch(errors => {
      addToast({
        msgs: errors?.data?.errors_msg
      })
    }).finally(() => {
      setLoading(false)
    })
  }, [addToast, pagination, receivablesFilters])

  const i18n = useTranslator(ReceivablesHooksTranslator)

  const fetchExportReceivables = useCallback(() => {
    const filters = {
      ...receivablesFilters
    }
    return ReceivablesService.export({ filters, i18n })
      .then(({ parserCsv, fileName }) => {
        exportCsv(fileName, parserCsv)
      })
      .catch(errors => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg
        })
      })
  }, [addToast, i18n, receivablesFilters])

  const handleFilters = useCallback((filters) => {
    setIsUpdatingFilters(true)
    setPagination({
      currentPage: 1,
      perPage: pagination.perPage
    })
    setReceivablesFilters(filters)
    setTimeout(() => setIsUpdatingFilters(false))
  }, [pagination.perPage, setReceivablesFilters])

  useEffect(() => {
    !isUpdatingFilters && fetchReceivables()
  }, [
    fetchReceivables,
    isUpdatingFilters
  ])

  return {
    receivables: receivablesData?.receivables,
    meta: receivablesData?.meta,
    handlePagination,
    loading,
    receivablesFilters,
    perPageOptions,
    pagination,
    fetchExportReceivables,
    fetchReceivables,
    handleFilters
  }
}
