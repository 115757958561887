export const receivablesImportMesssage = 'É necessário selecionar um adquirente para a importação de recebíveis'

export const titleColumnFailureListingTable = ['Motivo da falha', 'Dados']

export const gatewayPaymentStatusColor = {
  scheduled: '--clr-orange1',
  pending: '--clr-gray7',
  settled: '--clr-gray7',
  error: '--clr-red1',
  waitingforadjustmentdebit: '--clr-gray7',
  anticipated: '--clr-gray7',
  payed: '--clr-green2'
}
