/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Button, LoadingAnimation, TrashIcon, WarningIcon } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { useCallback, useRef, useState } from 'react'
import { Tooltip } from 'Shared/components/Tooltip'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConditionsContainer as Container } from './Conditions.styles'
import {
  EventsProcessorsTranslator
} from 'Domains/EventsProcessors/i18n/EventsProcessorsI18n'
import {
  useEntryConditionsInputs
} from 'Domains/EventsProcessors/hooks/inputs/useEntryConditionsInputs'
import { useOnClickOutside } from 'Shared/utils/utilityHooks/useOnClickOutside'

/**
 * @typedef ConditionsProps
 * @prop { { eventsProcessorsSimilar: Record<string, string>[], uniqueConditions: boolean} } uniqueConditionsData
 * @prop { Record<string, string>[] } conditions
 * @prop { (id: string) => void } onRemoveCondition
 * @prop { (payload: Record<string, string>) => void } onUpdateCondition
 * @prop { boolean } isLoading
 */

/** @type { React.FC<ConditionsProps> } */
export const Conditions = ({
  isLoading,
  onRemoveCondition,
  onUpdateCondition,
  conditions,
  uniqueConditionsData
}) => {
  const i18n = useTranslator(EventsProcessorsTranslator)

  return (
    <Container>
      <div>
        <h3 className='container__title'>
          {i18n?.createEventEntryConditions}
        </h3>
        <p className='container__subtitle'>
          {i18n?.selectingFieldAddConditions}
        </p>
      </div>
      <UniqueConditions
        uniqueConditionsData={uniqueConditionsData}
        i18n={i18n}
      />
      {isLoading ? (
        <LoadingAnimation
          size={40}
          className='load'
        />
      ) : (
        conditions?.map((condition, idx) => (
          <ConditionsList
            key={idx}
            condition={condition}
            onRemoveCondition={onRemoveCondition}
            onUpdateCondition={onUpdateCondition}
            buttonLabel={i18n?.save}
          />
        ))
      )}
    </Container>
  )
}

/**
 * @typedef ConditionsListProps
 * @prop { Record<string, string> } condition
 * @prop { (id: string) => void } onRemoveCondition
 * @prop { (payload: Record<string, string>) => void } onUpdateCondition
 * @prop { string } buttonLabel
 */

/** @type { React.FC<ConditionsListProps> } */
export const ConditionsList = (
  {
    condition,
    onRemoveCondition,
    onUpdateCondition,
    buttonLabel
  }
) => {
  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useEntryConditionsInputs(condition)

  return (
    <>
      {condition && (
        <FormsInputs
          inputs={inputsValid}
          onInputBlur={onInputBlur}
          formErrors={errors}
          formValues={form}
          onInputChange={onInputChange}
        >
          <Button
            className='condition__btn'
            onClick={onSubmit(onUpdateCondition)}
          >
            {buttonLabel}
          </Button>
          <button
            onClick={() => onRemoveCondition(condition?.id)}
            disabled={!condition?.id}
          >
            <TrashIcon />
          </button>
        </FormsInputs>
      )}
    </>
  )
}

/**
 * @typedef UniqueConditionsProps
 * @prop { { eventsProcessorsSimilar: Record<string, string>[], uniqueConditions: boolean} } uniqueConditionsData
 * @prop { Record<string, string> } i18n
 */

/** @type { React.FC<UniqueConditionsProps> } */
export const UniqueConditions = (
  { uniqueConditionsData, i18n }
) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const toggleTooltip = useCallback(() => {
    setIsTooltipOpen(v => !v)
  }, [])

  const closeTooltip = useCallback(() => {
    setIsTooltipOpen(false)
  }, [])

  const tooltipContainerRef = useRef(null)
  useOnClickOutside(tooltipContainerRef, closeTooltip)

  return (
    uniqueConditionsData?.uniqueConditions === false && (
      <div className='unique_conditions__wrapper'>
        <span className='unique_conditions__warning_circle'>
          <WarningIcon />
        </span>
        <div className='unique_conditions__message'>
          {i18n?.uniqueConditionsMessage}

          <span ref={tooltipContainerRef}>
            <button onClick={toggleTooltip}>
              {i18n?.viewMoreDetails}
            </button>
            <Tooltip
              verticalPosition='top'
              tooltipClass='unique_conditions__tooltip'
              isActive={isTooltipOpen}
            >
              <h3>{
                i18n?.processorWithSimilarConditions(
                  uniqueConditionsData?.eventsProcessorsSimilar?.length
                )
              }</h3>
              <div className='unique_conditions__wrapper_names'>
                {uniqueConditionsData?.eventsProcessorsSimilar?.map(({ id, name }) => (
                  <p key={id}>{name}</p>
                ))}
              </div>
            </Tooltip>
          </span>
        </div>
      </div>
    )
  )
}
