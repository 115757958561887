import { apiCodesTranslations } from 'Shared/language/utils/functions'

interface GatewayEventTypeDescriptionsTranslations {
  [key: string]: string,
  gateway_payment_event_description_credit: string
  gateway_payment_event_description_feecredit: string
  gateway_payment_event_description_refundcredit: string
  gateway_payment_event_description_chargebackcredit: string
  gateway_payment_event_description_undocargebackcredit: string
  gateway_payment_event_description_antifraudfeecredit: string
  gateway_payment_event_description_antifraudfeewithreviewcredit: string
  gateway_payment_event_description_adjustmentcredit: string
  gateway_payment_event_description_chargebackreversalcredit: string
  gateway_payment_event_description_anticipationcredit: string
  gateway_payment_event_description_anticipationcommissioncredit: string
  gateway_payment_event_description_anticipatedinstallmentscredit: string
  gateway_payment_event_description_refundreversalcredit: string
  gateway_payment_event_description_reversalfeecredit: string
  gateway_payment_event_description_bankslipfeecredit: string
  gateway_payment_event_description_balancecompensationcredit: string
  gateway_payment_event_description_reversalantifraudfeecredit: string
  gateway_payment_event_description_reversalbankslipfeecredit: string
  gateway_payment_event_description_schedulebalancecredit: string
  gateway_payment_event_description_debit: string
  gateway_payment_event_description_feedebit: string
  gateway_payment_event_description_refunddebit: string
  gateway_payment_event_description_chargebackdebit: string
  gateway_payment_event_description_undochargebackdebit: string
  gateway_payment_event_description_antifraudfeedebit: string
  gateway_payment_event_description_antifraudfeewithreviewdebit: string
  gateway_payment_event_description_adjustmentdebit: string
  gateway_payment_event_description_chargebackreversaldebit: string
  gateway_payment_event_description_anticipationcommissiondebit: string
  gateway_payment_event_description_anticipatedinstallmentsdebit: string
  gateway_payment_event_description_refundreversaldebit: string
  gateway_payment_event_description_reversalpayoutdebit: string
  gateway_payment_event_description_reversalfeedebit: string
  gateway_payment_event_description_bankslipfeedebit: string
  gateway_payment_event_description_balancecompensationdebit: string
  gateway_payment_event_description_reversalantifraudfeedebit: string
  gateway_payment_event_description_anticipationdebit: string
  gateway_payment_event_description_compensationbetweensamepaymentarrangementdebit: string
  gateway_payment_event_description_schedulebalancedebit: string
}

export const useTranslatedGatewayEventTypeDescriptions = ({ dictionary }) => {
  const gatewayEventTypeDescriptionsTranslations: GatewayEventTypeDescriptionsTranslations = {
    gateway_payment_event_description_credit: dictionary?.receivables_eventDcpCredit_label,
    gateway_payment_event_description_feecredit: dictionary?.receivables_eventDcpFeeCredit_label,
    gateway_payment_event_description_refundcredit: dictionary?.receivables_eventDcpRefundCredit_label,
    gateway_payment_event_description_chargebackcredit: dictionary?.receivables_eventDcpChargebackCredit_label,
    gateway_payment_event_description_undocargebackcredit: dictionary?.receivables_eventDcpUndoCargebackCredit_label,
    gateway_payment_event_description_antifraudfeecredit: dictionary?.receivables_eventDcpAntifraudFeeCredit_label,
    gateway_payment_event_description_antifraudfeewithreviewcredit: dictionary?.receivables_eventDcpAntiFraudFeeWithReviewCredit_label,
    gateway_payment_event_description_adjustmentcredit: dictionary?.receivables_eventDcpAdjustmentCredit_label,
    gateway_payment_event_description_chargebackreversalcredit: dictionary?.receivables_evetDcpChargebackReversalCredit_label,
    gateway_payment_event_description_anticipationcredit: dictionary?.receivables_eventDcpAnticipationCredit_label,
    gateway_payment_event_description_anticipationcommissioncredit: dictionary?.receivables_eventDcpAnticipationCommissionCredit_label,
    gateway_payment_event_description_anticipatedinstallmentscredit: dictionary?.receivables_eventDcpAnticipatedInstallmentsCredit_label,
    gateway_payment_event_description_refundreversalcredit: dictionary?.receivables_eventDcpRefundReversalCredit_label,
    gateway_payment_event_description_reversalfeecredit: dictionary?.receivables_eventDcpReversalFeeCredit_label,
    gateway_payment_event_description_bankslipfeecredit: dictionary?.receivables_eventDcpBankSlipFeeCredit_label,
    gateway_payment_event_description_balancecompensationcredit: dictionary?.receivables_eventDcpBalanceCompensationCredit_label,
    gateway_payment_event_description_reversalantifraudfeecredit: dictionary?.receivables_eventDcpReversalAntiFraudFeeCredit_label,
    gateway_payment_event_description_reversalbankslipfeecredit: dictionary?.receivables_eventDcpReversalBankSlipFeeCredit_label,
    gateway_payment_event_description_schedulebalancecredit: dictionary?.receivables_eventDcpScheduleBalanceCredit_label,
    gateway_payment_event_description_debit: dictionary?.receivables_eventDcpDebit_label,
    gateway_payment_event_description_feedebit: dictionary?.receivables_eventDcpFeeDebit_label,
    gateway_payment_event_description_refunddebit: dictionary?.receivables_eventDcpRefundDebit_label,
    gateway_payment_event_description_chargebackdebit: dictionary?.receivables_eventDcpChargebackDebit_label,
    gateway_payment_event_description_undochargebackdebit: dictionary?.receivables_eventDcpUndoChargebackDebit_label,
    gateway_payment_event_description_antifraudfeedebit: dictionary?.receivables_eventDcpAntiFraudFeeDebit_label,
    gateway_payment_event_description_antifraudfeewithreviewdebit: dictionary?.receivables_eventDcpAntiFraudFeeWithReviewDebit_label,
    gateway_payment_event_description_adjustmentdebit: dictionary?.receivables_eventDcpAdjustmentDebit_label,
    gateway_payment_event_description_chargebackreversaldebit: dictionary?.receivables_eventDcpChargebackReversalDebit_label,
    gateway_payment_event_description_anticipationcommissiondebit: dictionary?.receivables_eventDcpAnticipationCommissionDebit_label,
    gateway_payment_event_description_anticipatedinstallmentsdebit: dictionary?.receivables_eventDcpAnticipatedInstallmentsDebit_label,
    gateway_payment_event_description_refundreversaldebit: dictionary?.receivables_eventDcpRefundReversalDebit_label,
    gateway_payment_event_description_reversalpayoutdebit: dictionary?.receivables_eventDcpReversalPayoutDebit_label,
    gateway_payment_event_description_reversalfeedebit: dictionary?.receivables_eventDcpReversalFeeDebit_label,
    gateway_payment_event_description_bankslipfeedebit: dictionary?.receivables_eventDcpBankSlipFeeDebit_label,
    gateway_payment_event_description_balancecompensationdebit: dictionary?.receivables_eventDcpBalanceCompensationDebit_label,
    gateway_payment_event_description_reversalantifraudfeedebit: dictionary?.receivables_eventDcpReversalAntiFraudFeeDebit_label,
    gateway_payment_event_description_anticipationdebit: dictionary?.receivables_eventDcpAnticipationDebit_label,
    gateway_payment_event_description_compensationbetweensamepaymentarrangementdebit: dictionary?.receivables_eventDcpCompensationBetweenSamePaymentArrangementDebit_label,
    gateway_payment_event_description_schedulebalancedebit: dictionary?.receivables_eventDcpScheduleBalanceDebit_label
  }

  const gatewayPaymentEventTypeDescriptionTranslator = (gatewayEventTypeDescription: string): string => {
    return apiCodesTranslations(
      {
        apiCode: gatewayEventTypeDescription,
        codesMapped: gatewayEventTypeDescriptionsTranslations,
        isToLowerCase: false
      }
    )
  }

  return gatewayPaymentEventTypeDescriptionTranslator
}
