import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const width100 = css`
  width: 100%;
`

export const containerHeader = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const arrowIcon = css`
  font-size: 24px;
  color: ${colors.primary};
  margin-left: 10px;
  cursor: pointer;
  transition: transform 200ms ease;
`

export const collapsableIconOpen = css`
  transform: rotate(180deg);
`

export const title = css`
  font-size: 20px;
  font-weight: 500;
  color: ${colors.gray1};
`

export const containerExpandable = css`
  display: flex;
  flex-direction: row;
`

export const messageContainer = css`
  display: flex;
  align-self: flex-end;
  padding: 0;
`

export const buttonStyle = css`
  display: flex;
  border: 1px solid ${colors.primary};
  color: ${colors.primary};
  font-weight: 100;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
`

export const buttonIconStyle = css`
  margin-right: 8px;
  font-size: 16px;
`

export const messageStyle = css`
  line-height: 1.4;
  color: ${colors.gray4};
  font-size: 15px;
  width: 92%;
  text-align: justify;
`

export const divider = css`
  width: 100%;
  height: 2px;
  background-color: ${colors.gray14};
  margin-bottom: 1em;
`

export const marginTop = css`
  margin-top: 1em;
`

export const containerAlertIcon = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  background-color: ${colors.gray2};
  margin-right: 1em;
`

export const alertIcon = css`
  color: ${colors.white};
  font-size: 14px;
`

export const titleImport = css`
  color: ${colors.gray1};
  font-size: 16px;
  font-weight: 500;
`

export const closeIcon = css`
  color: ${colors.gray1};
  font-size: 16px;
  cursor: pointer;
`

export const uploadedCard = css`
  background-color: ${colors.gray14};
  padding: 16px;
`

export const containerList = css`
  margin-left: 56px;
  margin-top: 10px;
`

export const importList = (color, margin) => css`
  color: ${color};
  list-style-type: disc;
  margin-bottom: ${margin};
  font-size: 14px;
`

export const arrowRightIcon = css`
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
`

export const onHoverListItem = css`
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const linkStyle = css`
  color: inherit;
  text-decoration: inherit;
`

export const pointerEventsNone = css`
  pointer-events: none;
`

export const anexedFileButton = css`
  .is__file {
      background-color: ${colors.gray9};
  }

  button {
    :disabled {
      background-color: ${colors.gray9};
      border: 1px solid ${colors.gray8};
      color: ${colors.gray6};
    }
  }
`
