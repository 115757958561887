import React, { Fragment, useCallback } from 'react'
import { css } from '@emotion/react'
import {
  DatePicker,
  Input,
  InputCnpjMask,
  InputCpfMask,
  InputFormGroup,
  InputMoneyMask,
  InputWithSuffix,
  InputZipCodeMask,
  Textarea
} from '@bonitour/components'
import { Select } from 'Shared/components/Select/Select'
import { UploadReceipts } from 'Domains/ExpenseRevenue/UploadReceipts/UploadReceipts'
import { MultiSelect } from '../Select/MultiSelect'
import { FormInputsContainer } from './__FormInputs.styles'
import { AttachementButton } from '../AttachementButton/AttachementButton'

/**
 * @typedef FormsInputsProps
 * @prop { Record<string, string | any>[] } inputs
 * @prop { Record<string, string> } formValues
 * @prop { Record<string, string> } formErrors
 * @prop { (value: string) => (val: unknown) => void} onInputChange
 * @prop { (value: string) => (val: unknown) => void} onInputBlur
 * @prop { AnimationProps } [animationProps]
 */

/**
 * @typedef {Object} AnimationProps
 * @property {boolean} [layout]
 * @property {{ opacity: number; scaleY: number; }} [initial]
 * @property {{ opacity: number; y: number; scaleY: number; }} [animate]
 * @property {{ duration: number; }} [transition]
 * @property {{ opacity: number; scaleY: number; }} [exit]
 */

/** @type { React.FC<FormsInputsProps> } */
export const FormsInputs = ({
  inputs,
  formValues,
  formErrors,
  onInputChange,
  onInputBlur,
  animationProps = {},
  children
}) => {
  const maskedInputChange = useCallback(
    (value, maskFn) => (val) =>
      onInputChange(value)(maskFn(val)),
    [onInputChange]
  )

  const maskedInputBlur = useCallback(
    (value, maskFn) => (val) =>
      onInputBlur(value)(maskFn(val)),
    [onInputBlur]
  )

  return (
    <FormInputsContainer
      className='form__inputs'
      {...animationProps}
    >
      {inputs.map((
        {
          label,
          className,
          value,
          type,
          typeWrapper,
          maskFn = e => e,
          options = [],
          disabled = false,
          placeholder,
          withFilter = true,
          readOnly = false,
          yPlacement = 'bottom',
          hasSelectAll,
          minDate,
          maxDate,
          includeExtraOption,
          suffix,
          typeValue
        },
        idx
      ) => (
        <Fragment key={idx}>
          {typeWrapper === 'inputFormGroup' ? (
            <InputFormGroup
              customCss={[css``]} // TODO: Add custom css
              errorMessage={formErrors[value]}
              className={`input_container__${className}`}
              label={label}
            >
              {type === 'text' ? (
                <Input
                  name={value}
                  value={formValues[value] || ''}
                  className={`input__${className}`}
                  onChange={maskedInputChange(value, maskFn)}
                  onBlur={maskedInputBlur(value, maskFn)}
                  ariaLabel={label}
                  disabled={disabled}
                  placeholder={placeholder}
                  readOnly={readOnly}
                  required
                />
              ) : type === 'date' ? (
                <DatePicker
                  value={formValues[value] || ''}
                  className={`input__${className}`}
                  onChange={maskedInputChange(value, maskFn)}
                  onBlur={maskedInputBlur(value, maskFn)}
                  placeholder={placeholder}
                  allowsEmpty
                  disabled={disabled}
                  minDate={minDate}
                  maxDate={maxDate}
                  readOnly={readOnly}
                />
              ) : type === 'money' ? (
                <InputMoneyMask
                  value={formValues[value] || ''}
                  onChange={maskedInputChange(value, maskFn)}
                  onBlur={maskedInputBlur(value, maskFn)}
                  readOnly={readOnly}
                />
              ) : type === 'upload' ? (
                <UploadReceipts
                  receipts={formValues[value] || []}
                  onChange={maskedInputChange(value, maskFn)}
                />
              ) : type === 'textarea' ? (
                <Textarea
                  onChange={maskedInputChange(value, maskFn)}
                  onBlur={maskedInputBlur(value, maskFn)}
                  value={formValues[value] || ''}
                  placeholder={placeholder}
                  error={formErrors[value]}
                  readOnly={readOnly}
                />
              ) : type === 'maskCPF' ? (
                <InputCpfMask
                  value={formValues[value] || ''}
                  onChange={maskedInputChange(value, maskFn)}
                  onBlur={maskedInputBlur(value, maskFn)}
                  disabled={disabled}
                  name={value}
                  className={`input__${className}`}
                  ariaLabel={label}
                  placeholder={placeholder}
                  readOnly={readOnly}
                  required
                />
              ) : type === 'maskCNPJ' ? (
                <InputCnpjMask
                  value={formValues[value] || ''}
                  onChange={maskedInputChange(value, maskFn)}
                  onBlur={maskedInputBlur(value, maskFn)}
                  disabled={disabled}
                  name={value}
                  className={`input__${className}`}
                  ariaLabel={label}
                  placeholder={placeholder}
                  readOnly={readOnly}
                  required
                />
              ) : type === 'maskZipCode' ? (
                <InputZipCodeMask
                  value={formValues[value] || ''}
                  onChange={maskedInputChange(value, maskFn)}
                  onBlur={maskedInputBlur(value, maskFn)}
                  disabled={disabled}
                  name={value}
                  className={`input__${className}`}
                  ariaLabel={label}
                  placeholder={placeholder}
                  readOnly={readOnly}
                  required
                />
              ) : type === 'suffix' ? (
                <InputWithSuffix
                  name={value}
                  value={formValues[value] || ''}
                  className={`input__${className}`}
                  onChange={maskedInputChange(value, maskFn)}
                  onBlur={maskedInputBlur(value, maskFn)}
                  ariaLabel={label}
                  disabled={disabled}
                  placeholder={placeholder}
                  readOnly={readOnly}
                  type={typeValue}
                  required>
                  <span>{suffix}</span>
                </InputWithSuffix>
              ) : type === 'attachmentButton' ? (
                <AttachementButton
                  file={formValues[value] || []}
                  onChange={maskedInputChange(value, maskFn)}
                  accept={'.csv, text/csv, .ofx, .txt'}
                  className={`input__${className}`}
                />
              ) : null}
            </InputFormGroup>
          ) : type === 'select' ? (
            <Select
              withFilter={withFilter}
              name={value}
              label={label}
              placeholder={placeholder}
              options={options}
              value={formValues[value]}
              error={formErrors[value]}
              onChange={maskedInputChange(value, maskFn)}
              onBlur={maskedInputBlur(value, maskFn)}
              className={`input__${className}`}
              disabled={disabled || readOnly}
              yPlacement={yPlacement}
              includeExtraOption={includeExtraOption}
            />
          ) : type === 'multiSelect' ? (
            <MultiSelect
              name={value}
              label={label}
              placeholder={placeholder}
              options={options}
              value={formValues[value]}
              withFilter={withFilter}
              hasSelectAll={hasSelectAll}
              onChange={maskedInputChange(value, maskFn)}
              error={formErrors[value]}
              includeExtraOption={includeExtraOption}
              disabled={disabled || readOnly}
            />
          ) : null }
        </Fragment>
      ))}
      {children}
    </FormInputsContainer>
  )
}
