/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import BillingLogo from 'Shared/svgs/logoBilling.svg'
import { Header, Hamburguer, Content, Footer, Sidebar, Logo, flexRowCentered, colors } from '@bonitour/components'
import { User } from './User'
import { ProductsSection } from './ProductsSection'
import { AuthorizationProvider } from 'Shared/contexts/Authorization'
import { CompanyProvider } from 'Shared/contexts/Company'
import { UserProvider } from 'Shared/contexts/User'
import { Feature } from 'Shared/contexts/Feature'
import { Link } from 'react-router-dom'
import { Menu } from './Menu'
import { APP_ENVIRONMENT } from 'Shared/config/env'

const envStyle = css`
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  color: ${colors.white};
  background: ${colors.warning};
  text-transform: capitalize;
`

const LayoutContainer = ({ children }) => {
  const [isVisible, setVisibility] = useState(false)
  const closeSidebar = () => setVisibility(false)
  const openSidebar = () => setVisibility(true)

  return (
    <>
      <Header>
        <div css={flexRowCentered}>
          <Hamburguer onClick={openSidebar} />
          <Link to='/'>
            <Logo src={BillingLogo} />
          </Link>

          {APP_ENVIRONMENT !== 'production' && <span css={envStyle}>{APP_ENVIRONMENT}</span>}
        </div>
        <div css={flexRowCentered}>
          <ProductsSection />
          <User />
        </div>
      </Header>
      <Content>
        {children}
      </Content>
      <Footer />
      <Sidebar isOpen={isVisible} onSidebarClose={closeSidebar}>
        <Menu onNavigation={closeSidebar} />
      </Sidebar>
    </>
  )
}

export const Layout = (props) => {
  return (
    <UserProvider>
      <CompanyProvider>
        <AuthorizationProvider>
          <Feature>
            <LayoutContainer {...props} />
          </Feature>
        </AuthorizationProvider>
      </CompanyProvider>
    </UserProvider>
  )
}
