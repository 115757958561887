import { string as yupString } from 'yup'

const supplierType = (supplierTypeOptions = []) => ({
  label: 'Tipo do fornecedor',
  className: 'supplier_type',
  value: 'basicRegistration_supplierType',
  type: 'select',
  options: supplierTypeOptions,
  withFilter: false,
  schema: yupString().required('supplierTypeRequired')
})

const supplierDocument = {
  label: 'Documento',
  className: 'supplier_document',
  value: 'basicRegistration_supplierDocument',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite o CPF/CNPJ',
  schema: yupString().nullable()
}

const municipalRegistration = {
  label: 'Inscrição municipal',
  className: 'municipal_registration',
  value: 'basicRegistration_municipalRegistration',
  type: 'text',
  schema: yupString().nullable(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite a inscrição municipal'
}

const enabled = (enabledOptions = []) => ({
  label: 'Estado',
  className: 'enabled',
  value: 'basicRegistration_enabled',
  type: 'select',
  options: enabledOptions,
  schema: yupString(),
  withFilter: false
})

const corporateName = {
  label: 'Razão Social',
  className: 'corporate_name',
  value: 'basicRegistration_corporateName',
  type: 'text',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite a razão social',
  schema: yupString().required('corporateNameRequired')
}

const fancyName = {
  label: 'Nome Fantasia',
  className: 'fancy_name',
  value: 'basicRegistration_fancyName',
  type: 'text',
  schema: yupString().nullable(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite o nome fantasia'
}

const emails = {
  label: 'Emails',
  className: 'emails',
  value: 'contactDetails_emails',
  type: 'text',
  schema: yupString().nullable(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite os emails de contato'
}

const phoneNumbers = {
  label: 'Telefones',
  className: 'phone_numbers',
  value: 'contactDetails_phoneNumbers',
  type: 'text',
  schema: yupString().nullable(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite os telefones de contato'
}

const contactNames = {
  label: 'Pessoas de contato',
  className: 'contact_names',
  value: 'contactDetails_contactNames',
  type: 'text',
  schema: yupString().nullable(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite as pessoas de contato'
}

const observation = {
  label: 'Observação',
  className: 'observation',
  value: 'contactDetails_observation',
  type: 'text',
  schema: yupString().nullable(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite a observação'
}

const zipCode = {
  label: 'CEP',
  className: 'zip_code',
  value: 'address_zipCode',
  type: 'maskZipCode',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite o CEP',
  schema: yupString().nullable()
    .max(9, 'zipCodeMaxCharacters')
}

const address = {
  label: 'Endereço',
  className: 'address',
  value: 'address_address',
  type: 'text',
  schema: yupString().nullable(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite o endereço'
}

const neighborhood = {
  label: 'Bairro',
  className: 'neighborhood',
  value: 'address_neighborhood',
  type: 'text',
  schema: yupString().nullable(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite o bairro'
}

const cityName = {
  label: 'Cidade',
  className: 'city_name',
  value: 'address_cityName',
  type: 'text',
  schema: yupString().nullable(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite a cidade'
}

const state = {
  label: 'Estado',
  className: 'state',
  value: 'address_state',
  type: 'text',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite o estado',
  schema: yupString().nullable()
    .max(2, 'stateMaxCharacters')
}

const country = {
  label: 'País',
  className: 'country',
  value: 'address_country',
  type: 'text',
  schema: yupString().nullable(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite o país'
}

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  supplierType,
  supplierDocument,
  municipalRegistration,
  enabled,
  corporateName,
  fancyName,
  emails,
  phoneNumbers,
  contactNames,
  observation,
  zipCode,
  address,
  neighborhood,
  cityName,
  state,
  country,
  getSchema
}
