import React from 'react'
import {
  Checkbox,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow
} from '@bonitour/components'

/**
 * @typedef Options
 * @prop { boolean } [withCheckBox]
 * @prop { Record<string, string> } i18n
 * @prop {() => void } handleOnSelectAllRow
 * @prop { boolean } allSelected
 * @prop { boolean } disabled
 */

/**
 * @typedef TableHeadersProps
 * @prop { Record<string, string>[] } datas
 * @prop { Options } options
 */

/** @type { React.FC<TableHeadersProps> } */
export const TableHeaders = (
  {
    columnsTitles,
    options = {
      withCheckBox: false
    }
  }
) => {
  return (
    <TableHeader>
      <TableHeaderRow>
        {options.withCheckBox
          ? (
            <TableHeaderCell>
              <Checkbox
                disabled={options?.disabled || false}
                className='checkbox'
                onChange={options.handleOnSelectAllRow}
                checked={options.allSelected}
              />
            </TableHeaderCell>
          )
          : null
        }
        {
          columnsTitles.map(
            (item, idx) => (
              <TableHeaderCell key={idx}>{item}</TableHeaderCell>
            )
          )
        }
      </TableHeaderRow>
    </TableHeader>
  )
}
