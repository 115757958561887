import { ExpenseService } from 'Core/Service/expense'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { useQuery } from 'react-query'
import { ExpensesHooksTranslator } from '../i18n/ExpensesHooksI18n'
import { SimulateRecurrenceParams } from 'Core/Types/Expense'
import { BackendErrorFormat } from 'Core/Types/Error'

export const useSimulateRecurrenceExpense = (formData: SimulateRecurrenceParams) => {
  const {
    id,
    date,
    value,
    recurrenceType,
    ocurrences,
    paymentDay
  } = formData ?? {}

  const { addToast } = useToast()

  const i18n = useTranslator(ExpensesHooksTranslator)

  const {
    data,
    isLoading: isLoadingQuery,
    isFetching
  } = useQuery(
    ['simulateRecurrenceExpense', id, date, value, recurrenceType, ocurrences, paymentDay],
    () =>
      ExpenseService.getSimulateRecurrenceExpense(formData),
    {
      enabled: !!((id && date && value && paymentDay) || (date && value && paymentDay && recurrenceType && ocurrences)),
      onError: (errors: BackendErrorFormat) => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorLoadingRecurringAccountsPayableSimulation
          })
      },
      staleTime: 1000 * 60 * 10, // 10 min
      retry: 2
    }
  )

  return {
    data,
    loading: isLoadingQuery || isFetching
  }
}
