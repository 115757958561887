import { ConfigurationPayload } from 'Core/Types/Configuration'

export const ConfigurationsModel = {
  patchConfiguration: (configuration: ConfigurationPayload) => {
    const {
      forgottenExpenses,
      forgottenRevenues,
      maxRecurrences,
      expenseTradeId
    } = configuration ?? {}

    return {
      configs: {
        forgotten_expenses: forgottenExpenses,
        forgotten_revenues: forgottenRevenues,
        max_recurrences: maxRecurrences,
        expense_trade_id: expenseTradeId
      }
    }
  }
}
