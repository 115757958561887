import { useMemo } from 'react'
import { useForm } from '@bonitour/app-functions'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import {
  endDate,
  getSchema,
  destinationBankAccount,
  originBankAccount,
  startDate,
  status
} from '../../Form/__BankTransfersBaseInputs'
import { BankTransfersInputsTranslator } from '../../i18n/BankTransfersInputsI8n'
import { useBankAccountsOptions } from 'Domains/BankAction/BankAccounts/hooks/useBankAccountsOptions'
import { BankTransfersCommonParams } from 'Core/Types/BankTransfer'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'

export const useBankTransfersFilters = (
  filters: BankTransfersCommonParams
) => {
  const {
    bankAccountsOptionsWithAll
  } = useBankAccountsOptions()

  const inputsVals = useTranslatedInputs([
    startDate,
    endDate,
    status,
    originBankAccount(bankAccountsOptionsWithAll),
    destinationBankAccount(bankAccountsOptionsWithAll),
    getSchema
  ], BankTransfersInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsVals),
    [inputsVals]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(filters, schema)

  const formErrorsTranslteds = useTranslatedFormErrors(
    errors, BankTransfersInputsTranslator
  )

  return {
    errors: formErrorsTranslteds,
    form,
    onInputChange,
    onInputBlur,
    inputsValid: inputsVals,
    onSubmit
  }
}
