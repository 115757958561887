import React from 'react'
import { AccountingFucntionDashboardContainer } from './AccountingFunctionDashboard.style'
import { CustomFeatureEnabler } from 'Shared/components/CustomFeatureEnabler/CustomFeatureEnabler'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { NavLink } from 'react-router-dom'
import { CardButton } from 'Shared/components/CardButton'
import { ReturnMoney } from '@bonitour/components'
import { useTranslator } from 'Shared/language/i18nHandler'
import { AccountingFucntionDashboardTranslator } from './i18n/AccountingFunctionDashboardI18n'

export const AccountingFucntionDashboard = (): React.FC => {
  const i18n = useTranslator(AccountingFucntionDashboardTranslator)

  return (
    <>
      <PageTitle
        title={i18n?.titleAccountingsFunctions}
      />
      <AccountingFucntionDashboardContainer>
        <CustomFeatureEnabler featureKey='billing-count-plan'>
          <NavLink to='accounting-function/count-plan'>
            <CardButton>
              <ReturnMoney className='icon' />
              <label className='label'>
                {i18n?.menuTitleAccountsPlan}
              </label>
            </CardButton>
          </NavLink>
        </CustomFeatureEnabler>
      </AccountingFucntionDashboardContainer>
    </>
  )
}
