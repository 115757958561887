/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, Dialog, Fab, PaginationContainer, ExportIcon, Label, flexCenteredSpaceBetweenRow } from '@bonitour/components'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { FilterCountPlan } from './Filter/FilterCountPlan'
import { CountPlanListing } from './List/CountPlanList'
import { AddLabel } from '../../../Shared/components/AddLabel/AddLabel'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { ModalContent } from './ModalContent/ModalContent'
import { useListCountPlan } from './hooks/useCountPlan'
import { identity } from '@bonitour/common-functions'
import { width100, labelStyle, iconSize, hidden } from './CountPlan.style'

export const CountPlan = () => {
  const { push } = useHistory()
  const [modalVisible, setModalVisible] = useState(false)
  const titleColumnCountPlanTable = ['Nome', 'Classificação', 'Nível', 'Tipo', 'Ativo', 'Ações']

  const {
    initialFilters,
    deleteCountPlan = identity,
    handlePagination,
    countPlans,
    meta,
    loading,
    onSearch
  } = useListCountPlan()

  const { count = 0, last = 1, page } = meta ?? {}

  const handleCreateClick = () => push('/accounting-function/count-plan/create')

  const onReturnToDashboard = () => push('/accounting-function')

  const handleModalShow = () => setModalVisible(true)

  const handleModalHidden = () => setModalVisible(false)

  return (
    <>
      <Dialog
        title='Importar Plano de Contas'
        isVisible={modalVisible}
        onClose={handleModalHidden}
      >
        <ModalContent onClose={handleModalHidden} />
      </Dialog>
      <div css={[flexCenteredSpaceBetweenRow, width100]}>
        <PageTitle
          title='Plano de Contas'
          onClickReturn={onReturnToDashboard}
        />
        <div onClick={handleModalShow} css={hidden}>
          <Label css={labelStyle}>Importar Plano de Contas</Label>
          <Fab>
            <ExportIcon css={iconSize} />
          </Fab>
        </div>
      </div>
      <Card css={width100}>
        <FilterCountPlan
          onSearch={onSearch}
          initialFilters={initialFilters}
        />
        <PaginationContainer
          total={count}
          pagesQuantity={last}
          onPagination={handlePagination}
          currentPage={page}
        >
          <CountPlanListing
            columnList={titleColumnCountPlanTable}
            countPlanList={countPlans}
            deleteCountPlan={deleteCountPlan}
            loading={loading}
          />
        </PaginationContainer>
        <AddLabel onClick={handleCreateClick}>Novo plano de contas</AddLabel>
      </Card>
    </>
  )
}
