import { apiCodesTranslations } from '../utils/functions'
import { paymentMethodsOptions } from 'Constants/paymentMethodsOptions'

export const useTranslatedPaymentMethods = ({
  dictionary
}) => {
  const objTranslationsMap = {
    credit: dictionary?.common_credit_label,
    debit: dictionary?.common_debit_label,
    bank_billet: dictionary?.common_bankBillet_label,
    pix: dictionary?.common_pix_label,
    cash: dictionary?.common_cash_label,
    bank_transfer: dictionary?.common_bankTransfer_label,
    partnership_account_credit: dictionary?.common_originTypePartnershipAccountTransactionCredit_label,
    discount: dictionary?.common_discount_label,
    pay_pix: dictionary?.common_pix_label
  }

  const paymentMethodMapped = (method) => {
    return apiCodesTranslations({
      apiCode: method,
      codesMapped: objTranslationsMap
    })
  }

  /**
 * @typedef translatedPaymentMethodsOptionsProps
 * @prop { string[] } [valuesToRemove]
*/

  /** @type { function(translatedPaymentMethodsOptionsProps): { label: string, value: string }[] } */
  const translatedPaymentMethodsOptions = ({ valuesToRemove = [] }) => {
    return paymentMethodsOptions
      .filter(value => valuesToRemove?.indexOf(value) === -1)
      .map((value) => ({
        value,
        label: paymentMethodMapped(value)
      }))
  }

  return {
    paymentMethodMapped,
    translatedPaymentMethodsOptions
  }
}
