import { billingCore } from './Billing'
import { EventsProcessorParser } from 'Core/Parser/eventsProcessors'
import { EventsProcessorModel } from '../Models/eventsProcessor'

export const EventsProcessorsService = {
  getEventsProcessors (filters, pagination) {
    return billingCore.eventsProcessorDomain.getEventsProcessors(
      EventsProcessorModel.getEventsProcessors(
        filters, pagination
      )
    ).then(EventsProcessorParser.getEventsProcessors)
  },
  removeEventProcessor (eventProcessorId) {
    return billingCore.eventsProcessorDomain.removeEventProcessor(eventProcessorId)
  },
  createEventProcessor (eventProcessor) {
    return billingCore.eventsProcessorDomain.createEventProcessor(
      EventsProcessorModel.createEventProcessor(eventProcessor)
    )
  },
  getEventProcessor (eventProcessorId) {
    return billingCore.eventsProcessorDomain.getEventProcessor(
      eventProcessorId
    ).then(EventsProcessorParser.getEventProcessorSingle)
  },
  updateEventProcessor (eventProcessor, eventProcessorId) {
    return billingCore.eventsProcessorDomain.updateEventProcessor(
      EventsProcessorModel.updateEventProcessor(eventProcessor), eventProcessorId
    )
  },
  getMatchedEventss (eventProcessorId) {
    return billingCore.eventsProcessorDomain.getMatchedEvents(
      eventProcessorId
    ).then(EventsProcessorParser.matchedEvents)
  },
  getUniqueConditions (eventProcessorId) {
    return billingCore.eventsProcessorDomain.getUniqueConditions(eventProcessorId).then(
      EventsProcessorParser.getUniqueConditions
    )
  },
  getAutomationRulesSimulations (eventProcessorId, matchedEventId) {
    return billingCore.eventsProcessorDomain.getAutomationRulesSimulations(
      EventsProcessorModel.getAutomationRulesSimulations(matchedEventId), eventProcessorId
    ).then(EventsProcessorParser.getAutomationRulesSimulations)
  },
  getAutomationRules (eventProcessorId) {
    return billingCore.eventsProcessorDomain.getAutomationRules(
      eventProcessorId
    ).then(EventsProcessorParser.getAutomationRules)
  },
  getAutomationRuleDataForInputs (eventProcessorId, automationRuleId) {
    return billingCore.eventsProcessorDomain.getAutomationRule(
      eventProcessorId,
      automationRuleId
    ).then(EventsProcessorParser.getAutomationRuleDataForInputs)
  },
  createAutomationRule (payload, eventProcessorId) {
    return billingCore.eventsProcessorDomain.createAutomationRule(
      EventsProcessorModel.automationRulePayload(payload),
      eventProcessorId
    )
  },
  updateAutomationRule (
    payload, eventProcessorId, automationRuleId
  ) {
    return billingCore.eventsProcessorDomain.updateAutomationRule(
      EventsProcessorModel.automationRulePayload(payload),
      eventProcessorId,
      automationRuleId
    )
  },
  removeAutomationRule (eventProcessorId, automationRuleId) {
    return billingCore.eventsProcessorDomain.removeAutomationRule(
      eventProcessorId,
      automationRuleId
    )
  },
  getAutomationRuleActions () {
    return billingCore.automationRulesCommonDomain.getAutomationRuleActions(
      EventsProcessorModel.getAutomationRuleActions()
    ).then(EventsProcessorParser.getAutomationRuleActions)
  },
  getAutomationRuleAccounts () {
    return billingCore.automationRulesCommonDomain.getAutomationRuleAccounts(

    ).then(EventsProcessorParser.getAutomationRuleAccounts)
  },
  getAutomationRuleAccountFormulas () {
    return billingCore.automationRulesCommonDomain.getAutomationRuleAccountFormulas(

    ).then(EventsProcessorParser.getAutomationRuleAccountFormulas)
  }
}
