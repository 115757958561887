import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const ConfigurationInputsContainer = styled.div`
  * {
    box-sizing: border-box;
  }

  .form__inputs {
    width: 100%;
    display: grid;
    gap: 1.25rem;
    grid-template-columns: repeat(2, 1fr);
    background-color: var(--clr-white);
    border: 1px solid var(--clr-gray11);
    padding: 1.25rem;
    border-radius: var(--round-element-radius, 100%);

    .input__created_at {
      width: 100%;
    }

    div[class^='input_container__'],
    div[class^='input__'] {
      label {
        line-height: 1.5rem;
      }
    }
  }

  .wrapper__btns {
    display: flex;
    gap: 1.25rem;
    margin-top: 3.125rem;

    .auditButtonClass {
      font-weight: bold;
      width: 14.375rem;
      height: 2.438rem;
      border-radius: var(--round-element-radius, 50%);
      background-color: var(--clr-indigo1);
      color: var(--clr-white);
    }

    i {
      font-weight: bold;
      margin-left: 0.625rem;
      color: var(--clr-white);
    }
  }

  @media(max-width: ${BREAK_POINTS.tablet}) {
    .form__inputs {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`
