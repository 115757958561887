/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  AllowedFormulasContainer as Container
} from './AllowedFormulas.styles'
import { allowedFormulas } from 'Domains/EventsProcessors/Constants/EventsProcessors'
import { EventsProcessorsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsI18n'
import { useMemo } from 'react'
import { useTranslator } from 'Shared/language/i18nHandler'
import { useTranslatedFormulasDescriptions } from 'Shared/language/hooks/useTranslatedFormulasDescriptions'

export const AllowedFormulas = () => {
  const i18n = useTranslator(EventsProcessorsTranslator)

  const allowedFormulasWithDescriptionTranslated = useTranslatedFormulasDescriptions(
    allowedFormulas,
    i18n?.automationRuleAllowedFormulas
  )

  return (
    <Container>
      <h3>{i18n?.allowedFormulas}</h3>
      <section className='content'>
        {allowedFormulasWithDescriptionTranslated?.map((allowedFormula, idx) => (
          <AllowedFormulaWrapper
            key={idx}
            allowedFormula={allowedFormula}
            idx={idx}
            i18n={i18n}
          />
        ))}

      </section>
    </Container>
  )
}

/**
 * @typedef AllowedFormulaWrapperProps
 * @prop { Record<string, string> } allowedFormula
 * @prop { number } idx
 * @prop { Record<string, string> } i18n
 */

/** @type { React.FC<AllowedFormulaWrapperProps> } */
const AllowedFormulaWrapper = (
  { allowedFormula, idx, i18n }
) => {
  const {
    method,
    operation,
    desc,
    _params,
    _paramName
  } = allowedFormula

  const isPar = useMemo(() => idx % 2 === 0, [idx])
  const title = useMemo(
    () => method ? i18n?.formula : i18n?.operation,
    [i18n?.formula, i18n?.operation, method]
  )

  return (
    <section
      className={`allowed_formula_wrapper ${isPar && 'gray12'}` }
    >
      <>
        <p>{title}:</p>
        <pre className='code_snippet'>
          <code>
            <p >{method || operation}</p>
          </code>
        </pre>

      </>
      {desc &&
        (
          <>
            <p>{i18n?.description}:</p>
            <p>{desc}</p>
          </>
        )
      }
    </section>
  )
}
