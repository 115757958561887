import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'
import { ConditionService } from 'Core/Service/condition'

export const useGetConditions = () => {
  const [loading, setLoading] = useState(false)
  const [conditions, setConditions] = useState()
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const getConditions = useCallback((eventProcessorId) => {
    setLoading(true)
    return ConditionService.getConditions(eventProcessorId)
      .then(setConditions)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorFetchingConditions
        })
      })
      .finally(() => setLoading(false))
  }, [addToast, i18n?.errorFetchingConditions])

  return {
    getConditions,
    loading,
    conditions
  }
}
