import { MetaParser } from './Meta'
import { AuditParser } from './audit'

export const ExpenseRevenuesParser = {
  single: (expenseRevenue) => {
    const {
      id,
      description,
      enabled,
      expense_revenue_type: expenseRevenueType,
      user_id: userId,
      accountable_plan_id: accountPlanId
    } = expenseRevenue ?? {}

    return {
      id,
      description,
      enabled,
      expenseRevenueType,
      userId,
      accountPlanId
    }
  },
  list: ({ expense_revenues: expenseRevenues = [], meta }) => {
    return {
      expenseRevenues: expenseRevenues.map(ExpenseRevenuesParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  listWithoutPagination: ({ expense_revenues: expenseRevenues = [] }) => {
    return expenseRevenues.map(ExpenseRevenuesParser.single)
  },
  getExpenseRevenueAudits: (data) => {
    return AuditParser.getAudits({ data })
  }
}
