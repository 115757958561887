import { css } from '@emotion/core'

export const sizeIcon = css`
  font-size: 9px;
  margin-right: 1em;
  cursor: pointer;
`

export const collapsableIconOpen = css`
  transform: rotate(90deg);
`

export const tableBodyContainer = css`
  .circle__conciliated {
    display: inline-block;
    position: relative;

    &:hover .tooltip__conciliated {
      opacity: 1;
    }

    .tooltip__conciliated {
      opacity: 0;
      pointer-events: none;
    }

  }
`

export const tableContainerStyled = css`
  overflow: hidden;

  .value_color__negative {
    color: var(--clr-red2);
  }

  .value_color__positive {
    color: var(--clr-green2);
  }
`
