
export const ConditionParser = {
  conditionSingle: (condition) => {
    const {
      id,
      field,
      value,
      comparison_type
    } = condition?.condition ?? condition ?? {}

    return {
      id,
      field,
      value,
      comparisonType: comparison_type
    }
  },
  getConditions: ({ conditions }) => {
    if (!conditions?.length) return undefined

    const conditionParsed = conditions?.map(ConditionParser.conditionSingle)

    return conditionParsed
  }
}
