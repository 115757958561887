import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SupplierBankAccountService } from 'Core/Service/SupplierBankAccount'
import { SupplierBankAccountsHooksTranslator } from '../i18n/SupplierBankAccountsHooksI18n'

export const useSupplierBankAccountAudit = () => {
  const { addToast } = useToast()
  const i18n = useTranslator(SupplierBankAccountsHooksTranslator)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchSupplierAudit = useCallback((supplierBankAccountId, params) => {
    const { supplierId } = params
    if (!supplierBankAccountId || !supplierId) return
    setLoading(true)
    return SupplierBankAccountService.getSupplierBankAccountAudit(supplierBankAccountId, supplierId)
      .then(setData)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorAuditSupplier
        })
      }).finally(() => {
        setLoading(false)
      })
  }, [addToast, i18n])

  return {
    fetchSupplierAudit,
    data,
    loading
  }
}
