/**
 * @param { number } amount
 */
export const createNumericOptions = (amount) => {
  const builderArr = Array.from({ length: amount }).map((_, index) => {
    return { label: (index + 1).toString(), value: index + 1 }
  })

  return builderArr || []
}
