import { useTranslatedScheduledDescription } from 'Shared/language/hooks/useTranslatedScheduleDescription'
import { useTranslatedOriginTypes } from 'Shared/language/hooks/useTranslatedOriginTypes'

export const FullFinancialScheduleTranslator = (dictionary) => {
  const fullFinancialScheduleTableColumnsTitles = [
    dictionary?.flow_movement_date_label,
    dictionary?.flow_movement_description_label,
    dictionary?.flow_movement_releaseType_label,
    dictionary?.flow_movement_operation_label,
    dictionary?.flow_movement_value_label,
    dictionary?.flow_movement_status_label,
    ''
  ]

  const customValueCardTitles = {
    totalToPay: dictionary?.flow_movement_totalPayable_label,
    totalReceivable: dictionary?.flow_movement_totalReceivable_label,
    netValue: dictionary?.common_netValue_label
  }

  const flowMovementsStatus = {
    canceled: dictionary?.common_canceledStatus_label,
    processed: dictionary?.common_realizedStatus_label,
    need_confirmation: dictionary?.common_foreseenStatus_label,
    waiting: dictionary?.common_foreseenStatus_label,
    done: dictionary?.common_realizedStatus_label,
    error: dictionary?.common_withoutStatus_label
  }

  const flowMovementsOperationType = {
    credit: dictionary?.common_credit_label,
    debit: dictionary?.common_debit_label,
    error: dictionary?.common_withoutTypeOfOperation_label
  }

  const csvHeadersFlowMovements = [
    { key: 'date', label: dictionary?.flow_movement_date_label },
    { key: 'description', label: dictionary?.flow_movement_description_label },
    { key: 'originType', label: dictionary?.flow_movement_releaseType_label },
    { key: 'value', label: dictionary?.flow_movement_value_label },
    { key: 'operationType', label: dictionary?.flow_movement_operation_label },
    { key: 'status', label: dictionary?.flow_movement_status_label },
    { key: 'conciliated', label: dictionary?.flow_movement_conciliate_label }
  ]

  const { originTypeTranslator } = useTranslatedOriginTypes({ dictionary })

  const descriptionTranslator = useTranslatedScheduledDescription({ dictionary })

  const pageTitleFullFinancialSchedule = dictionary?.flow_movement_pageTitleFullFinancialSchedule_label
  const movements = dictionary?.flow_movement_movements_label
  const viewCanceledReleases = dictionary?.flow_movement_viewCanceledReleases_label
  const hideCanceledReleases = dictionary?.flow_movement_hideCanceledReleases_label
  const conciliate = dictionary?.common_conciliate_label
  const notConciliate = dictionary?.common_notConciliate_label
  const dateFormat = dictionary?.common_dateFormat_template
  const selectAperiodToViewMovements = dictionary?.flow_movement_selectAperiodToViewMovements_label
  const searchButton = dictionary?.common_searchButton_label

  return {
    fullFinancialScheduleTableColumnsTitles,
    customValueCardTitles,
    pageTitleFullFinancialSchedule,
    movements,
    viewCanceledReleases,
    hideCanceledReleases,
    conciliate,
    notConciliate,
    flowMovementsStatus,
    flowMovementsOperationType,
    originTypeTranslator,
    dateFormat,
    selectAperiodToViewMovements,
    searchButton,
    descriptionTranslator,
    csvHeadersFlowMovements
  }
}
