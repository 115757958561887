/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { getLangCode } from '../i18nHandler'

/**
 * @param { string } dictionaryElement
 * @param { Record<string | number, string | number> } substitutionObj
 * @returns {string}
 */
export const parseTemplateString = (
  dictionaryElement,
  substitutionObj = {}
) => {
  const substitutions = Object.entries(
    substitutionObj
  ).map(([key, value]) => ({
    varName: key,
    value
  }))

  const parsedString = substitutions.reduce((preParsedString, current) => {
    const replaceTag = /^<<.+>>$/.test(current.varName)
      ? current.varName.toUpperCase()
      : `<<${current.varName.toUpperCase()}>>`

    return preParsedString.replace(new RegExp(replaceTag, 'g'), current.value)
  }, dictionaryElement)

  return parsedString
}

/**
 * @param { string } dictionaryElement
 * @param { number } number
 * @param { string } lang
 * @returns { string }
 */
export const pluralize = (
  dictionaryElement,
  number,
  lang
) => {
  if (getLangCode(lang) === 'en_US' && Math.abs(number) !== 1) {
    return dictionaryElement[1]
  }
  return Math.abs(number) > 1 ? dictionaryElement[1] : dictionaryElement[0]
}

export const parseTemplateStringWithReactComponents = (
  dictionaryElement,
  substitutions
) => {
  const substitutionsArray = Object.entries(substitutions).map(
    ([key, value]) => {
      return {
        varName: key,
        value
      }
    }
  )

  const tagsIds = substitutionsArray.map(tag =>
    /^<<.+>>$/.test(tag.varName)
      ? tag.varName.toUpperCase()
      : `<<${tag.varName.toUpperCase()}>>`
  )

  const tagsValues = substitutionsArray.reduce((acc, curr) => {
    const currentTagName = curr.varName.replace(/<|>/gi, '')
    return { ...acc, [currentTagName]: curr.value }
  }, {})

  const buildElementsArray = ({
    elementTemplate,
    elementSubstitutions,
    replaceTagIds,
    replaceTagValues
  }) => {
    const replaceRegex = new RegExp(`(${replaceTagIds.join('|')})`, 'gi')

    const splitTemplate = elementTemplate.split(replaceRegex)

    const parsedElementsArray = splitTemplate.reduce((acc, current, idx) => {
      if (idx % 2 === 0) return acc

      const currentTagName = current.replace(/<|>/gi, '')

      const Replacement = replaceTagValues[currentTagName]

      if (!Replacement) return acc

      const ReplacementWrapped = data => (
        <React.Fragment key={`${currentTagName}-${idx}`}>
          {Replacement(data)}
        </React.Fragment>
      )

      const updatedList = [
        ...acc.slice(0, idx),
        ReplacementWrapped(elementSubstitutions[currentTagName]),
        ...acc.slice(idx + 1)
      ]

      return updatedList
    }, splitTemplate)

    return parsedElementsArray
  }

  return buildElementsArray({
    elementTemplate: dictionaryElement.template,
    // @ts-expect-error
    elementSubstitutions: dictionaryElement.substitutions,
    replaceTagIds: tagsIds,
    replaceTagValues: tagsValues
  })
}
