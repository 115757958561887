import { useForm } from '@bonitour/app-functions'
import { EventsTranslator } from 'Domains/Events/i18n/EventsI18n'
import {
  getSchema,
  value,
  comparisonType,
  field
} from 'Domains/EventsProcessors/Form/__EventsProcessorsBaseInputs'
import { EventsProcessorsInputsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsInputsI18n'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { useTranslator } from 'Shared/language/i18nHandler'
import { apiCodesTranslations } from 'Shared/language/utils/functions'
import { useMemo } from 'react'

const BASE_FORM = {}

export const useEntryConditionsInputs = (
  conditionData
) => {
  const inputsValid = useTranslatedInputs([
    field,
    comparisonType,
    value
  ], EventsProcessorsInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsValid),
    [inputsValid]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(conditionData || BASE_FORM, schema)

  const i18nEvent = useTranslator(EventsTranslator)

  const formValues = useMemo(() => {
    const fieldTranslated = form?.field ? apiCodesTranslations(
      {
        apiCode: form?.field,
        codesMapped: i18nEvent?.eventCodesMapped
      }
    ) : ''

    return {
      ...form,
      field: fieldTranslated
    }
  }, [
    form,
    i18nEvent?.eventCodesMapped
  ])

  return {
    errors,
    form: formValues,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  }
}
