import { formatMoney } from '@binamik/js-functions'
import { getImageUrl } from 'Shared/utils/getImageUrl'
import { MetaParser } from './Meta'
import { SafeDate } from '@binamik/js-providers'
import { AuditParser } from './audit'
import {
  Expense,
  ExportExpensesParams,
  GetExpensesRes,
  GetSimulateRecurrenceExpenseRes,
  Receipt,
  GetExpensesForgottenRes
} from 'Core/Types/Expense'
import { numberForCurrency } from 'Shared/utils/number/numberForCurrency'
import { csvGenerator } from 'Shared/utils/generatorCsv'

export const ExpenseParser = {
  getReceipts: (receipts: Receipt[]) => {
    if (!receipts?.length) return []

    return receipts.map(({ url, filename, id }) => {
      return {
        id, src: getImageUrl(url), name: filename
      }
    })
  },
  single: (expense: Expense) => {
    const {
      id,
      date,
      value,
      expense_revenue: expenseRevenue,
      status,
      created_at: createdAt,
      service_name: serviceName,
      receipts,
      user_name: userName,
      recurrence_id: recurrenceId,
      payment_day: paymentDay,
      supplier,
      external_origin_id,
      external_origin_type,
      observation,
      invoice_id
    } = expense ?? {}

    return {
      id,
      date,
      value,
      expenseRevenue,
      status: status || 'error',
      createdAt,
      serviceName,
      receipts: ExpenseParser.getReceipts(receipts),
      userName,
      recurrenceId,
      paymentDay,
      supplierName: supplier?.corporate_name || '',
      externalOriginId: external_origin_id,
      invoiceId: invoice_id,
      externalOriginType: external_origin_type,
      observation
    }
  },
  list: ({ meta, expenses = [] }: GetExpensesRes) => {
    return {
      expenses: expenses.map(ExpenseParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  singleExpense: ({ expense }: { expense: Expense }) => {
    const {
      id,
      date,
      reference_date,
      expense_revenue_id,
      value,
      payment_method,
      taxes,
      status,
      observation,
      service_name,
      bank_account_id,
      receipts,
      user_name,
      created_at,
      recurrence_id,
      payment_day,
      supplier_id,
      external_origin_id,
      invoice_id,
      external_origin_type
    } = expense

    return {
      id,
      date,
      referenceDate: reference_date,
      expenseRevenueId: expense_revenue_id,
      value: formatMoney(value),
      paymentMethod: payment_method,
      taxes,
      status,
      observation,
      serviceName: service_name,
      bankAccountId: bank_account_id || '',
      userName: user_name || '',
      createdAt: created_at ? new SafeDate(created_at).format('DD/MM/YYYY') : '',
      receipts: ExpenseParser.getReceipts(receipts),
      recurrenceId: recurrence_id,
      paymentDay: payment_day,
      supplierId: supplier_id || '',
      externalOriginId: external_origin_id,
      invoiceId: invoice_id,
      externalOriginType: external_origin_type
    }
  },
  getSimulateRecurrenceExpense: (resSimulateRecurrenceExpense: GetSimulateRecurrenceExpenseRes) => {
    const {
      dates,
      value
    } = resSimulateRecurrenceExpense ?? {}

    const arrDates = dates?.map((date) => {
      return new SafeDate(date).format('DD/MM/YYYY')
    })

    return {
      value: formatMoney(value),
      dates: arrDates
    }
  },
  getExpenseAudits: (data) => {
    return AuditParser.getAudits(
      { data, fieldsToFilter: ['external_origin_id', 'invoice_id'] }
    )
  },
  exportCsvExpenses: (expenses: Expense[], i18n: Record<string, any>, filters: ExportExpensesParams['filters']) => {
    const safeStartDate = filters?.startDate ? new SafeDate(filters?.startDate).format(i18n?.dateFormat) : ''
    const safeEndDate = filters?.endDate ? new SafeDate(filters?.endDate).format(i18n?.dateFormat) : ''
    const fileName = i18n?.fileNameExpense(safeStartDate, safeEndDate)

    const dataParsed = expenses?.map((
      {
        date, reference_date,
        service_name, expense_revenue,
        bank_account, payment_method,
        value, taxes,
        status, supplier,
        observation, created_at
      }
    ) => {
      const safeDate = date ? new SafeDate(date).format(i18n.dateFormat) : ''
      const safeReferenceDate = reference_date ? new SafeDate(reference_date).format(i18n.dateFormat) : ''
      const safeCreatedAt = created_at ? new SafeDate(created_at).format(i18n.dateFormat) : ''

      return {
        date: safeDate,
        referenceDate: safeReferenceDate,
        serviceName: service_name || '',
        expenseRevenue: expense_revenue?.description || '',
        bankAccount: bank_account?.bank_name || '',
        paymentMethod: i18n?.paymentMethodMapped(payment_method),
        value: numberForCurrency(value),
        taxes: numberForCurrency(taxes),
        status: i18n?.expensesStatusTranslator(status),
        supplier: supplier?.corporate_name || '',
        observation: observation || '',
        createdAt: safeCreatedAt
      }
    }, [])

    return {
      parserCsv: csvGenerator(dataParsed, { headers: i18n.csvHeadersExpenses }),
      fileName
    }
  },
  getExpensesForgotten: (expForgottenData: GetExpensesForgottenRes) => {
    const {
      forgotten,
      days_ago
    } = expForgottenData ?? {}

    return {
      forgotten: forgotten || 0,
      daysAgo: days_ago || 0
    }
  }
}
