import {
  string as yupString
} from 'yup'
import {
  automationRuleStatusOptions,
  comparisonTypeOptions,
  operationTypeOptions
} from '../Constants/EventsProcessors'

const name = {
  label: 'Nome do processador',
  placeholder: 'Digite o nome do processador',
  className: 'name',
  value: 'name',
  type: 'text',
  schema: yupString(),
  typeWrapper: 'inputFormGroup'
}

const fieldName = {
  label: 'Campo da condição de entrada',
  placeholder: 'Selecione o campo da condição de entrada',
  className: 'field_name',
  value: 'fieldName',
  type: 'text',
  schema: yupString(),
  typeWrapper: 'inputFormGroup'
}

const fieldValue = {
  label: 'Valor da condição de entrada',
  placeholder: 'Selecione o valor da condição de entrada',
  className: 'field_value',
  value: 'fieldValue',
  type: 'text',
  schema: yupString(),
  typeWrapper: 'inputFormGroup'
}

const status = {
  label: 'Status',
  placeholder: 'Selecione o status',
  className: 'status',
  value: 'status',
  type: 'select',
  options: [
    { value: '', label: 'Todos' },
    { value: 'enabled', label: 'Ativo' },
    { value: 'disabled', label: 'Inativo' }
  ],
  schema: yupString()
}

const nameWithRequired = {
  ...name,
  schema: yupString().required('nameRequired')
}

const description = {
  label: 'Descrição',
  placeholder: 'Digite a descrição',
  className: 'description',
  value: 'description',
  type: 'textarea',
  schema: yupString().required('descriptionRequired'),
  typeWrapper: 'inputFormGroup'
}

const field = {
  label: 'Quando o campo',
  placeholder: 'Digite o campo',
  className: 'field',
  value: 'field',
  type: 'text',
  schema: yupString(),
  readOnly: true,
  typeWrapper: 'inputFormGroup'
}

const value = {
  label: 'Valor',
  placeholder: 'Digite o valor',
  className: 'value',
  value: 'value',
  type: 'text',
  schema: yupString().when(
    'automationRuleAction', {
      is: (value) => {
        const isRequired = [
          'MoveAccountForecastWithFormula',
          'MoveAccountForecastWithFormulaForRefund',
          'MoveAccountWithFormula',
          'AccelerateInstallmentsForRefund'
        ].includes(value)
        return isRequired
      },
      then: yupString().required('valueRequired')
    }
  ),
  typeWrapper: 'inputFormGroup'
}

const comparisonType = {
  label: 'For',
  placeholder: 'Selecione o comparador',
  className: 'comparison_type',
  value: 'comparisonType',
  type: 'select',
  options: comparisonTypeOptions,
  schema: yupString(),
  withFilter: false
}

const automationRuleActions = (actionsOptions = []) => {
  return {
    label: 'Ação',
    placeholder: 'Selecione a ação',
    className: 'automation_rule_action',
    value: 'automationRuleAction',
    type: 'select',
    options: actionsOptions,
    schema: yupString().required(
      'automationRuleActionRequired'
    ),
    orderBy: ['alphabeticalASC']
  }
}

const operationType = {
  label: 'Tipo da operação',
  placeholder: 'Selecione o tipo de operação',
  className: 'operation_type',
  value: 'operationType',
  type: 'select',
  options: operationTypeOptions,
  schema: yupString().when(
    'automationRuleAction', {
      is: (value) => {
        const isRequired = [
          'MoveAccountForecastWithFormula',
          'MoveAccountForecastWithFormulaForRefund',
          'MoveAccountWithFormula'
        ].includes(value)
        return isRequired
      },
      then: yupString().required('operationTypeRequired')
    }
  ),
  withFilter: false
}

const dateWithFormula = {
  label: 'Data',
  placeholder: 'Digita a fórmula da data',
  className: 'date_with_formula',
  value: 'dateWithFormula',
  type: 'text',
  typeWrapper: 'inputFormGroup',
  schema: yupString().when(
    'automationRuleAction', {
      is: (value) => {
        const isRequired = [
          'MoveAccountWithFormula',
          'MoveAccountForecastWithFormula',
          'MoveAccountForecastWithFormulaForRefund',
          'AccelerateInstallmentsForRefund'
        ].includes(value)
        return isRequired
      },
      then: yupString().required('dateWithFormulaRequired')
    }
  )
}

const referenceDateWithFormula = {
  label: 'Data',
  placeholder: 'Digita a fórmula da data',
  className: 'reference_date_with_formula',
  value: 'referenceDateWithFormula',
  type: 'text',
  typeWrapper: 'inputFormGroup',
  schema: yupString().when(
    'automationRuleAction', {
      is: (value) => {
        const isRequired = [
          'MoveAccountWithFormula',
          'MoveAccountForecastWithFormula',
          'MoveAccountForecastWithFormulaForRefund',
          'AccelerateInstallmentsForRefund'
        ].includes(value)
        return isRequired
      },
      then: yupString().required('referenceDateWithFormulaRequired')
    }
  )
}

const automationRuleStatus = {
  label: 'Status',
  placeholder: 'Selecione o status',
  className: 'automation_rule_status',
  value: 'automationRuleStatus',
  type: 'select',
  options: automationRuleStatusOptions,
  schema: yupString().when(
    'automationRuleAction', {
      is: (value) => {
        const isRequired = [
          'UpdateAccountForecastWithFormula',
          'UpdateTicketStatus',
          'UpdateAccountMovement',
          'MoveAccountForecastWithFormula',
          'MoveAccountForecastWithFormulaForRefund',
          'AccelerateInstallmentsForRefund',
          'MoveAccountWithFormula'
        ].includes(value)
        return isRequired
      },
      then: yupString().required('automationRuleStatusRequired')
    }
  )
}

const accounts = (accountsOptions = []) => {
  return {
    label: 'Conta',
    placeholder: 'Selecione a conta',
    className: 'account',
    value: 'accountId',
    type: 'select',
    options: accountsOptions,
    schema: yupString().when(
      ['automationRuleAction', 'accountFormula'], {
        is: (automationRuleAction, accountFormula) => {
          const isIncludedAction = [
            'MoveAccountForecastWithFormula',
            'MoveAccountForecastWithFormulaForRefund',
            'MoveAccountWithFormula'
          ].includes(automationRuleAction)

          return isIncludedAction && !accountFormula
        },
        then: yupString().required('accountIdRequired')
      }
    ),
    yPlacement: 'top'
  }
}

const accountFormulas = (accountsWithFormulaOptions = []) => {
  return {
    label: 'Conta via fórmula',
    placeholder: 'Selecione a formula da conta',
    className: 'account_formulas',
    value: 'accountFormula',
    type: 'select',
    options: accountsWithFormulaOptions,
    schema: yupString().test(
      'accountFormulaRequired',
      'accountFormulaRequired',
      function (value) {
        const automationRuleAction = this.parent.automationRuleAction
        const accountId = this.parent.accountId

        const isIncludedAction = [
          'MoveAccountForecastWithFormula',
          'MoveAccountForecastWithFormulaForRefund',
          'MoveAccountWithFormula'
        ].includes(automationRuleAction)

        if (!value && isIncludedAction && !accountId) {
          return this.createError({ message: 'accountFormulaRequired', path: this.path })
        }

        return true
      }
    ),
    yPlacement: 'top'
  }
}

const iterationField = {
  label: 'Campo de iteração',
  placeholder: 'Digite o campo de iteração',
  className: 'iteration_field',
  value: 'iterationField',
  type: 'text',
  schema: yupString(),
  typeWrapper: 'inputFormGroup'
}

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  name,
  fieldName,
  fieldValue,
  status,
  description,
  nameWithRequired,
  value,
  comparisonType,
  field,
  automationRuleActions,
  operationType,
  accounts,
  accountFormulas,
  referenceDateWithFormula,
  dateWithFormula,
  automationRuleStatus,
  iterationField,
  getSchema
}
