import { cleanPayload } from 'Shared/utils/cleanPayload'
import { formatMoney } from '@binamik/js-functions'
import { SafeDate } from '@binamik/js-providers'

const sortAndFilterKeys = (keys, fieldsToFilter) => {
  const priorityFields = ['bank_account_name', 'accountable_plan_name', 'expense_revenue_name']

  return keys.sort((a, b) => {
    if (priorityFields.includes(a)) return 1
    if (priorityFields.includes(b)) return -1
    return 0
  }).filter(key => !fieldsToFilter.includes(key))
}

const formatValue = (key, value) => {
  switch (key) {
  case 'taxes':
  case 'value':
    return formatMoney(value)
  case 'date':
  case 'reference_date':
  case 'created_at':
    return new SafeDate(value)
  case 'enabled':
    return value ? 'enabled' : 'disabled'
  default:
    return value
  }
}

const extractObjectChanges = (element, fieldsToFilter) => {
  const newObjectChanges = {
    created_at: [element.created_at],
    event: [element.event],
    ...element.object_changes
  }

  const newValues = cleanPayload(newObjectChanges, { cleanArray: true, removeEmpty: true })
  const filteredKeys = sortAndFilterKeys(Object.keys(newValues), fieldsToFilter)

  return filteredKeys.map(key => {
    const rawValue = newObjectChanges[key][1] !== undefined ? newObjectChanges[key][1] : newObjectChanges[key][0]
    const formattedValue = formatValue(key, rawValue)

    return {
      key,
      value: formattedValue
    }
  })
}

export const AuditParser = {
  getAudits: ({ data, fieldsToFilter = [] }) => {
    const { versions = [] } = data ?? {}

    const defaultFieldsToFilter = [
      'id', 'user_id', 'bank_account_id', 'company_id', 'expense_revenue_id',
      'updated_at', 'recurrence_id', 'accountable_plan_id', 'billing_account_id',
      'event_id', 'supplier_id', 'origin_bank_account_id', 'destination_bank_account_id', ...fieldsToFilter
    ]

    return versions.map(element => extractObjectChanges(element, defaultFieldsToFilter))
  }
}
