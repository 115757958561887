import { ConfigurationsParser } from 'Core/Parser/configurations'
import { billingCore } from './Billing'
import { ConfigurationAuditsResParsed, ConfigurationParsed, ConfigurationPayload } from 'Core/Types/Configuration'
import { ConfigurationsModel } from 'Core/Models/configurations'

export const ConfigurationsService = {
  getConfiguration: async (
    configurationId: string
  ): Promise<ConfigurationParsed> => {
    return await billingCore.billingConfigurationDomain.get(
      configurationId
    ).then(ConfigurationsParser.configurationSingle)
  },
  patchConfiguration: async (
    configurationPayload: ConfigurationPayload,
    configurationId: string
  ): Promise<ConfigurationParsed> => {
    return await billingCore.billingConfigurationDomain.update(
      ConfigurationsModel.patchConfiguration(configurationPayload),
      configurationId
    )
  },
  getConfigurationAudits: async (configurationId: string): Promise<ConfigurationAuditsResParsed> => {
    return await billingCore.billingConfigurationDomain.getConfigurationAudits({}, configurationId).then(
      ConfigurationsParser.getConfigurationAudits
    )
  }
}
