import React, { createContext, useContext, useState, useMemo, useEffect } from 'react'
import { CompanyStore } from 'Core/Store/Company'
import { CompanyService } from 'Core/Service/Company'

interface CompanyProviderContextData {
  userCompanies: string[]
  company: { id?: string }
}

const CompanyProviderContext = createContext<CompanyProviderContextData>({
  userCompanies: [],
  company: {}
})

const CompanyProvider = ({ children }) => {
  const [company] = useState(() => CompanyStore.company || {})
  const [userCompanies, setUserCompanies] = useState([])

  useEffect(() => {
    if (company.id) {
      CompanyService.listUserCompanies(true).then(setUserCompanies)
    }
  }, [company])

  const providerData = useMemo(() => ({
    company,
    userCompanies
  }), [company, userCompanies])

  return (
    <CompanyProviderContext.Provider
      value={{ ...providerData }}
    >
      {children}
    </CompanyProviderContext.Provider>
  )
}

function useCompany(): CompanyProviderContextData {
  const context = useContext(CompanyProviderContext)

  if (!context) {
    throw new Error(
      'useCompany must be used within an CompanyProvider'
    )
  }

  return context
}

export { CompanyProvider, useCompany }
