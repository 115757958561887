
export const BankAccountsInputsTranslator = (
  dictionary
) => {
  const labels = {
    bankName: {
      label: dictionary?.bank_account_bankName_inputLabel
    },
    branchNumber: {
      label: dictionary?.bank_account_branchNumber_inputLabel
    },
    accountNumber: {
      label: dictionary?.bank_account_accountNumber_inputLabel
    },
    enabled: {
      label: dictionary?.bank_account_status_inputLabel
    },
    accountablePlanId: {
      label: dictionary?.bank_account_accountablePlanId_inputLabel,
      placeholder: dictionary?.bank_account_accountablePlanId_placeholderLabel
    }
  }

  return {
    inputs: labels
  }
}
