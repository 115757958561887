
export const pt_BR = {
  common_dateFormat_template: 'DD/MM/YYYY',
  common_flagVisa_label: 'Visa',
  common_flagAmericanExpress_label: 'American Express',
  common_flagElo_label: 'Elo',
  common_flagHipercard_label: 'Hipercard',
  common_flagMastercard_label: 'Mastercard',
  common_flagDiners_label: 'Diners',
  common_flagDiscover_label: 'Discover',
  common_credit_label: 'Crédito',
  common_debit_label: 'Débito',
  common_endDatemustBeGreaterOrEqualStartDate_toast: 'A data final deve ser maior ou igual a data inicial',
  common_startDateMustBeLessThanOrEqualEndDate_label: 'A data inicial deve ser menor ou igual a data final',
  common_selectPeriod_toast: 'Selecione um período',
  common_save_label: 'Salvar',
  common_cancel_label: 'Cancelar',
  common_searchButton_label: 'Pesquisar',
  common_all_label: 'Todos',
  common_aut_label: 'Aut',
  common_doc_label: 'Doc',
  common_yes_label: 'Sim',
  common_no_label: 'Não',
  common_originTypeReservationPayment_label: 'Pagamento de Reserva',
  common_originTypeReservationRefund_label: 'Reembolso de Reserva',
  common_originTypeTicket_label: 'Ingresso',
  common_originTypeInvoice_label: 'Fatura',
  common_originTypePartnershipAccountTransactionCredit_label: 'Crédito de Parceria',
  common_originTypePartnershipAccountTransactionDebit_label: 'Débito de Parceria',
  common_originTypeCashierHandling_label: 'Entrada e Saída de Caixa',
  common_originTypeCashierHandlingEntry_label: 'Entrada de Caixa',
  common_originTypeCashierHandlingExit_label: 'Saída de Caixa',
  common_originTypePaymentBatch_label: 'Lote Experiência Offline/Afiliados',
  common_originTypeExpense_label: 'Contas a Pagar',
  common_originTypeRevenue_label: 'Contas a Receber',
  common_originTypeBillingGatewayPayment_label: 'Lançamento em Recebíveis',
  common_pix_label: 'Pix',
  common_bankBillet_label: 'Boleto Bancário',
  common_cash_label: 'Dinheiro',
  common_bankTransfer_label: 'Transferência Bancária',
  common_discount_label: 'Desconto',
  common_noInformation_label: 'Não há informações',
  common_brand_label: 'Bandeira',
  common_statusActive_label: 'Ativo',
  common_statusInactive_label: 'Inativo',
  common_afterConfirmationNoWayToUndoAction_label: 'Após a confirmação, não haverá como desfazer a ação.',
  common_continue_label: 'Continuar',
  common_viewMoreDetails_label: 'Ver mais detalhes',
  common_back_label: 'Voltar',
  common_invalid_label: 'Inválidas',
  common_valid_label: 'Válidas',
  common_finalize_label: 'Finalizar',
  common_conciliate_label: 'Conciliado',
  common_notConciliate_label: 'Não conciliado',
  common_canceledStatus_label: 'Cancelado',
  common_realizedStatus_label: 'Realizado',
  common_foreseenStatus_label: 'Previsto',
  common_withoutStatus_label: 'Sem status',
  common_withoutTypeOfOperation_label: 'Sem tipo de operação',
  common_startDate_label: 'Data inicial',
  common_endDate_label: 'Data final',
  common_dateFormat_label: 'Data deve ter o formato dd/mm/yyyy',
  common_netValue_label: 'Valor líquido',
  common_description_label: 'Descrição',
  common_scheduling_label: 'Agendamento',
  common_cancellation_label: 'Cancelamento',
  common_payment_label: 'Pagamento',
  common_receivement_label: 'Recebimento',
  common_refunded_label: 'Estorno',
  common_formOfPayment_label: 'Forma de Pagamento',
  common_reservation_label: 'Reserva',
  common_dateWithHour_template: 'DD/MM/YYYY HH:mm',
  common_braspag_label: 'Braspag',
  common_acquirer_label: 'Adquirente',
  common_cielo_label: 'Cielo',
  common_lien_credit_label: 'Crédito de Gravame',
  common_lien_debit_label: 'Débito de Gravame',
  common_pos_monthly_fee_label: 'Mensalidade de POS',
  common_startDateRequired_label: 'Data inicial é obrigatória',
  common_endDateRequired_label: 'Data final é obrigatória',
  common_status_label: 'Status',
  common_selectStatuses_label: 'Selecione os status',
  common_selectAccount_label: 'Selecione a conta',
  common_date_label: 'Data',
  common_value_label: 'Valor',
  common_user_label: 'Usuário',
  common_actions_label: 'Ações',
  common_selectPeriodToSearch_label: 'Selecione um período para realizar a pesquisa',
  common_selectStatus_label: 'Selecione o status',
  common_userName_label: 'Nome do Usuário',
  common_registrationDate_label: 'Data de Cadastro',
  common_editDate_label: 'Data de Edição',
  common_selectTheDate_label: 'Selecione a data',
  common_bankFee_label: 'Taxa Bancária',
  common_observation_label: 'Observação',
  common_enterObservation_label: 'Digite a observação',
  common_statusRequired_label: 'Status obrigatório',
  common_observationRequired_label: 'Observação obrigatória',
  common_total_label: 'Total',
  common_none_label: 'Nenhum',
  common_bankAccount_label: 'Conta bancária',
  common_valueMustBeANumber_label: 'Valor deve ser um número',
  common_fillInTheValue_label: 'Preencha o valor',
  common_register_label: 'Cadastrar',
  common_referenceDate_label: 'Data de referência',
  common_exit_label: 'Sair',
  common_guideBatch_label: 'Lote de Guia',
  common_itMustBeNumber_label: 'Deve ser um número',
  common_numberOfRepetitionsMustBeLessThanOrEqual240_label: 'Número de repetições deve ser menor ou igual a 240',
  common_numberOfRepetitionsMustBeGreaterThanOrEqualTo12_label: 'Número de repetições deve ser maior ou igual a 12',
  common_numberOfRepetitionsMustBeGreaterThanOrEqualTo2_label: 'Número de repetições deve ser maior ou igual a 2',
  common_noFutureDateAllowed_label: 'Não é permitido data futura',
  common_totalizers_label: 'Totalizadores',
  common_attachTheFile_label: 'Anexar o arquivo',
  common_fileRequired_label: 'Arquivo obrigatório',
  common_bankAccountRequired_label: 'Conta bancária obrigatória',
  common_pagar_me_label: 'Pagar.me',

  receivables_scheduled_label: 'Agendado',
  receivables_peding_label: 'Aguardando confirmação de liquidação',
  receivables_settled_label: 'Liquidado',
  receivables_payed_label: 'Pago',
  receivables_errorSettledFinancialInstitution_label: 'Erro de liquidação na instituição financeira',
  receivables_waitingForAdjustmentDebit_label: 'Aguardando liquidação do ajuste de débito associado',
  receivables_anticipated_label: 'Evento antecipado',
  receivables_eventDcpCredit_label: 'Lançamento de crédito das parcelas de uma transação',
  receivables_eventDcpFeeCredit_label: 'Lançamento de crédito da tarifa fixa acordada entre o Marketplace e a Braspag (Facilitador)',
  receivables_eventDcpRefundCredit_label: 'Lançamento de crédito devido a um cancelamento',
  receivables_eventDcpChargebackCredit_label: 'Lançamento de crédito devido a um chargeback.',
  receivables_eventDcpUndoCargebackCredit_label: 'Lançamento de crédito para reversão de um chargeback',
  receivables_eventDcpAntifraudFeeCredit_label: 'Lançamento de crédito referente à transação de antifraude',
  receivables_eventDcpAntiFraudFeeWithReviewCredit_label: 'Lançamento de crédito referente à transação de antifraude com revisão manual',
  receivables_eventDcpAdjustmentCredit_label: 'Lançamento de um crédito como ajuste',
  receivables_evetDcpChargebackReversalCredit_label: 'Lançamento de crédito referente à reversão de um chargeback',
  receivables_eventDcpAnticipationCredit_label: 'Lançamento de crédito referente a antecipação',
  receivables_eventDcpAnticipationCommissionCredit_label: 'Lançamento de crédito referente à comissão de uma antecipação',
  receivables_eventDcpAnticipatedInstallmentsCredit_label: 'Lançamento de crédito referente a antecipação de parcelas de uma transação',
  receivables_eventDcpRefundReversalCredit_label: 'Lançamento de crédito devido a uma reversão de cancelamento',
  receivables_eventDcpReversalFeeCredit_label: 'Lançamento de crédito devido a uma reversão da Tarifa Fixa acordada entre o Marketplace e a Braspag',
  receivables_eventDcpBankSlipFeeCredit_label: 'Lançamento de crédito referente à reversão de uma transação de antifraude com revisão manual',
  receivables_eventDcpBalanceCompensationCredit_label: 'Lançamento de crédito referente a compensação da saldo com o saldo do próprio merchant (transferir saldo de um arranjo positivo para outro que está negativo)',
  receivables_eventDcpReversalAntiFraudFeeCredit_label: 'Lançamento de crédito referente à reversão de uma transação de antifraude',
  receivables_eventDcpReversalBankSlipFeeCredit_label: 'Lançamento de crédito referente à reversão de uma transação de antifraude com revisão manual',
  receivables_eventDcpScheduleBalanceCredit_label: 'Lançamento de crédito programado',
  receivables_eventDcpDebit_label: 'Lançamento de débito das parcelas de uma transação',
  receivables_eventDcpFeeDebit_label: 'Lançamento de débito da tarifa fixa acordada entre o Marketplace e a Braspag (Facilitador)',
  receivables_eventDcpRefundDebit_label: 'Lançamento de débito devido a um cancelamento',
  receivables_eventDcpChargebackDebit_label: 'Lançamento de débito devido a um chargeback',
  receivables_eventDcpUndoChargebackDebit_label: 'Lançamento de débito para reversão de um chargeback',
  receivables_eventDcpAntiFraudFeeDebit_label: 'Lançamento de débito referente à transação de antifraude',
  receivables_eventDcpAntiFraudFeeWithReviewDebit_label: 'Lançamento de débito referente à transação de antifraude com revisão manual',
  receivables_eventDcpAdjustmentDebit_label: 'Lançamento de um débito como ajuste',
  receivables_eventDcpChargebackReversalDebit_label: 'Lançamento de débito referente à reversão de um chargeback',
  receivables_eventDcpAnticipationCommissionDebit_label: 'Lançamento de débito referente à comissão de uma antecipação',
  receivables_eventDcpAnticipatedInstallmentsDebit_label: 'Lançamento de débito referente a antecipação de parcelas de uma transação',
  receivables_eventDcpRefundReversalDebit_label: 'Lançamento de débito devido a uma reversão de cancelamento',
  receivables_eventDcpReversalPayoutDebit_label: 'Lançamento de débito devido a uma reversão de pagamento',
  receivables_eventDcpReversalFeeDebit_label: 'Lançamento de débito devido a uma reversão da Tarifa Fixa acordada entre o Marketplace',
  receivables_eventDcpBankSlipFeeDebit_label: 'Lançamento de débito da Tarifa Fixa do produto boleto acordada entre o Marketplace e a Braspag',
  receivables_eventDcpBalanceCompensationDebit_label: 'Lançamento de débito referente a compensação da saldo com o saldo do próprio merchant (transferir saldo de um arranjo positivo para outro que está negativo)',
  receivables_eventDcpReversalAntiFraudFeeDebit_label: 'Lançamento de débito referente à reversão de uma transação de antifraude',
  receivables_eventDcpAnticipationDebit_label: 'Lançamento de débito referente a antecipação',
  receivables_eventDcpCompensationBetweenSamePaymentArrangementDebit_label: 'Lançamento de débito referente a compensação dentro de um mesmo pagamento',
  receivables_eventDcpScheduleBalanceDebit_label: 'Lançamento de débito programado',
  receivables_tableColumnTitleDate_label: 'Data',
  receivables_tableColumnTitleFlag_label: 'Bandeira',
  receivables_tableColumnTitleAutDoc_label: 'AUT / DOC',
  receivables_tableColumnTitleOperationType_label: 'Tipo de operação',
  receivables_tableColumnTitleGrossAmountInstallment_label: 'Valor bruto da parcela',
  receivables_tableColumnTitleNetAmountInstallment_label: 'Valor líquido da parcela',
  receivables_tableColumnTitleStatus_label: 'Status',
  receivables_csvColumnTitlePaymentDate_label: 'Data de pagamento',
  receivables_csvColumnTitleAcquirer_label: 'Adquirente',
  receivables_csvColumnTitleFlag_label: 'Bandeira',
  receivables_csvColumnTitleParcel_label: 'Parcela',
  receivables_csvColumnTitleAmountInstallments_label: 'Quantidade de parcelas',
  receivables_csvColumnTitleOperationType_label: 'Tipo de operação',
  receivables_csvColumnTitleAuthorizationCode_label: 'Código de autorização (AUT)',
  receivables_csvColumnTitleDOCNSU_label: 'DOC/NSU',
  receivables_csvColumnTitleGrossAmount_label: 'Valor bruto',
  receivables_csvColumnTitleNetAmount_label: 'Valor líquido',
  receivables_csvColumnTitleMdr_label: 'MDR (%)',
  receivables_csvColumnTitleMdrFee_label: 'MDR (R$)',
  receivables_csvColumnTitleBinamikFee_label: 'Taxa binamik (R$)',
  receivables_csvColumnTitleCardNumber_label: 'Número cartão',
  receivables_csvColumnTitleSaleDate_label: 'Data da venda',
  receivables_csvColumnTitleSaleGrossAmount_label: 'Valor bruto da venda',
  receivables_csvColumnTitleStatus_label: 'Status',
  receivables_csvColumnTitleEventTypeAcquirer_label: 'Tipo do evento na adquirente',
  receivables_csvColumnTitleDescriptionEventAcquirer_label: 'Descrição do evento na adquirente',
  receivables_csvColumnTitleConciliated_label: 'Conciliado',
  receivable_selectPeriodToImport_toast: 'Selecione um período para fazer a importação',
  receivable_paymentImportPeriodLimitToOneMonth_toast: 'Limite de importação de um mês (31 dias) por vez',
  receivable_paymentNoFutureReceivablesAllowed_toast: 'Não é permetido importar recebíveis para a data de hoje ou datas futuras',
  receivable_itIsNotAllowedDeleteReceivablesAlreadyConciliated_toast: 'Não é permitido excluir Recebíveis já conciliados',
  receivable_deleteReceivable_toast: [
    '<<NUMBER>> recebível deletado',
    '<<NUMBER>> recebíveis deletados'
  ],
  receivable_invalidFileFormat_label: 'Formato de arquivo inválido',

  revenue_statusScheduled_label: 'Agendado',
  revenue_statusCanceled_label: 'Agendamento cancelado',
  revenue_statusReceived_label: 'Recebido',
  revenue_statusRefunded_label: 'Recebimento estornado',
  revenue_accountsReceivableSuccessfullyRegistered_toast: 'Contas a Receber cadastrada com sucesso',
  revenue_errorRegisteringAccountsReceivable_toast: 'Erro ao cadastrar contas a receber',
  revenue_accountsReceivableUpdatedSuccessfully_toast: 'Contas a Receber atualizada com sucesso',
  revenue_errorUpdatingAccountsReceivable_toast: 'Erro ao atualizar contas a receber',
  revenue_errorFetchingAccountsReceivable_toast: 'Erro ao buscar contas a receber',
  revenue_dateOnlyByReceivedPresent_toast: 'Contas a Receber pagas não podem ser geradas para datas futuras',
  revenue_dateOnlyBeScheduledFuture_toast: 'Agendamentos de contas a receber podem ser feitos somente para datas futuras',
  revenue_errorLoadingAccountsReceivableHistory_toast: 'Erro ao carregar histórico de contas a receber',
  revenue_errorLoadingAccountsReceivable_toast: 'Erro ao carregar as contas a receber',
  revenue_errorLoadingAvailableStatus_toast: 'Error ao carregar os status disponiveis',
  revenue_errorLoadingForgottenReceivables_toast: 'Erro ao carregar contas a receber esquecidas',
  revenue_auditModalTitle_label: 'Histórico de alterações Contas a Receber',

  expense_statusScheduled_label: 'Agendado',
  expense_statusCanceled_label: 'Cancelado',
  expense_statusPayed_label: 'Pago',
  expense_statusRefunded_label: 'Estornado',
  expense_accountsPayableSuccessfullyRegistered_toast: 'Contas a Pagar cadastrada com sucesso',
  expense_errorRegisteringAccountsPayable_toast: 'Erro ao cadastrar a contas a pagar',
  expense_errorWhenSearchingForAccountsPayable_toast: 'Erro ao buscar a contas a pagar',
  expense_accountsPayableUpdatedSuccessfully_toast: 'Contas a Pagar atualizada com sucesso',
  expense_errorUpdatingAccountsPayable_toast: 'Erro ao atualizar a contas a pagar',
  expense_errorDeletingReceipts_toast: 'Erro ao deletar comprovantes',
  expense_dateOnlyByPayedPresent_toast: 'Contas a Pagar no status pago não podem ser geradas para datas futuras',
  expense_dateOnlyBeScheduledFuture_toast: 'Agendamentos de contas a pagar podem ser feitos somente para datas futuras',
  expense_onlyWithScheduledOrCanceledStatus_toast: 'Alteração para as demais contas a pagar somente com status agendado ou cancelado',
  expense_errorLoadingAvailableStatus_toast: 'Erro ao carregar os status disponiveis',
  expense_errorLoadingForgottenAccountsPayable_toast: 'Erro ao carregar as contas a pagar esquecidas',
  expense_errorLoadingAccountsPayableHistory_toast: 'Erro ao carregar histórico de contas a pagar',
  expense_errorLoadingAccountsPayable_toast: 'Erro ao carregar as contas a pagar',
  expense_errorLoadingRecurringAccountsPayableSimulation_toast: 'Erro ao carregar a simulação das contas a pagar recorrentes',
  expense_auditModalTitle_label: 'Histórico de alterações Contas a Pagar',
  expense_accountsPayable_label: 'Contas a Pagar',
  expense_newAccount_label: 'Nova conta',
  expense_newRecurringAccount_label: 'Nova conta recorrente',
  expense_accountPayableScheduledUnpaid_label: [
    'Há <<FORGOTTEN>> Conta a Pagar agendada e não paga',
    'Há <<FORGOTTEN>> Contas a Pagar agendadas e não pagas'
  ],
  expense_onTheLastDay_label: [
    ' no último dia.',
    ' nos últimos <<DAYS_AGO>> dias.'
  ],
  expense_typeOfExpense_label: 'Tipo de despesa',
  expense_productAndService_label: 'Produto/Serviço',
  expense_supplier_label: 'Fornecedor',
  expense_selectASupplier_label: 'Selecione um fornecedor',
  expense_payment_label: 'Pagamento',
  expense_paymentMethod_label: 'Forma de pagamento',
  expense_registrationDate_label: 'Data de registro',
  expense_selectPaymentDate_label: 'Selecione a data de pagamento',
  expense_selectReferenctDate_label: 'Selecione a data de referência',
  expense_selectExpenseType_label: 'Selecione o tipo de despesa',
  expense_paymentDayOfTheMonth_label: 'Dia do mês do pagamento',
  expense_selectThePaymentDayOfTheMonth_label: 'Selecione o dia do mês de pagamento',
  expense_bankFee_label: 'Taxa bancária',
  expense_recurrenceType_label: 'Tipo de recorrência',
  expense_informationOnTypesOfRecurrences_label: 'Informação dos tipos de recorrências',
  expense_recurringPayment_label: 'Pagamento recorrente',
  expense_paymentAmountWillBeTheSameAsTheSchedulingAmount_label: 'Valor dos pagamentos será o mesmo valor do agendamento',
  expense_installment_label: 'Parcelamento',
  expense_appointmentCostPerRepetition_label: 'Valor dos pagamentos será o valor do agendamento dividido pelo número de repetições',
  expense_selectTheTypeOfRecurrence_label: 'Selecione o tipo de recorrência',
  expense_numberOfRepetitions_label: 'Número de repetições',
  expense_fillInNumberOfRepetitions_label: 'Preencha número de repetições',
  expense_changeInformation_label: 'Informação das alterações',
  expense_replicateChange_label: 'Replicar alteração',
  expense_applyChangesToOtherRecurringPayments_label: 'Aplica as alterações nos demais pagamentos recorrentes',
  expense_doNotReplicateChanges_label: 'Não replicar alterações',
  expense_theEditOnlyAffectsTheCurrentPayment_label: 'A edição afeta apenas o pagamento atual',
  expense_selectTheChange_label: 'Selecione a alteração',
  expense_addedReceipt_label: [
    '<<NUMBER>> comprovante adicionado',
    '<<NUMBER>> comprovantes adicionados'
  ],
  expense_receipts_label: 'Comprovantes',
  expense_addOrRemoveReceitps_label: 'Adiciona ou remova os comprovantes',
  expense_dragTheImageOrClickToAddMaximum1Mb_label: 'Arraste a imagem ou clique para adicionar máximo 1Mb',
  expense_simulationOfRecurringPayments_label: 'Simulação pagamentos recorrentes',
  expense_datesOfEachPaymentFromThisCurrentOne_label: 'Datas de cada pagamento a partir desta atual',
  expense_amountOfEachPayment_label: 'Valor de cada pagamento:',
  expense_downloadYouReceipts_label: 'Baixe seus comprovantes',
  expense_downloadAllReceitps_label: 'Baixe todos os comprovantes',
  expense_accountPayableRegistration_label: 'Cadastro de conta a pagar',
  expense_editPayableAccount_label: 'Editar cadastro de conta a pagar',
  expense_recurringPayableAccountRegistration_label: 'Cadastro de conta a pagar recorrente',
  expense_editRecurringPayableAccountRegistration_label: 'Editar cadastro de conta a pagar recorrente',
  expense_fillOuTheSimulationForm_label: 'Preencha o formulário para a simulação',
  expense_mandatoryPaymentDayOfTheMonth_label: 'Dia do mês do pagamento obrigatório',
  expense_mandatoryRecurrenceType_label: 'Tipo de recorrência obrigatória',
  expense_replicateMandatoryChange_label: 'Replicar alteração obrigatória',
  expense_viewPayableAccount_label: 'Visualizar conta a pagar',
  expense_fileNameExpense_label: 'contas_a_pagar_<<START_DATE>>-<<END_DATE>>',
  expense_repetitionLimitIs_label: 'Limite de repetições é <<MAX_OCURRENCES>>',
  expense_editBatch_label: 'Editar lote',
  expense_selectedPayableAccount_label: [
    'Conta a pagar selecionada',
    'Contas a pagar selecionadas'
  ],
  expense_editPayablesInBatch_label: 'Editar contas a pagar em lote',
  expense_accountsPayableUpdatedSuccessfully_label: 'Contas a pagar atualizadas com successo',
  expense_addOrRemoveReceipts_label: 'Adicione ou remova os comprovantes',
  expense_attachmentWilNotBeAppliedToOtherInstallmentsRecurrences_label: 'Esse anexo não será aplicado as demais parcelas/recorrências',
  expense_attachmentWillAppearInAllInstallmentsRecurrences_label: 'Esse anexo aparecerá em todas as parcelas/recorrências',
  expense_totalBatchAccounts_label: 'Total contas em lote',
  expense_addReceipts_label: 'Adicione os comprovantes',
  expense_attachmentWillAppearOnAllTheAccountsInTheBatch_label: 'Esse anexo aparecerá em todas as contas do lote',

  expense_revenue_typeOfRevenueExpenseSuccessfullyRegistered_toast: 'Tipo de Receita/Despesa cadastrada com sucesso',
  expense_revenue_errorWhenRegisteringTypeOfRevenueExpense_toast: 'Erro ao cadastrar tipo de receita/despesa',
  expense_revenue_typeOfRevenueExpenseUpdatedSuccessfully_toast: 'Tipo de Receita/Despesa atualizada com sucesso',
  expense_revenue_errorWhenUpdatingRevenueExpenseType_toast: 'Erro ao atualizar tipo de receita/despesa',
  expense_revenue_errorWhenSearchingForRevenueExpenseType_toast: 'Erro ao buscar tipo de receita/despesa',
  expense_revenue_typeOfRevenueExpenseSuccessfullyRemoved_toast: 'Tipo de Receita/Despesa removida com sucesso',
  expense_revenue_errorRemovingRevenueExpenseType_toast: 'Erro ao remover tipo de receita/despesa',
  expense_revenue_errorLoadingReveueExpenseTypeHistory_toast: 'Erro ao carregar histórico de tipo de receita/despesa',
  expense_revenue_errorLoadingTypesOfRevenuesExpenses_toast: 'Erro ao carregar os tipos de receitas/despesas',
  expense_revenue_errorLoadingRevenueExpenseTypeOptions_toast: 'Erro ao carregar as opções de tipo de receita/despesa',
  expense_revenue_expenseRevenueCannotDestroy_toast: 'Receita/despesa em uso não pode ser deletada',
  expense_revenue_auditModalTitle_label: 'Histórico de alterações tipo de Despesa/Receita',

  conciliation_pageTitleBankConciliations_label: 'Conciliações Bancária',
  conciliation_tabsTitleConciliations_label: 'Conciliados',
  conciliation_tabsTitleNotConciliations_label: 'Não Conciliados',
  conciliation_tabsTitlePartiallyConciliations_label: 'Conciliados Parcialmente',
  conciliation_tableTitleImportedBankPostings_label: 'Lançamentos bancários importados',
  conciliation_tableTitleSystemReleases_label: 'Lançamentos do sistema',
  conciliation_fillInFieldsFromTheStartBar_label: 'Para listar as conciliações, primeiro preencha os campos dos filtros do relatório',
  conciliation_tableColumnTitleDate_label: 'Data',
  conciliation_tableColumnTitleValue_label: 'Valor',
  conciliation_tableColumnTitleHistoric_label: 'Histórico',
  conciliation_tableColumnTitleDocument_label: 'Documento',
  conciliation_tableColumnTitleDescription_label: 'Descrição',
  conciliation_startDate_inputLabel: 'Data inicial',
  conciliation_endDate_inputLabel: 'Data final',
  conciliation_dateFormat_formErrorLabel: 'Data deve ter o formato dd/mm/yyyy',
  conciliation_startDateRequired_formErrorLabel: 'Data inicial é obrigatória',
  conciliation_endDateRequired_formErrorLabel: 'Data final é obrigatória',
  conciliation_startDate_placeholderLabel: 'Data inicial',
  conciliation_endDate_placeholderLabel: 'Data final',
  conciliation_bankAccountId_inputLabel: 'Conta Bancária',
  conciliation_bankAccountId_placeholderLabel: 'Selecione a conta',
  conciliation_bankAccountRequired_formErrorLabel: 'Conta bancária é obrigatória',
  conciliation_endDateMustBeAfterOrEqualStartDate_formErrorLabel: 'Data final deve ser após ou igual a data inicial',
  conciliation_listButton_label: 'Listar',
  conciliation_noItemsToConciliation_label: 'Não há itens para conciliar',
  conciliation_itemSelected_label: ['Item selecionado', 'Itens selecionados'],
  conciliation_makeAutomaticConciliation_label: 'Fazer conciliação automática',
  conciliation_makeManualConciliation_label: 'Fazer conciliação manual',
  conciliation_automaticConciliationCreate_label: [
    '<<NUMBER>> conciliação automática criada',
    '<<NUMBER>> conciliações automáticas criadas'
  ],
  conciliation_manualConciliationCreate_label: 'Conciliação manual criada',
  conciliation_deletedConciliation_label: [
    '<<NUMBER>> conciliação deletada',
    '<<NUMBER>> conciliações deletadas'
  ],
  conciliation_deleteConciliations_error: 'Erro ao deletar as conciliações',
  conciliation_deleteConciliation_label: [
    'Deletar conciliação',
    'Deletar conciliações'
  ],
  conciliation_errorLoadingConciliations_toast: 'Erro ao carregar as conciliações',
  conciliation_errorCreatingAutomaticConciliation_toast: 'Erro ao criar a conciliação automática',
  conciliation_errorCreatingManualConciliation_toast: 'Erro ao criar a conciliação manual',
  conciliation_divergentValue_label: 'Valor Divergente',
  conciliation_divergentDate_label: 'Data Divergente',
  conciliation_details_label: 'Detalhes',
  conciliation_conciliationBy_label: 'Conciliado por',
  conciliation_creationDate_label: 'Data de criação',
  conciliation_noConciliationItems_label: 'Não há itens conciliados',
  conciliation_tableTitleReceivablesReleases_label: 'Lançamentos de recebíveis',
  conciliation_pageTitleCreditCardConciliations_label: 'Conciliações de Cartões',
  conciliation_selectAcquirer_placeholderLabel: 'Selecione a adquirente',
  conciliation_reconcilingCanceledSystemPostingsIsNotPermitted_label: 'Não é permitido conciliar lançamentos do sistema cancelados.',
  conciliation_totalAmountOfReceivablesNotReconciled_label: 'Valor total de recebíveis não conciliado',
  conciliation_totalSystemValueNotReconciled_label: 'Valor total do sistema não conciliado',
  conciliation_totalValueOfReconciledReceivables_label: 'Valor total de recebíveis conciliado',

  bank_account_auditModalTitle_label: 'Histórico de alterações Contas Bancárias',
  bank_account_editModalTitle_label: 'Editar Conta Bancária',
  bank_account_tableColumnTitleBankName_label: 'Banco',
  bank_account_tableColumnTitleBranchNumber_label: 'Agência',
  bank_account_tableColumnTitleAccountNumber_label: 'Conta',
  bank_account_tableColumnTitleStatus_label: 'Estado',
  bank_account_tableColumnTitleAction_label: 'Ação',
  bank_account_statusValueEnabled_label: 'Ativa',
  bank_account_statusValueDisabled_label: 'Inativa',
  bank_account_pageTitleBankAccounts_label: 'Contas Bancárias',
  bank_account_bankName_inputLabel: 'Banco',
  bank_account_branchNumber_inputLabel: 'Agência',
  bank_account_accountNumber_inputLabel: 'Conta',
  bank_account_status_inputLabel: 'Estado',
  bank_account_accountablePlanId_inputLabel: 'Plano de conta',
  bank_account_accountablePlanId_placeholderLabel: 'Selecione o plano de conta',
  bank_account_errorLoadBankAccountOptions_toast: 'Erro ao carregar as opções de contas bancárias',
  bank_account_errorLoadingBankAccountList_toast: 'Erro ao carregar a lista de contas bancárias',
  bank_account_errorLoadingBankAccount_toast: 'Erro ao carregar a conta bancária',
  bank_account_errorUpdatingBankAccount_toast: 'Erro ao atualizar a conta bancária',
  bank_account_errorLoadingBankAccountHistory_toast: 'Erro ao carregar histórico da conta bancária',
  bank_account_bankAccountHasBeenUpdatedSuccessfully_toast: 'Conta Bancária foi atualizada com sucesso',
  bank_account_incompatibleDifferentBankAccount_toast: 'Bancos diferentes',

  bank_statement_errorFetchingBankStatements_toast: 'Erro ao buscar os extratos bancários',
  bank_statement_deletedBankStatement_toast: [
    'Extrato bancário deletado: <<NUMBER>>',
    'Extratos bancários deletados: <<NUMBER>>'
  ],
  bank_statement_errorDeletingBankStatements_toast: 'Erro ao deletar os extratos bancários',
  bank_statement_errorExportBankStatementFile_toast: 'Erro ao exportar o arquivo de extrato bancário',
  bank_statement_importCorruptedFile_toast: 'Arquivo de extrato bancário corrompido',
  bank_statement_importWrongNumberOrFile_toast: 'Código de banco desconhecido ou arquivo errado',
  bank_statement_importEmptyFieldFormats_toast: 'Campos não cadastrados para esse formato de arquivo',
  bank_statement_errorFetchingImportedBankStatements_toast: 'Erro ao carregar a lista de extratos bancários importados',
  bank_statement_imported_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['lançamento importado', 'lançamentos importados']
    }
  },
  bank_statement_transactions_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['lançamentos no extrato', 'lançamentos nos extratos']
    }
  },
  bank_statement_ignored_current_date_transactions_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['lançamento ignorado na data atual', 'lançamentos ignorados na data atual']
    }
  },
  bank_statement_already_imported_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['lançamento já havia sido importado', 'lançamentos já haviam sido importados']
    }
  },
  bank_statement_not_imported_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['lançamento não importado', 'lançamentos não importados']
    }
  },
  bank_statement_date_releases: 'Data <<DATE>>:',
  bank_statement_importing_bank_statements_label: 'Importação de extratos bancários',
  bank_statement_bank_account_label: 'Conta bancária',
  bank_statement_accepted_file_formats_label: 'Formatos de arquivos aceitos:',
  bank_statement_statement_import_completed_label: 'Importação de extrato concluída!',
  bank_statement_waiting_bank_statement_imported_label: 'Aguardando a importação do Extrato Bancário!',
  bank_statement_latest_imported_extracts_label: 'Últimos extratos importados',
  bank_statement_details_label: 'Detalhes:',
  bank_statement_select_account_label: 'Selecione uma conta',
  bank_statement_itIsNotAllowedDeleteBankStatementAlreadyConciliated_toast: 'Não é permitido excluir extratos bancários já conciliados',
  bank_statement_errorWhenImportingBankStatements_toast: 'Erro ao importar extratos bancários',
  bank_statement_bankAccountNotAllowed_toast: 'Conta bancária não permitida',
  bank_statement_onlyPastDatesAllowed_label: 'Somente datas anteriores ao dia de hoje são permitidas',
  bank_statement_performImport_label: 'Realizar importação',
  bank_statement_pixPaymentPagarme_label: 'Pagamento pix Pagar.me',
  bank_statement_transferFromPagarme_label: 'Transferência da Pagar.me para',
  bank_statement_startAndEndDateMustBeDifferent_label: 'Data inicial e final devem ser diferentes',

  account_plan_errorFecthingAccountsPlans_toast: 'Erro ao buscar planos de contas',
  account_plan_errorFecthingAccountPlan_toast: 'Erro ao buscar plano de conta',
  account_plan_errorFecthingAccountsPlansOptions_toast: 'Erro ao buscar as opções de planos de contas',
  account_plan_errorDeleteAccountPlan_toast: 'Erro ao deletar plano de conta',
  account_plan_successDeletedAccountPlan_toast: 'Plano de conta deletado com sucesso',
  account_plan_accountPlanSucessRegistered_toast: 'Plano de conta foi cadastrado(a) com sucesso',
  account_plan_errorRegisteredAccountPlan_toast: 'Erro ao cadastrar plano de conta',
  account_plan_accountPlanSucessUpdated_toast: 'Plano de conta foi atualizado(a) com sucesso',
  account_plan_errorUpdateAccountPlan_toast: 'Erro ao atualizar o plano de conta',

  event_menuTitleEvents_label: 'Eventos',
  event_eventType_inputLabel: 'Tipo do Evento',
  event_selectEventType_placeholderLabel: 'Selecione o tipo do evento',
  event_pageTitleEvents_label: 'Eventos',
  event_tableColumnTitleEventType_label: 'Tipo do Evento',
  event_tableColumnTitleEventData_label: 'Dados do Evento',
  event_errorFetchingEvents_toast: 'Erro ao buscar os eventos',
  event_errorFecthingEventsTypes_toast: 'Erro ao buscar os tipos de eventos',
  event_errorFechtingEventRequestDetails_toast: 'Erro ao buscar os detalhes da requisição dos eventos',
  event_errorFetchingEventFieldNames_toast: 'Erro ao buscar os nomes dos campos dos eventos',
  // event type
  event_partnershipCredit_label: 'Crédito de Parceria',
  event_schedulingAccountsPayable_label: 'Agendamento Contas a Pagar',
  event_schedulingAccountsReceivable_label: 'Agendamento Contas a Receber',
  event_writeOffExternalBillet_label: 'Baixa Boleto Externo',
  event_writeOffAccountsPayable_label: 'Baixa de Contas a Pagar',
  event_writeOffAccountsReceivable_label: 'Baixa de Contas a Receber',
  event_writeOffExternalBilletFee_label: 'Baixa da taxa de boleto externo',
  event_digitalAccountMovement_label: 'Movimentação de Conta Digital',
  event_overdueBillet_label: 'Boleto Vencido',
  event_onlinePaymentCancellationCreditCard_label: 'Cancelamento Pagamento online - Cartão de Crédito',
  event_invoicePaymentCancellationSeller_label: 'Cancelamento Pagamento Fatura - Vendedor',
  event_cancellationAccountsPayable_label: 'Cancelamento de Contas a Pagar',
  event_cancellationAccountsReceivable_label: 'Cancelamento de Contas a Receber',
  event_ISSPaymentCancellation_label: 'Cancelamento de Pagamento de ISS',
  event_ticketCancellation_label: 'Cancelamento de Ingresso',
  event_supplierISSpaymentCancellation_label: 'Cancelamento de Pagamento ISS de Fornecedor',
  event_customerInvoiceCancellation_label: 'Cancelamento de Nota Fiscal de Cliente',
  event_offlinePaymentCancellation_label: 'Cancelamento de Pagamento Offline',
  event_onlinePaymentCreditCard_label: 'Pagamento online - Cartão de Crédito',
  event_onlinePaymentDebitCard_label: 'Pagamento online - Cartão Débito',
  event_invoiceReceivement_label: 'Recebimento de Fatura',
  event_bankAccountRegistration_label: 'Cadastro de Conta Bancária',
  event_gravameDebit_label: 'Débito de Gravame',
  event_checkDeposit_label: 'Depósito em Cheque',
  event_cashDeposit_label: 'Depósito em Dinheiro',
  event_checkReturned_label: 'Cheque devolvido',
  event_billetIssue_label: 'Emissão de Boleto',
  event_ISSPaymentScheduling_label: 'Agendamento de Pagamento de ISS',
  event_supplierISSPaymentSchedule_label: 'Agendamento de Pagamento de ISS de Fornecedor',
  event_issuanceInvoiceCustomer_label: 'Emissão de Nota Fiscal para o Cliente',
  event_agentBoxEntry_label: 'Entrada no Caixa do Agente',
  event_partnershipDebit_label: 'Débito de Parceria',
  event_refundAccountsPayable_label: 'Estorno de Contas a Pagar',
  event_refundAccountsReceivable_label: 'Estorno de Contas a Receber',
  event_checkDespostiRefund_label: 'Estorno de Depóstio em Cheque',
  event_ISSPaymentRefund_label: 'Estorno de Pagamento de ISS',
  event_supplierISSPaymentRefund_label: 'Estorno de Pagamento de ISS de Fornecedor',
  event_invoicePaymentRefund_label: 'Estorno de Pagamento de Fatura',
  event_POSMonthlyFee_label: 'Mensalidade de POS',
  event_invoicePayment_label: 'Pagamento de Fatura',
  event_paymentOfISSTax_label: 'Pagamento de Imposto ISS',
  event_supplierISSTaxPayment_label: 'Pagamento de Importo ISS de Fornecedor',
  event_offlinePayment_label: 'Pagamento offline',
  event_tourTaken_label: 'Passeio Realizado',
  event_guideTourOwnTicket_label: 'Paseio Realizado - ingresso próprio',
  event_transferSupplier_label: 'Repasse à Fornecedor',
  event_cancelattionTransferSupplier_label: 'Cancelamento de Repasse à Fornecedor',
  event_transferPaymentDebitCard_label: 'Repasse de Pagamento em Cartão Débito',
  event_confirmedReservation_label: 'Reserva Confirmada',
  event_confirmedBookingOwnTicket_label: 'Reserva confirmada - ingresso próprio',
  event_withdrawalCurrentAccount_label: 'Saque em conta-corrente',
  event_agentCashOut_label: 'Saída de caixa de Agente',
  event_transferBetweenBankAccounts_label: 'Transferência entre contas bancárias',
  // event_request
  event_info_label: 'Informações',
  event_clientName_label: 'Nome do Cliente',
  event_partnerName_label: 'Nome do Parceiro',
  event_reservationCode_label: 'Código da Reserva',
  event_fee_label: 'Taxa',
  event_type_label: 'Tipo',
  event_brand_label: 'Bandeira',
  event_installments_label: 'Parcelas',
  event_operator_label: 'Operadora',
  event_multipoint_label: 'Multiponto',
  event_originType_label: 'Tipo da Origem',
  event_datePayment_label: 'Data do Pagamento',
  event_paymentMethod_label: 'Forma do Pagamento',
  event_paymentValue_label: 'Valor do Pagamento',
  event_cashierReopened_label: 'Caixa Reaberto',
  event_guideValue_label: 'Valor do Guia',
  event_tourDate_label: 'Data do passeio',
  event_activityName_label: 'Nome do Passeio',
  event_ticketCode_label: 'Código do Ingresso',
  event_cardNumber_label: 'Número do Cartão',
  event_operatorName_label: 'Nome do Agente',
  event_userName_label: 'Nome do Usuário',
  event_valueOfTaxIss_label: 'Valor do Imposto(ISS)',
  event_attractiveValue_label: 'Valor do Atrativo',
  event_advanceAmount_label: 'Valor Adiantado',
  event_agencyValue_label: 'Valor da Agência',
  event_agencyFee_label: 'Taxa da Agência',
  event_operatorFee_label: 'Taxa da Operadora',
  event_antifraudFee_label: 'Taxa da Antifraude',
  event_mdrFee_label: 'Taxa de MDR',
  event_payFee_label: 'Taxa do Pay',
  event_cancelattionDate_label: 'Data do Cancelamento',
  event_creditDate_label: 'Data do Credito',
  event_debitDate_label: 'Data do Debito',
  event_dateOfReturn_label: 'Data da Devolução',
  event_amountCanceled_label: 'Valor Cancelado',
  event_amountReturned_label: 'Valor Devolvido',
  event_amountDeposited_label: 'Valor Depositado',
  event_agencyCancellationFee_label: 'Taxa de Cancelamento Agência',
  event_partnerCancellationFee_label: 'Taxa de Cancelamento Parceiros',
  event_canceledCardFee_label: 'Taxa de Cartão Cancelada',
  event_feeCharged_label: 'Taxa Cobrada',
  event_attractiveCancellationFee_label: 'Taxa de Cancelamento Atrativo',
  event_attractiveFee_label: 'Taxa de Atrativo',
  event_orbFee_label: 'Taxa do Orb',
  event_tuitionAmount_label: 'Valor da Mensalidade',
  event_ticketBySplit_label: 'Ingresso por Split',
  event_paymentInTheAttractive_label: 'Pagamento no Atrativo',
  event_invoiceIssuanceDate_label: 'Data da Emissão da Fatura',
  event_emissionDate_label: 'Data da Emissão',
  event_amountOfTaxToBeCollected_label: 'Valor do Imposto a Recolher',
  event_invoiceTaxAmount_label: 'Valor do Imposto da Fatura',
  event_advanceValue_label: 'Valor Antecipado',
  event_ticketOriginType_label: 'Tipo de Origem do Bilhete',
  event_tickets_label: 'Ingressos',
  event_ticketPrice_label: 'Valor do Ingresso',
  event_invoiceCancellationDate_label: 'Data de Cancelamento da Fatura',
  event_refundDate_label: 'Data de Estorno',
  event_transferDate_label: 'Data de Transferência',
  event_dueDate_label: 'Data de Vencimento',
  event_invoiceDueDate_label: 'Data de Vencimento da Fatura',
  event_invoiceAmount_label: 'Valor da Fatura',
  event_transferAmount_label: 'Valor da Transferência',
  event_billetValue_label: 'Valor do Boleto',
  event_description_label: 'Descrição',
  event_amountPaid_label: 'Valor Pago',
  event_advanceDate_label: 'Data de Adiantamento',
  event_depositDate_label: 'Data de Depósito',
  event_CCChartOfAccounts_label: 'Plano de Contas CC',
  event_CCOriginChartOfAccounts_label: 'Plano de Contas CC Origem',
  event_CCDestinationChartOfAccounts_label: 'Plano de Contas CC Destino',
  event_machineNumber_label: 'Número da Máquina',
  event_amountRefund_label: 'Valor Estornado',
  event_billetFee_label: 'Taxa do Boleto',
  event_noteIssuanceDate_label: 'Data de Emissão da Nota',
  event_date_label: 'Data',
  event_profit_label: 'Lucro',
  event_dateReceivement_label: 'Data de Recebimento',
  event_withdrawalDate_label: 'Data da Retirada',
  event_referenceDate_label: 'Data de Referência',
  event_amountReceivable_label: 'Valor a Receber',
  event_value_label: 'Valor',
  event_withdrawalValue_label: 'Valor da Retirada',
  event_amountReceived_label: 'Valor Recebido',
  event_amountPayable_label: 'Valor a Pagar',
  event_bankFee_label: 'Taxa Bancária',
  event_bankNumber_label: 'Número do Banco',
  event_bankName_label: 'Nome do Banco',
  event_agencyNumber_label: 'Número da Agência',
  event_accountNumber_label: 'Número da Conta',
  event_accountType_label: 'Tipo da Conta',
  event_originSystem_label: 'Sistema de Origem',
  event_enabled_label: 'Habilitado',
  event_paymentMethods_label: 'Métodos de Pagamentos',
  event_operationType_label: 'Tipo da Operação',
  event_installmentsBeignPaid_label: 'Parcelas sendo Paga',
  event_numberOfInstallmentsOfTheSale_label: 'Quantidade de Parcelas da Venda',
  event_saleDate_label: 'Data da Venda',
  event_grossValue_label: 'Valor Bruto',
  event_netValue_label: 'Valor Líquido',
  event_totalAmountOfFees_label: 'Valor Total das Taxas',
  event_breakdownOfFees_label: 'Discriminação das Taxas',
  event_xpertFee_label: 'Taxa do Xpert',
  event_transactionFee_label: 'Taxa por Transação',

  supplier_pageTitleSuppliers_label: 'Fornecedores',
  supplier_contactDetailsMenuTitle_label: 'Dados de contato',
  supplier_addressMenuTitle_label: 'Endereço',
  supplier_bankAccountMenuTitle_label: 'Dados bancários',
  supplier_tableColumnTitleCorporateName_label: 'Razão Social',
  supplier_tableColumnTitleSupplierDocument_label: 'Documento',
  supplier_tableColumnTitleFancyName_label: 'Nome Fantasia',
  supplier_tableColumnTitleStatus_label: 'Estado',
  supplier_tableColumnTitleAction_label: 'Ação',
  supplier_statusValueDisabled_label: 'Inativo',
  supplier_statusValueEnabled_label: 'Ativo',
  supplier_statusValueAll_label: 'Todos',
  supplier_newSupplierButton_label: 'Cadastrar novo fornecedor',
  supplier_modalNewSupplierTitle_label: 'Cadastrar fornecedor',
  supplier_modalEditSupplierTitle_label: 'Editar fornecedor',
  supplier_listButton_label: 'Pesquisar',
  supplier_legalPerson_label: 'Pessoa Jurídica',
  supplier_naturalPerson_label: 'Pessoa Física',
  supplier_corporateNameRequired_formErrorLabel: 'Razão social é obrigatório',
  supplier_supplierTypeRequired_formErrorLabel: 'Tipo do fornecedor é obrigatório',
  supplier_zipCodeMaxCharacters_formErrorLabel: 'O CEP deve ter no máximo 8 caracteres',
  supplier_stateMaxCharacters_formErrorLabel: 'O estado deve ter apenas 2 caracteres',
  supplier_corporateName_inputLabel: 'Razão social',
  supplier_fancyName_inputLabel: 'Nome fantasia',
  supplier_supplierDocument_inputLabel: 'Documento',
  supplier_supplierType_inputLabel: 'Tipo do fornecedor',
  supplier_municipalRegistration_inputLabel: 'Inscrição municipal',
  supplier_address_inputLabel: 'Endereço',
  supplier_neighborhood_inputLabel: 'Bairro',
  supplier_cityName_inputLabel: 'Cidade',
  supplier_state_inputLabel: 'Estado',
  supplier_country_inputLabel: 'País',
  supplier_zipCode_inputLabel: 'CEP',
  supplier_emails_inputLabel: 'Emails',
  supplier_phoneNumbers_inputLabel: 'Telefones',
  supplier_contactNames_inputLabel: 'Pessoas de contato',
  supplier_observation_inputLabel: 'Observação',
  supplier_corporateName_placeholderLabel: 'Digite a razão social',
  supplier_fancyName_placeholderLabel: 'Digite o nome fantasia',
  supplier_supplierDocument_placeholderLabel: 'Digite o CPF/CNPJ',
  supplier_supplierType_placeholderLabel: 'Selecione o tipo do fornecedor',
  supplier_enabled_placeholderLabel: 'Selecione o estado',
  supplier_municipalRegistration_placeholderLabel: 'Digite a inscrição municipal',
  supplier_address_placeholderLabel: 'Digite o endereço',
  supplier_neighborhood_placeholderLabel: 'Digite o bairro',
  supplier_cityName_placeholderLabel: 'Digite a cidade',
  supplier_state_placeholderLabel: 'Digite o estado',
  supplier_country_placeholderLabel: 'Digite o país',
  supplier_zipCode_placeholderLabel: 'Digite o CEP',
  supplier_emails_placeholderLabel: 'Digite os emails de contato',
  supplier_phoneNumbers_placeholderLabel: 'Digite os telefones de contato',
  supplier_contactNames_placeholderLabel: 'Digite as pessoas de contato',
  supplier_observation_placeholderLabel: 'Digite a observação',
  supplier_errorfecthingSuppliers_toast: 'Erro ao buscar os fornecedores',
  supplier_createSupplier_toast: 'Fornecedor cadastrado com sucesso',
  supplier_errorCreateSupplier_toast: 'Erro ao criar fornecedor',
  supplier_errorGetByIdSupplier_toast: 'Erro ao carregar o fornecedor',
  supplier_editSupplier_toast: 'Fornecedor atualizado com sucesso',
  supplier_errorEditSupplier_toast: 'Erro ao editar fornecedor',
  supplier_createButton_label: 'Cadastrar',
  supplier_removeDialogTitle_label: 'Excluir',
  supplier_removeDialogMessage_label: 'Tem certeza que deseja excluir o fornecedor?',
  supplier_removeSupplier_toast: 'Fornecedor removido com sucesso',
  supplier_errorRemoveSupplier_toast: 'Erro ao remover fornecedor',
  supplier_modalAuditSupplierTitle_label: 'Histórico de alterações Fornecedores',
  supplier_errorAuditSupplier_toast: 'Erro ao carregar histórico de fornecedores',
  supplier_listSupplierBankAccounts_label: 'Listar contas bancárias',
  supplier_errorLoadSupplierOptions_toast: 'Erro ao carregar as opções de fornecedores',
  supplier_supplierCannotDestroy_toast: 'Fornecedor em uso em Contas a Pagar não pode ser apagado',
  supplier_supplierAlreadyRegistered_toast: 'Esse documento já foi cadastrado em um outro fornecedor',

  supplier_bank_account_pageTitleSupplierBankAccounts_label: 'Contas Bancárias de',
  supplier_bank_account_newSupplierBankAccountButton_label: 'Cadastrar nova conta bancária de fornecedor',
  supplier_bank_account_modalAuditSupplierBankAccountTitle_label: 'Histórico de alterações Contas Bancárias dos Fornecedores',
  supplier_bank_account_tableColumnTitleBankName_label: 'Banco',
  supplier_bank_account_tableColumnTitleBranch_label: 'Agência',
  supplier_bank_account_tableColumnTitleAccountNumber_label: 'Conta',
  supplier_bank_account_tableColumnTitleStatus_label: 'Estado',
  supplier_bank_account_tableColumnTitleAction_label: 'Ação',
  supplier_bank_account_statusValueDisabled_label: 'Inativa',
  supplier_bank_account_statusValueEnabled_label: 'Ativa',
  supplier_bank_account_accountTypeValueCheckingAccount_label: 'Conta corrente',
  supplier_bank_account_accountTypeValueSavingsAccount_label: 'Conta poupança',
  supplier_bank_account_modalCreateSupplierBankAccountTitle_label: 'Cadastrar nova conta bancária do fornecedor',
  supplier_bank_account_bankName_inputLabel: 'Banco',
  supplier_bank_account_branch_inputLabel: 'Agência',
  supplier_bank_account_accountNumber_inputLabel: 'Conta',
  supplier_bank_account_accountType_inputLabel: 'Tipo da conta',
  supplier_bank_account_enabled_inputLabel: 'Estado',
  supplier_bank_account_bankName_placeholderLabel: 'Digite o banco',
  supplier_bank_account_branch_placeholderLabel: 'Digite a agência',
  supplier_bank_account_accountNumber_placeholderLabel: 'Digite o número da conta',
  supplier_bank_account_accountType_placeholderLabel: 'Selecione o tipo da conta',
  supplier_bank_account_enabled_placeholderLabel: 'Selecione o estado',
  supplier_bank_account_bankNameRequired_formErrorLabel: 'Banco é obrigatório',
  supplier_bank_account_branchRequired_formErrorLabel: 'Agência é obrigatória',
  supplier_bank_account_accountNumberRequired_formErrorLabel: 'Conta é obrigatória',
  supplier_bank_account_accountTypeRequired_formErrorLabel: 'Tipo da conta é obrigatória',
  supplier_bank_account_enabledRequired_formErrorLabel: 'Estado é obrigatório',
  supplier_bank_account_errorFecthingSupplierBankAccounts_toast: 'Erro ao buscar as contas bancárias dos fornecedores',
  supplier_bank_account_createSupplierBankAccount_toast: 'Conta bancária do fornecedor criada com sucesso',
  supplier_bank_account_errorCreateSupplierBankAccount_toast: 'Erro ao criar conta bancária do fornecedor',
  supplier_bank_account_editSupplierBankAccount_toast: 'Conta bancária do fornecedor editada com sucesso',
  supplier_bank_account_errorEditSupplierBankAccount_toast: 'Erro ao editar conta bancária do fornecedor',
  supplier_bank_account_modalEditSupplierBankAccountTitle_label: 'Editar conta bancária do fornecedor',
  supplier_bank_account_removeSupplierBankAccount_toast: 'Conta bancária do fornecedor removida com sucesso',
  supplier_bank_account_errorRemoveSupplierBankAccount_toast: 'Erro ao remover conta bancária do fornecedor',
  supplier_bank_account_removeDialogTitle_label: 'Excluir',
  supplier_bank_account_removeDialogMessage_label: 'Tem certeza que deseja excluir a conta bancária do fornecedor?',

  event_processor_menuTitleEventsProcessors_label: 'Processadores de Eventos',
  event_processor_pageTitleEventsProcessors_label: 'Processadores de Eventos',
  event_processor_tableColumnTitleEventType_label: 'Nome do processador',
  event_tableColumnTitleEntryCondition_label: 'Condição de entrada',
  event_processor_tableColumnTitleStatus_label: 'Status',
  event_processor_tableColumnTitleActions_label: 'Ações',
  event_processor_errorFetchingEventsProcessors_toast: 'Erro ao buscar os processadores de eventos',
  event_processor_errorRemoveEventProcessor_toast: 'Erro ao deletar o processador de evento',
  event_processor_successDeletedEventProcessor_toast: 'Processador de evento deletado com sucesso',
  event_processor_areYouSureToDeleteProcessors_label: 'Tem certeza que deseja excluir o processador?',
  event_processor_processorName_inputLabel: 'Nome do processador',
  event_processor_entryConditionField_inputLabel: 'Campo da condição de entrada',
  event_processor_entryConditionValue_inputLabel: 'Valor da condição de entrada',
  event_processor_status_inputLabel: 'Status',
  event_processor_enterProcessorName_placeholderLabel: 'Digite o nome do processador',
  event_processor_selectInputConditionField_placeholderLabel: 'Selecione o campo da condição de entrada',
  event_processor_selectInputConditionValue_placeholderLabel: 'Selecione o valor da condição de entrada',
  event_processor_selectTheStatus_placeholderLabel: 'Selecione o status',
  event_processor_condition_label: [
    '<<NUMBER>> condição',
    '<<NUMBER>> condições'
  ],
  event_processor_entryConditions_label: [
    'Condição de entrada',
    'Condições de entrada'
  ],
  event_processor_newProcessor_label: 'Novo processador',
  event_processor_nameAndDescription_label: 'Nome e Descrição',
  event_processor_saveAndContinue_label: 'Salvar e Continuar',
  event_processor_selectionAndValidationEvents_label: 'Seleção e validação dos eventos',
  event_processor_automationRules_label: 'Regras de automação',
  event_processor_nameRequired_formErrorLabel: 'Nome é obrigatório',
  event_processor_sucessRegisteredEventProcessor_toast: 'Processador de Eventos cadastrado(a) com sucesso',
  event_processor_errorRegisteringdEventProcessor_toast: 'Erro ao cadastrar o Processador de Eventos',
  event_processor_description_inputLabel: 'Descrição',
  event_processor_enterDescription_placeholderLabel: 'Digite uma descrição',
  event_processor_editProcessor_label: 'Editar processador',
  event_processor_finishTheFisrtSession_toast: 'Finalize a primeira sessão',
  event_processor_selectEventDetails_label: 'Selecione um evento para ver os detalhes',
  event_processor_eventWithoutDetails_label: 'Evento sem detalhes',
  event_processor_listEvents_label: 'Listar eventos',
  event_processor_tableColumnTitleField_label: 'Campo',
  event_processor_tableColumnTitleValue_label: 'Valor',
  event_processor_tableColumnTitleAction_label: 'Ação',
  event_processor_addInProcessor_label: 'Adicionar no processador',
  event_processor_pageTitleSelectAnEvent_label: 'Selecione um evento',
  event_processor_selectedEvent_label: 'Evento selecionado',
  event_processor_selectEvent_label: 'Selecionar evento',
  event_processor_createEventEntryConditions_label: 'Crie as condições de entrada dos eventos',
  event_processor_selectingFieldAddConditions_label: 'Ao selecionar um campo, adicione as condições que ele deve ser submetido.',
  event_processor_conditionAddedToProcessor_toast: 'Condição adicionada ao processador',
  event_processor_errorAddingConditionInProcessor_toast: 'Erro ao adicionar a condição no processador',
  event_processor_eventProcessorUpdatedSuccess_toast: 'Processador de Eventos atualizado com sucesso',
  event_processor_errorUpdatingEventProcessor_toast: 'Erro ao atualizar o Processador de Eventos',
  event_processor_errorFetchingCondition_toast: 'Erro ao buscar a condição',
  event_processor_errorFetchingConditions_toast: 'Erro ao buscar as condições',
  event_processor_errorFectingEventProcessor_toast: 'Erro ao buscar o processador de evento',
  event_processor_conditionRemovedSuccess_toast: 'Condição removida com sucesso',
  event_processor_errorRemovingCondition_toast: 'Erro ao remover a condição',
  event_processor_conditionSuccessUpdated_toast: 'Condição atualizada com sucesso',
  event_processor_errorUpdatingCondition_toast: 'Erro ao atualizar a condição',
  event_processor_uniqueConditionsMessage_label: 'Algumas condições de entrada já estão sendo processadas por um ou mais processadores de eventos já existentes.',
  event_processor_processorWithSimilarConditions_label: [
    'Processador com condições similar',
    'Processadores com condições similar'
  ],
  event_processor_eventServicedByProcessor_label: [
    'Evento atendido pelo processador',
    'Eventos atendidos pelo processador'
  ],
  event_processor_matchedEventsSubtitle_label: 'Para listar os eventos processados, deve cadastrar pelo menos 1 condição de entrada',
  event_processor_doesNotHaveEventsServicedByProcessor_label: 'Não possui eventos atendidos pelo processador',
  event_processor_errorFetchingMatchingEvents_toast: 'Erro ao buscar eventos correspondentes',
  event_processor_erroFetchingUniqueConditions_toast: 'Erro ao buscas condições únicas',
  event_processor_tableColumnTitleOperationType_label: 'Tipo de operação',
  event_processor_tableColumnTitleDate_label: 'Data',
  event_processor_tableColumnTitleDestinationAccount_label: 'Conta destino',
  event_processor_registeredAutomationRules_label: 'Regras de automação cadastradas',
  event_processor_tableColumnTitleRealeaseDate_label: 'Data de lançamento',
  event_processor_automationRulesSimulator_label: 'Simulador de Regras de Automação',
  event_processor_theAutomationRulesAre_label: 'As regras de automação são',
  event_processor_matchedEventsTiteInAutomationRules_label: 'Para criar as regras de automação, selecione o evento',
  event_processor_errorFetchingAutomationRules_toast: 'Erro ao buscar as regras de automação',
  event_processor_errorWhenFetchingSimulationsAutomationRules_toast: 'Erro ao buscar as simulações das regras de automação',
  event_processor_errorRemovingAutomationRule_toast: 'Erro ao remover a regra de automação',
  event_processor_automationRuleSuccessRemoved_toast: 'Regra de automação removida com sucesso',
  event_processor_action_inputLabel: 'Ação',
  event_processor_selectTheAction_placeholderLabel: 'Selecione a ação',
  event_processor_value_inputLabel: 'Valor',
  event_processor_enterTheValue_placeholderLabel: 'Digite o valor',
  event_processor_for_inputLabel: 'For',
  event_processor_selectTheComparator_placeholderLabel: 'Selecione o comparador',
  event_processor_operationType_inputLabel: 'Tipo de operação',
  event_processor_selectTheTypeOfOperation_placeholderLabel: 'Selecione o tipo de operação',
  event_processor_date_inputLabel: 'Data',
  event_processsor_enterTheDateFormula_placeholderLabel: 'Digite a fórmula da data',
  event_processor_referenceDate_inputLabel: 'Data de referência',
  event_processor_enterTheReferenceDateFormula_placeholderLabel: 'Digite a fórmula da data de referência',
  event_processor_account_inputLabel: 'Conta',
  event_processor_selectTheAccount_placeholderLabel: 'Selecione a conta',
  event_processor_accountViaFormula_inputLabel: 'Conta via fórmula',
  event_processor_selectTheAccountFormula_placeholderLabel: 'Selecione a formula da conta',
  event_processor_installmentDate_label: 'Cria as datas para as parcelas de acordo com as regras da bandeira do cartão de crédito',
  event_processor_installmentValue_label: 'Arredonda o valor da parcela de acordo com as regras de arredondamento do gateway (operadora)',
  event_processor_installmentFeeValue_label: 'Arredonda o valor da taxa da parcela de acordo com as regras de arredondamento do gateway (operadora)',
  event_processor_getBankAccount_label: 'Retorna a Conta Bancária a ser movimentada do campo do evento',
  event_processor_getAccountFromExpenseRevenue_label: 'Retorna a conta associada ao plano de contas (se houver) do Tipo de Depsesa/Receita informado no campo do evento',
  event_processor_binamikFee_label: 'Calcula o valor total da taxa da Binamik para a venda e/ou estorno',
  event_processor_fromCents_label: 'Converte um valor em centavos para monetário (divide por 100), ex: from_cents(450) = 4.50',
  event_processor_refundDate_label: "Calcula a data de reembolso (dia útil) de acordo com as regras da Operador e da bandeira do Cartão de Crédito. Esta fórmula somente pode ser usada nas ações 'Criar Lançamento Futuro via fórmula para reembolso em Cartão de Crédito' e 'Acelerar parcelas para reembolso em Cartão de Crédito'.",
  event_processor_toDate_label: 'Converte event_field para uma data, se possível',
  event_processor_durationOneMonth_label: 'Duração de 1 mês, considera o número de dias do mês',
  event_processor_durationTwentySixDays_label: 'Duração de 26 dias',
  event_processor_nextBusinessDays_label: 'Retorna o dia útil seguinte a data calculada, se esta não for um dia útil',
  event_processor_nextBusinessDaysOneMoreMonth_label: 'Dia útil de: data em event_field mais um mês ',
  event_processor_operationIfElse_label: 'SE inc < event_field a fórmula seguinte retorna event_field_2 SENÃO retorna round(event_field_2)',
  event_processor_roundValue_label: 'Arredonda o valor de event_field',
  event_processor_roundValueWithTwoDecimalPlaces_label: 'Arredodamento com 2 casas decimais',
  event_processor_roundValueUp_label: 'Arrendoda para cima (próximo inteiro)',
  event_processor_roundValueDown_label: 'Arredonda para baixo (inteiro anterior)',
  event_processor_allowedFormulas_label: 'Fórmulas permitidas',
  event_processor_description_label: 'Descrição',
  event_processor_formula_label: 'Fórmula',
  event_processor_operation_label: 'Operação',
  event_processor_newAutomationRule_label: 'Nova regra de automação',
  event_processor_pageTitleEditingAutomationRule_label: 'Editando regra de automação',
  event_processor_pageTitleNewAutomationRule_label: 'Nova regra de automação',
  event_processor_rules_label: 'Regras',
  event_processor_fieldOfAllowedEvents_label: 'Campo de eventos permitidos',
  event_processor_automationRuleActionRequired_formErrorLabel: 'Ação é obrigatória',
  event_processor_operationTypeRequired_formErrorLabel: 'Tipo de operação é obrigatório',
  event_processor_dateWithFormulaRequired_formErrorLabel: 'Data é obrigatória',
  event_processor_referenceDateWithFormulaRequired_formErrorLabel: 'Data de referência é obrigatória',
  event_processor_automationRuleStatusRequired_formErrorLabel: 'Status é obrigatório',
  event_processor_accountIdRequired_formErrorLabel: 'Conta é obrigatória',
  event_processor_accountFormulaRequired_formErrorLabel: 'Conta via fórmula é obrigatória',
  event_processor_valueRequired_formErrorLabel: 'Valor é obrigatório',
  event_processor_automationRuleCreatedSuccessfully_toast: 'Regra de automação criada com sucesso',
  event_processor_errorCreatingAutomationRule_toast: 'Erro ao criar a regra de automação',
  event_processor_errorFetchingAutomationRuleActions_toast: 'Erro ao buscar as ações da regra de automação',
  event_processor_errorFetchingAccountFormulasForAutomationRule_toast: 'Erro ao buscar as fórmulas das contas para a regra de automação',
  event_processor_errorFetchingAccountsForAutomationRule_toast: 'Erro ao buscar as contas para a regra de automação',
  event_processor_automationRuleUpdatedSuccessfully_toast: 'Regra de automação atualizada com sucesso',
  event_processor_errorUpdatingAutomationRule_toast: 'Erro ao atualizar a regra de automação',
  event_processor_errorFetchingAutomationRule_toast: 'Erro ao buscar a regra de automação',
  event_processor_uniqueConditionsEntryConditionsNotUnique_toast: 'Não é possivel ativar o processador, pois as condições de entradas não são únicas',
  event_processor_simulatonsInvalidAutomationRules_toast: 'Não é possivel ativar o processador, há regras de automações inválidas',
  event_processor_conditionsNoEntryConditions_toast: 'Não é possivel ativar o processador, não há condições de entrada',
  event_processor_cashFlow_label: 'Fluxo de caixa',
  event_processor_iterationField_inputLabel: 'Campo de iteração',
  event_processor_enterTheIterationField_placeholderLabel: 'Digite o campo de iteração',
  event_processor_descriptionRequired_formErrorLabel: 'Descrição obrigatória',
  event_processor_automationRuleAccountIdIsMissing_toast: 'Conta é obrigatória',
  event_processor_automationRuleAccountFormulaIsMissing_toast: 'Conta via formula é obrigatória',
  // options account formulas for automation rule
  event_processor_getEventBankAccount_label: 'Conta Bancária do Evento',
  event_processor_getAccountablePlanFromBankAccount_label: 'Plano de Contas da Conta Bancária do Evento',
  event_processor_getAccountblePlanFromEventRevenue_label: 'Plano de Contas do Tipo de Receita do Evento',
  event_processor_getAccountblePlanFromEventExpense_label: 'Plano de Contas do Tipo de Despesa do Evento',
  // automation rule status
  event_processor_automationRuleWaitingStatus_label: 'Aguardando data do lançamento futuro',
  event_processor_automationRuleNeedConfirmationStatus_label: 'Precisa de Confirmação',
  event_processor_automationRuleProcessedStatus_label: 'Processado',
  event_processor_automationRuleBilledStatus_label: 'Faturado',
  event_processor_automationRuleCanceledStatus_label: 'Cancelado',
  event_processor_automationRuleDoneStatus_label: 'Realizado',
  // comparion_type
  event_processor_equal_label: 'Igual a',
  event_processor_lessThan_label: 'Menor que',
  event_processor_greaterThan_label: 'Maior que',
  event_processor_in_label: 'Um de',
  event_processor_notEqual_label: 'Diferente de',
  event_processor_notIn_label: 'Nenhum de',
  // actions
  event_processor_moveAccountForecastWithFormula_label: 'Criar Lançamento Futuro',
  event_processor_moveAccountWithFormula_label: 'Criar Movimento Conta',
  event_processor_moveAccountForecastWithFormulaForRefund_label: 'Criar Lançamento Futuro para Estorno de Cartão de Crédito',
  event_processor_updateAccountForecastWithFormula_label: 'Atualizar Status do Lançamento Futuro',
  event_processor_updateAccountMovement_label: 'Atualizar Status do Movimento Conta',
  event_processor_accelerateInstallmentsForRefund_label: 'Adiantar parcelas para estorno de Cartão de Crédito',
  event_processor_updateTicketStatus_label: 'Atualizar Status do Ingresso',
  event_processor_analyzeInvoicePartialPayment_label: 'Analisar pagamento parcial de Fatura',
  event_processor_createUpdateBankAccount_label: 'Cadastrar/atualizar Conta Bancária',
  event_processor_moveBankAccount_label: 'Registrar movimento em Conta Digital',
  event_processor_initBilling_label: 'Inicializar o Billing para uma empresa',
  event_processor_updateBilling_label: 'Atualizar Eventos de modelo e Processadores',
  event_processor_deleteBilling_label: 'Apagar Eventos de modelo e Processadores',

  creditcard_movement_errorLoadingTheFinancialCardsSchedule_toast: 'Erro ao carrega a agenda financeira cartões',
  creditcard_movement_errorWhenExportingFinancialCards_toast: 'Erro ao exportar agenda financeira cartões',

  flow_movment_errorLoadingCompleteFinancialSchedule_toast: 'Erro ao carregar agenda financeira completa',
  flow_movment_errorWhenExportingCompleteFinancialSchedule_toast: 'Erro ao exportar agenda financeira completa',
  flow_movement_date_label: 'Data',
  flow_movement_description_label: 'Descrição',
  flow_movement_releaseType_label: 'Tipo de lançamento',
  flow_movement_operation_label: 'Operação',
  flow_movement_value_label: 'Valor',
  flow_movement_status_label: 'Status',
  flow_movement_totalPayable_label: 'Total a Pagar',
  flow_movement_totalReceivable_label: 'Total a Receber',
  flow_movement_pageTitleFullFinancialSchedule_label: 'Agenda Financeira Completa',
  flow_movement_movements_label: 'Movimentações',
  flow_movement_viewCanceledReleases_label: 'Visualizar lançamentos cancelados',
  flow_movement_hideCanceledReleases_label: 'Ocultar lançamentos cancelados',
  flow_movement_selectAperiodToViewMovements_label: 'Selecione um período para visualizar as movimentações',
  flow_movement_searchByKeywordOrDescription_label: 'Buscar por palavra chave ou descrição',
  flow_movement_conciliate_label: 'Conciliado',

  creditcard_dashboard_menuTitleCards_label: 'Cartões',
  creditcard_dashboard_receivables_label: 'Recebíveis',
  creditcard_dashboard_creditCardConciliations_label: 'Conciliações de Cartões',

  bank_action_dashboard_titleBankConciliations_label: 'Conciliações Bancárias',
  bank_action_dashboard_titleBankStatements_label: 'Extratos Bancários',
  bank_action_dashboard_menuTitleBankActions_label: 'Ações Bancárias',

  accounting_function_dashboard_titleAccountingsFunctions_label: 'Funções Contábeis',
  accounting_function_dashboard_titleAccountsPlan_label: 'Plano de Contas',

  apiCodeMapping_billingUnmapped_toast: 'Erro não mapeado no servidor',
  apiCodeMapping_orbUserUnauthorized_toast: 'Token expirado ou inválido, por favor logar novamente na plataforma',
  apiCodeMapping_internalServerError_toast: 'Erro interno no servidor',
  apiCodeMapping_orbUserForbidden_toast: 'Você não possui permissão',

  audit_value_label: 'Valor',
  audit_serviceName_label: 'Nome do serviço',
  audit_paymentMethod_label: 'Tipo de pagamento',
  audit_date_label: 'Data',
  audit_taxes_label: 'Taxas',
  audit_referenceDate_label: 'Data de referência',
  audit_observation_label: 'Observação',
  audit_status_label: 'Status',
  audit_createdAt_label: 'Data de alteração',
  audit_event_label: 'Tipo de alteração',
  audit_userName_label: 'Nome do usuário',
  audit_paymentDay_label: 'Dia do pagamento',
  audit_enabled_label: 'Estado',
  audit_expenseRevenueType_label: 'Tipo',
  audit_description_label: 'Descrição',
  audit_bankAccountName_label: 'Conta bancária',
  audit_expenseName_label: 'Tipo de despesa',
  audit_revenueName_label: 'Tipo de receita',
  audit_accountablePlanName_label: 'Plano de conta',
  audit_municipalRegistration_label: 'Inscrição municipal',
  audit_supplierType_label: 'Tipo do fornecedor',
  audit_supplierDocument_label: 'Documento',
  audit_corporateName_label: 'Razão social',
  audit_fancyName_label: 'Nome fantasia',
  audit_address_label: 'Endereço',
  audit_neighborhood_label: 'Bairro',
  audit_state_label: 'Estado',
  audit_country_label: 'País',
  audit_zipCode_label: 'CEP',
  audit_cityName_label: 'Cidade',
  audit_emails_label: 'Emails',
  audit_phoneNumbers_label: 'Telefones',
  audit_contactNames_label: 'Pessoas de contato',
  audit_bankName_label: 'Banco',
  audit_branch_label: 'Agência',
  audit_accountType_label: 'Tipo da conta',
  audit_accountNumber_label: 'Conta',
  audit_emptyData_toast: 'Não possui histórico de alterações',
  audit_pixValue_label: 'PIX',
  audit_bankBilletValue_label: 'Boleto',
  audit_bankTransferValue_label: 'Transferência Bancária',
  audit_creditValue_label: 'Cartão de Crédito',
  audit_debitValue_label: 'Cartão de Débito',
  audit_cashValue_label: 'Dinheiro',
  audit_createValue_label: 'Cadastro',
  audit_updateValue_label: 'Edição',
  audit_expenseValue_label: 'Despesa',
  audit_revenueValue_label: 'Receita',
  audit_savingsAccountValue_label: 'Conta Poupança',
  audit_checkingAccountValue_label: 'Conta Corrente',
  audit_scheduledValue_label: 'Agendado',
  audit_canceledValue_label: 'Cancelado',
  audit_payedValue_label: 'Pago',
  audit_receivedValue_label: 'Recebido',
  audit_refundedValue_label: 'Estornado',
  audit_enabledValue_label: 'Ativo',
  audit_disabledValue_label: 'Inativo',
  audit_defaultModalTitle_label: 'Histórico de alterações',
  audit_transferMethod_label: 'Método de transferência',
  audit_originAccount_label: 'Conta de origem',
  audit_destinationAccount_label: 'Conta de destino',
  audit_ivoiceType_label: 'Tipo fatura',
  audit_guideInvoice_label: 'Fatura guia',
  audit_affiliateInoice_label: 'Fatura afiliado',
  audit_affiliatePayment_label: 'Pagamento afiliado',
  audit_offlineInvoice_label: 'Fatura offline',
  audit_offlinePayment_label: 'Pagamento offline',
  audit_invoicePayment_label: 'Pagamento fatura',
  audit_invoice_label: 'Fatura',
  audit_forgottenExpenses_label: 'Contas a pagar',
  audit_forgottenRevenues_label: 'Contas a receber',
  audit_maxRecurrences_label: 'Pagamentos recorrentes',
  audit_expenseRevenueName_label: 'Tipo de despesa do trade turístico',

  bank_transfer_originAccount_label: 'Conta de Origem',
  bank_transfer_destinationAccount_label: 'Conta de Destino',
  bank_transfer_noTransfersFoundBetweenBankAccounts_label: 'Não foram encontradas Transferências entre Contas Bancárias',
  bank_transfer_transferBetweenBankAccount_label: 'Transferência entre Contas Bancárias',
  bank_transfer_errorWhenSearchingForTransfersBetweenBankAccounts_toast: 'Erro ao buscar transferências entre contas bancárias',
  bank_transfer_newTransfer_label: 'Nova Transferência',
  bank_transfer_editBankTransfer_label: 'Editar Transferência Bancária',
  bank_transfer_registerBankTransfer_label: 'Cadastrar Transferência Bancária',
  bank_transfer_transferBetweenAccountCreatedSuccess_toast: 'Transferência entre Contas criada com sucesso',
  bank_transfer_errorCreatingTransferBetweenAccount_toast: 'Erro ao criar a transferência entre contas',
  bank_transfer_errorUpdatingTransferBetweenAccount_toast: 'Erro ao atualizar a transferência entre contas',
  bank_transfer_transferBetweenAccountUpdatedSuccess_toast: 'Transferência entre Contas atualizada com sucesso',
  bank_transfer_errorUpdatingAccountTransfer_toast: 'Erro ao atualizar a transferência entre contas',
  bank_transfer_transferMethod_label: 'Método de Transferência',
  bank_transfer_selectTheMethod_label: 'Selecione o método',
  bank_transfer_transferDate_label: 'Data da Transferência',
  bank_transfer_originAccountRequired_label: 'Conta de Origem obrigatória',
  bank_transfer_destinationAccountRequired_label: 'Conta de Destino obrigatória',
  bank_transfer_transferMethodRequired_label: 'Método de Transferência obrigatório',
  bank_transfer_transferDateRequired_label: 'Data de Transferência obrigatória',
  bank_transfer_changeHistoryTransferBetweenAccounts_label: 'Histórico de alterações Transferência entre Contas',
  bank_transfer_errorWhenSearchingForTransferHistoryBetweenAccounts_toast: 'Erro ao buscar histórico de transferência entre contas',
  bank_transfer_theSourceAccountMustBeDifferentFromTheTargetAccount_label: 'A conta de origem deve ser diferente da conta de destino',
  bank_transfer_transferBetweenAccounts_label: 'Transferência entre Contas',
  bank_transfer_transferSentTo_label: 'Transferência enviada para',
  bank_transfer_transferReceivedFrom_label: 'Transferência recebida de',

  expense_revenue_dashboard_accountsToPayAndReceive_label: 'Contas a Pagar e a Receber',
  expense_revenue_dashboard_accountsToPay_label: 'Contas a Pagar',
  expense_revenue_dashboard_accountsReceivable_label: 'Contas a Receber',
  expense_revenue_dashboad_typeOfExpenseRevenue_label: 'Tipo de Despesa/Receita',

  configuration_dashboard_menuTitleConfigurations_label: 'Configurações',
  configuration_pageTitleConfiguration_label: 'Configurações',
  configuration_forgottenExpenses_inputLabel: 'Contas a pagar agendadas não pagas nos últimos:',
  configuration_forgottenRevenues_inputLabel: 'Contas a receber agendadas não recebidas nos últimos:',
  configuration_maxRecurrences_inputLabel: 'Número máximo de pagamentos recorrentes em até:',
  configuration_expenseTradeId_inputLabel: 'Tipo de despesa do trade turístico',
  configuration_updatedConfiguration_toast: 'Sucesso ao atualizar as configurações da empresa',
  configuration_errorWhenSearchingForConfigurations_toast: 'Erro ao buscas pelas configurações da empresa',
  configuration_errorUpdatingConfiguration_toast: 'Erro ao atualizar as configurações da empresa',
  configuration_forgottenExpensesExceedsLimit_label: 'Limite excedido de Contas a Pagar não pagas',
  configuration_forgottenRevenuesExceedsLimit_label: 'Limite excedido de Contas a Receber não recebidas',
  configuration_maxRecurrencesExceedsLimit_label: 'Limite excedido de número máximo de Pagamentos Recorrentes',
  configuration_auditButton_label: 'Histórico de alterações',
  configuration_numberOfDaysMustBeGreaterThanOrEqualTo1_label: 'Número de dias deve ser maior ou igual a 1',
  configuraiton_numberOfDaysMustBeLessThanOrEqualTo31_label: 'Número de dias deve ser menor ou igual a 31',

  cash_deposit_errorWhenSearchingForCashDeposits_toast: 'Erro ao buscar depósitos em dinheiro',
  cash_deposit_noCashDepositsFound_label: 'Não foram encontrados depósitos em dinheiro',
  cash_deposit_cashDeposit_label: 'Depósito em dinheiro',
  cash_deposit_newDeposit_label: 'Novo Depósito em Dinheiro',
  cash_deposit_originAccount_label: 'Conta de Origem',
  cash_deposit_destinationAccount_label: 'Conta de Destino',
  cash_deposit_dateFormat_formErrorLabel: 'Data deve ter o formato dd/mm/yyyy',
  cash_deposit_startDateRequired_formErrorLabel: 'Data inicial é obrigatória',
  cash_deposit_endDateMustBeafterOrEqualStartDate_formErrorLabel: 'Data final deve ser após ou igual a data inicial'
}
