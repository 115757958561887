
export const CreditcardMovementHooksTranslator = (
  dictionary
) => {
  const errorLoadingTheFinancialCardsSchedule = dictionary?.creditcard_movement_errorLoadingTheFinancialCardsSchedule_toast
  const errorWhenExportingFinancialCards = dictionary?.creditcard_movement_errorWhenExportingFinancialCards_toast
  const creditcardMovementEndDatemustBeGreaterOrEqualStartDate = dictionary?.common_endDatemustBeGreaterOrEqualStartDate_toast

  return {
    errorLoadingTheFinancialCardsSchedule,
    errorWhenExportingFinancialCards,
    creditcardMovementEndDatemustBeGreaterOrEqualStartDate
  }
}
