/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  EventProcessorEntryConditionsContainer as Container
} from './EntryConditions.styles'
import {
  Button, GhostButton
} from '@bonitour/components'
import {
  useCallback,
  useEffect,
  useMemo
} from 'react'
import { useTranslator } from 'Shared/language/i18nHandler'
import { useCreateCondition } from '../../hooks/useCreateCondition'
import { useGetConditions } from '../../hooks/useGetConditions'
import { useRemoveCondition } from '../../hooks/useRemoveCondition'
import { useUpdateCondition } from '../../hooks/useUpdateCondition'
import { useGetUniqueConditions } from '../../hooks/useGetUniqueConditions'
import { Conditions } from './Conditions/Conditions'
import { MatchedEvents } from './MatchedEvents/MatchedEvents'
import { EventsProcessorsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsI18n'
import { EventsRequestDetailsList } from './EventsRequestDetailsList/EventsRequestDetailsList'
import { useParams } from 'react-router-dom'

/**
 * @typedef EventProcessorEntryConditionsProps
 * @prop { () => void } onBack
 * @prop { () => void } onNext
 * @prop { (eventProcessorId: string) => void } fetchMatchedEvents
 * @prop { Record<string, string> } matchedEventsData
 * @prop { boolean } matchedEventsLoading
 */

/** @type { React.FC<EventProcessorEntryConditionsProps> } */
export const EventProcessorEntryConditions = ({
  onBack,
  onNext,
  fetchMatchedEvents,
  matchedEventsData,
  matchedEventsLoading
}) => {
  const routerParams = useParams()

  const eventProcessorId = useMemo(() => routerParams?.eventProcessorId, [routerParams?.eventProcessorId])
  const eventId = useMemo(() => routerParams?.eventId, [routerParams?.eventId])

  const {
    createCondition,
    loading: conditionCreateLoading
  } = useCreateCondition()

  const i18n = useTranslator(EventsProcessorsTranslator)

  const {
    getConditions,
    conditions,
    loading: conditionsListLoading
  } = useGetConditions()

  useEffect(() => {
    if (!eventProcessorId || conditions) return
    getConditions(eventProcessorId)
  }, [conditions, eventProcessorId, getConditions])

  const {
    loading: conditionRemoveLoading,
    removeCondition
  } = useRemoveCondition()

  const {
    updateCondition,
    loading: conditionUpdateLoading
  } = useUpdateCondition()

  const isLoading = useMemo(() => {
    return conditionCreateLoading ||
    conditionsListLoading ||
    conditionRemoveLoading ||
    conditionUpdateLoading
  }, [
    conditionCreateLoading,
    conditionRemoveLoading,
    conditionUpdateLoading,
    conditionsListLoading
  ])

  const {
    fetchUniqueConditions,
    uniqueConditionsData
  } = useGetUniqueConditions()

  useEffect(() => {
    if (!eventProcessorId || uniqueConditionsData) return
    fetchUniqueConditions(eventProcessorId)
  }, [
    eventProcessorId,
    fetchUniqueConditions,
    uniqueConditionsData
  ])

  const onRemoveCondition = useCallback((conditionId) => {
    removeCondition(eventProcessorId, conditionId).then(
      () => {
        getConditions(eventProcessorId)
        fetchUniqueConditions(eventProcessorId)
        fetchMatchedEvents(eventProcessorId)
      }
    )
  }, [
    eventProcessorId,
    getConditions,
    removeCondition,
    fetchUniqueConditions,
    fetchMatchedEvents
  ])

  const onUpdateCondition = useCallback((payload) => {
    updateCondition(payload, eventProcessorId, payload?.id).then(
      () => {
        getConditions(eventProcessorId)
        fetchUniqueConditions(eventProcessorId)
        fetchMatchedEvents(eventProcessorId)
      }
    )
  }, [
    eventProcessorId,
    getConditions,
    updateCondition,
    fetchUniqueConditions,
    fetchMatchedEvents
  ])

  const onCreateCondition = useCallback((field) => {
    createCondition(field, eventProcessorId).then(
      () => {
        getConditions(eventProcessorId)
        fetchUniqueConditions(eventProcessorId)
        fetchMatchedEvents(eventProcessorId)
      }
    )
  }, [
    createCondition,
    eventProcessorId,
    getConditions,
    fetchUniqueConditions,
    fetchMatchedEvents
  ])

  return (
    <Container>
      <EventsRequestDetailsList
        onCreateCondition={onCreateCondition}
        eventProcessorId={eventProcessorId}
        eventId={eventId}
      />
      <Conditions
        isLoading={isLoading}
        onRemoveCondition={onRemoveCondition}
        onUpdateCondition={onUpdateCondition}
        conditions={conditions}
        uniqueConditionsData={uniqueConditionsData}
      />
      <MatchedEvents
        matchedEventsData={matchedEventsData}
        isLoading={matchedEventsLoading}
      />
      <section className='entry_conditions__btns_wrapper'>
        <GhostButton
          type='reset'
          onClick={onBack}
        >
          {i18n?.back}
        </GhostButton>
        <Button
          type='button'
          onClick={onNext}
        >
          {i18n?.continueLabel}
        </Button>
      </section>
    </Container>
  )
}
