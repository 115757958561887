import { useCallback, useState } from 'react'
import { ExpenseService } from 'Core/Service/expense'
import { useUser } from 'Shared/contexts/User'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesHooksTranslator } from '../i18n/ExpensesHooksI18n'
import { ExpenseParsed, PayloadUpdateExpense } from 'Core/Types/Expense'
import { BackendErrorFormat } from 'Core/Types/Error'
import { useDeleteReceipts } from './useDeleteReceipts'
interface UseUpdateExpenseProps {
  recurrenceId?: string
}

export const useUpdateExpense = (
  {
    recurrenceId
  }: UseUpdateExpenseProps
) => {
  const [loading, setLoading] = useState(false)
  const [expense, setExpense] = useState<ExpenseParsed>()
  const { addToast } = useToast()
  const { user } = useUser()

  const i18n = useTranslator(ExpensesHooksTranslator)

  const { onDeleteReceipts } = useDeleteReceipts()

  const fetchExpense = useCallback((expenseId: string) => {
    setLoading(true)
    ExpenseService.getById(expenseId)
      .then(setExpense)
      .catch((errors: BackendErrorFormat) => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorWhenSearchingForAccountsPayable
          }
        )
      })
      .finally(() => setLoading(false))
  }, [addToast, i18n?.errorWhenSearchingForAccountsPayable])

  const onUpdateExpense = async (expenseData: PayloadUpdateExpense) => {
    setLoading(true)

    const currentReceiptsIds = expenseData?.receipts?.length ? expenseData?.receipts?.map(({ id }) => id) : []
    const removedReceitpsIds = expense?.receipts?.filter(({ id }) => !currentReceiptsIds.includes(id)).map(({ id }) => id)

    if (removedReceitpsIds?.length) {
      onDeleteReceipts(removedReceitpsIds, expenseData?.id)
    }

    return ExpenseService.edit(
      { ...expenseData, recurrenceId },
      expenseData?.id,
      expense,
      user
    )
      .then(() => {
        addToast(
          {
            defaultMessage: i18n?.accountsPayableUpdatedSuccessfully,
            typeToast: 'success'
          }
        )
        return true
      })
      .catch(errors => {
        console.error(errors?.data)
        addToast(
          {
            dictTranslation: i18n?.apiCodesMappingForExpense,
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorUpdatingAccountsPayable
          }
        )
        return false
      })
      .finally(() => setLoading(false))
  }

  return {
    expense,
    onUpdateExpense,
    fetchExpense,
    loading
  }
}
