import { useState, useEffect, useCallback, useRef } from 'react'
import { RevenueService } from 'Core/Service/revenue'
import { SafeDate } from '@binamik/js-providers'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { useRevenuesForgotten } from './useRevenuesForgotten'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { RevenuesHooksTranslator } from '../i18n/RevenuesHooksI18n'

export const useListRevenues = () => {
  const { addToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [revenuesList, setRevenuesList] = useState({ revenues: undefined, meta: undefined })
  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false)
  const [filters, setFilters] = useState(
    {
      startDate: new SafeDate().toStartOf('day').jsDate,
      endDate: new SafeDate().toStartOf('day').jsDate
    }
  )

  const i18n = useTranslator(RevenuesHooksTranslator)

  const { initStatePagination, perPageOptions } = usePaginationOptions()

  const initPagination = useRef(false)

  const {
    data: revenueForgottenData,
    setData: setRevenueForgottenData
  } = useRevenuesForgotten()

  const [pagination, setPagination] = useState(initStatePagination)

  const handleFilters = useCallback((filters) => {
    setRevenueForgottenData({ forgotten: 0, daysAgo: 0 })
    setIsUpdatingFilters(true)
    setPagination({
      currentPage: 1,
      perPage: pagination.perPage
    })
    setFilters(filters)
    setTimeout(() => setIsUpdatingFilters(false))
  }, [pagination.perPage, setRevenueForgottenData])

  const handlePagination = useCallback((currentPage, perPage) => {
    if (!initPagination.current) {
      initPagination.current = true
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [])

  const fetchRevenues = useCallback(() => {
    setLoading(true)
    return RevenueService.list({
      filters,
      pagination
    }).then((data) => {
      setRevenuesList(data)
    }).catch((errors) => {
      console.errors(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorLoadingAccountsReceivable
      })
    }).finally(() => {
      setLoading(false)
    })
  }, [
    addToast,
    filters,
    i18n?.errorLoadingAccountsReceivable,
    pagination
  ])

  useEffect(() => {
    !isUpdatingFilters && fetchRevenues()
  }, [fetchRevenues, isUpdatingFilters])

  return {
    handleFilters,
    filters,
    pagination,
    revenues: revenuesList?.revenues,
    meta: revenuesList?.meta,
    handlePagination,
    loading,
    fetchRevenues,
    perPageOptions,
    revenueForgottenData
  }
}
