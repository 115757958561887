import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const width100 = css`
  width: 100%;
`

export const marginBottom20 = css`
  margin-bottom: 20px;
`

export const fontWeight = css`
  font-weight: 500;
`

export const partnerItem = css`
  margin-top: 1em;
  color: ${colors.gray3};
`
