import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { AngleDownIcon } from '@bonitour/components'

import { keyboardSelectHandler } from 'Shared/utils/handlers/keyboardSelectHandler'
import { useOnClickOutside } from 'Shared/utils/utilityHooks/useOnClickOutside'

import { SimpleTooltip } from 'Shared/components/Tooltip/index'

import {
  SelectContainer,
  SelectControl,
  SelectOptionsContainer,
  SelectButton
} from './styles'
import { FilterSelect } from './FilterSelect'
import { OptionButtonAnimation } from './Constants/Select'
import { normalizeString } from 'Shared/utils/string/normalizeString'
import { preventPropagation } from 'Shared/utils/functions'
import { getOptionsWithExtras } from './utils/getOptionWithExtras'
import { SafeOverflow } from '../SafeOverflow/SafeOverflow'

/**
 * @typedef SelectProps
 * @prop { Path<T> } name
 * @prop { string } label
 * @prop { string[] } value
 * @prop { boolean } disabled
 * @prop { string } className
 * @prop { name } string
 * @prop {{value: string, label: string}[]} options
 * @prop { boolean } required
 * @prop { (value: string[]) => void } onChange
 * @prop { (value: string[], event: any) => void } onBlur
 * @prop { FieldError } error
 * @prop { 'top' | 'bottom' } yPlacement
 * @prop { string } selectAllLabel
 * @prop { boolean } hasSelectAll
 * @prop { boolean } withFilter
 * @prop { string } placeholder
 */

/** @type { React.FC<SelectProps> } */
export const Select = ({
  name,
  label,
  error = null,
  disabled: isDisabled = false,
  value,
  required,
  placeholder = '',
  className = '',
  options: currentOptions = [],
  yPlacement = 'bottom',
  onChange = _v => console.error('onChange not implemented'),
  onBlur = _v => console.error('onBlur not implemented'),
  withFilter = false,
  includeExtraOption = ''
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [emitBlurOnChange, setEmitBlur] = useState(false)

  const selectRef = useRef(null)

  const toggleSelectMenu = useCallback(() => {
    setIsOpen(currState => !currState)
  }, [])

  const handleCloseSelectMenu = useCallback(() => {
    setIsOpen(false)
  }, [])

  const options = getOptionsWithExtras(
    { options: currentOptions, includeExtraOption }
  )

  const currentlySelected = useMemo(() => {
    return options.find(option => option.value === value)?.label || ''
  }, [value, options])

  const [filter, setFilter] = useState('')

  const filteredOptions = useMemo(
    () =>
      options.filter(option =>
        normalizeString(filter)
          .trim()
          .split(' ')
          .every(word => normalizeString(option.label).includes(word))
      ),
    [filter, options]
  )

  const handleChange = useCallback((optionValue) => {
    onChange(optionValue)
    setIsOpen(false)
    setEmitBlur(true)
  }, [onChange])

  const handleBlur = useCallback((optionValue, event) => {
    onBlur(optionValue, event)
  }, [onBlur])

  useEffect(() => {
    if (emitBlurOnChange) {
      setEmitBlur(false)
      onBlur()
    }
  }, [value, emitBlurOnChange, onBlur])

  useOnClickOutside(selectRef, handleCloseSelectMenu)

  const disabled = useMemo(() => {
    return isDisabled || !options?.length
  }, [isDisabled, options?.length])

  return (
    <AnimatePresence exitBeforeEnter>
      <SelectControl className={className}>
        {!!label && (
          <label htmlFor={`select-${name}`}>
            {label}
            {required ? (
              <span className="input__required_tag">{'(Obrigatório)'}</span>
            ) : null}
          </label>
        )}

        <SelectContainer
          ref={selectRef}
          isDisabled={disabled}
          hasError={!!error}
          onClick={toggleSelectMenu}
          role="button"
          className={`${yPlacement === 'top' ? 'open_above' : ''} ${
            isOpen ? 'open' : ''
          }`}
          onKeyDown={keyboardSelectHandler(toggleSelectMenu)}
          tabIndex={0}
          aria-pressed={isOpen}
        >
          <SafeOverflow
            text={currentlySelected || placeholder}
            className={currentlySelected ? '' : 'select__placeholder'}
            alignment='center'
          />
          <span className="select__chevron">
            <AngleDownIcon />
          </span>
          {error ? (
            <SimpleTooltip
              label={error || ''}
              tooltipClass="input-tooltip"
              verticalPosition="top"
            />
          ) : null}
          <AnimatePresence>
            {isOpen && !disabled && (
              <SelectOptionsContainer
                layout
                initial={{
                  opacity: 0,
                  y: 20 * (yPlacement === 'top' ? 1 : -1),
                  scaleY: 0
                }}
                animate={{ opacity: 1, y: 0, scaleY: 1 }}
                exit={{
                  opacity: 0,
                  y: 20 * (yPlacement === 'top' ? 1 : -1),
                  scaleY: 0
                }}
                className={yPlacement === 'top' ? 'on_top' : ''}
                onClick={preventPropagation}
              >
                {withFilter && (
                  <FilterSelect
                    name={name}
                    disabled={disabled}
                    setFilter={setFilter}
                    filter={filter}
                    onKeyDown={preventPropagation}
                  />
                )}
                <div className="select_options__content">
                  <AnimatePresence>
                    {filteredOptions.map(option => (
                      <SelectButton
                        key={option.value}
                        onClick={() => handleChange(option.value)}
                        onBlur={(event) => handleBlur(option.value, event)}
                        type="button"
                        className={
                          option.value === value
                            ? 'option selected'
                            : 'option'
                        }
                        {...OptionButtonAnimation}
                      >
                        {option.label}
                      </SelectButton>
                    ))}
                  </AnimatePresence>
                </div>
              </SelectOptionsContainer>
            )}
          </AnimatePresence>
        </SelectContainer>
      </SelectControl>
    </AnimatePresence>
  )
}
