export const selectEventStatusOptions = [
  {
    value: '',
    label: 'Todos'
  },
  {
    value: 'pending',
    label: 'Pendente'
  },
  {
    value: 'processing',
    label: 'Processando'
  },
  {
    value: 'processed',
    label: 'Processado'
  },
  {
    value: 'failed',
    label: 'Falhou'
  }
]

export const eventsTypes = (dictionary = {}) => {
  return {
    adiantamento_fornecedor: dictionary?.event_partnershipCredit_label,
    agendamento_contas_pagar: dictionary?.event_schedulingAccountsPayable_label,
    agendamento_contas_receber: dictionary?.event_schedulingAccountsReceivable_label,
    baixa_boleto: dictionary?.event_writeOffExternalBillet_label,
    baixa_contas_pagar: dictionary?.event_writeOffAccountsPayable_label,
    baixa_contas_receber: dictionary?.event_writeOffAccountsReceivable_label,
    baixa_taxa_boleto: dictionary?.event_writeOffExternalBilletFee_label,
    bank_account_movement: dictionary?.event_digitalAccountMovement_label,
    boleto_vencido: dictionary?.event_overdueBillet_label,
    cancelamento_cartao_credito_split: dictionary?.event_onlinePaymentCancellationCreditCard_label,
    cancelamento_cobranca_fatura: dictionary?.event_invoicePaymentCancellationSeller_label,
    cancelamento_contas_pagar: dictionary?.event_cancellationAccountsPayable_label,
    cancelamento_contas_receber: dictionary?.event_cancellationAccountsReceivable_label,
    cancelamento_imposto_iss: dictionary?.event_ISSPaymentCancellation_label,
    cancelamento_ingresso: dictionary?.event_ticketCancellation_label,
    cancelamento_iss_atrativos: dictionary?.event_supplierISSpaymentCancellation_label,
    cancelamento_nota_cliente: dictionary?.event_customerInvoiceCancellation_label,
    cancelamento_pagamento_offline: dictionary?.event_offlinePaymentCancellation_label,
    cartao_credito_split: dictionary?.event_onlinePaymentCreditCard_label,
    cartao_debito_split: dictionary?.event_onlinePaymentDebitCard_label,
    cobranca_fatura: dictionary?.event_invoiceReceivement_label,
    create_update_bank_account: dictionary?.event_bankAccountRegistration_label,
    debito_gravame: dictionary?.event_gravameDebit_label,
    deposito_cheque: dictionary?.event_checkDeposit_label,
    deposito_dinheiro: dictionary?.event_cashDeposit_label,
    devolucao_cheque: dictionary?.event_checkReturned_label,
    emissao_boleto: dictionary?.event_billetIssue_label,
    emissao_imposto_iss: dictionary?.event_ISSPaymentScheduling_label,
    emissao_iss_atrativos: dictionary?.event_supplierISSPaymentSchedule_label,
    emissao_nota_cliente: dictionary?.event_issuanceInvoiceCustomer_label,
    entrada_caixa: dictionary?.event_agentBoxEntry_label,
    estorno_adiantamento_fornecedor: dictionary?.event_partnershipDebit_label,
    estorno_contas_pagar: dictionary?.event_refundAccountsPayable_label,
    estorno_contas_receber: dictionary?.event_refundAccountsReceivable_label,
    estorno_deposito_cheque: dictionary?.event_checkDespostiRefund_label,
    estorno_imposto_iss: dictionary?.event_ISSPaymentRefund_label,
    estorno_iss_atrativos: dictionary?.event_supplierISSPaymentRefund_label,
    estorno_pagamento_fatura: dictionary?.event_invoicePaymentRefund_label,
    mensalidade_pos_cielo: dictionary?.event_POSMonthlyFee_label,
    pagamento_fatura: dictionary?.event_invoicePayment_label,
    pagamento_imposto_iss: dictionary?.event_paymentOfISSTax_label,
    pagamento_iss_atrativos: dictionary?.event_supplierISSTaxPayment_label,
    pagamento_offline: dictionary?.event_offlinePayment_label,
    passeio_realizado: dictionary?.event_tourTaken_label,
    passeio_realizado_proprio: dictionary?.event_guideTourOwnTicket_label,
    repasse: dictionary?.event_transferSupplier_label,
    repasse_cancelamento: dictionary?.event_cancelattionTransferSupplier_label,
    repasse_debito: dictionary?.event_transferPaymentDebitCard_label,
    reserva_confirmada: dictionary?.event_confirmedReservation_label,
    reserva_confirmada_propria: dictionary?.event_confirmedBookingOwnTicket_label,
    retirada_dinheiro: dictionary?.event_withdrawalCurrentAccount_label,
    saida_caixa: dictionary?.event_agentCashOut_label,
    transferencia_entre_contas: dictionary?.event_transferBetweenBankAccounts_label
  }
}
