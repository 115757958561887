/* eslint-disable dot-notation */

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Fragment, useState } from 'react'
import { jsx } from '@emotion/core'
import { formatMoney } from '@bonitour/common-functions'
import { ReceivablesExpander } from './ReceivablesExpander/ReceivablesExpander'
import { collapsableIconOpen, sizeIcon, tableBodyContainer, tableContainerStyled } from './ReceivableList.styles'
import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  SortRightIcon
} from '@bonitour/components'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ReceivablesTranslator } from '../i18n/ReceivablesI18n'
import { SafeDate } from '@binamik/js-providers'
import { CircleStatus } from 'Shared/styles/global'
import { gatewayPaymentStatusColor } from '../Constants/Receivables'
import { Tooltip } from 'Shared/components/Tooltip'

const ReceivablesListingTableHeader = ({ columns }) => (
  <TableHeader>
    <TableHeaderRow>
      {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
    </TableHeaderRow>
  </TableHeader>
)

const ReceivablesListingTableBody = ({ loading, receivables, i18n }) => {
  const [indexDetail, setIndexDetail] = useState([])

  const toggleExpander = (index) => {
    if (indexDetail.includes(index)) {
      const newArray = indexDetail.filter((arrayIndex) => arrayIndex !== index)
      setIndexDetail(newArray)
    } else {
      setIndexDetail([...indexDetail, index])
    }
  }

  const isExpanded = (index) => indexDetail.includes(index)

  const onClickDetail = (index) => () => toggleExpander(index)

  return (
    <TableBody loading={loading}>
      {receivables.map(({
        id,
        date,
        brand,
        operationType,
        grossAmount,
        netAmount,
        status,
        saleDate,
        saleGrossAmount,
        mdrFee,
        binamikFee,
        cardNumber,
        installment,
        installments,
        gateway,
        gatewayEventTypeDescription,
        authorizationCode,
        proofOfSale,
        conciliated
      }, index) => (
        <Fragment key={id}>
          <TableBodyRow css={[tableBodyContainer]}>
            <TableBodyCell>
              <SortRightIcon onClick={onClickDetail(index)} css={[sizeIcon, isExpanded(index) && collapsableIconOpen]} />
              {date ? new SafeDate(date).format(i18n.dateFormat) : '-'}
            </TableBodyCell>
            <TableBodyCell>{i18n?.flagTranslator(brand)}</TableBodyCell>
            <TableBodyCell>{`${authorizationCode || '-'} / ${proofOfSale || '-'}`}</TableBodyCell>
            <TableBodyCell>
              {i18n?.paymentMethodMapped(operationType)}
            </TableBodyCell>
            <TableBodyCell
              className={grossAmount?.valueColorClass}
            >
              {grossAmount?.value}
            </TableBodyCell>
            <TableBodyCell
              className={netAmount?.valueColorClass}
            >
              {netAmount?.value}
            </TableBodyCell>
            <TableBodyCell>
              <CircleStatus statusColor={gatewayPaymentStatusColor[status.toLowerCase()] || '--clr-gray7'}>
                {i18n?.statusReceivablesTranslator(status)}
              </CircleStatus>
            </TableBodyCell>
            <TableBodyCell>
              <CircleStatus
                statusColor={conciliated ? '--clr-green1' : '--clr-gray7'}
                className='circle__conciliated'
              >
                <Tooltip
                  verticalPosition='center'
                  horizontalPosition='left'
                  tooltipClass='tooltip__conciliated'
                >
                  {conciliated ? i18n.conciliated : i18n.notConciliated}
                </Tooltip>
              </CircleStatus>
            </TableBodyCell>
          </TableBodyRow>
          {indexDetail.includes(index) &&
            <TableBodyCell colSpan='100%'>
              <ReceivablesExpander
                key={index}
                saleDate={saleDate ? new SafeDate(saleDate).format(i18n.dateFormat) : '-'}
                saleGrossAmount={formatMoney(saleGrossAmount)}
                mdrFee={formatMoney(mdrFee)}
                binamikFee={formatMoney(binamikFee)}
                cardNumber={cardNumber}
                installment={installment}
                installments={installments}
                gateway={gateway}
                gatewayEventTypeDescription={
                  i18n?.gatewayPaymentEventTypeDescriptionTranslator(gatewayEventTypeDescription)
                }
              />
            </TableBodyCell>}
        </Fragment>
      ))}
    </TableBody>
  )
}

export const ReceivablesList = ({ loading, receivables = [] }) => {
  const i18n = useTranslator(ReceivablesTranslator)
  return (
    <TableContainer css={[tableContainerStyled]}>
      <ReceivablesListingTableHeader columns={i18n.tableColumnsTitles} />
      <ReceivablesListingTableBody
        loading={loading}
        receivables={receivables}
        i18n={i18n}
      />
    </TableContainer>
  )
}
