import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SupplierBankAccountService } from 'Core/Service/SupplierBankAccount'
import { useQueryClient } from 'react-query'
import { SupplierBankAccountsHooksTranslator } from '../i18n/SupplierBankAccountsHooksI18n'

export const useCreateSupplierBankAccount = (supplierId) => {
  const [createLoading, setCreateLoading] = useState(false)
  const { addToast } = useToast()
  const i18n = useTranslator(SupplierBankAccountsHooksTranslator)

  const queryClient = useQueryClient()

  const createSupplierBankAccount = useCallback((params) => {
    setCreateLoading(true)
    return SupplierBankAccountService.create({ supplierId, params }).then(() => {
      queryClient.invalidateQueries({
        queryKey: ['supplierBankAccountList', supplierId]
      })
      addToast({
        defaultMessage: i18n?.createSupplierBankAccount,
        typeToast: 'success'
      })
    }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorCreateSupplierBankAccount
      })
    }).finally(() => {
      setCreateLoading(false)
    })
  }, [
    addToast,
    i18n?.createSupplierBankAccount,
    i18n?.errorCreateSupplierBankAccount,
    queryClient,
    supplierId
  ])

  return {
    onCreateSupplierBankAccount: createSupplierBankAccount,
    createLoading
  }
}
