import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConfigurationsHooksTranslator } from '../i18n/ConfigurationHooksI18n'
import { ConfigurationsService } from 'Core/Service/configurations'
import { UserStore } from 'Core/Store/User'
import { ConfigurationAuditsResParsed } from 'Core/Types/Configuration'

export const useConfigurationAudit = () => {
  const { addToast } = useToast()
  const [datas, setDatas] = useState<ConfigurationAuditsResParsed>()
  const [loading, setLoading] = useState(false)
  const i18n = useTranslator(ConfigurationsHooksTranslator)

  const fetchConfigurationAudit = useCallback(() => {
    const configurationId = UserStore?.user?.company?.id
    setLoading(true)
    return ConfigurationsService.getConfigurationAudits(configurationId)
      .then(setDatas)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorLoadingConfigurationAudit
        })
      }).finally(() => {
        setLoading(false)
      })
  }, [addToast, i18n?.errorLoadingConfigurationAudit])

  return {
    fetchConfigurationAudit,
    datas,
    loading
  }
}
