import { SafeDate } from '@binamik/js-providers'

type EndDateMustBeAfterOrEqualStartDateProps = {
  ctx: { parent: { startDate: string | Date } }
}

export const endDateMustBeAfterOrEqualStartDate = (
  value: string | Date,
  ctx: EndDateMustBeAfterOrEqualStartDateProps['ctx']
) => {
  if (!value || !ctx?.parent?.startDate) {
    return true
  }

  const startDate = new SafeDate(ctx?.parent?.startDate).jsDate
  const endDate = new SafeDate(value)

  return !endDate.isBefore(startDate)
}
