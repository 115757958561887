/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const labelCardButton = css`
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: var(--clr-gray3);
`

export const ExpenseRevenueDashboardContainer = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;

  .card-img {
    height: 1.5625rem;
  }

  .card-icon {
    font-size: 1.25rem;
  }
`
