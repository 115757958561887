
export const EventsProcessorsHooksTranslator =
 (dictionary, _locale) => {
   const errorFetchingEventsProcessors = dictionary?.event_processor_errorFetchingEventsProcessors_toast
   const errorRemoveEventProcessor = dictionary?.event_processor_errorRemoveEventProcessor_toast
   const successDeletedEventProcessor = dictionary?.event_processor_successDeletedEventProcessor_toast
   const sucessRegisteredEventProcessor = dictionary?.event_processor_sucessRegisteredEventProcessor_toast
   const errorRegisteringdEventProcessor = dictionary?.event_processor_errorRegisteringdEventProcessor_toast
   const conditionAddedToProcessor = dictionary?.event_processor_conditionAddedToProcessor_toast
   const errorAddingConditionInProcessor = dictionary?.event_processor_errorAddingConditionInProcessor_toast
   const eventProcessorUpdatedSuccess = dictionary?.event_processor_eventProcessorUpdatedSuccess_toast
   const errorUpdatingEventProcessor = dictionary?.event_processor_errorUpdatingEventProcessor_toast
   const errorFetchingCondition = dictionary?.event_processor_errorFetchingCondition_toast
   const errorFetchingConditions = dictionary?.event_processor_errorFetchingConditions_toast
   const errorFectingEventProcessor = dictionary?.event_processor_errorFectingEventProcessor_toast
   const conditionRemovedSuccess = dictionary?.event_processor_conditionRemovedSuccess_toast
   const errorRemovingCondition = dictionary?.event_processor_errorRemovingCondition_toast
   const conditionSuccessUpdated = dictionary?.event_processor_conditionSuccessUpdated_toast
   const errorUpdatingCondition = dictionary?.event_processor_errorUpdatingCondition_toast
   const errorFetchingMatchingEvents = dictionary?.event_processor_errorFetchingMatchingEvents_toast
   const erroFetchingUniqueConditions = dictionary?.event_processor_erroFetchingUniqueConditions_toast
   const errorFetchingAutomationRules = dictionary?.event_processor_errorFetchingAutomationRules_toast
   const errorWhenFetchingSimulationsAutomationRules = dictionary?.event_processor_errorWhenFetchingSimulationsAutomationRules_toast
   const errorRemovingAutomationRule = dictionary?.event_processor_errorRemovingAutomationRule_toast
   const automationRuleSuccessRemoved = dictionary?.event_processor_automationRuleSuccessRemoved_toast
   const automationRuleCreatedSuccessfully = dictionary?.event_processor_automationRuleCreatedSuccessfully_toast
   const errorCreatingAutomationRule = dictionary?.event_processor_errorCreatingAutomationRule_toast
   const errorFetchingAutomationRuleActions = dictionary?.event_processor_errorFetchingAutomationRuleActions_toast
   const errorFetchingAccountFormulasForAutomationRule = dictionary?.event_processor_errorFetchingAccountFormulasForAutomationRule_toast
   const errorFetchingAccountsForAutomationRule = dictionary?.event_processor_errorFetchingAccountsForAutomationRule_toast
   const automationRuleUpdatedSuccessfully = dictionary?.event_processor_automationRuleUpdatedSuccessfully_toast
   const errorUpdatingAutomationRule = dictionary?.event_processor_errorUpdatingAutomationRule_toast
   const errorFetchingAutomationRule = dictionary?.event_processor_errorFetchingAutomationRule_toast

   const apiCodesMappingsForEventsProcessors = {
     'events_processor::unique_conditions::entry_conditions_not_unique': dictionary?.event_processor_uniqueConditionsEntryConditionsNotUnique_toast,
     'events_processor::simulations::invalid_automation_rules': dictionary?.event_processor_simulatonsInvalidAutomationRules_toast,
     'events_processor::conditions::no_entry_conditions': dictionary?.event_processor_conditionsNoEntryConditions_toast,
     'automation_rule::account_id::is_missing': dictionary?.event_processor_automationRuleAccountIdIsMissing_toast,
     'automation_rule::account_formula::is_missing': dictionary?.event_processor_automationRuleAccountFormulaIsMissing_toast
   }

   return {
     errorFetchingEventsProcessors,
     errorRemoveEventProcessor,
     successDeletedEventProcessor,
     sucessRegisteredEventProcessor,
     errorRegisteringdEventProcessor,
     conditionAddedToProcessor,
     errorAddingConditionInProcessor,
     eventProcessorUpdatedSuccess,
     errorUpdatingEventProcessor,
     errorFetchingCondition,
     errorFetchingConditions,
     errorFectingEventProcessor,
     conditionRemovedSuccess,
     errorRemovingCondition,
     conditionSuccessUpdated,
     errorUpdatingCondition,
     errorFetchingMatchingEvents,
     erroFetchingUniqueConditions,
     errorFetchingAutomationRules,
     errorWhenFetchingSimulationsAutomationRules,
     errorRemovingAutomationRule,
     automationRuleSuccessRemoved,
     automationRuleCreatedSuccessfully,
     errorCreatingAutomationRule,
     errorFetchingAutomationRuleActions,
     errorFetchingAccountFormulasForAutomationRule,
     errorFetchingAccountsForAutomationRule,
     automationRuleUpdatedSuccessfully,
     errorUpdatingAutomationRule,
     errorFetchingAutomationRule,
     apiCodesMappingsForEventsProcessors
   }
 }
