/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { defineStatus, defineType } from '../Constants/CountPlan'
import { identity } from '@bonitour/common-functions'
import {
  H4,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableContainer,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  PencilIcon,
  TrashIcon,
  flexRow,
  TooltipOnHover
} from '@bonitour/components'
import {
  headerTitleStyle,
  width30,
  bold,
  iconButton,
  fontColor
} from './CountPlanList.style'

export const CountPlanListing = ({ columnList = [], countPlanList = [], deleteCountPlan = identity, loading }) => {
  const { push } = useHistory()
  const handleEditClick = (id) => push(`/accounting-function/count-plan/edit/${id}`)

  const CountPlanListingTableHeader = ({ columns }) => (
    <TableHeader>
      <TableHeaderRow>
        {columns.map((item) => <TableHeaderCell css={item === 'Nome' && width30} key={item}>{item}</TableHeaderCell>)}
      </TableHeaderRow>
    </TableHeader>
  )

  const CountPlanTableBody = ({ data }) => {
    const renderActions = (id) => (
      <div css={flexRow}>
        <TooltipOnHover text='Editar'>
          <PencilIcon css={iconButton} onClick={() => handleEditClick(id)} />
        </TooltipOnHover>
        <TooltipOnHover text='Deletar'>
          <TrashIcon css={iconButton} onClick={() => deleteCountPlan(id)} />
        </TooltipOnHover>
      </div>
    )

    return (
      <TableBody loading={loading}>
        {data.map(({ name, classification, level, planType, enabled, id }) => (
          <TableBodyRow key={id}>
            <TableBodyCell css={defineBoldText[planType]}>{name}</TableBodyCell>
            <TableBodyCell css={defineBoldText[planType]}>{classification}</TableBodyCell>
            <TableBodyCell css={defineBoldText[planType]}>{level}</TableBodyCell>
            <TableBodyCell css={defineBoldText[planType]}>{defineType[planType]}</TableBodyCell>
            <TableBodyCell css={fontColor(defineStatus[enabled].color)}>{defineStatus[enabled].label}</TableBodyCell>
            <TableBodyCell>{renderActions(id)}</TableBodyCell>
          </TableBodyRow>
        ))}
      </TableBody>
    )
  }

  const defineBoldText = {
    S: bold,
    A: ''
  }

  return (
    <>
      <H4 css={headerTitleStyle}>Resultado da pesquisa</H4>
      <TableContainer>
        <CountPlanListingTableHeader columns={columnList} />
        <CountPlanTableBody data={countPlanList} />
      </TableContainer>
    </>
  )
}
