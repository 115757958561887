import { useTranslator } from 'Shared/language/i18nHandler'
import { useMemo } from 'react'

export const useTranslatedFormErrors = (errors, formErrorsTranslator) => {
  const { errors: i18n } = useTranslator(formErrorsTranslator)

  const translatedFormErrors = useMemo(() => {
    return Object.keys(errors).reduce((obj, key) => ({
      ...obj,
      [key]: i18n?.[key]?.[errors[key]] || errors[key]
    }), {})
  }, [errors, i18n])

  return translatedFormErrors
}
