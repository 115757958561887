import React, { useMemo, useState } from 'react'
import { MenuTabsContainer } from './styles'
import { AnimateSharedLayout, motion } from 'framer-motion'

/**
 * @typedef MenuTabsPanelProps
 * @prop { { label: string, id: string, component: React.FC }} tabs
 * @prop { string } initTab
 */

/** @type { React.FC<MenuTabsPanelProps> } */
export const MenuTabsPanel = ({
  tabs = [],
  initTab
}) => {
  const [selectedTab, setSelectedTab] = useState(initTab)

  const selectedComponent = useMemo(
    () => tabs.find(({ id }) => id === selectedTab)?.component, [selectedTab, tabs]
  )

  return (
    <>
      <MenuTabsContainer>
        <nav className='menu_tab_nav'>
          <ul>
            <AnimateSharedLayout>
              {tabs.map(({ label, id, items }) => (
                <div className='wrapper' key={id}>
                  <li
                    key={label}
                    className={selectedTab === id ? 'selected' : ''}
                  >
                    <button onClick={() => setSelectedTab(id)}>
                      {label}
                      {selectedTab === id ? (
                        <motion.div className="underline" layoutId="underline" />
                      ) : null}
                    </button>

                  </li>
                  <span className={`items ${selectedTab === id ? 'selected' : ''}`}>
                    {(items !== undefined && items !== null) ? `(${items})` : ''}
                  </span>
                </div>
              ))}
            </AnimateSharedLayout>
          </ul>
        </nav>
      </MenuTabsContainer>
      {selectedComponent}
    </>
  )
}
