import { endDateMustBeAfterOrEqualStartDate } from 'Shared/utils/yup/date/endDateMustBeAfterOrEqualStartDate'
import { startDateMustBeLessThanOrEqualEndDate } from 'Shared/utils/yup/date/startDateMustBeLessThanOrEqualEndDate'
import { date, string } from 'yup'

export const FinancialScheduleFilterSchema = {
  reservationCode: string(),
  partnerName: string(),
  proofOfSale: string(),
  brand: string(),
  creditCardOperationType: string(),
  startDate: date()
    .typeError('Data deve ter o formato dd/mm/yyyy')
    .required('Data inicial é obrigatória')
    .test(
      'startDateMustBeLessThanOrEqualEndDate', 'A data inicial deve ser menor ou igual a data final', startDateMustBeLessThanOrEqualEndDate
    ),
  endDate: date()
    .required('Data final é obrigatória')
    .typeError('Data deve ter o formato dd/mm/yyyy')
    .test(
      'endDateMustBeAfterOrEqualStartDate', 'Data final deve ser após ou igual a data inicial', endDateMustBeAfterOrEqualStartDate
    ),
  gateway: string().required('Adquirente é obrigatório')
}
