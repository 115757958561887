export const ExpenseRevenueDashboardTranslator = (
  dictionary
) => {
  const pageTitleBankAccounts = dictionary?.bank_account_pageTitleBankAccounts_label
  const pageTitleSuppliers = dictionary?.supplier_pageTitleSuppliers_label
  const accountsToPayAndReceive = dictionary?.expense_revenue_dashboard_accountsToPayAndReceive_label
  const accountsToPay = dictionary?.expense_revenue_dashboard_accountsToPay_label
  const accountsReceivable = dictionary?.expense_revenue_dashboard_accountsReceivable_label
  const typeOfExpenseRevenue = dictionary?.expense_revenue_dashboad_typeOfExpenseRevenue_label

  return {
    pageTitleBankAccounts,
    pageTitleSuppliers,
    accountsToPayAndReceive,
    accountsToPay,
    accountsReceivable,
    typeOfExpenseRevenue
  }
}
