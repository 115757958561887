import { useForm } from '@bonitour/app-functions'
import {
  getSchema,
  typeOfEvent
} from 'Domains/Events/Form/__EventsBaseInputs'
import { EventsFiltersInputsTranslator } from 'Domains/Events/i18n/EventsFiltersInputsI18n'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { useMemo } from 'react'

export const useEventsFiltersInputs = (
  filters,
  eventsTypesOptions
) => {
  const inputsValid = useTranslatedInputs([
    typeOfEvent(eventsTypesOptions)
  ], EventsFiltersInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsValid),
    [inputsValid]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(filters, schema)

  return {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  }
}
