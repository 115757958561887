import { CompanyParser } from 'Core/Parser/Company'
import { extractData, OrbService } from './Orb'

export const CompanyService = {
  listUserCompanies (active) {
    return OrbService
      .get(`/v1/companies/user_companies${active ? '?active=true' : ''}`)
      .then(extractData)
      .then(CompanyParser.listUserCompanies)
  }
}
