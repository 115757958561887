import { billingCore } from './Billing'
import { ExpenseRevenuesModel } from '../Models/expenseRevenues'
import { ExpenseRevenuesParser } from 'Core/Parser/ExpenseRevenues'

export const ExpenseRevenueService = {
  list ({ filters, pagination }) {
    return billingCore.expenseRevenueDomain.list(ExpenseRevenuesModel.list({ filters, pagination })).then(ExpenseRevenuesParser.list)
  },
  create (expenseRevenue, user) {
    return billingCore.expenseRevenueDomain.create(ExpenseRevenuesModel.create(expenseRevenue, user))
  },
  getById (expenseRevenueId) {
    return billingCore.expenseRevenueDomain.get(expenseRevenueId).then(ExpenseRevenuesParser.single)
  },
  edit (expenseRevenue, expenseRevenueId, user) {
    return billingCore.expenseRevenueDomain.update(ExpenseRevenuesModel.edit(expenseRevenue, user), expenseRevenueId)
  },
  remove (expenseRevenueId) {
    return billingCore.expenseRevenueDomain.remove(expenseRevenueId)
  },
  listWithoutPagination ({ expenseRevenueType, enabled }) {
    return billingCore.expenseRevenueDomain.list({
      searchParams: {
        expense_revenue_type: expenseRevenueType,
        enabled
      },
      pagination: {}
    }).then(ExpenseRevenuesParser.listWithoutPagination)
  },
  getExpenseRevenueAudit (expenseRevenueId) {
    return billingCore.expenseRevenueDomain.getExpenseRevenueAudit({}, expenseRevenueId).then(
      ExpenseRevenuesParser.getExpenseRevenueAudits)
  }
}
