import { toCents } from '@binamik/js-functions'
import { SafeDate } from '@binamik/js-providers'
import { endDateMustBeAfterOrEqualStartDate } from 'Shared/utils/yup/date/endDateMustBeAfterOrEqualStartDate'
import { startDateMustBeLessThanOrEqualEndDate } from 'Shared/utils/yup/date/startDateMustBeLessThanOrEqualEndDate'

import {
  date as yupDate,
  string as yupString,
  array as yupArray,
  number as yupNumber
} from 'yup'
import { bankTransferStatus } from '../Constants/bankTransferStatus'
import { isNotFutureDate } from 'Shared/utils/yup/date/isNotFutureDate'

const convertBrlToNumber = (_val, originalVal) => {
  try {
    const cents = toCents(originalVal)
    return cents / 100
  } catch (error) {
    console.error(error)
    return NaN
  }
}

const startDate = {
  label: 'Data inicial',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data inicial',
  className: 'start_date',
  value: 'startDate',
  type: 'date',
  schema: yupDate()
    .typeError('dateFormat')
    .required('dateRequired')
    .test(
      'startDateMustBeLessThanOrEqualEndDate',
      'startDateMustBeLessThanOrEqualEndDate',
      startDateMustBeLessThanOrEqualEndDate
    )
}

const endDate = {
  label: 'Data final',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data final',
  className: 'end_date',
  value: 'endDate',
  type: 'date',
  schema: yupDate()
    .typeError('dateFormat')
    .required('dateRequired')
    .test(
      'endDateMustBeAfterOrEqualStartDate',
      'endDateMustBeAfterOrEqualStartDate',
      endDateMustBeAfterOrEqualStartDate
    )
}

const status = {
  label: 'Status',
  placeholder: 'Selecione os status',
  className: 'status',
  value: 'status',
  type: 'multiSelect',
  withFilter: false,
  hasSelectAll: true,
  schema: yupArray().of(yupString())
}

const originBankAccount = (bankAccountsOptions = []) => ({
  label: 'Conta de Origem',
  placeholder: 'Selecione a conta',
  className: 'origin_bank_account',
  value: 'originBankAccountId',
  type: 'select',
  options: bankAccountsOptions,
  schema: yupString().optional()
})

const destinationBankAccount = (bankAccountsOptions = []) => ({
  label: 'Conta de Destino',
  placeholder: 'Selecione a conta',
  className: 'destination_bank_account',
  value: 'destinationBankAccountId',
  type: 'select',
  options: bankAccountsOptions,
  schema: yupString().optional()
})

const date = {
  label: 'Data da Transferência',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Selecione a data',
  className: 'date',
  value: 'date',
  type: 'date',
  maxDate: new SafeDate().jsDate,
  schema: yupDate()
    .typeError('dateFormat')
    .required('dateRequired')
    .test(
      'isNotFutureDate',
      'isNotFutureDate',
      isNotFutureDate
    )

}

const originBankAccountRequired = (bankAccountsOptions = []) => ({
  ...originBankAccount(bankAccountsOptions),
  schema: yupString().required('originBankAccountIdRequired')
})

const destinationBankAccountRequired = (bankAccountsOptions = []) => ({
  ...destinationBankAccount(bankAccountsOptions),
  schema: yupString().required('destinationBankAccountIdRequired')
})

const taxe = {
  label: 'Taxa Bancária',
  className: 'taxe',
  value: 'taxe',
  typeWrapper: 'inputFormGroup',
  type: 'money',
  schema: yupNumber()
    .transform(convertBrlToNumber)
    .default(0.0).typeError('Preencha taxa em decimais'),
  maskFn: (val = '') => val.replace(/[^0-9,.]/g, '')
}

const observation = {
  label: 'Observações',
  className: 'observation',
  placeholder: 'Digite a observação',
  value: 'observation',
  typeWrapper: 'inputFormGroup',
  type: 'text',
  schema: yupString()
    .nullable()
    .optional()
    .test(
      'observationRequired',
      'observationRequired',
      function (value) {
        const status = this.parent.statusRequired

        const isIncludedStatus = [
          bankTransferStatus.canceled
        ].includes(status)

        if (!value && isIncludedStatus) {
          return false
        }

        return true
      }
    )
}

const statusRequired = {
  ...status,
  placeholder: 'Selecione o status',
  value: 'statusRequired',
  type: 'select',
  hasSelectAll: false,
  schema: yupString().required('statusRequired')
}

const transferMethod = {
  label: 'Método de Transferência',
  placeholder: 'Selecione o método',
  className: 'transfer_method',
  value: 'transferMethod',
  withFilter: false,
  type: 'select',
  schema: yupString().required('transferMethodRequired')
}

const value = {
  label: 'Valor',
  className: 'value',
  value: 'value',
  typeWrapper: 'inputFormGroup',
  type: 'money',
  schema: yupNumber()
    .transform(convertBrlToNumber).default(0.0),
  maskFn: (val = '') => val.replace(/[^0-9,.]/g, '')
}

const userName = {
  label: 'Nome do Usuário',
  className: 'user_name',
  value: 'userName',
  typeWrapper: 'inputFormGroup',
  type: 'text',
  schema: yupString(),
  disabled: true
}

const createdAt = {
  label: 'Data de Cadastro',
  className: 'created_at',
  typeWrapper: 'inputFormGroup',
  value: 'createdAt',
  type: 'date',
  schema: yupDate(),
  disabled: true
}

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  startDate,
  endDate,
  status,
  originBankAccount,
  destinationBankAccount,
  date,
  originBankAccountRequired,
  destinationBankAccountRequired,
  taxe,
  observation,
  statusRequired,
  transferMethod,
  value,
  userName,
  createdAt,
  getSchema
}
