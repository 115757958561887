/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  AutomationRulesSimulationsContainer as Container
} from './AutomationRulesSimulation.styles'
import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow
} from '@bonitour/components'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsI18n'

/**
 * @typedef AutomationRulesSimulationsProps
 * @prop { Record<string, string> } automationRulesSimulationsData
 * @prop { boolean } loading
 */

/** @type { React.FC<AutomationRulesSimulationsProps> } */
export const AutomationRulesSimulations = (
  {
    automationRulesSimulationsData,
    loading
  }
) => {
  const i18n = useTranslator(EventsProcessorsTranslator)

  return (
    <Container>
      <div>
        <h3 className='container__title'>
          {i18n?.automationRulesSimulator}
        </h3>
        <p className='container__subtitle'>
          {
            `${i18n?.theAutomationRulesAre}: ${automationRulesSimulationsData?.isValid ? i18n?.valid : i18n?.invalid}`
          }
        </p>
      </div>

      <div className='container__table'>
        <TableContainer>
          <TableHeader>
            <TableHeaderRow>
              {
                i18n?.automationRulesSimulationsTableColumnsTitles?.map(
                  (item, idx) => (
                    <TableHeaderCell key={idx}>{item}</TableHeaderCell>
                  )
                )
              }
            </TableHeaderRow>
          </TableHeader>
          <TableBody loading={loading}>
            {automationRulesSimulationsData?.results?.map((
              { _id, actionName, date, value, operationTypeName, accountName, status }, idx
            ) => (
              <TableBodyRow key={idx}>
                <TableBodyCell>
                  <SafeOverflow
                    text={actionName}
                  />
                </TableBodyCell>
                <TableBodyCell>
                  {i18n?.dateFormat(date)}
                </TableBodyCell>
                <TableBodyCell>
                  {value}
                </TableBodyCell>
                <TableBodyCell>
                  {operationTypeName}
                </TableBodyCell>
                <TableBodyCell>
                  <SafeOverflow
                    text={accountName}
                  />
                </TableBodyCell>
                <TableBodyCell>
                  <SafeOverflow
                    text={status}
                    alignment='right'
                  />
                </TableBodyCell>
              </TableBodyRow>
            ))}
          </TableBody>
        </TableContainer>
      </div>

    </Container>
  )
}
