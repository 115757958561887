import { useState, useCallback, useMemo } from 'react'
import { ExpenseService } from 'Core/Service/expense'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesHooksTranslator } from '../i18n/ExpensesHooksI18n'
import { ExpenseAllowedStatus } from 'Core/Types/Expense'
import { useTranslatedExpensesStatus } from '../i18n/hooks/useTranslatedExpensesStatus'

export const useExpenseAllowedStatus = () => {
  const [allowedStatus, setAllowedStatus] = useState<ExpenseAllowedStatus[]>()
  const { addToast } = useToast()

  const i18n = useTranslator(ExpensesHooksTranslator)

  const { expensesStatusTranslator } = useTranslatedExpensesStatus()

  const fetchAllowedStatus = useCallback((expenseId: string) => {
    return ExpenseService.alloweStatus(expenseId)
      .then(setAllowedStatus)
      .catch((errors) => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorLoadingAvailableStatus
          }
        )
      })
  }, [addToast, i18n?.errorLoadingAvailableStatus])

  const allowedStatusOptions = useMemo(() => {
    if (!allowedStatus) return []

    return allowedStatus.map(status => {
      return {
        label: expensesStatusTranslator(status),
        value: status
      }
    }, [])
  }, [allowedStatus, expensesStatusTranslator])

  return {
    allowedStatusOptions,
    fetchAllowedStatus
  }
}
