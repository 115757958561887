/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ConciliationsFiltersContainer } from './ConciliationsFilters.styles'
import { useConciliationsInputs } from '../hooks/inputs/useConciliationsInputs'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'

/**
 * @typedef ConciliationsFiltersProps
 * @prop { (filters: Record<string, string>) => void } onFilterSubmit
 * @prop { Array<Record<string, string>> } bankAccountsOptions
 * @prop { Record<string, string> } filters
 * @prop { boolean } isBankConciliations
 * @prop { string } buttonLabel
 * @prop { boolean } isLoading
 */

/** @type { React.FC<ConciliationsFiltersProps> } */
export const ConciliationsFilters = (
  {
    onFilterSubmit,
    bankAccountsOptions,
    filters,
    isLoading,
    buttonLabel,
    isBankConciliations
  }
) => {
  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useConciliationsInputs(
    filters,
    bankAccountsOptions,
    isBankConciliations
  )
  return (
    <ConciliationsFiltersContainer>
      <FormsInputs
        inputs={inputsValid}
        formErrors={errors}
        formValues={form}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      >
        <button
          type='button'
          disabled={!!isLoading}
          onClick={onSubmit(onFilterSubmit)}
          className='conciliations_filters__btn'
        >
          {buttonLabel}
        </button>
      </FormsInputs>
    </ConciliationsFiltersContainer>
  )
}
