import { billingCore } from './Billing'
import { FinancialScheduleModel } from '../Models/financialSchedule'
import { FinancialScheduleParser } from 'Core/Parser/FinancialSchedule'

const { financialScheduleDomain } = billingCore

export const FinancialScheduleService = {
  list ({ filters, pagination }) {
    return financialScheduleDomain.list(FinancialScheduleModel.list({ filters, pagination })).then(FinancialScheduleParser.list)
  },
  export ({ filters }) {
    return financialScheduleDomain.list(FinancialScheduleModel.export({ filters })).then()
  }
}
