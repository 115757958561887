import { billingCore } from './Billing'
import { CountPlanParser } from 'Core/Parser/CountPlan'
import { CountPlanModel } from '../Models/countPlan'

export const CountPlanService = {
  list ({ filters, pagination }) {
    return billingCore.countPlanDomain.list(CountPlanModel.list({ filters, pagination })).then(CountPlanParser.list)
  },
  getById (planId) {
    return billingCore.countPlanDomain.get(planId).then(CountPlanParser.single)
  },
  create (countPlan) {
    return billingCore.countPlanDomain.create(CountPlanModel.create(countPlan))
  },
  edit (countPlan, planId) {
    return billingCore.countPlanDomain.update(CountPlanModel.create(countPlan), planId)
  },
  remove (planId) {
    return billingCore.countPlanDomain.remove(planId)
  },
  listWithoutPagination () {
    return billingCore.countPlanDomain.list({
      searchParams: {},
      pagination: {}
    }).then(CountPlanParser.listWithoutPagination)
  }
}
