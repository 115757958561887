import { MetaParser } from './Meta'

export const EventParser = {
  single: (event) => {
    const {
      id,
      event_request: eventRequest
    } = event?.event ?? event ?? {}
    return {
      id,
      type: eventRequest?.tipo,
      eventRequest: eventRequest && JSON.stringify(eventRequest),
      eventRequestDetails: EventParser.eventRequestDetails({ eventRequest })
    }
  },
  getEvents: ({ meta, events = [] }) => {
    return {
      events: events.map(EventParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  getEventsTypes: (data = {}, i18n = {}) => {
    const {
      event_types: eventsTypes
    } = data

    if (!eventsTypes?.length) return []

    return ['', ...eventsTypes].map(
      (eventType) => {
        return {
          value: eventType, label: i18n[eventType] || undefined
        }
      }
    ).filter(eventType => !!eventType?.label)
  },
  eventRequestDetails ({ eventRequest = {} }) {
    if (!eventRequest || !Object.keys(eventRequest)?.length) return []

    const details = Object.keys(eventRequest)
      .filter(key => {
        return ![
          'user_id', 'company_id', 'conta_bancaria_id',
          'origin_id', 'pay_payment_id', 'bank_account_id',
          'reservation_payment_id', 'ticket_id', 'invoice_payment_id',
          'tipo_receita_id', 'tipo_despesa_id', 'operator_id', 'gateway_payment_id',
          'bank_billet_id'
        ].includes(key)
      })
      .map(key => {
        let value = ''
        const entry = eventRequest[key]

        switch (typeof entry) {
        case 'object':
          if (Array.isArray(entry) && entry?.length) {
            if (typeof entry[0] === 'string') {
              value = entry.join(', ')
            } else {
              value = entry.map(
                entryValue => EventParser.eventRequestDetails({ eventRequest: entryValue })
              )
            }
          } else {
            value = EventParser.eventRequestDetails({ eventRequest: entry })
          }
          break
        default:
          value = entry
          break
        }
        const type = entry && typeof entry === 'object' ? (Array.isArray(entry) ? 'array' : 'object') : typeof entry

        return {
          key,
          type,
          value
        }
      })
      .filter(obj => {
        if (obj.value === null || obj.value === undefined || obj.value === '') {
          return false
        }
        if (obj.type === 'array' && obj.value.length === 0) {
          return false
        }
        if (obj.type === 'object' && Object.keys(obj.value).length === 0) {
          return false
        }
        return true
      })
      .sort((a, b) => {
        if (a.type === 'object' && b.type !== 'object') {
          return 1
        }
        if (a.type === 'array' && b.type !== 'array' && b.type !== 'object') {
          return 1
        }
        return -1
      })

    return details
  },
  getEventRequestDetails: (event) => {
    const filteredEventRequestDetails = EventParser?.single(event)?.eventRequestDetails.filter(
      ({ type }) => type !== 'object' && type !== 'array')

    return filteredEventRequestDetails
  },
  getEventFieldsNames: ({ fields_names }) => {
    return fields_names
  }
}
