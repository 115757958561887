import { useState, useCallback, useEffect } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConciliationsHooksTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsHooksI18n'
import { BankConciliationService } from 'Core/Service/bankConciliation'

export const useListBankConciliations = () => {
  const { addToast } = useToast()
  const [isLoadingBankConciliations, setIsLoadingBankConciliations] = useState(false)
  const [filters, setFilters] = useState({})
  const [bankConciliationsData, setBankConciliationsData] = useState({
    conciliationsItems: 0,
    partialConciliationsItems: 0,
    notConciliationsItems: 0,
    conciliations: [],
    partialConciliations: [],
    notConciliations: {}
  })

  const i18n = useTranslator(ConciliationsHooksTranslator)

  const fetchBankConciliations = useCallback(() => {
    setIsLoadingBankConciliations(true)
    return BankConciliationService.getBankConciliations(filters).then(
      (data) => {
        setBankConciliationsData(data)
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorLoadingConciliations
      })
    }).finally(() => {
      setIsLoadingBankConciliations(false)
    })
  }, [addToast, filters, i18n?.errorLoadingConciliations])

  const handleFilters = useCallback((filters) => {
    setFilters(filters)
  }, [])

  const handleOnConciliation = useCallback(({ options }) => {
    const partialConciliation = Object.keys(options?.partialConciliation).length ? [options?.partialConciliation] : []
    const conciliation = Object.keys(options?.conciliation).length ? [options?.conciliation] : []
    setBankConciliationsData((oldStates) => ({
      partialConciliationsItems: oldStates?.partialConciliationsItems,
      conciliationsItems: oldStates?.conciliationsItems,
      notConciliationsItems: oldStates?.notConciliationsItems,
      conciliations: [...conciliation, ...oldStates?.conciliations].sort(
        (a, b) => a?.date?.localeCompare(b?.date) || a?.id.localeCompare(b?.id)
      ),
      partialConciliations: [...partialConciliation, ...oldStates?.partialConciliations].sort(
        (a, b) => a?.date?.localeCompare(b?.date) || a?.id.localeCompare(b?.id)
      ),
      notConciliations: {
        bankStatements: oldStates?.notConciliations?.bankStatements.filter(({ id }) => !options?.externalMovementsIds?.includes(id)),
        accountMovements: oldStates?.notConciliations?.accountMovements.filter(({ id }) => !options?.internalMovementsIds?.includes(id))
      }
    }))
  }, [])

  useEffect(() => {
    if (
      !filters?.bankAccountId ||
      !filters?.startDate ||
      !filters?.endDate
    ) return

    fetchBankConciliations()
  }, [
    fetchBankConciliations,
    filters?.bankAccountId,
    filters?.endDate,
    filters?.startDate
  ])

  return {
    isLoadingBankConciliations,
    handleFilters,
    conciliationsData: bankConciliationsData?.conciliations,
    partialConciliations: bankConciliationsData?.partialConciliations,
    notConciliationsData: bankConciliationsData?.notConciliations,
    conciliationsItems: bankConciliationsData?.conciliationsItems,
    partialConciliationsItems: bankConciliationsData?.partialConciliationsItems,
    notConciliationsItems: bankConciliationsData?.notConciliationsItems,
    filters,
    fetchBankConciliations,
    handleOnConciliation
  }
}
