
export const BILLING_GATEWAY_PAYMENT = 'Billing::GatewayPayment'
export const BILLING_BANK_TRANSFER = 'Billing::BankTransfer'
export const BILLING_BANK_TRANSFER_DEBIT = `${BILLING_BANK_TRANSFER}::Debit`
export const BILLING_BANK_TRANSFER_CREDIT = `${BILLING_BANK_TRANSFER}::Credit`
export const BANK_CONCILIATION = 'BankConciliation'
export const BILLING_BANK_TRANSFER_BANK_CONCILIATION_DEBIT = `${BILLING_BANK_TRANSFER}::${BANK_CONCILIATION}::Debit`
export const BILLING_BANK_TRANSFER_BANK_CONCILIATION_CREDIT = `${BILLING_BANK_TRANSFER}::${BANK_CONCILIATION}::Credit`
export const DESCRIPTION = 'Description'
export const TICKET_SELLER_GUIDE_PAYMENT_BATCH = 'TicketSeller::GuidePaymentBatch'
export const BILLING_REVENUE = 'Billing::Revenue'
export const BILLING_REVENUE_RECEIVED = `${BILLING_REVENUE}::Received`
export const BILLING_EXPENSE = 'Billing::Expense'
export const BILLING_EXPENSE_PAYED = `${BILLING_EXPENSE}::Payed`
export const FINANCIAL_RESERVATION_PAYMENT = 'Financial::ReservationPayment'
export const FINANCIAL_RESERVATION_PAYMENT_PAGAR_ME_PIX = `${FINANCIAL_RESERVATION_PAYMENT}::PagarMe::Pix`
export const FINANCIAL_RESERVATION_PAYMENT_CREDIT_DEBIT = `${FINANCIAL_RESERVATION_PAYMENT}::Credit::Debit`
export const FINANCIAL_RESERVATION_PAYMENT_PAYMENT = `${FINANCIAL_RESERVATION_PAYMENT}::Payment`
export const BILLING_BANK_STATEMENT = 'Billing::BankStatement'
export const BILLING_BANK_STATEMENT_PAGAR_ME = `${BILLING_BANK_STATEMENT}::PagarMe`
export const BILLING_BANK_STATEMENT_PAGAR_ME_TRANSFER = `${BILLING_BANK_STATEMENT_PAGAR_ME}::Transfer`
export const BILLING_BANK_STATEMENT_PAGAR_ME_PIX = `${BILLING_BANK_STATEMENT_PAGAR_ME}::Pix`
