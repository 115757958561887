import styled from '@emotion/styled'
import { motion } from 'framer-motion'

export const DetailsContainer = styled(motion.div)`
  width: 100%;
  border: 1px solid var(--clr-gray8);
  border-radius: 0.625rem;
  padding: 1.25rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  color: var(--clr-gray3);
  background-color: var(--clr-white);

  .item_label {
    font-weight: 700;
  }

  .empty__message {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    color: var(--clr-gray4);
    font-weight: 700;
    grid-column: span 3;
  }

  div[class^='annex_details__'] {
    grid-column: span 1;
    display: flex;
    gap: 0.3125rem;

    &.annex_details__wrapper_object {
      box-shadow: 0px 0px 6px #0000001a;
      border-radius: 0.3125rem;
      padding:  0.625rem;
      flex-direction: column;
      gap: 1.25rem;
      grid-column: span 3;
      grid-column-end: auto;
    }

    &.annex_details__object {
      display: grid;
      gap: 0.625rem;
      grid-template-columns: repeat(2, 1fr);

      div {
        display: flex;
        gap: 0.3125rem;
      }
    }
  }

`
