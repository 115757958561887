import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const movimentCardContainer = css`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    .moviment__header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    .section__moviment_canceled {
      background: none;
      
      section {
        width: 100%;
        min-width: 15.875rem ;
        display: flex;
        align-items: center;
        color: ${colors.gray4};
        font-weight: 600;

        p {
          margin-left: 5px;
        }
      }
      
    }

    .moviment__title {
      font-size: 1.125rem;
      font-weight: 700;
      color: ${colors.gray3};
    }
`
