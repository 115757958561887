import { billingCore } from './Billing'
import { SupplierParser } from 'Core/Parser/supplier'
import { SuppliersModel } from 'Core/Models/suppliers'

export const SupplierService = {
  list ({ filters, pagination }) {
    return billingCore.supplierDomain.list(SuppliersModel.list({ filters, pagination })).then(SupplierParser.list)
  },
  create (params) {
    return billingCore.supplierDomain.create(SuppliersModel.payload(params))
  },
  edit (params, supplierId) {
    return billingCore.supplierDomain.update(SuppliersModel.payload(params), supplierId)
  },
  getByIdForEdit (supplierId) {
    return billingCore.supplierDomain.get(supplierId).then(SupplierParser.singleForEdit)
  },
  remove (supplierId) {
    return billingCore.supplierDomain.remove(supplierId)
  },
  getSupplierAudit (supplierId) {
    return billingCore.supplierDomain.getSupplierAudit({}, supplierId).then(SupplierParser.supplierAudit)
  },
  getById (supplierId) {
    return billingCore.supplierDomain.get(supplierId).then(SupplierParser.single)
  },
  options () {
    return billingCore.supplierDomain.options().then(SupplierParser.options)
  }
}
