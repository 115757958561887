import { useState } from 'react'
import { RevenueService } from 'Core/Service/revenue'
import { useUser } from 'Shared/contexts/User'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { RevenuesHooksTranslator } from '../i18n/RevenuesHooksI18n'

export const useEditRevenue = () => {
  const [loading, setLoading] = useState(false)
  const [revenue, setRevenue] = useState()
  const { user } = useUser()
  const { addToast } = useToast()

  const i18n = useTranslator(RevenuesHooksTranslator)

  const fetchRevenue = (revenueId) => {
    setLoading(true)
    RevenueService.getById(revenueId)
      .then(setRevenue)
      .catch(errors => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorFetchingAccountsReceivable
          })
      })
      .finally(() => setLoading(false))
  }

  const onEditRevenue = async (revenueData) => {
    const { id: revenueId } = revenueData
    const safeUserName = user.name || ''
    setLoading(true)
    return RevenueService.edit({ userName: safeUserName, ...revenueData }, revenueId)
      .then(() => {
        addToast(
          {
            defaultMessage: i18n?.accountsReceivableUpdatedSuccessfully,
            typeToast: 'success'
          }
        )
        return true
      })
      .catch(errors => {
        console.error(errors?.data)
        addToast(
          {
            dictTranslation: i18n?.apiCodesMappingForRevenue,
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorUpdatingAccountsReceivable
          }
        )
        return false
      })
      .finally(() => setLoading(false))
  }

  return {
    revenue,
    onEditRevenue,
    fetchRevenue,
    loading,
    setRevenue
  }
}
