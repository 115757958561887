import { acquirersOptions } from 'Constants/acquirersOptions'
import { endDateMustBeAfterOrEqualStartDate } from 'Shared/utils/yup/date/endDateMustBeAfterOrEqualStartDate'
import { startDateMustBeLessThanOrEqualEndDate } from 'Shared/utils/yup/date/startDateMustBeLessThanOrEqualEndDate'

import {
  string as yupString,
  date as yupDate
} from 'yup'

const startDate = {
  label: 'Data inicial',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data inicial',
  className: 'start_date',
  value: 'startDate',
  type: 'date',
  schema: yupDate()
    .typeError('dateFormat')
    .required('dateRequired')
    .test(
      'startDateMustBeLessThanOrEqualEndDate', 'startDateMustBeLessThanOrEqualEndDate', startDateMustBeLessThanOrEqualEndDate
    )
}

const endDate = {
  label: 'Data final',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data final',
  className: 'end_date',
  value: 'endDate',
  type: 'date',
  schema: yupDate()
    .typeError('dateFormat')
    .required('dateRequired')
    .test(
      'endDateMustBeAfterOrEqualStartDate', 'endDateMustBeAfterOrEqualStartDate', endDateMustBeAfterOrEqualStartDate
    )
}

const bankAccounts = (bankAccountsOptions = []) => ({
  label: 'Conta Bancária',
  placeholder: 'Selecione a conta',
  className: 'bank_account',
  value: 'bankAccountId',
  type: 'select',
  options: bankAccountsOptions,
  schema: yupString()
    .optional()
    .required('bankAccountRequired')
})

const acquirers = {
  label: 'Adquirente',
  placeholder: 'Selecione a adquirente',
  className: 'acquirer',
  value: 'acquirer',
  type: 'select',
  withFilter: false,
  options: acquirersOptions,
  schema: yupString()
    .optional()
}

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  startDate,
  endDate,
  bankAccounts,
  acquirers,
  getSchema
}
