import { useMemo } from 'react'

import {
  date,
  referenceDate,
  expenseRevenues,
  value,
  observationWithTypeText,
  serviceName,
  status,
  bankAccounts,
  paymentMethods,
  userName,
  createdAt,
  getSchema
} from '../../../Form/__BaseInputs'
import { paymentMethodsLabels } from 'Domains/ExpenseRevenue/constants/expenseRevenue'
import { useForm } from '@bonitour/app-functions'

const BASE_FORM_VAL = {}

export const useRevenueInputs = (
  bankAccountOptions,
  statusOptions,
  expenseRevenuesOptions,
  currentFormData
) => {
  const inputs = useMemo(() => [
    date,
    referenceDate,
    serviceName,
    status(statusOptions),
    expenseRevenues('Tipo de receita', expenseRevenuesOptions),
    bankAccounts(bankAccountOptions),
    paymentMethods,
    value,
    userName,
    createdAt,
    observationWithTypeText
  ], [bankAccountOptions, expenseRevenuesOptions, statusOptions]
  )

  const schema = useMemo(
    () => getSchema(inputs),
    [inputs]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(currentFormData || BASE_FORM_VAL, schema)

  const newInputsValid = inputs.filter(({ value }) => {
    switch (value) {
    case 'bankAccountId':
      return (form?.bankAccountId || ['received'].includes(form.status))
    case 'paymentMethod':
      return (form?.paymentMethod || ['received'].includes(form.status))
    case 'userName':
    case 'createdAt':
      return Boolean(currentFormData)
    default:
      return true
    }
  })

  const buildPaymentMethods = (bankAccountOptions.find(
    ({ value }) => value === form.bankAccountId) || {}
  ).paymentMethods || {}
  const paymentMethodsOptions = Object.keys(buildPaymentMethods).map((payment_method) => ({
    value: payment_method,
    label: paymentMethodsLabels[payment_method]
  }))

  const newInputs = newInputsValid.map(input => {
    if (input.value === 'paymentMethod') {
      input.options = paymentMethodsOptions
    }
    return input
  })

  return {
    validationSchema: schema,
    inputsValid: newInputs,
    form,
    errors,
    onSubmit,
    onInputChange,
    onInputBlur
  }
}
