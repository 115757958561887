import { useCallback, useEffect, useState } from 'react'
import { CountPlanService } from 'Core/Service/CountPlan'
import { useParamsFilter } from 'Shared/hooks/useParamsFilter'
import dayjs from 'dayjs'
import { useToast } from 'Shared/hooks/useToast'
import { AccountsPlansHooksTranslator } from '../i18n/AccountsPlansHooksI18n'
import { useTranslator } from 'Shared/language/i18nHandler'

export const useListCountPlan = () => {
  const { addToast } = useToast()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [countPlanData, setCountPlanData] = useState({})
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10
  })

  const [initialFilters] = useState({
    initialDate: filters?.initialDate && dayjs(filters?.initialDate, 'YYYY-MM-DD'),
    finalDate: filters?.finalDate && dayjs(filters?.finalDate, 'YYYY-MM-DD'),
    ...filters
  })

  const i18n = useTranslator(AccountsPlansHooksTranslator)

  useEffect(() => {
    fetchCountPlan({
      pagination,
      filters
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filters])

  const handlePagination = useCallback((currentPage, pageLimit) => {
    const paginationInfo = {}
    paginationInfo.currentPage = currentPage
    paginationInfo.perPage = pageLimit
    setPagination(paginationInfo)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchCountPlan = useCallback(({ filters, pagination }) => {
    setLoading(true)

    return CountPlanService.list({ filters, pagination }).then((data) => {
      setCountPlanData(data)
    }).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorFecthingAccountsPlans
      })
    }).finally(() => {
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteCountPlan = useCallback((planId) => {
    CountPlanService.remove(planId).then(() => {
      fetchCountPlan({ pagination, filters })
      addToast({
        defaultMessage: i18n?.successDeletedAccountPlan,
        typeToast: 'success'
      })
    }).catch((errors) => {
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorDeleteAccountPlan
      })
    })
  }, [
    fetchCountPlan,
    pagination,
    filters,
    addToast,
    i18n?.errorDeleteAccountPlan,
    i18n?.successDeletedAccountPlan
  ])

  const onSearch = useCallback((filters) => {
    setFilters(filters)
    setPagination({ currentPage: 1, perPage: pagination?.perPage })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination])

  return {
    filters,
    onSearch,
    deleteCountPlan,
    initialFilters,
    handlePagination,
    countPlans: countPlanData?.countPlans,
    meta: countPlanData?.meta,
    loading
  }
}
