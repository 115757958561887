import { useTranslatedPaymentMethods } from './useTranslatedPaymentMethods'
import { useTranslatedFlags } from './useTranslatedFlags'
import {
  BANK_CONCILIATION,
  BILLING_BANK_STATEMENT_PAGAR_ME,
  BILLING_BANK_STATEMENT_PAGAR_ME_PIX,
  BILLING_BANK_STATEMENT_PAGAR_ME_TRANSFER,
  BILLING_BANK_TRANSFER,
  BILLING_BANK_TRANSFER_BANK_CONCILIATION_CREDIT,
  BILLING_BANK_TRANSFER_BANK_CONCILIATION_DEBIT,
  BILLING_BANK_TRANSFER_CREDIT,
  BILLING_BANK_TRANSFER_DEBIT,
  BILLING_EXPENSE,
  BILLING_EXPENSE_PAYED,
  BILLING_GATEWAY_PAYMENT,
  BILLING_REVENUE,
  BILLING_REVENUE_RECEIVED,
  FINANCIAL_RESERVATION_PAYMENT,
  FINANCIAL_RESERVATION_PAYMENT_CREDIT_DEBIT,
  FINANCIAL_RESERVATION_PAYMENT_PAGAR_ME_PIX,
  FINANCIAL_RESERVATION_PAYMENT_PAYMENT,
  TICKET_SELLER_GUIDE_PAYMENT_BATCH
} from 'Constants/originType'
import {
  LIENCREDIT,
  LIENDEBIT,
  POSMONTHLYFEE
} from 'Constants/flags'
import {
  CREDIT,
  DEBIT,
  PAY_PIX,
  PAYMENT
} from 'Constants/paymentMethodAndOperationType'
import { PAGAR_ME } from 'Constants/gateways'
import { PAYABLE_PIX, TRANSFER } from 'Constants/labels'

export const useTranslatedScheduledDescription = ({ dictionary }) => {
  const { paymentMethodMapped } = useTranslatedPaymentMethods({ dictionary })

  const { flagTranslator } = useTranslatedFlags({ dictionary })

  const objectTranslationsMap = (data) => {
    const translatedPaymentMethod = paymentMethodMapped(data?.paymentMethod)
    const translatedPosAndBankName = data?.pos ? `${data?.pos} - ${data?.bankName}` : data?.bankName
    const translatedFlag = flagTranslator(data?.label)
    return {
      [FINANCIAL_RESERVATION_PAYMENT_CREDIT_DEBIT]: `
      ${dictionary?.common_originTypeReservationPayment_label}: 
      ${data?.reservationCode} ${dictionary?.common_aut_label}/${dictionary?.common_doc_label}: 
      ${data?.authorizationCode}/${data?.proofOfSale} ${data?.gateway} ${data?.brand} 
      (${data?.installment}/${data?.installments})
    `,
      [FINANCIAL_RESERVATION_PAYMENT_PAYMENT]: `
      ${dictionary?.common_originTypeReservationPayment_label}: 
      ${data?.reservationCode} ${data?.clientName} 
      ${dictionary?.common_formOfPayment_label}: ${translatedPaymentMethod} ${data?.bankName}
    `,
      'Financial::ReservationRefund::Credit::Debit': `
      ${dictionary?.common_originTypeReservationRefund_label}: 
      ${data?.reservationCode} 
      ${dictionary?.common_aut_label}/${dictionary?.common_doc_label}: 
      ${data?.authorizationCode}/${data?.proofOfSale} 
      ${data?.gateway} ${data?.brand} (${data?.installment}/${data?.installments})
    `,
      'Financial::ReservationRefund::Refund': `
      ${dictionary?.common_originTypeReservationRefund_label}: 
      ${data?.reservationCode} ${data?.clientName}
      ${dictionary?.common_formOfPayment_label}: ${translatedPaymentMethod} ${data?.bankName}
    `,
      'TicketSeller::Ticket': `
      ${dictionary?.common_reservation_label}: ${data?.reservationCode} 
      ${dictionary?.common_originTypeTicket_label}: ${data?.ticketCode} 
      ${data?.activityName} ${data?.partnerName}
    `,
      'Financial::Invoice': `
      ${dictionary?.common_originTypeInvoice_label}: 
      ${data?.invoiceNumber} - ${data?.partnerName}
    `,
      'Financial::PaymentBatch': `
      ${dictionary?.common_originTypePaymentBatch_label}: 
      ${data?.invoiceNumber} - ${data?.partnerName}
    `,
      'Financial::PartnershipAccountTransaction::Credit': `
      ${dictionary?.common_originTypePartnershipAccountTransactionCredit_label}:  
      ${data?.partnerName} ${translatedPaymentMethod} ${data?.bankName}
    `,
      'Financial::PartnershipAccountTransaction::Debit': `
      ${dictionary?.common_originTypePartnershipAccountTransactionDebit_label}: 
      ${data?.partnerName} ${translatedPaymentMethod} ${data?.bankName}
    `,
      'Financial::CashierHandling::Entry': `
      ${dictionary?.common_originTypeCashierHandlingEntry_label}: 
      ${data?.userName} ${data?.operatorName}
    `,
      'Financial::CashierHandling::Exit': `
      ${dictionary?.common_originTypeCashierHandlingExit_label}: 
      ${data?.operatorName} ${data?.userName}
    `,
      'Expense::Revenue::Scheduled': `
      ${dictionary?.common_scheduling_label}: 
      ${data?.serviceName} ${data?.expenseRevenueDescription}
    `,
      'Expense::Revenue::Canceled': `
      ${dictionary?.common_cancellation_label}: 
      ${data?.serviceName} ${data?.expenseRevenueDescription}
    `,
      [BILLING_EXPENSE_PAYED]: `
      ${dictionary?.common_payment_label}: 
      ${data?.serviceName} ${data?.expenseRevenueDescription}
    `,
      [BILLING_REVENUE_RECEIVED]: `
      ${dictionary?.common_receivement_label}: 
      ${data?.serviceName} ${data?.expenseRevenueDescription}
    `,
      'Expense::Revenue::Refunded': `
      ${dictionary?.common_refunded_label}: 
      ${data?.serviceName} ${data?.expenseRevenueDescription}
    `,
      'Billing::GatewayPayment::LienCredit': `
        ${translatedFlag}: 
        ${translatedPosAndBankName}
      `,
      'Billing::GatewayPayment::LienDebit': `
        ${translatedFlag}: 
        ${translatedPosAndBankName}
      `,
      'Billing::GatewayPayment::PosMonthlyFee': `
        ${translatedFlag}: 
        ${translatedPosAndBankName}
      `,
      [BILLING_BANK_TRANSFER_DEBIT]: `
        ${dictionary?.bank_transfer_originAccount_label} ${data?.originBankName}
      `,
      [BILLING_BANK_TRANSFER_CREDIT]: `
        ${dictionary?.bank_transfer_destinationAccount_label} ${data?.destinationBankName}
      `,
      [BILLING_BANK_TRANSFER_BANK_CONCILIATION_DEBIT]: `
        ${dictionary?.bank_transfer_transferSentTo_label} ${data?.destinationBankName}
      `,
      [BILLING_BANK_TRANSFER_BANK_CONCILIATION_CREDIT]: `
        ${dictionary?.bank_transfer_transferReceivedFrom_label} ${data?.originBankName}
      `,
      [TICKET_SELLER_GUIDE_PAYMENT_BATCH]: `
        ${dictionary?.common_guideBatch_label}: 
        ${data?.invoiceNumber} - ${data?.partnerName}
      `,
      [BILLING_BANK_STATEMENT_PAGAR_ME_PIX]: `
        ${dictionary?.bank_statement_pixPaymentPagarme_label}
      `,
      [BILLING_BANK_STATEMENT_PAGAR_ME_TRANSFER]: `
        ${dictionary?.bank_statement_transferFromPagarme_label}: 
         ${data?.bankAccountName}
      `,
      [FINANCIAL_RESERVATION_PAYMENT_PAGAR_ME_PIX]: `
         ${dictionary?.common_originTypeReservationPayment_label}: 
        ${data?.reservationCode} / ${dictionary?.common_formOfPayment_label}: ${translatedPaymentMethod} / 
        ${dictionary?.common_pagar_me_label}
      `
    }
  }

  const descriptionTranslator = (data) => {
    const objectTranslations = objectTranslationsMap(data)

    let resultSwitch

    switch (data?.originType) {
    case FINANCIAL_RESERVATION_PAYMENT:
      if ([CREDIT, DEBIT].includes(data?.paymentMethod)) {
        resultSwitch = objectTranslations[FINANCIAL_RESERVATION_PAYMENT_CREDIT_DEBIT]
      } else if (![CREDIT, DEBIT].includes(data?.paymentMethod) && data?.infoOperationType === PAYMENT) {
        resultSwitch = objectTranslations[FINANCIAL_RESERVATION_PAYMENT_PAYMENT]
      } else if (data?.paymentMethod === PAY_PIX && data?.gateway === PAGAR_ME) {
        resultSwitch = objectTranslations[FINANCIAL_RESERVATION_PAYMENT_PAGAR_ME_PIX]
      }
      break
    case 'Financial::ReservationRefund':
      if (['credit', 'debit'].includes(data?.paymentMethod)) {
        resultSwitch = objectTranslations['Financial::ReservationRefund::Credit::Debit']
      } else if (!['credit', 'debit'].includes(data?.paymentMethod) && data?.infoOperationType === 'refund') {
        resultSwitch = objectTranslations['Financial::ReservationRefund::Refund']
      }
      break
    case 'TicketSeller::Ticket':
      resultSwitch = objectTranslations['TicketSeller::Ticket']
      break
    case 'Financial::Invoice':
      resultSwitch = objectTranslations['Financial::Invoice']
      break
    case 'Financial::PartnershipAccountTransaction':
      if (data?.transactionType === 'credit') {
        resultSwitch = objectTranslations['Financial::PartnershipAccountTransaction::Credit']
      } else if (data?.transactionType === 'debit') {
        resultSwitch = objectTranslations['Financial::PartnershipAccountTransaction::Debit']
      }
      break
    case 'Financial::CashierHandling':
      if (data?.tipo === 'saida_caixa') {
        resultSwitch = objectTranslations['Financial::CashierHandling::Exit']
      } else if (data?.tipo === 'entrada_caixa') {
        resultSwitch = objectTranslations['Financial::CashierHandling::Entry']
      }
      break
    case BILLING_EXPENSE:
    case BILLING_REVENUE:
      if (data?.infoStatus === 'scheduled') {
        resultSwitch = objectTranslations['Expense::Revenue::Scheduled']
      } else if (data?.infoStatus === 'payed') {
        resultSwitch = objectTranslations[BILLING_EXPENSE_PAYED]
      } else if (data?.infoStatus === 'received') {
        resultSwitch = objectTranslations[BILLING_REVENUE_RECEIVED]
      } else if (data?.infoStatus === 'canceled') {
        resultSwitch = objectTranslations['Expense::Revenue::Canceled']
      } else if (data?.infoStatus === 'refunded') {
        resultSwitch = objectTranslations['Expense::Revenue::Refunded']
      }
      break
    case 'Financial::PaymentBatch':
      resultSwitch = objectTranslations['Financial::PaymentBatch']
      break
    case BILLING_GATEWAY_PAYMENT:
      if ([LIENCREDIT, dictionary?.common_lien_credit_label].includes(data?.label)) {
        resultSwitch = objectTranslations['Billing::GatewayPayment::LienCredit']
      } else if ([LIENDEBIT, dictionary?.common_lien_debit_label].includes(data?.label)) {
        resultSwitch = objectTranslations['Billing::GatewayPayment::LienDebit']
      } else if ([POSMONTHLYFEE, dictionary?.common_pos_monthly_fee_label].includes(data?.label)) {
        resultSwitch = objectTranslations['Billing::GatewayPayment::PosMonthlyFee']
      }
      break
    case BILLING_BANK_TRANSFER:
      if (data?.type === BANK_CONCILIATION) {
        if (data?.operationType === DEBIT) {
          resultSwitch = objectTranslations[BILLING_BANK_TRANSFER_BANK_CONCILIATION_DEBIT]
        } else if (data?.operationType === CREDIT) {
          resultSwitch = objectTranslations[BILLING_BANK_TRANSFER_BANK_CONCILIATION_CREDIT]
        }
      } else if (data?.operationType === DEBIT) {
        resultSwitch = objectTranslations[BILLING_BANK_TRANSFER_DEBIT]
      } else if (data?.operationType === CREDIT) {
        resultSwitch = objectTranslations[BILLING_BANK_TRANSFER_CREDIT]
      }
      break
    case TICKET_SELLER_GUIDE_PAYMENT_BATCH:
      resultSwitch = objectTranslations[TICKET_SELLER_GUIDE_PAYMENT_BATCH]
      break
    case BILLING_BANK_STATEMENT_PAGAR_ME:
      if (data?.label.includes(PAYABLE_PIX)) {
        resultSwitch = objectTranslations[BILLING_BANK_STATEMENT_PAGAR_ME_PIX]
      } else if (data?.label.includes(TRANSFER)) {
        resultSwitch = objectTranslations[BILLING_BANK_STATEMENT_PAGAR_ME_TRANSFER]
      }
      break
    default:
      resultSwitch = data?.description
      break
    }

    const safeResult = resultSwitch || data?.description || ''

    return safeResult.replace(/\n\s+/g, '')
  }

  return descriptionTranslator
}
