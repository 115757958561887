/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  EventProcessorAutomationRulesContainer as Container
} from './EventProcessorAutomationRules.styles'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsI18n'
import { MatchedEventsContent } from '../EventProcessorEntryConditions/MatchedEvents/MatchedEvents'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AutomationRulesSimulations } from './AutomationRulesSimulations/AutomationRulesSimulations'
import { useParams, useHistory } from 'react-router-dom'
import { AutomationRules } from './AutomationRules/AutomationRules'
import { useGetAutomationRulesSimulations } from 'Domains/EventsProcessors/hooks/useGetAutomationRulesSimulations'
import { useRemoveAutomationRule } from 'Domains/EventsProcessors/hooks/useRemoveAutomationRule'
import { useGetAutomationRules } from 'Domains/EventsProcessors/hooks/useGetAutomationRules'
import { AddLabel } from 'Shared/components/AddLabel/AddLabel'
import { Button, GhostButton } from '@bonitour/components'

/**
 * @typedef EventProcessorAutomationRulesProps
 * @prop { Record<string, string>[] } matchedEventsData
 * @prop { boolean } matchedEventsLoading
 * @prop { () => void } onBack
 */

/** @type { React.FC<EventProcessorAutomationRulesProps> } */
export const EventProcessorAutomationRules = ({
  matchedEventsData,
  matchedEventsLoading,
  onBack
}) => {
  const routerParams = useParams()

  const eventProcessorId = useMemo(() => routerParams?.eventProcessorId, [routerParams?.eventProcessorId])

  const i18n = useTranslator(EventsProcessorsTranslator)
  const [selectedEventId, setSelectedEventId] = useState()

  const {
    getAutomationRulesSimulations,
    automationRulesSimulationsData,
    loading: loadingAutomationRulesSimulations
  } = useGetAutomationRulesSimulations()

  useEffect(() => {
    if (!eventProcessorId || !selectedEventId || automationRulesSimulationsData) return
    getAutomationRulesSimulations(eventProcessorId, selectedEventId)
  }, [
    automationRulesSimulationsData,
    getAutomationRulesSimulations,
    selectedEventId,
    eventProcessorId
  ])

  const {
    getAutomationRules,
    automationRulesData,
    loading: loadingAutomationRules
  } = useGetAutomationRules()

  useEffect(() => {
    if (!eventProcessorId || automationRulesData) return
    getAutomationRules(eventProcessorId)
  }, [
    automationRulesData,
    eventProcessorId,
    getAutomationRules
  ])

  const {
    removeAutomationRule,
    loading: loadingAutomationRuleRemove
  } = useRemoveAutomationRule()

  const onRemoveAutomationRule = useCallback((
    automationRuleId
  ) => {
    removeAutomationRule(eventProcessorId, automationRuleId).then(() => {
      getAutomationRules(eventProcessorId)
      if (selectedEventId) {
        getAutomationRulesSimulations(eventProcessorId, selectedEventId)
      }
    })
  }, [
    getAutomationRules,
    removeAutomationRule,
    getAutomationRulesSimulations,
    selectedEventId,
    eventProcessorId
  ])

  const { push } = useHistory()

  const redirectPageCreateEditAutomationRule = useCallback(() => {
    push(
      `/events-processors/create/${eventProcessorId}/automation-rules/${selectedEventId}`
    )
  }, [
    eventProcessorId,
    push,
    selectedEventId
  ])

  const redirectListEventsProcessors = useCallback(() => {
    push(
      '/events-processors'
    )
  }, [push])

  return (
    <Container>
      <section>
        <h3
          className='container__title'
        >
          {i18n?.matchedEventsTiteInAutomationRules}
        </h3>
        <MatchedEventsContent
          matchedEventsData={matchedEventsData}
          isLoading={matchedEventsLoading}
          i18n={i18n}
          isSelectEvent
          setSelectedEventId={setSelectedEventId}
          selectedEventId={selectedEventId}
        />
      </section>
      <AddLabel onClick={redirectPageCreateEditAutomationRule}>
        {i18n?.newAutomationRule}
      </AddLabel>
      <AutomationRules
        onRemoveAutomationRule={onRemoveAutomationRule}
        loadingAutomationRuleRemove={loadingAutomationRuleRemove}
        loadingAutomationRules={loadingAutomationRules}
        automationRulesData={automationRulesData}
        selectedEventId={selectedEventId}
        eventProcessorId={eventProcessorId}
      />
      <AutomationRulesSimulations
        automationRulesSimulationsData={automationRulesSimulationsData}
        loading={loadingAutomationRulesSimulations}
      />
      <section className='wrapper_btns'>
        <GhostButton
          type='reset'
          onClick={onBack}
        >
          {i18n?.back}
        </GhostButton>
        <Button
          type='button'
          onClick={redirectListEventsProcessors}
        >
          {i18n?.finalize}
        </Button>
      </section>
    </Container>
  )
}
