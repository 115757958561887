import {
  BILLING_EXPENSE,
  BILLING_REVENUE,
  TICKET_SELLER_GUIDE_PAYMENT_BATCH
} from 'Constants/originType'

export const defineCardFlagLabel = {
  american_express: 'American Express',
  diners: 'Diners',
  discover: 'Discover',
  elo: 'Elo',
  hipercard: 'Hipercard',
  mastercard: 'Mastercard',
  visa: 'Visa'
}

export const creditCardOperationTypeOptions = [
  {
    value: '',
    label: 'Todos'
  },
  {
    value: 'sale',
    label: 'Vendas'
  },
  {
    value: 'refund',
    label: 'Estornos'
  }
]

export const originTypeOptions = [
  'Financial::ReservationPayment',
  'Financial::ReservationRefund',
  'TicketSeller::Ticket',
  'Financial::Invoice',
  'Financial::PartnershipAccountTransaction',
  BILLING_EXPENSE,
  BILLING_REVENUE,
  'Financial::PaymentBatch',
  'Financial::CashierHandling',
  'Billing::GatewayPayment',
  'Billing::BankTransfer',
  TICKET_SELLER_GUIDE_PAYMENT_BATCH
]

export const flowMovementsStatusColors = {
  canceled: '--clr-red1',
  processed: '--clr-green2',
  need_confirmation: '--clr-yellow1',
  waiting: '--clr-yellow1',
  done: '--clr-green2',
  error: '--clr-gray10'
}

export const flowMovementsOperationTypeColors = {
  credit: '--clr-green2',
  debit: '--clr-red1',
  error: '--clr-gray10'
}
