/**
 * @typedef CsvOptions
 * @prop { string } [delimiter]
 * @prop { { key: string, label: string }[] } headers
 */

/**
 * @param {  Record<string, string | number | undefined>[] } data
 * @param { CsvOptions } options
 */

export const csvGenerator = (data, options) => {
  const delimiter = options.delimiter || ';'
  const headers = options.headers

  const headerString = headers.map(header => header.label).join(delimiter)

  const bodyStrings = data.map(row => {
    return headers.map(header => row[header.key] || '').join(delimiter)
  })

  return `\uFEFF${headerString}\n${bodyStrings.join('\n')}`
}
