/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import {
  PageCreateEditAutomationRuleContainer as Container
} from './PageCreateEditAutomationRule.styles'
import { useParams, useHistory } from 'react-router-dom'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsI18n'
import { FormActions } from 'Shared/components/Form/__FormActions'
import { useCreateAutomationRule } from 'Domains/EventsProcessors/hooks/useCreateAutomationRule'
import { useUpdateAutomationRule } from 'Domains/EventsProcessors/hooks/useUpdateAutomationRule'
import { AllowedFormulas } from './AllowedFormulas/AllowedFormulas'
import { AllowedFields } from './AllowedFields/AllowedFields'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { useCreateEditAutomationRuleInputs } from 'Domains/EventsProcessors/hooks/inputs/useCreateEditAutomationRuleInputs'
import { useGetAutomationRuleAccountFormulas } from 'Domains/EventsProcessors/hooks/useGetAutomationRuleAccountFormulas'
import { useGetAutomationRuleActions } from 'Domains/EventsProcessors/hooks/useGetAutomationRuleActions'
import { useGetAutomationRuleAccounts } from 'Domains/EventsProcessors/hooks/useGetAutomationRuleAccounts'
import { useGetAutomationRuleDataForInputs } from 'Domains/EventsProcessors/hooks/useGetAutomationRuleDataForInputs'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'

export const PageCreateEditAutomationRule = () => {
  const { push } = useHistory()
  const routerParams = useParams()

  useFlaggedRoute('billing-events-processors')

  const i18n = useTranslator(EventsProcessorsTranslator)

  const eventProcessorId = useMemo(
    () => routerParams?.eventProcessorId, [routerParams?.eventProcessorId]
  )
  const matchedEventId = useMemo(
    () => routerParams?.matchedEventId, [routerParams?.matchedEventId]
  )
  const automationRuleId = useMemo(
    () => routerParams?.automationRuleId, [routerParams?.automationRuleId]
  )

  const returnListAutomationRules = useCallback(
    () => {
      return push(
        `/events-processors/edit/${eventProcessorId}?section=3`
      )
    }, [eventProcessorId, push]
  )

  const isEdit = useMemo(() =>
    routerParams?.[0] === 'edit' && automationRuleId && eventProcessorId,
  [
    automationRuleId,
    eventProcessorId,
    routerParams
  ])

  const {
    createAutomationRule,
    loading: loadingCreateAutomationRule
  } = useCreateAutomationRule()

  const {
    updateAutomationRule,
    loading: loadingUpdateAutomationRule
  } = useUpdateAutomationRule()

  const handleCreateEditAutomationRule = useCallback((payload) => {
    if (isEdit) {
      updateAutomationRule(payload, eventProcessorId, automationRuleId).then(
        () => returnListAutomationRules()
      )
    } else {
      createAutomationRule(payload, eventProcessorId).then(
        () => returnListAutomationRules()
      )
    }
  }, [
    automationRuleId,
    createAutomationRule,
    eventProcessorId,
    isEdit,
    updateAutomationRule,
    returnListAutomationRules
  ])

  const {
    getAutomationRuleDataForInputs,
    automationRuleData
  } = useGetAutomationRuleDataForInputs()

  useEffect(() => {
    if (!eventProcessorId || !automationRuleId || automationRuleData) return

    getAutomationRuleDataForInputs(eventProcessorId, automationRuleId)
  }, [automationRuleData,
    automationRuleId,
    eventProcessorId,
    getAutomationRuleDataForInputs
  ])

  const {
    automationRuleAccountsData
  } = useGetAutomationRuleAccounts()

  const {
    automationRuleActionsData
  } = useGetAutomationRuleActions()

  const {
    automationRuleAccountFormulasData
  } = useGetAutomationRuleAccountFormulas()

  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useCreateEditAutomationRuleInputs(
    automationRuleData,
    automationRuleActionsData,
    automationRuleAccountsData,
    automationRuleAccountFormulasData
  )

  return (
    <>
      <PageTitle
        title={isEdit ? i18n?.pageTitleEditingAutomationRule : i18n?.pageTitleNewAutomationRule}
        onClickReturn={returnListAutomationRules}
      />
      <Container>
        <section className='wrapper__inputs_infos'>
          <div className='section__inputs'>
            <h3>{i18n?.rules}</h3>
            <FormsInputs
              formErrors={errors}
              formValues={form}
              onInputBlur={onInputBlur}
              onInputChange={onInputChange}
              inputs={inputsValid}
            />
          </div>
          <div className='section__infos'>
            <AllowedFields
              matchedEventId={matchedEventId}
            />
            <AllowedFormulas />
          </div>
        </section>
        <section className='wrapper__btns'>
          <FormActions
            onSubmit={onSubmit(handleCreateEditAutomationRule)}
            onClose={returnListAutomationRules}
            disabled={loadingCreateAutomationRule || loadingUpdateAutomationRule}
          />
        </section>
      </Container>

    </>

  )
}
