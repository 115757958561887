import { apiCodesTranslations } from 'Shared/language/utils/functions'
import { cashDepositsStatusOptions } from '../../Constants/cashDepositsStatusOptions'

interface CashDepositsStatusTranslations {
  [key: string]: string,
  done: string
  canceled: string
}

export const useTranslatedCashDepositsStatus = ({ dictionary }) => {
  const cashDepositsStatusTranslations: CashDepositsStatusTranslations = {
    done: dictionary?.common_realizedStatus_label,
    canceled: dictionary?.common_canceledStatus_label
  }

  const cashDepositsStatusTranslator = (status: string): string => {
    return apiCodesTranslations(
      {
        apiCode: status,
        codesMapped: cashDepositsStatusTranslations,
        isToLowerCase: false
      }
    )
  }

  const cashDepositsStatusOptionsTranslated = cashDepositsStatusOptions.map((value) => {
    return {
      value,
      label: value ? cashDepositsStatusTranslator(value) : dictionary?.common_all_label
    }
  })

  return {
    cashDepositsStatusTranslator,
    cashDepositsStatusOptionsTranslated
  }
}
