interface BankTransfersHooksTranslatorRes {
  errorWhenSearchingForTransfersBetweenBankAccounts: string,
  transferBetweenAccountCreatedSuccess: string
  errorCreatingTransferBetweenAccount: string
  errorUpdatingTransferBetweenAccount: string
  transferBetweenAccountUpdatedSuccess: string
  errorUpdatingAccountTransfer: string
  apiCodesMappingForBankTransfers: {
    'bank_transfer::destination_bank_account_id::cannot_be_equal_to_origin_bank_account': string
  },
  errorWhenSearchingForTransferHistoryBetweenAccounts: string
}

export const BankTransfersHooksTranslator = (
  dictionary
): BankTransfersHooksTranslatorRes => {
  const apiCodesMappingForBankTransfers = {
    'bank_transfer::destination_bank_account_id::cannot_be_equal_to_origin_bank_account': dictionary?.bank_transfer_theSourceAccountMustBeDifferentFromTheTargetAccount_label
  }

  const errorWhenSearchingForTransfersBetweenBankAccounts = dictionary?.bank_transfer_errorWhenSearchingForTransfersBetweenBankAccounts_toast
  const transferBetweenAccountCreatedSuccess = dictionary?.bank_transfer_transferBetweenAccountCreatedSuccess_toast
  const errorCreatingTransferBetweenAccount = dictionary?.bank_transfer_errorCreatingTransferBetweenAccount_toast
  const errorUpdatingTransferBetweenAccount = dictionary?.bank_transfer_errorUpdatingTransferBetweenAccount_toast
  const transferBetweenAccountUpdatedSuccess = dictionary?.bank_transfer_transferBetweenAccountUpdatedSuccess_toast
  const errorUpdatingAccountTransfer = dictionary?.bank_transfer_errorUpdatingAccountTransfer_toast
  const errorWhenSearchingForTransferHistoryBetweenAccounts = dictionary?.bank_transfer_errorWhenSearchingForTransferHistoryBetweenAccounts_toast

  return {
    errorWhenSearchingForTransfersBetweenBankAccounts,
    transferBetweenAccountCreatedSuccess,
    errorCreatingTransferBetweenAccount,
    errorUpdatingTransferBetweenAccount,
    transferBetweenAccountUpdatedSuccess,
    errorUpdatingAccountTransfer,
    apiCodesMappingForBankTransfers,
    errorWhenSearchingForTransferHistoryBetweenAccounts
  }
}
