import { string as yupString } from 'yup'

const corporateName = {
  label: 'Razão Social',
  className: 'corporate_name',
  value: 'corporateName',
  type: 'text',
  schema: yupString(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite a razão social'
}

const fancyName = {
  label: 'Nome Fantasia',
  className: 'fancy_name',
  value: 'fancyName',
  type: 'text',
  schema: yupString(),
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite o nome fantasia'
}

const enabled = (enabledOptions = []) => ({
  label: 'Estado',
  className: 'enabled',
  value: 'enabled',
  type: 'select',
  options: enabledOptions,
  schema: yupString(),
  withFilter: false
})

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  corporateName,
  fancyName,
  enabled,
  getSchema
}
