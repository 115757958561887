/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Portal } from 'Shared/components/Portal/Portal'
import { CloseIcon } from '@bonitour/components'
import {
  ModalContent,
  ModalBg
} from './ConfirmDialog.styles'

/**
 * @typedef ConfirmDialogProps
 * @prop { boolean } isVisible
 * @prop { string } title
 * @prop { string } subtitle
 * @prop { string } messageRes
 * @prop { boolean } isLoading
 * @prop { () => void } onClose
 * @prop { () => void } onSuccess
 */

/** @type { React.FC<ConfirmDialogProps> } */
export const ConfirmDialog = (
  {
    isVisible = false,
    title = 'Tem certeza que deseja excluir o extrato?',
    subtitle = 'Após a confirmação, não tem como rever a ação.',
    onClose,
    onSuccess
  }
) => {
  return isVisible && (
    <Portal selector='__MODALS_PORTAL__'>
      <ModalContent>
        <ModalBg
          type='button' onClick={onClose}
        />
        <div className='modal__card'>
          <div className='modal__info'>
            <button
              type='button'
              onClick={onClose}
              className='close-modal-button'
            >
              <CloseIcon />
            </button>
            <h3 className='modal__info_title'>{title}</h3>
            <p className='modal__info_subtitle'>{subtitle}</p>
          </div>

          <div className='button__wrapper'>
            <button onClick={onClose}>
              Voltar
            </button>

            <button onClick={onSuccess}>
              Excluir
            </button>
          </div>

        </div>
      </ModalContent>
    </Portal>
  )
}
