import { formatMoney } from '@binamik/js-functions'
import { ExpenseParsed } from 'Core/Types/Expense'

export const calculateValuesExpenses = (expenses: ExpenseParsed[]) => {
  const totalExpenses = expenses?.reduce((acc, expense) => {
    return acc + Number(expense?.value)
  }, 0)

  return {
    formattedMoney: formatMoney(totalExpenses),
    formattedNumber: totalExpenses
  }
}
