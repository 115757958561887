import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const headerTitleStyle = css`
  color: ${colors.gray2};
  margin-top: 50px;
`

export const width30 = css`
  width: 30%;
`

export const bold = css`
  font-weight: bold;
  color: ${colors.gray1};
`

export const iconButton = css`
  cursor: pointer;
  margin-right: 8px;
  font-size: 18px;
`

export const fontColor = (color) => css`
  color: ${color};
`
