import React, { useCallback } from 'react'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useHistory } from 'react-router-dom'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'
import { Card, Label, PaginationBar, PlusIcon } from '@bonitour/components'
import { cardIcon, wrapperFiltersList } from 'Shared/styles/global'
import { CardButton } from 'Shared/components/CardButton'
import { CashDepositFilters } from './Filters/CashDepositFilters'
import { useGetCashDeposits } from './hooks/useGetCashDeposits'
import { CashDepositsList } from './List/CashDepositsList'
import { CashDepositsTranslator } from './i18n/CashDepositsI18n'
import { useTranslator } from 'Shared/language/i18nHandler'

export const CashDeposits = (): React.Component => {
  useFlaggedRoute('billing-cash-deposits')

  const { push } = useHistory()

  const onReturnCreditCardDashboard = useCallback(() => {
    push('/bank-action')
  }, [push])

  const i18n = useTranslator(CashDepositsTranslator)

  const {
    filters,
    handleFiltersChange,
    handleOnPageChange,
    handleOnPerPageChange,
    pagination,
    cashDeposits,
    loading,
    newPerPageOptions
  } = useGetCashDeposits()

  return (
    <>
      <PageTitle
        title={i18n.cashDeposit}
        onClickReturn={onReturnCreditCardDashboard}
      />
      <CardButton>
        <PlusIcon css={cardIcon} />
        <Label>{i18n.newCashDeposit}</Label>
      </CardButton>
      <Card customCss={[wrapperFiltersList]}>
        <CashDepositFilters
          handleFiltersChange={handleFiltersChange}
          filters={filters}
          isLoading={loading}
          buttonLabel={i18n.searchButton}
        />
        <CashDepositsList
          cashDeposits={cashDeposits}
          isLoading={loading}
          filters={filters}
        />
        <PaginationBar
          currentPage={pagination.page}
          perPage={pagination.perPage}
          totalPages={pagination.totalPages}
          totalElements={pagination.totalItems}
          onPageChange={handleOnPageChange}
          onPerPageChange={handleOnPerPageChange}
          perPageOptions={newPerPageOptions}
        />
      </Card>
    </>
  )
}
