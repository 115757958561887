import React from 'react'
import { RevenueForm as FormContainer } from '../../Form/__Forms.styles'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { FormActions } from 'Shared/components/Form/__FormActions'
import { useRevenueInputs } from '../hooks/inputs/useRevenueInputs'

/**
 * @typedef RevenueFormProps
 * @prop { Record<string, string> } currentFormData
 * @prop { () => void} onCancel
 * @prop { (formData: Record<string, string>) => void} onSubmit
 * @prop { Record<string, string>[] } bankAccountOptions
 * @prop { Record<string, string>[] } statusOptions
 * @prop { Record<string, string>[] } expenseRevenuesOptions
 */

/** @type { React.FC<RevenueFormProps> } */
export const RevenueForm = ({
  onCancel,
  handleCreateAndEdit,
  currentFormData,
  bankAccountOptions,
  statusOptions,
  expenseRevenuesOptions
}) => {
  const {
    inputsValid,
    form,
    errors,
    onSubmit,
    onInputChange,
    onInputBlur
  } = useRevenueInputs(
    bankAccountOptions,
    statusOptions,
    expenseRevenuesOptions,
    currentFormData
  )

  return (
    <FormContainer
      onReset={onCancel}
      onSubmit={e => e.preventDefault()}
    >
      <FormsInputs
        inputs={inputsValid}
        formValues={form}
        formErrors={errors}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      />

      <section className='form__actions'>
        <FormActions
          onSubmit={onSubmit(handleCreateAndEdit)}
          isEditing={Boolean(currentFormData)}
          onClose={onCancel}
        />
      </section>
    </FormContainer>
  )
}
