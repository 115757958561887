import { SafeDate } from '@binamik/js-providers'
import { GetCashDepositsParams } from 'Core/Types/CashDeposit'
import { cashDepositsStatusOptions } from 'Domains/BankAction/CashDeposits/Constants/cashDepositsStatusOptions'

export const CashDepositsModel = {
  getCashDeposits: (params: GetCashDepositsParams) => {
    const {
      startDate,
      endDate,
      status,
      destinationBankAccountId
    } = params?.filters ?? {}

    const {
      currentPage,
      perPage
    } = params?.pagination ?? {}

    const safeStartDate = startDate && new SafeDate(startDate).isoDate
    const safeEndDate = endDate && new SafeDate(endDate).isoDate

    const newParams = {
      start_date: safeStartDate,
      end_date: safeEndDate,
      status,
      destination_bank_account_id: destinationBankAccountId || undefined,
      page: currentPage,
      per_page: perPage
    }

    const containAll = cashDepositsStatusOptions.every((val) =>
      status?.includes(val)
    )

    if (containAll) {
      delete newParams.status
    }

    return newParams
  }
}
