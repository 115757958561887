import { string, bool, number } from 'yup'

export const validationSchema = {
  name: string().required('Preencha o campo de nome'),
  code: number()
    .required('Preencha o campo de código')
    .typeError('Precisa ser um número'),
  planType: string().required('Preencha o campo de tipo de plano'),
  classification: string()
    .required('Preencha o campo de classificação')
    .matches(/^[(^)(\d+(.\d+)*)($)]+$/, 'Formato de classificação inválido'),
  enabled: bool()
}
