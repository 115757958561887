import styled from '@emotion/styled'

export const EventsFiltersContainer = styled.div`
  .form__inputs {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem;
    border-radius: 0.625rem;
    gap: 1.25rem;
    background-color: var(--clr-gray8);
    margin-bottom: 1.875rem;

    .input__type_of_event {
      flex: 0.5;
    }

    .events_filters__btn {
      flex: 0.2;
      font-weight: 700;
      height: 2.5rem;
      align-self: flex-end;
    }
  }

`
