import { billingCore } from './Billing'
import { EventModel } from '../Models/event'
import { EventParser } from 'Core/Parser/event'

export const EventService = {
  getEvents (filters, pagination) {
    return billingCore.eventDomain.getEvents(EventModel.getEvents(filters, pagination)).then(EventParser.getEvents)
  },
  getEventsTypes (i18n) {
    return billingCore.eventDomain.getEventsTypes().then(
      (data) => EventParser.getEventsTypes(data, i18n)
    )
  },
  getEventFieldsNames (eventId) {
    return billingCore.eventDomain.getEventFieldsNames(
      eventId
    ).then(EventParser.getEventFieldsNames)
  },
  getEventRequestDetails (eventId) {
    return billingCore.eventDomain.getEvent(eventId).then(EventParser.getEventRequestDetails)
  },
  create (event) {
    return billingCore.eventDomain.create(EventModel.create(event))
  },
  remove (eventId) {
    return billingCore.eventDomain.remove(eventId)
  },
  edit (event, eventId) {
    return billingCore.eventDomain.update(EventModel.edit(event), eventId)
  }
}
