import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ReceivablesHooksTranslator } from '../i18n/ReceivablesHooksI18n'
import { ReceivablesService } from 'Core/Service/receivables'
import { DeleteGatewayPaymentsParams, UseRemoveReceivablesProps } from 'Core/Types/Receivable'

export const useRemoveReceivables = ({
  fetchReceivables
}: UseRemoveReceivablesProps) => {
  const { addToast } = useToast()
  const [isLoadingDeleteGatewayPayments, setIsLoadingDeleteGatewayPayments] = useState(false)

  const i18n = useTranslator(ReceivablesHooksTranslator)

  const deleteGatewayPayments = useCallback(
    async (params: DeleteGatewayPaymentsParams) => {
      setIsLoadingDeleteGatewayPayments(true)
      return await ReceivablesService.deleteGatewayPayments(params).then(
        ({ deletedGatewayPayments }) => {
          fetchReceivables()
          addToast({
            defaultMessage: i18n?.deleteReceivable(deletedGatewayPayments),
            typeToast: 'success'
          })
        }
      ).catch(errors => {
        addToast({
          msgs: errors?.data?.errors_msg,
          dictTranslation: i18n?.apiCodesMappingForReceivables
        })
      }).finally(() => {
        setIsLoadingDeleteGatewayPayments(false)
      })
    }, [addToast, fetchReceivables, i18n])

  return {
    deleteGatewayPayments,
    isLoadingDeleteGatewayPayments
  }
}
