import { EditCountPlan } from 'Domains/AccountingFunction/CountPlan/Edit/EditCountPlan'
import { CreateCountPlan } from 'Domains/AccountingFunction/CountPlan/Create/CreateCountPlan'
import { CountPlan } from 'Domains/AccountingFunction/CountPlan/CountPlan'
import { Events } from 'Domains/Events/Events'
import { EventsProcessors } from 'Domains/EventsProcessors/EventsProcessors'
import { ExpenseRevenueDashboard } from 'Domains/ExpenseRevenue/Dashboard/ExpenseRevenueDashboard'
import { FinancialSchedule } from 'Domains/FinancialSchedule/FinancialSchedule'
import { Layout } from 'Domains/Layout/Layout'
import { Dashboard } from 'Domains/Dashboard/Dashboard'
import { Revenues } from 'Domains/ExpenseRevenue/Revenues/Revenues'
import { CreateRevenue } from 'Domains/ExpenseRevenue/Revenues/Create/CreateRevenue'
import { ExpenseRevenueType } from 'Domains/ExpenseRevenue/ExpenseRevenueType/ExpenseRevenueType'
import { FullFinancialSchedule } from 'Domains/FinancialSchedule/FullFinancialSchedule'
import { BankAccounts } from 'Domains/BankAction/BankAccounts/BankAccounts'
import { BankConciliations } from 'Domains/BankAction/BankConciliations/BankConciliations'
import { Suppliers } from 'Domains/ExpenseRevenue/Suppliers/Suppliers'
import { PageSelectEvents } from 'Domains/Events/PageSelectEvents'
import { PageCreateEditEventProcessor } from 'Domains/EventsProcessors/PageCreateEditEventProcessor/PageCreateEditEventProcessor'
import { PageCreateEditAutomationRule } from 'Domains/EventsProcessors/PageCreateEditEventProcessor/EventProcessorAutomationRules/PageCreateEditAutomationRule/PageCreateEditAutomationRule'
import { SupplierBankAccounts } from 'Domains/ExpenseRevenue/Suppliers/SupplierBankAccount/SupplierBankAccounts'
import { CreditCardDashboard } from 'Domains/CreditCard/Dashboard/CreditCardDashboard'
import { Receivables } from 'Domains/CreditCard/Receivables/Receivables'
import { ImportFailure } from 'Domains/CreditCard/Receivables/ReceivablesImport/ImportFailure/ImportFailure'
import { CreditCardConciliations } from 'Domains/CreditCard/CreditCardConciliations/CreditCardConciliations'
import TesteComponent from 'Domains/TestComponents/Counter'
import { BankActionDashboard } from 'Domains/BankAction/Dashboard/BankActionDashboard'
import { BankStatements } from 'Domains/BankAction/BankStatements/BankStatements'
import { AccountingFucntionDashboard } from 'Domains/AccountingFunction/Dashboard/AccountingFunctionDashboard'
import { BankTransfers } from 'Domains/BankAction/BankTransfers/BankTransfers'
import { Expenses } from 'Domains/ExpenseRevenue/Expenses/Expenses'
import { Configuration } from 'Domains/Configuration/Configuration'
import { PageCreateEditExpense } from 'Domains/ExpenseRevenue/Expenses/PageCreateEditExpense/PageCreateEditExpense'
import { PageCreateEditExpenseRecurrency } from 'Domains/ExpenseRevenue/Expenses/PageCreateEditExpenseRecurrency/PageCreateEditExpenseRecurrency'
import { CashDeposits } from 'Domains/BankAction/CashDeposits/CashDeposits'

const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV

export const routes = [
  {
    path: '/',
    component: Layout,
    routes: [
      {
        path: 'financial-schedule',
        component: FinancialSchedule
      },
      {
        path: 'receivables/import-failure',
        component: ImportFailure
      },
      {
        path: 'credit-card/receivables',
        component: Receivables
      },
      {
        path: 'expense-revenue-dashboard/expense-revenue-type',
        component: ExpenseRevenueType
      },
      {
        path: 'expense-revenue-dashboard/revenues/create',
        component: CreateRevenue
      },
      {
        path: 'expense-revenue-dashboard/revenues',
        component: Revenues
      },
      {
        path: 'expense-revenue-dashboard/expenses/(create|edit)/:expenseId?',
        component: PageCreateEditExpense
      },
      {
        path: 'expense-revenue-dashboard/expenses-recurrency/(create|edit)/:expenseId?/:recurrenceId?',
        component: PageCreateEditExpenseRecurrency
      },
      {
        path: 'expense-revenue-dashboard/expenses',
        component: Expenses
      },
      {
        path: 'bank-action/bank-accounts',
        component: BankAccounts
      },
      {
        path: 'expense-revenue-dashboard/suppliers/:supplierId?/supplier-bank-accounts',
        component: SupplierBankAccounts
      },
      {
        path: 'expense-revenue-dashboard/suppliers',
        component: Suppliers
      },
      {
        path: 'expense-revenue-dashboard',
        component: ExpenseRevenueDashboard
      },
      {
        path: 'full-financial-schedule',
        component: FullFinancialSchedule
      },
      {
        path: 'bank-action/bank-statements',
        component: BankStatements
      },
      {
        path: 'bank-action/conciliations',
        component: BankConciliations
      },
      {
        path: 'events-processors/(create|edit)/:eventProcessorId/automation-rules/:matchedEventId/:automationRuleId?',
        component: PageCreateEditAutomationRule
      },
      {
        path: 'events-processors/(create|edit)/:eventProcessorId?/:eventId?',
        component: PageCreateEditEventProcessor
      },
      {
        path: 'events-processors',
        component: EventsProcessors
      },
      {
        path: 'events/select/:eventProcessorId?/:eventId?',
        component: PageSelectEvents
      },
      {
        path: 'events',
        component: Events
      },
      {
        path: 'credit-card/conciliations',
        component: CreditCardConciliations
      },
      {
        path: 'credit-card',
        component: CreditCardDashboard
      },
      {
        path: 'bank-action/bank-transfers',
        component: BankTransfers
      },
      {
        path: 'bank-action/cash-deposits',
        component: CashDeposits
      },
      {
        path: 'bank-action',
        component: BankActionDashboard
      },
      {
        path: 'configuration',
        component: Configuration
      },
      ...(APP_ENVIRONMENT !== 'production'
        ? [

          {
            path: 'accounting-function/count-plan/edit/:id',
            component: EditCountPlan
          },
          {
            path: 'accounting-function/count-plan/create',
            component: CreateCountPlan
          },
          {
            path: 'accounting-function/count-plan',
            component: CountPlan
          },
          {
            path: 'test/counter',
            component: TesteComponent
          }
        ]
        : []),
      {
        path: 'accounting-function',
        component: AccountingFucntionDashboard
      },
      {
        path: 'dashboard',
        component: Dashboard
      },
      {
        path: 'dashboard',
        redirect: true
      }
    ]
  }
]
