import { useForm } from '@bonitour/app-functions'
import {
  getSchema,
  nameWithRequired,
  description
} from 'Domains/EventsProcessors/Form/__EventsProcessorsBaseInputs'
import { EventsProcessorsInputsErrorsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsInputsErrorsI18n'
import { EventsProcessorsInputsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsInputsI18n'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { useMemo } from 'react'

const BASE_FORM = {}

export const useCreateEditEventProcessorInputs = (
  eventProcessorData
) => {
  const inputsValid = useTranslatedInputs([
    nameWithRequired,
    description
  ], EventsProcessorsInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsValid),
    [inputsValid]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(eventProcessorData || BASE_FORM, schema)

  const formErrorsTranslteds = useTranslatedFormErrors(
    errors, EventsProcessorsInputsErrorsTranslator
  )

  return {
    errors: formErrorsTranslteds,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  }
}
