import { GhostButton, Button } from '@bonitour/components'
import { useTranslator } from 'Shared/language/i18nHandler'
import React from 'react'
import { FormTranslator } from './i18n/FormI18n'

/**
 * @typedef FormActionsProps
 * @prop { () => void } onClose
 * @prop { () => void } onSubmit
 * @prop { string } [successBtnLabel]
 * @prop { string } [cancelBtnLabel]
 * @prop { boolean } [disabled]
 */

/** @type { React.FC<FormActionsProps> } */
export const FormActions = ({
  onClose = () => undefined,
  onSubmit = () => undefined,
  successBtnLabel = '',
  cancelBtnLabel = '',
  disabled = false
}) => {
  const i18n = useTranslator(FormTranslator)

  return (
    <>
      <GhostButton
        type='reset'
        disabled={disabled}
        onClick={onClose}
        className='cancel__btn'
      >
        {cancelBtnLabel || i18n?.cancelButton}
      </GhostButton>
      <Button
        type='button'
        className='save__btn'
        onClick={onSubmit}
        disabled={disabled}
      >
        {successBtnLabel || i18n?.saveButton}
      </Button>
    </>
  )
}
