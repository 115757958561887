import styled from '@emotion/styled'

export const AllowedFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  height: 22.1875rem;
  

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0 1.25rem 0 0;
    overflow-y: auto;
  }

  .load {
    width: 100%;
    height: 100%;
  }

  .allowed_field_wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0.625rem;
    border-radius: 0.625rem;
  }
  
  .gray12 {
    background-color: var(--clr-gray12);
  }
`
