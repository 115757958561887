
export const EventModel = {
  getEvents: (filters = {}, pagination = {}) => {
    const {
      typeOfEvent
    } = filters

    const {
      currentPage,
      perPage
    } = pagination

    return {
      page: currentPage,
      per_page: perPage,
      type_of_event: typeOfEvent || undefined
    }
  },
  create (payload) {
    const {
      event_type: eventType,
      event_request: eventRequest
    } = payload ?? {}

    return {
      event_type: eventType,
      event_request: eventRequest
    }
  },
  edit (payload) {
    const {
      eventType,
      eventRequest
    } = payload ?? {}

    return {
      event_type: eventType,
      event_request: eventRequest
    }
  }
}
