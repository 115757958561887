import { CreditCardConciliationModel } from 'Core/Models/creditCardConciliations'
import { billingCore } from './Billing'
import { CreditCardConciliationParser } from 'Core/Parser/creditCardConciliation'

export const CreditCardConciliationService = {
  getCreditCardConciliations (filters, i18n) {
    return billingCore.creditCardConciliationDomain.getCreditCardConciliations(
      CreditCardConciliationModel.getCreditCardConciliations(filters)
    ).then(
      (data) => CreditCardConciliationParser.getCreditCardConciliations(
        data, i18n
      )
    )
  },
  postAutoCreditCardConciliation (params) {
    return billingCore.creditCardConciliationDomain.postAutoCreditCardConciliation(
      CreditCardConciliationModel.postAutoCreditCardConciliation(params)
    ).then(CreditCardConciliationParser.postAutoCreditCardConciliation)
  },
  postManualCreditCardConciliation (params, i18n) {
    return billingCore.creditCardConciliationDomain.postManualCreditCardConciliation(
      CreditCardConciliationModel.postManualCreditCardConciliation(params)
    ).then(
      data => CreditCardConciliationParser.postManualCreditCardConciliation(
        {
          conciliation: data,
          i18n
        }
      )
    )
  },
  deleteCreditCardConciliations (params) {
    return billingCore.creditCardConciliationDomain.deleteCreditCardConciliations(
      CreditCardConciliationModel.deleteCreditCardConciliations(params)
    )
  }
}
