/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  paymentDayOptions,
  applyRecurrencesOptions,
  ExpenseAndRevenueStatus
} from '../constants/expenseRevenue'
import {
  string as yupString,
  number as yupNumber,
  date as yupDate,
  array as yupArray,
  object as yupObject
} from 'yup'

import { toCents } from '@binamik/js-functions'
import { LabelWithInfo } from 'Shared/components/LabelWithInfo/LabelWithInfo'
import { startDateMustBeLessThanOrEqualEndDate } from 'Shared/utils/yup/date/startDateMustBeLessThanOrEqualEndDate'
import { endDateMustBeAfterOrEqualStartDate } from 'Shared/utils/yup/date/endDateMustBeAfterOrEqualStartDate'

const MAX_PRODUCT_SERVICE = 2047
const MAX_OBSERVATION = 2047

const convertBrlToNumber = (_val, originalVal) => {
  try {
    const cents = toCents(originalVal)
    return cents / 100
  } catch (error) {
    console.error(error)
    return NaN
  }
}

const date = {
  label: 'Data',
  className: 'date',
  value: 'date',
  type: 'date',
  typeWrapper: 'inputFormGroup',
  schema: yupDate()
    .typeError('Data deve ter o format dd/mm/yyyy')
    .required('Escolha a data de pagamento / recebimento')
}

const referenceDate = {
  label: 'Data de referência',
  className: 'reference_date',
  value: 'referenceDate',
  type: 'date',
  typeWrapper: 'inputFormGroup',
  schema: yupDate()
    .typeError('Data deve ter o format dd/mm/yyyy')
    .required('Escolha a data de vencimento')
}

const expenseRevenues = (label = '', expenseRevenuesOptions = []) => ({
  label,
  className: 'expense_revenue',
  value: 'expenseRevenueId',
  type: 'select',
  options: expenseRevenuesOptions,
  schema: yupString().required('Escolha um tipo de receita/despesa')
})

const expenseRevenuesNotRequired = (label = '', expenseRevenuesOptions = []) => ({
  ...expenseRevenues(label, expenseRevenuesOptions),
  placeholder: label,
  includeExtraOption: 'all',
  schema: yupString()
})

const bankAccounts = (bankAccountsOptions = []) => ({
  label: 'Conta Bancária',
  className: 'bank_account',
  value: 'bankAccountId',
  type: 'select',
  options: bankAccountsOptions,
  schema: yupString().optional().nullable()
})

const paymentMethods = {
  label: 'Forma de Pagamento',
  className: 'payment_method',
  value: 'paymentMethod',
  type: 'select',
  schema: yupString().optional().nullable()
}

const bankAccountsWithAll = (bankAccountsOptions = []) => ({
  ...bankAccounts(bankAccountsOptions),
  includeExtraOption: 'all'
})

const multiSelectPaymentMethods = {
  label: 'Forma de Pagamento',
  className: 'payment_method',
  value: 'paymentMethods',
  withFilter: false,
  type: 'multiSelect',
  hasSelectAll: true,
  schema: yupArray().of(yupString())
}

const serviceName = {
  label: 'Produto / Serviço',
  className: 'service_name',
  value: 'serviceName',
  type: 'text',
  typeWrapper: 'inputFormGroup',
  schema: yupString()
    .required('Preencha o nome do produto / serviço')
    .max(
      MAX_PRODUCT_SERVICE,
      `Produto / Serviço deve ter no máximo ${MAX_PRODUCT_SERVICE} caracteres`
    )
}

const serviceNameNotRequired = {
  ...serviceName,
  typeWrapper: 'inputFormGroup',
  schema: yupString()
}

const status = (statusOptions = []) => ({
  label: 'Status',
  className: 'status',
  value: 'status',
  type: 'select',
  options: statusOptions,
  withFilter: false,
  schema: yupString().required('Escolha o status da conta')
})

const multipleStatus = (statusOptions = []) => ({
  ...status(statusOptions),
  type: 'multiSelect',
  schema: yupArray().of(yupString())
})

const observation = {
  label: 'Observações',
  className: 'observation',
  value: 'observation',
  type: 'textarea',
  typeWrapper: 'inputFormGroup',
  schema: yupString()
    .nullable()
    .optional()
    .test(
      'observationRequired',
      'observationRequired',
      function (value) {
        const status = this.parent.status

        const isIncludedStatus = [
          ExpenseAndRevenueStatus.canceled,
          ExpenseAndRevenueStatus.refunded
        ].includes(status)

        if (!value && isIncludedStatus) {
          return this.createError({ message: 'Observação é obrigatória', path: this.path })
        }

        return true
      }
    )
    .max(
      MAX_OBSERVATION,
      `Observações deve ter no máximo ${MAX_OBSERVATION} caracteres`
    )
}

const observationWithTypeText = {
  ...observation,
  type: 'text'
}

const value = {
  label: 'Valor',
  className: 'value',
  value: 'value',
  type: 'money',
  typeWrapper: 'inputFormGroup',
  schema: yupNumber()
    .transform(convertBrlToNumber)
    .required('Preencha o valor').default(0.0)
    .typeError('Valor deve ser um número'),
  maskFn: (val = '') => val.replace(/[^0-9,.]/g, '')
}

const taxes = {
  label: 'Taxa bancária',
  className: 'taxes',
  value: 'taxes',
  type: 'money',
  typeWrapper: 'inputFormGroup',
  schema: yupNumber()
    .transform(convertBrlToNumber)
    .default(0.0).typeError('Preencha taxa em decimais'),
  maskFn: (val = '') => val.replace(/[^0-9,.]/g, '')
}

const receipts = {
  label: 'Adicione ou remova os comprovantes',
  className: 'receipts',
  value: 'receipts',
  type: 'upload',
  typeWrapper: 'inputFormGroup',
  schema: yupArray()
    .optional()
    .nullable()
}

const paymentDay = {
  label: 'Dia do mês do Pagamento',
  className: 'payment_day',
  value: 'paymentDay',
  type: 'select',
  options: paymentDayOptions,
  schema: yupNumber().required('paymentDayRequired')
}

const recurrenceType = (isEdit) => {
  return {
    label: (
      <LabelWithInfo
        label='Tipo de recorrência'
        title='Informação dos tipos de recorrências'
        info={
          [{
            optionTitle: 'Pagamento Recorrente',
            optionInfo: 'Valor dos pagamentos será o mesmo valor do agendamento'
          },
          {
            optionTitle: 'Parcelamento',
            optionInfo: 'Valor dos pagamentos será o valor do agendamento dividido pelo número de repetições.'
          }]
        }
      />
    ),
    className: 'recurrence_type',
    value: 'recurrenceType',
    type: 'select',
    withFilter: false,
    options: [
      { label: 'Parcelamento', value: 'installments' },
      { label: 'Pagamento Recorrente', value: 'recurrence' }
    ],
    schema: yupString().test(
      'recurrenceTypeRequired',
      'recurrenceTypeRequired',
      function (value) {
        const status = this.parent.status

        const isIncludedStatus = [
          ExpenseAndRevenueStatus.scheduled
        ].includes(status)

        if (!value && isIncludedStatus && !isEdit) {
          return this.createError({ message: 'recurrenceTypeRequired', path: this.path })
        }

        return true
      }
    )
  }
}

const ocurrences = {
  label: 'Número de repetições',
  className: 'ocurrences',
  value: 'ocurrences',
  type: 'text',
  typeWrapper: 'inputFormGroup',
  schema: yupNumber()
    .typeError('mustBeNumber')
    .min(2, 'mustBeGreaterThanOrEqual2')
    .max(240, 'mustBeLessThanOrEqual240'),
  maskFn: (val = '') => val.replace(/[^0-9,.]/g, '')
}

const userName = {
  label: 'Nome do Usuário',
  className: 'user_name',
  value: 'userName',
  type: 'text',
  typeWrapper: 'inputFormGroup',
  schema: yupString(),
  disabled: true
}

const createdAt = {
  label: 'Data de Registro',
  className: 'created_at',
  typeWrapper: 'inputFormGroup',
  value: 'createdAt',
  type: 'text',
  schema: yupString(),
  disabled: true
}

const expenseItems = {
  label: 'Lote de contas a pagar',
  className: 'expenseItems',
  value: 'expenseItems',
  type: 'array',
  typeWrapper: 'inputFormGroup',
  schema: yupArray().of(
    yupObject({
      value:
      yupString()
        .required('valueIsRequired')
    })
  ).required('expenseItemsIsRequired').min(1, 'leastOneExpenseItemIsRequired')
}

const applyRecurrence = (isEdit) => {
  return ({
    label: (
      <LabelWithInfo
        label='Replicar alteração'
        title='Informação das alterações'
        info={[
          {
            optionTitle: 'Replicar alterações',
            optionInfo: 'Aplica as alterações nos demais pagamentos recorrentes'
          },
          {
            optionTitle: 'Não replicar alterações',
            optionInfo: 'A edição afeta apenas o pagamento atual'
          }
        ]}
      />
    ),
    className: 'apply_recurrence',
    value: 'applyRecurrence',
    type: 'select',
    options: applyRecurrencesOptions,
    schema: yupString().test(
      'applyRecurrenceRequired',
      'applyRecurrenceRequired',
      function (value) {
        const status = this.parent.status

        const isIncludedStatus = [
          ExpenseAndRevenueStatus.scheduled
        ].includes(status)

        if (!value && isIncludedStatus && isEdit) {
          return this.createError({ message: 'applyRecurrenceRequired', path: this.path })
        }

        return true
      }
    )
  })
}

const suppliers = (suppliersOptions = []) => ({
  label: 'Fornecedor',
  className: 'supplier',
  value: 'supplierId',
  type: 'select',
  includeExtraOption: 'all',
  options: suppliersOptions,
  schema: yupString().optional().nullable()
})

const suppliersWithExtraOptionNone = (suppliersOptions = []) => ({
  ...suppliers(suppliersOptions),
  includeExtraOption: 'none'
})

const startDate = {
  label: 'Data inicial',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data inicial',
  className: 'start_date',
  value: 'startDate',
  type: 'date',
  schema: yupDate()
    .typeError('dateFormat')
    .required('dateRequired')
    .test(
      'startDateMustBeLessThanOrEqualEndDate',
      'startDateMustBeLessThanOrEqualEndDate',
      startDateMustBeLessThanOrEqualEndDate
    )
}

const endDate = {
  label: 'Data final',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data final',
  className: 'end_date',
  value: 'endDate',
  type: 'date',
  schema: yupDate()
    .typeError('dateFormat')
    .required('dateRequired')
    .test(
      'endDateMustBeAfterOrEqualStartDate',
      'endDateMustBeAfterOrEqualStartDate',
      endDateMustBeAfterOrEqualStartDate
    )
}

const receiptsRecurrency = (isEdit = false) => {
  return {
    label: 'Adicione ou remova os comprovantes',
    labelWithParams: { isEdit },
    className: 'receipts',
    value: 'receipts',
    type: 'upload',
    typeWrapper: 'inputFormGroup',
    schema: yupArray()
      .optional()
      .nullable()
  }
}

const receiptsBatchExpense = {
  label: 'Adicione os comprovantes',
  className: 'receipts',
  value: 'receipts',
  type: 'upload',
  typeWrapper: 'inputFormGroup',
  schema: yupArray()
    .optional()
    .nullable()

}

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  value,
  date,
  referenceDate,
  ocurrences,
  paymentDay,
  recurrenceType,
  receipts,
  taxes,
  observation,
  serviceName,
  userName,
  createdAt,
  applyRecurrence,
  endDate,
  startDate,
  serviceNameNotRequired,
  expenseRevenuesNotRequired,
  multipleStatus,
  status,
  bankAccounts,
  paymentMethods,
  expenseRevenues,
  suppliers,
  observationWithTypeText,
  suppliersWithExtraOptionNone,
  multiSelectPaymentMethods,
  bankAccountsWithAll,
  expenseItems,
  receiptsRecurrency,
  receiptsBatchExpense,
  getSchema
}
