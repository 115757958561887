import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const PageCreateEditExpenseFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  
  
  .form__inputs {
    width: 100%;
    display: grid;
    gap: 1.25rem;
    grid-template-columns: repeat(2, 1fr);
    background-color: var(--clr-white);
    border: 1px solid var(--clr-gray11);
    padding: 1.25rem;
    border-radius: var(--round-element-radius, 100%);
  }

  .input_container__observation,
  .input_container__receipts {
    grid-column: span 2;
  }

  .input__date, 
  .input__created_at,
  .input__reference_date {
    width: 100%;
  }

  .input__expense_revenue,
  .input__supplier {
    max-width: 36.4375rem;
  }

  .form__actions {
    display: flex;
    gap: 1.25rem;
    width: 100%;
    margin-top: 3.125rem;

    &.read_only {
      .save__btn {
        display: none;
      }

      .cancel__btn {
        border-color: var(--clr-indigo1);
        color: var(--clr-indigo1);
      }
    }
  }

  .load {
    width: 100%;
    margin-top: 3rem;
  }

  @media(max-width: ${BREAK_POINTS.tablet}) {
    .form__inputs {
      grid-template-columns: repeat(1, 1fr);
    }

    .input_container__observation,
    .input_container__receipts {
      grid-column: span 1;
    }
  }
`
