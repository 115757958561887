import { expensesStatusOptions } from 'Domains/ExpenseRevenue/constants/expensesStatusOptions'
import { useTranslator } from 'Shared/language/i18nHandler'
import { apiCodesTranslations } from 'Shared/language/utils/functions'

export interface ExpensesStatusTranslations {
  [key: string]: string,
  scheduled: string
  canceled: string
  payed: string
  refunded: string
  partial: string
}

export const useTranslatedExpensesStatus = () => {
  const expensesStatusDictionary = (dictionary): ExpensesStatusTranslations => ({
    scheduled: dictionary?.expense_statusScheduled_label,
    canceled: dictionary?.expense_statusCanceled_label,
    payed: dictionary?.expense_statusPayed_label,
    refunded: dictionary?.expense_statusRefunded_label,
    partial: dictionary?.expense_statusPartial_label,
    all: dictionary?.common_all_label
  })

  const expensesStatusI18n = useTranslator(expensesStatusDictionary)

  const expensesStatusTranslator = (status: string): string => {
    return apiCodesTranslations(
      {
        apiCode: status,
        codesMapped: expensesStatusI18n,
        isToLowerCase: false
      }
    )
  }

  const expensesStatusOptionsTranslated = expensesStatusOptions.map((value) => {
    return {
      value,
      label: value ? expensesStatusTranslator(value) : expensesStatusI18n?.all
    }
  })

  return {
    expensesStatusTranslator,
    expensesStatusOptionsTranslated
  }
}
