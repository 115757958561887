/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory, NavLink } from 'react-router-dom'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { CardButton } from 'Shared/components/TitleMenus/CardButton'
import {
  Label,
  ReportsIcon
} from '@bonitour/components'
import { useCallback } from 'react'
import { CreditCardDashboardContainer } from './CreditCardDashboard.style'
import { useTranslator } from 'Shared/language/i18nHandler'
import { CreditCardDashboardTranslator } from './i18n/CreditCardDashboardI18n'
import { CustomFeatureEnabler } from 'Shared/components/CustomFeatureEnabler/CustomFeatureEnabler'

export const CreditCardDashboard = () => {
  const { push } = useHistory()

  const i18n = useTranslator(CreditCardDashboardTranslator)

  const onReturnToDashboard = useCallback(() => {
    push('/dashboard')
  }, [push])

  return (
    <>
      <PageTitle
        title='Cartões'
        onClickReturn={onReturnToDashboard}
      />
      <CreditCardDashboardContainer>
        <NavLink to='credit-card/receivables'>
          <CardButton>
            <ReportsIcon className='icon' />
            <Label className='label'>
              {i18n?.receivables}
            </Label>
          </CardButton>
        </NavLink>

        <CustomFeatureEnabler featureKey='billing-creditcard-conciliations'>
          <NavLink to='credit-card/conciliations'>
            <CardButton>
              <ReportsIcon className='icon' />
              <Label className='label'>
                {i18n?.creditCardConciliations}
              </Label>
            </CardButton>
          </NavLink>
        </CustomFeatureEnabler>

      </CreditCardDashboardContainer>
    </>
  )
}
