import React from 'react'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { useConfigurationInputs } from '../hooks/inputs/useConfigurationInputs'
import { ConfigurationInputsContainer } from './ConfigutationInputs.styles'
import { ConfigurationParsed, ConfigurationPayload } from 'Core/Types/Configuration'
import { useExpenseRevenuesOptions } from 'Domains/ExpenseRevenue/ExpenseRevenueType/hooks/useExpenseRevenuesOptions'
import { useUpdateConfiguration } from '../hooks/useUpdateConfiguration'
import { Button } from '@bonitour/components'
import { useTranslator } from 'Shared/language/i18nHandler'
import { useConfigurationAudit } from '../hooks/useConfigurationAudit'
import { Audit } from 'Shared/components/Audit/Audit'
import { ConfigurationTranslator } from '../i18n/ConfigurationI18n'

interface ConfigurationFormProps {
  configurationData: ConfigurationParsed
  getConfiguration: (data: ConfigurationPayload) => void
}

export const ConfigurationForm = ({
  configurationData,
  getConfiguration
}: ConfigurationFormProps): React.ReactElement => {
  const { expenseRevenuesOptions } = useExpenseRevenuesOptions(true, true)
  const i18n = useTranslator(ConfigurationTranslator)

  const {
    fetchConfigurationAudit,
    datas,
    loading: auditLoading
  } = useConfigurationAudit()

  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useConfigurationInputs(
    {
      configurationData,
      expenseRevenuesOptions
    }
  )

  const {
    updateConfiguration
  } = useUpdateConfiguration(getConfiguration)

  return (
    <ConfigurationInputsContainer>
      <FormsInputs
        inputs={inputsValid}
        formValues={form}
        formErrors={errors}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      />

      <section className='wrapper__btns'>
        <Button
          type='button'
          onClick={onSubmit(updateConfiguration)}
        >
          {i18n?.saveButton}
        </Button>
        <Audit
          documentId={configurationData?.id}
          auditDatas={datas}
          auditLoading={auditLoading}
          fetchAuditDatas={fetchConfigurationAudit}
          modalTitle={i18n.modalAuditBankAccountTitle}
          i18n={i18n.auditLabels}
          auditButton={{ class: 'auditButtonClass', label: i18n.auditButton }}
        />
      </section>
    </ConfigurationInputsContainer>
  )
}
