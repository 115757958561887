import { useForm } from '@bonitour/app-functions'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { useMemo } from 'react'
import { fancyName, corporateName, enabled, getSchema } from '../Filter/__SuppliersBaseInputs'
import { SuppliersInputsTranslator } from '../i18n/SuppliersInputsI18n'

export const useInputsFilterSuppliers = (filters, enabledOptions) => {
  const inputsVals = useTranslatedInputs([
    corporateName,
    fancyName,
    enabled(enabledOptions)
  ], SuppliersInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsVals),
    [inputsVals]
  )

  const {
    form,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(filters, schema)

  return {
    form,
    onInputChange,
    onInputBlur,
    inputsValid: inputsVals,
    onSubmit
  }
}
