import { useState } from 'react'
import { RevenueService } from 'Core/Service/revenue'
import { useUser } from 'Shared/contexts/User'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { RevenuesHooksTranslator } from '../i18n/RevenuesHooksI18n'

export const useCreateRevenue = () => {
  const [loading, setLoading] = useState(false)
  const { user } = useUser()
  const { addToast } = useToast()

  const i18n = useTranslator(RevenuesHooksTranslator)

  const createRevenue = async (data) => {
    setLoading(true)
    const safeUserName = user.name || ''
    return RevenueService.create({ userName: safeUserName, ...data })
      .then(() => {
        addToast(
          {
            defaultMessage: i18n?.accountsReceivableSuccessfullyRegistered,
            typeToast: 'success'
          }
        )
        return true
      })
      .catch(errors => {
        console.error(errors?.data)
        addToast(
          {
            dictTranslation: i18n?.apiCodesMappingForRevenue,
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorRegisteringAccountsReceivable
          }
        )
        return false
      })
      .finally(() => setLoading(false))
  }
  return {
    onCreateRevenue: createRevenue,
    loading
  }
}
