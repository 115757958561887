import { useMemo } from 'react'

export const usePaginationOptions = () => {
  const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV

  const initStatePagination = useMemo(() => {
    if (!['development', 'staging'].includes(APP_ENVIRONMENT)) {
      return {
        currentPage: 1,
        perPage: 25
      }
    } else {
      return {
        currentPage: 1,
        perPage: 10
      }
    }
  }, [APP_ENVIRONMENT])

  const perPageOptions = useMemo(() => {
    if (!['development', 'staging'].includes(APP_ENVIRONMENT)) {
      return [
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
        { value: 200, label: '200' }
      ]
    }
  }, [APP_ENVIRONMENT])

  const newPerPageOptions = useMemo(() => {
    if (!['development', 'staging'].includes(APP_ENVIRONMENT)) {
      return [
        25, 50, 100,
        200
      ]
    }
  }, [APP_ENVIRONMENT])

  return {
    initStatePagination,
    perPageOptions,
    newPerPageOptions
  }
}
