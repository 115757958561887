import React, { useMemo } from 'react'
import { TableV2, TableRow, EditInfoIcon } from '@bonitour/components'
import { CircleStatus } from 'Shared/styles/global'
import { formatMoney } from '@binamik/js-functions'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { useTranslator } from 'Shared/language/i18nHandler'
import { tableV2Container } from './CashDepositsList.styles'
import { CashDepositParsed, CashDepositsCommonParams } from 'Core/Types/CashDeposit'
import { CashDepositsTranslator } from '../i18n/CashDepositsI18n'
import { cashDepositsStatusColors } from '../Constants/cashDepositsStatusColors'

interface CashDepositsListProps {
  cashDeposits: CashDepositParsed[],
  isLoading: boolean
  filters: CashDepositsCommonParams
}

export const CashDepositsList = ({
  cashDeposits,
  isLoading,
  filters
}: CashDepositsListProps) => {
  const i18n = useTranslator(CashDepositsTranslator)

  const columnsWidths = useMemo(
    () => ['6.5rem', '15rem', '15rem', '8rem', '8rem', '10rem', '4rem'], []
  )

  const emptyMessage = useMemo(
    () => {
      if (!Object.keys(filters)?.length) {
        return i18n?.selectPeriodToSearch
      }

      if (Object.keys(filters)?.length && !cashDeposits?.length) {
        return i18n?.noCashDepositsFound_label
      }
    },
    [
      cashDeposits?.length,
      filters,
      i18n?.noCashDepositsFound_label,
      i18n?.selectPeriodToSearch
    ]
  )

  return (
    <TableV2
      isLoading={isLoading}
      labels={i18n?.cashDepositsColumnsTitles}
      columnsWidths={columnsWidths}
      mobileBreakpoint={1024}
      customCss={[tableV2Container]}
      emptyMessage={emptyMessage}
    >
    {
      cashDeposits?.map((cashDeposit) => (
        <TableRow
          key={cashDeposit?.id}
        >
          <>{cashDeposit?.date.format(i18n?.dateFormat)}</>
          <SafeOverflow
            text={cashDeposit?.originBankAccountName}
          />
          <SafeOverflow
            text={cashDeposit?.destinationBankAccountName}
          />
          <>{formatMoney(cashDeposit?.value)}</>
          <CircleStatus
            statusColor={cashDepositsStatusColors[cashDeposit?.status].color}
          >
            {i18n?.cashDepositsStatusTranslator(cashDeposit?.status)}
          </CircleStatus>
          <SafeOverflow
            text={cashDeposit?.userName}
            alignment='right'
            className='safe_overflow__user_name'
          />
          <div className='wrapper__btn_actions'>
            <EditInfoIcon onClick={() => {}} />
          </div>
        </TableRow>
      ))
    }
  </TableV2>
  )
}
