import { pluralize } from 'Shared/language/utils/dictionaryUtils'
import { mappedValueSafeDate, templatePluralizeWithTemplateString } from 'Shared/language/utils/functions'

export const EventsProcessorsTranslator = (dictionary, locale) => {
  const pageTitleEventsProcessors = dictionary?.event_processor_pageTitleEventsProcessors_label

  const eventProcessorTableColumnsTitles = [
    dictionary?.event_processor_tableColumnTitleEventType_label,
    dictionary?.event_tableColumnTitleEntryCondition_label,
    dictionary?.event_processor_tableColumnTitleStatus_label,
    dictionary?.event_processor_tableColumnTitleActions_label
  ]

  const eventProcessorStatusLabels = {
    enabled: dictionary?.common_statusActive_label,
    disabled: dictionary?.common_statusInactive_label,
    '-': '-'
  }

  const afterConfirmationNoWayToUndoAction = dictionary?.common_afterConfirmationNoWayToUndoAction_label
  const areYouSureToDeleteProcessors = dictionary?.event_processor_areYouSureToDeleteProcessors_label

  const condition = (qty) => {
    return templatePluralizeWithTemplateString(
      {
        qty,
        locale,
        dictKey: dictionary?.event_processor_condition_label,
        keyReplaced: 'Number'
      }
    )
  }

  const comparionsTypes = {
    equals: dictionary?.event_processor_equal_label,
    less_than: dictionary?.event_processor_lessThan_label,
    greater_than: dictionary?.event_processor_greaterThan_label,
    in: dictionary?.event_processor_in_label,
    not_equal: dictionary?.event_processor_notEqual_label,
    not_in: dictionary?.event_processor_notIn_label
  }

  const entryConditions = (qty) => pluralize(
    dictionary?.event_processor_entryConditions_label,
    qty,
    locale
  )

  const newProcessor = dictionary?.event_processor_newProcessor_label
  const searchButton = dictionary?.common_searchButton_label
  const nameAndDescription = dictionary?.event_processor_nameAndDescription_label
  const saveAndContinue = dictionary?.event_processor_saveAndContinue_label
  const selectionAndValidationEvents = dictionary?.event_processor_selectionAndValidationEvents_label
  const automationRules = dictionary?.event_processor_automationRules_label
  const editProcessor = dictionary?.event_processor_editProcessor_label
  const finishTheFisrtSession = dictionary?.event_processor_finishTheFisrtSession_toast
  const addInProcessor = dictionary?.event_processor_addInProcessor_label
  const selectEventDetails = dictionary?.event_processor_selectEventDetails_label
  const eventWithoutDetails = dictionary?.event_processor_eventWithoutDetails_label
  const listEvents = dictionary?.event_processor_listEvents_label
  const pageTitleSelectAnEvent = dictionary?.event_processor_pageTitleSelectAnEvent_label
  const selectedEvent = dictionary?.event_processor_selectedEvent_label
  const selectEvent = dictionary?.event_processor_selectEvent_label
  const createEventEntryConditions = dictionary?.event_processor_createEventEntryConditions_label
  const selectingFieldAddConditions = dictionary?.event_processor_selectingFieldAddConditions_label
  const save = dictionary?.common_save_label

  const entryConditionsTableColumnsTitles = [
    dictionary?.event_processor_tableColumnTitleField_label,
    dictionary?.event_processor_tableColumnTitleValue_label,
    dictionary?.event_processor_tableColumnTitleAction_label
  ]

  const continueLabel = dictionary?.common_continue_label
  const cancel = dictionary?.common_cancel_label
  const uniqueConditionsMessage = dictionary?.event_processor_uniqueConditionsMessage_label
  const viewMoreDetails = dictionary?.common_viewMoreDetails_label

  const processorWithSimilarConditions = (qty) => pluralize(
    dictionary?.event_processor_processorWithSimilarConditions_label,
    qty,
    locale
  )

  const eventServicedByProcessor = (qty) => pluralize(
    dictionary?.event_processor_eventServicedByProcessor_label,
    qty,
    locale
  )

  const matchedEventsSubtitle = dictionary?.event_processor_matchedEventsSubtitle_label
  const doesNotHaveEventsServicedByProcessor = dictionary?.event_processor_doesNotHaveEventsServicedByProcessor_label
  const back = dictionary?.common_back_label
  const statusActive = dictionary?.common_statusActive_label
  const statusInactive = dictionary?.common_statusInactive_label

  const automationRulesActions = {
    MoveAccountForecastWithFormula: dictionary?.event_processor_moveAccountForecastWithFormula_label,
    MoveAccountWithFormula: dictionary?.event_processor_moveAccountWithFormula_label,
    MoveAccountForecastWithFormulaForRefund: dictionary?.event_processor_moveAccountForecastWithFormulaForRefund_label,
    UpdateAccountForecastWithFormula: dictionary?.event_processor_updateAccountForecastWithFormula_label,
    UpdateAccountMovement: dictionary?.event_processor_updateAccountMovement_label,
    AccelerateInstallmentsForRefund: dictionary?.event_processor_accelerateInstallmentsForRefund_label,
    UpdateTicketStatus: dictionary?.event_processor_updateTicketStatus_label,
    AnalyzeInvoicePartialPayment: dictionary?.event_processor_analyzeInvoicePartialPayment_label,
    CreateUpdateBankAccount: dictionary?.event_processor_createUpdateBankAccount_label,
    MoveBankAccount: dictionary?.event_processor_moveBankAccount_label,
    InitBilling: dictionary?.event_processor_initBilling_label,
    UpdateBilling: dictionary?.event_processor_updateBilling_label,
    DeleteBilling: dictionary?.event_processor_deleteBilling_label
  }

  const automationRulesOperationTypes = {
    credit: dictionary?.common_credit_label,
    debit: dictionary?.common_debit_label
  }

  const automationRulesTableColumnsTitles = {
    action: dictionary?.event_processor_tableColumnTitleAction_label,
    operationType: dictionary?.event_processor_tableColumnTitleOperationType_label,
    value: dictionary?.event_processor_tableColumnTitleValue_label,
    date: dictionary?.event_processor_tableColumnTitleDate_label,
    destinationAccount: dictionary?.event_processor_tableColumnTitleDestinationAccount_label,
    actions: dictionary?.event_processor_tableColumnTitleActions_label
  }

  const registeredAutomationRules = dictionary?.event_processor_registeredAutomationRules_label

  const dateFormat = (date) => {
    return mappedValueSafeDate(date, dictionary)
  }

  const automationRulesSimulationsTableColumnsTitles = [
    dictionary?.event_processor_tableColumnTitleAction_label,
    dictionary?.event_processor_tableColumnTitleRealeaseDate_label,
    dictionary?.event_processor_tableColumnTitleValue_label,
    dictionary?.event_processor_tableColumnTitleOperationType_label,
    dictionary?.event_processor_tableColumnTitleDestinationAccount_label,
    dictionary?.event_processor_tableColumnTitleStatus_label
  ]

  const automationRulesSimulator = dictionary?.event_processor_automationRulesSimulator_label
  const invalid = dictionary?.common_invalid_label
  const valid = dictionary?.common_valid_label
  const theAutomationRulesAre = dictionary?.event_processor_theAutomationRulesAre_label
  const matchedEventsTiteInAutomationRules = dictionary?.event_processor_matchedEventsTiteInAutomationRules_label

  const automationRuleAllowedFormulas = {
    'installment_date(event_field_date,inc,event_field_installments,event_field_brand,event_field_gateway)': dictionary?.event_processor_installmentDate_label,
    'installment_value(gateway,event_field_value,inc,event_field_integer)': dictionary?.event_processor_installmentValue_label,
    'installment_fee_value(gateway,event_field_value,inc,event_field_integer)': dictionary?.event_processor_installmentFeeValue_label,
    'get_bank_account(conta_bancaria_id)': dictionary?.event_processor_getBankAccount_label,
    'get_account_from_expense_revenue(expense_revenue_id)': dictionary?.event_processor_getAccountFromExpenseRevenue_label,
    'binamik_fee(operadora,valor_pagamento,parcelas,mdr,total_taxas_pay_mdr)': dictionary?.event_processor_binamikFee_label,
    'from_cents(value)': dictionary?.event_processor_fromCents_label,
    'refund_date (cancelation_date,brand,gateway)': dictionary?.event_processor_refundDate_label,
    'to_date(event_field)': dictionary?.event_processor_toDate_label,
    'duration(1, month)': dictionary?.event_processor_durationOneMonth_label,
    'duration(26, days)': dictionary?.event_processor_durationTwentySixDays_label,
    'next_business_days(to_date(event_field))': dictionary?.event_processor_nextBusinessDays_label,
    'next_business_days ( to_date(event_field) + duration(1, month) )': dictionary?.event_processor_nextBusinessDaysOneMoreMonth_label,
    'if (inc < event_field, event_field_2, round(event_field_2))': dictionary?.event_processor_operationIfElse_label,
    'round(event_field)': dictionary?.event_processor_roundValue_label,
    'round(event_field,2)': dictionary?.event_processor_roundValueWithTwoDecimalPlaces_label,
    'roundup(event_field)': dictionary?.event_processor_roundValueUp_label,
    'rounddown(event_field)': dictionary?.event_processor_roundValueDown_label
  }

  const allowedFormulas = dictionary?.event_processor_allowedFormulas_label
  const formula = dictionary?.event_processor_formula_label
  const operation = dictionary?.event_processor_operation_label
  const description = dictionary?.event_processor_description_label
  const newAutomationRule = dictionary?.event_processor_newAutomationRule_label
  const pageTitleEditingAutomationRule = dictionary?.event_processor_pageTitleEditingAutomationRule_label
  const pageTitleNewAutomationRule = dictionary?.event_processor_pageTitleNewAutomationRule_label
  const rules = dictionary?.event_processor_rules_label
  const fieldOfAllowedEvents = dictionary?.event_processor_fieldOfAllowedEvents_label
  const finalize = dictionary?.common_finalize_label

  return {
    pageTitleEventsProcessors,
    eventProcessorTableColumnsTitles,
    eventProcessorStatusLabels,
    afterConfirmationNoWayToUndoAction,
    areYouSureToDeleteProcessors,
    condition,
    entryConditions,
    comparionsTypes,
    newProcessor,
    searchButton,
    nameAndDescription,
    saveAndContinue,
    selectionAndValidationEvents,
    automationRules,
    editProcessor,
    finishTheFisrtSession,
    entryConditionsTableColumnsTitles,
    addInProcessor,
    selectEventDetails,
    eventWithoutDetails,
    listEvents,
    pageTitleSelectAnEvent,
    selectedEvent,
    selectEvent,
    createEventEntryConditions,
    selectingFieldAddConditions,
    save,
    continueLabel,
    cancel,
    processorWithSimilarConditions,
    uniqueConditionsMessage,
    viewMoreDetails,
    eventServicedByProcessor,
    matchedEventsSubtitle,
    doesNotHaveEventsServicedByProcessor,
    back,
    statusActive,
    statusInactive,
    automationRulesActions,
    automationRulesOperationTypes,
    automationRulesTableColumnsTitles,
    registeredAutomationRules,
    dateFormat,
    automationRulesSimulationsTableColumnsTitles,
    automationRulesSimulator,
    invalid,
    valid,
    theAutomationRulesAre,
    matchedEventsTiteInAutomationRules,
    automationRuleAllowedFormulas,
    allowedFormulas,
    formula,
    operation,
    description,
    newAutomationRule,
    pageTitleEditingAutomationRule,
    pageTitleNewAutomationRule,
    rules,
    fieldOfAllowedEvents,
    finalize
  }
}
