/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, PaginationContainer } from '@bonitour/components'
import { EventsFilters } from './Form/EventsFilters'
import { useListEvents } from './hooks/useListEvents'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsTranslator } from './i18n/EventsI18n'
import { EventsList } from './List/EventsList'
import { paginationContainerCustom } from './Events.styles'

/**
 * @typedef EventsBodyProps
 * @prop { boolean } isSelectEvent
 * @prop { (id: string) => void} handleSelectEventId
 * @prop { string } selectedEventId
 */

/** @type { React.FC<EventsBodyProps> } */
export const EventsBody = (
  {
    isSelectEvent = false,
    handleSelectEventId,
    selectedEventId
  }
) => {
  const {
    eventsDatas,
    eventsTypesDatas,
    isLoading,
    handleFilters,
    handlePagination,
    perPageOptions,
    meta,
    filters,
    pagination
  } = useListEvents()

  const { count = 0, last = 1 } = meta ?? {}

  const i18n = useTranslator(EventsTranslator)

  return (
    <>
      <Card>
        <EventsFilters
          onFilterSubmit={handleFilters}
          filters={filters}
          isLoading={isLoading}
          buttonLabel={i18n?.searchButton}
          eventsTypesOptions={eventsTypesDatas}
        />
        <PaginationContainer
          total={count}
          pagesQuantity={last}
          onPagination={handlePagination}
          pageLimitOptions={perPageOptions}
          currentPage={pagination?.currentPage}
          pageLimitDefault={pagination?.perPage}
          childrenCustomCss={[paginationContainerCustom]}
          isOpenDrawer
        >
          <EventsList
            isLoading={isLoading}
            eventsDatas={eventsDatas}
            isSelectEvent={isSelectEvent}
            handleSelectEventId={handleSelectEventId}
            selectedEventId={selectedEventId}
          />
        </PaginationContainer>
      </Card>
    </>
  )
}
