export const SuppliersInputsTranslator = (
  dictionary
) => {
  const labels = {
    basicRegistration_corporateName: {
      label: dictionary?.supplier_corporateName_inputLabel,
      placeholder: dictionary?.supplier_corporateName_placeholderLabel
    },
    basicRegistration_fancyName: {
      label: dictionary?.supplier_fancyName_inputLabel,
      placeholder: dictionary?.supplier_fancyName_placeholderLabel
    },
    enabled: {
      label: dictionary?.supplier_tableColumnTitleStatus_label,
      placeholder: dictionary?.supplier_enabled_placeholderLabel
    },
    basicRegistration_supplierType: {
      label: dictionary?.supplier_supplierType_inputLabel,
      placeholder: dictionary?.supplier_supplierType_placeholderLabel
    },
    basicRegistration_supplierDocument: {
      label: dictionary?.supplier_supplierDocument_inputLabel,
      placeholder: dictionary?.supplier_supplierDocument_placeholderLabel
    },
    basicRegistration_municipalRegistration: {
      label: dictionary?.supplier_municipalRegistration_inputLabel,
      placeholder: dictionary?.supplier_municipalRegistration_placeholderLabel
    },
    address_address: {
      label: dictionary?.supplier_address_inputLabel,
      placeholder: dictionary?.supplier_address_placeholderLabel
    },
    address_neighborhood: {
      label: dictionary?.supplier_neighborhood_inputLabel,
      placeholder: dictionary?.supplier_neighborhood_placeholderLabel
    },
    address_cityName: {
      label: dictionary?.supplier_cityName_inputLabel,
      placeholder: dictionary?.supplier_cityName_placeholderLabel
    },
    address_state: {
      label: dictionary?.supplier_state_inputLabel,
      placeholder: dictionary?.supplier_state_placeholderLabel
    },
    address_country: {
      label: dictionary?.supplier_country_inputLabel,
      placeholder: dictionary?.supplier_country_placeholderLabel
    },
    address_zipCode: {
      label: dictionary?.supplier_zipCode_inputLabel,
      placeholder: dictionary?.supplier_zipCode_placeholderLabel
    },
    contactDetails_emails: {
      label: dictionary?.supplier_emails_inputLabel,
      placeholder: dictionary?.supplier_emails_placeholderLabel
    },
    contactDetails_phoneNumbers: {
      label: dictionary?.supplier_phoneNumbers_inputLabel,
      placeholder: dictionary?.supplier_phoneNumbers_placeholderLabel
    },
    contactDetails_contactNames: {
      label: dictionary?.supplier_contactNames_inputLabel,
      placeholder: dictionary?.supplier_contactNames_placeholderLabel
    },
    contactDetails_observation: {
      label: dictionary?.supplier_observation_inputLabel,
      placeholder: dictionary?.supplier_observation_placeholderLabel
    }
  }

  return {
    inputs: labels
  }
}
