type FinacialScheduleFiltersErrorsResponse = {
  errors: {
    startDate: {
      dateFormat: string,
      dateRequired: string
    },
    endDate: {
      dateFormat: string,
      dateRequired: string
    },
  }
}

export const FinacialScheduleFiltersErrorsTranslator = (
  dictionary
): FinacialScheduleFiltersErrorsResponse => {
  const labels = {
    startDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_startDateRequired_formErrorLabel,
      startDateMustBeLessThanOrEqualEndDate: dictionary?.common_startDateMustBeLessThanOrEqualEndDate_label
    },
    endDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_endDateRequired_formErrorLabel,
      endDateMustBeAfterOrEqualStartDate: dictionary?.conciliation_endDateMustBeAfterOrEqualStartDate_formErrorLabel
    }
  }

  return {
    errors: labels
  }
}
