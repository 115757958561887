import { useState, useCallback, useRef, useEffect } from 'react'
import { SupplierService } from 'Core/Service/Supplier'
import { useToast } from 'Shared/hooks/useToast'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SuppliersHooksTranslator } from '../i18n/SuppliersHooksI18n'

export const useListSuppliers = () => {
  const [suppliersList, setSuppliersList] = useState({ suppliers: [], meta: {} })
  const [listLoading, setListLoading] = useState(false)
  const [filters, setFilters] = useState()
  const { addToast } = useToast()
  const { initStatePagination, perPageOptions } = usePaginationOptions()
  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false)
  const initPagination = useRef(false)
  const [pagination, setPagination] = useState(initStatePagination)

  const i18n = useTranslator(SuppliersHooksTranslator)

  const handlePagination = useCallback((currentPage, perPage) => {
    if (!initPagination.current) {
      initPagination.current = true
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [])

  const handleFilters = useCallback((filters) => {
    setIsUpdatingFilters(true)
    setPagination({
      currentPage: 1,
      perPage: pagination.perPage
    })

    setFilters(filters)
    setTimeout(() => setIsUpdatingFilters(false))
  }, [pagination.perPage, setFilters])

  const fetchListSupplier = useCallback(() => {
    setListLoading(true)
    return SupplierService.list({ filters, pagination }
    ).then((data) => {
      setSuppliersList(data)
    }).catch((errors) => {
      console.error(errors?.data)
      addToast({ msgs: errors?.data?.errors_msg, defaultMessage: i18n?.errorFecthingSuppliers })
    }).finally(() => {
      setListLoading(false)
    })
  }, [filters, pagination, addToast, i18n?.errorFecthingSuppliers])

  useEffect(() => {
    if (
      !pagination.currentPage ||
      !pagination.perPage
    ) return

    !isUpdatingFilters && fetchListSupplier({ filters, pagination })
  }, [fetchListSupplier, filters, isUpdatingFilters, pagination])

  return {
    suppliers: suppliersList?.suppliers,
    meta: suppliersList?.meta,
    listLoading,
    perPageOptions,
    handlePagination,
    pagination,
    fetchListSupplier,
    handleFilters,
    filters
  }
}
