import { useState } from 'react'
import { CountPlanService } from 'Core/Service/CountPlan'
import { useToast } from 'Shared/hooks/useToast'
import { AccountsPlansHooksTranslator } from '../i18n/AccountsPlansHooksI18n'
import { useTranslator } from 'Shared/language/i18nHandler'

export const useCreateCountPlan = () => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(AccountsPlansHooksTranslator)

  const createCountPlan = (countPlanData) => {
    setLoading(true)
    return CountPlanService.create(
      { ...countPlanData }
    ).then(() => {
      addToast({
        defaultMessage: i18n?.accountPlanSucessRegistered,
        typeToast: 'success'
      })
    }).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorRegisteredAccountPlan
      })
    }).finally(() => setLoading(false))
  }
  return {
    submit: createCountPlan,
    loading
  }
}
