import { useTranslatedExpensesStatus } from '../../i18n/hooks/useTranslatedExpensesStatus'
import { ExpenseAllowedStatus } from 'Core/Types/Expense'

export const useExpenseStatusOptions = () => {
  const {
    expensesStatusTranslator
  } = useTranslatedExpensesStatus()

  const expensesStatusOptions = (expenseStatus: ExpenseAllowedStatus[]) => {
    return expenseStatus.map((status) => ({
      value: status,
      label: expensesStatusTranslator(status)
    }))
  }

  return {
    expensesStatusOptions
  }
}
