import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const stylishCardSearch = css`
  border: none;
  background-color: ${colors.gray8};
  padding: 1em;

  .start_date,
  .end_date {
    width: 100%;
  }
`

export const buttonStyle = css`
  height: 40px;
  width: 100%;
  margin-top: auto;
`

export const marginTop = css`
  margin-top: 15px;
`
