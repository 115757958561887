export const SupplierBankAccountsTranslator = (
  dictionary
) => {
  const pageTitleSupplierBankAccount = dictionary?.supplier_bank_account_pageTitleSupplierBankAccounts_label
  const newSupplierBankAccountButton = dictionary?.supplier_bank_account_newSupplierBankAccountButton_label
  const modalAuditSupplierBankAccountTitle = dictionary?.supplier_bank_account_modalAuditSupplierBankAccountTitle_label
  const modalCreateSupplierBankAccountTitle = dictionary?.supplier_bank_account_modalCreateSupplierBankAccountTitle_label
  const modalEditSupplierBankAccountTitle = dictionary?.supplier_bank_account_modalEditSupplierBankAccountTitle_label
  const removeDialogTitle = dictionary?.supplier_bank_account_removeDialogTitle_label
  const removeDialogMessage = dictionary?.supplier_bank_account_removeDialogMessage_label

  const supplierBankAccountTableColumnsTitles = [
    dictionary?.supplier_bank_account_tableColumnTitleBankName_label,
    dictionary?.supplier_bank_account_tableColumnTitleBranch_label,
    dictionary?.supplier_bank_account_tableColumnTitleAccountNumber_label,
    dictionary?.supplier_bank_account_tableColumnTitleStatus_label,
    dictionary?.supplier_bank_account_tableColumnTitleAction_label
  ]

  const supplierBankAccountTableColumnsTitlesModalView = [
    dictionary?.supplier_bank_account_tableColumnTitleBankName_label,
    dictionary?.supplier_bank_account_tableColumnTitleBranch_label,
    dictionary?.supplier_bank_account_tableColumnTitleAccountNumber_label,
    dictionary?.supplier_bank_account_tableColumnTitleStatus_label
  ]

  const supplierBankAccountStatusCodesMapped = {
    enabled: { label: dictionary?.supplier_bank_account_statusValueEnabled_label },
    disabled: { label: dictionary?.supplier_bank_account_statusValueDisabled_label }
  }

  const supplierBankAccountTypeOptions = [
    { value: 'checking_account', label: dictionary?.supplier_bank_account_accountTypeValueCheckingAccount_label },
    { value: 'savings_account', label: dictionary?.supplier_bank_account_accountTypeValueSavingsAccount_label }
  ]

  const supplierBankAccountEnabledOptions = [
    { value: 'enabled', label: dictionary?.supplier_bank_account_statusValueEnabled_label },
    { value: 'disabled', label: dictionary?.supplier_bank_account_statusValueDisabled_label }
  ]

  const auditLabels = {
    branch: dictionary?.audit_branch_label,
    account_type: dictionary?.audit_accountType_label,
    account_number: dictionary?.audit_accountNumber_label
  }

  return {
    pageTitleSupplierBankAccount,
    newSupplierBankAccountButton,
    modalAuditSupplierBankAccountTitle,
    supplierBankAccountTableColumnsTitles,
    supplierBankAccountStatusCodesMapped,
    supplierBankAccountTableColumnsTitlesModalView,
    supplierBankAccountTypeOptions,
    supplierBankAccountEnabledOptions,
    modalCreateSupplierBankAccountTitle,
    modalEditSupplierBankAccountTitle,
    removeDialogTitle,
    removeDialogMessage,
    auditLabels
  }
}
