import React from 'react'
import {
  UploadReceiptsContainer as Container,
  UploadContent,
  customUploadDropzone
} from './UploadReceipts.styles'
import { Upload, useToast } from '@bonitour/components'
import { identity } from 'Shared/utils/functions'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesTranslator } from '../Expenses/i18n/ExpensesI18n'

/**
 * @typedef UploadReceiptsProps
 * @prop { {src: string; name: string; id: string;}[] } receipts
 * @prop { (value: string) => (val: unknown) => void } onChange
 */

/** @type { React.FC<UploadReceiptsProps> } */
export const UploadReceipts = ({
  receipts = [],
  onChange = identity
}) => {
  const { add: addToast } = useToast()

  const i18n = useTranslator(ExpensesTranslator)

  return (
    <Container>
      <UploadContent>
        <Upload
          files={receipts || []}
          onChange={onChange}
          onError={(e) => addToast(e)}
          accept='image/png, image/jpeg, image/bmp, application/pdf'
          limitMbSize={1}
          emptyMessage={i18n?.dragTheImageOrClickToAddMaximum1Mb}
          multiple={true}
          css={customUploadDropzone}
        />
      </UploadContent>
    </Container>
  )
}
