import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const SupplierFormContainer = styled.div`
  .form__inputs {
    width: 100%;
    gap: 1.25rem;
    margin-bottom: 1.875rem;
    display: grid;
    grid-template-columns: repeat(8, 1fr);

    .input__supplier_type {
      grid-column: span 4;
    }

    .input_container__corporate_name {
      grid-column: span 4;
    }

    .input_container__fancy_name {
      grid-column: span 3;
    }

    .input_container__emails {
      grid-column: span 3;
    }

    .input_container__phone_numbers {
      grid-column: span 3;
    }

    .input_container__contact_names {
      grid-column: span 2;
    }

    .input_container__observation {
      grid-column: span 8;
    }

    .input_container__address {
      grid-column: span 5;
    }
  }

  @media(max-width: ${BREAK_POINTS.smallTablet}) {
    .form__inputs {
      flex-direction: column;
    }
  }
`

export const SupplierFormButtons = styled.div`
  display: flex;
  font-weight: 700;
  margin-bottom: 1.875rem;
  gap: 1.25rem;

  button {
    align-items: center;
    justify-content: center;
    max-width: 10rem;
    width: 100%;
  }
`
