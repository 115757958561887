import { unformatMoney } from '@bonitour/common-functions'
import { metaModel } from './Meta'
import { SafeDate } from '@binamik/js-providers'
import { ExpenseRevenueModel } from './expenseRevenue'
import {
  ExpensesCommonParams,
  GetExpensesParams,
  PayloadCreateExpense,
  PayloadUpdateExpense,
  ExpenseParsed,
  SimulateRecurrenceParams,
  ExportExpensesParams,
  UpdateBatchExpenseReqBody
} from 'Core/Types/Expense'
import {
  User
} from 'Core/Types/User'

export const ExpenseSearchModel = (filterParams: ExpensesCommonParams) => {
  const {
    startDate,
    endDate,
    status,
    serviceName,
    expenseRevenueId,
    supplierId,
    paymentMethods,
    bankAccountId
  } = filterParams ?? {}

  const res = {
    start_date: startDate && new SafeDate(startDate).isoDate,
    end_date: endDate && new SafeDate(endDate).isoDate,
    status,
    service_name: serviceName,
    expense_revenue_id: expenseRevenueId,
    supplier_id: supplierId,
    payment_methods: paymentMethods,
    bank_account_id: bankAccountId
  }

  if (ExpenseRevenueModel.checkStatusSend(status, true)) {
    delete res.status
  }

  return res
}

export const ExpenseModel = {
  list: ({ filters, pagination }: GetExpensesParams) => {
    return {
      searchParams: ExpenseSearchModel(filters),
      pagination: metaModel(pagination)
    }
  },
  create (payload: PayloadCreateExpense, user: User) {
    const {
      date,
      referenceDate,
      expenseRevenueId,
      bankAccountId,
      paymentMethod,
      serviceName,
      value,
      observation,
      status,
      taxes,
      recurrence,
      paymentDay,
      recurrenceType,
      ocurrences,
      receipts,
      supplierId
    } = payload ?? {}

    return ExpenseRevenueModel.cleanPayloadAccordingStatus({
      date: date && new SafeDate(date).isoDate,
      reference_date: referenceDate && new SafeDate(referenceDate).isoDate,
      expense_revenue_id: expenseRevenueId,
      bank_account_id: bankAccountId,
      payment_method: paymentMethod,
      service_name: serviceName,
      value: unformatMoney(value),
      observation,
      status,
      taxes: unformatMoney(taxes),
      receipts: ExpenseModel.safeReceipts(receipts),
      user_name: ExpenseModel.safeUser(user),
      recurrence,
      payment_day: paymentDay,
      recurrence_type: recurrenceType,
      ocurrences,
      supplier_id: supplierId
    })
  },
  update (expense: PayloadUpdateExpense, currentExpenseData: ExpenseParsed, user: User) {
    const {
      id,
      date,
      referenceDate,
      expenseRevenueId,
      value,
      paymentMethod,
      taxes,
      status,
      observation,
      serviceName,
      bankAccountId,
      receipts = [],
      applyRecurrence,
      paymentDay,
      recurrenceId,
      supplierId
    } = expense ?? {}

    const {
      receipts: currentReceipts
    } = currentExpenseData ?? {}

    return ExpenseRevenueModel.cleanPayloadAccordingStatus({
      id,
      date: date && new SafeDate(date).isoDate,
      reference_date: referenceDate && new SafeDate(referenceDate).isoDate,
      expense_revenue_id: expenseRevenueId,
      value: unformatMoney(value),
      payment_method: paymentMethod,
      taxes: unformatMoney(taxes),
      status,
      observation,
      service_name: serviceName,
      bank_account_id: bankAccountId,
      receipts: ExpenseModel.safeReceipts(receipts, currentReceipts),
      apply_recurrence: applyRecurrence && JSON.parse(applyRecurrence.toLowerCase()),
      payment_day: paymentDay,
      recurrence_id: recurrenceId,
      user_name: ExpenseModel.safeUser(user),
      supplier_id: supplierId
    })
  },
  getSimulateRecurrenceExpense (params: SimulateRecurrenceParams) {
    const {
      id,
      recurrenceId,
      value,
      date,
      recurrenceType,
      paymentDay,
      ocurrences
    } = params ?? {}

    return {
      expense_id: id,
      recurrence_id: recurrenceId,
      value: unformatMoney(value),
      date: date && new SafeDate(date).isoDate,
      recurrence_type: recurrenceType,
      payment_day: paymentDay,
      ocurrences
    }
  },
  safeUser (user: User) {
    const safeUserName = user?.name || ''
    return safeUserName
  },
  safeReceipts (receipts = [], currentReceipts = []) {
    if (!receipts.length) return []
    const currentReceiptIds = currentReceipts.map(({ id }) => id)
    const safeReceipts = receipts.filter(({ id }) => !currentReceiptIds.includes(id)).map(({ file }) => file)
    return safeReceipts
  },
  deleteReceipts (receiptsIds: string[]) {
    return {
      attachment_ids: receiptsIds
    }
  },
  export: ({ filters }: ExportExpensesParams) => {
    return {
      searchParams: ExpenseSearchModel(filters),
      pagination: {}
    }
  },
  updateExpenses: (updateBatchExpenseReqBody: UpdateBatchExpenseReqBody) => {
    const {
      date,
      paymentMethod,
      bankAccountId,
      status,
      receipts,
      expenseItems
    } = updateBatchExpenseReqBody ?? {}

    return {
      date: date && new SafeDate(date).isoDate,
      payment_method: paymentMethod,
      bank_account_id: bankAccountId,
      status,
      receipts: ExpenseModel.safeReceipts(receipts),
      expense_items: expenseItems
    }
  }
}
