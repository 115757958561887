import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'
import { ConditionService } from 'Core/Service/condition'

export const useRemoveCondition = () => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const removeCondition = useCallback((eventProcessorId, conditionId) => {
    setLoading(true)
    return ConditionService.removeCondition(eventProcessorId, conditionId)
      .then(() => {
        addToast({
          typeToast: 'success',
          defaultMessage: i18n?.conditionRemovedSuccess
        })
      })
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorRemovingCondition
        })
        throw errors
      })
      .finally(() => setLoading(false))
  }, [
    addToast,
    i18n?.conditionRemovedSuccess,
    i18n?.errorRemovingCondition
  ])

  return {
    removeCondition,
    loading
  }
}
