export const acquirersOptions = [
  {
    value: 'braspag',
    label: 'Braspag'
  },
  {
    value: 'cielo',
    label: 'Cielo'
  },
  {
    value: 'pagar_me',
    label: 'Pagar.me'
  },
  {
    value: 'mercado_pago',
    label: 'Mercado Pago'
  }
]
