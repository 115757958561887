import { SupplierBankAccountParser } from 'Core/Parser/supplierBankAccount'
import { billingCore } from './Billing'
import { SupplierBankAccountsModel } from 'Core/Models/supplierBankAccounts'

export const SupplierBankAccountService = {
  list (supplierId) {
    return billingCore.supplierBankAccountDomain.list(supplierId).then(SupplierBankAccountParser.list)
  },
  create ({ supplierId, params }) {
    return billingCore.supplierBankAccountDomain.create(SupplierBankAccountsModel.payload(params), supplierId)
  },
  get ({ supplierId, supplierBankAccountId }) {
    return billingCore.supplierBankAccountDomain.get(supplierId, supplierBankAccountId).then(SupplierBankAccountParser.single)
  },
  update (supplierId, params, supplierBankAccountId) {
    return billingCore.supplierBankAccountDomain.update(SupplierBankAccountsModel.payload(params), supplierId, supplierBankAccountId)
  },
  remove (supplierId, supplierBankAccountId) {
    return billingCore.supplierBankAccountDomain.remove(supplierId, supplierBankAccountId)
  },
  getSupplierBankAccountAudit (supplierBankAccountId, supplierId) {
    return billingCore.supplierBankAccountDomain.getSupplierBankAccountAudit({}, supplierBankAccountId, supplierId).then(SupplierBankAccountParser.supplierBankAccountAudit)
  }
}
