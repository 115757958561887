import { createNumericOptions } from 'Shared/utils/createNumericOptions'

export const revenueStatusOptions = [
  {
    value: 'scheduled',
    label: 'Agendado'
  },
  {
    value: 'canceled',
    label: 'Cancelado'
  },
  {
    value: 'received',
    label: 'Recebido'
  },
  {
    value: 'refunded',
    label: 'Estornado'
  }
]

export const revenuesListColumnsTitles = [
  'Data de Recebimento',
  'Tipo de Receita',
  'Produto / Serviço',
  'Valor',
  'Status',
  'Usuário',
  'Ações'
]

export const statusOptions = [
  {
    value: 'scheduled',
    label: 'Agendado'
  },
  {
    value: 'canceled',
    label: 'Cancelado'
  },
  {
    value: 'received',
    label: 'Recebido'
  },
  {
    value: 'refunded',
    label: 'Estornado'
  },
  {
    value: 'payed',
    label: 'Pago'
  }
]

export const recurrenceTypeOptions = [
  { value: 'installments', label: 'Parcelamento' },
  { value: 'recurrence', label: 'Pagamento Recorrente' }
]

export const applyRecurrencesOptions = [
  { label: 'Replicar alterações', value: 'true' },
  { label: 'Não replicar alterações', value: 'false' }
]

export const paymentDayOptions = createNumericOptions(31)

// TODO remove "label" from dict when translating accounts receivable
export const statusLabelColor = {
  scheduled: { label: 'Agendado', color: '--clr-orange1' },
  canceled: { label: 'Cancelado', color: '--clr-red1' },
  payed: { label: 'Pago', color: '--clr-green1' },
  received: { label: 'Recebido', color: '--clr-green1' },
  refunded: { label: 'Estornado', color: '--clr-red1' },
  enabled: { label: 'Ativo', color: '--clr-green1' },
  disabled: { label: 'Inativo', color: '--clr-gray7' }
}

export const paymentMethodsLabels = {
  pix: 'PIX',
  bank_billet: 'Boleto',
  bank_transfer: 'Transferência Bancária',
  credit: 'Cartão de Crédito',
  debit: 'Cartão de Débito',
  cash: 'Dinheiro'
}

export const expenseInitialStatusOptions = [
  {
    value: 'scheduled',
    label: 'Agendado'
  },
  {
    value: 'payed',
    label: 'Pago'
  }
]

export const revenueInitialStatusOptions = [
  {
    value: 'scheduled',
    label: 'Agendado'
  },
  {
    value: 'received',
    label: 'Recebido'
  }
]

export const expRecurrencyInitStatusOptions = [
  {
    value: 'scheduled',
    label: 'Agendado'
  }
]

export const ExpenseAndRevenueStatus = {
  scheduled: 'scheduled',
  refunded: 'refunded',
  canceled: 'canceled',
  received: 'received',
  payed: 'payed'
}
