import {
  string as yupString
} from 'yup'

const typeOfEvent = (eventsTypesOptions) => {
  return {
    label: 'Tipo do Evento',
    placeholder: 'Selecione o tipo do evento',
    className: 'type_of_event',
    value: 'typeOfEvent',
    options: eventsTypesOptions,
    type: 'select',
    schema: yupString()
  }
}

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  typeOfEvent,
  getSchema
}
