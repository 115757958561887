import {
  apiCodesTranslations,
  mappedValueBool,
  mappedValueMoney,
  mappedValueSafeDate
} from 'Shared/language/utils/functions'
import { eventsTypes } from '../Constants/Events'
import { useTranslatedPaymentMethods } from 'Shared/language/hooks/useTranslatedPaymentMethods'

export const EventsTranslator = (dictionary, _locale) => {
  const searchButton = dictionary?.common_searchButton_label
  const pageTitleEvents = dictionary?.event_pageTitleEvents_label

  const eventTableColumnsTitles = [
    dictionary?.event_tableColumnTitleEventType_label,
    dictionary?.event_tableColumnTitleEventData_label
  ]

  const emptyMessage = dictionary?.common_noInformation_label

  const { paymentMethodMapped } = useTranslatedPaymentMethods({ dictionary })

  const eventCodesMapped = {
    aut: dictionary?.common_aut_label,
    doc: dictionary?.common_doc_label,
    info: dictionary?.event_info_label,
    client_name: dictionary?.event_clientName_label,
    partner_name: dictionary?.event_partnerName_label,
    reservation_code: dictionary?.event_reservationCode_label,
    activity_name: dictionary?.event_activityName_label,
    ticket_code: dictionary?.event_ticketCode_label,
    card_number: dictionary?.event_cardNumber_label,
    operator_name: dictionary?.event_operatorName_label,
    user_name: dictionary?.event_userName_label,
    iss_agencia: {
      label: dictionary?.event_valueOfTaxIss_label,
      mappedValue: mappedValueMoney
    },
    valor_atrativo: {
      label: dictionary?.event_attractiveValue_label,
      mappedValue: mappedValueMoney
    },
    valor_adiantado: {
      label: dictionary?.event_advanceAmount_label,
      mappedValue: mappedValueMoney
    },
    valor_agencia: {
      label: dictionary?.event_agencyValue_label,
      mappedValue: mappedValueMoney
    },
    taxa_agencia: {
      label: dictionary?.event_agencyFee_label,
      mappedValue: mappedValueMoney
    },
    taxa_operadora: {
      label: dictionary?.event_operatorFee_label,
      mappedValue: mappedValueMoney
    },
    taxa_antifraude: {
      label: dictionary?.event_antifraudFee_label,
      mappedValue: mappedValueMoney
    },
    taxa_mdr: {
      label: dictionary?.event_mdrFee_label,
      mappedValue: mappedValueMoney
    },
    taxa_pay: {
      label: dictionary?.event_payFee_label,
      mappedValue: mappedValueMoney
    },
    data_cancelamento: {
      label: dictionary?.event_cancelattionDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data_credito: {
      label: dictionary?.event_creditDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data_debito: {
      label: dictionary?.event_debitDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data_devolucao: {
      label: dictionary?.event_dateOfReturn_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    valor_cancelado: {
      label: dictionary?.event_amountCanceled_label,
      mappedValue: mappedValueMoney
    },
    valor_devolvido: {
      label: dictionary?.event_amountReturned_label,
      mappedValue: mappedValueMoney
    },
    valor_depositado: {
      label: dictionary?.event_amountDeposited_label,
      mappedValue: mappedValueMoney
    },
    taxa_cancelamento_agencia: {
      label: dictionary?.event_agencyCancellationFee_label,
      mappedValue: mappedValueMoney
    },
    taxa_cancelamento_parceiros: {
      label: dictionary?.event_partnerCancellationFee_label,
      mappedValue: mappedValueMoney
    },
    taxa_cartao_cancelada: {
      label: dictionary?.event_canceledCardFee_label,
      mappedValue: mappedValueMoney
    },
    taxa_cobrada: {
      label: dictionary?.event_feeCharged_label,
      mappedValue: mappedValueMoney
    },
    taxa_cancelamento_atrativo: {
      label: dictionary?.event_attractiveCancellationFee_label,
      mappedValue: mappedValueMoney
    },
    taxa_atrativo: {
      label: dictionary?.event_attractiveFee_label,
      mappedValue: mappedValueMoney
    },
    taxa_orb: {
      label: dictionary?.event_orbFee_label,
      mappedValue: mappedValueMoney
    },
    mensalidade: {
      label: dictionary?.event_tuitionAmount_label,
      mappedValue: mappedValueMoney
    },
    ticket_split: {
      label: dictionary?.event_ticketBySplit_label,
      mappedValue: (value) => mappedValueBool(value, dictionary)
    },
    pagamento_atrativo: {
      label: dictionary?.event_paymentInTheAttractive_label,
      mappedValue: (value) => mappedValueBool(value, dictionary)
    },
    data_emissao_fatura: {
      label: dictionary?.event_invoiceIssuanceDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data_emissao: {
      label: dictionary?.event_emissionDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    imposto_recolher: {
      label: dictionary?.event_amountOfTaxToBeCollected_label,
      mappedValue: mappedValueMoney
    },
    imposto_fatura: {
      label: dictionary?.event_invoiceTaxAmount_label,
      mappedValue: mappedValueMoney
    },
    valor_antecipado: {
      label: dictionary?.event_advanceValue_label,
      mappedValue: mappedValueMoney
    },
    ticket_origin_type: dictionary?.event_ticketOriginType_label,
    tickets: dictionary?.event_tickets_label,
    valor_ingresso: {
      label: dictionary?.event_ticketPrice_label,
      mappedValue: mappedValueMoney
    },
    data_cancelamento_fatura: {
      label: dictionary?.event_invoiceCancellationDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data_estorno: {
      label: dictionary?.event_refundDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data_transferencia: {
      label: dictionary?.event_transferDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data_vencimento: {
      label: dictionary?.event_dueDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data_vencimento_fatura: {
      label: dictionary?.event_invoiceDueDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    valor_fatura: {
      label: dictionary?.event_invoiceAmount_label,
      mappedValue: mappedValueMoney
    },
    valor_transferido: {
      label: dictionary?.event_transferAmount_label,
      mappedValue: mappedValueMoney
    },
    valor_boleto: {
      label: dictionary?.event_billetValue_label,
      mappedValue: mappedValueMoney
    },
    descricao: dictionary?.event_description_label,
    valor_pago: {
      label: dictionary?.event_amountPaid_label,
      mappedValue: mappedValueMoney
    },
    data_adiantamento: {
      label: dictionary?.event_advanceDate_label,
      mapppedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data_deposito: {
      label: dictionary?.event_depositDate_label,
      mapppedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    plano_contas_cc: dictionary?.event_CCChartOfAccounts_label,
    plano_contas_cc_origem: dictionary?.event_CCOriginChartOfAccounts_label,
    plano_contas_cc_destino: dictionary?.event_CCDestinationChartOfAccounts_label,
    pos: dictionary?.event_machineNumber_label,
    valor_estornado: {
      label: dictionary?.event_amountRefund_label,
      mappedValue: mappedValueMoney
    },
    taxa_boleto: {
      label: dictionary?.event_billetFee_label,
      mappedValue: mappedValueMoney
    },
    data_emissao_nota: {
      label: dictionary?.event_noteIssuanceDate_label,
      mapppedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data: {
      label: dictionary?.event_date_label,
      mapppedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    lucro: {
      label: dictionary?.event_profit_label,
      mappedValue: mappedValueMoney
    },
    data_recebimento: {
      label: dictionary?.event_dateReceivement_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data_retirada: {
      label: dictionary?.event_withdrawalDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    data_referencia: {
      label: dictionary?.event_referenceDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    valor_receber: {
      label: dictionary?.event_amountReceivable_label,
      mappedValue: mappedValueMoney
    },
    valor: {
      label: dictionary?.event_value_label,
      mappedValue: mappedValueMoney
    },
    valor_retirado: {
      label: dictionary?.event_withdrawalValue_label,
      mappedValue: mappedValueMoney
    },
    valor_recebido: {
      label: dictionary?.event_amountReceived_label,
      mappedValue: mappedValueMoney
    },
    valor_pagar: {
      label: dictionary?.event_amountPayable_label,
      mappedValue: mappedValueMoney
    },
    taxa_bancaria: {
      label: dictionary?.event_bankFee_label,
      mappedValue: mappedValueMoney
    },
    bank_number: dictionary?.event_bankNumber_label,
    bank_name: dictionary?.event_bankName_label,
    branch_number: dictionary?.event_agencyNumber_label,
    account_number: dictionary?.event_accountNumber_label,
    account_type: dictionary?.event_accountType_label,
    origin_system: dictionary?.event_originSystem_label,
    enabled: {
      label: dictionary?.event_enabled_label,
      mappedValue: (value) => mappedValueBool(value, dictionary)
    },
    payment_methods: dictionary?.event_paymentMethods_label,
    operation_type: {
      label: dictionary?.event_operationType_label,
      mappedValue: (value) => value
    },
    authorization_code: dictionary?.common_aut_label,
    proof_of_sale: dictionary?.common_doc_label,
    brand: dictionary?.common_brand_label,
    installment: dictionary?.event_installmentsBeignPaid_label,
    installments: dictionary?.event_numberOfInstallmentsOfTheSale_label,
    sale_date: {
      label: dictionary?.event_saleDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    gross_value: {
      label: dictionary?.event_grossValue_label,
      mappedValue: mappedValueMoney
    },
    net_value: {
      label: dictionary?.event_netValue_label,
      mappedValue: mappedValueMoney
    },
    total_fee: {
      label: dictionary?.event_totalAmountOfFees_label,
      mappedValue: mappedValueMoney
    },
    taxes: dictionary?.event_breakdownOfFees_label,
    pay_fee: {
      label: dictionary?.event_payFee_label,
      mappedValue: mappedValueMoney
    },
    xpert_fee: {
      label: dictionary.event_xpertFee_label,
      mappedValue: mappedValueMoney
    },
    mdr_fee: {
      label: dictionary?.event_mdrFee_label,
      mappedValue: mappedValueMoney
    },
    gateway_fee: {
      label: dictionary?.event_transactionFee_label,
      mappedValue: mappedValueMoney
    },
    antifraud_fee: {
      label: dictionary?.event_antifraudFee_label,
      mappedValue: mappedValueMoney
    },
    parcelas_venda: dictionary?.event_installments_label,
    taxa: {
      label: dictionary?.event_fee_label,
      mappedValue: mappedValueMoney
    },
    tipo: dictionary?.event_type_label,
    bandeira: dictionary?.event_brand_label,
    parcelas: dictionary?.event_installments_label,
    operadora: dictionary?.event_operator_label,
    multiponto: {
      label: dictionary?.event_multipoint_label,
      mappedValue: (value) => mappedValueBool(value, dictionary)
    },
    origin_type: {
      label: dictionary?.event_originType_label,
      mappedValue: (value) => {
        const objTranslations = {
          'Financial::ReservationPayment': dictionary?.common_originTypeReservationPayment_label,
          'Financial::ReservationRefund': dictionary?.common_originTypeReservationRefund_label,
          'TicketSeller::Ticket': dictionary?.common_originTypeTicket_label,
          'Financial::Invoice': dictionary?.common_originTypeInvoice_label,
          'Financial::PartnershipAccountTransaction': dictionary?.common_originTypePartnershipAccountTransaction_label,
          'Financial::CashierHandling': dictionary?.common_originTypeCashierHandling_label,
          'Financial::PaymentBatch': dictionary?.common_originTypePaymentBatch_label
        }

        return apiCodesTranslations(
          { apiCode: value, codesMapped: objTranslations, isToLowerCase: false }
        )
      }
    },
    data_pagamento: {
      label: dictionary?.event_datePayment_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    forma_pagamento: {
      label: dictionary?.event_paymentMethod_label,
      mappedValue: paymentMethodMapped
    },
    valor_pagamento: {
      label: dictionary?.event_paymentValue_label,
      mappedValue: mappedValueMoney
    },
    cashier_reopened: {
      label: dictionary?.event_cashierReopened_label,
      mappedValue: (value) => mappedValueBool(value, dictionary)
    },
    valor_guia: {
      label: dictionary?.event_guideValue_label,
      mappedValue: mappedValueMoney
    },
    data_passeio: {
      label: dictionary?.event_tourDate_label,
      mappedValue: (value) => mappedValueSafeDate(value, dictionary)
    },
    description: dictionary?.event_description_label,
    ...eventsTypes(dictionary)
  }

  return {
    searchButton,
    pageTitleEvents,
    eventTableColumnsTitles,
    eventCodesMapped,
    emptyMessage
  }
}
