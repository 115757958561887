import { apiCodesTranslations } from 'Shared/language/utils/functions'
import { bankTransfersTransferMethodsOptions } from '../../Constants/bankTransfersTransferMethodsOptions'

interface BankTransfersTransferMethodsTranslations {
  [key: string]: string,
  bank_transfer: string
  pix: string
}

export const useTranslatedBankTransfersTransferMethods = ({ dictionary }) => {
  const bankTransfersTransferMethodsTranslations: BankTransfersTransferMethodsTranslations = {
    bank_transfer: dictionary?.common_bankTransfer_label,
    pix: dictionary?.common_pix_label
  }

  const bankTransfersTransferMethodsTranslator = (transferMethod: string): string => {
    return apiCodesTranslations(
      {
        apiCode: transferMethod,
        codesMapped: bankTransfersTransferMethodsTranslations,
        isToLowerCase: false
      }
    )
  }

  const bankTransfersTransferMethodsOptionsTranslated = bankTransfersTransferMethodsOptions.map((value) => {
    return {
      value,
      label: bankTransfersTransferMethodsTranslator(value)
    }
  })

  return {
    bankTransfersTransferMethodsTranslator,
    bankTransfersTransferMethodsOptionsTranslated
  }
}
