/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FormsInputs } from '../../../Shared/components/Form/__FormInputs'
import { useEventsFiltersInputs } from '../hooks/inputs/useEventsFiltersInputs'
import { EventsFiltersContainer } from './EventsFilters.styles'
import { Button } from '@bonitour/components'

/**
 * @typedef EventsFilterProps
 * @prop { (filters: Record<string, string>) => void } onFilterSubmit
 * @prop { Record<string, string> } filters
 * @prop { string } buttonLabel
 * @prop { boolean } isLoading
 * @prop { Record<string, string>[] } eventsTypesOptions
 */

/** @type { React.FC<EventsFilterProps> } */
export const EventsFilters = (
  {
    onFilterSubmit,
    filters,
    isLoading,
    buttonLabel,
    eventsTypesOptions
  }
) => {
  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useEventsFiltersInputs(
    filters,
    eventsTypesOptions
  )

  return (
    <EventsFiltersContainer>
      <FormsInputs
        inputs={inputsValid}
        formErrors={errors}
        formValues={form}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      >
        <Button
          type='button'
          disabled={!!isLoading}
          onClick={onSubmit(onFilterSubmit)}
          className='events_filters__btn'
        >
          {buttonLabel}
        </Button>
      </FormsInputs>
    </EventsFiltersContainer>
  )
}
