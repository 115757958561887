export const ConditionModel = {
  conditionPayload (payload) {
    const {
      key,
      field,
      value,
      comparisonType
    } = payload ?? {}

    return {
      field: key || field,
      value: value !== undefined ? String(value) : undefined,
      comparison_type: comparisonType || 'equals',
      conditionable_type: 'EventsProcessor'
    }
  }
}
