import { metaModel } from './Meta'

export const CountPlanModel = {
  list: ({ filters, pagination }) => {
    return {
      searchParams: CountPlanSearchModel(filters),
      pagination: metaModel(pagination)
    }
  },
  create (payload) {
    const {
      code,
      name,
      planType,
      enabled,
      classification
    } = payload ?? {}

    return {
      code: parseInt(code),
      name,
      plan_type: planType,
      enabled,
      classification
    }
  }
}

export const CountPlanSearchModel = (filterParams = {}) => {
  const {
    name = '',
    classification = '',
    level = '',
    enabled = '',
    planType = ''
  } = filterParams

  return {
    name,
    classification,
    level,
    enabled,
    plan_type: planType
  }
}
