import styled from '@emotion/styled'

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 3.75rem;
  justify-content: flex-end;
  padding: 0 0.625rem;
  width: 100%;
  overflow: hidden;

  i {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--clr-gray3);
  }

  .expand__btn {
    padding: 0.625rem;
    display: flex;
    width: 2.5rem;
    transition: all 300ms;
  }

  .expand__btn small {
    display: flex;
    align-items: center;
    height: calc(100% + 1.25rem);
    margin-top: -0.625rem;
    pointer-events: none;
    opacity: 0;
    transition: opacity 150ms ease-in-out, margin-left 150ms ease-in-out;
  }

  .expand__btn:not(:disabled):hover {
    transform: translateX(calc(var(--text-length) * -0.4375rem));
  }

  .expand__btn:not(:disabled):hover small {
    pointer-events: all;
    margin-left: 5px;
    opacity: 1;
  }
`
