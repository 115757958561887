import { formatMoney } from '@binamik/js-functions'
import { unformatMoney } from '@bonitour/common-functions'

type Movements = {
  id: string,
  values: {
    data: string,
    type: string,
    options: {
      classNameMoney: string
    }
  }[]
}

export const calculateTotalMovements = (movements: Movements[] = [], movementsIds: string[]) => {
  const movementsFilteredByIds = movements?.filter(
    ({ id }) => movementsIds?.includes(id)
  )

  const movementsValues = movementsFilteredByIds.map(
    movement => movement?.values
  ).flat()

  const moneyValues = movementsValues.filter(
    movementValues => movementValues?.type === 'money'
  )

  const sumTotalValues = moneyValues.reduce((acc, moneyValues) => {
    return acc + Number(moneyValues?.data)
  }, 0)

  return formatMoney(sumTotalValues)
}

export const calculateFinancialMovements = (totalMoneyExternalMovements: string, totalMoneyInternalMovements: string) => {
  const totalExternalMovements = unformatMoney(totalMoneyExternalMovements)
  const totalInternalMovements = unformatMoney(totalMoneyInternalMovements)
  const totalMoney = (totalExternalMovements - totalInternalMovements)

  return formatMoney(totalMoney)
}
