import { useCallback, useEffect, useMemo, useState } from 'react'
import { CountPlanService } from 'Core/Service/CountPlan'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { AccountsPlansHooksTranslator } from '../i18n/AccountsPlansHooksI18n'

export const useAccountsPlanOptions = () => {
  const { addToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [accountsPlanData, setAccountsPlanData] = useState([])

  const i18n = useTranslator(AccountsPlansHooksTranslator)

  const fetchAccountsPlan = useCallback(() => {
    setLoading(true)

    return CountPlanService.listWithoutPagination().then((data) => {
      setAccountsPlanData(data)
    }).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorFecthingAccountsPlansOptions
      })
    }).finally(() => {
      setLoading(false)
    })
  }, [addToast, i18n?.errorFecthingAccountsPlansOptions])

  const accountsPlanOptions = useMemo(() => {
    const initData = accountsPlanData?.map(
      ({ id, name, classification }) => (
        { value: id, label: `${classification} ${name || ''}` }
      )
    )

    const newData = accountsPlanData.length ? [{ value: '', label: 'Nenhum' }, ...initData] : []

    return newData
  }, [accountsPlanData])

  useEffect(() => {
    fetchAccountsPlan()
  }, [fetchAccountsPlan])

  return {
    accountsPlanOptions,
    loading
  }
}
