/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  fontColor,
  tableBodyContainer
} from './FinancialMovements.styles'
import {
  TableBody,
  TableBodyRow,
  TableBodyCell
} from '@bonitour/components'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { CircleStatus } from 'Shared/styles/global'
import { Tooltip } from 'Shared/components/Tooltip'
import { useTranslator } from 'Shared/language/i18nHandler'
import { FullFinancialScheduleTranslator } from '../i18n/FullFinancialScheduleI18n'
import { flowMovementsOperationTypeColors, flowMovementsStatusColors } from '../Constants/FinancialSchedule'

/**
 * @typedef FullFinancialScheduleProps
 * @prop { Record<string, string>[] } data
 * @prop { boolean } [loading]
 */

/** @type { React.FC<FullFinancialScheduleProps> } */
export const FullFinancialScheduleTableBody = (
  {
    data,
    loading
  }
) => {
  const i18n = useTranslator(FullFinancialScheduleTranslator)

  return (
    <TableBody loading={loading}>
      {data.map((dataObject) => (
        <TableBodyRow key={dataObject?.id} css={[tableBodyContainer]}>
          <TableBodyCell>
            {dataObject?.date.format(i18n?.dateFormat)}
          </TableBodyCell>
          <TableBodyCell>
            <SafeOverflow text={i18n?.descriptionTranslator(dataObject)} className='safe_overflow__description' />
          </TableBodyCell>
          <TableBodyCell>
            <SafeOverflow
              text={i18n?.originTypeTranslator(dataObject?.originType)}
              className='safe_overflow__origin_type'
            />
          </TableBodyCell>
          <TableBodyCell
            css={
              [fontColor(flowMovementsOperationTypeColors[dataObject?.operationType])]
            }
          >
            {i18n?.flowMovementsOperationType[dataObject?.operationType]}
          </TableBodyCell>
          <TableBodyCell
            css={
              [fontColor(flowMovementsOperationTypeColors[dataObject?.operationType])]
            }
          >
            {dataObject?.value}
          </TableBodyCell>
          <TableBodyCell>
            <CircleStatus
              statusColor={flowMovementsStatusColors[dataObject?.status]}
            >
              {i18n?.flowMovementsStatus[dataObject?.status]}
            </CircleStatus>
          </TableBodyCell>
          <TableBodyCell>
            <CircleStatus
              statusColor={dataObject?.conciliated ? '--clr-blue1' : '--clr-gray7'}
              className='circle__conciliated'
            >
              <Tooltip
                verticalPosition='center'
                horizontalPosition='left'
                tooltipClass='tooltip__conciliated'
              >
                {dataObject?.conciliated ? i18n?.conciliate : i18n?.notConciliate}
              </Tooltip>
            </CircleStatus>
          </TableBodyCell>
        </TableBodyRow>
      ))}
    </TableBody>
  )
}
