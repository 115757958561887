import { useState, useEffect, useCallback } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SuppliersHooksTranslator } from '../i18n/SuppliersHooksI18n'
import { SupplierService } from 'Core/Service/Supplier'

export const useSuppliersOptions = () => {
  const { addToast } = useToast()
  const [suppliersData, setSuppliersData] = useState([])

  const i18n = useTranslator(SuppliersHooksTranslator)

  const fetchSuppliers = useCallback(() => {
    return SupplierService.options().then(
      setSuppliersData
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorLoadSupplierOptions
      })
    })
  }, [addToast, i18n?.errorLoadSupplierOptions])

  useEffect(() => {
    fetchSuppliers()
  }, [fetchSuppliers])

  return {
    suppliersData
  }
}
