import React, { useCallback } from 'react'
import { CloseIcon, SearchIcon } from '@bonitour/components'
import { FilterContainer } from './styles'

/**
 * @typedef FilterSelectProps
 * @prop { Path<T> } name
 * @prop { boolean } disabled
 * @prop { ((e: string)) => void } setFilter
 * @prop { string } filter
 */

/** @type { React.FC<FilterSelectProps> } */
export const FilterSelect = ({
  disabled = false,
  name,
  setFilter,
  filter,
  ...others
}) => {
  const handleFilterChange = useCallback(
    (e) => {
      setFilter(e.target.value)
    },
    [setFilter]
  )

  const clearFilter = useCallback(() => {
    setFilter('')
  }, [setFilter])

  return (
    <FilterContainer {...others}>
      <SearchIcon className='icon__search' />
      <input
        className="filter-input"
        name="filter"
        id={`filter-input-${name}`}
        disabled={disabled}
        size={1}
        placeholder={disabled ? '' : 'Pesquisar'}
        onClick={e => e.stopPropagation()}
        onChange={handleFilterChange}
        value={filter}
        autoComplete="off"
      />
      <button
        onClick={clearFilter}
        type="button"
        disabled={!filter}
        aria-label="Limpar pesquisa"
        title="Limpar"
      >
        <CloseIcon className='icon__close' />
      </button>
    </FilterContainer>
  )
}
