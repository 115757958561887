/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { formatMoney } from '@bonitour/common-functions'
import { jsx } from '@emotion/core'
import { useCallback, useState } from 'react'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { ReceivablesFilter } from './Filter/ReceivablesFIlter'
import { useListReceivables } from './hooks/useListReceivables'
import { ReceivablesImport } from './ReceivablesImport/ReceivablesImport'
import { ReceivablesList } from './ReceivablesList/ReceivablesList'
import {
  Card,
  flexColumn,
  Label,
  PaginationContainer
} from '@bonitour/components'
import {
  cardPriceStyle,
  listCard,
  textCardPrice,
  valueCardPrice
} from './Receivables.styles'
import { useHistory } from 'react-router-dom'

const ValueCard = ({ text, value }) => {
  return (
    <Card css={[flexColumn, cardPriceStyle]}>
      <Label
        css={textCardPrice}
        htmlFor='text'
      >
        {text}
      </Label>
      <Label
        htmlFor='value'
        css={valueCardPrice}
      >
        {value ? formatMoney(value) : '-'}
      </Label>
    </Card>
  )
}

export const Receivables = () => {
  const { push } = useHistory()

  const {
    receivables,
    handlePagination,
    meta,
    loading,
    receivablesFilters,
    perPageOptions,
    pagination,
    fetchExportReceivables,
    fetchReceivables,
    handleFilters
  } = useListReceivables()

  const [expandableImport, setExpandableImport] = useState(false)

  const toggleExpandableImport = () => setExpandableImport(!expandableImport)

  const { count = 0, last = 1, totalGrossAmount, totalNetAmount } = meta ?? {}

  const onReturnCreditCardDashboard = useCallback(() => {
    push('/credit-card')
  }, [push])

  return (
    <>
      <PageTitle
        title='Recebíveis'
        onClickReturn={onReturnCreditCardDashboard}
      />
      <ReceivablesImport
        isOpened={expandableImport}
        toggleExpandableImport={toggleExpandableImport}
      />
      <Card css={listCard}>
        <div className='wrapper__totalizers'>
          <ValueCard
            text='Valor bruto'
            value={totalGrossAmount}
          />
          <ValueCard
            text='Valor líquido'
            value={totalNetAmount}
          />
        </div>

        <ReceivablesFilter
          receivablesFilters={receivablesFilters}
          fetchExportReceivables={fetchExportReceivables}
          fetchReceivables={fetchReceivables}
          handleFilters={handleFilters}
        />
        <PaginationContainer
          total={count}
          pagesQuantity={last}
          onPagination={handlePagination}
          pageLimitOptions={perPageOptions}
          currentPage={pagination.currentPage}
          pageLimitDefault={pagination.perPage}
          isOpenDrawer
        >
          <ReceivablesList
            loading={loading}
            receivables={receivables}
          />
        </PaginationContainer>
      </Card>
    </>
  )
}
