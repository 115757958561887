/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useHistory } from 'react-router'
import { useCallback, useEffect, useMemo } from 'react'
import {
  Accordion,
  CollapsableSection,
  ToggleInputGroup
} from '@bonitour/components'
import { useCreateEventsProcessor } from '../hooks/useCreateEventProcessor'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsTranslator } from '../i18n/EventsProcessorsI18n'
import { EventProcessorEntryConditions } from './EventProcessorEntryConditions/EventProcessorEntryConditions'
import { useParams } from 'react-router-dom'
import { useGetEventProcessor } from '../hooks/useGetEventProcessor'
import { useQuery } from 'Shared/hooks/useQuery'
import { EventProcessorNameAndDescription } from './EventProcessorNameAndDescription/EventProcessorNameAndDescription'
import { useEventProcessorUpdate } from '../hooks/useEventProcessorUpdate'
import { useToast } from 'Shared/hooks/useToast'
import {
  PageCreateEditEventProcessorContainer as Container, customToggle
} from './PageCreateEditEventProcessor.styles'
import {
  EventProcessorAutomationRules
} from './EventProcessorAutomationRules/EventProcessorAutomationRules'
import { useGetMatchedEvents } from '../hooks/useGetMatchedEvents'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'

export const PageCreateEditEventProcessor = () => {
  const { push } = useHistory()
  const routerParams = useParams()
  const [{ section, search }] = useQuery()

  const { addToast } = useToast()

  useFlaggedRoute('billing-events-processors')

  const returnToListEventsProcessors = useCallback(
    () => push('/events-processors'), [push]
  )

  const i18n = useTranslator(EventsProcessorsTranslator)

  const {
    onCreateEventProcessor,
    loading
  } = useCreateEventsProcessor()

  const {
    fetchEventProcessor,
    eventProcessorData
  } = useGetEventProcessor()

  const {
    updateEventProcessor,
    loading: isLoadingEventProcessorUpdate,
    status,
    handleUpdateStatus
  } = useEventProcessorUpdate(eventProcessorData)

  const isCreate = useMemo(() => routerParams?.[0] === 'create', [routerParams])

  const handleSetSection = useCallback((sectionValue) => {
    if (isCreate && (sectionValue !== 1 && sectionValue !== 0)) {
      return addToast({
        defaultMessage: i18n?.finishTheFisrtSession
      })
    }

    return push({ search: `?section=${sectionValue}` })
  }, [addToast, i18n?.finishTheFisrtSession, isCreate, push])

  const selectedSession = useMemo(() => {
    return section ? Number(section) : 1
  }, [section])

  const handleCreateEditEventProcessor = useCallback((payload) => {
    if (isCreate && !routerParams?.eventProcessorId) {
      onCreateEventProcessor(payload).then(({ events_processor }) => {
        push(`/events-processors/edit/${events_processor?.id}?section=2`)
      })
    } else {
      updateEventProcessor(payload, routerParams?.eventProcessorId).then(
        () => fetchEventProcessor(routerParams?.eventProcessorId)
      )
    }
  }, [isCreate,
    routerParams?.eventProcessorId,
    onCreateEventProcessor,
    push,
    updateEventProcessor,
    fetchEventProcessor
  ])

  useEffect(() => {
    if (!routerParams?.eventProcessorId || eventProcessorData) return
    fetchEventProcessor(routerParams?.eventProcessorId)
  }, [
    eventProcessorData,
    fetchEventProcessor,
    push,
    routerParams.eventId,
    routerParams?.eventProcessorId,
    search
  ])

  const onNext = useCallback(() => {
    push({ search: `?section=${Number(section) + 1}` })
  }, [push, section])

  const onBack = useCallback(() => {
    push({ search: `?section=${Number(section) - 1}` })
  }, [push, section])

  const {
    fetchMatchedEvents,
    matchedEventsData,
    loading: matchedEventsLoading
  } = useGetMatchedEvents()

  useEffect(() => {
    if (!routerParams?.eventProcessorId || matchedEventsData) return
    fetchMatchedEvents(routerParams?.eventProcessorId)
  }, [
    routerParams?.eventProcessorId,
    fetchMatchedEvents,
    matchedEventsData
  ])

  return (
    <Container>
      <section className='section__header'>
        <PageTitle
          title={isCreate ? i18n?.newProcessor : i18n?.editProcessor}
          onClickReturn={returnToListEventsProcessors}
        />
        {!isCreate && (
          <ToggleInputGroup
            id={'toggle'}
            checked={status}
            disabled={isLoadingEventProcessorUpdate}
            onChange={
              () => handleUpdateStatus(eventProcessorData, routerParams?.eventProcessorId)
            }
            customCss={[customToggle]}
          >
            {status ? i18n?.statusActive : i18n?.statusInactive}
          </ToggleInputGroup>
        )}
      </section>
      <Accordion value={selectedSession} onClick={handleSetSection}>
        <CollapsableSection
          indicator={1}
          title={i18n?.nameAndDescription}
        >
          <EventProcessorNameAndDescription
            handleSubmit={handleCreateEditEventProcessor}
            isLoading={loading || isLoadingEventProcessorUpdate}
            onClose={returnToListEventsProcessors}
            eventProcessorData={eventProcessorData}
          />
        </CollapsableSection>

        <CollapsableSection
          indicator={2}
          title={i18n?.selectionAndValidationEvents}
        >
          <EventProcessorEntryConditions
            onNext={onNext}
            onBack={onBack}
            fetchMatchedEvents={fetchMatchedEvents}
            matchedEventsData={matchedEventsData}
            matchedEventsLoading={matchedEventsLoading}
          />
        </CollapsableSection>

        <CollapsableSection
          indicator={3}
          title={i18n?.automationRules}
        >
          <EventProcessorAutomationRules
            onBack={onBack}
            fetchMatchedEvents={fetchMatchedEvents}
            matchedEventsData={matchedEventsData}
            matchedEventsLoading={matchedEventsLoading}
          />
        </CollapsableSection>
      </Accordion>
    </Container>
  )
}
