import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { css, keyframes } from '@emotion/react'

import { displayTooltipOnHover } from 'Shared/components/Tooltip/styles'

import { depth } from 'Shared/styles/utils/depthBuilder'

const InputControl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  label {
    color: var(--clr-gray2);
    font-size: var(--fs-sm);
    font-weight: 700;
    margin: 0.3125rem;
  }

  .input__required_tag {
    color: var(--clr-gray4);
    margin-left: 0.9ch;
    font-weight: 500;
    font-size: 0.9em;
  }
`

export const SelectControl = styled(InputControl)`
  position: relative;
`

const showTooltip = keyframes`
  0% {
    opacity: 0;
    pointer-events: none;
  }

  10% {
    opacity: 1;
    pointer-events: inherit;
    --translateYVar: 0px;
  }

  90% {
    opacity: 1;
    pointer-events: inherit;
    --translateYVar: 0px;
  }

  100% {
    opacity: 0;
    pointer-events: none;
  }
`

export const SelectContainer = styled.div`
  background: ${props => {
    return props.isDisabled ? 'var(--clr-gray12)' : 'var(--clr-white)'
  }};

  height: 2.5rem;
  width: 100%;
  border-radius: var(--round-element-radius, 1.25rem);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  border: 1px solid
    ${props => {
    return props.hasError ? 'var(--clr-danger)' : 'var(--clr-gray7)'
  }};
  padding: 0.5rem 1.25rem;

  font-size: 0.875rem;
  color: var(--clr-gray3);

  cursor: pointer;

  .select__chevron i {
    transition: transform 200ms ease-in-out;
    transform-origin: center center;
    margin-right: 0.625rem;
  }

  .select__chevron {
    margin-right: -1rem;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .select__placeholder {
    color: ${({ isDisabled }) =>
    isDisabled ? 'var(--clr-gray4)' : 'var(--clr-gray5)'};
  }

  &.open_above {
    .select__chevron i {
      transform: rotate(180deg);
    }
  }

  &.open {
    .select__chevron i {
      transform: rotate(180deg);
    }
  }

  &.open.open_above {
    .select__chevron i {
      transform: rotate(0deg);
    }
  }

  &:focus {
    outline: none;

    .input-tooltip {
      opacity: 1;
      pointer-events: inherit;
      --translateYVar: 0px;
    }
  }

  .input-tooltip {
    animation: ${showTooltip} 5s;

    .tooltip-box {
      padding: 0.5rem 1.25rem;
      color: var(--clr-white);
      background: var(--clr-red2);

      &:before {
        background: var(--clr-red2);
      }
    }
  }

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          cursor: default;
          pointer-events: none;
        `
      : ''}

  ${displayTooltipOnHover('input-tooltip')}
`

export const SelectOptionsContainer = styled(motion.div)`
  ${depth(5)}

  position: absolute;
  width: calc(100% - 1rem);
  left: 0.5rem;
  top: calc(100% - 0.4rem);
  z-index: 3;

  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--clr-gray9);

  background: var(--clr-white);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  max-height: 18.625rem;

  transform-origin: top center;

  &.on_top {
    top: auto;
    bottom: calc(100% - 0.4rem);
    transform-origin: bottom center;
  }

  .option {
    width: 100%;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: transparent;
    color: var(--clr-gray3);

    transition: background 0.2s ease-in-out;
  }

  .option + .option {
    margin-top: 0.25rem;
  }

  .option:hover {
    background: var(--clr-gray13);
  }

  .option.selected {
    background: var(--clr-primary-t08);
    color: var(--clr-gray2);
  }

  .select_options__content {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 3px;
    box-sizing: border-box;

    width: 100%;
  }
`

export const FilterContainer = styled.div`
  width: calc(100% - 1rem);
  margin: 0.5rem;
  position: relative;

  input {
    padding: 0.5rem 2rem;
    width: 100%;
    border-radius: 3rem;
    box-sizing: border-box;
    border: 1px solid var(--clr-gray7);
    color: var(--clr-gray2);

    &:focus {
      outline: none;
    }
  }

  i,
  button {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    font-size: 1.25rem;
    color: var(--clr-gray4);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    .icon__close {
      i {
        font-size: 14px;
      }
    }
  }
  
  .icon__search {
    i {
      left: 0.5rem;
    }
  }

  button {
    right: 1rem;
    transition: opacity 200ms ease-in-out;
    &[disabled] {
      pointer-events: none;
      opacity: 0;
    }
  }
`

export const MultiSelectorButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  transform-origin: 1.5rem 50%;

  width: 100%;
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  background: transparent;
  color: var(--clr-gray3);

  transition: background 0.2s ease-in-out;

  &.single {
    padding: 0;
  }

  .selector_label {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: calc(100% - 2.125rem);
    text-align: left;
  }

  .selector__icon {
    --selector-size: 1.625rem;
    box-sizing: border-box;

    position: relative;

    background: var(--clr-white);

    width: var(--selector-size);
    height: var(--selector-size);
    min-width: var(--selector-size);
    min-height: var(--selector-size);

    border-radius: 0.5rem;
    border: 1.5px solid var(--clr-gray7);
    color: var(--clr-gray11);

    transition-property: background, border, color;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;

    i,
    &:after {
      --default-transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: var(--default-transform);
      transform-origin: 50% 50%;
    }

    i {
      display: none;
    }

    &::after {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      background: var(--clr-yellow1);
      border-radius: 100%;
      transform: var(--default-transform) scale(0);
      transition: transform 300ms ease-in-out;
    }
  }

  &.selected,
  &.partial {
    .selector__icon {
      color: var(--clr-white);
      background: var(--clr-yellow1);
      border-color: var(--clr-yellow1);
    }
  }

  &.selected i {
    display: flex;
  }

  &.partial .selector__icon {
    background: var(--clr-white);
  }
`

export const SelectButton = styled(MultiSelectorButton)`
`
