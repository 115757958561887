import { billingCore } from './Billing'
import { CashDepositsModel } from 'Core/Models/cashDeposits'
import { CashDepositsParser } from 'Core/Parser/cashDeposits'
import { GetCashDepositParsed, GetCashDepositsParams } from 'Core/Types/CashDeposit'

export const CashDepositsService = {
  getCashDeposits: async (filters: GetCashDepositsParams): Promise<GetCashDepositParsed> => {
    return await billingCore.cashDepositDomain.getCashDeposits(
      CashDepositsModel.getCashDeposits(filters)
    ).then(CashDepositsParser.getCashDeposits)
  }
}
