/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { LoadingAnimation } from '@bonitour/components'
import { jsx } from '@emotion/core'

/**
 * @typedef BaseMessageInfoProps
 * @prop { boolean } isLoading
 * @prop { string } title
 * @prop { string } subTitle
 */

/** @type { React.FC<BaseMessageInfoProps> } */
export const BaseMessageInfo = (
  {
    isLoading,
    title,
    subTitle
  }
) => {
  return (
    <div className='wrapper__message'>
      {isLoading
        ? (
          <>
            <LoadingAnimation size={40} />
          </>
        )
        : (
          <>
            <p>
              {title}
            </p>
            <p>
              {subTitle}
            </p>
          </>

        )
      }
    </div>
  )
}
