import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const customLinkWidth = css`
  max-width: 30vw;
`

export const requestLink = css`
  max-width: 30vw;
  color: ${colors.gray1};
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const modalBox = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const modalBoxRow = css`
  color: ${colors.gray4};
  margin-bottom: 22px;
`

export const containerSendToEmailMessage = css`
  margin-bottom: 2em;
  color: ${colors.gray2};
`

export const sendToEmailMessage = css`
  font-size: 18px;
  color: ${colors.gray2};
  margin-bottom: 10px;
`

export const emailStyle = css`
  color: ${colors.primary};
  margin-left: 8px;
`
