export const RevenuesTranslator = (
  dictionary
) => {
  const modalAuditRevenueTitle = dictionary?.revenue_auditModalTitle_label

  const auditLabels = {
    value: dictionary?.audit_value_label,
    service_name: dictionary?.audit_serviceName_label,
    payment_method: dictionary?.audit_paymentMethod_label,
    date: dictionary?.audit_date_label,
    taxes: dictionary?.audit_taxes_label,
    reference_date: dictionary?.audit_referenceDate_label,
    observation: dictionary?.audit_observation_label,
    status: dictionary?.audit_status_label,
    payment_day: dictionary?.audit_paymentDay_label,
    expense_revenue_type: dictionary?.audit_expenseRevenueType_label,
    bank_account_name: dictionary?.audit_bankAccountName_label,
    expense_revenue_name: dictionary?.audit_revenueName_label,
    accountable_plan_name: dictionary?.audit_accountablePlanName_label
  }

  return {
    modalAuditRevenueTitle,
    auditLabels
  }
}
