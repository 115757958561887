import React from 'react'
import {
  ActionButtonsContainer
} from './ActionButtonss.styles'
import { FileExportIcon } from '@bonitour/components'

interface Button {
  text: string
  icon: React.ReactElement
  onClick: () => void
  isDisabled: boolean
}

interface ActionButtonsProps {
  btns: Button[]
}

export const exportBtn = (
  onClick?: () => void,
  isDisabled = false,
  text?: string
) => ({
  text: text || 'Exportar',
  icon: <FileExportIcon />,
  onClick,
  isDisabled
})

export const ActionButtons: React.FC<ActionButtonsProps> = ({ btns }) => {
  return (
    <ActionButtonsContainer>
      {btns.map(({ text, icon, onClick, isDisabled = false }, idx) => (
        <button
          type='button'
          onClick={onClick}
          disabled={isDisabled}
          className='expand__btn'
          key={idx}
          style={{ '--text-length': text?.length || 0 }}
        >
          {icon}
          <small className='test'>
            {text}
          </small>
        </button>
      ))}
    </ActionButtonsContainer>
  )
}
