import { SafeDate } from '@binamik/js-providers'
import { useTranslatedCashDepositsStatus } from './hooks/useTranslatedCashDepositsStatus'

interface CashDepositsInputsTranslatorRes {
  inputs: {
    startDate: {
      label: string
      placeholder: string
    },
    endDate: {
      label: string
      placeholder: string
    },
    status: {
      label: string
      placeholder: string
    },
    destinationBankAccountId: {
      label: string
      placeholder: string
    }
  }
  errors: {
    startDate: {
      dateFormat: string
      dateRequired: string
      startDateMustBeLessThanOrEqualEndDate: string
    },
    endDate: {
      dateFormat: string
      dateRequired: string
      endDateMustBeAfterOrEqualStartDate: string
    }
  },
  translatedOptions: {
    status: { value: string, label: string }[]
  },
  translatedValues: {
    createdAt: (value: SafeDate) => string
  }
}

export const CashDepositsInputsTranslator = (
  dictionary
): CashDepositsInputsTranslatorRes => {
  const { cashDepositsStatusOptionsTranslated } = useTranslatedCashDepositsStatus({ dictionary })

  const labels = {
    startDate: {
      label: dictionary?.conciliation_startDate_inputLabel,
      placeholder: dictionary?.conciliation_startDate_placeholderLabel
    },
    endDate: {
      label: dictionary?.conciliation_endDate_inputLabel,
      placeholder: dictionary?.conciliation_endDate_placeholderLabel
    },
    status: {
      label: dictionary?.common_status_label,
      placeholder: dictionary?.common_selectStatuses_label
    },
    destinationBankAccountId: {
      label: dictionary?.cash_deposit_destinationAccount_label,
      placeholder: dictionary?.common_selectAccount_label
    }
  }

  const errors = {
    startDate: {
      dateFormat: dictionary?.cash_deposit_dateFormat_formErrorLabel,
      dateRequired: dictionary?.cash_deposit_startDateRequired_formErrorLabel,
      startDateMustBeLessThanOrEqualEndDate: dictionary?.common_startDateMustBeLessThanOrEqualEndDate_label
    },
    endDate: {
      dateFormat: dictionary?.cash_deposit_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_endDateRequired_formErrorLabel,
      endDateMustBeAfterOrEqualStartDate: dictionary?.cash_deposit_endDateMustBeafterOrEqualStartDate_formErrorLabel
    }
  }

  const translatedOptions = {
    status: cashDepositsStatusOptionsTranslated,
    statusRequired: cashDepositsStatusOptionsTranslated
  }

  const translatedValues = {
    createdAt: (value: SafeDate) => value?.format(dictionary?.common_dateFormat_template)
  }

  return {
    inputs: labels,
    errors,
    translatedOptions,
    translatedValues
  }
}
