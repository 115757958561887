import { useCallback, useEffect, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'
import { EventsProcessorsService } from 'Core/Service/eventsProcessors'

export const useGetAutomationRuleAccountFormulas = () => {
  const [loading, setLoading] = useState(false)
  const [automationRuleAccountFormulasData, setAutomationRuleAccountFormulasData] = useState()
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const getAutomationRuleAccountFormulas = useCallback(() => {
    setLoading(true)
    return EventsProcessorsService.getAutomationRuleAccountFormulas()
      .then(setAutomationRuleAccountFormulasData)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorFetchingAccountFormulasForAutomationRule
        })
      })
      .finally(() => setLoading(false))
  }, [addToast, i18n?.errorFetchingAccountFormulasForAutomationRule])

  useEffect(() => {
    if (automationRuleAccountFormulasData) return
    getAutomationRuleAccountFormulas()
  }, [automationRuleAccountFormulasData, getAutomationRuleAccountFormulas])

  return {
    loading,
    automationRuleAccountFormulasData
  }
}
