/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTranslator } from 'Shared/language/i18nHandler'
import { MatchedEventsContainer as Container, MatchedEventsBody } from './MatchedEvents.styles'
import { EventsRowAnnexDetails } from 'Domains/Events/List/EventsRowAnnexDetails'
import { EventsProcessorsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsI18n'
import { EventsTranslator } from 'Domains/Events/i18n/EventsI18n'
import { LoadingAnimation, RadioGroup, RadioInputGroup } from '@bonitour/components'
import { useEffect } from 'react'

/**
 * @typedef MatchedEventsProps
 * @prop { Record<string, string>[] } matchedEventsData
 * @prop { boolean } isLoading
 */

/** @type { React.FC<MatchedEventsProps> } */
export const MatchedEvents = ({
  matchedEventsData,
  isLoading
}) => {
  const i18n = useTranslator(EventsProcessorsTranslator)

  return (
    <Container>
      <div>
        <h3 className='container__title'>
          {i18n?.eventServicedByProcessor(
            matchedEventsData?.length
          )}
        </h3>
        <p className='container__subtitle'>
          {i18n?.matchedEventsSubtitle}
        </p>
      </div>
      <MatchedEventsContent
        isLoading={isLoading}
        matchedEventsData={matchedEventsData}
        i18n={i18n}
      />
    </Container>
  )
}

/**
 * @typedef MatchedEventsContentProps
 * @prop { Record<string, string>[] } matchedEventsData
 * @prop { boolean } isLoading
 * @prop { Record<string, string> } i18n
 * @prop { boolean } isSelectEvent
 * @prop { string } selectedEventId
 * @prop { (id: string) => void } setSelectedEventId
 */

/** @type { React.FC<MatchedEventsContentProps> } */
export const MatchedEventsContent = (
  {
    isLoading,
    matchedEventsData,
    i18n,
    isSelectEvent = false,
    setSelectedEventId,
    selectedEventId
  }
) => {
  const i18nEvent = useTranslator(EventsTranslator)

  useEffect(() => {
    const eventId = matchedEventsData?.length > 0 ? matchedEventsData[0]?.id : null
    setSelectedEventId && setSelectedEventId(eventId)
  }, [
    matchedEventsData,
    setSelectedEventId
  ])

  return (
    <MatchedEventsBody
      className={
        isSelectEvent ? 'border' : ''
      }
    >
      {isLoading
        ? (
          <LoadingAnimation
            size={40}
            className='load'
          />
        )
        : (
          !matchedEventsData?.length ? (
            <p className='matched_events__empty_message'>
              {i18n?.doesNotHaveEventsServicedByProcessor}
            </p>
          ) : (
            <ElementsWrapper
              isSelectEvent={isSelectEvent}
              selectedEventId={selectedEventId}
              onSelectedEventId={setSelectedEventId}
            >
              {matchedEventsData?.map((event, idx) => (
                <ElementContainer
                  key={idx}
                  isSelectEvent={isSelectEvent}
                  value={event?.id}
                >
                  <EventsRowAnnexDetails
                    details={event?.eventRequestDetails}
                    i18n={i18nEvent}
                  />
                </ElementContainer>
              ))}
            </ElementsWrapper>
          )
        )
      }
    </MatchedEventsBody>
  )
}

/**
 * @typedef ElementsWrapperProps
 * @prop { boolean } isSelectEvent
 * @prop { string } selectedEventId
 * @prop { (id: string) => void } onSelectedEventId
 * @prop { React.ReactNode } children
 */

/** @type { React.FC<ElementsWrapperProps> } */
const ElementsWrapper = (
  {
    isSelectEvent,
    selectedEventId,
    onSelectedEventId,
    children = []
  }
) => (
  isSelectEvent ? (
    <RadioGroup
      value={selectedEventId}
      onChange={onSelectedEventId}
      className='matched_events__wrapper different_max_height'
      name='event'
    >
      {children}
    </RadioGroup>
  ) : (
    <div className='matched_events__wrapper'>
      {children}
    </div>
  )
)

/**
 * @typedef ElementContainerProps
 * @prop { boolean } isSelectEvent
 * @prop { boolean } checked
 * @prop { React.ReactNode } children
 * @prop { () => void } onChange
 * @prop { string } value
 */

/** @type { React.FC<ElementContainerProps> } */
const ElementContainer = (
  {
    isSelectEvent,
    value,
    checked,
    onChange,
    children = []
  }
) => (
  isSelectEvent ? (
    <RadioInputGroup
      id={value}
      name='event'
      value={value}
      checked={checked}
      onChange={onChange}
    >
      {children}
    </RadioInputGroup>
  ) : (
    children
  )
)
