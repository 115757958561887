import { useCallback } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SupplierBankAccountService } from 'Core/Service/SupplierBankAccount'
import { useQueryClient } from 'react-query'
import { SupplierBankAccountsHooksTranslator } from '../i18n/SupplierBankAccountsHooksI18n'

export const useRemoveSupplierBankAccount = () => {
  const { addToast } = useToast()
  const i18n = useTranslator(SupplierBankAccountsHooksTranslator)
  const queryClient = useQueryClient()

  const removeSupplierBankAccount = useCallback((supplierBankAccountId, supplierId) => {
    return SupplierBankAccountService.remove(supplierId, supplierBankAccountId).then(() => {
      queryClient.invalidateQueries({
        queryKey: ['supplierBankAccountList', supplierId]
      })
      addToast({
        defaultMessage: i18n?.removeSupplierBankAccount,
        typeToast: 'success'
      })
    }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorRemoveSupplierBankAccount
      })
    }).finally(() => {})
  }, [
    addToast,
    i18n?.removeSupplierBankAccount,
    i18n?.errorRemoveSupplierBankAccount,
    queryClient
  ])

  return {
    onRemoveSupplierBankAccount: removeSupplierBankAccount
  }
}
