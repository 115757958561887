
export const EventsProcessorsInputsErrorsTranslator = (dictionary) => {
  const labels = {
    name: {
      nameRequired: dictionary?.event_processor_nameRequired_formErrorLabel
    },
    automationRuleAction: {
      automationRuleActionRequired: dictionary?.event_processor_automationRuleActionRequired_formErrorLabel
    },
    operationType: {
      operationTypeRequired: dictionary?.event_processor_operationTypeRequired_formErrorLabel
    },
    dateWithFormula: {
      dateWithFormulaRequired: dictionary?.event_processor_dateWithFormulaRequired_formErrorLabel
    },
    referenceDateWithFormula: {
      referenceDateWithFormulaRequired: dictionary?.event_processor_referenceDateWithFormulaRequired_formErrorLabel
    },
    automationRuleStatus: {
      automationRuleStatusRequired: dictionary?.event_processor_automationRuleStatusRequired_formErrorLabel
    },
    accountId: {
      accountIdRequired: dictionary?.event_processor_accountIdRequired_formErrorLabel
    },
    accountFormula: {
      accountFormulaRequired: dictionary?.event_processor_accountFormulaRequired_formErrorLabel
    },
    value: {
      valueRequired: dictionary?.event_processor_valueRequired_formErrorLabel
    },
    description: {
      descriptionRequired: dictionary?.event_processor_descriptionRequired_formErrorLabel
    }
  }

  return {
    errors: labels
  }
}
