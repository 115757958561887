
import { SafeDate } from '@binamik/js-providers'
import { CashDeposit, GetCashDepositsRes } from 'Core/Types/CashDeposit'

export const CashDepositsParser = {
  cashDepositSingle: (cashDeposit: CashDeposit) => {
    const {
      id,
      destination_bank_account_name,
      origin_bank_account_name,
      date,
      value,
      status,
      origin_bank_account_id,
      destination_bank_account_id,
      transfer_method,
      user_name,
      user_id,
      observation,
      taxes,
      created_at
    } = cashDeposit

    const safeDate = date && new SafeDate(date)
    const safeCreatedAt = created_at && new SafeDate(created_at)

    return {
      id,
      destinationBankAccountName: destination_bank_account_name,
      originBankAccountName: origin_bank_account_name,
      date: safeDate,
      value,
      status,
      statusRequired: status,
      userName: user_name,
      originBankAccountId: origin_bank_account_id,
      destinationBankAccountId: destination_bank_account_id,
      transferMethod: transfer_method,
      userId: user_id,
      taxe: taxes,
      observation,
      createdAt: safeCreatedAt
    }
  },
  getCashDeposits: ({ bank_transfers, meta }: GetCashDepositsRes) => {
    return {
      cashDeposits: bank_transfers?.map(CashDepositsParser.cashDepositSingle),
      meta: {
        total: meta?.count,
        totalPages: meta?.last
      }
    }
  }
}
