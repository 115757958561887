/* eslint-disable indent */
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const ListContainer = styled.div`
  min-height: 30rem;
  background-color: var(--clr-white);
  border-radius: 0.625rem;
  padding: 1.25rem;
  overflow-x: auto;

  .wrapper__message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 30rem;

    p {
      color: var(--clr-gray3);
      font-size: 1.125rem;
    }
  }


  table {
    box-sizing: border-box;
    table-layout: fixed;
    width: 100%;
  }

  .value_color {
    font-weight: 700;
  }

  .value_color__negative {
    color: var(--clr-red2);
  }

  .value_color__positive {
    color: var(--clr-green2);
  }

  th {
    padding: 0.625rem;
  }

  td {
    white-space: initial;
    padding: 1.25rem 0.625rem;


    ${props => {
    const {
      isBankConciliationsTabPartial,
      isCreditCardConciliationsTabConciliation,
      isBankConciliationsTabConciliation,
      isCreditCardConciliationsTabPartial

    } = props

    if (isBankConciliationsTabPartial || isCreditCardConciliationsTabPartial) {
      return css`
        &:nth-of-type(2):not(td td),
        &:nth-of-type(4):not(td td) {
          padding: 0;
        }
      `
    }

    if (isCreditCardConciliationsTabConciliation || isBankConciliationsTabConciliation) {
      return css`
        &:nth-of-type(2):not(td td),
        &:nth-of-type(3):not(td td) {
          padding: 0;
        }
      `
    }
  }}

    


    vertical-align: top;

    table {
      margin: 1.25rem 0;
      background-color: transparent !important;
      td {
        vertical-align: middle;
        padding: 0rem 0.625rem;
        background-color: transparent !important;
      }
    }
  }

  .safe_overflow__container {
    margin-top: 4px;
    max-width: 17.5rem;
  }

  .safe_overflow_value_diference {
    margin-top: 5px;
  }

  .td_value_diference {
    padding: 1.25rem 0px;
  }

  .container_info {
    display: flex;
    height: 1.25rem;
    width: 1.25rem;
    justify-content: center;
    border-radius: 100vw;
    border: 1px solid var(--clr-gray6);
    position: relative;

    i {
      color: var(--clr-gray6)
    }

    &:hover .tooltip__conciliation {
      pointer-events: initial;
      cursor: initial;
      opacity: 1;
    }

    .tooltip__conciliation {
      max-width: 25rem;
      opacity: 0;
     
      .tooltip-box {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        white-space: nowrap;
        
        
        p {
          padding: 0.3em 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .content_table {
    height: 100%;
    border: 1px solid var(--clr-gray11);
    border-radius: 0.625rem;
    padding: 0.625rem;
    width: fit-content;
  }

  .content_table__titles {
    display: flex;
    margin-bottom: 14px;

    p {
      font-weight: 700;
      color: var(--clr-gray3);
      min-width: 18rem;

      :first-of-type {
        padding-left: 4.3rem;

        ${props => {
          const {
            isBankConciliationsTabPartial,
            isCreditCardConciliationsTabConciliation,
            isBankConciliationsTabConciliation,
            isCreditCardConciliationsTabPartial
          } = props

          if (isCreditCardConciliationsTabPartial) {
            return css`
              width: 38.3rem;
              min-width: 38.3rem;
            `
          }

          if (isBankConciliationsTabPartial) {
            return css`
              width: 42.3rem;
              min-width: 42.3rem;
            `
          }

          if (isCreditCardConciliationsTabConciliation) {
            return css`
              width: 33.8rem;
              min-width: 33.8rem;
            `
          }

          if (isBankConciliationsTabConciliation) {
            return css`
              width: 42.2rem;
              min-width: 42.2rem;
            `
          }
        }}

      }
    }
  }

  .table-body-empty {
    background-color: var(--clr-white);
  }
`

export const NotConciliatedListContainer = styled(ListContainer)`
  .wrapper_tables {
    display: flex;
    gap: 1rem;
  }

  .safe_overflow__container {
    width: 100%;
  }

  .content_table__titles {
    p {
      :first-of-type {

        ${({ isBankConciliationsTabNot }) => {
          if (isBankConciliationsTabNot) {
            return css`
              width: 42rem;
              min-width: 42rem;
            `
          }

          if (!isBankConciliationsTabNot) {
            return css`
              width: 39.3rem;
              min-width: 39.3rem;
              padding-left: 4.4rem;
            `
          }
        }}
      }
    }
  }

  td {
    &:nth-of-type(2):not(td td),
    &:nth-of-type(4):not(td td) {
      padding: 1.25rem 0.625rem;
    }
    vertical-align: middle;
  }
`
