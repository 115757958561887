import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback
} from 'react'
import { UserStore } from 'Core/Store/User'

interface UserContextData {
  user: typeof UserStore.user
  authKey: string
  updateAuthKey: (newAuthKey: string) => void
  hasAffiliateProfile: boolean
}

export const UserContext = createContext<UserContextData>({
  user: UserStore.user,
  authKey: UserStore.authKey,
  updateAuthKey: () => {},
  hasAffiliateProfile: false
})

export const useUser = (): UserContextData => useContext(UserContext)

export const UserProvider = (props) => {
  const [user] = useState(() => UserStore.user)
  const [authKey, setAuthKey] = useState(() => UserStore.authKey)
  const hasAffiliateProfile = useMemo(
    () => Boolean(user?.affiliates_info?.length),
    [user]
  )

  const updateAuthKey = useCallback((newAuthKey: string) => {
    UserStore.authKey = newAuthKey
    setAuthKey(newAuthKey)
  }, [])

  const providerData = useMemo(() => ({
    user,
    authKey,
    updateAuthKey,
    hasAffiliateProfile
  }), [user, authKey, updateAuthKey, hasAffiliateProfile])

  return (
    <UserContext.Provider value={providerData}>
      {props.children}
    </UserContext.Provider>
  )
}
