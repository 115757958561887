import { SafeDate } from '@binamik/js-providers'
import { GetBankTransfersParams, PatchBankTransferPayload, PostBankTransferPayload } from 'Core/Types/BankTransfer'
import { bankTransfersStatusOptions } from 'Domains/BankAction/BankTransfers/Constants/bankTransfersStatusOptions'
import { unformatMoney } from '@bonitour/common-functions'

export const BankTransfersModel = {
  getBankTransfers: (params: GetBankTransfersParams) => {
    const {
      startDate,
      endDate,
      status,
      originBankAccountId,
      destinationBankAccountId
    } = params?.filters ?? {}

    const {
      currentPage,
      perPage
    } = params?.pagination ?? {}

    const safeStartDate = startDate && new SafeDate(startDate).isoDate
    const safeEndDate = endDate && new SafeDate(endDate).isoDate

    const newParams = {
      start_date: safeStartDate,
      end_date: safeEndDate,
      status,
      origin_bank_account_id: originBankAccountId || undefined,
      destination_bank_account_id: destinationBankAccountId || undefined,
      page: currentPage,
      per_page: perPage
    }

    const containAll = bankTransfersStatusOptions.every((val) =>
      status?.includes(val)
    )

    if (containAll) {
      delete newParams.status
    }

    return newParams
  },
  bankTransferPayload: (bankTransferPayload: PostBankTransferPayload | PatchBankTransferPayload) => {
    const {
      date,
      destinationBankAccountId,
      observation,
      originBankAccountId,
      status,
      statusRequired,
      taxe,
      transferMethod,
      value
    } = bankTransferPayload ?? {}

    const safeDate = date && new SafeDate(date).isoDate

    return {
      date: safeDate,
      destination_bank_account_id: destinationBankAccountId,
      observation: observation || undefined,
      origin_bank_account_id: originBankAccountId,
      status: statusRequired || status,
      taxes: unformatMoney(taxe),
      transfer_method: transferMethod,
      value: unformatMoney(value)
    }
  },
  postBankTransfer: (bankTransferPayload: PostBankTransferPayload) => {
    return BankTransfersModel.bankTransferPayload(bankTransferPayload)
  },
  patchBankTransfer: (bankTransferPayload: PatchBankTransferPayload) => {
    return BankTransfersModel.bankTransferPayload(bankTransferPayload)
  }
}
