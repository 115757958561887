/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { ArrowLeftIcon } from '@bonitour/components'
import {
  containerTitle,
  headerTitle, subtitleStyle, iconHeaderTitle, containerHeaderTitle, generalContent, dashboardShortcut
} from './PageTitle.styles'

export const PageTitle = (
  {
    title = '',
    subtitle = '',
    onClickReturn = undefined,
    customCss = []
  }
) => {
  const { push } = useHistory()

  const redirectToDashboard = () => push('/dashboard')

  return (
    <div css={generalContent}>
      <small onClick={redirectToDashboard} css={dashboardShortcut}>Dashboard</small>
      <div css={[containerTitle,
        ...customCss]}
      >
        <div css={containerHeaderTitle}>
          <ArrowLeftIcon onClick={onClickReturn || redirectToDashboard} css={iconHeaderTitle} />
          <span css={headerTitle}>{title}</span>
          {subtitle && <span css={subtitleStyle}>{subtitle}</span>}
        </div>
      </div>
    </div>
  )
}
