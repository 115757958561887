import { useMemo } from 'react'
import { useForm } from '@bonitour/app-functions'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import {
  forgottenExpenses,
  forgottenRevenues,
  maxRecurrences,
  expenseTradeId,
  getSchema
} from '../../Form/__ConfigurationsBaseInputs'
import { ConfigurationsInputsTranslator } from '../../i18n/ConfigurationsInputsI18n'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'
import { ConfigurationParsed } from 'Core/Types/Configuration'

interface ConfigurationInputs {
  configurationData: ConfigurationParsed,
  expenseRevenuesOptions: { label: string, value: string }[]
}

export const useConfigurationInputs = (
  {
    configurationData,
    expenseRevenuesOptions
  }: ConfigurationInputs
) => {
  const inputsVals = useTranslatedInputs([
    forgottenExpenses,
    forgottenRevenues,
    maxRecurrences,
    expenseTradeId(expenseRevenuesOptions)
  ], ConfigurationsInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsVals),
    [inputsVals]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(configurationData, schema)

  const formErrorsTranslated = useTranslatedFormErrors(
    errors, ConfigurationsInputsTranslator
  )

  return {
    errors: formErrorsTranslated,
    form,
    onInputChange,
    onInputBlur,
    inputsValid: inputsVals,
    onSubmit
  }
}
