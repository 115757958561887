export const OptionButtonAnimation = {
  initial: {
    transform: 'scale(0)',
    maxHeight: 0,
    opacity: 0,
    padding: 0
  },
  animate: {
    transform: 'scale(1)',
    maxHeight: '3rem',
    padding: '0.5rem',
    opacity: 1
  },
  exit: {
    transform: 'scale(0)',
    maxHeight: 0,
    opacity: 0,
    padding: 0
  },
  transition: {
    default: { ease: 'easeInOut' }
  }
}
