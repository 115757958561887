import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const generalContent = css`
  width: 100%;
  margin-top: 1em;
`

export const dashboardShortcut = css`
  font-size: 12px;
  color: ${colors.gray5};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const subtitleStyle = css`
  color: ${colors.primary};
  margin-left: 5px;
  font-size: 22px;
`

export const containerTitle = css`
  display: flex;
  margin: 26px 0px 28px 0;
`

export const containerHeaderTitle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const headerTitle = css`
  font-weight: 700;
  color: ${colors.gray1};
  font-size: 22px;
`

export const iconHeaderTitle = css`
  font-size: 26px;
  cursor: pointer;
  margin-right: 10px;
  color: ${colors.gray1};
`
