
import { SafeDate } from '@binamik/js-providers'
import { Configuration } from 'Core/Types/Configuration'
import { AuditParser } from './audit'

export const ConfigurationsParser = {
  configurationSingle: (configuration: Configuration) => {
    const {
      configuration: {
        id,
        user_name,
        user_id,
        configs: {
          max_recurrences,
          forgotten_expenses,
          forgotten_revenues,
          expense_trade_id
        },
        updated_at
      }
    } = configuration ?? {}

    return {
      id,
      userName: user_name,
      userId: user_id,
      updatedAt: updated_at ? new SafeDate(updated_at).format('DD/MM/YYYY') : '',
      maxRecurrences: max_recurrences,
      forgottenExpenses: forgotten_expenses,
      forgottenRevenues: forgotten_revenues,
      expenseTradeId: expense_trade_id
    }
  },
  getConfigurationAudits: (data) => {
    return AuditParser.getAudits(
      { data, fieldsToFilter: ['expense_trade_id'] }
    )
  }
}
