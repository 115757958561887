import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const UploadReceiptsContainer = styled.div`
  grid-column: span 2;

  .upload_btn__title {
    margin: 0.3125rem;
    font-weight: 700;
    color: var(--clr-gray2);
    font-size: 0.875rem;
  }
`

export const customUploadDropzone = css`
 border: none;
`

export const UploadContent = styled.div`
  max-height: 15.625rem;
  overflow-y: auto;
  border: 1px dashed var(--clr-gray7);
  border-radius: 0.625rem;
`
