/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { ResponsiveImage, CircleThumbnail, flexColumnCentered, flexColumn, colors } from '@bonitour/components'
import { useUser } from 'Shared/contexts/User'
import { Shortcuts } from './Shortcuts'
import { getFirstLetter } from 'Shared/utils/getFirstLetter'
import { useCompany } from 'Shared/contexts/Company'

const marginBottom20 = css`
  margin-bottom: 20px;
`

const container = css`
  min-width: 180px;
  padding: 10px;
`

const imageContainer = css`
  width: 60px;
  height: 60px;
  background-color: ${colors.gray12};
  font-size: 32px;
  font-weight: bold;
  text-transform: capitalize;
  color: ${colors.gray4};
`

const nameStyle = css`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.gray3};
  margin-top: 10px;
`

const emailStyle = css`
  margin-top: 5px;
  color: ${colors.gray5};
`

const shortcutLabel = css`
  font-size: 12px;
  color: ${colors.gray5};
`

const flexStart = css`
  align-items: flex-start;
`

const profileModeStyle = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-top: 5px;
  font-weight: 500;
`

const profileCompanyStyle = css`
  ${profileModeStyle}
  color: ${colors.gray4};
  font-weight: 700;
  font-size: 14px;
  margin-top: 15px;
  max-width: 220px;
`

export const UserOptions = () => {
  const { user } = useUser()
  const { name, avatar, email } = user
  const { company } = useCompany()

  return (
    <div css={container}>
      <div css={[flexColumnCentered, marginBottom20]}>
        <CircleThumbnail size={60} css={imageContainer}>
          {avatar ? <ResponsiveImage src={avatar} alt={`Foto de ${name}`} /> : getFirstLetter(name)}
        </CircleThumbnail>
        <p css={nameStyle}>{name}</p>
        <p css={emailStyle}>{email}</p>
        {company?.id && (
          <>
            <p css={profileCompanyStyle}>{company.name}</p>
            <p css={profileModeStyle}>Perfil de reservas</p>
          </>
        )}
      </div>
      <div css={[flexColumn, flexStart]}>
        <span css={shortcutLabel}>Atalhos</span>
        <Shortcuts />
      </div>
    </div>
  )
}
