
export const conciliationsAccountMovementsWidths = ['5.95rem', '15rem', '8.5rem']

export const conciliationsBankStatementsColumnsWidths = ['5.95rem', '13.3rem', '10rem', '8.5rem']

export const partialConciliationsBankStatementsColumnsWidths = ['5.95rem', '9.375rem', '8rem', '8.5rem']

export const partialConciliationsAccountMovementsWidths = ['5.95rem', '15rem', '8.5rem']

export const notConciliationsBankStatementsColumnsWidths = ['5.95rem', '10.25rem', '7.5rem', '8.5rem']

export const notConciliationsAccountMovementsColumnsWidths = ['5.95rem', '17.6rem', '8.5rem']
