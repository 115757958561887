import { formatMoney } from '@binamik/js-functions'
import { SafeDate } from '@binamik/js-providers'
import { centsForReais } from 'Shared/utils/number/centsForReais'
import { parseTemplateString, pluralize } from './dictionaryUtils'

/**
 * @typedef ApiCodesTranslationsProps
 * @prop { Record<string, string> } codesMapped
 * @prop { string } apiCode
 * @prop { boolean } [isToLowerCase]
 * @prop { string | boolean | number | Record<string, string>} [value]
*/

/** @type { function(ApiCodesTranslationsProps): string } */
export const apiCodesTranslations = (
  { apiCode = '', codesMapped = {}, isToLowerCase = true, value = undefined }
) => {
  const apiCodeLowerCase = typeof apiCode === 'string' ? apiCode.toLowerCase() : ''
  const newApiCode = isToLowerCase ? apiCodeLowerCase : apiCode

  if (value !== undefined) {
    const mappedValue = (codesMapped[newApiCode]?.mappedValue?.(value) ?? codesMapped[value]) || value
    return mappedValue
  }

  const newValue = codesMapped[newApiCode]?.label || codesMapped[newApiCode] || newApiCode
  return newValue
}

export const mappedValueBool = (value, dictionary) => {
  return value ? dictionary?.common_yes_label : dictionary?.common_no_label
}

export const mappedValueMoney = (value, isConvertCentsToReais = true) => {
  if (!value) return value

  const numberValue = Number(value)
  let newValue = numberValue
  if (isConvertCentsToReais) {
    newValue = centsForReais(value)
  }

  return formatMoney(newValue)
}

export const mappedValueSafeDate = (value, dictionary) => {
  return value ? new SafeDate(value).format(dictionary?.common_dateFormat_template) : '-'
}

export const templatePluralizeWithTemplateString = ({ qty, dictKey, keyReplaced, locale }) => {
  const templateString = pluralize(
    dictKey,
    qty,
    locale
  )
  return parseTemplateString(templateString, {
    [keyReplaced]: qty
  })
}
