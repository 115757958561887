/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useCallback, useMemo } from 'react'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'
import { MenuTabsPanel } from 'Shared/components/MenuTabs/MenuTabsPanel'
import { useBankAccountsOptions } from 'Domains/BankAction/BankAccounts/hooks/useBankAccountsOptions'
import { useTranslator } from 'Shared/language/i18nHandler'
import {
  conciliationsAccountMovementsWidths,
  conciliationsBankStatementsColumnsWidths,
  notConciliationsAccountMovementsColumnsWidths,
  notConciliationsBankStatementsColumnsWidths,
  partialConciliationsAccountMovementsWidths,
  partialConciliationsBankStatementsColumnsWidths
} from './constants/bankConciliations'
import { ConciliationsTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsI18n'
import { NotConciliationList } from 'Shared/components/Conciliations/List/NotConciliationList'
import { ConciliationList } from 'Shared/components/Conciliations/List/ConciliationList'
import { ConciliationsFilters } from 'Shared/components/Conciliations/Form/ConciliationsFilters'
import { useCreateAutoBankConciliation } from './hooks/useCreateAutoBankConciliation'
import { useCreateManualBankConciliation } from './hooks/useCreateManualBankConciliation'
import { useListBankConciliations } from './hooks/useListBankConciliations'
import { useDeleteBankConciliations } from './hooks/useDeleteBankConciliations'
import { useHistory } from 'react-router-dom'

export const BankConciliations = () => {
  useFlaggedRoute('billing-bank-conciliations')

  const {
    handleFilters,
    conciliationsData,
    notConciliationsData,
    filters,
    handleOnConciliation,
    partialConciliations,
    isLoadingBankConciliations,
    fetchBankConciliations,
    conciliationsItems,
    notConciliationsItems,
    partialConciliationsItems
  } = useListBankConciliations()

  const {
    bankAccountsOptions
  } = useBankAccountsOptions()

  const i18n = useTranslator(ConciliationsTranslator)

  const {
    createAutoBankConciliation,
    isLoadingAutoBankConciliation
  } = useCreateAutoBankConciliation(fetchBankConciliations)

  const {
    createManualBankConciliation,
    isLoadingManualBankConciliation
  } = useCreateManualBankConciliation()

  const {
    deleteBankConciliations,
    isLoadingDeleteBankConciliations
  } = useDeleteBankConciliations(fetchBankConciliations)

  const tabs = useMemo(() => {
    return [
      {
        id: 'not-conciliations',
        component: (
          <NotConciliationList
            internalMovements={notConciliationsData?.accountMovements}
            externalMovements={notConciliationsData?.bankStatements}
            options={
              {
                isLoading: isLoadingBankConciliations,
                isLoadingAutoConciliation: isLoadingAutoBankConciliation,
                isLoadingManualConciliation: isLoadingManualBankConciliation,
                isBankConciliationsTabNot: true,
                tableTitleExternalMovements: i18n?.tableTitleImportedBankPostings,
                internalMovementsColumnsTitles: i18n?.tableAccountMovementsColumnsTitles,
                internalMovementsColumnsWidths: notConciliationsAccountMovementsColumnsWidths,
                externalMovementsColumnsTitles: i18n?.tableBankStatementsColumnsTitles,
                externalMovementsColumnsWidths: notConciliationsBankStatementsColumnsWidths
              }
            }
            filters={filters}
            handleOnConciliation={handleOnConciliation}
            createAutoConciliation={createAutoBankConciliation}
            createManualConciliation={createManualBankConciliation}
          />
        ),
        label: i18n?.tabsTitleNotConciliations,
        items: notConciliationsItems
      },
      {
        id: 'partial-conciliations',
        component: (
          <ConciliationList
            conciliationsData={partialConciliations}
            filters={filters}
            handleOnConciliation={handleOnConciliation}
            options={
              {
                isLoading: isLoadingBankConciliations,
                isLoadingDeleteConciliations: isLoadingDeleteBankConciliations,
                isBankConciliationsTabPartial: true,
                tableTitleExternalMovements: i18n?.tableTitleImportedBankPostings,
                internalMovementsColumnsTitles: i18n?.tableAccountMovementsColumnsTitles,
                internalMovementsColumnsWidths: partialConciliationsAccountMovementsWidths,
                externalMovementsColumnsTitles: i18n?.tableBankStatementsColumnsTitles,
                externalMovementsColumnsWidths: partialConciliationsBankStatementsColumnsWidths
              }
            }
            deleteConciliations={deleteBankConciliations}
          />
        ),
        label: i18n?.tabsTitlePartiallyConciliations,
        items: partialConciliationsItems
      },
      {
        id: 'conciliations',
        component: (
          <ConciliationList
            conciliationsData={conciliationsData}
            filters={filters}
            handleOnConciliation={handleOnConciliation}
            options={
              {
                isLoading: isLoadingBankConciliations,
                isLoadingDeleteConciliations: isLoadingDeleteBankConciliations,
                isBankConciliationsTabConciliation: true,
                tableTitleExternalMovements: i18n?.tableTitleImportedBankPostings,
                internalMovementsColumnsTitles: i18n?.tableAccountMovementsColumnsTitles,
                internalMovementsColumnsWidths: conciliationsAccountMovementsWidths,
                externalMovementsColumnsTitles: i18n?.tableBankStatementsColumnsTitles,
                externalMovementsColumnsWidths: conciliationsBankStatementsColumnsWidths
              }
            }
            deleteConciliations={deleteBankConciliations}
          />
        ),
        label: i18n?.tabsTitleConciliations,
        items: conciliationsItems
      }
    ]
  }, [
    notConciliationsData?.accountMovements,
    notConciliationsData?.bankStatements,
    isLoadingBankConciliations,
    isLoadingAutoBankConciliation,
    isLoadingManualBankConciliation,
    i18n?.tableTitleImportedBankPostings,
    i18n?.tableAccountMovementsColumnsTitles,
    i18n?.tableBankStatementsColumnsTitles,
    i18n?.tabsTitleNotConciliations,
    i18n?.tabsTitlePartiallyConciliations,
    i18n?.tabsTitleConciliations,
    filters,
    handleOnConciliation,
    createAutoBankConciliation,
    createManualBankConciliation,
    partialConciliations,
    isLoadingDeleteBankConciliations,
    deleteBankConciliations,
    conciliationsData,
    conciliationsItems,
    partialConciliationsItems,
    notConciliationsItems
  ])

  const { push } = useHistory()
  const onReturnToDashboard = useCallback(() => push('/bank-action'), [push])

  return (
    <>
      <PageTitle
        title={i18n?.pageTitleBankConciliations}
        onClickReturn={onReturnToDashboard}
      />
      <ConciliationsFilters
        onFilterSubmit={handleFilters}
        bankAccountsOptions={bankAccountsOptions}
        filters={filters}
        isLoading={isLoadingBankConciliations}
        buttonLabel={i18n?.listButton}
        isBankConciliations
      />
      <MenuTabsPanel
        tabs={tabs}
        initTab='not-conciliations'
      />
    </>
  )
}
