/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ButtonUpload, ModalContent, ModalUploadBg, customUploadInline } from './AttachementButton.styles'
import { useCallback, useState } from 'react'
import { Portal } from '../Portal/Portal'
import { CloseIcon, Upload, UploadIcon, useToast } from '@bonitour/components'

export const AttachementButton = ({ file, onChange, accept, className }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const toggleModal = useCallback(() => setIsOpenModal(isOpen => !isOpen), [])
  const { add: addToast } = useToast()

  const handleChangeParams = useCallback((value) => {
    if (value?.length) {
      setIsOpenModal(false)
    }

    onChange(value)
  }, [onChange])

  return (
    <>
      <ButtonUpload
        type='button'
        onClick={toggleModal}
        className={
          `${className} ${file?.length ? 'is__file' : ''}`
        }
      >
        <UploadIcon /> <span>Anexar documento</span>
      </ButtonUpload>
      {isOpenModal && (
        <Portal selector='__MODALS_PORTAL__'>
          <ModalContent>
            <ModalUploadBg
              type='button' onClick={toggleModal}
            />
            <div className='modal__card'>
              <div className='modal__info'>
                <button
                  type='button'
                  onClick={toggleModal}
                  className='close-modal-button'
                >
                  <CloseIcon />
                </button>
                <div className='modal__info_title'>
                  <b>Anexar documento</b>
                </div>
              </div>
              <Upload
                files={file}
                onChange={handleChangeParams}
                onError={(e) => addToast(e)}
                css={customUploadInline}
                accept={accept}
              />
            </div>
          </ModalContent>
        </Portal>
      )}
    </>
  )
}
