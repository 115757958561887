
import { SafeDate } from '@binamik/js-providers'
import { BankTransfer, GetBankTransfersRes } from 'Core/Types/BankTransfer'
import { AuditParser } from './audit'

export const BankTransfersParser = {
  bankTransferSingle: (bankTransfer: BankTransfer) => {
    const {
      id,
      destination_bank_account_name,
      origin_bank_account_name,
      date,
      value,
      status,
      origin_bank_account_id,
      destination_bank_account_id,
      transfer_method,
      user_name,
      user_id,
      observation,
      taxes,
      created_at
    } = bankTransfer

    const safeDate = date && new SafeDate(date)
    const safeCreatedAt = created_at && new SafeDate(created_at)

    return {
      id,
      destinationBankAccountName: destination_bank_account_name,
      originBankAccountName: origin_bank_account_name,
      date: safeDate,
      value,
      status,
      statusRequired: status,
      userName: user_name,
      originBankAccountId: origin_bank_account_id,
      destinationBankAccountId: destination_bank_account_id,
      transferMethod: transfer_method,
      userId: user_id,
      taxe: taxes,
      observation,
      createdAt: safeCreatedAt
    }
  },
  getBankTransfers: ({ bank_transfers, meta }: GetBankTransfersRes) => {
    return {
      bankTransfers: bank_transfers?.map(BankTransfersParser.bankTransferSingle),
      meta: {
        total: meta?.count,
        totalPages: meta?.last
      }
    }
  },
  getBankTransfer: ({ bank_transfer }) => {
    const {
      date,
      createdAt,
      ...bankTransfer
    } = BankTransfersParser.bankTransferSingle(bank_transfer)

    return {
      ...bankTransfer,
      date: date.isoDate,
      createdAt: createdAt.isoDate
    }
  },
  getBankTransferAudits: ({ data }) => {
    return AuditParser.getAudits({ data })
  }
}
