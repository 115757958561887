import { useEffect, useMemo } from 'react'

import {
  date,
  referenceDate,
  expenseRevenues,
  taxes,
  value,
  observation,
  serviceName,
  status,
  bankAccounts,
  paymentMethods,
  receiptsRecurrency,
  userName,
  createdAt,
  suppliersWithExtraOptionNone,
  getSchema,
  paymentDay,
  recurrenceType,
  applyRecurrence,
  ocurrences
} from '../../../Form/__BaseInputs'
import { expRecurrencyInitStatusOptions } from 'Domains/ExpenseRevenue/constants/expenseRevenue'
import { useForm } from '@bonitour/app-functions'
import { useBankAccountsOptions } from 'Domains/BankAction/BankAccounts/hooks/useBankAccountsOptions'
import { ExpenseParsed } from 'Core/Types/Expense'
import { useExpenseAllowedStatus } from '../useExpenseAllowedStatus'
import { useSuppliersOptions } from 'Domains/ExpenseRevenue/Suppliers/hooks/useSupplierOptions'
import { useExpenseRevenuesOptions } from 'Domains/ExpenseRevenue/ExpenseRevenueType/hooks/useExpenseRevenuesOptions'
import { ExpensesInputsTranslator, ExpensesRecurrencyInputsTranslator } from '../../i18n/ExpensesInputsI18n'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { expensesStatus } from 'Domains/ExpenseRevenue/constants/expensesStatus'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'

const BASE_FORM_VAL = {}

interface UseExpenseInputsProps {
  currentFormData: ExpenseParsed
  expenseId: string
}

export const useExpenseRecurrencyInputs = (
  {
    currentFormData,
    expenseId
  }: UseExpenseInputsProps
) => {
  const {
    bankAccountsOptions,
    paymentMethodsOptions
  } = useBankAccountsOptions()

  const { fetchAllowedStatus, allowedStatusOptions } = useExpenseAllowedStatus()

  useEffect(() => {
    if (!expenseId) return

    fetchAllowedStatus(expenseId)
  }, [expenseId, fetchAllowedStatus])

  const { suppliersData } = useSuppliersOptions()

  const { expenseRevenuesOptions } = useExpenseRevenuesOptions(true)

  const statusOptions = useMemo(() => {
    return expenseId ? allowedStatusOptions : expRecurrencyInitStatusOptions
  }, [allowedStatusOptions, expenseId])

  const isEdit = useMemo(
    () => Boolean(currentFormData)
    , [currentFormData])

  const inputs = useTranslatedInputs(
    [
      date,
      referenceDate,
      suppliersWithExtraOptionNone(suppliersData),
      serviceName,
      expenseRevenues('', expenseRevenuesOptions),
      status(statusOptions),
      bankAccounts(bankAccountsOptions),
      paymentMethods,
      value,
      taxes,
      recurrenceType(isEdit),
      ocurrences,
      paymentDay,
      applyRecurrence(isEdit),
      userName,
      createdAt,
      observation,
      receiptsRecurrency(isEdit)
    ], ExpensesRecurrencyInputsTranslator
  )

  const schema = useMemo(
    () => getSchema(inputs),
    [inputs]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(currentFormData || BASE_FORM_VAL, schema)

  const newInputsValid = useMemo(() => inputs.filter(({ value }) => {
    switch (value) {
      case 'userName':
      case 'createdAt':
      case 'applyRecurrence':
        return (
          isEdit && [expensesStatus?.scheduled, expensesStatus?.canceled].includes(form.status)
        )
      case 'ocurrences':
      case 'recurrenceType':
        return !currentFormData
      case 'bankAccountId':
        return (form?.bankAccount || [expensesStatus?.payed].includes(form.status))
      case 'paymentMethod':
        return (form?.paymentMethod || [expensesStatus?.payed].includes(form.status))
      default:
        return true
    }
  }).map(input => {
    if (input?.value === 'paymentMethod') {
      input.options = paymentMethodsOptions(form?.bankAccountId)
    }

    return input
  }), [
    currentFormData,
    form?.bankAccount,
    form?.bankAccountId,
    form?.paymentMethod,
    form.status,
    inputs,
    isEdit,
    paymentMethodsOptions
  ])

  const formErrorsTranslteds = useTranslatedFormErrors(
    errors, ExpensesInputsTranslator
  )

  return {
    validationSchema: schema,
    inputsValid: newInputsValid,
    form,
    errors: formErrorsTranslteds,
    onSubmit,
    onInputChange,
    onInputBlur
  }
}
