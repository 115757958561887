
export const statusColors = {
  enabled: { color: '--clr-green1' },
  disabled: { color: '--clr-gray7' },
  '-': { color: '--clr-red1' }
}

export const statusOptions = [
  {
    value: 'enabled',
    label: 'Habilitado'
  },
  {
    value: 'disabled',
    label: 'Desabilitado'
  }
]

export const statusOptionsFilter = [
  { value: '', label: 'Todos' },
  { value: 'enabled', label: 'Habilitado' },
  { value: 'disabled', label: 'Desabilitado' }
]

export const comparisonTypeOptions = [
  { value: 'equals', label: 'Igual a' },
  { value: 'less_than', label: 'Menor que' },
  { value: 'greater_than', label: 'Maior que' },
  { value: 'in', label: 'Um de' },
  { value: 'not_equal', label: 'Diferente de' },
  { value: 'not_in', label: 'Nenhum de' }
]

export const operationTypeOptions = [
  { value: 'credit', label: 'Crédito' },
  { value: 'debit', label: 'Débito' }
]

export const automationRuleStatusOptions = [
  { value: 'waiting', label: 'Aguardando data do lançamento futuro' },
  { value: 'need_confirmation', label: 'Precisa de Confirmação' },
  { value: 'processed', label: 'Processado' },
  { value: 'billed', label: 'Faturado' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'done', label: 'Realizado' }
]

export const allowedFormulas = [
  {
    method: `installment_date(
      event_field_date,inc,event_field_installments,
      event_field_brand,event_field_gateway
    )`,
    desc: 'Cria as datas para as parcelas de acordo com as regras da bandeira do cartão de crédito',
    params: [
      { paramName: 'event_field_date', desc: '' },
      { paramName: 'inc', desc: '' },
      { paramName: 'event_field_brand', desc: '' }
    ]
  },
  {
    method: 'installment_value(gateway,event_field_value,inc,event_field_integer)',
    desc: 'Arredonda o valor da parcela de acordo com as regras de arredondamento do gateway (operadora)',
    params: [
      { paramName: 'gateway', desc: 'nome do campo com a operadora (cielo, braspag, etc)' },
      { paramName: 'event_field_value', desc: 'valor total da venda' },
      { paramName: 'inc', desc: 'campo de repetição' },
      { paramName: 'event_field_integer', desc: 'nome do campo com o número de parcelas' }
    ]
  },
  {
    method: 'installment_fee_value(gateway,event_field_value,inc,event_field_integer)',
    desc: 'Arredonda o valor da taxa da parcela de acordo com as regras de arredondamento do gateway (operadora)',
    params: [
      { paramName: 'gateway', desc: 'nome do campo com a operadora (cielo, braspag, etc)' },
      { paramName: 'event_field_value', desc: 'valor total da venda' },
      { paramName: 'inc', desc: 'campo de repetição' },
      { paramName: 'event_field_integer', desc: 'nome do campo com o número de parcelas' }
    ]
  },
  {
    method: 'get_bank_account(conta_bancaria_id)',
    desc: 'Retorna a Conta Bancária a ser movimentada do campo do evento',
    params: [
      { paramName: 'conta_bancaria_id', desc: '' }
    ]
  },
  {
    method: 'get_account_from_expense_revenue(expense_revenue_id)',
    desc: 'Retorna a conta associada ao plano de contas (se houver) do Tipo de Depsesa/Receita informado no campo do evento',
    params: [
      { paramName: 'expense_revenue_id', desc: '' }
    ]
  },
  {
    method: 'binamik_fee(operadora,valor_pagamento,parcelas,mdr,total_taxas_pay_mdr)',
    desc: 'Calcula o valor total da taxa da Binamik para a venda e/ou estorno',
    params: [
      { paramName: 'operadora', desc: '' },
      { paramName: 'valor_pagamento', desc: '' },
      { paramName: 'parcelas', desc: '' },
      { paramName: 'mdr', desc: '' },
      { paramName: 'total_taxas_pay_mdr', desc: '' }
    ]
  },
  {
    method: 'from_cents(value)',
    desc: 'Converte um valor em centavos para monetário (divide por 100), ex: from_cents(450) = 4.50',
    params: [
      { paramName: 'value', desc: '' }
    ]
  },
  {
    method: 'refund_date (cancelation_date,brand,gateway)',
    desc: `Calcula a data de reembolso (dia útil) de acordo com as regras
           da Operador e da bandeira do Cartão de Crédito.
           Esta fórmula somente pode ser usada nas ações 
           'Criar Lançamento Futuro via fórmula para reembolso em Cartão de Crédito' e
           'Acelerar parcelas para reembolso em Cartão de Crédito'.`,
    params: [
      { paramName: 'cancelation_date', desc: '' },
      { paramName: 'brand', desc: '' },
      { paramName: 'gateway' }
    ]
  },
  {
    method: 'to_date(event_field)',
    desc: 'Converte event_field para uma data, se possível',
    params: [
      { paramName: 'event_field', desc: '' }
    ]
  },
  {
    method: 'duration(1, month)',
    desc: 'Duração de 1 mês, considera o número de dias do mês',
    params: [
      { paramName: '1', desc: '' },
      { paramName: 'month', desc: '' }
    ]
  },
  {
    method: 'duration(26, days)',
    desc: 'Duração de 26 dias',
    params: [
      { paramName: '26', desc: '' },
      { paramName: 'month', desc: '' }
    ]
  },
  {
    method: 'next_business_days(to_date(event_field))',
    desc: 'Retorna o dia útil seguinte a data calculada, se esta não for um dia útil',
    params: [
      {
        method: 'to_date',
        desc: '',
        params: [
          { paramName: 'event_field', desc: '' }
        ]
      },
      {
        paramName: 'banana', desc: 'a'
      }
    ]
  },
  {
    method: 'next_business_days ( to_date(event_field) + duration(1, month) )',
    desc: 'Dia útil de: data em event_field mais um mês ',
    params: [
      {
        method: 'to_date',
        desc: '',
        params: [
          { paramName: 'event_field', desc: '' }
        ]
      },
      {
        method: 'duration',
        desc: '',
        params: [
          { paramName: 'month', desc: '' }
        ]
      }
    ]
  },
  {
    operation: 'event_field / event_field_2',
    desc: ''
  },
  {
    operation: 'event_field + event_field_2',
    desc: ''
  },
  {
    operation: 'event_field - event_field_2',
    desc: ''
  },
  {
    operation: 'event_field * event_field_2',
    desc: ''
  },
  {
    method: 'to_date(date) + duration(inc, month)',
    desc: '',
    params: [
      {
        method: 'to_date',
        desc: '',
        params: [
          { paramName: 'date', desc: '' }
        ]
      },
      {
        method: 'duration',
        desc: '',
        params: [
          { paramName: 'inc', desc: '' },
          { paramName: 'month', desc: '' }
        ]
      }
    ]
  },
  {
    operation: 'if (inc < event_field, event_field_2, round(event_field_2))',
    desc: 'SE inc < event_field a fórmula seguinte retorna event_field_2 SENÃO retorna round(event_field_2)'
  },
  {
    method: 'round(event_field)',
    desc: 'Arredonda o valor de event_field ',
    params: [
      { paramName: 'event_field', desc: '' }
    ]
  },
  {
    method: 'round(event_field,2)',
    desc: 'Arredodamento com 2 casas decimais '
  },
  {
    method: 'roundup(event_field)',
    desc: 'Arrendoda para cima (próximo inteiro)',
    params: [
      { paramName: 'event_field', desc: '' }
    ]
  },
  {
    method: 'rounddown(event_field)',
    desc: 'Arredonda para baixo (inteiro anterior)',
    params: [
      { paramName: 'event_field', desc: '' }
    ]
  }
]

export const automationRulesTableColumns = [
  { key: 'action' },
  { key: 'operationType', className: 'operation_type' },
  { key: 'value' },
  { key: 'date' },
  { key: 'destinationAccount' },
  { key: 'actions' }
]
