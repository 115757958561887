import { billingCore } from './Billing'
import { RevenueModel } from '../Models/revenue'
import { RevenueParser } from 'Core/Parser/revenue'
import { convertToFormData } from '@bonitour/common-functions'

export const RevenueService = {
  list ({ filters, pagination }) {
    return billingCore.revenueDomain.list(RevenueModel.list({ filters, pagination })).then(RevenueParser.list)
  },
  create (revenue) {
    const payload = RevenueModel.create(revenue)
    return billingCore.revenueDomain.create(convertToFormData(payload))
  },
  getById (revenueId) {
    return billingCore.revenueDomain.get(revenueId).then(RevenueParser.singleRevenue)
  },
  allowedStatus (revenueId) {
    return billingCore.revenueDomain.getAllowedStatus(revenueId)
  },
  edit (revenue, revenueId) {
    return billingCore.revenueDomain.update(RevenueModel.update(revenue), revenueId)
  },
  getRevenuesForgotten () {
    return billingCore.revenueDomain.getRevenuesForgotten().then(
      RevenueParser.getRevenuesForgotten
    )
  },
  getRevenueAudits (revenueId) {
    return billingCore.revenueDomain.getRevenueAudits(
      {}, revenueId).then(RevenueParser.getRevenueAudits)
  }
}
