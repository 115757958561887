import { formatMoney } from '@bonitour/common-functions'
import { MetaParser } from './Meta'
import dayjs from 'dayjs'
import { AuditParser } from './audit'

export const RevenueParser = {
  single: (revenue) => {
    const {
      id,
      date,
      value,
      expense_revenue,
      status,
      created_at,
      service_name,
      user_name
    } = revenue?.revenue ?? revenue ?? {}
    return {
      id,
      date,
      value,
      expenseRevenue: expense_revenue,
      status,
      createdAt: created_at,
      serviceName: service_name,
      userName: user_name
    }
  },
  list: ({ meta, revenues = [] }) => {
    return {
      revenues: revenues.map(RevenueParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  singleRevenue: ({ revenue }) => {
    const {
      id,
      date,
      reference_date,
      expense_revenue_id,
      value,
      payment_method,
      taxes,
      status,
      observation,
      user_id,
      service_name,
      bank_account_id,
      user_name,
      created_at
    } = revenue

    return {
      id,
      date,
      referenceDate: reference_date,
      expenseRevenueId: expense_revenue_id,
      value: formatMoney(value),
      paymentMethod: payment_method || '',
      taxes,
      status,
      observation: observation || undefined,
      userId: user_id,
      serviceName: service_name,
      bankAccountId: bank_account_id || '',
      userName: user_name || '',
      createdAt: dayjs(created_at).format('YYYY-MM-DD') || ''
    }
  },
  getRevenueAudits: (data) => {
    return AuditParser.getAudits({ data })
  },
  getRevenuesForgotten: (revenueForgottenData) => {
    const {
      forgotten,
      days_ago
    } = revenueForgottenData ?? {}

    return {
      forgotten: forgotten || 0,
      daysAgo: days_ago || 0
    }
  }
}
