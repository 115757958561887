import { eventsTypes } from '../Constants/Events'

export const EventsHooksTranslator = (
  dictionary,
  _locale
) => {
  const errorFetchingEvents = dictionary?.event_errorFetchingEvents_toast
  const errorFecthingEventsTypes = dictionary?.event_errorFecthingEventsTypes_toast

  const eventsTypesLabels = {
    '': dictionary?.common_all_label,
    ...eventsTypes(dictionary)
  }

  const errorFechtingEventRequestDetails = dictionary?.event_errorFechtingEventRequestDetails_toast
  const errorFetchingEventFieldNames = dictionary?.event_errorFetchingEventFieldNames_toast

  return {
    errorFetchingEvents,
    errorFecthingEventsTypes,
    eventsTypesLabels,
    errorFechtingEventRequestDetails,
    errorFetchingEventFieldNames
  }
}
