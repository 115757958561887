
import { SafeDate } from '@binamik/js-providers'

export const CreditCardConciliationModel = {
  creditCardConciliationParamsParse: (params) => {
    const {
      startDate,
      endDate,
      acquirer
    } = params ?? {}
    const safeStartDate = startDate ? new SafeDate(startDate).format('YYYY-MM-DD') : undefined
    const safeEndDate = endDate ? new SafeDate(endDate).format('YYYY-MM-DD') : undefined

    return {
      start_date: safeStartDate,
      end_date: safeEndDate,
      conciliation_type: 'GatewayPayment',
      conciliate_with: 'cash_flow',
      gateway: acquirer
    }
  },
  getCreditCardConciliations: (filters) => {
    return CreditCardConciliationModel.creditCardConciliationParamsParse(
      filters
    )
  },
  postAutoCreditCardConciliation: (params) => {
    return CreditCardConciliationModel.creditCardConciliationParamsParse(params)
  },
  postManualCreditCardConciliation: (params) => {
    return {
      ...CreditCardConciliationModel.creditCardConciliationParamsParse(params),
      start_date: undefined,
      end_date: undefined,
      gateway_payment_ids: params?.externalMovementsIds,
      account_forecast_ids: params?.internalMovementsIds
    }
  },
  deleteCreditCardConciliations: (params) => {
    return {
      ...CreditCardConciliationModel.creditCardConciliationParamsParse(params),
      start_date: undefined,
      end_date: undefined,
      conciliation_ids: params?.conciliationsIds
    }
  }
}
