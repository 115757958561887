import styled from '@emotion/styled'

export const BankAccountContainer = styled.div`
  min-height: 21rem;
  max-width: 21rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;

  .form__inputs {
    display: grid;
    gap: 0.625rem;
    grid-template-columns: repeat(4, 1fr);
  }

  .form__actions {
    margin-top: 1.875rem;

    button {
      width: 12.5rem;
      &:first-of-type {
        margin-right: 1.25rem;
      }
    }
  }
  .input__accountable_plan {
    min-width: 30rem;
  }
`
