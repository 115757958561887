/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TooltipContainer, TooltipContent } from './styles'
import { useRef } from 'react'

export { displayTooltipOnHover } from './styles'

/**
 * @typedef TooltipProps
 * @prop { string } tooltipClass
 * @prop { boolean } [isActive]
 * @prop { 'top' | 'bottom' | 'center' } verticalPosition
 * @prop { 'left' | 'center' | 'right' } horizontalPosition
 * @prop { string } [width]
 */

/** @type { React.FC<TooltipProps> } */
export const Tooltip = ({
  children,
  tooltipClass,
  isActive = true,
  width,
  verticalPosition = 'bottom',
  horizontalPosition = 'right'
}) => {
  const tooltipRef = useRef(null)

  return (
    <TooltipContainer
      ref={tooltipRef}
      className={tooltipClass}
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
      isActive={isActive}
    >
      <TooltipContent
        className='tooltip-box'
        verticalPosition={verticalPosition}
        horizontalPosition={horizontalPosition}
        width={width}
      >
        {children}
      </TooltipContent>
    </TooltipContainer>
  )
}
