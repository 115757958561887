import { useCallback, useEffect, useRef, useState } from 'react'
import { EventService } from 'Core/Service/event'
import { useToast } from 'Shared/hooks/useToast'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsHooksTranslator } from '../i18n/EventsHooksI18n'

export const useListEvents = () => {
  const { addToast } = useToast()
  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [eventsDatas, setEventsDatas] = useState({
    events: [],
    meta: {}
  })

  const [eventsTypesDatas, setEventsTypesDatas] = useState([])

  const { initStatePagination, perPageOptions } = usePaginationOptions()

  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false)

  const initPagination = useRef(false)

  const [pagination, setPagination] = useState(initStatePagination)

  const i18n = useTranslator(EventsHooksTranslator)

  const fetchEvents = useCallback(() => {
    setIsLoading(true)
    return EventService.getEvents(filters, pagination).then(
      (data) => {
        setEventsDatas(data)
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorFetchingEvents
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }, [
    addToast,
    filters,
    i18n?.errorFetchingEvents,
    pagination
  ])

  const handleFilters = useCallback((filters) => {
    setIsUpdatingFilters(true)
    setPagination({
      currentPage: 1,
      perPage: pagination.perPage
    })
    setFilters(filters)
    setTimeout(() => setIsUpdatingFilters(false))
  }, [pagination.perPage])

  const handlePagination = useCallback((currentPage, perPage) => {
    if (!initPagination.current) {
      initPagination.current = true
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [])

  const fetchEventsTypes = useCallback(() => {
    return EventService.getEventsTypes(i18n?.eventsTypesLabels).then(
      (data) => {
        setEventsTypesDatas(data)
      }
    ).catch((errors) => {
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorFecthingEventsTypes
      })
    })
  }, [addToast, i18n?.errorFecthingEventsTypes, i18n?.eventsTypesLabels])

  useEffect(() => {
    !isUpdatingFilters && fetchEvents()
  }, [fetchEvents, isUpdatingFilters])

  useEffect(() => {
    fetchEventsTypes()
  }, [fetchEventsTypes])

  return {
    eventsDatas: eventsDatas?.events,
    meta: eventsDatas?.meta,
    eventsTypesDatas,
    isLoading,
    handleFilters,
    handlePagination,
    perPageOptions,
    filters,
    pagination
  }
}
