import { useState, useCallback } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { useUser } from 'Shared/contexts/User'
import { BankConciliationService } from 'Core/Service/bankConciliation'
import { ConciliationsHooksTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsHooksI18n'

export const useCreateManualBankConciliation = () => {
  const { addToast } = useToast()
  const [isLoadingManualBankConciliation, setIsLoadingManualBankConciliation] = useState(false)

  const i18n = useTranslator(ConciliationsHooksTranslator)
  const { user } = useUser()

  const createManualBankConciliation = useCallback((params) => {
    setIsLoadingManualBankConciliation(true)
    return BankConciliationService.postManualBankConciliation(params, user).then(
      (conciliated) => {
        addToast({
          defaultMessage: i18n?.manualConciliation,
          typeToast: 'success'
        })
        return conciliated
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.conciliationCreateManualError,
        dictTranslation: i18n?.apiCodesMappingForBankConciliation
      })
      return false
    }).finally(() => {
      setIsLoadingManualBankConciliation(false)
    })
  }, [addToast, i18n, user])

  return {
    isLoadingManualBankConciliation,
    createManualBankConciliation
  }
}
