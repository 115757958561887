import styled from '@emotion/styled'
import { displayTooltipOnHover } from '../Tooltip'
import { motion } from 'framer-motion'

export const FormInputsContainer = styled(motion.section)`

  .input_container__forgotten_expenses,
  .input_container__forgotten_revenues,
  .input_container__max_recurrences,
  .input_container__receipts {
    label {
      display: flex;
      align-items: center;
      gap: 0.3125rem;
    }

    .circle__info {
      display: flex;
      position: relative;

      i {
        color: var(--clr-gray6)
      }

      ${displayTooltipOnHover('tooltip__conciliated')}

      .tooltip__conciliated {
        width: 15rem;
        line-height: 1;
      }
    }
  }
`
