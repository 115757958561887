import React, { useCallback, useMemo } from 'react'
import { TableV2, TableRow, EditInfoIcon, EyeIcon, Checkbox } from '@bonitour/components'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { Audit } from 'Shared/components/Audit/Audit.jsx'
import { CircleStatus, ValueColor } from 'Shared/styles/global.js'
import { statusLabelColor } from 'Domains/ExpenseRevenue/constants/expenseRevenue.js'
import { useTranslator } from 'Shared/language/i18nHandler.js'
import { ExpensesTranslator } from '../i18n/ExpensesI18n'
import { SafeDate } from '@binamik/js-providers'
import { formatMoney } from '@bonitour/common-functions'
import { ExpenseParsed } from 'Core/Types/Expense.js'
import { useGetExpenseAudits } from '../hooks/useGetExpenseAudits'
import { DownloadReceipts } from 'Domains/ExpenseRevenue/DownloadReceipts/DownloadReceipts'
import { expenseTableV2Container } from '../Expenses.styles'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'Shared/hooks/useQuery'
import { expensesStatus } from 'Domains/ExpenseRevenue/constants/expensesStatus'
import { useTranslatedExpensesStatus } from '../i18n/hooks/useTranslatedExpensesStatus'

const {
  REACT_APP_ORB_URL
} = process.env

interface ExpensesListProps {
  expenses: ExpenseParsed[]
  loading: boolean
  expenseToUpdate: ExpenseParsed[]
  setExpenseToUpdate: React.Dispatch<React.SetStateAction<ExpenseParsed[]>>
}

const columnsWidths = [
  '4rem',
  '6.5rem',
  'minmax(5rem, 7rem)',
  'minmax(6.25rem, 8.75rem)',
  'minmax(10rem, 14rem)',
  '8rem',
  '6.5rem',
  'minmax(5rem, 7rem)',
  '7rem'
]

export const ExpensesList = ({
  expenses = [],
  loading = false,
  expenseToUpdate,
  setExpenseToUpdate
}: ExpensesListProps) => {
  const { push } = useHistory()
  const i18n = useTranslator(ExpensesTranslator)

  const { expensesStatusTranslator } = useTranslatedExpensesStatus()

  const [_queryParams, _fn, search] = useQuery()

  const {
    datas,
    loading: auditLoading,
    fetchListExpenseAudit
  } = useGetExpenseAudits()

  const redirectToEditExpenseOrExpenseRecurrency = useCallback(
    (expenseId: string, recurrenceId: string) => {
      if (recurrenceId) {
        return push(`/expense-revenue-dashboard/expenses-recurrency/edit/${expenseId}/${recurrenceId}${search}`)
      }
      return push(`/expense-revenue-dashboard/expenses/edit/${expenseId}${search}`)
    }, [push, search]
  )

  const redirectTo = useCallback(
    (expenseId: string) => {
      const queryParam = search ? `${search}&inputsReadingOnly=true` : '?inputsReadingOnly=true'

      return push(`/expense-revenue-dashboard/expenses/edit/${expenseId}${queryParam}`)
    }, [push, search]
  )

  const urlRedirectToInvoiceScreen = useCallback((expense: ExpenseParsed) => {
    const baseOrbUrl = REACT_APP_ORB_URL
    const { externalOriginType, invoiceId, date } = expense || {}

    const paths = {
      affiliated: `/invoices-batch/affiliate/${invoiceId}`,
      affiliated_transaction: `/invoices-batch/affiliate/${invoiceId}`,
      external_partner: `/invoices-batch/offline/${invoiceId}`,
      external_partner_transaction: `/invoices-batch/offline/${invoiceId}`,
      invoice_payment: `/invoices-pay/${invoiceId}/edit`,
      invoice: `/invoices-pay/${invoiceId}/edit`,
      guide: `/tour-guide-payment?selectedNav=generated-batches&expectedInitialPaymentDate=${date}&expectedFinalPaymentDate=${date}`
    }

    return baseOrbUrl + (paths[externalOriginType] || '')
  }, [])

  const paymentDate = useCallback((expenseDate: string) => {
    return new SafeDate(expenseDate).format(i18n?.dateFormat)
  }, [i18n?.dateFormat])

  const getActiveExpensesWithoutInvoice = useMemo(
    () => {
      return expenses?.filter(({ externalOriginId, invoiceId, status }) => {
        return !externalOriginId &&
               !invoiceId &&
               ![expensesStatus.canceled, expensesStatus.refunded].includes(status)
      })
    }
    , [expenses]
  )

  const handleOnSelectAllExpenses = useCallback(
    ev => {
      if (ev.target.checked) {
        return setExpenseToUpdate((currentValue) => {
          return Array.from(
            new Set([...currentValue, ...getActiveExpensesWithoutInvoice])
          )
        })
      } else {
        return setExpenseToUpdate(currentValue => {
          return currentValue.filter(({ id }) => !expenses?.some((expense) => expense?.id === id))
        })
      }
    },
    [expenses, getActiveExpensesWithoutInvoice, setExpenseToUpdate]
  )

  const isIdSelected = useCallback(
    (expenseId: string) => expenseToUpdate?.some(({ id }) => id === expenseId),
    [expenseToUpdate]
  )

  const allexpenseToUpdateSelected = useMemo(
    () => {
      return getActiveExpensesWithoutInvoice?.length && Boolean(
        getActiveExpensesWithoutInvoice?.every(({ id }) => isIdSelected(id))
      )
    }
    , [getActiveExpensesWithoutInvoice, isIdSelected]
  )

  const handleOnSelectExpense = useCallback((expense: ExpenseParsed) => {
    setExpenseToUpdate((currentExpense: ExpenseParsed[]) => {
      if (!currentExpense) return []

      if (isIdSelected(expense?.id)) {
        return currentExpense.filter(({ id }) => id !== expense?.id)
      } else {
        return [...currentExpense, expense]
      }
    })
  }, [isIdSelected, setExpenseToUpdate])

  const isExpenseInvoice = useMemo(
    () => {
      return expenses.every((
        { invoiceId }
      ) => invoiceId !== undefined && invoiceId !== null)
    },
    [expenses]
  )

  const labels = useMemo(() => {
    return [
        <Checkbox
          key='select-all'
          id='select-all'
          name='select-all'
          className='checkbox'
          onChange={(ev) => handleOnSelectAllExpenses(ev)}
          checked={allexpenseToUpdateSelected}
          disabled={isExpenseInvoice}
        />,
        ...i18n?.expensesColumnsTitles
    ]
  }, [
    allexpenseToUpdateSelected,
    handleOnSelectAllExpenses,
    i18n?.expensesColumnsTitles,
    isExpenseInvoice
  ])

  const isDisabledCheckbox = useCallback(
    (expense: ExpenseParsed) =>
      expense?.externalOriginId ||
      expense?.invoiceId ||
      [expensesStatus.canceled, expensesStatus.refunded].includes(expense?.status)
    , []
  )

  return (
    <TableV2
      isLoading={loading}
      labels={labels}
      columnsWidths={columnsWidths}
      mobileBreakpoint={1024}
      customCss={[expenseTableV2Container]}
    >
      {
        expenses?.map((expense) => (
          <TableRow
            key={expense?.id}
          >
            <Checkbox
              disabled={isDisabledCheckbox(expense)}
              className='checkbox'
              onChange={() => handleOnSelectExpense(expense)}
              checked={isIdSelected(expense?.id)}
            />

            <>{paymentDate(expense?.date)}</>

            <SafeOverflow text={expense?.supplierName} />

            <SafeOverflow
              text={expense?.expenseRevenue?.description || ''}
            />

            {expense?.externalOriginId
              ? (
                <a
                  href={
                    urlRedirectToInvoiceScreen(
                      expense
                    )
                  }
                  target='_blank'
                  rel="noreferrer"
                  className='link__service_name'
                >
                <SafeOverflow
                  text={
                    expense?.serviceName
                  }
                  className='safe_overflow__service_name'
                />
                </a>
                )
              : (
              <SafeOverflow
                text={
                  expense?.serviceName
                }
                className='safe_overflow__service_name'
              />
                )
            }
            <ValueColor statusColor={statusLabelColor[expense?.status]?.color}>
              {expense?.value ? formatMoney(expense?.value) : ''}
            </ValueColor>
            <CircleStatus statusColor={statusLabelColor[expense?.status]?.color}>
              {expensesStatusTranslator(expense?.status)}
            </CircleStatus>
            <SafeOverflow
              text={expense?.userName || ''}
              alignment='right'
              className='safe_overflow__use_name'
            />
            <div className='wrapper__btn_actions'>
              {expense?.externalOriginId
                ? (
                <EyeIcon
                  onClick={
                    () => redirectTo(expense?.id)
                  }
                  className={expense?.observation ? 'highlight__eye' : ''}
                />
                  )
                : (
                <EditInfoIcon
                  onClick={
                    () => redirectToEditExpenseOrExpenseRecurrency(expense?.id, expense?.recurrenceId)
                  }
                />
                  )
              }
              <DownloadReceipts receipts={expense?.receipts}/>
              <Audit
                documentId={expense?.id}
                auditDatas={datas}
                auditLoading={auditLoading}
                fetchAuditDatas={fetchListExpenseAudit}
                modalTitle={i18n.modalAuditExpenseTitle}
                i18n={i18n.auditLabels}
              />
            </div>
          </TableRow>
        ))
      }
    </TableV2>
  )
}
