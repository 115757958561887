import { useTranslatedBankTransfersStatus } from './hooks/useTranslatedBankTransfersStatus'

export interface BankTransfersTranslatorRes {
  bankTransfersColumnsTitles: string[]
  dateFormat: string
  bankTransfersStatusTranslator: (status: string) => string
  selectPeriodToSearch: string
  noTransfersFoundBetweenBankAccounts: string
  transferBetweenBankAccount: string
  searchButton: string
  newTransfer: string
  editBankTransfer: string
  registerBankTransfer: string
  changeHistoryTransferBetweenAccounts: string
  bankTransferAuditLabels: {
    destination_bank_account_name: string
    origin_bank_account_name: string
  }
}

export const BankTransfersTranslator = (
  dictionary
): BankTransfersTranslatorRes => {
  const bankTransfersColumnsTitles = [
    dictionary?.common_date_label,
    dictionary?.bank_transfer_originAccount_label,
    dictionary?.bank_transfer_destinationAccount_label,
    dictionary?.common_value_label,
    dictionary?.common_status_label,
    dictionary?.common_user_label,
    dictionary?.common_actions_label
  ]

  const dateFormat = dictionary?.common_dateFormat_template

  const { bankTransfersStatusTranslator } = useTranslatedBankTransfersStatus({ dictionary })

  const selectPeriodToSearch = dictionary?.common_selectPeriodToSearch_label
  const noTransfersFoundBetweenBankAccounts = dictionary?.bank_transfer_noTransfersFoundBetweenBankAccounts_label
  const transferBetweenBankAccount = dictionary?.bank_transfer_transferBetweenBankAccount_label
  const searchButton = dictionary?.common_searchButton_label
  const newTransfer = dictionary?.bank_transfer_newTransfer_label
  const editBankTransfer = dictionary?.bank_transfer_editBankTransfer_label
  const registerBankTransfer = dictionary?.bank_transfer_registerBankTransfer_label
  const changeHistoryTransferBetweenAccounts = dictionary?.bank_transfer_changeHistoryTransferBetweenAccounts_label

  const bankTransferAuditLabels = {
    destination_bank_account_name: dictionary?.audit_destinationAccount_label,
    origin_bank_account_name: dictionary?.audit_originAccount_label
  }

  return {
    bankTransfersColumnsTitles,
    dateFormat,
    bankTransfersStatusTranslator,
    selectPeriodToSearch,
    noTransfersFoundBetweenBankAccounts,
    transferBetweenBankAccount,
    searchButton,
    newTransfer,
    editBankTransfer,
    registerBankTransfer,
    changeHistoryTransferBetweenAccounts,
    bankTransferAuditLabels
  }
}
