import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'
import { ConditionService } from 'Core/Service/condition'

export const useUpdateCondition = () => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const updateCondition = useCallback(
    (
      payload, eventProcessorId, conditionId
    ) => {
      setLoading(true)
      return ConditionService.updateCondition(payload, eventProcessorId, conditionId)
        .then(() => {
          addToast({
            typeToast: 'success',
            defaultMessage: i18n?.conditionSuccessUpdated
          })
        })
        .catch((errors) => {
          console.error(errors?.data)
          addToast({

            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorUpdatingCondition
          })
          throw errors
        })
        .finally(() => setLoading(false))
    }, [addToast, i18n?.conditionSuccessUpdated, i18n?.errorUpdatingCondition])

  return {
    updateCondition,
    loading
  }
}
