
export const ExpensesRevenuesTypesHooksTranslator = (
  dictionary
) => {
  const typeOfRevenueExpenseSuccessfullyRegistered = dictionary?.expense_revenue_typeOfRevenueExpenseSuccessfullyRegistered_toast
  const errorWhenRegisteringTypeOfRevenueExpense = dictionary?.expense_revenue_errorWhenRegisteringTypeOfRevenueExpense_toast
  const typeOfRevenueExpenseUpdatedSuccessfully = dictionary?.expense_revenue_typeOfRevenueExpenseUpdatedSuccessfully_toast
  const errorWhenUpdatingRevenueExpenseType = dictionary?.expense_revenue_errorWhenUpdatingRevenueExpenseType_toast
  const errorWhenSearchingForRevenueExpenseType = dictionary?.expense_revenue_errorWhenSearchingForRevenueExpenseType_toast
  const typeOfRevenueExpenseSuccessfullyRemoved = dictionary?.expense_revenue_typeOfRevenueExpenseSuccessfullyRemoved_toast
  const errorRemovingRevenueExpenseType = dictionary?.expense_revenue_errorRemovingRevenueExpenseType_toast
  const errorLoadingReveueExpenseTypeHistory = dictionary?.expense_revenue_errorLoadingReveueExpenseTypeHistory_toast
  const errorLoadingTypesOfRevenuesExpenses = dictionary?.expense_revenue_errorLoadingTypesOfRevenuesExpenses_toast
  const errorLoadingRevenueExpenseTypeOptions = dictionary?.expense_revenue_errorLoadingRevenueExpenseTypeOptions_toast

  const apiCodesMappingForExpenseRevenue = {
    'expense_revenue::id::cannot_destroy': dictionary?.expense_revenue_expenseRevenueCannotDestroy_toast
  }

  return {
    typeOfRevenueExpenseSuccessfullyRegistered,
    errorWhenRegisteringTypeOfRevenueExpense,
    typeOfRevenueExpenseUpdatedSuccessfully,
    errorWhenUpdatingRevenueExpenseType,
    errorWhenSearchingForRevenueExpenseType,
    typeOfRevenueExpenseSuccessfullyRemoved,
    errorRemovingRevenueExpenseType,
    errorLoadingReveueExpenseTypeHistory,
    errorLoadingTypesOfRevenuesExpenses,
    errorLoadingRevenueExpenseTypeOptions,
    apiCodesMappingForExpenseRevenue
  }
}
