import { useState, useCallback } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConciliationsHooksTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsHooksI18n'
import { CreditCardConciliationService } from 'Core/Service/creditCardConciliation'
import { ConciliationsTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsI18n'

export const useCreateManualCreditCardConciliation = () => {
  const { addToast } = useToast()
  const [isLoadingManualCreditCardConciliation, setIsLoadingManualCreditCardConciliation] = useState(false)

  const i18n = useTranslator(ConciliationsTranslator)

  const i18nHooks = useTranslator(ConciliationsHooksTranslator)

  const createManualCreditCardConciliation = useCallback((params) => {
    setIsLoadingManualCreditCardConciliation(true)
    return CreditCardConciliationService.postManualCreditCardConciliation(params, i18n).then(
      (conciliated) => {
        addToast({
          defaultMessage: i18nHooks?.manualConciliation,
          typeToast: 'success'
        })
        return conciliated
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18nHooks?.conciliationCreateManualError,
        dictTranslation: i18nHooks?.apiCodesMappingForCreditCardConciliation
      })
      return false
    }).finally(() => {
      setIsLoadingManualCreditCardConciliation(false)
    })
  }, [
    addToast,
    i18n,
    i18nHooks?.conciliationCreateManualError,
    i18nHooks?.manualConciliation,
    i18nHooks?.apiCodesMappingForCreditCardConciliation
  ])

  return {
    isLoadingManualCreditCardConciliation,
    createManualCreditCardConciliation
  }
}
