import React, { useCallback, useMemo } from 'react'
import { Modal, LoadingAnimation } from '@bonitour/components'
import { hideLoad, loadingAnimation } from 'Shared/styles/global'
import { BankTransferForm } from '../Form/BankTransferForm'
import { BankTransferParsed, PatchBankTransferPayload, PostBankTransferPayload } from 'Core/Types/BankTransfer'
import { BankTransfersTranslatorRes } from '../i18n/BankTransfersI18n'
import { bankTransferModalLoad } from './BankTransferModal.styles'

interface BankTransferModalProps {
  isVisible: boolean
  isLoading: boolean
  toggleHidden: () => void
  createBankTransfer: (createBankTransferPayload: PostBankTransferPayload) => Promise<boolean>
  updateBankTransfer: (updateBankTransferPayload: PatchBankTransferPayload, bankTransferId: string) => Promise<boolean>
  bankTransferData: BankTransferParsed
  isEdit: boolean
  i18n: BankTransfersTranslatorRes
  fetchBankTransfers: () => Promise<void>
}

export const BankTransferModal = ({
  isVisible,
  isLoading,
  toggleHidden,
  createBankTransfer,
  updateBankTransfer,
  bankTransferData,
  isEdit,
  i18n,
  fetchBankTransfers
}: BankTransferModalProps): React.ReactElement => {
  const onSubmitBankTransferData = useCallback(
    async (data: PostBankTransferPayload | PatchBankTransferPayload) => {
      const isSuccess = isEdit ? await updateBankTransfer(data, bankTransferData?.id) : await createBankTransfer(data)

      if (isSuccess) {
        toggleHidden()
        fetchBankTransfers()
      }
    }, [
      bankTransferData?.id,
      createBankTransfer,
      fetchBankTransfers,
      isEdit,
      toggleHidden,
      updateBankTransfer
    ])

  const modalTitle = useMemo(
    () => isEdit ? i18n?.editBankTransfer : i18n?.registerBankTransfer
    , [i18n?.editBankTransfer, i18n?.registerBankTransfer, isEdit]
  )

  return (
    <Modal
      title={modalTitle}
      isVisible={isVisible}
      onCloseClick={toggleHidden}
    >

      <LoadingAnimation
        customCss={
          isLoading
            ? [
                bankTransferModalLoad, loadingAnimation
              ]
            : [hideLoad]
        }
      />

      <BankTransferForm
        onSubmitBankTransferData={onSubmitBankTransferData}
        bankTransferData={bankTransferData}
        onClose={toggleHidden}
        isEdit={isEdit}
      />
    </Modal>
  )
}
