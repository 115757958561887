import { colors } from '@bonitour/components'

export const defineStatus = Object.freeze({
  true: { label: 'Ativo', color: colors.gray2 },
  false: { label: 'Inativo', color: colors.red2 }
})

export const defineType = Object.freeze({
  S: 'Sintético',
  A: 'Analítico'
})

export const planTypeOptions = [
  {
    value: '',
    label: 'Todos'
  },
  {
    value: 'A',
    label: 'Analítico'
  },
  {
    value: 'S',
    label: 'Sintético'
  }
]

export const enabledOptions = [
  { value: '', label: 'Todos' },
  { value: true, label: 'Ativo' },
  { value: false, label: 'Inativo' }
]
