import React from 'react'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { useBankTransferInputs } from '../hooks/inputs/useBankTransferInputs'
import { BankTransfersInputsContainer } from './BankTransferInputs.styles'
import { FormActions } from 'Shared/components/Form/__FormActions'
import {
  BankTransferParsed,
  PatchBankTransferPayload,
  PostBankTransferPayload
} from 'Core/Types/BankTransfer'

interface BankTransferFormProps {
  onSubmitBankTransferData: (data: PostBankTransferPayload | PatchBankTransferPayload) => void
  bankTransferData: BankTransferParsed
  onClose: () => void
  isEdit: boolean
}

export const BankTransferForm = ({
  onSubmitBankTransferData,
  bankTransferData,
  onClose,
  isEdit
}: BankTransferFormProps): React.ReactElement => {
  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useBankTransferInputs(
    {
      bankTransferData,
      isEdit
    }
  )

  return (
    <BankTransfersInputsContainer>
      <FormsInputs
        inputs={inputsValid}
        formValues={form}
        formErrors={errors}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      />

      <section className='wrapper__btns'>
        <FormActions
          onClose={onClose}
          onSubmit={onSubmit(onSubmitBankTransferData)}
        />
      </section>
    </BankTransfersInputsContainer>
  )
}
