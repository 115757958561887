import { useForm } from '@bonitour/app-functions'
import {
  automationRuleActions,
  operationType,
  accounts,
  accountFormulas,
  referenceDateWithFormula,
  dateWithFormula,
  automationRuleStatus,
  value,
  iterationField,
  getSchema
} from 'Domains/EventsProcessors/Form/__EventsProcessorsBaseInputs'
import {
  EventsProcessorsInputsErrorsTranslator
} from 'Domains/EventsProcessors/i18n/EventsProcessorsInputsErrorsI18n'
import {
  EventsProcessorsInputsTranslator
} from 'Domains/EventsProcessors/i18n/EventsProcessorsInputsI18n'
import { useSortedInputOptions } from 'Shared/language/hooks/inputs/useSortedInputOptions'
import {
  useTranslatedFormErrors
} from 'Shared/language/hooks/inputs/useTranslatedFormErrors'
import {
  useTranslatedInputs
} from 'Shared/language/hooks/inputs/useTranslatedInputs'
import {
  useCallback,
  useMemo
} from 'react'

const BASE_FORM = {}

export const useCreateEditAutomationRuleInputs = (
  automationRuleData,
  automationRuleActionsOptions,
  automationRuleAccountsOptions,
  automationRuleAccountFormulasOptions
) => {
  const inputsTranslated = useTranslatedInputs([
    automationRuleActions(automationRuleActionsOptions),
    operationType,
    value,
    dateWithFormula,
    referenceDateWithFormula,
    automationRuleStatus,
    iterationField,
    accounts(automationRuleAccountsOptions),
    accountFormulas(automationRuleAccountFormulasOptions)
  ], EventsProcessorsInputsTranslator)

  const inputs = useSortedInputOptions(inputsTranslated)

  const schema = useMemo(
    () => getSchema(inputs),
    [inputs]
  )

  const {
    form,
    errors,
    onSubmit,
    clearErrors,
    setForm,
    utils: { onInputChange: emitInputChange, onInputBlur }
  } = useForm(automationRuleData || BASE_FORM, schema)

  const inputsValid = useMemo(() => {
    return inputs?.map((input) => {
      if (input?.value === 'automationRuleStatus') {
        let statusValuesReleased = []
        const action = form?.automationRuleAction
        switch (action) {
        case 'UpdateTicketStatus':
          statusValuesReleased = [
            'billed',
            'need_confirmation'
          ]
          break
        case 'UpdateAccountForecastWithFormula':
        case 'MoveAccountForecastWithFormula':
        case 'MoveAccountForecastWithFormulaForRefund':
          statusValuesReleased = [
            'waiting',
            'processed',
            'canceled',
            'need_confirmation'
          ]
          break
        case 'UpdateAccountMovement':
        case 'MoveAccountWithFormula':
          statusValuesReleased = [
            'done',
            'canceled'
          ]
          break
        default:
          statusValuesReleased = [
            'waiting',
            'need_confirmation',
            'processed',
            'billed',
            'canceled',
            'done'
          ]
          break
        }

        return {
          ...input,
          options: input?.options?.filter(({ value }) => {
            return statusValuesReleased?.includes(value)
          })
        }
      } else {
        return input
      }
    })
  }, [form?.automationRuleAction, inputs])

  const onInputChange = useCallback((field) => {
    switch (field) {
    case 'automationRuleAction':
      clearErrors()
      setForm(oldForm => {
        const { automationRuleStatus: _, ...newForm } = oldForm
        return newForm
      })
      break
    case 'accountId':
      clearErrors('accountFormula')
      break
    case 'accountFormula':
      clearErrors('accountId')
      break
    default:
      break
    }

    return emitInputChange(field)
  }, [clearErrors, emitInputChange, setForm])

  const formErrorsTranslteds = useTranslatedFormErrors(
    errors, EventsProcessorsInputsErrorsTranslator
  )

  return {
    errors: formErrorsTranslteds,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  }
}
