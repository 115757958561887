import { useState, useCallback, useEffect } from 'react'
import { ExpenseRevenueService } from 'Core/Service/ExpenseRevenues'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesRevenuesTypesHooksTranslator } from '../i18n/ExpensesRevenuesTypesHooksI18n'

export const useExpenseRevenuesOptions = (isExpense, enabled = undefined) => {
  const [expenseRevenuesList, setExpenseRevenuesList] = useState([])

  const { addToast } = useToast()

  const i18n = useTranslator(ExpensesRevenuesTypesHooksTranslator)

  const fetchExpenseRevenues = useCallback((expenseRevenueType, enabled) => {
    return ExpenseRevenueService.listWithoutPagination(expenseRevenueType, enabled).then((data) => {
      setExpenseRevenuesList(data)
    }).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorLoadingRevenueExpenseTypeOptions
      })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast])

  useEffect(() => {
    fetchExpenseRevenues({ expenseRevenueType: isExpense ? 'expense' : 'revenue', enabled })
  }, [fetchExpenseRevenues, isExpense, enabled])

  return {
    expenseRevenuesOptions: expenseRevenuesList?.map(expenseRevenue => ({
      value: expenseRevenue.id,
      label: expenseRevenue.description
    })),
    fetchExpenseRevenues
  }
}
