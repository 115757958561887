import { useForm } from '@bonitour/app-functions'
import {
  getSchema,
  status,
  name,
  fieldName,
  fieldValue
} from 'Domains/EventsProcessors/Form/__EventsProcessorsBaseInputs'
import { EventsProcessorsInputsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsInputsI18n'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { useMemo } from 'react'

export const useEventsProcessorsFiltersInputs = (
  filters
) => {
  const inputsValid = useTranslatedInputs([
    name,
    fieldName,
    fieldValue,
    status
  ], EventsProcessorsInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsValid),
    [inputsValid]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(filters, schema)

  return {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  }
}
