/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { statusColors } from '../Constants/EventsProcessors'
import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TrashIcon,
  EditInfoIcon
} from '@bonitour/components'
import { EventsProcessorsTranslator } from '../i18n/EventsProcessorsI18n'
import { useTranslator } from 'Shared/language/i18nHandler'
import { CircleStatus, wrapperButtons } from 'Shared/styles/global'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { useDeleteEventProcessor } from '../hooks/useDeleteEventProcessor'
import { apiCodesTranslations } from 'Shared/language/utils/functions'
import { ConfirmDialog } from 'Shared/components/ConfirmDialog/ConfirmDialog'
import { useCallback, useState } from 'react'
import { ConditionsDetailsWrapper, ConditionsWrapper, wrapperName } from './EventsProcessorsList.style'
import { Modal } from 'Shared/components/Modal/Modal'
import { useHistory } from 'react-router-dom'

/**
 * @typedef EventsProcessorsListProps
 * @prop { boolean} isLoading
 * @prop { Record<string, string>[] } eventsProcessorsData
 * @prop { (filets: Record<string, string>) => Record<string, string>[] } fetchEventsProcessors
 */

/** @type { React.FC<EventsProcessorsListProps> } */
export const EventsProcessorsList = (
  { isLoading, eventsProcessorsData, fetchEventsProcessors }
) => {
  const i18n = useTranslator(EventsProcessorsTranslator)

  return (
    <>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            {
              i18n?.eventProcessorTableColumnsTitles?.map(
                (item, idx) => (
                  <TableHeaderCell key={idx}>{item}</TableHeaderCell>
                )
              )
            }
          </TableHeaderRow>
        </TableHeader>
        <EventsProcessorsListingTableBody
          eventsProcessorsData={eventsProcessorsData}
          isLoading={isLoading}
          i18n={i18n}
          fetchEventsProcessors={fetchEventsProcessors}
        />
      </TableContainer>
    </>
  )
}

/**
 * @typedef EventsProcessorsListingTableBodyProps
 * @prop { boolean} isLoading
 * @prop { Record<string, string>[] } eventsProcessorsData
 * @prop { Record<string, string> } i18n
 * @prop { (filets: Record<string, string>) => Record<string, string>[] } fetchEventsProcessors
 */

/** @type { React.FC<EventsProcessorsListingTableBodyProps> } */
const EventsProcessorsListingTableBody = (
  { eventsProcessorsData, isLoading, i18n, fetchEventsProcessors }
) => {
  const [deletingId, setDeletingId] = useState(null)

  const closeModal = useCallback(() => {
    setDeletingId(null)
  }, [])

  const {
    onRemoveEventProcessor,
    isLoading: loadingRemoveEventProcessor
  } = useDeleteEventProcessor(fetchEventsProcessors)

  const handleRemoveEventProcessor = useCallback(() => {
    closeModal()
    onRemoveEventProcessor(deletingId)
  }, [closeModal, deletingId, onRemoveEventProcessor])

  const { push } = useHistory()

  const redirectEditPage = useCallback((eventProcessorId) => {
    push(`/events-processors/edit/${eventProcessorId}?section=1`)
  }, [push])

  return (
    <>
      <TableBody loading={isLoading}>
        {eventsProcessorsData?.map(
          (
            { id, status, name, conditions, processorType }
          ) => (
            <TableBodyRow key={id}>
              <TableBodyCell css={wrapperName}>
                <SafeOverflow
                  text={name}
                  className='event_processor_safe_overflow'
                />
              </TableBodyCell>
              <TableBodyCell>
                <Conditions
                  conditions={conditions}
                  i18n={i18n}
                />
              </TableBodyCell>
              <TableBodyCell>
                <CircleStatus
                  statusColor={statusColors?.[status || '-'].color}
                >
                  {
                    apiCodesTranslations({ apiCode: status, codesMapped: i18n?.eventProcessorStatusLabels })
                  }
                </CircleStatus>
              </TableBodyCell>
              <TableBodyCell css={wrapperButtons}>
                <button
                  type='button'
                  onClick={() => redirectEditPage(id)}
                >
                  <EditInfoIcon />
                </button>
                {processorType === 'custom'
                  ? <button
                    onClick={() => setDeletingId(id)}
                    disabled={!!loadingRemoveEventProcessor}
                  >
                    <TrashIcon />
                  </button>
                  : null
                }
              </TableBodyCell>
            </TableBodyRow>
          ))}
      </TableBody>
      <ConfirmDialog
        isVisible={Boolean(deletingId)}
        onClose={closeModal}
        title={i18n?.areYouSureToDeleteProcessors}
        subtitle={i18n?.afterConfirmationNoWayToUndoAction}
        onSuccess={() => handleRemoveEventProcessor()}
      />
    </>
  )
}

/**
 * @typedef ConditionsProps
 * @prop { Record<string, string>[] } conditions
 * @prop { Record<string, string> } i18n
 */

/** @type { React.FC<ConditionsProps> } */
export const Conditions = (
  { conditions, i18n }
) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDetails = useCallback(() => {
    setIsOpen((v) => !v)
  }, [])

  return (
    <ConditionsWrapper className='wrapper_conditions'>
      {i18n?.condition(conditions?.length)}
      {!conditions?.length ? null : (
        <>
          <button
            onClick={() => toggleDetails()}
            className='circle_conditions'
          >
        i
          </button>
          <Modal
            title={i18n?.entryConditions(conditions?.length)}
            isOpen={isOpen}
            onClose={toggleDetails}
            maxWidth='40'
          >
            <ConditionsDetailsWrapper>
              {conditions?.map((condition, idx) => (
                <div className='content_conditions_details' key={idx}>
                  <p>{condition?.field}</p>
                  <p>{
                    apiCodesTranslations(
                      { apiCode: condition?.comparisonType, codesMapped: i18n?.comparionsTypes }
                    )}
                  </p>
                  <p>{condition?.value}</p>
                </div>
              ))}
            </ConditionsDetailsWrapper>
          </Modal>
        </>
      )}
    </ConditionsWrapper>
  )
}
