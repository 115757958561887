import { useTranslatedFlags } from 'Shared/language/hooks/useTranslatedFlags'
import { useTranslatedScheduledDescription } from 'Shared/language/hooks/useTranslatedScheduleDescription'
import { pluralize } from 'Shared/language/utils/dictionaryUtils'

export const ConciliationsTranslator = (
  dictionary,
  locale
) => {
  const dateFormat = dictionary?.common_dateFormat_template
  const pageTitleBankConciliations = dictionary?.conciliation_pageTitleBankConciliations_label
  const tabsTitleConciliations = dictionary?.conciliation_tabsTitleConciliations_label
  const tabsTitleNotConciliations = dictionary?.conciliation_tabsTitleNotConciliations_label
  const tabsTitlePartiallyConciliations = dictionary?.conciliation_tabsTitlePartiallyConciliations_label
  const tableTitleImportedBankPostings = dictionary?.conciliation_tableTitleImportedBankPostings_label
  const tableTitleSystemReleases = dictionary?.conciliation_tableTitleSystemReleases_label
  const fillInFieldsFromTheStartBar = dictionary?.conciliation_fillInFieldsFromTheStartBar_label
  const listButton = dictionary?.conciliation_listButton_label
  const noItemsToConciliation = dictionary?.conciliation_noItemsToConciliation_label
  const itemSelected = (qty) => pluralize(dictionary?.conciliation_itemSelected_label, qty, locale)
  const makeAutomaticConciliation = dictionary?.conciliation_makeAutomaticConciliation_label
  const makeManualConciliation = dictionary?.conciliation_makeManualConciliation_label
  const deleteConciliation = (qty) => pluralize(dictionary?.conciliation_deleteConciliation_label, qty, locale)

  const tableBankStatementsColumnsTitles = [
    dictionary?.conciliation_tableColumnTitleDate_label,
    dictionary?.conciliation_tableColumnTitleHistoric_label,
    dictionary?.conciliation_tableColumnTitleDocument_label,
    dictionary?.conciliation_tableColumnTitleValue_label
  ]

  const tableAccountMovementsColumnsTitles = [
    dictionary?.conciliation_tableColumnTitleDate_label,
    dictionary?.conciliation_tableColumnTitleDescription_label,
    dictionary?.conciliation_tableColumnTitleValue_label
  ]

  const statusDetails = (details, regexStatusDetails) => {
    const objectTranslations = {
      divergent_value: dictionary?.conciliation_divergentValue_label,
      divergent_date: dictionary?.conciliation_divergentDate_label
    }

    const result = details.replace(regexStatusDetails, (match) => objectTranslations[match.trim()])

    return result.replace(/,/g, ', ')
  }

  const conciliationBy = dictionary?.conciliation_conciliationBy_label
  const details = dictionary?.conciliation_details_label
  const data = dictionary?.conciliation_creationDate_label
  const dateWithHourTemplate = dictionary?.common_dateWithHour_template
  const noConciliationItems = dictionary?.conciliation_noConciliationItems_label

  const { flagTranslator } = useTranslatedFlags({ dictionary })

  const tableTitleReceivablesReleases = dictionary?.conciliation_tableTitleReceivablesReleases_label

  const tableAccountForecastsColumnsTitles = [
    dictionary?.conciliation_tableColumnTitleDate_label,
    `${dictionary?.common_aut_label}/${dictionary?.common_doc_label}`,
    dictionary?.common_reservation_label,
    dictionary?.common_brand_label,
    dictionary?.conciliation_tableColumnTitleValue_label
  ]

  const tableGatewayPaymentsColumnsTitles = [
    dictionary?.conciliation_tableColumnTitleDate_label,
    `${dictionary?.common_aut_label}/${dictionary?.common_doc_label}`,
    dictionary?.common_brand_label,
    dictionary?.conciliation_tableColumnTitleValue_label
  ]

  const pageTitleCreditCardConciliations = dictionary?.conciliation_pageTitleCreditCardConciliations_label

  const descriptionTranslator = useTranslatedScheduledDescription({ dictionary })

  const totalAmountOfReceivablesNotReconciled = dictionary?.conciliation_totalAmountOfReceivablesNotReconciled_label
  const totalSystemValueNotReconciled = dictionary?.conciliation_totalSystemValueNotReconciled_label
  const totalValueOfReconciledReceivables = dictionary?.conciliation_totalValueOfReconciledReceivables_label
  const totalizers = dictionary?.common_totalizers_label

  return {
    tableBankStatementsColumnsTitles,
    dateFormat,
    tableAccountMovementsColumnsTitles,
    pageTitleBankConciliations,
    tabsTitleConciliations,
    tabsTitleNotConciliations,
    tabsTitlePartiallyConciliations,
    tableTitleImportedBankPostings,
    tableTitleSystemReleases,
    fillInFieldsFromTheStartBar,
    listButton,
    noItemsToConciliation,
    itemSelected,
    makeAutomaticConciliation,
    makeManualConciliation,
    deleteConciliation,
    statusDetails,
    conciliationBy,
    details,
    data,
    dateWithHourTemplate,
    noConciliationItems,
    flagTranslator,
    tableTitleReceivablesReleases,
    tableAccountForecastsColumnsTitles,
    tableGatewayPaymentsColumnsTitles,
    pageTitleCreditCardConciliations,
    descriptionTranslator,
    totalAmountOfReceivablesNotReconciled,
    totalSystemValueNotReconciled,
    totalValueOfReconciledReceivables,
    totalizers
  }
}
