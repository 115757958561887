import { useCallback, useState } from 'react'
import { EventsProcessorsService } from 'Core/Service/eventsProcessors'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'

export const useGetUniqueConditions = () => {
  const [loading, setLoading] = useState(false)
  const [uniqueConditionsData, setUniqueConditionsData] = useState()
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const fetchUniqueConditions = useCallback((eventProcessorId) => {
    setLoading(true)
    return EventsProcessorsService.getUniqueConditions(eventProcessorId)
      .then(
        setUniqueConditionsData
      ).catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.erroFetchingUniqueConditions
        })
      })
      .finally(() => setLoading(false))
  }, [addToast, i18n?.erroFetchingUniqueConditions])

  return {
    fetchUniqueConditions,
    uniqueConditionsData,
    loading
  }
}
