import { formatMoney } from '@binamik/js-functions'
import { SafeDate } from '@binamik/js-providers'
import { MetaParser } from './Meta'
import { BANK_ACCOUNT_OPTIONS } from 'Constants/bankAccountOptions'
import { BILLING_BANK_STATEMENT_PAGAR_ME, DESCRIPTION } from 'Constants/originType'
import { PAGAR_ME } from 'Constants/gateways'
import { TRANSFER } from 'Constants/labels'

export const BankStatementsParser = {
  bankStatementsImport: (bankStatementsImportRes) => {
    const {
      ignored_current_date_transactions: ignoredCurrentDateTransactions,
      imported_transactions: importedTransactions,
      transactions,
      details
    } = bankStatementsImportRes ?? {}

    return {
      ignoredCurrentDateTransactions: ignoredCurrentDateTransactions || 0,
      importedTransactions: importedTransactions || 0,
      transactions: transactions || 0,
      details: Object.keys(details).map(key => {
        return {
          date: new SafeDate(key),
          alreadyImported: details[key].already_imported,
          imported: details[key].imported,
          notImported: details[key].not_imported
        }
      }) || []
    }
  },
  single: (bankStatement) => {
    const {
      id,
      date,
      transaction_code: transactionCode,
      transaction_description: transactionDescription,
      document_number: documentNumber,
      value,
      balance,
      cretead_at: createdAt
    } = bankStatement

    return {
      id,
      date: date && new SafeDate(date).format('DD/MM/YYYY'),
      transactionCode,
      transactionDescription,
      parsedBankStatementDescription: BankStatementsParser.bankStatementDescription(transactionDescription),
      documentNumber,
      value: value && formatMoney(value),
      balance: balance && formatMoney(balance),
      createdAt
    }
  },
  fetchBankStatements: (data) => {
    return {
      bankStatements: data.bank_statements.map(BankStatementsParser.single),
      meta: MetaParser.meta(data.meta)
    }
  },
  removeBankStatements: ({ deleted_bank_statements: deletedBankStatements }) => {
    return {
      deletedBankStatements
    }
  },
  importedBankStatements: ({ imported: importedBankStatements }) => {
    const dataArray = Object.keys(importedBankStatements).map(key => ({
      date: new SafeDate(key).format('DD/MM/YYYY'),
      qtd: importedBankStatements[key]
    }))

    return dataArray || []
  },
  bankStatementDescription: (transactionDescription) => {
    const originType = transactionDescription.startsWith(PAGAR_ME) ? BILLING_BANK_STATEMENT_PAGAR_ME : DESCRIPTION
    const bankNumber = transactionDescription.startsWith(PAGAR_ME) && transactionDescription.includes(TRANSFER)
      ? transactionDescription.split('_').pop()
      : ''

    const bankAccountName = BANK_ACCOUNT_OPTIONS.find(({ value }) => value === bankNumber)?.label || ''

    return {
      label: transactionDescription,
      bankAccountName,
      originType,
      description: transactionDescription
    }
  }

}
