import { SafeDate } from '@binamik/js-providers'
import { useTranslatedBankTransfersStatus } from './hooks/useTranslatedBankTransfersStatus'
import { useTranslatedBankTransfersTransferMethods } from './hooks/useTranslatedBankTransfersTransferMethod'

interface BankTransfersInputsTranslatorRes {
  inputs: {
    startDate: {
      label: string
      placeholder: string
    },
    endDate: {
      label: string
      placeholder: string
    },
    status: {
      label: string
      placeholder: string
    },
    originBankAccountId: {
      label: string
      placeholder: string
    },
    destinationBankAccountId: {
      label: string
      placeholder: string
    }
  }
  errors: {
    startDate: {
      dateFormat: string
      dateRequired: string
      startDateMustBeLessThanOrEqualEndDate: string
    },
    endDate: {
      dateFormat: string
      dateRequired: string
      endDateMustBeAfterOrEqualStartDate: string
    }
  },
  translatedOptions: {
    status: { value: string, label: string }[]
  },
  translatedValues: {
    createdAt: (value: SafeDate) => string
  }
}

export const BankTransfersInputsTranslator = (
  dictionary
): BankTransfersInputsTranslatorRes => {
  const { bankTransfersStatusOptionsTranslated } = useTranslatedBankTransfersStatus({ dictionary })
  const { bankTransfersTransferMethodsOptionsTranslated } = useTranslatedBankTransfersTransferMethods({ dictionary })

  const labels = {
    startDate: {
      label: dictionary?.conciliation_startDate_inputLabel,
      placeholder: dictionary?.conciliation_startDate_placeholderLabel
    },
    endDate: {
      label: dictionary?.conciliation_endDate_inputLabel,
      placeholder: dictionary?.conciliation_endDate_placeholderLabel
    },
    status: {
      label: dictionary?.common_status_label,
      placeholder: dictionary?.common_selectStatuses_label
    },
    originBankAccountId: {
      label: dictionary?.bank_transfer_originAccount_label,
      placeholder: dictionary?.common_selectAccount_label
    },
    destinationBankAccountId: {
      label: dictionary?.bank_transfer_destinationAccount_label,
      placeholder: dictionary?.common_selectAccount_label
    },
    statusRequired: {
      label: dictionary?.common_status_label,
      placeholder: dictionary?.common_selectStatus_label
    },
    transferMethod: {
      label: dictionary?.bank_transfer_transferMethod_label,
      placeholder: dictionary?.bank_transfer_selectTheMethod_label
    },
    userName: {
      label: dictionary?.common_userName_label
    },
    date: {
      label: dictionary?.bank_transfer_transferDate_label,
      placeholder: dictionary?.common_selectTheDate_label
    },
    taxe: {
      label: dictionary?.common_bankFee_label
    },
    value: {
      label: dictionary?.common_value_label
    },
    observation: {
      label: dictionary?.common_observation_label,
      placeholder: dictionary?.common_enterObservation_label
    },
    createdAt: {
      label: dictionary?.common_registrationDate_label
    }
  }

  const errors = {
    startDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_startDateRequired_formErrorLabel,
      startDateMustBeLessThanOrEqualEndDate: dictionary?.common_startDateMustBeLessThanOrEqualEndDate_label
    },
    endDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_endDateRequired_formErrorLabel,
      endDateMustBeAfterOrEqualStartDate: dictionary?.conciliation_endDateMustBeAfterOrEqualStartDate_formErrorLabel
    },
    originBankAccountId: {
      originBankAccountIdRequired: dictionary?.bank_transfer_originAccountRequired_label
    },
    destinationBankAccountId: {
      destinationBankAccountIdRequired: dictionary?.bank_transfer_destinationAccountRequired_label
    },
    statusRequired: {
      statusRequired: dictionary?.common_statusRequired_label
    },
    transferMethod: {
      transferMethodRequired: dictionary?.bank_transfer_transferMethodRequired_label
    },
    date: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.bank_transfer_transferDateRequired_label,
      isNotFutureDate: dictionary?.common_noFutureDateAllowed_label
    },
    observation: {
      observationRequired: dictionary?.common_observationRequired_label
    }
  }

  const translatedOptions = {
    status: bankTransfersStatusOptionsTranslated,
    transferMethod: bankTransfersTransferMethodsOptionsTranslated,
    statusRequired: bankTransfersStatusOptionsTranslated
  }

  const translatedValues = {
    createdAt: (value: SafeDate) => value?.format(dictionary?.common_dateFormat_template)
  }

  return {
    inputs: labels,
    errors,
    translatedOptions,
    translatedValues
  }
}
