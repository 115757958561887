/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { DetailsContainer } from './EventsRowAnnexDetails.style'
import { Fragment } from 'react'

/**
 * @typedef EventsRowAnnexDetailsProps
 * @prop { Record<string, string> } i18n
 * @prop { Record<string, string> } details
 */

/** @type { React.FC<EventsRowAnnexDetailsProps> } */
export const EventsRowAnnexDetails = (
  {
    details = [],
    i18n
  }
) => {
  return (
    <DetailsContainer>
      {!details?.length ? (
        <p className='empty__message'>{i18n?.emptyMessage}</p>
      ) : (
        details?.map(({ key, value, type }) => (
          <Fragment key={key}>
            {
              type === 'object' ? (
                <div className={'annex_details__wrapper_object'}>
                  <p className='item_label'>{i18n?.eventCodesMapped[key]?.label || i18n?.eventCodesMapped[key] || key}:</p>
                  <div className={'annex_details__object'} key={key}>
                    {value?.map(({ key, value }) => (
                      <div key={key}>
                        <p className='item_label'>{i18n?.eventCodesMapped[key]?.label || i18n?.eventCodesMapped[key] || key}:</p>
                        <p>
                          {
                            (
                              i18n?.eventCodesMapped[key]?.mappedValue && i18n?.eventCodesMapped[key]?.mappedValue(value)) ||
                              i18n?.eventCodesMapped[value] ||
                              value
                          }
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : type === 'array' ? (
                <div className={`annex_details__wrapper_${key}`}>
                  <p className='item_label'>{i18n?.eventCodesMapped[key]?.label || i18n?.eventCodesMapped[key] || key}:</p>
                  {value?.map((entry) => (
                    <div className={`annex_details__${key}`} key={key}>
                      {entry.map(({ key, value: newValue }) => (
                        <div key={key}>
                          <p className='item_label'>{i18n?.eventCodesMapped[key]?.label || i18n?.eventCodesMapped[key] || key}:</p>
                          <p>
                            {
                              (i18n?.eventCodesMapped[key]?.mappedValue && i18n?.eventCodesMapped[key]?.mappedValue(newValue)) || i18n?.eventCodesMapped[newValue] || newValue
                            }
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ) : (
                <div className={`annex_details__${key}`}>
                  <p className='item_label'>{i18n?.eventCodesMapped[key]?.label || i18n?.eventCodesMapped[key] || key}:</p>
                  <p>
                    {
                      (i18n?.eventCodesMapped[key]?.mappedValue && i18n?.eventCodesMapped[key]?.mappedValue(value)) || i18n?.eventCodesMapped[value] || value
                    }
                  </p>
                </div>
              )
            }
          </Fragment>
        ))
      )}
    </DetailsContainer>
  )
}
