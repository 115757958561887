import { useTranslatedFlags } from 'Shared/language/hooks/useTranslatedFlags'

interface FinancialScheduleFiltersTranslatorRes {
  cardFlagOptionsTranslated: { value: string, label: string }[]
}

export const FinancialScheduleFiltersTranslator = (
  dictionary
): FinancialScheduleFiltersTranslatorRes => {
  const { cardFlagOptionsTranslated } = useTranslatedFlags({ dictionary })

  return {
    cardFlagOptionsTranslated
  }
}
