/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LoadingAnimation } from '@bonitour/components'
import { FloatingSelectBarContainer } from './FloatingSelect.styles'
import { useCallback, useMemo } from 'react'
import { motion } from 'framer-motion'

/**
 * @typedef Options
 * @prop { string } [totalOutgoingTransactionsAmount]
 * @prop { string } [totalIncomingTransactionsAmount]
 * @prop { string } [totalTransactionsAmount]
 * @prop { string } [outgoingTransactionsLabel]
 * @prop { string } [incomingTransactionsLabel]
 * @prop { boolean } [isDynamicTotalizerEnabled]
 */

/**
 * @typedef FloatingSelectBarProps
 * @prop { string } itemLabel
 * @prop { Array } items
 * @prop { { label: string, onClick: () => void, disabled: boolean }[] } floatingSelectBarBtns
 * @prop { boolean } isVisible
 * @prop { Options } [options]
 */

/** @type { React.FC<FloatingSelectBarProps> } */
export const FloatingSelectBar = (
  {
    itemLabel,
    items = [],
    floatingSelectBarBtns,
    isVisible = true,
    options = {}
  }) => {
  const count = useMemo(() => items?.length, [items?.length])

  const classNameMoney = useCallback(
    (totalTransactionsAmount) => {
      if (typeof totalTransactionsAmount !== 'string') {
        return ''
      }

      const base = 'section_item_money'

      return [base, totalTransactionsAmount?.includes('-') ? `${base}__negative` : `${base}__positive`].join(' ')
    }
    , []
  )

  const {
    totalOutgoingTransactionsAmount,
    totalIncomingTransactionsAmount,
    totalTransactionsAmount,
    outgoingTransactionsLabel,
    incomingTransactionsLabel,
    isDynamicTotalizerEnabled = false
  } = options ?? {}

  if (!isVisible && !count) {
    return null
  }

  return (
    <FloatingSelectBarContainer>

      <motion.div
        layout
        transition={{ layout: { duration: 0.5 } }}
        className='floating_wrapper__counts'
      >
        <div className='floating_select_bar__section_item'>
          <p>{itemLabel}</p>
          <span className='section_item_count'>{count}</span>
        </div>

        {isDynamicTotalizerEnabled ? (
          <>
            {
              totalOutgoingTransactionsAmount && (
                <div className='floating_select_bar__section_item'>
                  <p>{outgoingTransactionsLabel}</p>
                  <span className={classNameMoney(totalOutgoingTransactionsAmount)}>
                    {totalOutgoingTransactionsAmount}
                  </span>
                </div>
              )
            }

            {
              totalIncomingTransactionsAmount && (
                <div className='floating_select_bar__section_item'>
                  <p>{incomingTransactionsLabel}</p>
                  <span className={classNameMoney(totalIncomingTransactionsAmount)}>
                    {totalIncomingTransactionsAmount}
                  </span>
                </div>
              )
            }

            <div className='floating_select_bar__section_item'>
              <p>Total</p>
              <span className={classNameMoney(totalTransactionsAmount)}>
                {totalTransactionsAmount}
              </span>
            </div>
          </>
        ) : null}

      </motion.div>

      <div className='floating_select_bar__section_btns'>
        {
          floatingSelectBarBtns?.map(
            ({ label, onClick, isLoading, disabled = false }, idx) => (
              <button
                key={idx}
                className='floating_select_bar__btn'
                onClick={onClick}
                disabled={disabled}
              >
                {isLoading ? (
                  <LoadingAnimation
                    size={20}
                    className='floating_select_bar__load'
                    baseColor='#ffffff' />
                ) : label}
              </button>
            ))
        }
      </div>
    </FloatingSelectBarContainer>
  )
}
