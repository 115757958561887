import styled from '@emotion/styled'

export const AllowedFormulasContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  height: 22.1875rem;
  
  .content {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0 1.25rem 0 0;
    overflow-y: auto;
  }

  .allowed_formula_wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0.625rem;
    border-radius: 0.625rem;
  }
  
  .gray12 {
    background-color: var(--clr-gray12);
  }

  .code_snippet {
    font-weight: 700;
    display: block;
    word-wrap: break-word;
  }
`
