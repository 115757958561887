import { SafeDate } from '@binamik/js-providers'

interface ConfigurationsInputsTranslatorType {
  inputs: {
    userName: {
      label: string
    },
    expenseTradeId: {
      label: string
    },
  }
  translatedValues: {
    updatedAt: (value: SafeDate) => string
  }
  errors: {
    forgottenExpenses: {
      mustBeGreaterThanOrEqual1: string
      mustBeLessThanOrEqual31: string
    },
    forgottenRevenues: {
      mustBeGreaterThanOrEqual1: string
      mustBeLessThanOrEqual31: string
    },
    maxRecurrences: {
      mustBeGreaterThanOrEqual12: string
      mustBeLessThanOrEqual240: string
    }
  }
}

export const ConfigurationsInputsTranslator = (
  dictionary
): ConfigurationsInputsTranslatorType => {
  const labels = {
    userName: {
      label: dictionary?.common_userName_label
    },
    expenseTradeId: {
      label: dictionary?.configuration_expenseTradeId_inputLabel
    },
    updatedAt: {
      label: dictionary?.common_editDate_label
    }
  }

  const translatedValues = {
    updatedAt: (value: SafeDate) => value?.format(dictionary?.common_dateFormat_template)
  }

  const errors = {
    forgottenExpenses: {
      mustBeGreaterThanOrEqual1: dictionary?.configuration_numberOfDaysMustBeGreaterThanOrEqualTo1_label,
      mustBeLessThanOrEqual31: dictionary?.configuraiton_numberOfDaysMustBeLessThanOrEqualTo31_label
    },
    forgottenRevenues: {
      mustBeGreaterThanOrEqual1: dictionary?.configuration_numberOfDaysMustBeGreaterThanOrEqualTo1_label,
      mustBeLessThanOrEqual31: dictionary?.configuraiton_numberOfDaysMustBeLessThanOrEqualTo31_label
    },
    maxRecurrences: {
      mustBeGreaterThanOrEqual12: dictionary?.common_numberOfRepetitionsMustBeGreaterThanOrEqualTo12_label,
      mustBeLessThanOrEqual240: dictionary?.common_numberOfRepetitionsMustBeLessThanOrEqual240_label
    }
  }

  return {
    inputs: labels,
    translatedValues,
    errors
  }
}
