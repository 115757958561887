export const EventsProcessorModel = {
  getEventsProcessors: (filters, pagination) => {
    const {
      name,
      status,
      fieldName,
      fieldValue
    } = filters

    const {
      currentPage,
      perPage
    } = pagination

    return {
      status: status || undefined,
      name: name || undefined,
      field_name: fieldName,
      field_value: fieldValue,
      page: currentPage,
      per_page: perPage
    }
  },
  createEventProcessor (payload) {
    const {
      name,
      description
    } = payload ?? {}

    return {
      name,
      status: 'disabled',
      description
    }
  },
  updateEventProcessor (payload) {
    const {
      name,
      description,
      status
    } = payload ?? {}

    const newStatus = (status === 'enabled' || (status && status !== 'disabled')) ? 'enabled' : 'disabled'

    return {
      name,
      status: newStatus,
      description
    }
  },
  getAutomationRulesSimulations (matchedEventId) {
    return {
      event_id: matchedEventId
    }
  },
  getAutomationRuleActions: () => {
    return {
      only_public_actions: true
    }
  },
  automationRulePayload: (payload) => {
    const {
      automationRuleAction,
      operationType,
      dateWithFormula,
      referenceDateWithFormula,
      accountId,
      accountFormula,
      automationRuleStatus,
      value,
      iterationField
    } = payload

    const safeAccountId = accountId === 'remove' ? undefined : accountId
    const safeAccountFormula = accountFormula === 'remove' ? undefined : accountFormula

    return {
      action_formula: automationRuleAction,
      operation_type: operationType,
      date: dateWithFormula,
      reference_date: referenceDateWithFormula,
      account_id: safeAccountId,
      account_formula: safeAccountFormula,
      status: automationRuleStatus,
      value,
      iteration_field: iterationField
    }
  }
}
