import { parseTemplateString, pluralize } from 'Shared/language/utils/dictionaryUtils'
import { useTranslatedFlags } from 'Shared/language/hooks/useTranslatedFlags'
import { useTranslatedPaymentMethods } from 'Shared/language/hooks/useTranslatedPaymentMethods'
import { useTranslatedStatusReceivables } from './hooks/useTranslatedStatusReceivables'
import { useTranslatedGatewayEventTypeDescriptions } from './hooks/useTranslatedGatewayEventTypeDescriptions'

export const ReceivablesHooksTranslator = (
  dictionary,
  locale
) => {
  const selectPeriodToImport = dictionary?.receivable_selectPeriodToImport_toast
  const apiCodesMappingForReceivables = {
    'gateway_payment::date::import_period_limit_to_one_month': dictionary?.receivable_paymentImportPeriodLimitToOneMonth_toast,
    'gateway_payment::date::no_future_receivables_allowed': dictionary?.receivable_paymentNoFutureReceivablesAllowed_toast,
    'gateway_payment::conciliated::cannot_delete_conciliated_gateway_payments': dictionary?.receivable_itIsNotAllowedDeleteReceivablesAlreadyConciliated_toast,
    'gateway_payment::file::corrupted_file': dictionary?.receivable_invalidFileFormat_label
  }

  const { flagTranslator } = useTranslatedFlags({ dictionary })
  const { paymentMethodMapped } = useTranslatedPaymentMethods({ dictionary })
  const statusReceivablesTranslator = useTranslatedStatusReceivables({ dictionary })
  const gatewayPaymentEventTypeDescriptionTranslator = useTranslatedGatewayEventTypeDescriptions({ dictionary })

  const dateFormat = dictionary?.common_dateFormat_template
  const conciliated = dictionary?.common_conciliate_label
  const notConciliated = dictionary?.common_notConciliate_label

  const csvHeadersReceivables = [
    { key: 'date', label: dictionary?.receivables_csvColumnTitlePaymentDate_label },
    { key: 'gateway', label: dictionary?.receivables_csvColumnTitleAcquirer_label },
    { key: 'flag', label: dictionary?.receivables_csvColumnTitleFlag_label },
    { key: 'installment', label: dictionary?.receivables_csvColumnTitleParcel_label },
    { key: 'installments', label: dictionary?.receivables_csvColumnTitleAmountInstallments_label },
    { key: 'operationType', label: dictionary?.receivables_csvColumnTitleOperationType_label },
    { key: 'authorizationCode', label: dictionary?.receivables_csvColumnTitleAuthorizationCode_label },
    { key: 'proofOfSale', label: dictionary?.receivables_csvColumnTitleDOCNSU_label },
    { key: 'grossAmount', label: dictionary?.receivables_csvColumnTitleGrossAmount_label },
    { key: 'netAmount', label: dictionary?.receivables_csvColumnTitleNetAmount_label },
    { key: 'mdr', label: dictionary?.receivables_csvColumnTitleMdr_label },
    { key: 'mdrFee', label: dictionary?.receivables_csvColumnTitleMdrFee_label },
    { key: 'binamikFee', label: dictionary?.receivables_csvColumnTitleBinamikFee_label },
    { key: 'cardNumber', label: dictionary?.receivables_csvColumnTitleCardNumber_label },
    { key: 'saleDate', label: dictionary?.receivables_csvColumnTitleSaleDate_label },
    { key: 'saleGrossAmount', label: dictionary?.receivables_csvColumnTitleSaleGrossAmount_label },
    { key: 'status', label: dictionary?.receivables_csvColumnTitleStatus_label },
    { key: 'gatewayEventType', label: dictionary?.receivables_csvColumnTitleEventTypeAcquirer_label },
    { key: 'gatewayEventTypeDescription', label: dictionary?.receivables_csvColumnTitleDescriptionEventAcquirer_label },
    { key: 'conciliated', label: dictionary?.receivables_csvColumnTitleConciliated_label }
  ]

  const receivableSelectPeriod = dictionary?.common_selectPeriod_toast
  const deleteReceivable = (qty) => {
    const templateString = pluralize(
      dictionary?.receivable_deleteReceivable_toast,
      qty,
      locale
    )
    return parseTemplateString(templateString, {
      NUMBER: qty
    })
  }

  return {
    selectPeriodToImport,
    apiCodesMappingForReceivables,
    dateFormat,
    csvHeadersReceivables,
    receivableSelectPeriod,
    conciliated,
    notConciliated,
    deleteReceivable,
    flagTranslator,
    paymentMethodMapped,
    statusReceivablesTranslator,
    gatewayPaymentEventTypeDescriptionTranslator
  }
}
