export interface ConfigurationTranslatorRes {
  pageTitleConfiguration: string,
  saveButton: string,
  auditButton: string,
  auditLabels: {
    forgotten_expenses: string,
    forgotten_revenues: string,
    max_recurrences: string,
    expense_revenue_name: string
  }
}

export const ConfigurationTranslator = (
  dictionary
): ConfigurationTranslatorRes => {
  const pageTitleConfiguration = dictionary?.configuration_pageTitleConfiguration_label
  const saveButton = dictionary?.common_save_label
  const auditButton = dictionary?.configuration_auditButton_label

  const auditLabels = {
    forgotten_expenses: dictionary?.audit_forgottenExpenses_label,
    forgotten_revenues: dictionary?.audit_forgottenRevenues_label,
    max_recurrences: dictionary?.audit_maxRecurrences_label,
    expense_revenue_name: dictionary?.audit_expenseRevenueName_label
  }

  return {
    pageTitleConfiguration,
    saveButton,
    auditButton,
    auditLabels
  }
}
