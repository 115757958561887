export const BankActionDashboardTranslator = (
  dictionary
) => {
  const titleBankConciliations = dictionary?.bank_action_dashboard_titleBankConciliations_label
  const titleBankStatements = dictionary?.bank_action_dashboard_titleBankStatements_label
  const pageTitleBankAccounts = dictionary?.bank_account_pageTitleBankAccounts_label
  const pageTitleBankActions = dictionary?.bank_action_dashboard_menuTitleBankActions_label
  const transferBetweenBankAccount = dictionary?.bank_transfer_transferBetweenBankAccount_label

  return {
    titleBankConciliations,
    titleBankStatements,
    pageTitleBankAccounts,
    pageTitleBankActions,
    transferBetweenBankAccount
  }
}
