import { SafeDate } from '@binamik/js-providers'
import { endDateMustBeAfterOrEqualStartDate } from 'Shared/utils/yup/date/endDateMustBeAfterOrEqualStartDate'
import { checkIfIsPastDate } from 'Shared/utils/yup/date/checkIfIsPastDate'
import { startDateMustBeLessThanOrEqualEndDate } from 'Shared/utils/yup/date/startDateMustBeLessThanOrEqualEndDate'
import {
  string as yupString,
  date as yupDate,
  array as yupArray
} from 'yup'
import { startAndEndDateMustDiffer } from 'Shared/utils/yup/date/startAndEndDateMustDiffer'

const yesterday = new SafeDate().subtract({ days: 1 }).toStartOf('day').jsDate

const dateRequired = (value: Date, ctx: { parent: { isStoneAccount: boolean } }) => {
  if (!value && ctx?.parent?.isStoneAccount) {
    return false
  }

  return true
}

const startDate = {
  label: 'Data inicial',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data inicial',
  className: 'start_date',
  value: 'startDate',
  type: 'date',
  maxDate: yesterday,
  schema: yupDate()
    .typeError('dateFormat')
    .test(
      'checkIfIsPastDate',
      'checkIfIsPastDate',
      checkIfIsPastDate
    ).test(
      'dateRequired',
      'dateRequired',
      dateRequired
    ).test(
      'startDateMustBeLessThanOrEqualEndDate',
      'startDateMustBeLessThanOrEqualEndDate',
      startDateMustBeLessThanOrEqualEndDate
    )
}

const endDate = {
  label: 'Data final',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data final',
  className: 'end_date',
  value: 'endDate',
  type: 'date',
  maxDate: yesterday,
  schema: yupDate()
    .typeError('dateFormat')
    .test(
      'checkIfIsPastDate',
      'checkIfIsPastDate',
      checkIfIsPastDate
    ).test(
      'dateRequired',
      'dateRequired',
      dateRequired
    ).test(
      'endDateMustBeAfterOrEqualStartDate',
      'endDateMustBeAfterOrEqualStartDate',
      endDateMustBeAfterOrEqualStartDate
    ).test(
      'startAndEndDateMustDiffer',
      'startAndEndDateMustDiffer',
      startAndEndDateMustDiffer
    )
}

const bankAccount = (bankAccountsOptions = []) => ({
  label: 'Conta Bancária',
  className: 'bank_account',
  value: 'bankAccountId',
  type: 'select',
  options: bankAccountsOptions,
  schema: yupString().required('bankAccountIdRequired')
})

const file = {
  label: 'Anexar o documento',
  className: 'file',
  value: 'file',
  type: 'attachmentButton',
  typeWrapper: 'inputFormGroup',
  schema: yupArray().test(
    'fileRequired',
    'fileRequired',
    (value, ctx) => {
      if (!value?.length && !ctx?.parent?.isStoneAccount) {
        return false
      }
      return true
    }
  )
}

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  startDate,
  endDate,
  bankAccount,
  file,
  getSchema
}
