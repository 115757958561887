/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FinancialScheduleFilterSchema } from './FinancialScheduleFilter.schema'
import { stylishCardSearch, buttonStyle, marginTop } from './FinancialScheduleFilter.styles'
import { creditCardOperationTypeOptions } from '../Constants/FinancialSchedule'
import {
  Button,
  Input,
  InputFormGroup,
  Card,
  Column,
  Row,
  DatePicker
} from '@bonitour/components'
import { acquirersOptions } from 'Constants/acquirersOptions'
import { Select } from 'Shared/components/Select/Select'
import { useForm } from '@bonitour/app-functions'
import { ActionButtons, exportBtn } from 'Shared/components/ExportFile/ActionButtons'
import { useExportMoves } from '../hooks/useExportMoves'
import { FinancialScheduleFiltersTranslator } from '../i18n/FinancialScheduleFiltersi18n'
import { useTranslator } from 'Shared/language/i18nHandler'

export const FinancialScheduleFilters = ({ onSearch, initialMoveFilters }) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(initialMoveFilters, FinancialScheduleFilterSchema)

  const {
    reservationCode,
    partnerName,
    proofOfSale,
    brand,
    creditCardOperationType,
    gateway,
    startDate,
    endDate
  } = form

  const { fetchExportMoves } = useExportMoves()

  const i18n = useTranslator(FinancialScheduleFiltersTranslator)

  return (
    <>
      <Card css={stylishCardSearch}>
        <Row>
          <Column desktop={3} phone={12}>
            <InputFormGroup label='Data inicial' errorMessage={errors.startDate}>
              <DatePicker
                id='startDate'
                className='start_date'
                value={startDate}
                onChange={onInputChange('startDate')}
                onBlur={onInputBlur('startDate')}
                placeholder='Selecione ou digite uma data'
                allowsEmpty
              />
            </InputFormGroup>
          </Column>
          <Column desktop={3} phone={12}>
            <InputFormGroup label='Data final' errorMessage={errors.endDate}>
              <DatePicker
                id='endDate'
                className='end_date'
                value={endDate}
                onChange={onInputChange('endDate')}
                onBlur={onInputBlur('endDate')}
                placeholder='Selecione ou digite uma data'
                allowsEmpty
              />
            </InputFormGroup>
          </Column>
          <Column desktop={3} phone={12}>
            <Select
              id='gateway'
              label='Adquirente'
              error={errors?.gateway}
              options={acquirersOptions}
              placeholder='Selecione um adquirente'
              value={gateway}
              onChange={onInputChange('gateway')}
              onBlur={onInputBlur('gateway')}
            />
          </Column>
          <Column desktop={3} phone={12}>
            <Select
              id='brand'
              options={i18n?.cardFlagOptionsTranslated}
              error={errors?.brand}
              label='Bandeira do cartão'
              value={brand}
              onChange={onInputChange('brand')}
              onBlur={onInputBlur('brand')}
            />
          </Column>
          <Column desktop={3} phone={12}>
            <InputFormGroup label='DOC'>
              <Input
                id='proofOfSale'
                error={errors?.proofOfSale}
                placeholder='Digite aqui'
                value={proofOfSale}
                onChange={onInputChange('proofOfSale')}
                onBlur={onInputBlur('proofOfSale')}
              />
            </InputFormGroup>
          </Column>
          <Column desktop={3} phone={12}>
            <InputFormGroup label='Código da Reserva' errorMessage={errors.reservationCode}>
              <Input
                id='reservationCode'
                placeholder='Digite aqui'
                value={reservationCode}
                onChange={onInputChange('reservationCode')}
                onBlur={onInputBlur('reservationCode')}
              />
            </InputFormGroup>
          </Column>
          <Column desktop={3} phone={12}>
            <InputFormGroup label='Parceiro'>
              <Input
                id='partnerName'
                placeholder='Digite aqui'
                value={partnerName}
                onChange={onInputChange('partnerName')}
                onBlur={onInputBlur('partnerName')}
              />
            </InputFormGroup>
          </Column>
          <Column desktop={3} phone={12}>
            <Select
              id='operation'
              label='Operação'
              error={errors?.creditCardOperationType}
              options={creditCardOperationTypeOptions}
              value={creditCardOperationType}
              onChange={onInputChange('creditCardOperationType')}
              onBlur={onInputBlur('creditCardOperationType')}
            />
          </Column>
          <Column css={marginTop} desktop={3} phone={12}>
            <Button css={buttonStyle} onClick={onSubmit(onSearch)}>
            Pesquisar
            </Button>
          </Column>
        </Row>
      </Card>

      <ActionButtons
        btns={[exportBtn(onSubmit(fetchExportMoves))]}
      />
    </>

  )
}
