import { numberForCurrency } from 'Shared/utils/number/numberForCurrency'
import { MetaParser } from './Meta'
import { SafeDate } from '@binamik/js-providers'
import { numberForPercentage } from 'Shared/utils/number/numberForPercentage'
import { csvGenerator } from 'Shared/utils/generatorCsv'
import { formatMoney } from '@binamik/js-functions'
import { CREDIT } from 'Constants/paymentMethodAndOperationType'

export const ReceivablesParser = {
  single: (gatewayPayments) => {
    const {
      id,
      gateway,
      date,
      sale_date: saleDate,
      sale_gross_amount: saleGrossAmount,
      brand,
      installment,
      installments,
      operation_type: operationType,
      authorization_code: authorizationCode,
      proof_of_sale: proofOfSale,
      gross_amount: grossAmount,
      net_amount: netAmount,
      mdr,
      mdr_fee: mdrFee,
      binamik_fee: binamikFee,
      card_number: cardNumber,
      status,
      gateway_event_type: gatewayEventType,
      gateway_event_type_description: gatewayEventTypeDescription,
      conciliated
    } = gatewayPayments ?? {}

    const isOperationTypeCredit = operationType?.includes(CREDIT)

    const valueColorClass = isOperationTypeCredit ? 'value_color__positive' : 'value_color__negative'

    const parseValue = (value) => {
      const formattedValue = formatMoney(value)

      if (value === '0.0') {
        return formattedValue
      }

      return isOperationTypeCredit ? formattedValue : `-${formattedValue}`
    }

    return {
      id,
      gateway,
      date,
      saleDate,
      saleGrossAmount,
      brand,
      installment,
      installments,
      operationType,
      authorizationCode,
      proofOfSale,
      grossAmount: {
        value: parseValue(grossAmount),
        valueColorClass
      },
      netAmount: {
        value: parseValue(netAmount),
        valueColorClass
      },
      mdr,
      mdrFee,
      binamikFee,
      cardNumber,
      status,
      gatewayEventType,
      gatewayEventTypeDescription,
      conciliated
    }
  },
  list: ({ meta, gateway_payments: gatewayPayments = [] }) => {
    return {
      receivables: gatewayPayments.map(ReceivablesParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  import: (importedDetail) => {
    const {
      failed,
      fails,
      imported,
      receivables,
      status,
      already_imported: alreadyImported
    } = importedDetail || {}

    return {
      failed,
      fails,
      imported,
      receivables,
      status,
      alreadyImported
    }
  },
  exportCsvReceivables: (data, i18n, filters) => {
    const safeStartDate = filters?.startDate ? new SafeDate(filters?.startDate).format(i18n?.dateFormat) : '-'
    const safeEndDate = filters?.endDate ? new SafeDate(filters?.endDate).format(i18n?.dateFormat) : '-'
    const fileName = `recebiveis_${filters?.gateway}_${safeStartDate}-${safeEndDate}`

    const dataParsed = data.gateway_payments.map((
      {
        date, gateway,
        brand, installment,
        installments, operation_type,
        authorization_code, proof_of_sale,
        gross_amount, net_amount,
        mdr, mdr_fee,
        binamik_fee, card_number,
        sale_date, sale_gross_amount,
        status, gateway_event_type,
        gateway_event_type_description,
        conciliated
      }
    ) => {
      const safeDate = date ? new SafeDate(date).format(i18n.dateFormat) : '-'
      const safeSaleDate = sale_date ? new SafeDate(sale_date).format(i18n.dateFormat) : '-'

      return {
        date: safeDate,
        gateway,
        flag: i18n?.flagTranslator(brand),
        installment: installment || '0',
        installments: installments || '0',
        operationType: i18n?.paymentMethodMapped(operation_type),
        authorizationCode: authorization_code || '-',
        proofOfSale: proof_of_sale || '-',
        grossAmount: numberForCurrency(gross_amount),
        netAmount: numberForCurrency(net_amount),
        mdr: numberForPercentage(mdr),
        mdrFee: numberForCurrency(mdr_fee),
        binamikFee: numberForCurrency(binamik_fee),
        cardNumber: card_number || '0',
        saleDate: safeSaleDate,
        saleGrossAmount: numberForCurrency(sale_gross_amount),
        status: i18n?.statusReceivablesTranslator(status),
        gatewayEventType: gateway_event_type || '-',
        gatewayEventTypeDescription: i18n?.gatewayPaymentEventTypeDescriptionTranslator(gateway_event_type_description),
        conciliated: conciliated ? i18n?.conciliated : i18n?.notConciliated
      }
    }, [])

    return {
      parserCsv: csvGenerator(dataParsed, { headers: i18n.csvHeadersReceivables }),
      fileName
    }
  },
  deleteGatewayPayments: ({ deleted_gateway_payments }) => {
    return {
      deletedGatewayPayments: deleted_gateway_payments
    }
  }
}
