import { useCallback, useEffect, useState, useRef } from 'react'
import { ExpenseRevenueService } from 'Core/Service/ExpenseRevenues'
import { useParamsFilter } from 'Shared/hooks/useParamsFilter'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesRevenuesTypesHooksTranslator } from '../i18n/ExpensesRevenuesTypesHooksI18n'

export const useListExpenseRevenuesType = () => {
  const { addToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useParamsFilter()

  const { initStatePagination, perPageOptions } = usePaginationOptions()
  const initPagination = useRef(false)
  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false)

  const [pagination, setPagination] = useState(initStatePagination)

  const i18n = useTranslator(ExpensesRevenuesTypesHooksTranslator)

  const handlePagination = useCallback((currentPage, perPage) => {
    if (!initPagination.current) {
      initPagination.current = true
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [])

  const handleFilters = useCallback((filters) => {
    setIsUpdatingFilters(true)
    setPagination({
      currentPage: 1,
      perPage: pagination.perPage
    })
    setFilters(filters)
    setTimeout(() => setIsUpdatingFilters(false))
  }, [pagination.perPage, setFilters])

  const [expenseRevenues, setExpenseRevenues] = useState({ expenseRevenues: undefined, meta: undefined })

  const fetchExpenseRevenues = useCallback(({ filters, pagination }) => {
    setLoading(true)

    return ExpenseRevenueService.list({ filters, pagination }).then((data) => {
      setExpenseRevenues(data)
    }).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorLoadingTypesOfRevenuesExpenses
      })
    }).finally(() => {
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    !isUpdatingFilters && fetchExpenseRevenues({ filters, pagination })
  }, [fetchExpenseRevenues, filters, isUpdatingFilters, pagination])

  return {
    filters,
    handleFilters,
    expenseRevenues: expenseRevenues?.expenseRevenues,
    meta: expenseRevenues?.meta,
    loading,
    fetchExpenseRevenues,
    handlePagination,
    pagination,
    perPageOptions
  }
}
