import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const EventsListContainer = styled.div`
  .expand {
    cursor: pointer;
  }

  .event_request {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    max-width: 20rem;
  }

  .annex_event_request_type {
    max-width: 25rem;
  }
`

export const WrapperSortRightIcon = styled.div`
  display: flex;
  gap: 0.625rem;

  button {
    width: 1rem;
    height: 1rem;
    font-size: 0.75rem;
    display: inline-block;
    transition: transform 200ms ease-in-out;
    background-color: transparent;

    &.open {
      transform: rotate(90deg);
    }
  }
`

export const tdDetails = css`
  padding: 0.625rem 1.25rem;
`
