
type AccountingFucntionDashboardTranslations = {
  menuTitleAccountsPlan: string
  titleAccountingsFunctions: string
}

// We will add the dict props in a future pr as we will have to map all the keys in this i18n.
export const AccountingFucntionDashboardTranslator = (
  dictionary
): AccountingFucntionDashboardTranslations => {
  const menuTitleAccountsPlan = dictionary?.accounting_function_dashboard_titleAccountsPlan_label
  const titleAccountingsFunctions = dictionary?.accounting_function_dashboard_titleAccountingsFunctions_label

  return {
    menuTitleAccountsPlan,
    titleAccountingsFunctions
  }
}
