import styled from '@emotion/styled'

export const CreditCardDashboardContainer = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;

  .icon {
    font-size: 1.25rem;
  }

  .label {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--clr-gray3);
    cursor: pointer;
  }
`
