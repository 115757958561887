import {
  getSchema,
  description,
  originType,
  startDate,
  endDate
} from 'Domains/FinancialSchedule/Filter/__FullFinancialFiltersBaseInputs'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { useForm } from '@bonitour/app-functions'
import { FullFinancialFiltersInputsTranslator } from 'Domains/FinancialSchedule/i18n/FullFinancialFiltersInputsI18n'
import { useMemo } from 'react'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'
import { FinacialScheduleFiltersErrorsTranslator } from 'Domains/FinancialSchedule/i18n/FinancialScheduleFiltersErrorsI18n'

const BASE_FORM = {}

export const useFullFinancialFiltersInputs = () => {
  const inputsValid = useTranslatedInputs([
    startDate,
    endDate,
    originType,
    description
  ], FullFinancialFiltersInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsValid),
    [inputsValid]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(BASE_FORM, schema)

  const formErrorsTranslteds = useTranslatedFormErrors(
    errors, FinacialScheduleFiltersErrorsTranslator
  )

  return {
    inputsValid,
    form,
    errors: formErrorsTranslteds,
    onInputChange,
    onInputBlur,
    onSubmit
  }
}
