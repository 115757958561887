import styled from '@emotion/styled'
import { colors } from '@bonitour/components'

export const InfoTooltipContainer = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
`

export const ButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`

export const ModalBg = styled.button`
  background-color: #000;
  opacity: 0.3;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  outline: none;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 3;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  

  width: 100%;
  max-width: 29.375rem;
  max-height: calc(100% - 2rem);
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 1.25rem;
  background: white;
  z-index: 4;

  .modal__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;

    .modal__title {
      font-size: var(--fs-lg);
      color: ${colors.gray3};
      font-weight: 700;
    }
  }


  .modal__info {
    .modal__option_title {
      color: ${colors.gray3};
      font-weight: 600;
      font-size: var(--fs-md);
      margin-bottom: 0.625rem;
    }

    p {
      color: ${colors.gray3};
      &:first-of-type {
          margin-bottom: 0.625rem;
      }
    }
  }


  .close-modal-button {
    --close-modal-button-size: 1.875rem;
    height: var(--close-modal-button-size);
    width: var(--close-modal-button-size);
    min-width: var(--close-modal-button-size);
    min-height: var(--close-modal-button-size);
    border-radius: 100vw;
    top: calc(var(--close-modal-button-size) / 2);
    right: calc(var(--close-modal-button-size) / 2);

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;

    background: ${colors.indigo1};;
    color: white;
    margin-left: 0.625rem;
  }
`
