/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  AllowedFieldsContainer as Container
} from './AllowedFields.styles'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsI18n'
import { useGetEventFieldsNames } from 'Domains/Events/hooks/useGetEventFieldsNames'
import { useCallback, useEffect } from 'react'
import { LoadingAnimation } from '@bonitour/components'

/**
 * @typedef AllowedFieldsProps
 * @prop { string } matchedEventId

/** @type { React.FC<AllowedFieldsProps> } */
export const AllowedFields = ({
  matchedEventId
}) => {
  const i18n = useTranslator(EventsProcessorsTranslator)

  const {
    eventFieldsNamesData,
    loading,
    getEventFieldsNames
  } = useGetEventFieldsNames()

  useEffect(() => {
    if (!matchedEventId || eventFieldsNamesData) return
    getEventFieldsNames(matchedEventId)
  }, [eventFieldsNamesData, getEventFieldsNames, matchedEventId])

  const isPar = useCallback((idx) => idx % 2 === 0, [])

  return (
    <Container>
      <h3>{i18n?.fieldOfAllowedEvents}</h3>
      {loading ? (
        <LoadingAnimation size={40} className='load' />
      ) : (
        <section className='content'>
          {eventFieldsNamesData?.map((fieldName, idx) => (
            <section
              key={idx}
              className={`allowed_field_wrapper ${isPar(idx) && 'gray12'}` }
            >
              {fieldName}
            </section>
          ))}
        </section>
      )}
    </Container>
  )
}
