import { SafeDate } from '@binamik/js-providers'

export const isNotFutureDate = (
  value: string | Date
) => {
  if (!value) {
    return true
  }
  const date = new SafeDate(value)
  const currentDate = new SafeDate().jsDate

  return !date.isAfter(currentDate)
}
