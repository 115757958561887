import { string as yupString } from 'yup'

const bankName = {
  label: 'Banco',
  className: 'bank_name',
  value: 'bankName',
  type: 'text',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite o nome do banco',
  schema: yupString()
    .required('bankNameRequired')
}

const branch = {
  label: 'Agência',
  className: 'branch',
  value: 'branch',
  type: 'text',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite a agência',
  schema: yupString()
    .required('branchRequired')
}

const accountNumber = {
  label: 'Conta',
  className: 'account_number',
  value: 'accountNumber',
  type: 'text',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Digite a conta',
  schema: yupString()
    .required('accountNumberRequired')
}

const accountType = (accountTypeOptions = []) => ({
  label: 'Tipo da conta',
  className: 'account_type',
  value: 'accountType',
  type: 'select',
  options: accountTypeOptions,
  withFilter: false,
  schema: yupString()
    .required('accountTypeRequired')
})

const enabled = (enabledOptions = []) => ({
  label: 'Estado',
  className: 'enabled',
  value: 'enabled',
  type: 'select',
  options: enabledOptions,
  withFilter: false,
  schema: yupString()
    .required('enabledRequired')
})

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  bankName,
  branch,
  accountNumber,
  enabled,
  accountType,
  getSchema
}
