
export const EventsFiltersInputsTranslator = (dictionary, _locale) => {
  const labels = {
    typeOfEvent: {
      label: dictionary?.event_eventType_inputLabel,
      placeholder: dictionary?.event_selectEventType_placeholderLabel
    }
  }

  return {
    inputs: labels
  }
}
