
interface BankStatementsInputsTranslatorRes {
  inputs: {
    startDate: {
      label: string
      placeholder: string
    },
    endDate: {
      label: string
      placeholder: string
    },
    bankAccountId: {
      label: string
      placeholder: string
    },
    file: {
      label: string
    }
  }
  errors: {
    startDate: {
      dateFormat: string
      dateRequired: string
      startDateMustBeLessThanOrEqualEndDate: string
    },
    endDate: {
      dateFormat: string
      dateRequired: string
      endDateMustBeAfterOrEqualStartDate: string
    },
    file: {
      fileRequired: string
    },
    bankAccountId: {
      bankAccountIdRequired: string
    }
  },
}

export const BankStatementsInputsTranslator = (
  dictionary
): BankStatementsInputsTranslatorRes => {
  const labels = {
    startDate: {
      label: dictionary?.conciliation_startDate_inputLabel,
      placeholder: dictionary?.conciliation_startDate_placeholderLabel
    },
    endDate: {
      label: dictionary?.conciliation_endDate_inputLabel,
      placeholder: dictionary?.conciliation_endDate_placeholderLabel
    },
    bankAccountId: {
      label: dictionary?.common_bankAccount_label,
      placeholder: dictionary?.common_bankAccount_label
    },
    file: {
      label: dictionary?.common_attachTheFile_label
    }
  }

  const errors = {
    startDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_startDateRequired_formErrorLabel,
      startDateMustBeLessThanOrEqualEndDate: dictionary?.common_startDateMustBeLessThanOrEqualEndDate_label,
      checkIfIsPastDate: dictionary?.bank_statement_onlyPastDatesAllowed_label
    },
    endDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_endDateRequired_formErrorLabel,
      endDateMustBeAfterOrEqualStartDate: dictionary?.conciliation_endDateMustBeAfterOrEqualStartDate_formErrorLabel,
      checkIfIsPastDate: dictionary?.bank_statement_onlyPastDatesAllowed_label,
      startAndEndDateMustDiffer: dictionary?.bank_statement_startAndEndDateMustBeDifferent_label
    },
    file: {
      fileRequired: dictionary?.common_fileRequired_label
    },
    bankAccountId: {
      bankAccountIdRequired: dictionary?.common_bankAccountRequired_label
    }
  }

  return {
    inputs: labels,
    errors
  }
}
