import { AuditParser } from './audit'

export const SupplierBankAccountParser = {
  single: (supplierBankAccount = {}) => {
    const {
      id,
      enabled,
      supplier_id: supplierId,
      bank_name: bankName,
      branch,
      account_number: accountNumber,
      account_type: accountType,
      created_at: createdAt,
      updated_at: updatedAt,
      user_name: userName,
      user_id: userId
    } = supplierBankAccount.supplier_bank_account ?? supplierBankAccount ?? {}

    return {
      id,
      supplierId,
      bankName,
      branch,
      accountNumber,
      accountType,
      enabled: enabled ? 'enabled' : 'disabled',
      createdAt,
      updatedAt,
      userName,
      userId
    }
  },
  list: ({ supplier_bank_accounts = [] }) => {
    return {
      supplierBankAccounts: supplier_bank_accounts.map(SupplierBankAccountParser.single)
    }
  },
  supplierBankAccountAudit: (data) => {
    return AuditParser.getAudits({ data })
  }
}
