
import { deepGetOrElse } from 'deep-getter'
import { SafeDate } from '@binamik/js-providers'

export const BankStatementModel = {
  bankStatementsFileImport: ({ bankAccountId, file }) => {
    return {
      bank_account_id: bankAccountId,
      file: deepGetOrElse({ file }, 'file.0.file', null)
    }
  },
  fetchBankStatements: (searchParams, pagination) => {
    const {
      startDate,
      endDate,
      bankAccountId
    } = searchParams ?? {}

    const {
      currentPage,
      perPage
    } = pagination ?? {}

    return {
      searchParams: {
        start_date: new SafeDate(startDate).isoDate,
        end_date: new SafeDate(endDate).isoDate,
        bank_account_id: bankAccountId
      },
      pagination: {
        per_page: perPage,
        page: currentPage
      }
    }
  },
  removeBankStatements: (params) => {
    const {
      startDate,
      endDate,
      bankAccountId
    } = params

    return {
      start_date: new SafeDate(startDate).isoDate,
      end_date: new SafeDate(endDate).isoDate,
      bank_account_id: bankAccountId
    }
  },
  importedBankStatements: (params) => {
    const {
      bankAccountId
    } = params

    return {
      bank_account_id: bankAccountId
    }
  },
  bankStatementsImport: (params) => {
    const {
      bankAccountId,
      startDate,
      endDate
    } = params ?? {}

    return {
      bank_account_id: bankAccountId,
      start_date: new SafeDate(startDate).isoDate,
      end_date: new SafeDate(endDate).isoDate
    }
  }
}
