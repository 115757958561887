import { useTranslator } from 'Shared/language/i18nHandler'
import { useMemo } from 'react'

export const useTranslatedInputs = (inputs, formTranslator) => {
  const { inputs: i18n, translatedOptions = {} } = useTranslator(formTranslator)

  return useMemo(() => {
    const translateLabel = (input, type) => i18n?.[input.value]?.[type] || input[type]

    const translateOptions = (input) => {
      if (translatedOptions[input?.value]) {
        return translatedOptions[input?.value]
      }

      return input.options?.map(option => {
        const translatedOptionByValue = i18n?.[input.value]?.options?.[option.value]
        const translatedOptionByLabel = i18n?.[input.value]?.options?.[option.label]

        const label = translatedOptionByValue || translatedOptionByLabel || option.label

        return {
          ...option,
          label
        }
      })
    }

    return inputs.map(input => {
      const newObject = {
        ...input,
        label: translateLabel(input, 'label'),
        placeholder: translateLabel(input, 'placeholder'),
        options: translateOptions(input)
      }

      if (input?.labelWithParams && Object.keys(input?.labelWithParams).length) {
        newObject.label = newObject.label(input?.labelWithParams)
      }

      return newObject
    })
  }, [inputs, i18n, translatedOptions])
}
