
interface RevenueInputsTranslatorRes {
  inputs: {
    startDate: {
      label: string
      placeholder: string
    },
    endDate: {
      label: string
      placeholder: string
    },
    status: {
      label: string
      placeholder: string
    },
    serviceName: {
      label: string
      placeholder: string
    }
  }
  errors: {
    startDate: {
      dateFormat: string
      dateRequired: string
      startDateMustBeLessThanOrEqualEndDate: string
    },
    endDate: {
      dateFormat: string
      dateRequired: string
      endDateMustBeAfterOrEqualStartDate: string
    }
  }
}

export const RevenueInputsTranslator = (
  dictionary
): RevenueInputsTranslatorRes => {
  const labels = {
    startDate: {
      label: dictionary?.conciliation_startDate_inputLabel,
      placeholder: dictionary?.conciliation_startDate_placeholderLabel
    },
    endDate: {
      label: dictionary?.conciliation_endDate_inputLabel,
      placeholder: dictionary?.conciliation_endDate_placeholderLabel
    },
    status: {
      label: dictionary?.common_status_label,
      placeholder: dictionary?.common_selectStatuses_label
    },
    supplierId: {
      label: dictionary?.expense_supplier_label,
      placeholder: dictionary?.expense_supplier_label
    },
    serviceName: {
      label: dictionary?.expense_productAndService_label,
      placeholder: dictionary?.expense_productAndService_label
    },
    expenseRevenueId: {
      label: dictionary?.audit_revenueName_label,
      placeholder: dictionary?.audit_revenueName_label
    },
    date: {
      label: dictionary?.common_date_label,
      placeholder: dictionary?.common_date_label
    },
    referenceDate: {
      label: dictionary?.common_referenceDate_label,
      placeholder: dictionary?.common_referenceDate_label
    }
  }

  const errors = {
    startDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_startDateRequired_formErrorLabel,
      startDateMustBeLessThanOrEqualEndDate: dictionary?.common_startDateMustBeLessThanOrEqualEndDate_label
    },
    endDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_endDateRequired_formErrorLabel,
      endDateMustBeAfterOrEqualStartDate: dictionary?.conciliation_endDateMustBeAfterOrEqualStartDate_formErrorLabel
    }
  }

  return {
    inputs: labels,
    errors
  }
}
