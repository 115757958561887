import { useMemo } from 'react'

import {
  bankName,
  branchNumber,
  enabled,
  accountNumber,
  accountablePlans,
  getSchema
} from '../../Form/__BankAccountsBaseInputs'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { BankAccountsInputsTranslator } from '../../i18n/BankAccountsInputsI18n'

export const useBankAccountInputs = (
  accountablePlanOptions,
  enabledOptions
) => {
  const inputsVals = useTranslatedInputs([
    bankName,
    branchNumber,
    accountNumber,
    accountablePlans(accountablePlanOptions),
    enabled(enabledOptions)
  ], BankAccountsInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsVals),
    [inputsVals]
  )

  return {
    validationSchema: schema,
    inputsValid: inputsVals
  }
}
