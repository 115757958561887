import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { SupplierService } from 'Core/Service/Supplier'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SuppliersHooksTranslator } from '../i18n/SuppliersHooksI18n'

export const useCreateSupplier = (fetchSuppliers) => {
  const [createLoading, setCreateLoading] = useState(false)
  const { addToast } = useToast()
  const i18n = useTranslator(SuppliersHooksTranslator)

  const createSupplier = useCallback((params) => {
    setCreateLoading(true)
    return SupplierService.create(params).then(() => {
      addToast({
        defaultMessage: i18n?.createSupplier,
        typeToast: 'success'
      })
      fetchSuppliers()
    }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        dictTranslation: i18n?.apiCodesMappingForSupplier,
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorCreateSupplier
      })
    }).finally(() => {
      setCreateLoading(false)
    })
  }, [addToast, fetchSuppliers, i18n])

  return {
    onCreateSupplier: createSupplier,
    createLoading
  }
}
