/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { CountPlanForm } from '../Form/CountPlanForm'
import { useCreateCountPlan } from '../hooks/useCreateCountPlan'

export const CreateCountPlan = () => {
  const {
    submit,
    loading
  } = useCreateCountPlan()

  return (
    <CountPlanForm submit={submit} loading={loading} />
  )
}
