
export const SupplierBankAccountsFormErrorsTranslator = (
  dictionary
) => {
  const labels = {
    bankName: {
      bankNameRequired: dictionary?.supplier_bank_account_bankNameRequired_formErrorLabel
    },
    branch: {
      branchRequired: dictionary?.supplier_bank_account_branchRequired_formErrorLabel
    },
    accountNumber: {
      accountNumberRequired: dictionary?.supplier_bank_account_accountNumberRequired_formErrorLabel
    },
    accountType: {
      accountTypeRequired: dictionary?.supplier_bank_account_accountTypeRequired_formErrorLabel
    },
    enabled: {
      enabledRequired: dictionary?.supplier_bank_account_enabledRequired_formErrorLabel
    }
  }

  return {
    errors: labels
  }
}
