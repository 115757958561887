import { SafeDate } from '@binamik/js-providers'
import { useTranslatedBankTransfersStatus } from 'Domains/BankAction/BankTransfers/i18n/hooks/useTranslatedBankTransfersStatus'

export const AuditsTranslator = (
  dictionary
) => {
  const emptyAuditData = dictionary?.audit_emptyData_toast
  const defaultModalTitle = dictionary?.audit_defaultModalTitle_label

  const { bankTransfersStatusTranslator } = useTranslatedBankTransfersStatus({ dictionary })

  const auditDictValues = (value) => {
    const dictValues = {
      pix: dictionary?.audit_pixValue_label,
      bank_billet: dictionary?.audit_bankBilletValue_label,
      bank_transfer: dictionary?.audit_bankTransferValue_label,
      credit: dictionary?.audit_creditValue_label,
      debit: dictionary?.audit_debitValue_label,
      cash: dictionary?.audit_cashValue_label,
      create: dictionary?.audit_createValue_label,
      update: dictionary?.audit_updateValue_label,
      expense: dictionary?.audit_expenseValue_label,
      revenue: dictionary?.audit_revenueValue_label,
      savings_account: dictionary?.audit_savingsAccountValue_label,
      checking_account: dictionary?.audit_checkingAccountValue_label,
      scheduled: dictionary?.audit_scheduledValue_label,
      canceled: dictionary?.audit_canceledValue_label,
      payed: dictionary?.audit_payedValue_label,
      received: dictionary?.audit_receivedValue_label,
      refunded: dictionary?.audit_refundedValue_label,
      enabled: dictionary?.audit_enabledValue_label,
      disabled: dictionary?.audit_disabledValue_label,
      dateFormat: dictionary?.common_dateFormat_template,
      guide: dictionary?.audit_guideInvoice_label,
      affiliated: dictionary?.audit_affiliateInoice_label,
      affiliated_transaction: dictionary?.audit_affiliatePayment_label,
      external_partner: dictionary?.audit_offlineInvoice_label,
      external_partner_transaction: dictionary?.audit_offlinePayment_label,
      invoice_payment: dictionary?.audit_invoicePayment_label,
      invoice: dictionary?.audit_invoice_label,
      partial: dictionary?.expense_statusPartial_label
    }

    if (value instanceof SafeDate) {
      return value.format(dictValues.dateFormat)
    }

    return dictValues[value] || bankTransfersStatusTranslator(value) || value
  }

  const auditLabels = {
    created_at: dictionary?.audit_createdAt_label,
    event: dictionary?.audit_event_label,
    user_name: dictionary?.audit_userName_label,
    enabled: dictionary?.audit_enabled_label,
    bank_name: dictionary?.audit_bankName_label,
    value: dictionary?.audit_value_label,
    date: dictionary?.audit_date_label,
    taxes: dictionary?.audit_taxes_label,
    reference_date: dictionary?.audit_referenceDate_label,
    observation: dictionary?.audit_observation_label,
    status: dictionary?.audit_status_label,
    transfer_method: dictionary?.audit_transferMethod_label
  }

  return {
    emptyAuditData,
    defaultModalTitle,
    auditDictValues,
    auditLabels
  }
}
