/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Portal } from 'Shared/components/Portal/Portal'
import { CloseIcon, LoadingAnimation } from '@bonitour/components'
import {
  ModalContainer as Container,
  ModalBg,
  ModalContent as Content
} from './Modal.styles'
import { loadingContainer } from 'Shared/styles/global'

/**
 * @typedef ModalProps
 * @prop { boolean } isOpen
 * @prop { string } title
 * @prop { string } subtitle
 * @prop { string } maxWidth
 * @prop { () => void } onClose
 * @prop { React.Children } children
 */

/** @type { React.FC<ModalProps> } */
export const Modal = (
  {
    isOpen = false,
    onClose,
    children,
    title = '',
    subtitle = '',
    maxWidth = '29.375',
    isLoading = false
  }
) => {
  return isOpen && (
    <Portal selector='__MODALS_PORTAL__'>
      <Container>
        <ModalBg
          type='button'
          onClick={onClose}
          className='modal__close_bg'
        />
        <Content maxWidth={maxWidth}>
          <section className='modal__header'>
            {title && <h2 className='modal__title'>{title}</h2>}
            <button
              className='modal__close_btn'
              aria-label='Fechar'
              onClick={onClose}
              type='button'
            >
              <CloseIcon />
            </button>
          </section>
          {subtitle && <p className='modal__subtitle'>{subtitle}</p>}

          <div className='modal__inner_content'>{
            isLoading
              ? <LoadingAnimation css={loadingContainer} />

              : children
          }
          </div>

        </Content>
      </Container>
    </Portal>
  )
}
