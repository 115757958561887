/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, Column, Row } from '@bonitour/components'
import {
  containerContent,
  labelContent,
  titleContent,
  uppercase,
  rowStyle,
  cardStyle
} from './ReceivablesExpander.styles'

export const ReceivablesExpander = ({
  saleDate,
  saleGrossAmount,
  mdrFee,
  binamikFee,
  cardNumber,
  installment,
  installments,
  gateway,
  gatewayEventTypeDescription
}) => {
  const RenderContent = ({ title, value, titleUppercase = false }) => {
    return (
      <div css={containerContent}>
        <span css={[titleContent, titleUppercase && uppercase]}>{title}</span>
        {value}
      </div>
    )
  }

  const SympleData = ({ text }) => <small css={labelContent}>{text}</small>

  return (
    <Card css={cardStyle}>
      <Row css={rowStyle}>
        <Column desktop={2} phone={12}>
          <RenderContent
            title='Data da transação'
            value={<SympleData text={saleDate} />}
          />
        </Column>
        <Column desktop={3} phone={12}>
          <RenderContent
            title='Número do cartão'
            value={<SympleData text={cardNumber || '-'} />}
          />
        </Column>
        <Column desktop={3} phone={12}>
          <RenderContent
            title='Valor bruto da transação'
            value={<SympleData text={saleGrossAmount} />}
          />
        </Column>
        <Column desktop={2} phone={12}>
          <RenderContent
            title='Taxa MDR'
            value={<SympleData text={mdrFee} />}
          />
        </Column>
        <Column desktop={2} phone={12}>
          <RenderContent
            title='Taxa Binamik'
            value={<SympleData text={binamikFee} />}
          />
        </Column>
      </Row>
      <Row css={rowStyle}>
        <Column desktop={2} phone={12}>
          <RenderContent
            title='Parcela'
            value={<SympleData text={`${installment || '-'} de ${installments || '-'}`} />}
          />
        </Column>
        <Column desktop={3} phone={12}>
          <RenderContent
            title='Adquirente'
            value={<SympleData text={gateway || '-'} />}
          />
        </Column>
        <Column desktop={7} phone={12}>
          <RenderContent
            title='Descrição do Evento na Adquirente'
            value={<SympleData text={gatewayEventTypeDescription || '-'} />}
          />
        </Column>
      </Row>
    </Card>
  )
}
