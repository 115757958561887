/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, PaginationContainer } from '@bonitour/components'
import { BankAccountsList } from './List/BankAccountsList'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useHistory } from 'react-router-dom'
import { useListBankAccounts } from './hooks/useListBankAccounts'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankAccountsTranslator } from './i18n/BankAccountsI18n'
import { BankAccountModal } from './Modal/BankAccountModal'
import { useCallback, useState } from 'react'
import { useEditBankAccount } from './hooks/useEditBankAccount'

export const BankAccounts = () => {
  const {
    bankAccounts,
    meta,
    isLoading,
    perPageOptions,
    handlePagination,
    pagination,
    fetchListBankAccount
  } = useListBankAccounts()

  const {
    fetchBankAccount,
    bankAccount,
    loading: bankAccountLoading,
    onEditBankAccount,
    setBankAccount
  } = useEditBankAccount(fetchListBankAccount)

  const [isBillVisible, setBillVisibility] = useState(false)
  const onBillVisible = useCallback(() => setBillVisibility(true), [])

  const onBillHidden = useCallback(() => {
    setBillVisibility(false)
    setBankAccount(null)
  }, [setBankAccount])

  const onEdit = useCallback((id) => {
    fetchBankAccount(id)
    onBillVisible()
  }, [fetchBankAccount, onBillVisible])

  const { count = 0, last = 1 } = meta ?? {}
  const { push } = useHistory()
  const i18n = useTranslator(BankAccountsTranslator)

  const onReturnToDashboard = useCallback(() => push('/bank-action'), [push])
  return (
    <>
      <PageTitle
        title={i18n.pageTitleBankAccount}
        onClickReturn={onReturnToDashboard}
      />
      <Card>
        <PaginationContainer
          total={count}
          pagesQuantity={last}
          onPagination={handlePagination}
          currentPage={pagination.currentPage}
          pageLimitDefault={pagination.perPage}
          pageLimitOptions={perPageOptions}
          isOpenDrawer
        >
          <BankAccountsList
            bankAccounts={bankAccounts}
            isLoading={isLoading}
            i18n={i18n}
            onEdit={onEdit}
          />
        </PaginationContainer>
      </Card>

      {isBillVisible
        ? (
          <BankAccountModal
            toggleHidden={onBillHidden}
            isVisible
            bankAccountData={bankAccount}
            loading={bankAccountLoading}
            onEdit={onEditBankAccount}
            editModalTitleBankAccounts={i18n.editModalTitleBankAccounts}
          />
        )
        : null}
    </>
  )
}
