import { GhostButton, Button } from '@bonitour/components'
import React from 'react'

/**
 * @typedef BankAccountFormActionsProps
 * @prop { () => void } onClose
 * @prop { () => void } onSubmit
 * @prop { string } saveButton
 * @prop { string } cancelButton
 */

/** @type { React.FC<BankAccountFormActionsProps> } */
export const BankAccountFormActions = ({
  onClose = () => null,
  onSubmit = () => null,
  saveButton,
  cancelButton
}) => {
  return (
    <div className='form__actions'>
      <GhostButton type='reset' className='btn' onClick={onClose}>
        {cancelButton}
      </GhostButton>
      <Button type='button' className='btn' onClick={onSubmit}>
        {saveButton}
      </Button>
    </div>
  )
}
