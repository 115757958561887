import { GoogleTagManagerHeader, GoogleTagManagerBody } from './GoogleTagManager'

const googleTagManagerId = 'GTM-N8DKWFL'

export const HeaderIntegrations = () => {
  GoogleTagManagerHeader(googleTagManagerId)
}

export const BodyIntegrations = () => {
  GoogleTagManagerBody(googleTagManagerId)
}

export const ExternalIntegrations = () => {
  HeaderIntegrations()
  BodyIntegrations()
}
