/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'

import { Tooltip } from './Tooltip'

const LabelContainer = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
`
/**
 * @typedef SimpleTooltip
 * @prop { string } label
 */

/** @type { React.FC<SimpleTooltip> } */
export const SimpleTooltip = ({
  label,
  ...rest
}) => {
  return (
    <Tooltip {...rest}>
      <LabelContainer>{label}</LabelContainer>
    </Tooltip>
  )
}
