import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { useCallback, useMemo } from 'react'
import { useForm } from '@bonitour/app-functions'
import {
  supplierType, supplierDocument, municipalRegistration,
  corporateName, fancyName, emails, phoneNumbers,
  contactNames, observation, zipCode, address, enabled,
  neighborhood, cityName, state, country, getSchema
} from '../Form/__SuppliersBaseInputs'
import { SuppliersInputsTranslator } from '../i18n/SuppliersInputsI18n'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'
import { SuppliersFormErrorsTranslator } from '../i18n/SuppliersFormErrorsI18n'

const BASE_FORM = {}

export const useInputsFormSuppliers = ({ i18n, supplierData, isEditing }) => {
  const inputsVals = useTranslatedInputs([
    supplierType(i18n.selectSupplierTypeOptions),
    supplierDocument,
    municipalRegistration,
    enabled(i18n.supplierEnabledOptions),
    corporateName,
    fancyName,
    emails,
    phoneNumbers,
    contactNames,
    observation,
    zipCode,
    address,
    neighborhood,
    cityName,
    state,
    country
  ], SuppliersInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsVals),
    [inputsVals]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(supplierData || BASE_FORM, schema)

  const filterByPrefix = useCallback((prefix) => inputsVals.filter(({ value }) => value.startsWith(prefix)), [inputsVals])

  const basicRegistrationFieldsInputs = useMemo(() => {
    const fieldsWithPrefix = filterByPrefix('basicRegistration_').map(input => {
      if (input?.value === 'basicRegistration_supplierDocument') {
        input.disabled = !form?.basicRegistration_supplierType
        input.type = form?.basicRegistration_supplierType === 'natural_person' ? 'maskCPF' : 'maskCNPJ'
      }

      return input
    })

    if (!isEditing) {
      return fieldsWithPrefix.filter(({ value }) => value !== 'basicRegistration_enabled')
    }
    return fieldsWithPrefix
  }, [filterByPrefix, form?.basicRegistration_supplierType, isEditing])
  const addressFieldsInputs = useMemo(() => filterByPrefix('address_'), [filterByPrefix])
  const contactDetailsFieldsInputs = useMemo(() => filterByPrefix('contactDetails_'), [filterByPrefix])

  const formErrorsTranslated = useTranslatedFormErrors(
    errors, SuppliersFormErrorsTranslator
  )

  return {
    basicRegistrationFieldsInputs,
    addressFieldsInputs,
    contactDetailsFieldsInputs,
    form,
    onSubmit,
    onInputChange,
    onInputBlur,
    formErrorsTranslated
  }
}
