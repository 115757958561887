import { useCallback, useState } from 'react'
import { SupplierService } from 'Core/Service/Supplier'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SuppliersHooksTranslator } from '../i18n/SuppliersHooksI18n'

export const useSupplierAudit = () => {
  const { addToast } = useToast()
  const i18n = useTranslator(SuppliersHooksTranslator)
  const [datas, setDatas] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchSupplierAudit = useCallback((supplierId) => {
    if (!supplierId) return
    setLoading(true)
    return SupplierService.getSupplierAudit(supplierId)
      .then(setDatas)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorAuditSupplier
        })
      }).finally(() => {
        setLoading(false)
      })
  }, [addToast, i18n])

  return {
    fetchSupplierAudit,
    datas,
    loading
  }
}
