import { useEffect, useMemo } from 'react'

import {
  date,
  referenceDate,
  expenseRevenues,
  taxes,
  value,
  observation,
  serviceName,
  status,
  bankAccounts,
  paymentMethods,
  receipts,
  userName,
  createdAt,
  suppliersWithExtraOptionNone,
  getSchema
} from '../../../Form/__BaseInputs'
import { expenseInitialStatusOptions } from 'Domains/ExpenseRevenue/constants/expenseRevenue'
import { useForm } from '@bonitour/app-functions'
import { useBankAccountsOptions } from 'Domains/BankAction/BankAccounts/hooks/useBankAccountsOptions'
import { ExpenseParsed } from 'Core/Types/Expense'
import { useExpenseAllowedStatus } from '../useExpenseAllowedStatus'
import { useSuppliersOptions } from 'Domains/ExpenseRevenue/Suppliers/hooks/useSupplierOptions'
import { useExpenseRevenuesOptions } from 'Domains/ExpenseRevenue/ExpenseRevenueType/hooks/useExpenseRevenuesOptions'
import { ExpensesInputsTranslator } from '../../i18n/ExpensesInputsI18n'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { expensesStatus } from 'Domains/ExpenseRevenue/constants/expensesStatus'

const BASE_FORM_VAL = {}

interface UseExpenseInputsProps {
  currentFormData: ExpenseParsed
  expenseId: string
  inputsReadingOnly: boolean
}

export const useExpenseInputs = (
  {
    currentFormData,
    expenseId,
    inputsReadingOnly
  }: UseExpenseInputsProps
) => {
  const {
    bankAccountsOptions,
    paymentMethodsOptions
  } = useBankAccountsOptions()

  const { fetchAllowedStatus, allowedStatusOptions } = useExpenseAllowedStatus()

  const isEdit = useMemo(() => Boolean(currentFormData), [currentFormData])

  useEffect(() => {
    if (!expenseId) return

    fetchAllowedStatus(expenseId)
  }, [expenseId, fetchAllowedStatus])

  const { suppliersData } = useSuppliersOptions()

  const { expenseRevenuesOptions } = useExpenseRevenuesOptions(true)

  const statusOptions = useMemo(() => {
    return expenseId ? allowedStatusOptions : expenseInitialStatusOptions
  }, [allowedStatusOptions, expenseId])

  const inputs = useTranslatedInputs(
    [
      date,
      referenceDate,
      suppliersWithExtraOptionNone(suppliersData),
      serviceName,
      expenseRevenues('', expenseRevenuesOptions),
      status(statusOptions),
      bankAccounts(bankAccountsOptions),
      paymentMethods,
      value,
      taxes,
      userName,
      createdAt,
      observation,
      receipts
    ], ExpensesInputsTranslator
  )

  const schema = useMemo(
    () => getSchema(inputs),
    [inputs]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(currentFormData || BASE_FORM_VAL, schema)

  const newInputsValid = useMemo(() => inputs.filter(({ value }) => {
    switch (value) {
      case 'bankAccountId':
        return (form?.bankAccountId || [expensesStatus?.payed].includes(form.status))
      case 'paymentMethod':
        return (form?.paymentMethod || [expensesStatus?.payed].includes(form.status))
      case 'receipts':
        return !inputsReadingOnly
      case 'userName':
      case 'createdAt':
        return isEdit
      default:
        return true
    }
  }).map(input => {
    if (input?.value === 'paymentMethod' && !currentFormData?.externalOriginId) {
      input.options = paymentMethodsOptions(form?.bankAccountId)
    }

    if (inputsReadingOnly) {
      input.readOnly = true
    }

    return input
  }), [
    currentFormData,
    form?.bankAccountId,
    form?.paymentMethod,
    form.status,
    inputs,
    paymentMethodsOptions,
    inputsReadingOnly,
    isEdit
  ])

  return {
    validationSchema: schema,
    inputsValid: newInputsValid,
    form,
    errors,
    onSubmit,
    onInputChange,
    onInputBlur
  }
}
