/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { NavLink } from 'react-router-dom'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { CardButton } from 'Shared/components/TitleMenus/CardButton'
import {
  BillIcon,
  CashbackIcon,
  CashierIcon,
  Label,
  ReportsIcon
} from '@bonitour/components'
import { BankActionDashboardContainer } from './BankActionDashboard.styles'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankActionDashboardTranslator } from './i18n/BankActionDashboardI8n'
import { CustomFeatureEnabler } from 'Shared/components/CustomFeatureEnabler/CustomFeatureEnabler'

export const BankActionDashboard = () => {
  const i18n = useTranslator(BankActionDashboardTranslator)

  return (
    <>
      <PageTitle
        title={i18n?.pageTitleBankActions}
      />
      <BankActionDashboardContainer>
        <CustomFeatureEnabler featureKey='billing-bank-statements'>
          <NavLink to='bank-action/bank-statements'>
            <CardButton>
              <ReportsIcon className='icon' />
              <Label className='label'>{i18n?.titleBankStatements}</Label>
            </CardButton>
          </NavLink>
        </CustomFeatureEnabler>

        <CustomFeatureEnabler featureKey='billing-bank-conciliations'>
          <NavLink to='bank-action/conciliations'>
            <CardButton>
              <ReportsIcon className='icon'/>
              <Label className='label'>{i18n?.titleBankConciliations}</Label>
            </CardButton>
          </NavLink>
        </CustomFeatureEnabler>

        <NavLink to='bank-action/bank-accounts'>
          <CardButton>
            <BillIcon className='icon' />
            <Label className='label'>{i18n?.pageTitleBankAccounts}</Label>
          </CardButton>
        </NavLink>

        <CustomFeatureEnabler featureKey='billing-bank-transfers'>
          <NavLink to='bank-action/bank-transfers'>
            <CardButton>
              <CashbackIcon className='icon' />
              <Label className='label'>
                {i18n?.transferBetweenBankAccount}
              </Label>
            </CardButton>
          </NavLink>
        </CustomFeatureEnabler>

        <CustomFeatureEnabler featureKey='billing-cash-deposits'>
          <NavLink to='bank-action/cash-deposits'>
            <CardButton>
              <CashierIcon className='icon' />
              <Label className='label'>
                Deposito em dinheiro
              </Label>
            </CardButton>
          </NavLink>
        </CustomFeatureEnabler>

      </BankActionDashboardContainer>
    </>
  )
}
