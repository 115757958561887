import { useCallback, useState } from 'react'
import { BankAccountService } from 'Core/Service/BankAccount'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankAccountsHooksTranslator } from '../i18n/BankAccountsHooksI18n'

export const useBankAccountAudit = () => {
  const { addToast } = useToast()
  const [datas, setDatas] = useState([])
  const [loading, setLoading] = useState(false)

  const i18n = useTranslator(BankAccountsHooksTranslator)

  const fetchBankAccountAudit = useCallback((bankAccountId) => {
    if (!bankAccountId) return
    setLoading(true)
    return BankAccountService.getBankAccountAudit(bankAccountId)
      .then(setDatas)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorLoadingBankAccountHistory
        })
      }).finally(() => {
        setLoading(false)
      })
  }, [addToast, i18n?.errorLoadingBankAccountHistory])

  return {
    fetchBankAccountAudit,
    datas,
    loading
  }
}
