/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { customSelect } from 'Shared/styles/global'
import {
  Button,
  Select,
  InputFormGroup,
  Card,
  Column,
  Row,
  DatePicker
} from '@bonitour/components'
import {
  containerFilters,
  customDate,
  buttonSearch
} from './BankStatementsFilters.styles'

/**
 * @typedef BankStatementsFilters
 * @prop { (filters: Record<string, string>) => void } onFilterSubmit
 * @prop { Record<string, string> } form
 * @prop { Array<Record<string, string>> } bankAccountsOptions
 * @prop { Record<string, string> } errors
 * @prop { () => void } onSubmit
 * @prop { () => void } onInputChange
 * @prop { () => void } onInputBlur
 */

/** @type { React.FC<BankStatementsFiltersProps> } */
export const BankStatementsFilters = (
  {
    onFilterSubmit,
    bankAccountsOptions,
    onInputBlur,
    onInputChange,
    form,
    errors,
    onSubmit
  }
) => {
  return (
    <Card customCss={[containerFilters]}>
      <Row>
        <Column desktop={2.91} phone={12}>
          <InputFormGroup label='Conta bancária' errorMessage={errors.bankAccountId}>
            <Select
              id='bankAccountId'
              placeholder='Selecione uma conta'
              value={form?.bankAccountId}
              onChange={onInputChange('bankAccountId')}
              onBlur={onInputBlur('bankAccountId')}
              options={bankAccountsOptions}
              customCss={[customSelect]}
            />
          </InputFormGroup>
        </Column>
        <Column desktop={3} phone={12}>
          <InputFormGroup label='Data inicial' errorMessage={errors.startDate}>
            <DatePicker
              id='startDate'
              css={[customDate]}
              value={form?.startDate}
              onChange={onInputChange('startDate')}
              onBlur={onInputBlur('startDate')}
              placeholder='Selecione ou digite uma data'
              allowsEmpty
            />
          </InputFormGroup>
        </Column>
        <Column desktop={3} phone={12}>
          <InputFormGroup label='Data final' errorMessage={errors.endDate}>
            <DatePicker
              id='endDate'
              css={[customDate]}
              value={form?.endDate}
              onChange={onInputChange('endDate')}
              onBlur={onInputBlur('endDate')}
              placeholder='Selecione ou digite uma data'
              allowsEmpty
            />
          </InputFormGroup>
        </Column>
        <Column desktop={3} phone={12}>
          <Button css={[buttonSearch]} onClick={onSubmit(onFilterSubmit)}>
            Pesquisar
          </Button>
        </Column>
      </Row>
    </Card>
  )
}
