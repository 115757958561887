import { useCallback, useEffect, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'
import { EventsProcessorsService } from 'Core/Service/eventsProcessors'

export const useGetAutomationRuleActions = () => {
  const [loading, setLoading] = useState(false)
  const [automationRuleActionsData, setAutomationRuleActionsData] = useState()
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const getAutomationRuleActions = useCallback(() => {
    setLoading(true)
    return EventsProcessorsService.getAutomationRuleActions()
      .then(setAutomationRuleActionsData)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorFetchingAutomationRuleActions
        })
      })
      .finally(() => setLoading(false))
  }, [addToast, i18n?.errorFetchingAutomationRuleActions])

  useEffect(() => {
    if (automationRuleActionsData) return
    getAutomationRuleActions()
  }, [automationRuleActionsData, getAutomationRuleActions])

  return {
    loading,
    automationRuleActionsData
  }
}
