export const revenueStatusOptionsSearch = [
  {
    value: 'scheduled',
    label: 'Agendado'
  },
  {
    value: 'canceled',
    label: 'Cancelado'
  },
  {
    value: 'received',
    label: 'Recebido'
  },
  {
    value: 'refunded',
    label: 'Estornado'
  }
]
