/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import {
  selectDateContainer
} from './FinancialMovements.styles'
import {
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableContainer,
  PaginationContainer
} from '@bonitour/components'
import { FullFinancialScheduleTranslator } from '../i18n/FullFinancialScheduleI18n'
import { useTranslator } from 'Shared/language/i18nHandler'

/**
 * @typedef FinancialMovementsProps
 * @prop { { count: number, last: number } } meta
 * @prop { () => void } handlePagination
 * @prop { boolean } hasDate
 * @prop { string[] } columns
 * @prop { number[] } perPageOptions
 * @prop { { currentPage: number, perPage: number } } pagination
 */

/** @type { React.FC<FinancialMovementsProps> } */
export const FinancialMovements = (
  {
    children,
    meta = {},
    handlePagination = identity,
    hasDate = false,
    columns = [],
    perPageOptions,
    pagination
  }
) => {
  const { count = 0, last = 1 } = meta ?? {}

  const i18n = useTranslator(FullFinancialScheduleTranslator)

  if (!hasDate) {
    return (
      <div css={[selectDateContainer]}>
        <small>{i18n?.selectAperiodToViewMovements}</small>
      </div>
    )
  }

  return (
    <PaginationContainer
      total={count}
      currentPage={pagination.currentPage}
      pageLimitDefault={pagination.perPage}
      pagesQuantity={last}
      onPagination={handlePagination}
      pageLimitOptions={perPageOptions}
      isOpenDrawer
    >
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
          </TableHeaderRow>
        </TableHeader>
        {children}
      </TableContainer>

    </PaginationContainer>
  )
}
