export const expenseRevenueTypeListColumnsTitles = [
  'Descrição',
  'Tipo',
  'Status',
  'Ação'
]

export const defineExpenseRevenueType = {
  expense: 'Despesa',
  revenue: 'Receita'
}

export const selectExpenseRevenueTypeOptions = [
  {
    value: '',
    label: 'Todos'
  },
  {
    value: 'expense',
    label: 'Despesa'
  },
  {
    value: 'revenue',
    label: 'Receita'
  }
]

export const selectExpenseRevenueStatusOptions = [
  {
    value: '',
    label: 'Todos'
  },
  {
    value: true,
    label: 'Ativo'
  },
  {
    value: 'false',
    label: 'Inativo'
  }
]

export const expenseRevenueTypeSelectOptions = [
  {
    value: 'revenue',
    label: 'Receita'
  },
  {
    value: 'expense',
    label: 'Despesa'
  }
]

export const expenseRevenueTypeStatusSelectOptions = [
  {
    value: true,
    label: 'Ativo'
  },
  {
    value: false,
    label: 'Inativo'
  }
]

export const defineChangeModalTitle = {
  true: 'Editar tipo de despesa ou receita',
  false: 'Novo tipo de despesa ou receita'
}
