/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { TableBody, TableContainer } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useCallback, useMemo } from 'react'
import { BaseTableBody } from './BaseTableBody'
import { TableHeaders } from './TableHeaders'

/**
 * @typedef BaseTableProps
 * @prop { boolean } isLoading
 * @prop { Record<string, string>[] } datas
 * @prop { string[] } columnsTitles
 * @prop { React.FC } [colGroupComponent]
 * @prop { Record<string, string> } i18n
 * @prop { string[] } selectedIds
 * @prop { (ids: string[]) => void} setSelectedIds
 */

/** @type { React.FC<BaseTableProps> } */
export const BaseTable = (
  {
    isLoading,
    datas,
    columnsTitles,
    colGroupComponent,
    i18n,
    selectedIds,
    setSelectedIds
  }
) => {
  const allSelected = useMemo(
    () => datas?.length && Boolean(selectedIds?.length === datas?.length),
    [datas?.length, selectedIds?.length]
  )

  const handleOnSelectAllRow = useCallback(
    ev => {
      if (ev.target.checked) {
        return setSelectedIds(datas?.map(({ id }) => id))
      } else {
        return setSelectedIds([])
      }
    },
    [datas, setSelectedIds]
  )

  const selectedId = useCallback((id) => selectedIds?.includes(id), [selectedIds])

  const handleOnSelectRow = useCallback((selectId) => {
    const removeIds = selectedIds?.filter((id) => id !== selectId)
    const newArrayIds = selectedId(selectId) ? removeIds : [...selectedIds, selectId]
    setSelectedIds(newArrayIds)
  }, [selectedId, selectedIds, setSelectedIds])

  return (
    <TableContainer>
      {colGroupComponent || null}
      <TableHeaders
        options={
          {
            allSelected,
            handleOnSelectAllRow,
            withCheckBox: true,
            disabled: !!isLoading || !datas?.length
          }
        }
        columnsTitles={columnsTitles}
      />
      <TableBody loading={isLoading}>
        {datas.map(
          ({
            id,
            values
          }) => (
            <BaseTableBody
              key={id}
              tableBodyOptions={
                {
                  id,
                  handleOnSelectRow,
                  selectedId,
                  withCheckBox: true,
                  disabled: !!isLoading,
                  i18n
                }
              }
              datas={values}
            />
          ))}
      </TableBody>
    </TableContainer>
  )
}
