/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { CountPlanForm } from '../Form/CountPlanForm'
import { useEditCountPlan } from '../hooks/useEditCountPlan'

export const EditCountPlan = () => {
  const {
    countPlan,
    submit,
    loading
  } = useEditCountPlan()

  return (
    <CountPlanForm submit={submit} loading={loading} countPlan={countPlan} />
  )
}
