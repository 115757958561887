import { css } from '@emotion/core'
import { colors } from '@bonitour/components'
import styled from '@emotion/styled'

export const TitleMenusContainer = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;

  * {
    box-sizing: border-box;
  }

  .card_item__content {
    height: 100%;
  }
`

export const cardIcon = css`
  font-size: 20px;
`

export const labelCardButton = css`
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: var(--clr-gray3);
`

export const angleDownStyle = css`
  font-size: 14px;
`

export const linkColor = css`
  color: ${colors.gray1};
  padding: 5px;
  margin: 5px 0;
`

export const cardItems = css`
  min-width: 120px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  margin-right: -40px;
`

export const cardItemIcon = css`
  min-height: 28px;
`

export const resetLink = css`
  text-decoration: none;
`
export const margin = css`
  margin: 0px;
`
