import React from 'react'
import { colors, flexRowCentered } from '@bonitour/components'
import { css } from '@emotion/core'
import { Global } from '@emotion/react'
import styled from '@emotion/styled'

export const cardButton = css`
  width: 100px;
  padding: 10px 20px;
  min-width: 140px;
`
export const cardIcon = css`
  width: 32px;
  height: 32px;
  padding: 5px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: var(--round-element-radius, 100%);
`

export const labelCardButton = css`
  width: 100%;
  cursor: pointer;
`
export const loadingContainer = css`
  ${flexRowCentered};
  width: 100%;
  margin: 50px 0;
`

export const loadingAnimation = css`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 5rem;
`

export const hideLoad = css`
  display: none;
`

export const customSelect = css`
  .component-drawer-item {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12.5rem;
  }

`

export const iconButton = css`
  cursor: pointer;
  font-size: 1.375rem;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: var(--clr-gray3);
`

/**
 * @typedef {Object} CircleStatusProps
 * @property {string} statusColor
 */

/**
 * @type {React.FC<CircleStatusProps>}
 */
export const CircleStatus = styled.span`
  display: flex;
  gap: 5px;
  align-items: center;
  &::before {
    content: '';
    display: inline-block;
    width: 0.625rem;
    height: 0.625rem;
    background-color: var(${({ statusColor }) => statusColor || '--clr-gray7'});
    border-radius: 100vw;
  }
`

/**
 * @typedef {Object} ValueColorProps
 * @property {string} statusColor
 */

/**
 * @type {React.FC<ValueColorProps>}
 */
export const ValueColor = styled.span`
  color: var(${({ statusColor }) => statusColor || '--clr-gray7'});
`

export const wrapperButtons = css`
  display: flex;
  align-items: center;
  gap: 0.625rem;

  .b-font-edit-info, .b-font-trash {
    ${iconButton}
  }
`

export const wrapperFiltersList = css`
  margin-top: 1.5625rem;
`

const globalCss = css`
  @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700&display=swap");

  :root {
    --clr-white: #ffffff;
    --clr-black: #000000;

    --clr-white-overlay: rgba(255, 255, 255, 0.8);

    --clr-gray1: #4D4D4D;
    --clr-gray2: #666666;
    --clr-gray3: #6A6A6A;
    --clr-gray4: #959595;
    --clr-gray5: #AAAAAA;
    --clr-gray6: #BABABA;
    --clr-gray7: #D6D6D6;
    --clr-gray8: #E6E6E6;
    --clr-gray9: #EAEAEA;
    --clr-gray10: #EDEDED;
    --clr-gray11: #F0F0F0;
    --clr-gray12: #F4F4F4;
    --clr-gray13: #F7F7F7;
    --clr-gray14: #FAFAFA;
    --clr-gray15: #FCFCFC;

    --clr-blue1: #286C81;
    --clr-blue2: #55A6AC;
    --clr-blue3: #1ED6BB;
    --clr-blue4: #007aff;
    --clr-blue-grad1: linear-gradient(90deg, rgba(85,166,172,1) 0%, rgba(40,108,129,1) 100%);
    --clr-blue-grad2: linear-gradient(46deg, #0096A2 0%, #1ED6BB 100%);
    --clr-blue-grad3: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 100%);

    --clr-green1: #188955;
    --clr-green2: #10bd6d;
    --clr-green3: #38B54B;
    --clr-green4: #F4FAFA;
    --clr-green5: #E2F4EC;
    --clr-green6: #218430;
    --clr-green7: #2aa747;
    --clr-green-grad1: linear-gradient(90deg, rgba(16,189,109,1) 0%, rgba(24,137,85,1) 100%);

    --clr-indigo1: #4D4DFF;
    --clr-indigo1-t08: rgba(77, 77, 255, 0.05);
    --clr-indigo2: #7E7EFC;
    --clr-indigo3: #EDEDFF;
    --clr-indigoGradient1: linear-gradient(90deg, rgba(126,126,252,1) 0%, rgba(77,77,255,1) 100%);

    --clr-orange1: #FF8000;
    --clr-orange2: #DD9E41;
    --clr-orange3: #F6A742;
    --clr-orange4: #FFC164;
    --clr-orange5: #FAF2E7;
    --clr-orange6: #CB901A;

    --clr-red1: #E22521;
    --clr-red2: #FF0000;
    --clr-red3: #FF6461;
    --clr-red4: #F64242;
    --clr-red5: #F26D6D;
    --clr-red6: #FBEBEB;
    --clr-red-grad1: linear-gradient(90deg, rgb(246, 66, 66) 0%, rgb(242, 109, 109) 100%);

    --clr-yellow1: #FDBB4E;
    --clr-yellow2: #F8A520;
    --clr-yellow3: #F2BB4E;

    --clr-purple1: #7E7EFCFA;
    --clr-purple2: #8080FC;

    --clr-primary: var(--clr-indigo1);
    --clr-primary-t08: var(--clr-indigo1-t08);
    --clr-primary2: var(--clr-indigo2);
    --clr-secondary: var(--clr-yellow1);

    --clr-warning: var(--clr-orange4);
    --clr-warning2: var(--clr-orange5);

    --clr-success: var(--clr-green3);
    --clr-success2: var(--clr-green5);

    --clr-danger: var(--clr-red3);
    --clr-danger2: var(--clr-red6);

    --clr-info: var(--clr-gray5);
    --clr-info2: var(--clr-gray14);

    --clr-dark: var(--clr-gray2);
    --clr-disabled: var(--clr-gray11);

    --fs-2xs: 0.625rem; // 10px
    --fs-xs: 0.75rem; // 12px
    --fs-sm: 0.875rem; // 14px
    --fs-md: 1rem; // 16px
    --fs-lg: 1.125rem; // 18px
    --fs-xl: 1.25rem; // 20px
    --fs-2xl: 1.5rem; // 24px
    --fs-3xl: 2rem; // 32px
    --fs-4xl: 3rem; // 48px

    --bp-tablet: 64rem; // 1024px
  }

  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
  blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em,
  img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u,
  i, center, dl, dt, dd, menu, ol, ul, li, fieldset, form, label, legend, table,
  caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
  embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output,
  ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, footer, header, hgroup, main,
  menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
    color: var(--clr-gray1);
    background: var(--clr-gray13);
  }
  menu, ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  // Custom values
  *, :before, :after {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
  }

  button {
    color: inherit;
    cursor: pointer;
  }

  button:disabled {
    cursor: default;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  body, input, textarea, select, textarea, button {
    font-family: "Mulish", sans-serif;
    font-size: inherit;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    /* Rounded Square appearence: */
    --round-element-radius: 10px;
    --small-round-element-radius: 6px;
  }

  body {
    position: relative;
  }

  img {
    position: relative;
  }

  img[alt]:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #D6D6D6;
    content: '';
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  h1 {
    font-size: var(--fs-3xl);
  }

  h2 {
    font-size: var(--fs-2xl);
  }

  h3 {
    font-size: var(--fs-xl);
  }

  h4 {
    font-size: var(--fs-l);
  }

  @-webkit-keyframes autofill {
    0%,100% {
      background: transparent;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-animation-delay: 9999999s; /* 115 days without the anoying auto complete background */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    -webkit-background-clip: text;
  }

  .noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  .primary_color_txt {
    color: var(--clr-primary);
  }

  button {
    border: none;
    background: none;
  }

  .wrapper__btn_actions {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    .b-font-edit-info,
    .b-font-trash,
    .b-font-eye {
      ${iconButton}
    }
  }
`

export const GlobalStyle = () => <Global styles={[globalCss]} />
