/* eslint-disable camelcase */

import { SafeDate } from '@binamik/js-providers'

export const BankConciliationModel = {
  conciliationParamsParse: (params) => {
    const {
      startDate,
      endDate,
      bankAccountId,
      gateway
    } = params ?? {}
    const safeStartDate = startDate ? new SafeDate(startDate).format('YYYY-MM-DD') : undefined
    const safeEndDate = endDate ? new SafeDate(endDate).format('YYYY-MM-DD') : undefined

    return {
      start_date: safeStartDate,
      end_date: safeEndDate,
      conciliation_type: 'BankStatement',
      conciliate_with: 'bank_account',
      bank_account_id: bankAccountId,
      gateway
    }
  },
  getBankConciliations: (filters) => {
    return BankConciliationModel.conciliationParamsParse(filters)
  },
  postAutoBankConciliation: (params, user) => {
    return {
      ...BankConciliationModel.conciliationParamsParse(params),
      user_name: user?.name || ''
    }
  },
  postManualBankConciliation: (params, user) => {
    return {
      ...BankConciliationModel.conciliationParamsParse(params),
      start_date: undefined,
      end_date: undefined,
      user_name: user?.name || '',
      bank_statement_ids: params?.externalMovementsIds,
      account_movement_ids: params?.internalMovementsIds
    }
  },
  deleteBankConciliations: (params, user) => {
    return {
      ...BankConciliationModel.conciliationParamsParse(params),
      start_date: undefined,
      end_date: undefined,
      user_name: user?.name || '',
      conciliation_ids: params?.conciliationsIds
    }
  }
}
