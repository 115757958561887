import { string, date, ref } from 'yup'

export const BankStatementsFiltersSchema = {
  bankAccountId: string().required('Conta bancária é obrigatória'),
  startDate: date().typeError('Data deve ter o formato dd/mm/yyyy')
    .required('Data inicial é obrigatória'),
  endDate: date()
    .required('Data final é obrigatória')
    .typeError('Data deve ter o formato dd/mm/yyyy').min(
      ref('startDate'),
      'Data final deve ser após ou igual a data inicial'
    )
}
