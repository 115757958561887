export const BREAK_POINTS = {
  largeScreen: '125rem', // 2000px
  hdDesktop: '100rem', //   1600px
  desktop: '80rem', //      1280px
  tablet: '64rem', //       1024px
  smallTablet: '48rem', //   768px
  tabletUp: '37rem', //      592px
  bigPhone: '30rem', //      480px
  phone: '26.25rem', //      420px
  smallPhone: '22.5rem' //   360px
}
