import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'
import { EventsProcessorsService } from 'Core/Service/eventsProcessors'

export const useRemoveAutomationRule = () => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const removeAutomationRule = useCallback((eventProcessorId, automationRuleId) => {
    setLoading(true)
    return EventsProcessorsService.removeAutomationRule(eventProcessorId, automationRuleId)
      .then(() => {
        addToast({
          typeToast: 'success',
          defaultMessage: i18n?.automationRuleSuccessRemoved
        })
      })
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorRemovingAutomationRule
        })
        throw errors
      })
      .finally(() => setLoading(false))
  }, [addToast, i18n?.automationRuleSuccessRemoved, i18n?.errorRemovingAutomationRule])

  return {
    removeAutomationRule,
    loading
  }
}
