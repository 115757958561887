
export const BankAccountsModel = {
  list: (pagination) => {
    const {
      currentPage,
      perPage
    } = pagination ?? {}

    return {
      pagination: {
        per_page: perPage,
        page: currentPage
      }

    }
  },
  edit: (bankAccount, user) => {
    const {
      accountablePlanId
    } = bankAccount ?? {}

    return {
      accountable_plan_id: accountablePlanId,
      user_name: BankAccountsModel.safeUser(user)
    }
  },
  safeUser (user = {}) {
    const safeUserName = user?.name || ''
    return safeUserName
  }
}
