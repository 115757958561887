import { useState, useCallback } from 'react'
import { BankStatementsService } from 'Core/Service/BankStatement'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankStatementsHooksTranslator } from '../i18n/BankStatementsHooksI18n'

export const useUploadBankStatementsFileImport = (setSelectedBankAccountId) => {
  const { addToast } = useToast()
  const [isLoadUpload, setIsLoadUpload] = useState(false)
  const [bankStatementsFileImportRes, setBankStatementsFileImportRes] = useState(
    {
      ignoredCurrentDateTransactions: 0,
      importedTransactions: 0,
      transactions: 0,
      details: []
    }
  )

  const i18n = useTranslator(BankStatementsHooksTranslator)

  const onUploadBankStatementsFile = useCallback((params) => {
    setIsLoadUpload(true)
    const response = BankStatementsService.uploadBankStatementsFile({ uploadParams: params }).then(
      ({ ignoredCurrentDateTransactions, importedTransactions, transactions, details }) => {
        setBankStatementsFileImportRes(
          { ignoredCurrentDateTransactions, importedTransactions, transactions, details }
        )
        return true
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        dictTranslation: i18n?.apiCodesMappingsForBankStatements,
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorExportBankStatementFile
      })
      return false
    }).finally(() => {
      setIsLoadUpload(false)
    })
    setSelectedBankAccountId('')
    return response
  }, [
    addToast,
    i18n?.errorExportBankStatementFile,
    setSelectedBankAccountId,
    i18n?.apiCodesMappingsForBankStatements
  ])

  return {
    isLoadUpload,
    bankStatementsFileImportRes,
    setBankStatementsFileImportRes,
    onUploadBankStatementsFile
  }
}
