import styled from '@emotion/styled'

export const EventsRequestDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  border: 1px solid var(--clr-gray8);
  border-radius: 0.625rem;
  padding: 1.25rem;

  .btn__consult_event {
    width: 25rem;
  }

  .container__table {
    max-height: 18.75rem;
    overflow: auto;

    thead {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
    }

    .td_btn__add_processor {
      width: 14.375rem;
    }

    .safe_overflow__container {
      max-width: 21.875rem;
    }
  }
`
