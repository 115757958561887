import styled from '@emotion/styled'
import { colors } from '@bonitour/components'

export const ModalContent = styled.div`
    * {
        box-sizing: border-box;
    }

    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;

    .modal__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;

        width: 100%;
        max-width: 29.375rem;
        max-height: calc(100% - 2rem);
        box-sizing: border-box;
        border-radius: 0.625rem;
        padding: 1.875rem;
        background: white;

        z-index: 4;
        opacity: 1;
        transform: translateY(0);
        overflow-y: auto;

        .close-modal-button {
            --close-modal-button-size: 1.875rem;
            height: var(--close-modal-button-size);
            width: var(--close-modal-button-size);
            min-width: var(--close-modal-button-size);
            min-height: var(--close-modal-button-size);
            border-radius: 100vw;
            top: calc(var(--close-modal-button-size) / 2);
            right: calc(var(--close-modal-button-size) / 2);

            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;

            background: ${colors.indigo2};;
            color: white;
            margin-left: 0.625rem;
        }

        .modal__info {
            .modal__info_title {
                max-width: 16.8125rem;
                font-size: 1.5rem;
                font-weight: 700;
                color: ${colors.gray1};
                margin-bottom: 1.25rem;
                margin-right: 0.625rem;
            }

            .modal__info_subtitle {
                margin-top: 1.25rem;
                font-size: 0.875rem;
                color: ${colors.gray3};
            }
        }

        .button__wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 1.875rem;

            button {
                border: none;
                width: 100%;
                height: 100%;
                min-height: 2.5rem;
                min-width: 6.25rem;
                border-radius: var(--round-element-radius, 1.25rem);
                padding: 0.625rem;
                cursor: pointer;
                font-size: 0.875rem;
                font-weight: 700;

                &:first-of-type {
                    margin-right: 1.25rem;
                    color: white;
                    background-color: ${colors.indigo1};
                }

                &:last-of-type {
                    border: 1px solid ${colors.gray4};
                    color: ${colors.gray4};
                    background-color: white;
                }
            }
        }
    }

`

export const ModalBg = styled.button`
  background-color: #000;
  opacity: 0.3;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  outline: none;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 3;
`
