import styled from '@emotion/styled'

export const SupplierBankAccountFormContainer = styled.div`
  min-height: 10rem;
  max-width: 10rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;

  .form__inputs {
    display: grid;
    gap: 0.625rem;
    grid-template-columns: repeat(5, 1fr);
  }

  .input__account_type {
    min-width: 15rem;
  }

  .input__enabled {
    min-width: 15rem;
  }
`
export const SupplierBankAccountFormButtons = styled.div`
  margin-top: 1.875rem;

  button {
    width: 12.5rem;
    &:first-of-type {
      margin-right: 1.25rem;
    }
  }
`
