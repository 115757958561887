/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FormsInputs } from '../../../Shared/components/Form/__FormInputs'
import { EventsProcessorsFiltersContainer } from './EventsProcessors.styles'
import { Button } from '@bonitour/components'
import { useEventsProcessorsFiltersInputs } from '../hooks/inputs/useEventsProcessorsFiltersInputs'

/**
 * @typedef EventsProcessorsFiltersProps
 * @prop { (filters: Record<string, string>) => void } onFilterSubmit
 * @prop { Record<string, string> } filters
 * @prop { boolean } isLoading
 * @prop { string } buttonLabel
 */

/** @type { React.FC<EventsProcessorsFiltersProps> } */
export const EventsProcessorsFilters = (
  {
    onFilterSubmit,
    filters,
    isLoading,
    buttonLabel
  }
) => {
  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useEventsProcessorsFiltersInputs(
    filters
  )

  return (
    <EventsProcessorsFiltersContainer>
      <FormsInputs
        inputs={inputsValid}
        formErrors={errors}
        formValues={form}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      >
        <Button
          type='button'
          disabled={!!isLoading}
          onClick={onSubmit(onFilterSubmit)}
          className='events_processors_filters__btn'
        >
          {buttonLabel}
        </Button>
      </FormsInputs>
    </EventsProcessorsFiltersContainer>
  )
}
