/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { EventsListContainer, WrapperSortRightIcon, tdDetails } from './EventsList.styles'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsTranslator } from '../i18n/EventsI18n'
import { EventsRowAnnexDetails } from './EventsRowAnnexDetails'
import {
  RadioInputGroup,
  SortRightIcon,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow
} from '@bonitour/components'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'

/**
 * @typedef EventsListProps
 * @prop { boolean } isLoading
 * @prop { boolean } isSelectEvent
 * @prop { (id: string) => void} handleSelectEventId
 * @prop { string } selectedEventId
 * @prop { Record<string, string>[] } eventsDatas
 */

/** @type { React.FC<EventsListProps> } */
export const EventsList = (
  {
    isLoading,
    eventsDatas,
    isSelectEvent,
    handleSelectEventId,
    selectedEventId
  }
) => {
  const [selectedIdsDetails, setSelectedIdsDetails] = useState([])

  const selectedIds = useCallback((id) => selectedIdsDetails?.includes(id), [selectedIdsDetails])
  const handleOnSelectRowDetails = useCallback((selectId) => {
    const removeIds = selectedIdsDetails?.filter((id) => id !== selectId)
    const newArrayIds = selectedIds(selectId) ? removeIds : [...selectedIdsDetails, selectId]
    setSelectedIdsDetails(newArrayIds)
  }, [selectedIds, selectedIdsDetails])

  useEffect(() => {
    setSelectedIdsDetails([])
  }, [eventsDatas])

  const i18n = useTranslator(EventsTranslator)

  const onEventSelect = useCallback((eventId) => (browserEvent) => {
    handleSelectEventId(eventId)
    browserEvent.stopPropagation()
  }, [handleSelectEventId])

  return (
    <EventsListContainer>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            {isSelectEvent && <TableHeaderCell />}
            {
              i18n?.eventTableColumnsTitles.map(
                (item, idx) => (
                  <TableHeaderCell key={idx}>{item}</TableHeaderCell>
                )
              )
            }
          </TableHeaderRow>
        </TableHeader>
        <TableBody loading={isLoading}>
          {eventsDatas.map(({ id, type, eventRequest, eventRequestDetails }) => (
            <Fragment key={id}>
              <TableBodyRow
                onClick={() => handleOnSelectRowDetails(id)}
                className='expand'
              >
                {isSelectEvent && (
                  <TableBodyCell onClick={onEventSelect(id)}>
                    <RadioInputGroup
                      id={id}
                      name='event'
                      value={selectedEventId}
                    />
                  </TableBodyCell>
                )}
                <TableBodyCell>
                  <WrapperSortRightIcon>
                    <button
                      className={selectedIds(id) ? 'open' : ''}
                      type='button'
                    >
                      <SortRightIcon />
                    </button>
                    <SafeOverflow
                      text={i18n?.eventCodesMapped[type] || type }
                      className='annex_event_request_type'
                    />
                  </WrapperSortRightIcon>
                </TableBodyCell>
                <TableBodyCell>
                  <p className='event_request'>
                    {eventRequest}
                  </p>
                </TableBodyCell>
              </TableBodyRow>
              {selectedIds(id) && (
                <TableBodyCell css={tdDetails} colSpan='100%'>
                  <EventsRowAnnexDetails details={eventRequestDetails} i18n={i18n} />
                </TableBodyCell>
              )}
            </Fragment>
          ))}
        </TableBody>
      </TableContainer>
    </EventsListContainer>
  )
}
