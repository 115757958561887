export const SuppliersTranslator = (
  dictionary
) => {
  const pageTitleSupplier = dictionary?.supplier_pageTitleSuppliers_label
  const modalNewSupplierTitle = dictionary?.supplier_modalNewSupplierTitle_label
  const modalEditSupplierTitle = dictionary?.supplier_modalEditSupplierTitle_label
  const newSupplierButton = dictionary?.supplier_newSupplierButton_label
  const listButton = dictionary?.supplier_listButton_label
  const contactDetailsMenuTitle = dictionary?.supplier_contactDetailsMenuTitle_label
  const addressMenuTitle = dictionary?.supplier_addressMenuTitle_label
  const bankAccountMenuTitle = dictionary?.supplier_bankAccountMenuTitle_label
  const cancelButton = dictionary?.common_cancel_label
  const createButton = dictionary?.supplier_createButton_label
  const editButton = dictionary?.common_save_label
  const removeDialogTitle = dictionary?.supplier_removeDialogTitle_label
  const removeDialogMessage = dictionary?.supplier_removeDialogMessage_label
  const modalAuditSupplierTitle = dictionary?.supplier_modalAuditSupplierTitle_label
  const listSupplierBankAccountsLabel = dictionary?.supplier_listSupplierBankAccounts_label

  const supplierTableColumnsTitles = [
    dictionary?.supplier_tableColumnTitleCorporateName_label,
    dictionary?.supplier_tableColumnTitleFancyName_label,
    dictionary?.supplier_tableColumnTitleSupplierDocument_label,
    dictionary?.supplier_tableColumnTitleStatus_label,
    dictionary?.supplier_tableColumnTitleAction_label
  ]

  const supplierStatusCodesMapped = {
    enabled: { label: dictionary?.supplier_statusValueEnabled_label },
    disabled: { label: dictionary?.supplier_statusValueDisabled_label }
  }

  const supplierEnabledOptions = [
    { value: 'enabled', label: dictionary?.supplier_statusValueEnabled_label },
    { value: 'disabled', label: dictionary?.supplier_statusValueDisabled_label }
  ]

  const selectSupplierStatusOptions = [
    { value: '', label: dictionary?.supplier_statusValueAll_label },
    { value: true, label: dictionary?.supplier_statusValueEnabled_label },
    { value: false, label: dictionary?.supplier_statusValueDisabled_label }
  ]

  const selectSupplierTypeOptions = [
    { value: 'legal_person', label: dictionary?.supplier_legalPerson_label },
    { value: 'natural_person', label: dictionary?.supplier_naturalPerson_label }
  ]

  const auditLabels = {
    observation: dictionary?.audit_observation_label,
    municipal_registration: dictionary?.audit_municipalRegistration_label,
    supplier_type: dictionary?.audit_supplierType_label,
    supplier_document: dictionary?.audit_supplierDocument_label,
    corporate_name: dictionary?.audit_corporateName_label,
    fancy_name: dictionary?.audit_fancyName_label,
    address: dictionary?.audit_address_label,
    neighborhood: dictionary?.audit_neighborhood_label,
    state: dictionary?.audit_state_label,
    country: dictionary?.audit_country_label,
    zip_code: dictionary?.audit_zipCode_label,
    city_name: dictionary?.audit_cityName_label,
    emails: dictionary?.audit_emails_label,
    phone_numbers: dictionary?.audit_phoneNumbers_label,
    contact_names: dictionary?.audit_contactNames_label
  }

  return {
    pageTitleSupplier,
    modalNewSupplierTitle,
    modalEditSupplierTitle,
    supplierTableColumnsTitles,
    supplierStatusCodesMapped,
    supplierEnabledOptions,
    selectSupplierStatusOptions,
    selectSupplierTypeOptions,
    newSupplierButton,
    listButton,
    addressMenuTitle,
    bankAccountMenuTitle,
    contactDetailsMenuTitle,
    cancelButton,
    createButton,
    editButton,
    removeDialogTitle,
    removeDialogMessage,
    modalAuditSupplierTitle,
    listSupplierBankAccountsLabel,
    auditLabels
  }
}
