import { BillingMeta } from 'Core/Types/Abstractions'

export const MetaParser = {
  meta ({
    page,
    count,
    last,
    next,
    prev,
    total_receivable: totalReceivable,
    total_to_pay: totalToPay,
    balance,
    total_gross_amount: totalGrossAmount,
    total_net_amount: totalNetAmount,
    total
  }: BillingMeta) {
    return {
      page,
      prev,
      next,
      last,
      count,
      totalReceivable,
      totalToPay,
      balance,
      totalGrossAmount,
      totalNetAmount,
      total
    }
  }
}
