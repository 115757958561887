import { useCallback, useEffect, useState, useRef } from 'react'
import { FullFinancialScheduleService } from 'Core/Service/FullFinancialSchedule'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { FlowMovementHooksTranslator } from '../i18n/FlowMovementsHooksi18n'
import { FullFinancialScheduleTranslator } from '../i18n/FullFinancialScheduleI18n'
import { exportCsv } from '@bonitour/common-functions'

export const useGetListFlowMovements = () => {
  const { addToast } = useToast()

  const { initStatePagination, perPageOptions } = usePaginationOptions()

  const initPagination = useRef(false)

  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false)

  const [params, setParams] = useState({
    canceled: false,
    ...initStatePagination
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const [data, setData] = useState({
    data: [],
    meta: {}
  })

  const i18n = useTranslator(FlowMovementHooksTranslator)

  const i18nForCsv = useTranslator(FullFinancialScheduleTranslator)

  const fetchFlowMovements = useCallback((filters) => {
    setIsLoading(true)
    return FullFinancialScheduleService.getListFlowMovements(filters).then(({ data, meta }) => {
      setData({ data, meta })
    }).catch(errors => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorLoadingCompleteFinancialSchedule
      })
    }).finally(() => setIsLoading(false))
  }, [addToast, i18n?.errorLoadingCompleteFinancialSchedule])

  const handlePagination = useCallback((currentPage, perPage) => {
    if (!initPagination.current) {
      initPagination.current = true
      return
    }

    setParams(values => ({ ...values, exportFormat: undefined, currentPage, perPage }))
  }, [])

  const toggleVisibleCanceled = useCallback(() => {
    setParams(values => ({ ...values, exportFormat: undefined, canceled: !values.canceled }))
  }, [])

  const onSearch = useCallback((filters) => {
    if (!(filters.startDate || filters.endDate) && !(params.endDate && params.startDate)) {
      return addToast({
        defaultMessage: i18n?.flowMovementSelectPeriod,
        typeToast: 'warning'
      })
    }

    setIsUpdatingFilters(true)

    setParams(values => ({ ...values, currentPage: 1, exportFormat: undefined, ...filters }))
    setTimeout(() => setIsUpdatingFilters(false))
  }, [addToast, i18n?.flowMovementSelectPeriod, params.endDate, params.startDate])

  const handleExport = useCallback((filters) => {
    setIsLoadingExport(true)
    return FullFinancialScheduleService.getListFlowMovementsExport(filters, i18nForCsv)
      .then((data) => exportCsv('movimentacoes-completa', data))
      .catch(errors => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorWhenExportingCompleteFinancialSchedule
        })
      }).finally(() => setIsLoadingExport(false))
  }, [addToast, i18n?.errorWhenExportingCompleteFinancialSchedule, i18nForCsv])

  useEffect(() => {
    if (!(params.endDate && params.startDate)) return

    !isUpdatingFilters && fetchFlowMovements(params)
  }, [params, fetchFlowMovements, addToast, isUpdatingFilters])

  return {
    params,
    handlePagination,
    data: data?.data,
    meta: data?.meta,
    isLoading,
    toggleVisibleCanceled,
    handleExport,
    perPageOptions,
    onSearch,
    isLoadingExport
  }
}
