import { css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { depth } from 'Shared/styles/utils/depthBuilder'

export const safeOverflowContainer = css`
  position: relative;
  width: 100%;
  max-width: 100%;

  display: flex;
  align-items: center;

  font-size: 1em;
  white-space: nowrap;

  --overflow-safety-padding: 0.3em;

  &.safe_overflow__align_left {
    justify-content: flex-start;
  }
  &.safe_overflow__align_center {
    justify-content: center;
  }
  &.safe_overflow__align_right {
    justify-content: flex-end;
  }

  .safe_overflow__text {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    padding: var(--overflow-safety-padding) 0;
    margin-top: calc(var(--overflow-safety-padding) * -1);
    text-overflow: ellipsis;
  }

  .safe_overflow__hint {
    ${depth(3, 'dark')}

    max-width: 43.75rem;
    overflow: hidden;
    text-overflow: ellipsis;

    position: absolute;

    background: ${colors.white};
    padding: 0.675rem 1.25rem;
    border-radius: 9in;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease-in-out;

    z-index: 2;

    transform: translateY(calc(var(--overflow-safety-padding) * -0.5));
  }

  &:hover .safe_overflow__hint.is_overflowing {
    opacity: 1;
    pointer-events: all;
  }
`
