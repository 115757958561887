import { css } from '@emotion/core'

export const sizeIcon = css`
  font-size: 9px;
  margin-right: 1em;
  cursor: pointer;
`

export const collapsableIconOpen = css`
  transform: rotate(90deg);
`

export const fontColor = (color) => css`
  color: var(${color});
`

export const customLinkWidth = css`
  white-space: wrap;
  overflow: hidden;
  display: flex;
`

export const internCustomLinkWidth = css`
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16vw;
  margin-right: 6px;
  margin-top: 2px;
`

export const iconInfoStyle = css`
  width: 18px;
  height: 18px;
  cursor: pointer;
`

export const borderLeftFill = css`
  border-top: 1px solid var(--clr-red5);
  border-bottom: 1px solid var(--clr-red5);
  border-left: 1px solid var(--clr-red5);
`

export const borderRightFill = css`
  border-top: 1px solid var(--clr-red5);
  border-bottom: 1px solid var(--clr-red5);
  border-right: 1px solid var(--clr-red5);
`

export const borderCenterFill = css`
  border-top: 1px solid var(--clr-red5);
  border-bottom: 1px solid var(--clr-red5);
`

export const selectDateContainer = css`
  display: flex;
  justify-content: center;
  margin: 7em 0 4em 0;
  color: var(--clr-gray4);
`

export const tableBodyContainer = css`

  .safe_overflow__description {
    max-width: 20rem;
  }

  .safe_overflow__origin_type {
    max-width: 15rem;
  }

  .circle__conciliated {
    display: inline-block;
    position: relative;

    &:hover .tooltip__conciliated {
      opacity: 1;
    }

    .tooltip__conciliated {
      opacity: 0;
      pointer-events: none;
    }

  }
`
