import {
  formatCnpj,
  formatCpf
} from '@bonitour/common-functions'
import { AuditParser } from './audit'

export const SupplierParser = {
  single: (supplier = {}) => {
    const {
      id,
      company_id: companyId,
      enabled,
      supplier_type: supplierType,
      supplier_document: supplierDocument,
      corporate_name: corporateName,
      fancy_name: fancyName,
      municipal_registration: municipalRegistration,
      address,
      neighborhood,
      state,
      country,
      zip_code: zipCode,
      created_at: createdAt,
      updated_at: updatedAt,
      emails,
      phone_numbers: phoneNumbers,
      contact_names: contactNames,
      city_name: cityName,
      observation,
      user_name: userName,
      user_id: userId
    } = supplier?.supplier ?? supplier ?? {}

    const newSupplierDocument = supplierDocument?.length > 11 ? formatCnpj(supplierDocument || '') : formatCpf(supplierDocument || '')

    return {
      id,
      companyId,
      enabled: enabled ? 'enabled' : 'disabled',
      supplierType,
      supplierDocument: newSupplierDocument,
      corporateName,
      fancyName,
      municipalRegistration,
      address,
      neighborhood,
      state,
      country,
      zipCode,
      createdAt,
      updatedAt,
      emails,
      phoneNumbers,
      contactNames,
      cityName,
      observation,
      userName,
      userId
    }
  },
  list: ({ suppliers = [], meta = {} }) => {
    return {
      suppliers: suppliers.map(SupplierParser.single),
      meta
    }
  },
  singleForEdit: (supplier = {}) => {
    const {
      id,
      company_id: companyId,
      enabled: basicRegistration_enabled,
      supplier_type: basicRegistration_supplierType,
      supplier_document: basicRegistration_supplierDocument,
      corporate_name: basicRegistration_corporateName,
      fancy_name: basicRegistration_fancyName,
      municipal_registration: basicRegistration_municipalRegistration,
      address: address_address,
      neighborhood: address_neighborhood,
      state: address_state,
      country: address_country,
      zip_code: address_zipCode,
      city_name: address_cityName,
      emails: contactDetails_emails,
      phone_numbers: contactDetails_phoneNumbers,
      contact_names: contactDetails_contactNames,
      observation: contactDetails_observation,
      created_at: createdAt,
      updated_at: updatedAt,
      user_name: userName,
      user_id: userId
    } = supplier?.supplier ?? supplier ?? {}

    return {
      id,
      companyId,
      basicRegistration_enabled: basicRegistration_enabled ? 'enabled' : 'disabled',
      basicRegistration_supplierType: basicRegistration_supplierType === 'PJ' ? 'legal_person' : 'natural_person',
      basicRegistration_supplierDocument,
      basicRegistration_corporateName,
      basicRegistration_fancyName,
      basicRegistration_municipalRegistration,
      address_address,
      address_neighborhood,
      address_state,
      address_country,
      address_zipCode,
      address_cityName,
      contactDetails_emails,
      contactDetails_phoneNumbers,
      contactDetails_contactNames,
      contactDetails_observation,
      createdAt,
      updatedAt,
      userName,
      userId
    }
  },
  supplierAudit: (data) => {
    return AuditParser.getAudits({ data })
  },
  options: ({ suppliers = [] }) => {
    return suppliers.map(
      (
        {
          id: value,
          corporate_name: label
        }
      ) => (
        {
          value,
          label
        }
      ))
  }
}
