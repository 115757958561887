import { useEffect, useState } from 'react'
import { CountPlanService } from 'Core/Service/CountPlan'
import { useParams } from 'react-router-dom'
import { useToast } from 'Shared/hooks/useToast'
import { AccountsPlansHooksTranslator } from '../i18n/AccountsPlansHooksI18n'
import { useTranslator } from 'Shared/language/i18nHandler'

export const useEditCountPlan = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [countPlan, setCountPlan] = useState()
  const { addToast } = useToast()

  const i18n = useTranslator(AccountsPlansHooksTranslator)

  useEffect(() => {
    setLoading(true)
    CountPlanService.getById(id).then(
      setCountPlan
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorFecthingAccountPlan
      })
    }).finally(() => setLoading(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editCountPlan = async (data) => {
    setLoading(true)
    return CountPlanService.edit({ ...data }, id)
      .then(() => {
        addToast({
          defaultMessage: i18n?.accountPlanSucessUpdated
        })
      }).catch((errors) => {
        console.error(errors?.data)
        addToast(({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorUpdateAccountPlan
        }))
      })
      .finally(() => setLoading(false))
  }

  return {
    countPlan,
    submit: editCountPlan,
    loading
  }
}
