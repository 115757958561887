import React, { useCallback, useMemo } from 'react'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { FormActions } from 'Shared/components/Form/__FormActions'
import { BatchExpenseUpdateInputsContainer, expenseUpdateFormTableV2 } from './BatchExpenseUpdateForm.styles'
import { InputFormGroup, InputMoneyMask, TableRow, TableV2 } from '@bonitour/components'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { ExpenseParsed, UpdateBatchExpenseReqBody } from 'Core/Types/Expense'
import { useBatchExpenseUpdateInputs } from '../hooks/inputs/useBatchExpenseUpdateInputs'
import { unformatMoney } from '@bonitour/common-functions'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesTranslator } from '../i18n/ExpensesI18n'
import { ValuesCard } from 'Shared/components/ValuesCard/ValuesCard'
import { calculateValuesExpenses } from '../utils/calculateValuesExpenses'

interface BankTransferFormProps {
  handleBatchExpenseUpdate: (data: UpdateBatchExpenseReqBody) => void
  onClose: () => void
  selectedExpenses: ExpenseParsed[]
  loadingUpdateBatchExpenses: boolean
}

const columnsWidths = ['2fr', '2fr', '1fr']

export const BatchExpenseUpdateForm = ({
  handleBatchExpenseUpdate,
  onClose,
  selectedExpenses,
  loadingUpdateBatchExpenses
}: BankTransferFormProps): React.ReactElement => {
  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useBatchExpenseUpdateInputs(
    {
      selectedExpenses
    }
  )

  const handleMoneyInputChange = useCallback(
    (fieldName) => (val) =>
      onInputChange(fieldName)((unformatMoney(val))),
    [onInputChange]
  )

  const handleMoneyInputBlur = useCallback(
    (fieldName) => (val) =>
      onInputBlur(fieldName)((unformatMoney(val))),
    [onInputBlur]
  )

  const i18n = useTranslator(ExpensesTranslator)

  const valuesCardOptions = useMemo(() => {
    const { formattedNumber } = calculateValuesExpenses(form?.expenseItems)

    return [
      {
        text: i18n?.totalBatchAccounts,
        value: formattedNumber
      }
    ]
  }, [form?.expenseItems, i18n?.totalBatchAccounts])

  return (
    <BatchExpenseUpdateInputsContainer>
      <ValuesCard
        valuesCardOptions={valuesCardOptions}
      />
      <FormsInputs
        inputs={inputsValid}
        formValues={form}
        formErrors={errors}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      />
      <TableV2
          isLoading={false}
          labels={i18n?.updateExpensesColumnsTitles}
          columnsWidths={columnsWidths}
          mobileBreakpoint={1024}
          customCss={[expenseUpdateFormTableV2]}
        >
          {
            selectedExpenses?.map((expense, idx) => (
              <TableRow
                key={expense?.id}
              >
                <SafeOverflow
                  text={expense?.expenseRevenue?.description || ''}
                />
                <SafeOverflow
                  text={
                    expense?.serviceName
                  }
                  className='safe_overflow__service_name'
                />
                <InputFormGroup
                  errorMessage={errors?.expenseItems?.[idx]?.value}
                  className={'input_container__value'}
                >
                  <InputMoneyMask
                    name={'value'}
                    value={form?.expenseItems?.[idx].value || ''}
                    className={'input__value'}
                    onChange={handleMoneyInputChange(`expenseItems[${idx}].value`)}
                    onBlur={handleMoneyInputBlur(`expenseItems[${idx}].value`)}
                    aria-label={i18n?.value}
                    disabled={false}
                    placeholder={i18n?.value}
                    readOnly={false}
                    required
                  />
                </InputFormGroup>

              </TableRow>
            ))
          }
        </TableV2>

      <section className='wrapper__btns'>
        <FormActions
          onClose={onClose}
          onSubmit={onSubmit(handleBatchExpenseUpdate)}
          disabled={loadingUpdateBatchExpenses}
        />
      </section>
    </BatchExpenseUpdateInputsContainer>
  )
}
