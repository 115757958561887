import { SafeDate } from '@binamik/js-providers'
import { deepGetOrElse } from 'deep-getter'

export const ReceivablesModel = {
  receivablesParams: (params) => {
    const {
      startDate,
      endDate,
      gateway
    } = params

    return {
      start_date: startDate ? new SafeDate(startDate).format('YYYY-MM-DD') : undefined,
      end_date: endDate ? new SafeDate(endDate).format('YYYY-MM-DD') : undefined,
      gateway
    }
  },
  list: ({ filters = {} }) => {
    const {
      proofOfSale,
      startDate,
      endDate,
      brand,
      currentPage,
      perPage,
      gateway
    } = filters

    const searchParams = {
      proof_of_sale: proofOfSale,
      brand,
      page: currentPage,
      per_page: perPage,
      ...ReceivablesModel.receivablesParams({ startDate, endDate, gateway })
    }

    return { searchParams }
  },
  import: ({ startDate, endDate }) => {
    return {
      searchParams: ReceivablesModel.receivablesParams({ startDate, endDate, gateway: 'braspag' })
    }
  },
  importFile: ({ file }) => {
    return {
      gateway: 'cielo',
      file: deepGetOrElse({ file }, 'file.0.file', null)
    }
  },
  export: ({ filters = {} }) => {
    const {
      proofOfSale,
      startDate,
      endDate,
      brand,
      gateway
    } = filters

    const searchParams = {
      proof_of_sale: proofOfSale,
      brand,
      ...ReceivablesModel.receivablesParams({ startDate, endDate, gateway })
    }

    return { searchParams }
  },
  deleteGatewayPayments: (params) => {
    return ReceivablesModel.receivablesParams(params)
  }
}
