/**
 * @param { string } id
 * @param { string } src
 * @param { string } fileName
 */
export const browserDownloadFromSrcImg = (
  id,
  src,
  fileName
) => {
  const iframe = document.createElement('iframe')
  iframe.name = `img-download-${id}`
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  const link = document.createElement('a')
  link.href = src + '?disposition=attachment'
  link.download = fileName
  link.target = `img-download-${id}`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  setTimeout(() => document.body.removeChild(iframe), 30 * 1000)
}
