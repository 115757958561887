
export const FormTranslator = (dictionary) => {
  const cancelButton = dictionary?.common_cancel_label
  const saveButton = dictionary?.common_save_label

  return {
    cancelButton,
    saveButton
  }
}
