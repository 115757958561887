interface CashDepositsHooksTranslatorRes {
  errorWhenSearchingForCashDeposits: string
}

export const CashDepositsHooksTranslator = (
  dictionary
): CashDepositsHooksTranslatorRes => {
  const errorWhenSearchingForCashDeposits = dictionary?.cash_deposit_errorWhenSearchingForCashDeposits_toast

  return {
    errorWhenSearchingForCashDeposits
  }
}
