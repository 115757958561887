import { apiCodesTranslations } from 'Shared/language/utils/functions'
import { bankTransfersStatusOptions } from '../../Constants/bankTransfersStatusOptions'

interface BankTransfersStatusTranslations {
  [key: string]: string,
  done: string
  canceled: string
}

export const useTranslatedBankTransfersStatus = ({ dictionary }) => {
  const bankTransfersStatusTranslations: BankTransfersStatusTranslations = {
    done: dictionary?.common_realizedStatus_label,
    canceled: dictionary?.common_canceledStatus_label
  }

  const bankTransfersStatusTranslator = (status: string): string => {
    return apiCodesTranslations(
      {
        apiCode: status,
        codesMapped: bankTransfersStatusTranslations,
        isToLowerCase: false
      }
    )
  }

  const bankTransfersStatusOptionsTranslated = bankTransfersStatusOptions.map((value) => {
    return {
      value,
      label: value ? bankTransfersStatusTranslator(value) : dictionary?.common_all_label
    }
  })

  return {
    bankTransfersStatusTranslator,
    bankTransfersStatusOptionsTranslated
  }
}
