/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import {
  Card,
  EyeIcon,
  EyeSlashIcon
} from '@bonitour/components'
import {
  movimentCardContainer
} from './FullFinancialSchedule.styles'
import { FinancialMovements } from './FinancialMovements/FinancialMovements'
import { DateUtils } from './utils/DateUtils'
import { useGetListFlowMovements } from './hooks/useGetListFlowMovements'
import { FullFinancialScheduleTableBody } from './FinancialMovements/FullFinancialScheduleTableBody'
import { MovementsCard } from './MovementsCard/MovementsCard'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'
import { useTranslator } from 'Shared/language/i18nHandler'
import { FullFinancialScheduleTranslator } from './i18n/FullFinancialScheduleI18n'
import { FullFinancialFilters } from './Filter/FullFinancialFilters'

export const FullFinancialSchedule = () => {
  const { push } = useHistory()
  const returnToDashboard = () => push('/dashboard')

  const {
    handlePagination,
    isLoading,
    data,
    meta,
    params: filters,
    toggleVisibleCanceled,
    handleExport,
    perPageOptions,
    onSearch,
    isLoadingExport
  } = useGetListFlowMovements()

  const hasDate = useMemo(() => Boolean(filters.startDate && filters.endDate), [filters.endDate, filters.startDate])

  const i18n = useTranslator(FullFinancialScheduleTranslator)

  useFlaggedRoute('billing-full-financial-schedule')

  return (
    <>
      <PageTitle
        title={i18n?.pageTitleFullFinancialSchedule}
        onClickReturn={returnToDashboard}
      />
      <MovementsCard
        balanceMoves={meta}
        customText={i18n?.customValueCardTitles}
      />
      <Card customCss={[movimentCardContainer]}>
        <div className='moviment__header'>
          <section>
            <h3 className='moviment__title'>
              {
                `
                  ${i18n?.movements}
                  ${DateUtils.getTextBetweenDates(filters?.startDate, filters?.endDate)}
                  ${DateUtils.getDateMoves(filters?.startDate, filters?.endDate)}
                `
              }
            </h3>
          </section>

          <button onClick={() => toggleVisibleCanceled()} className='section__moviment_canceled'>
            <section>
              {!filters.canceled ? (
                <>
                  <EyeIcon />
                  <p>{i18n?.viewCanceledReleases}</p>
                </>
              ) : (
                <>
                  <EyeSlashIcon />
                  <p>{i18n?.hideCanceledReleases}</p>
                </>
              )}
            </section>
          </button>
        </div>
        <FullFinancialFilters
          onFilterSubmit={onSearch}
          isLoading={isLoading}
          btnLabel={i18n?.searchButton}
          handleExport={handleExport}
          isLoadingExport={isLoadingExport}
        />
        <FinancialMovements
          hasDate={hasDate}
          meta={meta}
          handlePagination={handlePagination}
          columns={i18n?.fullFinancialScheduleTableColumnsTitles}
          perPageOptions={perPageOptions}
          pagination={filters}
        >
          <FullFinancialScheduleTableBody
            data={data}
            loading={isLoading}
          />
        </FinancialMovements>
      </Card>
    </>
  )
}
