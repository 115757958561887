
export const FlowMovementHooksTranslator = (
  dictionary
) => {
  const errorLoadingCompleteFinancialSchedule = dictionary?.flow_movment_errorLoadingCompleteFinancialSchedule_toast
  const errorWhenExportingCompleteFinancialSchedule = dictionary?.flow_movment_errorWhenExportingCompleteFinancialSchedule_toast
  const flowMovementSelectPeriod = dictionary?.common_selectPeriod_toast
  const flowMovementendDatemustBeGreaterOrEqualStartDate = dictionary?.common_endDatemustBeGreaterOrEqualStartDate_toast

  return {
    errorLoadingCompleteFinancialSchedule,
    errorWhenExportingCompleteFinancialSchedule,
    flowMovementSelectPeriod,
    flowMovementendDatemustBeGreaterOrEqualStartDate
  }
}
