import { useTranslatedFlags } from 'Shared/language/hooks/useTranslatedFlags'
import { useTranslatedPaymentMethods } from 'Shared/language/hooks/useTranslatedPaymentMethods'
import { useTranslatedGatewayEventTypeDescriptions } from './hooks/useTranslatedGatewayEventTypeDescriptions'
import { useTranslatedStatusReceivables } from './hooks/useTranslatedStatusReceivables'

export const ReceivablesTranslator = (
  dictionary
) => {
  const { flagTranslator, cardFlagOptionsTranslated } = useTranslatedFlags({ dictionary })
  const { paymentMethodMapped } = useTranslatedPaymentMethods({ dictionary })
  const statusReceivablesTranslator = useTranslatedStatusReceivables({ dictionary })
  const gatewayPaymentEventTypeDescriptionTranslator = useTranslatedGatewayEventTypeDescriptions({ dictionary })

  const dateFormat = dictionary?.common_dateFormat_template
  const conciliated = dictionary?.common_conciliate_label
  const notConciliated = dictionary?.common_notConciliate_label

  const tableColumnsTitles = [
    dictionary.receivables_tableColumnTitleDate_label,
    dictionary.receivables_tableColumnTitleFlag_label,
    dictionary.receivables_tableColumnTitleAutDoc_label,
    dictionary.receivables_tableColumnTitleOperationType_label,
    dictionary.receivables_tableColumnTitleGrossAmountInstallment_label,
    dictionary.receivables_tableColumnTitleNetAmountInstallment_label,
    dictionary.receivables_tableColumnTitleStatus_label,
    ''
  ]

  return {
    dateFormat,
    tableColumnsTitles,
    conciliated,
    notConciliated,
    flagTranslator,
    paymentMethodMapped,
    statusReceivablesTranslator,
    gatewayPaymentEventTypeDescriptionTranslator,
    cardFlagOptionsTranslated
  }
}
