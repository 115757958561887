import styled from '@emotion/styled'

export const ModalContainer = styled.div`
  * {
    box-sizing: border-box;
  }

  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}rem` : 'none')};
  max-height: calc(100% - 2rem);
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 1.875rem;
  background: white;

  z-index: 4;
  opacity: 1;

  .modal__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal__close_btn {
      --close-modal-button-size: 1.875rem;
      height: var(--close-modal-button-size);
      width: var(--close-modal-button-size);
      min-width: var(--close-modal-button-size);
      min-height: var(--close-modal-button-size);
      border-radius: 100vw;

      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;

      background: var(--clr-primary);
      color: white;
      margin-left: 0.625rem;
    }
  }

  .modal__subtitle {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    color: var(--clr-gray3);
  }


  .modal__inner_content {
    width: 100%;
    margin-top: 20px;
  }

`

export const ModalBg = styled.button`
  background-color: #000;
  opacity: 0.3;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  outline: none;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 3;
`
