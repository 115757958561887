import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { PageCreateEditExpenseFormContainer } from './PageCreateEditExpense.styles'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useParams } from 'react-router-dom'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { useExpenseInputs } from '../hooks/inputs/useExpenseInputs'
import { FormActions } from 'Shared/components/Form/__FormActions'
import { useCreateExpense } from '../hooks/useCreateExpense'
import { useUpdateExpense } from '../hooks/useUpdateExpense'
import { PayloadCreateExpense, PayloadUpdateExpense } from 'Core/Types/Expense'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesTranslator } from '../i18n/ExpensesI18n'
import { useQuery } from 'Shared/hooks/useQuery'
import { LoadingAnimation } from '@bonitour/components'

export const PageCreateEditExpense = () => {
  const { push } = useHistory()
  const routerParams = useParams()
  const [
    { inputsReadingOnly }
    ,
    { removeQueryParam }
  ] = useQuery()

  const isCreate = useMemo(
    () => routerParams?.[0] === 'create',
    [routerParams]
  )

  const expenseId = useMemo(
    () => routerParams?.expenseId, [routerParams?.expenseId]
  )

  const returnToListExpenses = useCallback(
    () => {
      const newSearch = removeQueryParam('inputsReadingOnly')
      return push(
        `/expense-revenue-dashboard/expenses${newSearch}`
      )
    }
    , [push, removeQueryParam]
  )

  const {
    onCreateExpense,
    loading: loadingCreateExpense
  } = useCreateExpense({})

  const {
    fetchExpense,
    expense,
    loading: loadingUpdateExpense,
    onUpdateExpense
  } = useUpdateExpense({})

  const {
    inputsValid,
    form,
    errors,
    onSubmit,
    onInputChange,
    onInputBlur
  } = useExpenseInputs(
    {
      currentFormData: expense,
      expenseId,
      inputsReadingOnly
    }
  )

  useEffect(() => {
    if (!expenseId || expense) return

    fetchExpense(expenseId)
  }, [expenseId, expense, fetchExpense])

  const expenseOperationStrategy = useMemo(() => ({
    create: onCreateExpense,
    update: onUpdateExpense
  }), [onCreateExpense, onUpdateExpense])

  const handleCreateEditExpense = useCallback(
    async (currentForm: PayloadUpdateExpense & PayloadCreateExpense
    ) => {
      const operationType = isCreate ? 'create' : 'update'

      const isSuccess = await expenseOperationStrategy[operationType](currentForm)

      if (isSuccess) {
        returnToListExpenses()
      }
    },
    [expenseOperationStrategy, isCreate, returnToListExpenses]
  )

  const i18n = useTranslator(ExpensesTranslator)

  const pageTitleCreateEditExpense = useMemo(
    () => {
      if (isCreate) {
        return i18n?.accountPayableRegistration
      } else if (inputsReadingOnly) {
        return i18n?.viewPayableAccount
      } else {
        return i18n?.editPayableAccount
      }
    }, [
      i18n?.accountPayableRegistration,
      i18n?.editPayableAccount,
      inputsReadingOnly,
      isCreate,
      i18n?.viewPayableAccount
    ]
  )

  const cancelBtnLabel = useMemo(() => {
    if (!inputsReadingOnly) return
    return i18n?.exit
  }, [i18n?.exit, inputsReadingOnly]
  )

  const successBtnLabel = useMemo(
    () => isCreate ? i18n?.register : i18n?.save,
    [i18n?.register, i18n?.save, isCreate]
  )

  return (
    <>
      <PageTitle
        title={pageTitleCreateEditExpense}
        onClickReturn={returnToListExpenses}
      />

      <PageCreateEditExpenseFormContainer
        onReset={returnToListExpenses}
        onSubmit={e => e.preventDefault()}
      >
        {
          loadingUpdateExpense
            ? (
              <LoadingAnimation className='load' />
              )
            : (
          <>
            <FormsInputs
              inputs={inputsValid}
              formValues={form}
              formErrors={errors}
              onInputChange={onInputChange}
              onInputBlur={onInputBlur}
            />

            <section className={`form__actions ${inputsReadingOnly ? 'read_only' : ''}`}>
              <FormActions
                onSubmit={onSubmit(handleCreateEditExpense)}
                onClose={returnToListExpenses}
                successBtnLabel={successBtnLabel}
                cancelBtnLabel={cancelBtnLabel}
                disabled={loadingUpdateExpense || loadingCreateExpense}
              />
            </section>
          </>
              )}
      </PageCreateEditExpenseFormContainer>

    </>
  )
}
