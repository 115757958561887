/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { stylishCardSearch, buttonStyle, marginTop } from './ReceivablesFIlter.styles'
import {
  Button,
  Input,
  InputFormGroup,
  Card,
  Column,
  Row,
  TrashIcon,
  DatePicker
} from '@bonitour/components'
import { ReceivablesFilterSchema } from './Receivables.schema'
import { useForm } from '@bonitour/app-functions'
import { ActionButtons, exportBtn } from 'Shared/components/ExportFile/ActionButtons'
import { useCallback, useMemo, useState } from 'react'
import { useRemoveReceivables } from '../hooks/useRemoveReceivables'
import { Select } from 'Shared/components/Select/Select'
import { ConfirmDialog } from 'Shared/components/ConfirmDialog/ConfirmDialog'
import { acquirersOptions } from 'Constants/acquirersOptions'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ReceivablesTranslator } from '../i18n/ReceivablesI18n'

export const ReceivablesFilter = (
  {
    receivablesFilters,
    fetchExportReceivables,
    fetchReceivables,
    handleFilters
  }
) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(receivablesFilters, ReceivablesFilterSchema)

  const {
    proofOfSale,
    brand,
    gateway,
    startDate,
    endDate
  } = form

  const {
    deleteGatewayPayments,
    isLoadingDeleteGatewayPayments
  } = useRemoveReceivables(
    {
      fetchReceivables
    }
  )

  const [isOpenModal, setIsOpenModal] = useState(false)

  const toggleModal = useCallback(() => {
    setIsOpenModal((isOpen) => !isOpen)
  }, [])

  const handleRemoveReceivables = useCallback(() => {
    deleteGatewayPayments(form)
    setIsOpenModal(false)
  }, [deleteGatewayPayments, form])

  const btns = useMemo(() => {
    return [
      exportBtn(onSubmit(fetchExportReceivables)),
      {
        text: 'Excluir',
        icon: <TrashIcon />,
        onClick: onSubmit(toggleModal),
        isDisabled: isLoadingDeleteGatewayPayments
      }
    ]
  }, [onSubmit, fetchExportReceivables, toggleModal, isLoadingDeleteGatewayPayments])

  const i18n = useTranslator(ReceivablesTranslator)

  return (
    <>
      <Card css={stylishCardSearch}>
        <Row>
          <Column desktop={3} phone={12}>
            <InputFormGroup label='Data inicial' errorMessage={errors?.startDate}>
              <DatePicker
                placeholder='Data inicial'
                value={startDate}
                className='start_date'
                onChange={onInputChange('startDate')}
                onBlur={onInputBlur('startDate')}
              />
            </InputFormGroup>
          </Column>
          <Column desktop={3} phone={12}>
            <InputFormGroup label='Data final' errorMessage={errors?.endDate}>
              <DatePicker
                placeholder='Data final'
                className='end_date'
                value={endDate}
                onChange={onInputChange('endDate')}
                onBlur={onInputBlur('endDate')}
              />
            </InputFormGroup>
          </Column>
          <Column desktop={3} phone={12}>
            <Select
              id='gateway'
              label='Adquirente'
              error={errors?.gateway}
              options={acquirersOptions}
              placeholder='Selecione um adquirente'
              value={gateway}
              onChange={onInputChange('gateway')}
              onBlur={onInputBlur('gateway')}
            />
          </Column>
          <Column desktop={3} phone={12}>
            <Select
              id='brand'
              label='Bandeira do cartão'
              error={errors?.brand}
              options={i18n?.cardFlagOptionsTranslated}
              placeholder='Selecione uma bandeira'
              value={brand}
              onChange={onInputChange('brand')}
              onBlur={onInputBlur('brand')}
            />
          </Column>
          <Column desktop={3} phone={12}>
            <InputFormGroup label='DOC' errorMessage={errors?.proofOfSale}>
              <Input
                id='proofOfSale'
                placeholder='Digite um número de DOC'
                value={proofOfSale}
                onChange={onInputChange('proofOfSale')}
                onBlur={onInputBlur('proofOfSale')}
              />
            </InputFormGroup>
          </Column>
          <Column css={marginTop} desktop={3} phone={12}>
            <Button css={buttonStyle} onClick={onSubmit(handleFilters)}>
              Pesquisar
            </Button>
          </Column>
        </Row>
      </Card>
      <ActionButtons
        btns={btns}
      />
      <ConfirmDialog
        isVisible={isOpenModal}
        onClose={toggleModal}
        title='Tem certeza que deseja excluir os recebíveis?'
        subtitle='Após a confirmação, não tem como rever a ação.'
        onSuccess={handleRemoveReceivables}
      />
    </>
  )
}
