
export const en_US = {
  common_dateFormat_template: 'MM/DD/YYYY',
  common_flagVisa_label: 'Visa',
  common_flagAmericanExpress_label: 'American Express',
  common_flagElo_label: 'Elo',
  common_flagHipercard_label: 'Hipercard',
  common_flagMastercard_label: 'Mastercard',
  common_flagDiners_label: 'Diners',
  common_flagDiscover_label: 'Discover',
  common_credit_label: 'Credit',
  common_debit_label: 'Debit',
  common_endDatemustBeGreaterOrEqualStartDate_toast: 'End date must be greater than or equal to start date',
  common_startDateMustBeLessThanOrEqualEndDate_label: 'The start date must be less than or equal to the end date.',
  common_selectPeriod_toast: 'Select a period',
  common_save_label: 'Save',
  common_cancel_label: 'Cancel',
  common_searchButton_label: 'Search',
  common_all_label: 'All',
  common_aut_label: 'Aut',
  common_doc_label: 'Doc',
  common_yes_label: 'Yes',
  common_no_label: 'No',
  common_originTypeReservationPayment_label: 'Reservation Payment',
  common_originTypeReservationRefund_label: 'Reservation Refund',
  common_originTypeTicket_label: 'Ticket',
  common_originTypeInvoice_label: 'Invoice',
  common_originTypePartnershipAccountTransactionCredit_label: 'Partnership Credit',
  common_originTypePartnershipAccountTransactionDebit_label: 'Partnership Debit',
  common_originTypeCashierHandling_label: 'Entry and Exit Box',
  common_originTypeCashierHandlingEntry_label: 'Entry of Box',
  common_originTypeCashierHandlingExit_label: 'Exit of Box',
  common_originTypePaymentBatch_label: 'Payment Batch Offline/Affiliates',
  common_originTypeExpense_label: 'Accounts Payable',
  common_originTypeRevenue_label: 'Accounts Receivable',
  common_originTypeBillingGatewayPayment_label: 'Launch in Receivables',
  common_pix_label: 'Pix',
  common_bankBillet_label: 'Bank Billet',
  common_cash_label: 'Cash',
  common_bankTransfer_label: 'Bank Transfer',
  common_discount_label: 'Discount',
  common_noInformation_label: 'No information',
  common_brand_label: 'Brand',
  common_statusActive_label: 'Active',
  common_statusInactive_label: 'Inactive',
  common_afterConfirmationNoWayToUndoAction_label: 'After confirmation, there will be no way to undo the action.',
  common_continue_label: 'Continue',
  common_viewMoreDetails_label: 'View More Details',
  common_back_label: 'Back',
  common_invalid_label: 'Invalid',
  common_valid_label: 'Valid',
  common_finalize_label: 'Finalize',
  common_conciliate_label: 'Conciliate',
  common_notConciliate_label: 'Not conciliate',
  common_canceledStatus_label: 'Canceled',
  common_realizedStatus_label: 'Realized',
  common_foreseenStatus_label: 'Forecasted',
  common_withoutStatus_label: 'Without Status',
  common_withoutTypeOfOperation_label: 'Without Type of Operation',
  common_startDate_label: 'Start Date',
  common_endDate_label: 'End Date',
  common_dateFormat_label: 'Date must have the format mm/dd/yyyy',
  common_netValue_label: 'Net Value',
  common_description_label: 'Description',
  common_scheduling_label: 'Scheduling',
  common_cancellation_label: 'Cancellation',
  common_payment_label: 'Payment',
  common_receivement_label: 'Receivement',
  common_refunded_label: 'Refunded',
  common_formOfPayment_label: 'Form of Payment',
  common_reservation_label: 'Reservation',
  common_dateWithHour_template: 'MM/DD/YYYY HH:mm',
  common_braspag_label: 'Braspag',
  common_acquirer_label: 'Acquirer',
  common_cielo_label: 'Cielo',
  common_lien_credit_label: 'Lien Credit',
  common_lien_debit_label: 'Lien Debit',
  common_pos_monthly_fee_label: 'POS Monthly Fee',
  common_startDateRequired_label: 'Start date is required',
  common_endDateRequired_label: 'End date is required',
  common_status_label: 'Status',
  common_selectStatuses_label: 'Select Statuses',
  common_selectAccount_label: 'Select Account',
  common_date_label: 'Date',
  common_value_label: 'Value',
  common_user_label: 'User',
  common_actions_label: 'Actions',
  common_selectPeriodToSearch_label: 'Select a Period to Search',
  common_selectStatus_label: 'Select the status',
  common_userName_label: 'User Name',
  common_registrationDate_label: 'Registration Date',
  common_editDate_label: 'Edition Date',
  common_selectTheDate_label: 'Select the date',
  common_bankFee_label: 'Bank Fee',
  common_observation_label: 'Observation',
  common_enterObservation_label: 'Enter the observation',
  common_statusRequired_label: 'Status required',
  common_observationRequired_label: 'Observation required',
  common_total_label: 'Total',
  common_none_label: 'None',
  common_bankAccount_label: 'Bank Account',
  common_valueMustBeANumber_label: 'Value Must Be a Number',
  common_fillInTheValue_label: 'Fill in the Value',
  common_register_label: 'Register',
  common_referenceDate_label: 'Reference Date',
  common_exit_label: 'Exit',
  common_guideBatch_label: 'Guide Batch',
  common_itMustBeNumber_label: 'Must be a number',
  common_numberOfRepetitionsMustBeLessThanOrEqual240_label: 'Number of repetitions must be less than or equal to 240',
  common_numberOfRepetitionsMustBeGreaterThanOrEqualTo12_label: 'Number of repetitions must be greater than or equal to 12',
  common_numberOfRepetitionsMustBeGreaterThanOrEqualTo2_label: 'Number of repetitions must be greater than or equal to 2',
  common_noFutureDateAllowed_label: 'Future date not allowed',
  common_totalizers_label: 'Totalizers',
  common_attachTheFile_label: 'Attach the file',
  common_fileRequired_label: 'File required',
  common_bankAccountRequired_label: 'Bank account required',
  common_pagar_me_label: 'Pagar.me',

  receivables_scheduled_label: 'Scheduled',
  receivables_peding_label: 'Awaiting settlement confirmation',
  receivables_settled_label: 'Settled',
  receivables_payed_label: 'Payed',
  receivables_errorSettledFinancialInstitution_label: 'Settlement error at the financial institution',
  receivables_waitingForAdjustmentDebit_label: 'Awaiting settlement of the associated debit adjustment',
  receivables_anticipated_label: 'Anticipated event',
  receivables_eventDcpCredit_label: 'Credit posting of installments of a transaction',
  receivables_eventDcpFeeCredit_label: 'Credit launch of the fixed rate agreed between the Marketplace and Braspag (Facilitator)',
  receivables_eventDcpRefundCredit_label: 'Credit release due to a cancellation',
  receivables_eventDcpChargebackCredit_label: 'Credit release due to a chargeback',
  receivables_eventDcpUndoCargebackCredit_label: 'Credit release for reversal of a chargeback',
  receivables_eventDcpAntifraudFeeCredit_label: 'Credit entry related to the anti-fraud transaction',
  receivables_eventDcpAntiFraudFeeWithReviewCredit_label: 'Credit entry related to the anti-fraud transaction with manual review',
  receivables_eventDcpAdjustmentCredit_label: 'Posting a credit as an adjustment',
  receivables_evetDcpChargebackReversalCredit_label: 'Credit entry related to the reversal of a chargeback',
  receivables_eventDcpAnticipationCredit_label: 'Entry of credit related to anticipation',
  receivables_eventDcpAnticipationCommissionCredit_label: 'Entry of credit referring to the commission of an advance',
  receivables_eventDcpAnticipatedInstallmentsCredit_label: 'Credit release referring to the anticipation of installments of a transaction',
  receivables_eventDcpRefundReversalCredit_label: 'Credit posting due to a cancellation reversal',
  receivables_eventDcpReversalFeeCredit_label: 'Credit release due to a reversal of the Fixed Fee agreed between the Marketplace and Braspag',
  receivables_eventDcpBankSlipFeeCredit_label: 'Credit entry regarding the reversal of an anti-fraud transaction with manual review',
  receivables_eventDcpBalanceCompensationCredit_label: 'Credit entry referring to balance compensation with the merchant"s own balance (transfer balance from a positive arrangement to another that is negative)',
  receivables_eventDcpReversalAntiFraudFeeCredit_label: 'Credit entry related to the reversal of an anti-fraud transaction',
  receivables_eventDcpReversalBankSlipFeeCredit_label: 'Credit entry regarding the reversal of an anti-fraud transaction with manual review',
  receivables_eventDcpScheduleBalanceCredit_label: 'Scheduled credit launch',
  receivables_eventDcpDebit_label: 'Posting debit of the installments of a transaction',
  receivables_eventDcpFeeDebit_label: 'Debit launch of the fixed fee agreed between Marketplace and Braspag (Facilitator)',
  receivables_eventDcpRefundDebit_label: 'Posting a debit due to a cancellation',
  receivables_eventDcpChargebackDebit_label: 'Posting a debit due to a chargeback',
  receivables_eventDcpUndoChargebackDebit_label: 'Posting a debit to reverse a chargeback',
  receivables_eventDcpAntiFraudFeeDebit_label: 'Debit entry related to the anti-fraud transaction',
  receivables_eventDcpAntiFraudFeeWithReviewDebit_label: 'Debit entry related to the anti-fraud transaction with manual review',
  receivables_eventDcpAdjustmentDebit_label: 'Posting a debit as an adjustment',
  receivables_eventDcpChargebackReversalDebit_label: 'Posting a debit related to the reversal of a chargeback',
  receivables_eventDcpAnticipationCommissionDebit_label: 'Entry of debit referring to the commission of an advance',
  receivables_eventDcpAnticipatedInstallmentsDebit_label: 'Posting a debit referring to the anticipation of installments of a transaction',
  receivables_eventDcpRefundReversalDebit_label: 'Debit posting due to a cancellation reversal',
  receivables_eventDcpReversalPayoutDebit_label: 'Debit posting due to a payment reversal',
  receivables_eventDcpReversalFeeDebit_label: 'Debit entry due to a reversal of the Fixed Fee agreed between the Marketplace',
  receivables_eventDcpBankSlipFeeDebit_label: 'Launch of Fixed Fee debit for the boleto product agreed between Marketplace and Braspag',
  receivables_eventDcpBalanceCompensationDebit_label: 'Debit entry referring to balance compensation with the merchant"s own balance (transfer balance from a positive arrangement to another that is negative)',
  receivables_eventDcpReversalAntiFraudFeeDebit_label: 'Debit entry related to the reversal of an anti-fraud transaction',
  receivables_eventDcpAnticipationDebit_label: 'Entry of debit referring to anticipation',
  receivables_eventDcpCompensationBetweenSamePaymentArrangementDebit_label: 'Posting a debit related to compensation within the same payment',
  receivables_eventDcpScheduleBalanceDebit_label: 'Scheduled debit entry',
  receivables_tableColumnTitleDate_label: 'Date',
  receivables_tableColumnTitleFlag_label: 'Flag',
  receivables_tableColumnTitleAutDoc_label: 'AUT / DOC',
  receivables_tableColumnTitleOperationType_label: 'Operation type',
  receivables_tableColumnTitleGrossAmountInstallment_label: 'Gross amount of installment',
  receivables_tableColumnTitleNetAmountInstallment_label: 'Net amount of installment',
  receivables_tableColumnTitleStatus_label: 'Status',
  receivables_csvColumnTitlePaymentDate_label: 'Payment date',
  receivables_csvColumnTitleAcquirer_label: 'Acquirer',
  receivables_csvColumnTitleFlag_label: 'Flag',
  receivables_csvColumnTitleParcel_label: 'Parcel',
  receivables_csvColumnTitleAmountInstallments_label: 'Amount installments',
  receivables_csvColumnTitleOperationType_label: 'Operation type',
  receivables_csvColumnTitleAuthorizationCode_label: 'Authorization Code (AUT)',
  receivables_csvColumnTitleDOCNSU_label: 'DOC/NSU',
  receivables_csvColumnTitleGrossAmount_label: 'Gross amount',
  receivables_csvColumnTitleNetAmount_label: 'Net amount',
  receivables_csvColumnTitleMdr_label: 'MDR (%)',
  receivables_csvColumnTitleMdrFee_label: 'MDR (R$)',
  receivables_csvColumnTitleBinamikFee_label: 'Binamik fee (R$)',
  receivables_csvColumnTitleCardNumber_label: 'Card number',
  receivables_csvColumnTitleSaleDate_label: 'Sale date',
  receivables_csvColumnTitleSaleGrossAmount_label: 'Gross sale value',
  receivables_csvColumnTitleStatus_label: 'Status',
  receivables_csvColumnTitleEventTypeAcquirer_label: 'Event type acquirer',
  receivables_csvColumnTitleDescriptionEventAcquirer_label: 'Description event acquirer',
  receivables_csvColumnTitleConciliated_label: 'Conciliated',
  receivable_selectPeriodToImport_toast: 'Select a period to perform the import',
  receivable_paymentImportPeriodLimitToOneMonth_toast: 'Import limit of one month (31 days) at a time',
  receivable_paymentNoFutureReceivablesAllowed_toast: 'Importing receivables for today or future dates is not allowed',
  receivable_itIsNotAllowedDeleteReceivablesAlreadyConciliated_toast: 'It is not allowed to delete receivables that have already been reconciled',
  receivable_deleteReceivable_toast: [
    '<<NUMBER>> receivable deleted',
    '<<NUMBER>> receivables deleted'
  ],
  receivable_invalidFileFormat_label: 'Invalid file format',

  revenue_statusScheduled_label: 'Scheduled',
  revenue_statusCanceled_label: 'Schedule Canceled',
  revenue_statusReceived_label: 'Received',
  revenue_statusRefunded_label: 'Receipt Refunded',
  revenue_accountsReceivableSuccessfullyRegistered_toast: 'Accounts Receivable registered successfully',
  revenue_errorRegisteringAccountsReceivable_toast: 'Error registering Accounts Receivable',
  revenue_accountsReceivableUpdatedSuccessfully_toast: 'Accounts Receivable updated successfully',
  revenue_errorUpdatingAccountsReceivable_toast: 'Error updating Accounts Receivable',
  revenue_errorFetchingAccountsReceivable_toast: 'Error fetching Accounts Receivable',
  revenue_dateOnlyByReceivedPresent_toast: 'Paid revenues cannot be generated for future dates',
  revenue_dateOnlyBeScheduledFuture_toast: 'Revenue scheduling can only be done for future dates',
  revenue_errorLoadingAccountsReceivableHistory_toast: 'Error loading accounts receivable history',
  revenue_errorLoadingAccountsReceivable_toast: 'Error loading accounts receivable',
  revenue_errorLoadingAvailableStatus_toast: 'Error loading available statuses',
  revenue_errorLoadingForgottenReceivables_toast: 'Error loading forgotten receivables',
  revenue_auditModalTitle_label: 'Change history Accounts Receivable',

  expense_statusScheduled_label: 'Scheduled',
  expense_statusCanceled_label: 'Canceled',
  expense_statusPayed_label: 'Paid',
  expense_statusRefunded_label: 'Refunded',
  expense_accountsPayableSuccessfullyRegistered_toast: 'Accounts Payable registered successfully',
  expense_errorRegisteringAccountsPayable_toast: 'Error registering Accounts Payable',
  expense_errorWhenSearchingForAccountsPayable_toast: 'Error when searching for Accounts Payable',
  expense_accountsPayableUpdatedSuccessfully_toast: 'Accounts Payable updated successfully',
  expense_errorUpdatingAccountsPayable_toast: 'Error updating Accounts Payable',
  expense_errorDeletingReceipts_toast: 'Error deleting receipts',
  expense_dateOnlyByPayedPresent_toast: 'Expense payments cannot be made for future dates',
  expense_dateOnlyBeScheduledFuture_toast: 'Expense scheduling can only be done for future dates',
  expense_onlyWithScheduledOrCanceledStatus_toast: 'Changes to other accounts payable only allowed with scheduled or canceled status',
  expense_errorLoadingAvailableStatus_toast: 'Error loading available statuses',
  expense_errorLoadingForgottenAccountsPayable_toast: 'Error loading forgotten accounts payable',
  expense_errorLoadingAccountsPayableHistory_toast: 'Error loading accounts payable history',
  expense_errorLoadingAccountsPayable_toast: 'Error loading accounts payable',
  expense_errorLoadingRecurringAccountsPayableSimulation_toast: 'Error loading recurring accounts payable simulation',
  expense_auditModalTitle_label: 'History of changes Accounts Payable',
  expense_accountsPayable_label: 'Accounts Payable',
  expense_newAccount_label: 'New Account',
  expense_newRecurringAccount_label: 'New Recurring Account',
  expense_accountPayableScheduledUnpaid_label: [
    'There is <<FORGOTTEN>> Account Payable scheduled and unpaid',
    'There are <<FORGOTTEN>> Accounts Payable scheduled and unpaid'
  ],
  expense_onTheLastDay_label: [
    ' on the last day.',
    ' in the last <<DAYS_AGO>> days.'
  ],
  expense_typeOfExpense_label: 'Type of Expense',
  expense_productAndService_label: 'Product/Service',
  expense_supplier_label: 'Supplier',
  expense_selectASupplier_label: 'Select a Supplier',
  expense_payment_label: 'Payment',
  expense_paymentMethod_label: 'Payment Method',
  expense_registrationDate_label: 'Registration Date',
  expense_selectPaymentDate_label: 'Select Payment Date',
  expense_selectReferenctDate_label: 'Select Reference Date',
  expense_selectExpenseType_label: 'Select Expense Type',
  expense_paymentDayOfTheMonth_label: 'Payment Day of the Month',
  expense_selectThePaymentDayOfTheMonth_label: 'Select the Payment Day of the Month',
  expense_bankFee_label: 'Bank Fee',
  expense_recurrenceType_label: 'Type of Recurrence',
  expense_informationOnTypesOfRecurrences_label: 'Information on Types of Recurrences',
  expense_recurringPayment_label: 'Recurring Payment',
  expense_paymentAmountWillBeTheSameAsTheSchedulingAmount_label: 'Payment amount will be the same as the scheduling amount',
  expense_installment_label: 'Installment',
  expense_appointmentCostPerRepetition_label: 'Payment amount will be the scheduling amount divided by the number of repetitions',
  expense_selectTheTypeOfRecurrence_label: 'Select the Type of Recurrence',
  expense_numberOfRepetitions_label: 'Number of Repetitions',
  expense_fillInNumberOfRepetitions_label: 'Fill in Number of Repetitions',
  expense_changeInformation_label: 'Information on Changes',
  expense_replicateChange_label: 'Replicate Change',
  expense_applyChangesToOtherRecurringPayments_label: 'Apply changes to other recurring payments',
  expense_doNotReplicateChanges_label: 'Do not replicate changes',
  expense_theEditOnlyAffectsTheCurrentPayment_label: 'The edit only affects the current payment',
  expense_selectTheChange_label: 'Select the Change',
  expense_addedReceipt_label: [
    '<<NUMBER>> receipt added',
    '<<NUMBER>> receipts added'
  ],
  expense_receipts_label: 'Receipts',
  expense_addOrRemoveReceitps_label: 'Add or Remove Receipts',
  expense_dragTheImageOrClickToAddMaximum1Mb_label: 'Drag the image or click to add maximum 1Mb',
  expense_simulationOfRecurringPayments_label: 'Simulation of Recurring Payments',
  expense_datesOfEachPaymentFromThisCurrentOne_label: 'Dates of each payment from this current one',
  expense_amountOfEachPayment_label: 'Amount of each payment:',
  expense_downloadYouReceipts_label: 'Download Your Receipts',
  expense_downloadAllReceitps_label: 'Download All Receipts',
  expense_accountPayableRegistration_label: 'Register Payable Account',
  expense_editPayableAccount_label: 'Edit Payable Account Registration',
  expense_recurringPayableAccountRegistration_label: 'Recurring Payable Account Registration',
  expense_editRecurringPayableAccountRegistration_label: 'Edit Recurring Payable Account Registration',
  expense_fillOuTheSimulationForm_label: 'Fill Out the Simulation Form',
  expense_mandatoryPaymentDayOfTheMonth_label: 'Mandatory Payment Day of the Month',
  expense_mandatoryRecurrenceType_label: 'Mandatory Recurrence Type',
  expense_replicateMandatoryChange_label: 'Replicate Mandatory Change',
  expense_viewPayableAccount_label: 'View Payable Account',
  expense_fileNameExpense_label: 'bills_to_pay_<<START_DATE>>-<<END_DATE>>',
  expense_repetitionLimitIs_label: 'Repetition limit is <<MAX_OCURRENCES>>',
  expense_editBatch_label: 'Edit batch',
  expense_selectedPayableAccount_label: [
    'Selected payable account',
    'Selected payable accounts'
  ],
  expense_editPayablesInBatch_label: 'Edit payables in batch',
  expense_accountsPayableUpdatedSuccessfully_label: 'Payables updated successfully',
  expense_addOrRemoveReceipts_label: 'Add or remove receipts',
  expense_attachmentWilNotBeAppliedToOtherInstallmentsRecurrences_label: 'This attachment will not be applied to other installments/recurrences',
  expense_attachmentWillAppearInAllInstallmentsRecurrences_label: 'This attachment will appear in all installments/recurrences',
  expense_totalBatchAccounts_label: 'Total batch accounts',
  expense_addReceipts_label: 'Add the receipts',
  expense_attachmentWillAppearOnAllTheAccountsInTheBatch_label: 'This attachment will appear on all the accounts in the batch',

  expense_revenue_typeOfRevenueExpenseSuccessfullyRegistered_toast: 'Type of Revenue/Expense registered successfully',
  expense_revenue_errorWhenRegisteringTypeOfRevenueExpense_toast: 'Error when registering Type of Revenue/Expense',
  expense_revenue_typeOfRevenueExpenseUpdatedSuccessfully_toast: 'Type of Revenue/Expense updated successfully',
  expense_revenue_errorWhenUpdatingRevenueExpenseType_toast: 'Error when updating Type of Revenue/Expense',
  expense_revenue_errorWhenSearchingForRevenueExpenseType_toast: 'Error when searching for Type of Revenue/Expense',
  expense_revenue_typeOfRevenueExpenseSuccessfullyRemoved_toast: 'Type of Revenue/Expense removed successfully',
  expense_revenue_errorRemovingRevenueExpenseType_toast: 'Error removing Type of Revenue/Expense',
  expense_revenue_errorLoadingReveueExpenseTypeHistory_toast: 'Error loading revenue/expense type history',
  expense_revenue_errorLoadingTypesOfRevenuesExpenses_toast: 'Error loading types of revenues/expenses',
  expense_revenue_errorLoadingRevenueExpenseTypeOptions_toast: 'Error loading revenue/expense type options',
  expense_revenue_expenseRevenueCannotDestroy_toast: 'Revenue/Expense in use cannot be deleted',
  expense_revenue_auditModalTitle_label: 'History of Expense/Revenue type changes',

  conciliation_pageTitleBankConciliations_label: 'Bank Conciliation',
  conciliation_tabsTitleConciliations_label: 'Conciliation',
  conciliation_tabsTitleNotConciliations_label: 'Not Conciliation',
  conciliation_tabsTitlePartiallyConciliations_label: 'Partially Conciliation',
  conciliation_tableTitleImportedBankPostings_label: 'Imported bank postings',
  conciliation_tableTitleSystemReleases_label: 'System releases',
  conciliation_fillInFieldsFromTheStartBar_label: 'To list reconciliations, first fill in the report filter fields',
  conciliation_tableColumnTitleDate_label: 'Date',
  conciliation_tableColumnTitleValue_label: 'Value',
  conciliation_tableColumnTitleHistoric_label: 'Historic',
  conciliation_tableColumnTitleDocument_label: 'Document',
  conciliation_tableColumnTitleDescription_label: 'Description',
  conciliation_startDate_inputLabel: 'Start date',
  conciliation_endDate_inputLabel: 'End date',
  conciliation_dateFormat_formErrorLabel: 'Date must have the format mm/dd/yyyy',
  conciliation_startDateRequired_formErrorLabel: 'Start date is required',
  conciliation_endDateRequired_formErrorLabel: 'End date is required',
  conciliation_startDate_placeholderLabel: 'Start date',
  conciliation_endDate_placeholderLabel: 'End date',
  conciliation_bankAccountId_inputLabel: 'Bank account',
  conciliation_bankAccountId_placeholderLabel: 'Select account',
  conciliation_bankAccountRequired_formErrorLabel: 'Bank account is required',
  conciliation_endDateMustBeAfterOrEqualStartDate_formErrorLabel: 'End date must be after or equal to start date',
  conciliation_listButton_label: 'List',
  conciliation_noItemsToConciliation_label: 'There are no items to conciliation',
  conciliation_itemSelected_label: ['Selected item', 'Selected Items'],
  conciliation_makeAutomaticConciliation_label: 'Make automatic conciliation',
  conciliation_makeManualConciliation_label: 'Make manual conciliation',
  conciliation_automaticConciliationCreate_label: [
    '<<NUMBER>> automatic conciliation created',
    '<<NUMBER>> automatic conciliations created'
  ],
  conciliation_manualConciliationCreate_label: 'Manual conciliation created',
  conciliation_deletedConciliation_label: [
    '<<NUMBER>> deleted conciliation',
    '<<NUMBER>> deleted conciliations'
  ],
  conciliation_deleteConciliations_error: 'Error deleting conciliations',
  conciliation_deleteConciliation_label: [
    'Delete conciliation',
    'Delete conciliations'
  ],
  conciliation_errorLoadingConciliations_toast: 'Error loading reconciliations',
  conciliation_errorCreatingAutomaticConciliation_toast: 'Error creating automatic reconciliation',
  conciliation_errorCreatingManualConciliation_toast: 'Error creating manual reconciliation',
  conciliation_divergentValue_label: 'Divergent Value',
  conciliation_divergentDate_label: 'Divergent Date',
  conciliation_details_label: 'Details',
  conciliation_conciliationBy_label: 'Conciliated by',
  conciliation_creationDate_label: 'Creation Date',
  conciliation_noConciliationItems_label: 'There are no conciliation items',
  conciliation_tableTitleReceivablesReleases_label: 'Receivables Releases',
  conciliation_pageTitleCreditCardConciliations_label: 'Credit Card Conciliations',
  conciliation_selectAcquirer_placeholderLabel: 'Select the acquirer',
  conciliation_reconcilingCanceledSystemPostingsIsNotPermitted_label: 'Reconciling canceled system postings is not permitted.',
  conciliation_totalAmountOfReceivablesNotReconciled_label: 'Total Amount of Unreconciled Receivables',
  conciliation_totalSystemValueNotReconciled_label: 'Total System Value Unreconciled',
  conciliation_totalValueOfReconciledReceivables_label: 'Total Value of Reconciled Receivables',

  bank_account_auditModalTitle_label: 'Change History Bank Accounts',
  bank_account_editModalTitle_label: 'Edit Bank Account',
  bank_account_tableColumnTitleBankName_label: 'Bank',
  bank_account_tableColumnTitleBranchNumber_label: 'Branch',
  bank_account_tableColumnTitleAccountNumber_label: 'Account',
  bank_account_tableColumnTitleStatus_label: 'Status',
  bank_account_tableColumnTitleAction_label: 'Action',
  bank_account_statusValueEnabled_label: 'Enabled',
  bank_account_statusValueDisabled_label: 'Disabled',
  bank_account_pageTitleBankAccounts_label: 'Bank Accounts',
  bank_account_bankName_inputLabel: 'Bank',
  bank_account_branchNumber_inputLabel: 'Branch',
  bank_account_accountNumber_inputLabel: 'Account',
  bank_account_status_inputLabel: 'Status',
  bank_account_accountablePlanId_inputLabel: 'Accountable Plan',
  bank_account_accountablePlanId_placeholderLabel: 'Select an accountable plan',
  bank_account_errorLoadBankAccountOptions_toast: 'Error loading bank account options',
  bank_account_errorLoadingBankAccountList_toast: 'Error loading the list of bank accounts',
  bank_account_errorLoadingBankAccount_toast: 'Error loading the bank account',
  bank_account_errorUpdatingBankAccount_toast: 'Error updating the bank account',
  bank_account_errorLoadingBankAccountHistory_toast: 'Error loading bank account history',
  bank_account_bankAccountHasBeenUpdatedSuccessfully_toast: 'Bank account has been updated successfully',
  bank_account_incompatibleDifferentBankAccount_toast: 'Different banks',

  bank_statement_errorFetchingBankStatements_toast: 'Error fetching bank statements',
  bank_statement_deletedBankStatement_toast: [
    'Deleted bank statement: <<NUMBER>>',
    'Deleted bank statements: <<NUMBER>>'
  ],
  bank_statement_errorDeletingBankStatements_toast: 'Error deleting bank statements',
  bank_statement_errorExportBankStatementFile_toast: 'Error exporting bank statement file',
  bank_statement_importCorruptedFile_toast: 'Corrupted bank statement file',
  bank_statement_importWrongNumberOrFile_toast: 'Unknown bank code or incorrect file',
  bank_statement_importEmptyFieldFormats_toast: 'Fields not registered for this file format',
  bank_statement_errorFetchingImportedBankStatements_toast: 'Error loading the list of imported bank statements',
  bank_statement_imported_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['imported release', 'imported releases']
    }
  },
  bank_statement_transactions_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['statement transactions', 'statement transactions']
    }
  },
  bank_statement_ignored_current_date_transactions_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['ignored transaction on current date', 'ignored transactions on current date']
    }
  },
  bank_statement_already_imported_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['release already imported', 'releases already imported']
    }
  },
  bank_statement_not_imported_releases: {
    template: '<<RELEASES>>',
    substitutions: {
      RELEASES: ['release not imported', 'releases not imported']
    }
  },
  bank_statement_date_releases: 'Date <<DATE>>:',
  bank_statement_importing_bank_statements_label: 'Bank Statements Import',
  bank_statement_bank_account_label: 'Bank Account',
  bank_statement_accepted_file_formats_label: 'Accepted File Formats:',
  bank_statement_statement_import_completed_label: 'Statement Import Completed!',
  bank_statement_waiting_bank_statement_imported_label: 'Waiting for Bank Statement Import!',
  bank_statement_latest_imported_extracts_label: 'Latest Imported Statements',
  bank_statement_details_label: 'Details:',
  bank_statement_select_account_label: 'Select an Account',
  bank_statement_itIsNotAllowedDeleteBankStatementAlreadyConciliated_toast: 'It is not allowed to delete bank statements that have already been reconciled',
  bank_statement_errorWhenImportingBankStatements_toast: 'Error importing bank statements',
  bank_statement_bankAccountNotAllowed_toast: 'Bank account not allowed',
  bank_statement_onlyPastDatesAllowed_label: 'Only past dates are allowed',
  bank_statement_performImport_label: 'Perform import',
  bank_statement_pixPaymentPagarme_label: 'Pix payment Pagar.me',
  bank_statement_transferFromPagarme_label: 'Transfer from Pagar.me',
  bank_statement_startAndEndDateMustBeDifferent_label: 'Start and end date must be different',

  account_plan_errorFecthingAccountsPlans_toast: 'Error fetching account plans',
  account_plan_errorFecthingAccountPlan_toast: 'Error fetching account plan',
  account_plan_errorFecthingAccountsPlansOptions_toast: 'Error when fetching for chart of accounts options',
  account_plan_errorDeleteAccountPlan_toast: 'Error deleting account plan',
  account_plan_successDeletedAccountPlan_toast: 'Account plan successfully deleted',
  account_plan_accountPlanSucessRegistered_toast: 'Account plan has been successfully registered',
  account_plan_errorRegisteredAccountPlan_toast: 'Error registering account plan',
  account_plan_accountPlanSucessUpdated_toast: 'Account plan has been updated successfully',
  account_plan_errorUpdateAccountPlan_toast: 'Error update account plan',

  event_menuTitleEvents_label: 'Events',
  event_eventType_inputLabel: 'Event type',
  event_selectEventType_placeholderLabel: 'Select event type',
  event_pageTitleEvents_label: 'Events',
  event_tableColumnTitleEventType_label: 'Event Type',
  event_tableColumnTitleEventData_label: 'Event Data',
  event_errorFetchingEvents_toast: 'Error fetching events',
  event_errorFecthingEventsTypes_toast: 'Error fetching event types',
  event_errorFechtingEventRequestDetails_toast: 'Error fetching event request details',
  event_errorFetchingEventFieldNames_toast: 'Error fetching event field names',
  // event type
  event_partnershipCredit_label: 'Partnership Credit',
  event_schedulingAccountsPayable_label: 'Scheduling Accounts Payable',
  event_schedulingAccountsReceivable_label: 'Scheduling Accounts Receivable',
  event_writeOffExternalBillet_label: 'Write-off External Billet',
  event_writeOffAccountsPayable_label: 'Write-off of Accounts Payable',
  event_writeOffAccountsReceivable_label: 'Write-off of Accounts Receivable',
  event_writeOffExternalBilletFee_label: 'Write-off External Billet Fee',
  event_digitalAccountMovement_label: 'Digital Account Movement',
  event_overdueBillet_label: 'Overdue Billet',
  event_onlinePaymentCancellationCreditCard_label: 'Cancellation Online payment - Credit Card',
  event_invoicePaymentCancellationSeller_label: 'Invoice Payment Cancellation - Seller',
  event_cancellationAccountsPayable_label: 'Cancellation of Accounts Payable',
  event_cancellationAccountsReceivable_label: 'Cancellation of Accounts Receivable',
  event_ISSPaymentCancellation_label: 'ISS Payment Cancellation',
  event_ticketCancellation_label: 'Ticket Cancellation',
  event_supplierISSpaymentCancellation_label: 'Supplier ISS Payment Cancellation',
  event_customerInvoiceCancellation_label: 'Customer Invoice Cancellation',
  event_offlinePaymentCancellation_label: 'Offline Payment Cancellation',
  event_onlinePaymentCreditCard_label: 'Online Payment - Credit Card',
  event_onlinePaymentDebitCard_label: 'Online payment - Debit Card',
  event_invoiceReceivement_label: 'Invoice Receivement',
  event_bankAccountRegistration_label: 'Bank Account Registration',
  event_gravameDebit_label: 'Debit of Gravame',
  event_checkDeposit_label: 'Check Deposit',
  event_cashDeposit_label: 'Cash Deposit',
  event_checkReturned_label: 'Check Returned',
  event_billetIssue_label: 'Issuance of Billet',
  event_ISSPaymentScheduling_label: 'ISS Payment Scheduling',
  event_supplierISSPaymentSchedule_label: 'Supplier ISS Payment Scheduling',
  event_issuanceInvoiceCustomer_label: 'Issuance of Invoice to the Customer',
  event_agentBoxEntry_label: 'Entry to the Agent cashier',
  event_partnershipDebit_label: 'Partnership Debit',
  event_refundAccountsPayable_label: 'Refund of Accounts Payable',
  event_refundAccountsReceivable_label: 'Refund of Accounts Receivable',
  event_checkDespostiRefund_label: 'Check Deposit Refund',
  event_ISSPaymentRefund_label: 'ISS Payment Refund',
  event_supplierISSPaymentRefund_label: 'Supplier ISS Payment Refund',
  event_invoicePaymentRefund_label: 'Invoice Payment Reversal',
  event_POSMonthlyFee_label: 'POS Monthly Fee',
  event_invoicePayment_label: 'Invoice Payment',
  event_paymentOfISSTax_label: 'ISS Tax Payment',
  event_supplierISSTaxPayment_label: 'Supplier ISS Tax Payment',
  event_offlinePayment_label: 'Offline Payment',
  event_tourTaken_label: 'Tour Taken',
  event_guideTourOwnTicket_label: 'Tour Taken - Own Ticket',
  event_transferSupplier_label: 'Transfer to Supplier',
  event_cancelattionTransferSupplier_label: 'Cancellation of Transfer to Supplier',
  event_transferPaymentDebitCard_label: 'Transfer Payment by Debit Card',
  event_confirmedReservation_label: 'Confirmed Reservation',
  event_confirmedBookingOwnTicket_label: 'Confirmed Reservation - Own Ticket',
  event_withdrawalCurrentAccount_label: 'Withdrawal from Current Account',
  event_agentCashOut_label: 'Agent Cash Out',
  event_transferBetweenBankAccounts_label: 'Transfer Between Bank Accounts',
  // event_request
  event_info_label: 'Information',
  event_clientName_label: 'Client Name',
  event_partnerName_label: 'Partner Name',
  event_reservationCode_label: 'Reservation Code',
  event_fee_label: 'Fee',
  event_type_label: 'Type',
  event_brand_label: 'Brand',
  event_installments_label: 'Installments',
  event_operator_label: 'Operator',
  event_multipoint_label: 'Multipoint',
  event_originType_label: 'Origin Type',
  event_datePayment_label: 'Payment Date',
  event_paymentMethod_label: 'Payment Method',
  event_paymentValue_label: 'Payment Value',
  event_cashierReopened_label: 'Cashier Reopened',
  event_guideValue_label: 'Guide Value',
  event_tourDate_label: 'Tour Date',
  event_activityName_label: 'Activity Name',
  event_ticketCode_label: 'Ticket Code',
  event_cardNumber_label: 'Card Number',
  event_operatorName_label: 'Operator Name',
  event_userName_label: 'User Name',
  event_valueOfTaxIss_label: 'Value of ISS Tax',
  event_attractiveValue_label: 'Attractive Value',
  event_advanceAmount_label: 'Advance Amount',
  event_agencyValue_label: 'Agency Value',
  event_agencyFee_label: 'Agency Fee',
  event_operatorFee_label: 'Operator Fee',
  event_antifraudFee_label: 'Antifraud Fee',
  event_mdrFee_label: 'MDR Fee',
  event_payFee_label: 'Pay Fee',
  event_cancelattionDate_label: 'Cancellation Date',
  event_creditDate_label: 'Credit Date',
  event_debitDate_label: 'Debit Date',
  event_dateOfReturn_label: 'Return Date',
  event_amountCanceled_label: 'Canceled Amount',
  event_amountReturned_label: 'Returned Amount',
  event_amountDeposited_label: 'Deposited Amount',
  event_agencyCancellationFee_label: 'Agency Cancellation Fee',
  event_partnerCancellationFee_label: 'Partner Cancellation Fee',
  event_canceledCardFee_label: 'Canceled Card Fee',
  event_feeCharged_label: 'Charged Fee',
  event_attractiveCancellationFee_label: 'Attractive Cancellation Fee',
  event_attractiveFee_label: 'Attractive Fee',
  event_orbFee_label: 'Orb Fee',
  event_tuitionAmount_label: 'Tuition Amount',
  event_ticketBySplit_label: 'Ticket by Split',
  event_paymentInTheAttractive_label: 'Payment in the Attractive',
  event_invoiceIssuanceDate_label: 'Invoice Issuance Date',
  event_emissionDate_label: 'Emission Date',
  event_amountOfTaxToBeCollected_label: 'Amount of Tax to be Collected',
  event_invoiceTaxAmount_label: 'Invoice Tax Amount',
  event_advanceValue_label: 'Advance Value',
  event_ticketOriginType_label: 'Ticket Origin Type',
  event_tickets_label: 'Tickets',
  event_ticketPrice_label: 'Ticket Price',
  event_invoiceCancellationDate_label: 'Invoice Cancellation Date',
  event_refundDate_label: 'Refund Date',
  event_transferDate_label: 'Transfer Date',
  event_dueDate_label: 'Due Date',
  event_invoiceDueDate_label: 'Invoice Due Date',
  event_invoiceAmount_label: 'Invoice Amount',
  event_transferAmount_label: 'Transfer Amount',
  event_billetValue_label: 'Billet Value',
  event_description_label: 'Description',
  event_amountPaid_label: 'Amount Paid',
  event_advanceDate_label: 'Advance Date',
  event_depositDate_label: 'Deposit Date',
  event_CCChartOfAccounts_label: 'CC Chart of Accounts',
  event_CCOriginChartOfAccounts_label: 'CC Origin Chart of Accounts',
  event_CCDestinationChartOfAccounts_label: 'CC Destination Chart of Accounts',
  event_machineNumber_label: 'Machine Number',
  event_amountRefund_label: 'Refund Amount',
  event_billetFee_label: 'Billet Fee',
  event_noteIssuanceDate_label: 'Note Issuance Date',
  event_date_label: 'Date',
  event_profit_label: 'Profit',
  event_dateReceivement_label: 'Receivement Date',
  event_withdrawalDate_label: 'Withdrawal Date',
  event_referenceDate_label: 'Reference Date',
  event_amountReceivable_label: 'Receivable Amount',
  event_value_label: 'Value',
  event_withdrawalValue_label: 'Withdrawal Value',
  event_amountReceived_label: 'Received Amount',
  event_amountPayable_label: 'Payable Amount',
  event_bankFee_label: 'Bank Fee',
  event_bankNumber_label: 'Bank Number',
  event_bankName_label: 'Bank Name',
  event_agencyNumber_label: 'Agency Number',
  event_accountNumber_label: 'Account Number',
  event_accountType_label: 'Account Type',
  event_originSystem_label: 'Origin System',
  event_enabled_label: 'Enabled',
  event_paymentMethods_label: 'Payment Methods',
  event_operationType_label: 'Operation Type',
  event_installmentsBeignPaid_label: 'Installments Being Paid',
  event_numberOfInstallmentsOfTheSale_label: 'Number of Installments of the Sale',
  event_saleDate_label: 'Sale Date',
  event_grossValue_label: 'Gross Value',
  event_netValue_label: 'Net Value',
  event_totalAmountOfFees_label: 'Total Amount of Fees',
  event_breakdownOfFees_label: 'Breakdown of Fees',
  event_xpertFee_label: 'Xpert Fee',
  event_transactionFee_label: 'Transaction Fee',

  supplier_pageTitleSuppliers_label: 'Suppliers',
  supplier_contactDetailsMenuTitle_label: 'Contact details',
  supplier_addressMenuTitle_label: 'Adress',
  supplier_bankAccountMenuTitle_label: 'Bank accounts',
  supplier_tableColumnTitleCorporateName_label: 'Corportate Name',
  supplier_tableColumnTitleSupplierDocument_label: 'Document',
  supplier_tableColumnTitleFancyName_label: 'Fancy Name',
  supplier_tableColumnTitleStatus_label: 'Status',
  supplier_tableColumnTitleAction_label: 'Action',
  supplier_statusValueDisabled_label: 'Disabled',
  supplier_statusValueEnabled_label: 'Enabled',
  supplier_statusValueAll_label: 'All',
  supplier_newSupplierButton_label: 'Register new supplier',
  supplier_modalNewSupplierTitle_label: 'Register supplier',
  supplier_modalEditSupplierTitle_label: 'Edit supplier',
  supplier_listButton_label: 'Search',
  supplier_legalPerson_label: 'Legal Person',
  supplier_naturalPerson_label: 'Natural Person',
  supplier_corporateNameRequired_formErrorLabel: 'Corporate name is required',
  supplier_supplierTypeRequired_formErrorLabel: 'Supplier type is required',
  supplier_zipCodeMaxCharacters_formErrorLabel: 'The zip code must have a maximum of 8 characters',
  supplier_stateMaxCharacters_formErrorLabel: 'State must be 2 characters only',
  supplier_corporateName_inputLabel: 'Corporate name',
  supplier_fancyName_inputLabel: 'Fancy name',
  supplier_supplierDocument_inputLabel: 'Document',
  supplier_supplierType_inputLabel: 'Supplier type',
  supplier_municipalRegistration_inputLabel: 'Municipal registration',
  supplier_address_inputLabel: 'Address',
  supplier_neighborhood_inputLabel: 'Neighborhood',
  supplier_cityName_inputLabel: 'City',
  supplier_state_inputLabel: 'State',
  supplier_country_inputLabel: 'Country',
  supplier_zipCode_inputLabel: 'Zip code',
  supplier_emails_inputLabel: 'Emails',
  supplier_phoneNumbers_inputLabel: 'Phone numbers',
  supplier_contactNames_inputLabel: 'Contact names',
  supplier_observation_inputLabel: 'Observation',
  supplier_corporateName_placeholderLabel: 'Enter the corporate name',
  supplier_fancyName_placeholderLabel: 'Enter the fancy name',
  supplier_supplierDocument_placeholderLabel: 'Enter the document',
  supplier_supplierType_placeholderLabel: 'Select supplier type',
  supplier_enabled_placeholderLabel: 'Select the state',
  supplier_municipalRegistration_placeholderLabel: 'Enter the municipal registration',
  supplier_address_placeholderLabel: 'Enter the address',
  supplier_neighborhood_placeholderLabel: 'Enter the neighborhood',
  supplier_cityName_placeholderLabel: 'Enter the city',
  supplier_state_placeholderLabel: 'Enter the state',
  supplier_country_placeholderLabel: 'Enter the country',
  supplier_zipCode_placeholderLabel: 'Enter the zip code',
  supplier_emails_placeholderLabel: 'Enter contact emails',
  supplier_phoneNumbers_placeholderLabel: 'Enter contact phones',
  supplier_contactNames_placeholderLabel: 'Enter contact persons',
  supplier_observation_placeholderLabel: 'Enter the observation',
  supplier_errorfecthingSuppliers_toast: 'Error fetching suppliers',
  supplier_createSupplier_toast: 'Supplier was successfully created',
  supplier_errorCreateSupplier_toast: 'Error creating supplier',
  supplier_errorGetByIdSupplier_toast: 'Error loading supplier',
  supplier_editSupplier_toast: 'Supplier successfully edited',
  supplier_errorEditSupplier_toast: 'Error editing supplier',
  supplier_createButton_label: 'Create',
  supplier_removeDialogTitle_label: 'Delete',
  supplier_removeDialogMessage_label: 'Are you sure you want to delete the supplier?',
  supplier_removeSupplier_toast: 'Supplier removed successfully',
  supplier_errorRemoveSupplier_toast: 'Error removing supplier',
  supplier_modalAuditSupplierTitle_label: 'History of Changes Suppliers',
  supplier_errorAuditSupplier_toast: 'Error loading supplier history',
  supplier_listSupplierBankAccounts_label: 'List bank accounts',
  supplier_errorLoadSupplierOptions_toast: 'Error loading supplier options',
  supplier_supplierCannotDestroy_toast: 'Supplier in use in Expenses cannot be deleted',
  supplier_supplierAlreadyRegistered_toast: 'This document has already been registered with another supplier',

  supplier_bank_account_pageTitleSupplierBankAccounts_label: 'Bank Accounts of',
  supplier_bank_account_newSupplierBankAccountButton_label: 'Register a new supplier bank account',
  supplier_bank_account_modalAuditSupplierBankAccountTitle_label: 'History of changes Suppliers\' Bank Accounts',
  supplier_bank_account_tableColumnTitleBankName_label: 'Bank',
  supplier_bank_account_tableColumnTitleBranch_label: 'Branch',
  supplier_bank_account_tableColumnTitleAccountNumber_label: 'Account Number',
  supplier_bank_account_tableColumnTitleStatus_label: 'Status',
  supplier_bank_account_tableColumnTitleAction_label: 'Action',
  supplier_bank_account_statusValueDisabled_label: 'Disabled',
  supplier_bank_account_statusValueEnabled_label: 'Enabled',
  supplier_bank_account_accountTypeValueCheckingAccount_label: 'Checking account',
  supplier_bank_account_accountTypeValueSavingsAccount_label: 'Savings account',
  supplier_bank_account_modalCreateSupplierBankAccountTitle_label: 'Create new bank account',
  supplier_bank_account_bankName_inputLabel: 'Bank',
  supplier_bank_account_branch_inputLabel: 'Branch',
  supplier_bank_account_accountNumber_inputLabel: 'Account number',
  supplier_bank_account_accountType_inputLabel: 'Account type',
  supplier_bank_account_enabled_inputLabel: 'Status',
  supplier_bank_account_bankName_placeholderLabel: 'Enter the bank',
  supplier_bank_account_branch_placeholderLabel: 'Enter the branch',
  supplier_bank_account_accountNumber_placeholderLabel: 'Enter the account number',
  supplier_bank_account_accountType_placeholderLabel: 'Select the account type',
  supplier_bank_account_enabled_placeholderLabel: 'Select the status',
  supplier_bank_account_bankNameRequired_formErrorLabel: 'Bank is required',
  supplier_bank_account_branchRequired_formErrorLabel: 'Branch is required',
  supplier_bank_account_accountNumberRequired_formErrorLabel: 'Account number is required',
  supplier_bank_account_accountTypeRequired_formErrorLabel: 'Account type is required',
  supplier_bank_account_enabledRequired_formErrorLabel: 'Status is required',
  supplier_bank_account_errorFecthingSupplierBankAccounts_toast: 'Error when searching for suppliers\' bank accounts',
  supplier_bank_account_createSupplierBankAccount_toast: 'Supplier bank account created successfully',
  supplier_bank_account_errorCreateSupplierBankAccount_toast: 'Error creating supplier bank account',
  supplier_bank_account_editSupplierBankAccount_toast: 'Supplier bank account edited successfully',
  supplier_bank_account_errorEditSupplierBankAccount_toast: 'Error when editing supplier bank account',
  supplier_bank_account_modalEditSupplierBankAccountTitle_label: 'Edit supplier bank account',
  supplier_bank_account_removeSupplierBankAccount_toast: 'Supplier bank account successfully removed',
  supplier_bank_account_errorRemoveSupplierBankAccount_toast: 'Error removing supplier bank account',
  supplier_bank_account_removeDialogTitle_label: 'Delete',
  supplier_bank_account_removeDialogMessage_label: 'Are you sure you want to delete the supplier\'s bank account?',

  event_processor_menuTitleEventsProcessors_label: 'Event Processors',
  event_processor_pageTitleEventsProcessors_label: 'Event Processors',
  event_processor_tableColumnTitleEventType_label: 'Processor Name',
  event_tableColumnTitleEntryCondition_label: 'Entry Condition',
  event_processor_tableColumnTitleStatus_label: 'Status',
  event_processor_tableColumnTitleActions_label: 'Actions',
  event_processor_errorFetchingEventsProcessors_toast: 'Error fetching event processors',
  event_processor_errorRemoveEventProcessor_toast: 'Error deleting the event processor',
  event_processor_successDeletedEventProcessor_toast: 'Event processor successfully deleted',
  event_processor_areYouSureToDeleteProcessors_label: 'Are you sure you want to delete the processor?',
  event_processor_processorName_inputLabel: 'Processor Name',
  event_processor_entryConditionField_inputLabel: 'Entry Condition Field',
  event_processor_entryConditionValue_inputLabel: 'Entry Condition Value',
  event_processor_status_inputLabel: 'Status',
  event_processor_enterProcessorName_placeholderLabel: 'Enter the processor name',
  event_processor_selectInputConditionField_placeholderLabel: 'Select the entry condition field',
  event_processor_selectInputConditionValue_placeholderLabel: 'Select the entry condition value',
  event_processor_selectTheStatus_placeholderLabel: 'Select the status',
  event_processor_condition_label: [
    '<<NUMBER>> condition',
    '<<NUMBER>> conditions'
  ],
  event_processor_entryConditions_label: [
    'Entry Condition',
    'Entry Conditions'
  ],
  event_processor_newProcessor_label: 'New Processor',
  event_processor_nameAndDescription_label: 'Name and Description',
  event_processor_saveAndContinue_label: 'Save and Continue',
  event_processor_selectionAndValidationEvents_label: 'Event Selection and Validation',
  event_processor_automationRules_label: 'Automation Rules',
  event_processor_nameRequired_formErrorLabel: 'Name is required',
  event_processor_sucessRegisteredEventProcessor_toast: 'Event Processor successfully registered',
  event_processor_errorRegisteringdEventProcessor_toast: 'Error registering the Event Processor',
  event_processor_description_inputLabel: 'Description',
  event_processor_enterDescription_placeholderLabel: 'Enter a description',
  event_processor_editProcessor_label: 'Edit processor',
  event_processor_finishTheFisrtSession_toast: 'Finish the first session',
  event_processor_selectEventDetails_label: 'Select an event to view details',
  event_processor_eventWithoutDetails_label: 'Event without details',
  event_processor_listEvents_label: 'List events',
  event_processor_tableColumnTitleField_label: 'Field',
  event_processor_tableColumnTitleValue_label: 'Value',
  event_processor_tableColumnTitleAction_label: 'Action',
  event_processor_addInProcessor_label: 'Add in processor',
  event_processor_pageTitleSelectAnEvent_label: 'Select an event',
  event_processor_selectedEvent_label: 'Selected event',
  event_processor_selectEvent_label: 'Select event',
  event_processor_createEventEntryConditions_label: 'Create event entry conditions',
  event_processor_selectingFieldAddConditions_label: 'When selecting a field, add the conditions it should be subjected to.',
  event_processor_conditionAddedToProcessor_toast: 'Condition added to the processor',
  event_processor_errorAddingConditionInProcessor_toast: 'Error adding condition to the processor',
  event_processor_eventProcessorUpdatedSuccess_toast: 'Event Processor updated successfully',
  event_processor_errorUpdatingEventProcessor_toast: 'Error updating the Event Processor',
  event_processor_errorFetchingCondition_toast: 'Error fetching the condition',
  event_processor_errorFetchingConditions_toast: 'Error fetching conditions',
  event_processor_errorFectingEventProcessor_toast: 'Error fetching the event processor',
  event_processor_conditionRemovedSuccess_toast: 'Condition removed successfully',
  event_processor_errorRemovingCondition_toast: 'Error removing the condition',
  event_processor_conditionSuccessUpdated_toast: 'Condition updated successfully',
  event_processor_errorUpdatingCondition_toast: 'Error updating the condition',
  event_processor_uniqueConditionsMessage_label: 'Some input conditions are already being processed by one or more existing event processors.',
  event_processor_processorWithSimilarConditions_label: [
    'Processor with similar conditions',
    'Processors with similar conditions'
  ],
  event_processor_eventServicedByProcessor_label: [
    'Event serviced by processor',
    'Events serviced by processor'
  ],
  event_processor_matchedEventsSubtitle_label: 'To list processed events, you must register at least 1 input condition.',
  event_processor_doesNotHaveEventsServicedByProcessor_label: 'Does not have events serviced by the processor',
  event_processor_errorFetchingMatchingEvents_toast: 'Error fetching matching events',
  event_processor_erroFetchingUniqueConditions_toast: 'Error fetching unique conditions',
  event_processor_tableColumnTitleOperationType_label: 'Operation Type',
  event_processor_tableColumnTitleDate_label: 'Date',
  event_processor_tableColumnTitleDestinationAccount_label: 'Destination Account',
  event_processor_registeredAutomationRules_label: 'Registered Automation Rules',
  event_processor_tableColumnTitleRealeaseDate_label: 'Release Date',
  event_processor_automationRulesSimulator_label: 'Automation Rules Simulator',
  event_processor_theAutomationRulesAre_label: 'The automation rules are',
  event_processor_matchedEventsTiteInAutomationRules_label: 'To create the automation rules, select the event',
  event_processor_errorFetchingAutomationRules_toast: 'Error fetching automation rules',
  event_processor_errorWhenFetchingSimulationsAutomationRules_toast: 'Error when fetching simulations of automation rules',
  event_processor_errorRemovingAutomationRule_toast: 'Error removing automation rule',
  event_processor_automationRuleSuccessRemoved_toast: 'Automation rule successfully removed',
  event_processor_action_inputLabel: 'Action',
  event_processor_selectTheAction_placeholderLabel: 'Select the action',
  event_processor_value_inputLabel: 'Value',
  event_processor_enterTheValue_placeholderLabel: 'Enter the value',
  event_processor_for_inputLabel: 'For',
  event_processor_selectTheComparator_placeholderLabel: 'Select the comparator',
  event_processor_operationType_inputLabel: 'Operation type',
  event_processor_selectTheTypeOfOperation_placeholderLabel: 'Select the type of operation',
  event_processor_date_inputLabel: 'Date',
  event_processsor_enterTheDateFormula_placeholderLabel: 'Enter the date formula',
  event_processor_referenceDate_inputLabel: 'Reference date',
  event_processor_enterTheReferenceDateFormula_placeholderLabel: 'Enter the reference date formula',
  event_processor_account_inputLabel: 'Account',
  event_processor_selectTheAccount_placeholderLabel: 'Select the account',
  event_processor_accountViaFormula_inputLabel: 'Account via formula',
  event_processor_selectTheAccountFormula_placeholderLabel: 'Select the account formula',
  event_processor_installmentDate_label: 'Create dates for installments according to credit card brand rules',
  event_processor_installmentValue_label: 'Round installment value according to gateway rounding rules',
  event_processor_installmentFeeValue_label: 'Round installment fee value according to gateway rounding rules',
  event_processor_getBankAccount_label: 'Return the Bank Account to be used from the event field',
  event_processor_getAccountFromExpenseRevenue_label: 'Return the account associated with the Expense/Revenue account type provided in the event field, if available',
  event_processor_binamikFee_label: 'Calculate total Binamik fee for sale and/or refund',
  event_processor_fromCents_label: 'Convert value from cents to currency (divide by 100), e.g., from_cents(450) = 4.50',
  event_processor_refundDate_label: 'Calculate refund date (business day) according to Operator and Credit Card brand rules. This formula can only be used in actions "Create Future Entry via formula for Credit Card refund" and "Accelerate installments for Credit Card refund".',
  event_processor_toDate_label: 'Convert event_field to a date, if possible',
  event_processor_durationOneMonth_label: 'Duration of 1 month, considering the number of days in the month',
  event_processor_durationTwentySixDays_label: 'Duration of 26 days',
  event_processor_nextBusinessDays_label: "Return the next business day after the calculated date if it's not a business day",
  event_processor_nextBusinessDaysOneMoreMonth_label: 'Business day: event_field date plus one more month',
  event_processor_operationIfElse_label: 'IF inc < event_field, the following formula returns event_field_2; OTHERWISE, it returns round(event_field_2)',
  event_processor_roundValue_label: 'Round the value of event_field',
  event_processor_roundValueWithTwoDecimalPlaces_label: 'Rounding with 2 decimal places',
  event_processor_roundValueUp_label: 'Round up (next integer)',
  event_processor_roundValueDown_label: 'Round down (previous integer)',
  event_processor_allowedFormulas_label: 'Allowed formulas',
  event_processor_description_label: 'Description',
  event_processor_formula_label: 'Formula',
  event_processor_operation_label: 'Operation',
  event_processor_newAutomationRule_label: 'New automation rule',
  event_processor_pageTitleEditingAutomationRule_label: 'Editing automation rule',
  event_processor_pageTitleNewAutomationRule_label: 'New automation rule',
  event_processor_rules_label: 'Rules',
  event_processor_fieldOfAllowedEvents_label: 'Field of allowed events',
  event_processor_automationRuleActionRequired_formErrorLabel: 'Action is required',
  event_processor_operationTypeRequired_formErrorLabel: 'Operation type is required',
  event_processor_dateWithFormulaRequired_formErrorLabel: 'Date is required',
  event_processor_referenceDateWithFormulaRequired_formErrorLabel: 'Reference date is required',
  event_processor_automationRuleStatusRequired_formErrorLabel: 'Status is required',
  event_processor_accountIdRequired_formErrorLabel: 'Account is required',
  event_processor_accountFormulaRequired_formErrorLabel: 'Account via formula is required',
  event_processor_valueRequired_formErrorLabel: 'Value is required',
  event_processor_automationRuleCreatedSuccessfully_toast: 'Automation rule created successfully',
  event_processor_errorCreatingAutomationRule_toast: 'Error creating automation rule',
  event_processor_errorFetchingAutomationRuleActions_toast: 'Error fetching automation rule actions',
  event_processor_errorFetchingAccountFormulasForAutomationRule_toast: 'Error fetching account formulas for automation rule',
  event_processor_errorFetchingAccountsForAutomationRule_toast: 'Error fetching accounts for automation rule',
  event_processor_automationRuleUpdatedSuccessfully_toast: 'Automation rule updated successfully',
  event_processor_errorUpdatingAutomationRule_toast: 'Error updating automation rule',
  event_processor_errorFetchingAutomationRule_toast: 'Error fetching automation rule',
  event_processor_uniqueConditionsEntryConditionsNotUnique_toast: 'Cannot activate the processor, as entry conditions are not unique',
  event_processor_simulatonsInvalidAutomationRules_toast: 'Cannot activate the processor, there are invalid automation rules',
  event_processor_conditionsNoEntryConditions_toast: 'Cannot activate the processor, there are no entry conditions',
  event_processor_cashFlow_label: 'Cash Flow',
  event_processor_iterationField_inputLabel: 'Iteration Field',
  event_processor_enterTheIterationField_placeholderLabel: 'Enter the iteration field',
  event_processor_descriptionRequired_formErrorLabel: 'Description required',
  event_processor_automationRuleAccountIdIsMissing_toast: 'Account is required',
  event_processor_automationRuleAccountFormulaIsMissing_toast: 'Account via formula is required',
  // options account formulas for automation rule
  event_processor_getEventBankAccount_label: 'Event Bank Account',
  event_processor_getAccountablePlanFromBankAccount_label: 'Accountable Plan from Event Bank Account',
  event_processor_getAccountblePlanFromEventRevenue_label: 'Accountable Plan from Event Revenue Type',
  event_processor_getAccountblePlanFromEventExpense_label: 'Accountable Plan from Event Expense Type',
  // automation rule status
  event_processor_automationRuleWaitingStatus_label: 'Awaiting future entry date',
  event_processor_automationRuleNeedConfirmationStatus_label: 'Needs Confirmation',
  event_processor_automationRuleProcessedStatus_label: 'Processed',
  event_processor_automationRuleBilledStatus_label: 'Billed',
  event_processor_automationRuleCanceledStatus_label: 'Cancelled',
  event_processor_automationRuleDoneStatus_label: 'Completed',
  // comparison_type
  event_processor_equal_label: 'Equal to',
  event_processor_lessThan_label: 'Less than',
  event_processor_greaterThan_label: 'Greater than',
  event_processor_in_label: 'One of',
  event_processor_notEqual_label: 'Not equal to',
  event_processor_notIn_label: 'None of',
  // actions
  event_processor_moveAccountForecastWithFormula_label: 'Create Future Entry',
  event_processor_moveAccountWithFormula_label: 'Create Account Movement',
  event_processor_moveAccountForecastWithFormulaForRefund_label: 'Create Future Entry for Credit Card Refund',
  event_processor_updateAccountForecastWithFormula_label: 'Update Future Entry Status',
  event_processor_updateAccountMovement_label: 'Update Account Movement Status',
  event_processor_accelerateInstallmentsForRefund_label: 'Accelerate installments for Credit Card Refund',
  event_processor_updateTicketStatus_label: 'Update Ticket Status',
  event_processor_analyzeInvoicePartialPayment_label: 'Analyze Partial Invoice Payment',
  event_processor_createUpdateBankAccount_label: 'Create/Update Bank Account',
  event_processor_moveBankAccount_label: 'Record movement in Digital Account',
  event_processor_initBilling_label: 'Initialize Billing for a company',
  event_processor_updateBilling_label: 'Update Model Events and Processors',
  event_processor_deleteBilling_label: 'Delete Model Events and Processors',

  creditcard_movement_errorLoadingTheFinancialCardsSchedule_toast: 'Error loading the financial cards schedule',
  creditcard_movement_errorWhenExportingFinancialCards_toast: 'Error exporting financial cards schedule',

  flow_movment_errorLoadingCompleteFinancialSchedule_toast: 'Error loading complete financial schedule',
  flow_movment_errorWhenExportingCompleteFinancialSchedule_toast: 'Error exporting complete financial schedule',
  flow_movement_date_label: 'Date',
  flow_movement_description_label: 'Description',
  flow_movement_releaseType_label: 'Release Type',
  flow_movement_operation_label: 'Operation',
  flow_movement_value_label: 'Value',
  flow_movement_status_label: 'Status',
  flow_movement_totalPayable_label: 'Total Payable',
  flow_movement_totalReceivable_label: 'Total Receivable',
  flow_movement_pageTitleFullFinancialSchedule_label: 'Full Financial Schedule',
  flow_movement_movements_label: 'Movements',
  flow_movement_viewCanceledReleases_label: 'View Canceled Releases',
  flow_movement_hideCanceledReleases_label: 'Hide Canceled Releases',
  flow_movement_selectAperiodToViewMovements_label: 'Select a Period to View Movements',
  flow_movement_searchByKeywordOrDescription_label: 'Search by Keyword or Description',
  flow_movement_conciliate_label: 'Conciliate',

  creditcard_dashboard_menuTitleCards_label: 'Cards',
  creditcard_dashboard_receivables_label: 'Receivables',
  creditcard_dashboard_creditCardConciliations_label: 'Credit Card Reconciliations',

  bank_action_dashboard_titleBankConciliations_label: 'Bank Reconciliations',
  bank_action_dashboard_titleBankStatements_label: 'Bank Statements',
  bank_action_dashboard_menuTitleBankActions_label: 'Bank Actions',

  accounting_function_dashboard_titleAccountingsFunctions_label: 'Accounting Functions',
  accounting_function_dashboard_titleAccountsPlan_label: 'Chart of Accounts',

  apiCodeMapping_billingUnmapped_toast: 'Unmapped error on the server',
  apiCodeMapping_orbUserUnauthorized_toast: 'Expired or invalid token, please log in to the platform again',
  apiCodeMapping_internalServerError_toast: 'Internal server error',
  apiCodeMapping_orbUserForbidden_toast: "You don't have permission",

  audit_value_label: 'Value',
  audit_serviceName_label: 'Service name',
  audit_paymentMethod_label: 'Payment method',
  audit_date_label: 'Date',
  audit_taxes_label: 'Taxes',
  audit_referenceDate_label: 'Reference date',
  audit_observation_label: 'Observation',
  audit_status_label: 'Status',
  audit_createdAt_label: 'Change date',
  audit_event_label: 'Change type',
  audit_userName_label: 'User name',
  audit_paymentDay_label: 'Payment day',
  audit_enabled_label: 'Status',
  audit_expenseRevenueType_label: 'Type',
  audit_description_label: 'Description',
  audit_bankAccountName_label: 'Bank account',
  audit_expenseName_label: 'Type of expense',
  audit_revenueName_label: 'Type of revenue',
  audit_accountablePlanName_label: 'Accountable plan',
  audit_municipalRegistration_label: 'Municipal registration',
  audit_supplierType_label: 'Supplier type',
  audit_supplierDocument_label: 'Document',
  audit_corporateName_label: 'Corporate name',
  audit_fancyName_label: 'Fancy name',
  audit_address_label: 'Address',
  audit_neighborhood_label: 'Neighborhood',
  audit_state_label: 'State',
  audit_country_label: 'Country',
  audit_zipCode_label: 'Zip code',
  audit_cityName_label: 'City',
  audit_emails_label: 'Emails',
  audit_phoneNumbers_label: 'Phone numbers',
  audit_contactNames_label: 'Contact names',
  audit_bankName_label: 'Bank',
  audit_branch_label: 'Branch',
  audit_accountType_label: 'Account type',
  audit_accountNumber_label: 'Account number',
  audit_emptyData_toast: 'Does not have a change history',
  audit_pixValue_label: 'PIX',
  audit_bankBilletValue_label: 'Bank Billet',
  audit_bankTransferValue_label: 'Bank Transfer',
  audit_creditValue_label: 'Credit Card',
  audit_debitValue_label: 'Debit Card',
  audit_cashValue_label: 'Cash',
  audit_createValue_label: 'Registration',
  audit_updateValue_label: 'Editing',
  audit_expenseValue_label: 'Expense',
  audit_revenueValue_label: 'Revenue',
  audit_savingsAccountValue_label: 'Savings Account',
  audit_checkingAccountValue_label: 'Checking Account',
  audit_scheduledValue_label: 'Scheduled',
  audit_canceledValue_label: 'Canceled',
  audit_payedValue_label: 'Paid',
  audit_receivedValue_label: 'Received',
  audit_refundedValue_label: 'Refunded',
  audit_enabledValue_label: 'Active',
  audit_disabledValue_label: 'Inactive',
  audit_defaultModalTitle_label: 'Change history',
  audit_transferMethod_label: 'Transfer method',
  audit_originAccount_label: 'Origin account',
  audit_destinationAccount_label: 'Destination account',
  audit_invoiceType_label: 'Invoice Type',
  audit_guideInvoice_label: 'Guide Invoice',
  audit_affiliateInvoice_label: 'Affiliate Invoice',
  audit_affiliatePayment_label: 'Affiliate Payment',
  audit_offlineInvoice_label: 'Offline Invoice',
  audit_offlinePayment_label: 'Offline Payment',
  audit_invoicePayment_label: 'Invoice Payment',
  audit_invoice_label: 'Invoice',
  audit_forgottenExpenses_label: 'Expenses',
  audit_forgottenRevenues_label: 'Revenues',
  audit_maxRecurrences_label: 'Recurring payments',
  audit_expenseRevenueName_label: 'Type of tourist trade expense',

  bank_transfer_originAccount_label: 'Origin Account',
  bank_transfer_destinationAccount_label: 'Destination Account',
  bank_transfer_noTransfersFoundBetweenBankAccounts_label: 'No Transfers Found Between Bank Accounts',
  bank_transfer_transferBetweenBankAccount_label: 'Transfer Between Bank Accounts',
  bank_transfer_errorWhenSearchingForTransfersBetweenBankAccounts_toast: 'Error When Searching for Transfers Between Bank Accounts',
  bank_transfer_newTransfer_label: 'New Transfer',
  bank_transfer_editBankTransfer_label: 'Edit Bank Transfer',
  bank_transfer_registerBankTransfer_label: 'Register Bank Transfer',
  bank_transfer_transferBetweenAccountCreatedSuccess_toast: 'Transfer between Accounts created successfully',
  bank_transfer_errorCreatingTransferBetweenAccount_toast: 'Error in creating transfer between accounts',
  bank_transfer_errorUpdatingTransferBetweenAccount_toast: 'Error in updating the transfer between accounts',
  bank_transfer_transferBetweenAccountUpdatedSuccess_toast: 'Transfer between Accounts updated successfully',
  bank_transfer_errorUpdatingAccountTransfer_toast: 'Error in updating the account transfer',
  bank_transfer_transferMethod_label: 'Transfer Method',
  bank_transfer_selectTheMethod_label: 'Select the method',
  bank_transfer_transferDate_label: 'Transfer Date',
  bank_transfer_originAccountRequired_label: 'Origin Account required',
  bank_transfer_destinationAccountRequired_label: 'Destination Account required',
  bank_transfer_transferMethodRequired_label: 'Transfer Method required',
  bank_transfer_transferDateRequired_label: 'Transfer Date required',
  bank_transfer_changeHistoryTransferBetweenAccounts_label: 'Transfer Between Accounts Change History',
  bank_transfer_errorWhenSearchingForTransferHistoryBetweenAccounts_toast: 'Error when Searching for Transfer History Between Accounts',
  bank_transfer_theSourceAccountMustBeDifferentFromTheTargetAccount_label: 'The Source Account Must Be Different From The Target Account',
  bank_transfer_transferBetweenAccounts_label: 'Transfer Between Accounts',
  bank_transfer_transferSentTo_label: 'Transfer sent to',
  bank_transfer_transferReceivedFrom_label: 'Transfer received from',

  expense_revenue_dashboard_accountsToPayAndReceive_label: 'Accounts Payable and Receivable',
  expense_revenue_dashboard_accountsToPay_label: 'Accounts Payable',
  expense_revenue_dashboard_accountsReceivable_label: 'Accounts Receivable',
  expense_revenue_dashboad_typeOfExpenseRevenue_label: 'Type of Expense/Revenue',

  configuration_dashboard_menuTitleConfigurations_label: 'Configurations',
  configuration_pageTitleConfiguration_label: 'Configurations',
  configuration_forgottenExpenses_inputLabel: 'Scheduled accounts payable not paid in the last:',
  configuration_forgottenRevenues_inputLabel: 'Scheduled accounts receivable not received in the last:',
  configuration_maxRecurrences_inputLabel: 'Maximum number of recurring payments up to:',
  configuration_expenseTradeId_inputLabel: 'Type of tourism trade expenses',
  configuration_updatedConfiguration_toast: 'Successful updating company settings',
  configuration_errorWhenSearchingForConfigurations_toast: 'Error when searching for company settings',
  configuration_errorUpdatingConfiguration_toast: 'Error updating company settings',
  configuration_forgottenExpensesExceedsLimit_label: 'Unpaid Accounts Payable limit exceeded',
  configuration_forgottenRevenuesExceedsLimit_label: 'Exceeded limit of Accounts Receivable not received',
  configuration_maxRecurrencesExceedsLimit_label: 'Exceeded limit on maximum number of Recurring Payments',
  configuration_auditButton_label: 'Change history',
  configuration_numberOfDaysMustBeGreaterThanOrEqualTo1_label: 'Number of days must be greater than or equal to 1',
  configuraiton_numberOfDaysMustBeLessThanOrEqualTo31_label: 'Number of days must be less than or equal to 31',

  cash_deposit_errorWhenSearchingForCashDeposits_toast: 'Error when searching for cash deposits',
  cash_deposit_noCashDepositsFound_label: 'No cash deposits were found',
  cash_deposit_cashDeposit_label: 'Cash Deposit',
  cash_deposit_newDeposit_label: 'New Cash Deposit',
  cash_deposit_originAccount_label: 'Origin Account',
  cash_deposit_destinationAccount_label: 'Destination Account',
  cash_deposit_dateFormat_formErrorLabel: 'Date must be in the format dd/mm/yyyy',
  cash_deposit_startDateRequired_formErrorLabel: 'Start date is mandatory',
  cash_deposit_endDateMustBeafterOrEqualStartDate_formErrorLabel: 'End date must be after or equal to start date'
}
