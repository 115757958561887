import React, { useMemo } from 'react'
import { TableV2, TableRow, EditInfoIcon } from '@bonitour/components'
import { BankTransferParsed, BankTransfersCommonParams } from 'Core/Types/BankTransfer'
import { Audit } from 'Shared/components/Audit/Audit'
import { CircleStatus } from 'Shared/styles/global'
import { bankTransfersStatusColors } from '../Constants/bankTransfersStatusColors'
import { tableV2Container } from './BankTransfersList.styles'
import { formatMoney } from '@binamik/js-functions'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankTransfersTranslator } from '../i18n/BankTransfersI18n'
import { useGetBankTransferAudits } from '../hooks/useGetBankTransferAudits'

interface BankTransfersListProps {
  bankTransfers: BankTransferParsed[],
  isLoading: boolean
  filters: BankTransfersCommonParams
  showModalAndGetBankTransferData: (bankTransferId: string) => void
}

export const BankTransfersList = ({
  bankTransfers,
  isLoading,
  filters,
  showModalAndGetBankTransferData
}: BankTransfersListProps) => {
  const i18n = useTranslator(BankTransfersTranslator)

  const columnsWidths = useMemo(
    () => ['6.5rem', '15rem', '15rem', '8rem', '8rem', '10rem', '4rem']
    , []
  )

  const emptyMessage = useMemo(
    () => {
      if (!Object.keys(filters).length) {
        return i18n?.selectPeriodToSearch
      }

      if (Object.keys(filters).length && !bankTransfers?.length) {
        return i18n?.noTransfersFoundBetweenBankAccounts
      }
    }
    , [
      bankTransfers?.length,
      filters,
      i18n?.noTransfersFoundBetweenBankAccounts,
      i18n?.selectPeriodToSearch
    ]
  )

  const {
    getBankTransferAudits,
    getBankTransferAuditsData,
    loadingBankTransferAudits
  } = useGetBankTransferAudits()

  return (
    <TableV2
      isLoading={isLoading}
      labels={i18n?.bankTransfersColumnsTitles}
      columnsWidths={columnsWidths}
      mobileBreakpoint={1024}
      customCss={[tableV2Container]}
      emptyMessage={emptyMessage}
    >
    {
      bankTransfers?.map((bankTransfer) => (
        <TableRow
          key={bankTransfer?.id}
        >
          <>{bankTransfer?.date.format(i18n?.dateFormat)}</>
          <SafeOverflow
            text={bankTransfer?.originBankAccountName}
          />
          <SafeOverflow
            text={bankTransfer?.destinationBankAccountName}
          />
          <>{formatMoney(bankTransfer?.value)}</>
          <CircleStatus
            statusColor={bankTransfersStatusColors[bankTransfer?.status].color}
          >
            {i18n?.bankTransfersStatusTranslator(bankTransfer?.status)}
          </CircleStatus>
          <SafeOverflow
            text={bankTransfer?.userName}
            alignment='right'
            className='safe_overflow__user_name'
          />
          <div className='wrapper__btn_actions'>
            <EditInfoIcon
              onClick={() => showModalAndGetBankTransferData(bankTransfer?.id)}
            />
            <Audit
              documentId={bankTransfer?.id}
              auditDatas={getBankTransferAuditsData}
              auditLoading={loadingBankTransferAudits}
              fetchAuditDatas={getBankTransferAudits}
              modalTitle={i18n?.changeHistoryTransferBetweenAccounts}
              i18n={i18n?.bankTransferAuditLabels}
            />
          </div>
        </TableRow>
      ))
    }
  </TableV2>
  )
}
