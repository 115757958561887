
export const notConciliationsGatewayPaymentsColumnsWidths = ['5.90rem', '7.5rem', '7.5rem', '8.5rem']

export const notConciliationsAccountForecastsColumnsWidths = ['5.90rem', '7.5rem', '6rem', '7rem', '8.5rem']

export const partialConciliationsAccountForecastsColumnsWidths = ['5.90rem', '8rem', '5.4rem', '5.5rem', '8.5rem']

export const partialConciliationsGatewayPaymentsColumnsWidths = ['5.90rem', '8rem', '5.5rem', '8.5rem']

export const conciliationsAccountForecastsColumnsWidths = ['5.90rem', '8rem', '8.3rem', '7rem', '8.5rem']

export const conciliationsGatewayPaymentsColumnsWidths = ['5.90rem', '8rem', '7rem', '8.5rem']
