import { useFeature } from '@optimizely/react-sdk'
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useCompany } from 'Shared/contexts/Company'

export const useFlaggedRoute = (flag, redirectTo = '/') => {
  const { company } = useCompany()
  const { push } = useHistory()

  const [isEnabled, variables, isReady] = useFeature(flag, { autoUpdate: true })

  const isUserAllowed = useMemo(
    () => isEnabled && variables?.allowedCompanies?.companies?.includes(company?.id)
    ,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company?.id, isEnabled, variables?.allowedCompanies?.companies]
  )

  useEffect(() => {
    if (isReady && !isUserAllowed) {
      push(redirectTo)
    }
  }, [isReady, isUserAllowed, push, redirectTo])
}
