/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors, Card } from '@bonitour/components'

const cardButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  min-width: 98px;
  height: 100%;
  padding: 10px;
  min-height: 98px;
  color: ${colors.gray4};
  box-shadow: 0px 0px 6px #0000001a;
  cursor: pointer;

  label {
    width: 120px;
    text-align: center;
    line-height: 1.3;
  }
`

export const CardButton = props => <Card css={cardButton} {...props} />
