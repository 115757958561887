import React from 'react'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { Button } from '@bonitour/components'
import { CashDepositFiltersContainer } from './CashDepositFilters.styles'
import { useCashDepositFilters } from '../hooks/inputs/useCashDepositFilters'
import { CashDepositsCommonParams } from 'Core/Types/CashDeposit'

interface CashDepositFiltersProps {
  isLoading: boolean
  handleFiltersChange: (filters: CashDepositsCommonParams) => void
  filters: CashDepositsCommonParams
  buttonLabel: string
}

export const CashDepositFilters = ({
  isLoading,
  handleFiltersChange,
  filters,
  buttonLabel
}: CashDepositFiltersProps): React.ReactElement => {
  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useCashDepositFilters(
    filters
  )

  return (
    <CashDepositFiltersContainer>
      <FormsInputs
        inputs={inputsValid}
        formValues={form}
        formErrors={errors}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      >
        <Button
          type='button'
          disabled={!!isLoading}
          onClick={onSubmit(handleFiltersChange)}
        >
          {buttonLabel}
        </Button>
      </FormsInputs>

    </CashDepositFiltersContainer>
  )
}
