import { useState, useCallback, useEffect } from 'react'
import { ExpenseService } from 'Core/Service/expense'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesHooksTranslator } from '../i18n/ExpensesHooksI18n'

export const useExpensesForgotten = () => {
  const [data, setData] = useState({
    forgotten: 0,
    daysAgo: 0
  })
  const { addToast } = useToast()

  const i18n = useTranslator(ExpensesHooksTranslator)

  const fetchExpensesForgotten = useCallback(() => {
    return ExpenseService.getExpensesForgotten()
      .then(setData)
      .catch((errors) => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorLoadingForgottenAccountsPayable
          }
        )
      })
  }, [addToast, i18n?.errorLoadingForgottenAccountsPayable])

  useEffect(() => {
    fetchExpensesForgotten()
  }, [fetchExpensesForgotten])

  return {
    data,
    setData
  }
}
