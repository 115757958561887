import { LIENCREDIT, LIENDEBIT, POSMONTHLYFEE } from './flags'

export const cardFlagOptions = [
  '',
  'mastercard',
  'visa',
  'amex',
  'elo',
  'hipercard',
  'diners',
  'discover',
  LIENCREDIT,
  LIENDEBIT,
  POSMONTHLYFEE
]
