import React, { useCallback } from 'react'
import { LoadingAnimation, Modal } from '@bonitour/components'
import { loadingAnimation } from 'Shared/styles/global'
import { BatchExpenseUpdateForm } from '../BatchExpenseUpdateForm/BatchExpenseUpdateForm'
import { useUpdateBatchExpense } from '../hooks/useUpdateBatchExpense'
import { batchExpenseUpdateModalContainer } from './BatchExpenseUpdateModal.styles'
import { ExpenseParsed, UpdateBatchExpenseReqBody } from 'Core/Types/Expense'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesTranslator } from '../i18n/ExpensesI18n'

export interface BatchExpenseUpdateModalProps {
  toggleBatchUpdateModal: () => void
  showBatchUpdateModal: boolean
  selectedExpenses: ExpenseParsed[]
  fetchExpenses: () => void
  setExpenseToUpdate: React.Dispatch<React.SetStateAction<ExpenseParsed[]>>
}

export const BatchExpenseUpdateModal = (
  {
    toggleBatchUpdateModal,
    showBatchUpdateModal,
    selectedExpenses,
    fetchExpenses,
    setExpenseToUpdate
  }: BatchExpenseUpdateModalProps
) => {
  const {
    onUpdateBatchExpenses,
    loading: loadingUpdateBatchExpenses
  } = useUpdateBatchExpense()

  const handleOnCloseBatchUpdateModal = useCallback(
    () => {
      !loadingUpdateBatchExpenses && toggleBatchUpdateModal()
    }
    , [loadingUpdateBatchExpenses, toggleBatchUpdateModal]
  )

  const handleBatchExpenseUpdate = useCallback(
    async (batchExpenseUpdateData: UpdateBatchExpenseReqBody) => {
      const updated = await onUpdateBatchExpenses(batchExpenseUpdateData)
      if (updated) {
        fetchExpenses()
        setExpenseToUpdate([])
        toggleBatchUpdateModal()
      }
    }
    , [
      fetchExpenses,
      onUpdateBatchExpenses,
      setExpenseToUpdate,
      toggleBatchUpdateModal
    ]
  )

  const i18n = useTranslator(ExpensesTranslator)

  return (
    <Modal
      title={i18n?.editPayablesInBatch}
      isVisible={showBatchUpdateModal}
      onCloseClick={handleOnCloseBatchUpdateModal}
      customCss={[batchExpenseUpdateModalContainer]}
    >
      <LoadingAnimation
        customCss={[loadingAnimation]}
        className={
          `${loadingUpdateBatchExpenses ? '' : 'batch_expense_update_hidden'}
          batch_expense_update_load`
        }
      />
      <BatchExpenseUpdateForm
        onClose={toggleBatchUpdateModal}
        handleBatchExpenseUpdate={handleBatchExpenseUpdate}
        selectedExpenses={selectedExpenses}
        loadingUpdateBatchExpenses={loadingUpdateBatchExpenses}
      />
    </Modal>
  )
}
