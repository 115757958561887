/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, ConfirmDialog, PaginationContainer, PlusIcon } from '@bonitour/components'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useHistory } from 'react-router-dom'
import { useListSuppliers } from './hooks/useListSuppliers'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SuppliersTranslator } from './i18n/SuppliersI18n'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'
import { CardButton } from 'Shared/components/CardButton'
import { SupplierFilter } from './Filter/SupplierFilter'
import { cardIcon } from 'Shared/styles/global'
import { cardButtonStyle, labelStyle, removeDialog } from './Supplier.styles'
import { SuppliersList } from './List/SuppliersList'
import { SupplierModal } from './Form/SupplierModal'
import { useCallback, useState } from 'react'
import { useCreateSupplier } from './hooks/useCreateSupplier'
import { useEditSupplier } from './hooks/useEditSuppliers'
import { useRemoveSupplier } from './hooks/useRemoveSupplier'

export const Suppliers = () => {
  const onReturnToDashboard = () => push('/expense-revenue-dashboard')
  useFlaggedRoute('billing-suppliers', '/expense-revenue-dashboard')
  const [isVisibleModal, setVisibilityModal] = useState(false)
  const [supplierIdToRemove, setSupplierIdToRemove] = useState(null)

  const {
    suppliers,
    meta,
    listLoading,
    perPageOptions,
    handlePagination,
    pagination,
    handleFilters,
    fetchListSupplier,
    filters
  } = useListSuppliers()

  const { push } = useHistory()
  const { count = 0, last = 1 } = meta ?? {}
  const i18n = useTranslator(SuppliersTranslator)
  const {
    onEditSupplier,
    fetchSupplier,
    supplier,
    editLoading,
    setSupplier
  } = useEditSupplier(fetchListSupplier)

  const toggleModal = useCallback(() => {
    setVisibilityModal(!isVisibleModal)
    setSupplier(null)
  }, [isVisibleModal, setSupplier])

  const { onCreateSupplier, createLoading } = useCreateSupplier(fetchListSupplier)

  const onEdit = useCallback((id) => {
    fetchSupplier(id)
    toggleModal()
  }, [fetchSupplier, toggleModal])

  const { onRemoveSupplier } = useRemoveSupplier(fetchListSupplier)

  const closeModal = useCallback(() => {
    setSupplierIdToRemove(null)
  }, [])

  const onDialogRemoveSupplier = useCallback((id) => {
    setSupplierIdToRemove(id)
  }, [])

  const onValidationRemoveSuccess = useCallback((id) => {
    onRemoveSupplier(id)
    closeModal()
  }, [onRemoveSupplier, closeModal])

  return (
    <>
      {isVisibleModal &&
        <SupplierModal
          toggleHidden={toggleModal}
          isVisible={isVisibleModal}
          onCreateSupplier={onCreateSupplier}
          onEditSupplier={onEditSupplier}
          loading={createLoading || editLoading}
          supplierData={supplier}
          i18n={i18n}
        />}
      <ConfirmDialog
        title={i18n.removeDialogTitle}
        isVisible={Boolean(supplierIdToRemove)}
        message={i18n.removeDialogMessage}
        successCallback={() => onValidationRemoveSuccess(supplierIdToRemove)}
        cancelCallback={closeModal}
        customCss={[removeDialog]}
      />
      <PageTitle
        title={i18n.pageTitleSupplier}
        onClickReturn={onReturnToDashboard}
      />
      <CardButton onClick={toggleModal} css={cardButtonStyle}>
        <PlusIcon css={cardIcon} />
        <span css={labelStyle}>{i18n.newSupplierButton}</span>
      </CardButton>
      <Card>
        <SupplierFilter
          onFilterSubmit={handleFilters}
          i18={i18n}
          filters={filters}
          buttonLabel={i18n.listButton}
          enabledOptions={i18n.selectSupplierStatusOptions}
        />
        <PaginationContainer
          total={count}
          pagesQuantity={last}
          onPagination={handlePagination}
          currentPage={pagination.currentPage}
          pageLimitDefault={pagination.perPage}
          pageLimitOptions={perPageOptions}
          isOpenDrawer
        >
          <SuppliersList
            suppliers={suppliers}
            isLoading={listLoading}
            i18n={i18n}
            onEdit={onEdit}
            onDialogRemoveSupplier={onDialogRemoveSupplier}
          />
        </PaginationContainer>
      </Card>
    </>
  )
}
