/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo, useState, useCallback, useEffect } from 'react'
import {
  BankStatementImportContainer,
  BankStatmentInfoHeaderContainer,
  BtnDrawer,
  ImportReviewContainer,
  LatestImportedBankStatementsContainer
} from './BankStatementImport.styles'
import {
  AngleUpIcon,
  Button,
  CheckIcon,
  InfoIcon,
  LoadingAnimation,
  WarningIcon
} from '@bonitour/components'
import { useUploadBankStatementsFileImport } from '../hooks/useUploadBankStatementsFileImport'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankStatementsTranslator } from '../i18n/BankStatementsI18n'
import { useGetImportedBankStatements } from '../hooks/useGetImportedBankStatements'
import { useUploadBankStatementsImport } from '../hooks/useUploadBankStatementsImport'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import {
  bankAccount,
  endDate,
  getSchema,
  startDate,
  file
} from './__BankStatementBaseInputs'
import { useForm } from '@bonitour/app-functions'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { BankStatementsInputsTranslator } from '../i18n/BankStatementsInputsi18n'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'
import { STONE_BANK_NUMBER } from '../constants/bankStatements'

const BASE_FORM_VAL = {}

export const BankStatementImport = (
  {
    bankAccountsOptions,
    setSelectedBankAccountId,
    selectedBankAccount
  }
) => {
  const [isOpenSections, setIsOpenSections] = useState(true)
  const toggleSections = useCallback(() => setIsOpenSections(isOpen => !isOpen), [])

  const {
    onUploadBankStatementsFile,
    isLoadUpload,
    bankStatementsFileImportRes,
    setBankStatementsFileImportRes
  } = useUploadBankStatementsFileImport(setSelectedBankAccountId)

  const {
    isLoadUploadBankStatements,
    bankStatementsImportRes,
    setBankStatementsImportRes,
    onUploadBankStatements
  } = useUploadBankStatementsImport()

  const {
    imported,
    isLoadingImported,
    onGetImportedBankStatements
  } = useGetImportedBankStatements()

  const inputs = useTranslatedInputs(
    [
      bankAccount(bankAccountsOptions),
      file,
      startDate,
      endDate
    ], BankStatementsInputsTranslator
  )

  const schema = useMemo(
    () => getSchema(inputs),
    [inputs]
  )

  const {
    form,
    errors,
    clearErrors,
    onSubmit,
    setForm,
    utils: { onInputChange, onInputBlur }
  } = useForm(BASE_FORM_VAL, schema)

  const i18n = useTranslator(BankStatementsTranslator)

  const translatedFormErrors = useTranslatedFormErrors(
    errors, BankStatementsInputsTranslator
  )

  useEffect(
    () => {
      const isStoneAccount = bankAccountsOptions?.filter(
        ({ bankNumber }) => bankNumber === STONE_BANK_NUMBER
      ).some(
        ({ value }) => value === form?.bankAccountId
      )

      if (form?.isStoneAccount !== isStoneAccount) {
        onInputChange('isStoneAccount')(isStoneAccount)
      }
    }
    , [bankAccountsOptions, form?.bankAccountId, form?.isStoneAccount, onInputChange]
  )

  const newInputsValid = useMemo(
    () => inputs
      .filter(({ value }) => {
        switch (value) {
        case 'startDate':
        case 'endDate':
          return form?.isStoneAccount
        case 'file':
          return !form?.isStoneAccount
        default:
          return true
        }
      }),
    [inputs, form?.isStoneAccount]
  )

  const handleChangeParams = useCallback((field) => (value) => {
    if (field === 'bankAccountId' && value) {
      setSelectedBankAccountId(value)
      onGetImportedBankStatements(value)
      setBankStatementsFileImportRes({})
      setBankStatementsImportRes({})
      clearErrors('startDate')
      clearErrors('endDate')
      clearErrors('file')
    }

    if (field === 'file' && value?.length) {
      clearErrors('file')
    }

    onInputChange(field)(value)
  }, [
    onInputChange,
    setSelectedBankAccountId,
    onGetImportedBankStatements,
    setBankStatementsFileImportRes,
    setBankStatementsImportRes,
    clearErrors
  ])

  const handleImport = useCallback((params) => {
    if (form?.isStoneAccount) {
      onUploadBankStatements(params).then(isSuccess => {
        if (isSuccess) {
          setForm({})
        }
      })
    } else {
      onUploadBankStatementsFile(params).then(isSuccess => {
        if (isSuccess) {
          setForm({})
        }
      })
    }
  }, [
    form?.isStoneAccount,
    onUploadBankStatements,
    onUploadBankStatementsFile,
    setForm
  ])

  const parsedBankStatementsData = useMemo(() => {
    return Object.keys(bankStatementsFileImportRes).length
      ? bankStatementsFileImportRes
      : bankStatementsImportRes
  }, [bankStatementsFileImportRes, bankStatementsImportRes])

  const handleInputBlur = useCallback((field) => (value) => {
    if (['startDate', 'endDate'].includes(field)) {
      if (form?.startDate) {
        onInputBlur('startDate')(value)
      }

      if (form?.endDate) {
        onInputBlur('endDate')(value)
      }
      return
    }
    onInputBlur(field)(value)
  }, [
    form?.endDate,
    form?.startDate,
    onInputBlur
  ])

  return (
    <BankStatementImportContainer>
      <ButtonDrawerTitle
        title={i18n?.importingBankStatements}
        toggle={toggleSections}
        isOpen={isOpenSections}
      />
      {isOpenSections && (
        <>
          <FormsInputs
            inputs={newInputsValid}
            formValues={form}
            formErrors={translatedFormErrors}
            onInputChange={handleChangeParams}
            onInputBlur={handleInputBlur}
          >
            {!form?.isStoneAccount
              ? <div className='upload_formats  section__upload_child'>
                {i18n?.acceptedFileFormats} {selectedBankAccount}
              </div>
              : null
            }
          </FormsInputs>
          <Button
            type='button'
            className='btn__upload'
            disabled={false}
            onClick={onSubmit(handleImport)}
          >
            {i18n?.performImport}
          </Button>
          <section className='wrapper_infos'>
            <ImportReview
              bankStatementsImportRes={parsedBankStatementsData}
              isLoadUpload={isLoadUpload || isLoadUploadBankStatements}
              i18n={i18n}
            />
            <LatestImportedBankStatements
              imported={imported}
              isLoadingImported={isLoadingImported}
              i18n={i18n}
            />
          </section>
        </>
      )}
    </BankStatementImportContainer>
  )
}

const ImportReview = ({
  bankStatementsImportRes,
  isLoadUpload,
  i18n
}) => {
  const [isOpenDetails, setIsOpenDetails] = useState(false)
  const toggleDetails = useCallback(() => setIsOpenDetails(isOpen => !isOpen), [])

  const {
    ignoredCurrentDateTransactions,
    importedTransactions,
    transactions,
    details
  } = bankStatementsImportRes

  const hasBeenUploaded = useMemo(() => {
    if (
      importedTransactions &&
      transactions &&
      !(details?.length || ignoredCurrentDateTransactions)
    ) {
      return true
    }

    if (details?.length || ignoredCurrentDateTransactions) {
      return false
    }

    return false
  }, [
    details?.length,
    ignoredCurrentDateTransactions,
    importedTransactions,
    transactions
  ])

  const hasImportResponse = useMemo(() => {
    return (
      bankStatementsImportRes?.importedTransactions ||
      bankStatementsImportRes?.transactions ||
      bankStatementsImportRes?.ignoredCurrentDateTransactions ||
      (
        bankStatementsImportRes?.details &&
        bankStatementsImportRes?.details?.length !== 0
      )
    )
  }, [bankStatementsImportRes])

  const title = useMemo(() => {
    if (importedTransactions || transactions || details?.length || ignoredCurrentDateTransactions) {
      return i18n?.statementImportCompleted
    }

    return i18n?.waitingBankStatementImported
  }, [
    details?.length,
    ignoredCurrentDateTransactions,
    importedTransactions,
    transactions,
    i18n?.statementImportCompleted,
    i18n?.waitingBankStatementImported
  ])

  return (
    <ImportReviewContainer
      hasBeenUploaded={hasBeenUploaded}
      isLoadUpload={isLoadUpload}
    >
      {
        isLoadUpload ? (
          <LoadingAnimation className='load' size={30} />
        ) : (
          <>
            <BankStatmentInfoHeader
              icon={hasBeenUploaded ? <CheckIcon /> : <WarningIcon />}
              title={title}
              newBackgroundColor={hasBeenUploaded ? '--clr-green3' : '--clr-yellow2'}
              newColor={hasBeenUploaded ? '--clr-green6' : '--clr-yellow2'}
            />
            {hasImportResponse && (
              <div className='import_review_infos'>
                {i18n?.importedReleases(bankStatementsImportRes?.importedTransactions)}
                {i18n?.transactionsReleases(bankStatementsImportRes?.transactions)}
                {i18n?.ignoredCurrentDateTransactionsReleases(bankStatementsImportRes?.ignoredCurrentDateTransactions)}
                {bankStatementsImportRes?.details?.length ? (
                  <div className='import_review_details'>
                    <ButtonDrawerTitle
                      title={i18n?.details}
                      toggle={toggleDetails}
                      isOpen={isOpenDetails}
                      btnDrawerSpanClassName='import_review_details_title'
                    />
                    {isOpenDetails && (
                      <div className='import_review_details_wrapper'>
                        {bankStatementsImportRes?.details?.map(
                          ({ date, alreadyImported, imported, notImported }, idx) => (
                            <div
                              className='import_review_details_content'
                              key={idx}
                            >
                              <p className='import_review_details_subtitle'>
                                {i18n?.dateLabel(date)}
                              </p>

                              {i18n?.alreadyImportedReleases(alreadyImported)}
                              {i18n?.importedReleases(imported)}
                              {i18n?.notImportedReleases(notImported)}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            )}
          </>
        )
      }
    </ImportReviewContainer>
  )
}

const ButtonDrawerTitle = (
  {
    title,
    toggle,
    isOpen,
    btnDrawerSpanClassName
  }
) => {
  return (
    <BtnDrawer
      onClick={toggle}
      isOpen={isOpen}
    >
      <AngleUpIcon />
      <span className={`btn_drawer_title ${btnDrawerSpanClassName || ''}`}>
        {title}
      </span>
    </BtnDrawer>
  )
}

const LatestImportedBankStatements = (
  {
    imported,
    isLoadingImported,
    i18n
  }
) => {
  return (
    <LatestImportedBankStatementsContainer
      isLoadingImported={isLoadingImported}
    >
      {isLoadingImported ? (
        <LoadingAnimation className='load' size={30} />
      ) : (
        <>
          <BankStatmentInfoHeader
            icon={<InfoIcon />}
            title={i18n?.latestImportedExtracts}
          />
          {imported?.length !== 0 &&
            <div className='latest_imported_bank_statements__wrapper'>
              {imported?.map((item, index) => (
                <div key={index}>
                  <span>{item.date}: </span>
                  <span>{item.qtd}</span>
                </div>
              ))}
            </div>
          }
        </>
      )}
    </LatestImportedBankStatementsContainer>
  )
}

const BankStatmentInfoHeader = (
  {
    icon,
    title,
    newBackgroundColor,
    newColor
  }
) => {
  return (
    <BankStatmentInfoHeaderContainer
      newBackgroundColor={newBackgroundColor}
      newColor={newColor}
    >
      <span className='bank_statement_info_header_circle'>
        {icon}
      </span>
      <span className='bank_statement_info_header_title'>
        {title}
      </span>
    </BankStatmentInfoHeaderContainer>
  )
}
