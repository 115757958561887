import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'
import { css } from '@emotion/core'

export const BatchExpenseUpdateInputsContainer = styled.div`
  .form__inputs {
    display: grid;
    align-items: end;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 1.25rem;
    border-radius: 0.625rem;
    margin-bottom: 1.875rem;

    .input__origin_bank_account, 
    .input__destination_bank_account {
      .select_options__content {
        max-height: 12rem;
      }
    }

    .input__value {
      border: 1px solid var(--clr-gray8);
    }

    .input__date, 
    .input__created_at {
      width: 100%;
    }

    .input_container__observation {
      grid-column: span 2;
    }
  }

  * {
    box-sizing: border-box;
  }

  .wrapper__btns {
    display: flex;
    gap: 1.25rem;
    margin-top: 3.125rem;
  }

  .values_card__container {
    display: flex;
    margin-bottom: 1.875rem;

    .values_card__wrapper {
      width: 14.375rem;
    }
  }

  .input_container__receipts {
    grid-column: span 3;
  }


  @media(max-width: ${BREAK_POINTS.tablet}) {
    .form__inputs {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`

export const expenseUpdateFormTableV2 = css`
  .TableRow {
    .TableCell {
      overflow: initial;

      &.column-0, &.column-1 {
        max-width: 28rem;
      }
    }
    color: var(--clr-gray3);
  }

  .empty_result {
    color: var(--clr-gray3);
    font-size: 1.125rem;
  }
`
