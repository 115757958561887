/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

export const identity = (value) => value
export const noop = () => undefined
export const randomId = () => Math.random().toString(16).slice(2)
export const preventPropagation = (
  event
) => {
  event.stopPropagation()
  return event
}
