import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ReceivablesHooksTranslator } from '../i18n/ReceivablesHooksI18n'
import { ReceivablesService } from 'Core/Service/receivables'

export const useImportReceivables = () => {
  const { addToast } = useToast()
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [importedData, setImportedData] = useState()
  const [gateway, setGateway] = useState()
  const [file, setFile] = useState()

  const handleUploadFile = useCallback((newParam) => {
    setFile(
      newParam
    )
  }, [])

  const i18n = useTranslator(ReceivablesHooksTranslator)

  const fetchImportReceivables = () => {
    if (gateway === 'cielo') {
      importCieloReceivables()
    } else if (gateway === 'braspag') {
      importBraspagReceivables()
    }
  }

  const importCieloReceivables = () => {
    setLoading(true)
    return ReceivablesService.importFile({ file }).then((data) => {
      setImportedData(data)
    }).catch(errors => {
      console.error(errors?.data)
      addToast({
        dictTranslation: i18n?.apiCodesMappingForReceivables,
        msgs: errors?.data?.errors_msg
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const importBraspagReceivables = () => {
    setLoading(true)
    if (!(startDate && endDate)) {
      return addToast({
        defaultMessage: i18n?.selectPeriodToImport,
        typeToast: 'warning'
      })
    }
    return ReceivablesService.import({ startDate, endDate }).then((data) => {
      setImportedData(data)
    }).catch(errors => {
      console.error(errors?.data)
      addToast({
        dictTranslation: i18n?.apiCodesMappingForReceivables,
        msgs: errors?.data?.errors_msg
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  return {
    fetchImportReceivables,
    importedData,
    setImportedData,
    loading,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    gateway,
    setGateway,
    file,
    handleUploadFile
  }
}
