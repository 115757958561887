import { useCallback, useEffect, useRef, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsService } from 'Core/Service/eventsProcessors'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'

export const useListEventsProcessors = () => {
  const { addToast } = useToast()
  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [eventsProcessorsDatas, setEventsProcessorsDatas] = useState({
    events_processors: [],
    meta: {}
  })

  const { initStatePagination, perPageOptions } = usePaginationOptions()

  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false)

  const initPagination = useRef(false)

  const [pagination, setPagination] = useState(initStatePagination)

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const fetchEventsProcessors = useCallback(() => {
    setIsLoading(true)
    return EventsProcessorsService.getEventsProcessors(filters, pagination).then(
      (data) => {
        setEventsProcessorsDatas(data)
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorFetchingEventsProcessors
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }, [addToast, filters, i18n?.errorFetchingEventsProcessors, pagination])

  const handleFilters = useCallback((filters) => {
    setIsUpdatingFilters(true)
    setPagination({
      currentPage: 1,
      perPage: pagination.perPage
    })
    setFilters(filters)
    setTimeout(() => setIsUpdatingFilters(false))
  }, [pagination.perPage])

  const handlePagination = useCallback((currentPage, perPage) => {
    if (!initPagination.current) {
      initPagination.current = true
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [])

  useEffect(() => {
    !isUpdatingFilters && fetchEventsProcessors()
  }, [fetchEventsProcessors, isUpdatingFilters])

  return {
    eventsProcessorsDatas: eventsProcessorsDatas?.events_processors,
    meta: eventsProcessorsDatas?.meta,
    isLoading,
    handleFilters,
    handlePagination,
    perPageOptions,
    filters,
    fetchEventsProcessors,
    pagination
  }
}
