import styled from '@emotion/styled'
import { iconButton } from 'Shared/styles/global'

export const DownloadContainer = styled.div`
  .b-font-arrow-down {
   ${iconButton}
    border-bottom: 2px solid var(--clr-gray3);
  }
`

export const DownloadImg = styled.img`
  border-radius: 0.625rem;
  object-fit: cover;
  height: 100%;
  width: 100%;
`

interface DownloadImgContainerProps {
  numberColumn: number;
}

export const DonwloadImgContainer = styled.div<DownloadImgContainerProps>`
  display: grid;
  grid-template-columns: repeat(${({ numberColumn }) => numberColumn > 1 ? 3 : 1}, 12.5rem);
  justify-content: center;
  gap: 2.1875rem;
  padding: 0.625rem 0;
  height: 100%;
  min-height: 12.5rem;
  overflow-y: auto;
`

export const DownloadBtn = styled.button`
  margin-top: 1.25rem;
  padding: 0.625rem;
  color: var(--clr-white);
  font-weight: 700;
  border-radius: var(--round-element-radius, 1.25rem);
  background-color: var(--clr-primary);
`

export const DownloadImgContent = styled.div`
  background-color: var(--clr-gray8);
  border-radius: 0.625rem;
  width: 12.5rem;
  height: 150px;
  position: relative;
  margin: 0.625rem 0;

  .name__img {
    padding: 0.625rem 0;
    color: var(--clr-gray3);
    font-size: 0.875rem;
    font-weight: 600;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;


  .icon {
    cursor: pointer;
    font-size: 1.125rem;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: var(--clr-gray3);
  }

  .circle__icons {
    background-color: white;
    border-radius: 100vw;
    height: 1.875rem;
    width: 1.875rem;
    padding: 0.625rem;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon__arrow_down {
    border-bottom: 2px solid var(--clr-gray3);
  }

`
