import { useCallback, useState } from 'react'
import { RevenueService } from 'Core/Service/revenue'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { RevenuesHooksTranslator } from '../i18n/RevenuesHooksI18n'

export const useListRevenueAudits = () => {
  const [datas, setDatas] = useState([])
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(RevenuesHooksTranslator)

  const fetchListRevenueAudits = useCallback((revenueId) => {
    if (!revenueId) return
    setLoading(true)
    return RevenueService.getRevenueAudits(revenueId)
      .then(setDatas)
      .catch((errors) => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorLoadingAccountsReceivableHistory
          }
        )
      }).finally(() => {
        setLoading(false)
      })
  }, [addToast, i18n?.errorLoadingAccountsReceivableHistory])

  return {
    fetchListRevenueAudits,
    datas,
    loading
  }
}
