import React from 'react'
import { Tooltip } from 'Shared/components/Tooltip'
import { ReactComponent as CircleInfoIcon } from 'Shared/svgs/circleInfoIcon.svg'
import { useTranslatedPaymentMethods } from 'Shared/language/hooks/useTranslatedPaymentMethods'

interface ExpensesInputsTranslatorRes {
  inputs: {
    startDate: {
      label: string
      placeholder: string
    },
    endDate: {
      label: string
      placeholder: string
    },
    status: {
      label: string
      placeholder: string
    },
    supplierId: {
      label: string
      placeholder: string
    },
    serviceName: {
      label: string
      placeholder: string
    },
    receipts: {
      label: string | ((props: { isEdit: boolean }) => React.ReactElement)
    }
  }
  errors: {
    startDate: {
      dateFormat: string
      dateRequired: string
      startDateMustBeLessThanOrEqualEndDate: string
    },
    endDate: {
      dateFormat: string
      dateRequired: string
      endDateMustBeAfterOrEqualStartDate: string
    }
  },
  translatedOptions: {
    paymentMethods: { value: string, label: string }[]
  }
}

const InfoTooltip = ({
  title,
  info
}) => {
  return (
    <>
      <span>{title}</span>
      <div className='circle__info'>
        <CircleInfoIcon />
        <Tooltip
          verticalPosition='top'
          horizontalPosition='center'
          tooltipClass='tooltip__conciliated'
          width="auto"
        >
          {info}
        </Tooltip>
      </div>
    </>
  )
}

export const ExpensesInputsTranslator = (
  dictionary
): ExpensesInputsTranslatorRes => {
  const labels = {
    startDate: {
      label: dictionary?.conciliation_startDate_inputLabel,
      placeholder: dictionary?.conciliation_startDate_placeholderLabel
    },
    endDate: {
      label: dictionary?.conciliation_endDate_inputLabel,
      placeholder: dictionary?.conciliation_endDate_placeholderLabel
    },
    status: {
      label: dictionary?.common_status_label,
      placeholder: dictionary?.common_selectStatuses_label
    },
    supplierId: {
      label: dictionary?.expense_supplier_label,
      placeholder: dictionary?.expense_supplier_label
    },
    serviceName: {
      label: dictionary?.expense_productAndService_label,
      placeholder: dictionary?.expense_productAndService_label
    },
    expenseRevenueId: {
      label: dictionary?.expense_typeOfExpense_label,
      placeholder: dictionary?.expense_typeOfExpense_label
    },
    date: {
      label: dictionary?.common_date_label,
      placeholder: dictionary?.common_date_label
    },
    referenceDate: {
      label: dictionary?.common_referenceDate_label,
      placeholder: dictionary?.common_referenceDate_label
    },
    bankAccountId: {
      label: dictionary?.common_bankAccount_label,
      placeholder: dictionary?.common_bankAccount_label
    },
    paymentMethod: {
      label: dictionary?.common_formOfPayment_label,
      placeholder: dictionary?.common_formOfPayment_label
    },
    paymentMethods: {
      label: dictionary?.common_formOfPayment_label,
      placeholder: dictionary?.common_formOfPayment_label
    },
    receipts: {
      label: dictionary?.expense_addOrRemoveReceipts_label
    }
  }

  const { translatedPaymentMethodsOptions } = useTranslatedPaymentMethods({ dictionary })

  const errors = {
    startDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_startDateRequired_formErrorLabel,
      startDateMustBeLessThanOrEqualEndDate: dictionary?.common_startDateMustBeLessThanOrEqualEndDate_label
    },
    endDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_endDateRequired_formErrorLabel,
      endDateMustBeAfterOrEqualStartDate: dictionary?.conciliation_endDateMustBeAfterOrEqualStartDate_formErrorLabel
    },
    paymentDay: {
      paymentDayRequired: dictionary?.expense_mandatoryPaymentDayOfTheMonth_label
    },
    recurrenceType: {
      recurrenceTypeRequired: dictionary?.expense_mandatoryRecurrenceType_label
    },
    applyRecurrence: {
      applyRecurrenceRequired: dictionary?.expense_replicateMandatoryChange_label
    },
    ocurrences: {
      mustBeNumber: dictionary?.common_itMustBeNumber_label,
      mustBeGreaterThanOrEqual2: dictionary?.common_numberOfRepetitionsMustBeGreaterThanOrEqualTo2_label,
      mustBeLessThanOrEqual240: dictionary?.common_numberOfRepetitionsMustBeLessThanOrEqual240_label
    }
  }

  const translatedOptions = {
    paymentMethod: translatedPaymentMethodsOptions({}),
    paymentMethods: translatedPaymentMethodsOptions({})
  }

  return {
    inputs: labels,
    errors,
    translatedOptions
  }
}

export const ExpensesRecurrencyInputsTranslator = (
  dictionary
): ExpensesInputsTranslatorRes => {
  return {
    inputs: {
      ...ExpensesInputsTranslator(dictionary).inputs,
      receipts: {
        label: ({ isEdit }) => {
          return InfoTooltip(
            {
              title: dictionary?.expense_addOrRemoveReceipts_label,
              info: isEdit
                ? dictionary?.expense_attachmentWilNotBeAppliedToOtherInstallmentsRecurrences_label
                : dictionary?.expense_attachmentWillAppearInAllInstallmentsRecurrences_label
            }
          )
        }
      }
    },
    errors: ExpensesInputsTranslator(dictionary).errors,
    translatedOptions: ExpensesInputsTranslator(dictionary).translatedOptions
  }
}

export const BatchExpenseInputsTranslator = (
  dictionary
): ExpensesInputsTranslatorRes => {
  return {
    inputs: {
      ...ExpensesInputsTranslator(dictionary).inputs,
      receipts: {
        label: InfoTooltip(
          {
            title: dictionary?.expense_addReceipts_label,
            info: dictionary?.expense_attachmentWillAppearOnAllTheAccountsInTheBatch_label
          }
        )

      }
    },
    errors: ExpensesInputsTranslator(dictionary).errors,
    translatedOptions: ExpensesInputsTranslator(dictionary).translatedOptions
  }
}
