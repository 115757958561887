/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  AutomationRulesContainer as Container
} from './AutomationRules.styles'
import {
  EditInfoIcon,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TrashIcon
} from '@bonitour/components'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { wrapperButtons } from 'Shared/styles/global'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsI18n'
import { apiCodesTranslations } from 'Shared/language/utils/functions'
import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { automationRulesTableColumns } from 'Domains/EventsProcessors/Constants/EventsProcessors'

/**
 * @typedef AutomationRulesProps
 * @prop { (id: string) => void } onRemoveAutomationRule
 * @prop { boolean } loadingAutomationRuleRemove
 * @prop { boolean } loadingAutomationRules
 * @prop { Record<string, string> } automationRulesData
 * @prop { string } selectedEventId
 * @prop { string } eventProcessorId
 */

/** @type { React.FC<AutomationRulesProps> } */
export const AutomationRules = ({
  onRemoveAutomationRule,
  loadingAutomationRuleRemove,
  loadingAutomationRules,
  automationRulesData,
  selectedEventId,
  eventProcessorId
}) => {
  const i18n = useTranslator(EventsProcessorsTranslator)

  const { push } = useHistory()

  const redirectPageCreateEditAutomationRule = useCallback((automationRuleId) => {
    push(
      `/events-processors/edit/${eventProcessorId}/automation-rules/${selectedEventId}/${automationRuleId}`
    )
  }, [
    eventProcessorId,
    push,
    selectedEventId
  ])

  return (
    <Container>
      <div>
        <h3 className='container__title'>
          {i18n?.registeredAutomationRules}
        </h3>
      </div>
      <div className='container__table'>
        <TableContainer>
          <TableHeader>
            <TableHeaderRow>
              {
                automationRulesTableColumns?.map(
                  ({ key, className }, idx) => (
                    <TableHeaderCell key={idx} className={className}>
                      {i18n?.automationRulesTableColumnsTitles?.[key]}
                    </TableHeaderCell>
                  )
                )
              }
            </TableHeaderRow>
          </TableHeader>
          <TableBody loading={loadingAutomationRules}>
            {automationRulesData?.map(({ id, action, accountName, date, value, operationType }) => (
              <TableBodyRow key={id}>
                <TableBodyCell>
                  <SafeOverflow
                    text={
                      apiCodesTranslations({
                        apiCode: action,
                        isToLowerCase: false,
                        codesMapped: i18n?.automationRulesActions
                      })
                    }
                  />
                </TableBodyCell>
                <TableBodyCell>
                  {
                    apiCodesTranslations({
                      apiCode: operationType,
                      isToLowerCase: false,
                      codesMapped: i18n?.automationRulesOperationTypes
                    })
                  }
                </TableBodyCell>
                <TableBodyCell>
                  {value}
                </TableBodyCell>
                <TableBodyCell>
                  {date}
                </TableBodyCell>
                <TableBodyCell>
                  <SafeOverflow
                    text={accountName}
                    alignment='right'
                  />
                </TableBodyCell>
                <TableBodyCell css={wrapperButtons}>
                  <button
                    onClick={() => redirectPageCreateEditAutomationRule(id)}
                  >
                    <EditInfoIcon />
                  </button>
                  <button
                    onClick={() => onRemoveAutomationRule(id)}
                    disabled={loadingAutomationRuleRemove}
                  >
                    <TrashIcon />
                  </button>
                </TableBodyCell>
              </TableBodyRow>
            ))}
          </TableBody>
        </TableContainer>
      </div>
    </Container>
  )
}
