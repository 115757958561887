import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { iconButton } from 'Shared/styles/global'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const AuditContainer = styled.div`
  .b-font-audit {
    ${iconButton}
  }
`

export const AuditDetailsWrapper = styled.div`
  border-radius: 0.625rem;
  border: 1px solid var(--clr-gray8);
  padding: 1.25rem 0.625rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.625rem;
  

  div[class^='audit_details__'] {
    grid-column: span 1;

    &.audit_details__event {
      grid-column: span 2;
    }
  }

  .audit_details__content {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .item__label {
      font-weight: 600;
      font-size: 0.8125rem;
      color: var(--clr-gray5);
    }

    .item__created_at,
    .item__event {
      &.item__label {
        color: var(--clr-gray3);
      }

      font-weight: 700;
      color: var(--clr-gray1);
    }

    .safe_overflow__container {
      max-width: 20rem;
    }

    .item__bank_account_name,
    .item__accountable_plan_name,
    .item__expense_revenue_name,
    .item__origin_bank_account_name,
    .item__destination_bank_account_name {
      max-width: 25rem;
    }
  }

  @media(max-width: ${BREAK_POINTS.tablet}) {
    div[class^='audit_details__'] {
      &.audit_details__event {
        grid-column: span 1;
      }
    }

    grid-template-columns: repeat(1, 1fr);
  }
`

export const AuditDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.25rem;

  .audit_deatils__message_empty {
    font-size: 1.125rem;
    font-weight: 700;
    color: var(--clr-gray3);
    text-align: center;
  }
`

export const customModal = css`
  min-height: 12.5rem;
`
