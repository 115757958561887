import { originTypeOptions } from 'Domains/FinancialSchedule/Constants/FinancialSchedule'
import { apiCodesTranslations } from '../utils/functions'
import {
  BILLING_BANK_TRANSFER,
  BILLING_EXPENSE,
  BILLING_REVENUE,
  TICKET_SELLER_GUIDE_PAYMENT_BATCH
} from 'Constants/originType'

export const useTranslatedOriginTypes = ({ dictionary }) => {
  const originTypestranslations = {
    'Financial::ReservationPayment': dictionary?.common_originTypeReservationPayment_label,
    'Financial::ReservationRefund': dictionary?.common_originTypeReservationRefund_label,
    'TicketSeller::Ticket': dictionary?.common_originTypeTicket_label,
    'Financial::Invoice': dictionary?.common_originTypeInvoice_label,
    'Financial::PartnershipAccountTransaction': dictionary?.common_originTypePartnershipAccountTransactionCredit_label,
    [BILLING_EXPENSE]: dictionary?.common_originTypeExpense_label,
    [BILLING_REVENUE]: dictionary?.common_originTypeRevenue_label,
    'Financial::PaymentBatch': dictionary?.common_originTypePaymentBatch_label,
    'Financial::CashierHandling': dictionary?.common_originTypeCashierHandling_label,
    'Billing::GatewayPayment': dictionary?.common_originTypeBillingGatewayPayment_label,
    [BILLING_BANK_TRANSFER]: dictionary?.bank_transfer_transferBetweenAccounts_label,
    [TICKET_SELLER_GUIDE_PAYMENT_BATCH]: dictionary?.common_guideBatch_label
  }

  const originTypeTranslator = (originType) => {
    return apiCodesTranslations(
      { apiCode: originType, codesMapped: originTypestranslations, isToLowerCase: false }
    )
  }

  const originTypeOptionsTranslated = originTypeOptions.map((value) => {
    return {
      value,
      label: originTypeTranslator(value)
    }
  })

  return {
    originTypestranslations,
    originTypeOptionsTranslated,
    originTypeTranslator
  }
}
