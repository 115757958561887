import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const PageCreateEditExpenseRecurrencyContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  
  .wrapper__inputs_simulator {
    width: 100%;
    display: flex;
    gap: 0.625rem;
  }
  
  .form__inputs {
    width: 100%;
    display: grid;
    gap: 1.25rem;
    grid-template-columns: repeat(2, 1fr);
    background-color: var(--clr-white);
    border: 1px solid var(--clr-gray11);
    padding: 1.25rem;
    border-radius: var(--round-element-radius, 100%);
  }

  .input_container__observation,
  .input_container__receipts {
    grid-column: span 2;
  }

  .input__date, 
  .input__created_at,
  .input__reference_date {
    width: 100%;
  }

  .input__recurrence_type,
  .input__apply_recurrence {
    label {
      margin: 0;
    }
  }

  .form__actions {
    display: flex;
    gap: 1.25rem;
    width: 100%;
    margin-top: 3.125rem;
  }

  @media(max-width: ${BREAK_POINTS.tablet}) {
    .wrapper__inputs_simulator {
      flex-direction: column;
    }

    .form__inputs {
      grid-template-columns: repeat(1, 1fr);
    }

    .input_container__observation,
    .input_container__receipts {
      grid-column: span 1;
    }
  }
`
