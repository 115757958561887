import { useCallback, useState } from 'react'
import { BankTransfersService } from 'Core/Service/bankTransfers'
import { useToast } from 'Shared/hooks/useToast'
import { BankTransferParsed } from 'Core/Types/BankTransfer'
import { BankTransfersHooksTranslator } from '../i18n/BankTransfersHooksI18n'
import { useTranslator } from 'Shared/language/i18nHandler'

export const useGetBankTransfer = () => {
  const [getBankTransferData, setGetBankTransferData] = useState<BankTransferParsed>()
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(BankTransfersHooksTranslator)

  const getBankTransfer = useCallback(async (bankTransferId: string) => {
    setLoading(true)
    return await BankTransfersService.getBankTransfer(bankTransferId).then(
      setGetBankTransferData
    ).catch(errors => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorUpdatingTransferBetweenAccount
      })
    }).finally(() => {
      setLoading(false)
    })
  }, [addToast, i18n?.errorUpdatingTransferBetweenAccount])

  return {
    getBankTransfer,
    getBankTransferData,
    loadingBankTransfer: loading,
    setGetBankTransferData
  }
}
