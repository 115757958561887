import { useState, useCallback } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConciliationsHooksTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsHooksI18n'
import { CreditCardConciliationService } from 'Core/Service/creditCardConciliation'

export const useCreateAutoCreditCardConciliation = (fetchCreditCardConciliations) => {
  const { addToast } = useToast()
  const [isLoadingAutoCreditCardConciliation, setIsLoadingAutoCreditCardConciliation] = useState(false)

  const i18n = useTranslator(ConciliationsHooksTranslator)

  const createAutoCreditCardConciliation = useCallback((params) => {
    setIsLoadingAutoCreditCardConciliation(true)
    return CreditCardConciliationService.postAutoCreditCardConciliation(params).then(
      (conciliations) => {
        addToast({
          defaultMessage: i18n?.automaticConciliation(conciliations),
          typeToast: 'success'
        })
        fetchCreditCardConciliations()
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.conciliationCreateAutomicError
      })
    }).finally(() => {
      setIsLoadingAutoCreditCardConciliation(false)
    })
  }, [addToast, fetchCreditCardConciliations, i18n])

  return {
    isLoadingAutoCreditCardConciliation,
    createAutoCreditCardConciliation
  }
}
