import { useCallback, useState } from 'react'
import { BankTransfersService } from 'Core/Service/bankTransfers'
import { useToast } from 'Shared/hooks/useToast'
import { BankTransfersHooksTranslator } from '../i18n/BankTransfersHooksI18n'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankTransferAuditsResParsed } from 'Core/Types/BankTransfer'

export const useGetBankTransferAudits = () => {
  const [getBankTransferAuditsData, setGetBankTransferAuditsData] = useState<BankTransferAuditsResParsed>()
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(BankTransfersHooksTranslator)

  const getBankTransferAudits = useCallback(async (bankTransferId: string) => {
    setLoading(true)
    return await BankTransfersService.getBankTransferAudits(bankTransferId).then(
      setGetBankTransferAuditsData
    ).catch(errors => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorWhenSearchingForTransferHistoryBetweenAccounts
      })
    }).finally(() => {
      setLoading(false)
    })
  }, [addToast, i18n?.errorWhenSearchingForTransferHistoryBetweenAccounts])

  return {
    getBankTransferAudits,
    getBankTransferAuditsData,
    loadingBankTransferAudits: loading
  }
}
