import React, { useMemo } from 'react'
import xhook from 'xhook'
import {
  createInstance,
  OptimizelyProvider,
  useDecision
} from '@optimizely/react-sdk'
import { useUser } from './User'

const { REACT_APP_OPTIMIZELY_KEY, REACT_APP_OPTIMIZELY_CDN_PROXY } = process.env

xhook.before((request) => {
  if (REACT_APP_OPTIMIZELY_CDN_PROXY) {
    request.url = request.url.replace('https://cdn.optimizely.com/', REACT_APP_OPTIMIZELY_CDN_PROXY)
  }
})

const optimizely = createInstance({ sdkKey: REACT_APP_OPTIMIZELY_KEY })

export const Feature = (props) => {
  const { user } = useUser()

  const userInfo = useMemo(
    () => {
      const { id, ...attributes } = user
      return {
        id,
        attributes
      }
    }
    , [user]
  )

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={userInfo}
      {...props}
    />
  )
}

export const useFeatureFlag = (featureKey) => {
  const [feature] = useDecision(featureKey, { autoUpdate: true })
  const { enabled, variables } = feature || {}

  return { enabled, companies: variables.allowedCompanies?.companies }
}
