
export const AccountsPlansHooksTranslator = (
  dictionary
) => {
  const errorFecthingAccountsPlans = dictionary?.account_plan_errorFecthingAccountsPlans_toast
  const errorFecthingAccountsPlansOptions = dictionary?.account_plan_errorFecthingAccountsPlansOptions_toast
  const errorDeleteAccountPlan = dictionary?.account_plan_errorDeleteAccountPlan_toast
  const successDeletedAccountPlan = dictionary?.account_plan_successDeletedAccountPlan_toast
  const accountPlanSucessRegistered = dictionary?.account_plan_accountPlanSucessRegistered_toast
  const errorRegisteredAccountPlan = dictionary?.account_plan_errorRegisteredAccountPlan_toast
  const errorFecthingAccountPlan = dictionary?.account_plan_errorFecthingAccountPlan_toast
  const accountPlanSucessUpdated = dictionary?.account_plan_accountPlanSucessUpdated_toast
  const errorUpdateAccountPlan = dictionary?.account_plan_errorUpdateAccountPlan_toast

  return {
    errorFecthingAccountsPlans,
    errorFecthingAccountsPlansOptions,
    errorDeleteAccountPlan,
    successDeletedAccountPlan,
    accountPlanSucessRegistered,
    errorRegisteredAccountPlan,
    errorFecthingAccountPlan,
    accountPlanSucessUpdated,
    errorUpdateAccountPlan
  }
}
