export const ExpenseRevenueTypesTranslator = (
  dictionary
) => {
  const auditModalTitle = dictionary?.expense_revenue_auditModalTitle_label

  const auditLabels = {
    expense_revenue_type: dictionary?.audit_expenseRevenueType_label,
    description: dictionary?.audit_description_label,
    accountable_plan_name: dictionary?.audit_accountablePlanName_label
  }

  return {
    auditModalTitle,
    auditLabels
  }
}
