import { useState, useEffect, useCallback } from 'react'
import { ExpenseService } from 'Core/Service/expense'
import { SafeDate } from '@binamik/js-providers'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { useExpensesForgotten } from './useExpensesForgotten'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesHooksTranslator } from '../i18n/ExpensesHooksI18n'
import { usePagination } from '@bonitour/app-functions'
import { ExpensesCommonParams, GetExpensesParsed } from 'Core/Types/Expense'
import { useParamsFilter } from 'Shared/hooks/useParamsFilter'
import { BackendErrorFormat } from 'Core/Types/Error'
import { exportCsv } from '@bonitour/common-functions'
import { useTranslatedExpensesStatus } from '../i18n/hooks/useTranslatedExpensesStatus'

export interface FetchExpensesProps {
  newFilters?: ExpensesCommonParams
  newPage?: number
  newPerPage?: number
  page?: number
  finallyCallback?: () => void
}

const initFilters = {
  startDate: new SafeDate().isoDate,
  endDate: new SafeDate().isoDate
}

export const useGetExpenses = () => {
  const [loading, setLoading] = useState(false)
  const [expensesList, setExpensesList] = useState<GetExpensesParsed>(
    {
      expenses: [],
      meta: {}
    }
  )

  const [filters, setFilters] = useParamsFilter<ExpensesCommonParams>(initFilters)

  const { addToast } = useToast()

  const i18n = useTranslator(ExpensesHooksTranslator)
  const { expensesStatusTranslator } = useTranslatedExpensesStatus()

  const { data: expForgottenData, setData: setExpForgottenData } = useExpensesForgotten()

  const { initStatePagination, newPerPageOptions } = usePaginationOptions()

  const { pagination, setPagination, handleOnPageChange, handleOnPerPageChange } = usePagination({
    startingPage: initStatePagination.currentPage,
    perPage: initStatePagination.perPage
  })

  const fetchExpenses = useCallback(
    async ({ newFilters, newPage, newPerPage, page, finallyCallback }: FetchExpensesProps = {}) => {
      setLoading(true)
      return await ExpenseService.list({
        filters: newFilters || filters,
        pagination: {
          currentPage: newPage || page || pagination.page,
          perPage: newPerPage || pagination.perPage
        }
      })
        .then((data: GetExpensesParsed) => {
          setExpensesList(data)
          setPagination({
            page: newPage || page || pagination.page,
            perPage: newPerPage || pagination.perPage,
            totalItems: data?.meta?.count || 0,
            totalPages: data?.meta?.last || 0
          })
        })
        .catch((errors: BackendErrorFormat) => {
          console.error(errors?.data)
          addToast({
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorLoadingAccountsPayable
          })
        })
        .finally(() => {
          setLoading(false)
          finallyCallback && finallyCallback()
        })
    },
    [
      filters,
      pagination.page,
      pagination.perPage,
      setPagination,
      addToast,
      i18n?.errorLoadingAccountsPayable
    ]
  )

  const handleFiltersChange = useCallback(
    (newFilters: ExpensesCommonParams) => {
      setExpForgottenData({ forgotten: 0, daysAgo: 0 })
      fetchExpenses({
        newFilters,
        newPage: 1,
        finallyCallback: () => setFilters(newFilters)
      })
    },
    [fetchExpenses, setExpForgottenData, setFilters]
  )

  useEffect(() => {
    if (pagination.isActive || (!filters?.startDate && !filters?.endDate)) return
    fetchExpenses({
      newPage: 1,
      newPerPage: pagination.perPage
    })
  }, [
    filters?.endDate,
    filters?.startDate,
    fetchExpenses,
    filters,
    pagination.isActive,
    pagination.perPage
  ])

  const fetchExportExpenses = useCallback(async () => {
    return await ExpenseService.exportExpenses({ filters, i18n: { ...i18n, expensesStatusTranslator } })
      .then(({ parserCsv, fileName }) => {
        exportCsv(fileName, parserCsv)
      })
      .catch(errors => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg
        })
      })
  }, [addToast, expensesStatusTranslator, filters, i18n])

  return {
    handleFiltersChange,
    filters,
    pagination,
    expenses: expensesList?.expenses,
    meta: expensesList?.meta,
    loading,
    newPerPageOptions,
    expForgottenData,
    handleOnPageChange: handleOnPageChange(fetchExpenses),
    handleOnPerPageChange: handleOnPerPageChange(fetchExpenses),
    fetchExportExpenses,
    fetchExpenses
  }
}
