import { apiCodesTranslations } from 'Shared/language/utils/functions'

interface StatusReceivablesTranslations {
  [key: string]: string,
  scheduled: string
  pending: string
  settled: string
  error: string
  waitingforadjustmentdebit: string
  anticipated: string
  payed: string
}

export const useTranslatedStatusReceivables = ({ dictionary }) => {
  const statusReceivablesTranslations: StatusReceivablesTranslations = {
    scheduled: dictionary?.receivables_scheduled_label,
    pending: dictionary?.receivables_peding_label,
    settled: dictionary?.receivables_settled_label,
    error: dictionary?.receivables_errorSettledFinancialInstitution_label,
    waitingforadjustmentdebit: dictionary?.receivables_waitingForAdjustmentDebit_label,
    anticipated: dictionary?.receivables_anticipated_label,
    payed: dictionary?.receivables_payed_label
  }

  const statusReceivablesTranslator = (status: string): string => {
    return apiCodesTranslations(
      {
        apiCode: status,
        codesMapped: statusReceivablesTranslations
      }
    )
  }

  return statusReceivablesTranslator
}
