import styled from '@emotion/styled'
import { colors } from '@bonitour/components'

export const BaseContainer = styled.form`
  min-height: 21rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;

  .form__inputs {
    display: grid;
    gap: 0.625rem;
    grid-template-columns: repeat(8, 1fr);

    input,
    .input__date,
    .input__reference_date,
    .input__status,
    .input__payment_method,
    .input__value,
    .input__expense_revenue,
    .input__payment_day,
    .input__ocurrences,
    .input__recurrence_type,
    .input__bank_account,
    .input__apply_recurrence,
    .input__supplier
    {
      border-color: ${colors.gray6};
    }

    div[class^='input_container__'],
    div[class^='input__'] {
      grid-column: span 2;

      &.input_container__date,
      &.input_container__reference_date {
        grid-column: span 1;
      }

      &.input_container__observation {
        grid-column: span 3;
      }
    }

    .input__payment_day {
      .component-drawer-list {
        max-height: 9.375rem;
      }
    }

    .input__expense_revenue,
    .input__supplier {
      .component-drawer-container {
        width: 400px;
        white-space: normal;
        overflow-wrap: break-word;

        .component-drawer-item {
          line-height: 1.3;
        }
      }
    }
  }

  .input_container__recurrence_type,
  .input_container__apply_recurrence {
    label {
      margin: 0;
    }
  }


  .form__actions {
    margin-top: 1.875rem;

    button {
      width: 12.5rem;
      &:first-of-type {
        margin-right: 1.25rem;
      }
    }
  }

`

export const RevenueForm = styled(BaseContainer)``

export const ExpenseRecurrencyForm = styled(BaseContainer)`
  .form__inputs_recurrency {
    grid-template-columns: repeat(2, 1fr);

    div[class^='input_container__'] {
      grid-column: span 1;
      &.input_container__observation {
        grid-column: span 2;
      }
    }
  }

  .wrapper_inputs_simulator {
    display: grid;
    gap: 1.25rem;
    grid-template-columns: 1fr 0.5fr;
  }
`
