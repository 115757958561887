import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConfigurationsService } from 'Core/Service/configurations'
import { ConfigurationPayload } from 'Core/Types/Configuration'
import { UserStore } from 'Core/Store/User'
import { ConfigurationsHooksTranslator } from '../i18n/ConfigurationHooksI18n'

export const useUpdateConfiguration = (getConfiguration) => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(ConfigurationsHooksTranslator)

  const updateConfiguration = useCallback(async (configurationPayload: ConfigurationPayload) => {
    setLoading(true)
    const configurationId = UserStore?.user?.company?.id
    return await ConfigurationsService.patchConfiguration(configurationPayload, configurationId)
      .then(() => {
        addToast({
          typeToast: 'success',
          defaultMessage: i18n?.updatedConfiguration
        })
      }).catch(errors => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorWhenSearchingForConfigurations,
          dictTranslation: i18n?.apiCodesMappingForConfigurations
        })
      }).finally(() => {
        getConfiguration()
        setLoading(false)
      })
  }, [
    getConfiguration,
    addToast,
    i18n?.updatedConfiguration,
    i18n?.errorWhenSearchingForConfigurations,
    i18n?.apiCodesMappingForConfigurations
  ])

  return {
    updateConfiguration,
    loadingUpdateConfiguration: loading
  }
}
