import { useState, useCallback } from 'react'
import { BankStatementsService } from 'Core/Service/BankStatement'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankStatementsHooksTranslator } from '../i18n/BankStatementsHooksI18n'

export const useRemoveBankStatements = (fetchBankStatements) => {
  const { addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const i18n = useTranslator(BankStatementsHooksTranslator)

  const onRemoveBankStatements = useCallback((params) => {
    setIsLoading(true)
    return BankStatementsService.removeBankStatements(params).then(
      ({ deletedBankStatements }) => {
        addToast({
          defaultMessage: i18n?.deletedBankStatement(deletedBankStatements),
          typeToast: 'success'
        })
        fetchBankStatements()
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        dictTranslation: i18n?.apiCodesMappingsForBankStatements,
        defaultMessage: i18n?.errorDeletingBankStatements
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }, [addToast, fetchBankStatements, i18n])

  return {
    onRemoveBankStatements,
    isLoading
  }
}
