import { useTranslatedCashDepositsStatus } from './hooks/useTranslatedCashDepositsStatus'

export interface CashDepositsTranslatorRes {
  cashDepositsColumnsTitles: string[]
  dateFormat: string
  selectPeriodToSearch: string
  noCashDepositsFound_label: string
  cashDeposit: string
  searchButton: string
  newCashDeposit: string
  cashDepositsStatusTranslator: (status: string) => string
}

export const CashDepositsTranslator = (
  dictionary
): CashDepositsTranslatorRes => {
  const cashDepositsColumnsTitles = [
    dictionary?.common_date_label,
    dictionary?.cash_deposit_originAccount_label,
    dictionary?.cash_deposit_destinationAccount_label,
    dictionary?.common_value_label,
    dictionary?.common_status_label,
    dictionary?.common_user_label,
    dictionary?.common_actions_label
  ]

  const dateFormat = dictionary?.common_dateFormat_template

  const selectPeriodToSearch = dictionary?.common_selectPeriodToSearch_label
  const noCashDepositsFound_label = dictionary?.cash_deposit_noCashDepositsFound_label
  const cashDeposit = dictionary?.cash_deposit_cashDeposit_label
  const searchButton = dictionary?.common_searchButton_label
  const newCashDeposit = dictionary?.cash_deposit_newDeposit_label

  const { cashDepositsStatusTranslator } = useTranslatedCashDepositsStatus({ dictionary })

  return {
    cashDepositsColumnsTitles,
    dateFormat,
    selectPeriodToSearch,
    noCashDepositsFound_label,
    cashDeposit,
    searchButton,
    newCashDeposit,
    cashDepositsStatusTranslator
  }
}
