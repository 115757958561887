import { useForm } from '@bonitour/app-functions'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { useMemo } from 'react'
import {
  acquirers,
  bankAccounts,
  endDate,
  getSchema,
  startDate
} from '../../Form/__ConciliationsBaseInputs'
import { ConciliationsInputsTranslator } from '../../i18n/ConciliationsInputsI18n'
import { ConciliationsFormErrorsTranslator } from '../../i18n/ConciliationsFormErrorsI18n'

export const useConciliationsInputs = (
  filters,
  bankAccountOptions,
  isBankConciliations
) => {
  const inputsVals = useTranslatedInputs([
    bankAccounts(bankAccountOptions),
    acquirers,
    startDate,
    endDate
  ], ConciliationsInputsTranslator)

  const newInputsValid = useMemo(() => {
    return inputsVals.filter(({ value }) => {
      switch (value) {
      case 'bankAccountId':
        return isBankConciliations
      case 'acquirer':
        return !isBankConciliations
      default:
        return true
      }
    })
  }, [inputsVals, isBankConciliations])

  const schema = useMemo(
    () => getSchema(newInputsValid),
    [newInputsValid]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(filters, schema)

  const formErrorsTranslteds = useTranslatedFormErrors(
    errors, ConciliationsFormErrorsTranslator
  )

  return {
    errors: formErrorsTranslteds,
    form,
    onInputChange,
    onInputBlur,
    inputsValid: newInputsValid,
    onSubmit
  }
}
