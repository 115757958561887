import { SafeDate } from '@binamik/js-providers'

type StartDateMustBeLessThanOrEqualEndDateProps = {
  ctx: { parent: { endDate: string | Date } }
}

export const startDateMustBeLessThanOrEqualEndDate = (
  value: string | Date,
  ctx: StartDateMustBeLessThanOrEqualEndDateProps['ctx']
) => {
  if (!value || !ctx?.parent?.endDate) {
    return true
  }
  const endDate = new SafeDate(ctx.parent.endDate).jsDate
  const startDate = new SafeDate(value)

  return !startDate.isAfter(endDate)
}
