import { parseTemplateString, pluralize } from 'Shared/language/utils/dictionaryUtils'

export const BankStatementsHooksTranslator = (
  dictionary,
  locale
) => {
  const errorFetchingBankStatements = dictionary?.bank_statement_errorFetchingBankStatements_toast
  const deletedBankStatement = (qty) => {
    const templateString = pluralize(
      dictionary?.bank_statement_deletedBankStatement_toast,
      qty,
      locale
    )
    return parseTemplateString(templateString, {
      NUMBER: qty
    })
  }

  const errorDeletingBankStatements = dictionary?.bank_statement_errorDeletingBankStatements_toast
  const errorExportBankStatementFile = dictionary?.bank_statement_errorExportBankStatementFile_toast
  const errorFetchingImportedBankStatements = dictionary?.bank_statement_errorDeletingBankStatements_toast
  const errorWhenImportingBankStatements = dictionary?.bank_statement_errorWhenImportingBankStatements_toast

  const apiCodesMappingsForBankStatements = {
    'bank_statement::import::corrupted_file': dictionary?.bank_statement_importCorruptedFile_toast,
    'bank_account::incompatible::different_bank_account': dictionary?.bank_account_incompatibleDifferentBankAccount_toast,
    'bank_statement::import::wrong_number_or_file': dictionary?.bank_statement_importWrongNumberOrFile_toast,
    'bank_statement::import::empty_field_formats': dictionary?.bank_statement_importEmptyFieldFormats_toast,
    'bank_statement::conciliated::cannot_delete_conciliated_bank_statements': dictionary?.bank_statement_itIsNotAllowedDeleteBankStatementAlreadyConciliated_toast,
    'bank_statement::import::bank_account_not_allowed': dictionary?.bank_statement_bankAccountNotAllowed_toast
  }

  return {
    errorFetchingBankStatements,
    deletedBankStatement,
    errorDeletingBankStatements,
    errorExportBankStatementFile,
    apiCodesMappingsForBankStatements,
    errorFetchingImportedBankStatements,
    errorWhenImportingBankStatements
  }
}
