/* eslint-disable dot-notation */

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import dayjs from 'dayjs'
import { titleColumnFailureListingTable } from 'Domains/CreditCard/Receivables/Constants/Receivables'
import { containerSendToEmailMessage, emailStyle, modalBox, modalBoxRow, requestLink, sendToEmailMessage } from './ImportFailureList.styles'
import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  Modal
} from '@bonitour/components'

const FailureListingTableHeader = ({ columns }) => (
  <TableHeader>
    <TableHeaderRow>
      {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
    </TableHeaderRow>
  </TableHeader>
)

const FailureListingTableBody = ({ fails, loading, startDate, endDate }) => {
  const [modalData, setModalData] = useState()
  const [isVisible, setVisibility] = useState(false)

  const toggleVisible = () => setVisibility(true)
  const toggleHidden = () => setVisibility(false)

  return (
    <>
      <Modal
        title='Informações'
        isVisible={isVisible}
        onCloseClick={toggleHidden}
      >
        <div css={containerSendToEmailMessage}>
          <p css={sendToEmailMessage}>
            Faça o print dessa tela e envie para o e-mail do suporte:
            <span css={emailStyle}>centraldecomando@binamik.com.br</span>
          </p>
          <small>{`Período: ${startDate && dayjs(startDate).format('DD/MM/YYYY')} a ${endDate && dayjs(endDate).format('DD/MM/YYYY')}`}</small>
        </div>
        <div css={modalBox}>
          {modalData && Object.keys(modalData).map((item) =>
            <p key={item} css={modalBoxRow}>{`${item}: ${modalData[item]}`}</p>)}
        </div>
      </Modal>
      <TableBody loading={loading}>
        {fails?.map(({ data, error }) => (
          <TableBodyRow key={data.id}>
            <TableBodyCell>{error}</TableBodyCell>
            <TableBodyCell>
              <div
                css={requestLink}
                onClick={() => {
                  setModalData(data)
                  toggleVisible()
                }}
              >
                {data
                  ? Object.keys(data).map((key) => (
                    `${key}: ${data[key]} | `
                  ))
                  : '-'}
              </div>
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </TableBody>
    </>
  )
}
export const ImportFailureList = ({ loading, fails, startDate, endDate }) => {
  return (
    <TableContainer>
      <FailureListingTableHeader columns={titleColumnFailureListingTable} />
      <FailureListingTableBody
        fails={fails}
        loading={loading}
        startDate={startDate}
        endDate={endDate}
      />
    </TableContainer>
  )
}
