export const BANK_ACCOUNT_OPTIONS = [
  { value: '1', label: 'Banco do Brasil' },
  { value: '341', label: 'Itaú Unibanco' },
  { value: '237', label: 'Bradesco' },
  { value: '33', label: 'Santander' },
  { value: '104', label: 'Caixa Econômica Federal' },
  { value: '748', label: 'Sicredi' },
  { value: '84', label: 'CC Uniprime Norte do Paraná' },
  { value: '94', label: 'Banco Finaxis' },
  {
    value: '756',
    label: 'Sicoob'
  },
  { value: '755', label: 'Bank of America Merrill Lynch' },
  { value: '47', label: 'Est. SE - Banese' },
  { value: '77', label: 'Banco Inter' },
  { value: '41', label: 'Est. RS - Banrisul' },
  { value: '260', label: 'NU PAGAMENTOS S.A (NUBANK)' },
  { value: '4', label: 'BNB' },
  { value: '136', label: 'CC Unicred do Brasil' },
  { value: '422', label: 'Safra' },
  { value: '133', label: 'CRESOL CONFEDERAÇÃO' },
  { value: '21', label: 'Est. ES - Banestes' },
  { value: '212', label: 'Banco Original' },
  { value: '655', label: 'Votorantim' },
  { value: '3', label: 'Amazônia - BASA' },
  { value: '25', label: 'Alfa' },
  { value: '37', label: 'Est. PA - Banpará' },
  { value: '69', label: 'Banco Crefisa' },
  { value: '70', label: 'BRB - Banco de Brasília' },
  { value: '85', label: 'CC Cecred' },
  { value: '89', label: 'CC Região da Mogiana' },
  { value: '97', label: 'CC Centralcredi' },
  { value: '99', label: 'CC Uniprime Central' },
  { value: '107', label: 'BBM' },
  { value: '120', label: 'Rodobens' },
  { value: '213', label: 'Arbi' },
  { value: '218', label: 'Banco BS2' },
  { value: '224', label: 'Fibra' },
  { value: '243', label: 'Banco Master' },
  { value: '246', label: 'ABC - Brasil' },
  { value: '318', label: 'BCO BMG S.A.' },
  { value: '376', label: 'J. P. Morgan' },
  { value: '389', label: 'Mercantil do Brasil' },
  { value: '600', label: 'Luso Brasileiro' },
  { value: '604', label: 'Indl. do Brasil' },
  { value: '611', label: 'Paulista' },
  { value: '612', label: 'Guanabara' },
  { value: '623', label: 'BANCO PAN' },
  { value: '633', label: 'Rendimento' },
  { value: '634', label: 'Triângulo' },
  { value: '637', label: 'Sofisa' },
  { value: '643', label: 'Pine' },
  { value: '654', label: 'Banco Digimais' },
  { value: '707', label: 'Daycoval' },
  { value: '741', label: 'Ribeirão Preto' },
  { value: '743', label: 'Semear' },
  { value: '745', label: 'Citibank S. A.' },
  { value: '757', label: 'BCO KEB HANA DO BRASIL S.A.' },
  { value: '197', label: 'STONE PAGAMENTOS S.A' },
  { value: '336', label: 'Banco C6 S.A' },
  { value: '301', label: 'Bpp Instituição De Pagamentos S.A' },
  { value: '993', label: 'Alelo' },
  { value: '208', label: 'BANCO BTG PACTUAL S.A' },
  { value: '125', label: 'BANCO GENIAL' },
  { value: '613', label: 'OMNI BANCO S.A.' },
  { value: '653', label: 'BANCO VOITER' },
  { value: '487', label: 'DEUTSCHE BANK S.A.BCO ALEMAO' },
  { value: '999', label: ' Zoop Instituição de Pagamentos' },
  { value: '0', label: 'Cloud Walk Meios de Pagamentos' },
  { value: '998', label: 'ENOPP' }
]
