import { cardFlagOptions } from 'Constants/cardFlagOptions'
import { apiCodesTranslations } from '../utils/functions'

interface FlagsTranslations {
  [key: string]: string,
  mastercard: string
  visa: string
  american_express: string
  discover: string
  elo: string
  hipercard: string
  diners: string
  lien_credit: string
  lien_debit: string
  pos_monthly_fee: string
}

export const useTranslatedFlags = ({ dictionary }) => {
  const flagsTranslations: FlagsTranslations = {
    mastercard: dictionary?.common_flagMastercard_label,
    visa: dictionary?.common_flagVisa_label,
    american_express: dictionary?.common_flagAmericanExpress_label,
    discover: dictionary?.common_flagDiscover_label,
    elo: dictionary?.common_flagElo_label,
    hipercard: dictionary?.common_flagHipercard_label,
    diners: dictionary?.common_flagDiners_label,
    lien_credit: dictionary?.common_lien_credit_label,
    lien_debit: dictionary?.common_lien_debit_label,
    pos_monthly_fee: dictionary?.common_pos_monthly_fee_label
  }

  const flagTranslator = (flag: string): string => {
    return apiCodesTranslations(
      {
        apiCode: flag,
        codesMapped: flagsTranslations,
        isToLowerCase: false
      }
    )
  }

  const cardFlagOptionsTranslated = cardFlagOptions.map((value) => {
    return {
      value,
      label: value ? flagTranslator(value) : dictionary?.common_all_label
    }
  })

  return {
    flagTranslator,
    cardFlagOptionsTranslated
  }
}
