import dayjs from 'dayjs'

export const DateUtils = {
  getDateMoves (startDate, endDate) {
    const formattedStartDate = startDate ? dayjs(startDate).format('DD/MM/YYYY') : '__/__/__'
    const formattedEndDate = endDate ? dayjs(endDate).format('DD/MM/YYYY') : '__/__/__'

    if ((startDate && endDate) && (formattedStartDate === formattedEndDate)) return formattedStartDate
    else return `${formattedStartDate} ${formattedEndDate && 'e'} ${formattedEndDate}`
  },
  getTextBetweenDates (startDate, endDate) {
    const formattedStartDate = startDate ? dayjs(startDate).format('DD/MM/YYYY') : '__/__/__'
    const formattedEndDate = endDate ? dayjs(endDate).format('DD/MM/YYYY') : '__/__/__'

    if ((startDate && endDate) && (formattedStartDate === formattedEndDate)) return 'de'
    else return 'entre'
  }
}
