/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Link } from 'react-router-dom'
import { receivablesImportMesssage } from '../Constants/Receivables'
import { useImportReceivables } from '../hooks/useImportReceivables'
import {
  alertIcon,
  arrowIcon,
  arrowRightIcon,
  buttonIconStyle,
  buttonStyle,
  closeIcon,
  collapsableIconOpen,
  containerAlertIcon,
  containerExpandable,
  containerHeader,
  containerList,
  divider,
  importList,
  linkStyle,
  marginTop,
  messageContainer,
  messageStyle,
  onHoverListItem,
  pointerEventsNone,
  anexedFileButton,
  title,
  titleImport,
  uploadedCard,
  width100
} from './ReceivablesImport.styles'
import {
  Card,
  Row,
  Column,
  DatePicker,
  InputFormGroup,
  UploadIcon,
  GhostButton,
  InfoIcon,
  CloseIcon,
  AngleRightIcon,
  flexCenteredSpaceBetweenRow,
  flexRowCentered,
  colors,
  LoadingAnimation,
  Select,
  AngleUpIcon
} from '@bonitour/components'
import { AttachementButton } from 'Shared/components/AttachementButton/AttachementButton'
import { acquirersOptions } from 'Constants/acquirersOptions'

const listItemRed = importList(colors.red1)
const listItemGray = importList(colors.gray3, '6px')

export const ReceivablesImport = ({ isOpened = false, toggleExpandableImport }) => {
  const {
    gateway,
    startDate,
    endDate,
    file,
    handleUploadFile,
    setGateway,
    setStartDate,
    setEndDate,
    fetchImportReceivables,
    loading,
    importedData,
    setImportedData
  } = useImportReceivables()

  const { receivables, imported, alreadyImported, failed, fails } = importedData || {}
  const closeIsUploaded = () => setImportedData(undefined)

  return (
    <Card customCss={[anexedFileButton]}>
      <div css={containerHeader}>
        <span css={title}>Importação de recebíveis</span>
        <AngleUpIcon
          css={[arrowIcon, isOpened && collapsableIconOpen]}
          onClick={toggleExpandableImport}
        />
      </div>
      {isOpened && (
        <>
          <Row css={containerExpandable}>
            <Column css={messageContainer} desktop={3} phone={12}>
              <small css={messageStyle}>{receivablesImportMesssage}</small>
            </Column>
            <Column desktop={2} phone={12}>
              <InputFormGroup label='Adquirente'>
                <Select
                  id='gateway'
                  options={acquirersOptions}
                  placeholder='Selecione um adquirente'
                  value={gateway}
                  onChange={setGateway}
                />
              </InputFormGroup>
            </Column>
            { gateway === 'braspag' &&
              <>
                <Column desktop={2} phone={12}>
                  <InputFormGroup label='Data inicial'>
                    <DatePicker
                      css={width100}
                      id='start-date'
                      placeholder='Selecione ou digite uma data inicial'
                      onChange={setStartDate}
                      value={startDate}
                    />
                  </InputFormGroup>
                </Column>
                <Column desktop={2} phone={12}>
                  <InputFormGroup label='Data final'>
                    <DatePicker
                      css={width100}
                      id='end-date'
                      placeholder='Selecione ou digite uma data final'
                      onChange={setEndDate}
                      value={endDate}
                    />
                  </InputFormGroup>
                </Column>
              </>
            }
            { gateway === 'cielo' &&
              <Column desktop={2} phone={12}>
                <AttachementButton file={file} onChange={handleUploadFile} accept={'.csv, text/csv'}/>
              </Column>
            }
            { !!gateway &&
              <Column desktop={3} phone={12}>
                <GhostButton disabled={loading || (gateway === 'cielo' && !file?.length)} onClick={fetchImportReceivables} css={[width100, buttonStyle]}>
                  { loading ? <LoadingAnimation css={buttonIconStyle} size={19} /> : <UploadIcon css={buttonIconStyle} /> }
                  Importar recebíveis
                </GhostButton>
              </Column>
            }
          </Row>
        </>
      )}
      {importedData
        ? (
          <Card css={uploadedCard}>
            <div css={flexCenteredSpaceBetweenRow}>
              <div css={flexRowCentered}>
                <div css={containerAlertIcon}>
                  <InfoIcon css={alertIcon} />
                </div>
                <span css={titleImport}>Importação de recebíveis concluída!</span>
              </div>
              <CloseIcon css={closeIcon} onClick={closeIsUploaded} />
            </div>
            <ul css={containerList}>
              <li css={listItemGray}>{`${receivables} registros`}</li>
              <li css={listItemGray}>{`${imported} registros importados`}</li>
              <li css={listItemGray}>{`${alreadyImported} registros importados anteriormente`}</li>
              <li css={listItemRed}>
                <Link
                  css={[linkStyle, !failed && pointerEventsNone]}
                  to={{
                    pathname: '/receivables/import-failure',
                    state: { fails, startDate, endDate }
                  }}
                >
                  <small css={onHoverListItem}>{`${failed} registros com falha`}</small>
                  <AngleRightIcon css={arrowRightIcon} />
                </Link>
              </li>
            </ul>
          </Card>
        )
        : <div css={[divider, !isOpened && marginTop]} />}
    </Card>
  )
}
