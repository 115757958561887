import { useState, useCallback } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { useUser } from 'Shared/contexts/User'
import { BankConciliationService } from 'Core/Service/bankConciliation'
import { ConciliationsHooksTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsHooksI18n'

export const useDeleteBankConciliations = (fetchBankConciliations) => {
  const { addToast } = useToast()
  const [isLoadingDeleteBankConciliations, setIsLoadingDeleteBankConciliations] = useState(false)

  const i18n = useTranslator(ConciliationsHooksTranslator)
  const { user } = useUser()

  const deleteBankConciliations = useCallback((params) => {
    setIsLoadingDeleteBankConciliations(true)
    return BankConciliationService.deleteBankConciliations(params, user).then(
      () => {
        addToast({
          defaultMessage: i18n?.deletedConciliation(params?.conciliationsIds?.length),
          typeToast: 'success'
        })
        fetchBankConciliations()
        return true
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.deleteConciliationsError
      })
      return false
    }).finally(() => {
      setIsLoadingDeleteBankConciliations(false)
    })
  }, [addToast, fetchBankConciliations, i18n, user])

  return {
    isLoadingDeleteBankConciliations,
    deleteBankConciliations
  }
}
