
export const SuppliersHooksTranslator = (
  dictionary,
  _locale
) => {
  const errorFecthingSuppliers = dictionary?.supplier_errorFecthingSuppliers_toast
  const createSupplier = dictionary?.supplier_createSupplier_toast
  const errorCreateSupplier = dictionary?.supplier_errorCreateSupplier_toast
  const errorGetByIdSupplier = dictionary?.supplier_errorGetByIdSupplier_toast
  const editSupplier = dictionary?.supplier_editSupplier_toast
  const errorEditSupplier = dictionary?.supplier_errorEditSupplier_toast
  const removeSupplier = dictionary?.supplier_removeSupplier_toast
  const errorRemoveSupplier = dictionary?.supplier_errorRemoveSupplier_toast
  const errorAuditSupplier = dictionary?.supplier_errorAuditSupplier_toast
  const errorLoadSupplierOptions = dictionary?.supplier_errorLoadSupplierOptions_toast

  const apiCodesMappingForSupplier = {
    'supplier::id::cannot_destroy': dictionary?.supplier_supplierCannotDestroy_toast,
    'supplier::supplier_document::supplier_document_already_used': dictionary?.supplier_supplierAlreadyRegistered_toast
  }

  return {
    errorFecthingSuppliers,
    createSupplier,
    errorCreateSupplier,
    errorGetByIdSupplier,
    editSupplier,
    errorEditSupplier,
    removeSupplier,
    errorRemoveSupplier,
    errorAuditSupplier,
    errorLoadSupplierOptions,
    apiCodesMappingForSupplier
  }
}
