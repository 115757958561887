import { useState, useCallback } from 'react'
import { BankStatementsService } from 'Core/Service/BankStatement'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankStatementsHooksTranslator } from '../i18n/BankStatementsHooksI18n'
import { BankStatementsImportPayload } from 'Core/Types/BankStatement'
import { BackendErrorFormat } from 'Core/Types/Error'

export const useUploadBankStatementsImport = () => {
  const { addToast } = useToast()
  const [isLoadUpload, setIsLoadUpload] = useState(false)
  const [bankStatementsImportRes, setBankStatementsImportRes] = useState(
    {
      ignoredCurrentDateTransactions: 0,
      importedTransactions: 0,
      transactions: 0,
      details: []
    }
  )

  const i18n = useTranslator(BankStatementsHooksTranslator)

  const onUploadBankStatements = useCallback((params: BankStatementsImportPayload) => {
    setIsLoadUpload(true)
    const response = BankStatementsService.uploadBankStatements(params).then(
      ({ ignoredCurrentDateTransactions, importedTransactions, transactions, details }) => {
        setBankStatementsImportRes(
          { ignoredCurrentDateTransactions, importedTransactions, transactions, details }
        )
        return true
      }
    ).catch((errors: BackendErrorFormat) => {
      console.error(errors?.data)
      addToast({
        dictTranslation: i18n?.apiCodesMappingsForBankStatements,
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorWhenImportingBankStatements
      })
      return false
    }).finally(() => {
      setIsLoadUpload(false)
    })
    return response
  }, [
    addToast,
    i18n?.apiCodesMappingsForBankStatements,
    i18n?.errorWhenImportingBankStatements
  ])

  return {
    isLoadUploadBankStatements: isLoadUpload,
    bankStatementsImportRes,
    setBankStatementsImportRes,
    onUploadBankStatements
  }
}
