import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const containerFilters = css`
    margin-top: 1.25rem;
    background-color: ${colors.gray12};
    padding: 0.625rem;

    #datepicker-input {
        width: 100%;
    }
`

export const customDate = css`
    width: 16.6875rem;
`

export const buttonSearch = css`
  height: 2.5rem;
  width: 100%;
  margin-top: 1.25rem;
`
