import { MetaParser } from './Meta'

export const FinancialScheduleParser = {
  single: (move) => {
    const {
      id,
      date,
      value,
      installment,
      installments,
      brand,
      authorization_code: authorizationCode,
      proof_of_sale: proofOfSale,
      status,
      operation_type: operationType,
      creditcard_operation_type: creditcardOperationType,
      conciliated,
      info
    } = move?.move ?? move ?? {}
    return {
      id,
      date,
      value,
      installment,
      installments,
      brand,
      authorizationCode,
      proofOfSale,
      status,
      operationType,
      creditcardOperationType,
      reservationCode: info ? info.reservation_code : '',
      partnerName: info ? info.partner_name : [],
      clientName: info ? info.client_name : '',
      transactionDate: info ? info.event_date : '',
      grossAmount: info ? info.gross_amount : '',
      netAmount: info ? info.net_amount : '',
      mdrFee: info ? info.mdr_fee : '',
      payFee: info ? info.pay_fee : '',
      transactionFee: info ? info.transaction_fee : '',
      antifraudFee: info ? info.antifraud_fee : '',
      conciliated,
      label: info?.label
    }
  },
  list: ({ meta, creditcard_movements: moves = [] }) => {
    return {
      moves: moves.map(FinancialScheduleParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  flowMovementsStatus: {
    received: { label: 'Recebido', color: '--clr-green2' }, // status depreciated
    done: { label: 'Recebido', color: '--clr-green2' },
    forecasted: { label: 'Previsto', color: '--clr-yellow1' },
    error: { label: 'Sem status', color: '--clr-gray10' }
  },
  flowMovementsOperationType: {
    credit: { label: 'Crédito', color: '--clr-green2' },
    debit: { label: 'Débito', color: '--clr-red1' },
    error: { label: 'Sem tipo de operação', color: '--clr-gray10' }
  }
}
