import React from 'react'
import { useCompany } from 'Shared/contexts/Company'
import { useFeatureFlag } from 'Shared/contexts/Feature'

interface CustomFeatureEnablerProps {
  featureKey: string
  children?: React.ReactNode
}

export const CustomFeatureEnabler: React.FC<CustomFeatureEnablerProps> = ({ featureKey, children }) => {
  const { company } = useCompany()
  const { enabled, companies } = useFeatureFlag(featureKey)

  if (!enabled || !companies?.includes(company?.id)) {
    return null
  }

  return <>{children}</>
}
