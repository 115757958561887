/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'

export const CreateRevenue = () => {
  const { push } = useHistory()

  const onReturnToDashboard = () => push('/expense-revenue-dashboard')

  return (
    <PageTitle
      title='Cadastrar Conta a Receber'
      onClickReturn={onReturnToDashboard}
    />
  )
}
