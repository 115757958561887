/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTranslatedScheduledDescription } from 'Shared/language/hooks/useTranslatedScheduleDescription'

import {
  parseTemplateString,
  parseTemplateStringWithReactComponents,
  pluralize
} from 'Shared/language/utils/dictionaryUtils'

const ReleasesHighlight = ({ releases, text, className }) => {
  return (
    <p className={className}>
      <span className="highlight">{releases}</span>
      {text}
    </p>
  )
}

export const BankStatementsTranslator = (
  dictionary,
  locale
) => {
  const buildText = ({ releases, dictionaryElement, className }) => {
    if (!releases || releases === 0) return

    return parseTemplateStringWithReactComponents(
      dictionaryElement,
      {
        RELEASES: substitution => ReleasesHighlight(
          {
            releases,
            text: pluralize(substitution, releases, locale),
            className
          }
        )
      }
    )
  }

  const importedReleases = (releases) => buildText(
    { releases, dictionaryElement: dictionary?.bank_statement_imported_releases }
  )

  const transactionsReleases = (releases) => buildText(
    { releases, dictionaryElement: dictionary?.bank_statement_transactions_releases }
  )

  const ignoredCurrentDateTransactionsReleases = (releases) => buildText(
    {
      releases,
      dictionaryElement: dictionary?.bank_statement_ignored_current_date_transactions_releases,
      className: 'import_review_transaction_ignoreds'
    }
  )

  const alreadyImportedReleases = (releases) => buildText(
    {
      releases,
      dictionaryElement: dictionary?.bank_statement_already_imported_releases
    }
  )

  const notImportedReleases = (releases) => buildText(
    {
      releases,
      dictionaryElement: dictionary.bank_statement_not_imported_releases,
      className: 'import_review_transaction_ignoreds'
    }
  )

  const dateLabel = (date) => parseTemplateString(
    dictionary.bank_statement_date_releases,
    {
      DATE: date.format(dictionary?.common_dateFormat_template)
    }
  )

  const importingBankStatements = dictionary?.bank_statement_importing_bank_statements_label
  const bankAccount = dictionary?.bank_statement_bank_account_label
  const acceptedFileFormats = dictionary?.bank_statement_accepted_file_formats_label
  const statementImportCompleted = dictionary?.bank_statement_statement_import_completed_label
  const latestImportedExtracts = dictionary?.bank_statement_latest_imported_extracts_label
  const waitingBankStatementImported = dictionary?.bank_statement_waiting_bank_statement_imported_label
  const details = dictionary?.bank_statement_details_label
  const selectAccountLabel = dictionary?.bank_statement_select_account_label
  const performImport = dictionary?.bank_statement_performImport_label

  const descriptionTranslator = useTranslatedScheduledDescription({ dictionary })

  return {
    importedReleases,
    transactionsReleases,
    ignoredCurrentDateTransactionsReleases,
    alreadyImportedReleases,
    notImportedReleases,
    dateLabel,
    importingBankStatements,
    bankAccount,
    acceptedFileFormats,
    statementImportCompleted,
    latestImportedExtracts,
    waitingBankStatementImported,
    details,
    selectAccountLabel,
    performImport,
    descriptionTranslator
  }
}
