import { useState } from 'react'
import { BankAccountService } from 'Core/Service/BankAccount'
import { useToast } from 'Shared/hooks/useToast'
import { useUser } from 'Shared/contexts/User'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankAccountsHooksTranslator } from '../i18n/BankAccountsHooksI18n'

export const useEditBankAccount = (fetchBankAccounts) => {
  const [loading, setLoading] = useState(false)
  const [bankAccount, setBankAccount] = useState()
  const { addToast } = useToast()
  const { user } = useUser()

  const i18n = useTranslator(BankAccountsHooksTranslator)

  const fetchBankAccount = (bankAccountId) => {
    setLoading(true)
    BankAccountService.getById(bankAccountId)
      .then(setBankAccount)
      .catch(errors => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorLoadingBankAccount
          }
        )
      })
      .finally(() => setLoading(false))
  }

  const onEditBankAccount = async (bankAccountData) => {
    const { id: bankAccountId } = bankAccountData
    setLoading(true)
    return BankAccountService.edit(
      bankAccountData,
      bankAccountId,
      user
    )
      .then(() => {
        addToast(
          {
            defaultMessage: i18n?.bankAccountHasBeenUpdatedSuccessfully,
            typeToast: 'success'
          }
        )
        fetchBankAccounts()
      }
      )
      .catch(errors => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorUpdatingBankAccount
          }
        )
      })
      .finally(() => setLoading(false))
  }

  return {
    bankAccount,
    onEditBankAccount,
    fetchBankAccount,
    loading,
    setBankAccount
  }
}
