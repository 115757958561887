import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const ConciliationsFiltersContainer = styled.div`
  * {
    box-sizing: border-box;
  }

  .form__inputs {
    display: flex;
    align-items: end;
    width: 100%;
    gap: 1.25rem;
    background-color: var(--clr-gray7);
    padding: 1.25rem;
    border-radius: 0.625rem;
    margin-bottom: 1.875rem;

    .input__start_date,
    .input__end_date  {
      width: 100%;
    }

    .conciliations_filters__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--clr-primary);
      font-weight: 700;
      width: 100%;
      height: 2.5rem;
      padding: 1.25rem;
      border-radius: var(--round-element-radius, 1.25rem);
      border: 1px solid var(--clr-primary);
    }
  }

  @media(max-width: ${BREAK_POINTS.smallTablet}) {
    .form__inputs {
      flex-direction: column;
    }
  }
`
