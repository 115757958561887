import { SafeDate } from '@binamik/js-providers'

export const checkIfIsPastDate = (
  value: string | Date
) => {
  if (!value) {
    return true
  }
  const date = new SafeDate(value)
  const currentDate = new SafeDate().toStartOf('day').jsDate

  return date.isBefore(currentDate)
}
