/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, GhostButton, Modal, LoadingAnimation, BankCardIcon } from '@bonitour/components'
import { cardIcon, loadingContainer } from 'Shared/styles/global'
import { SupplierFormButtons, SupplierFormContainer } from './SupplierModal.style'
import { MenuTabsPanel } from 'Shared/components/MenuTabs/MenuTabsPanel'
import { useCallback, useMemo } from 'react'
import { useInputsFormSuppliers } from '../hooks/useInputsFormSuppliers'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { SupplierBankAccountsList } from '../SupplierBankAccount/List/SupplierBankAccountsList'
import { CardButton } from 'Shared/components/CardButton'
import { cardButtonStyle, labelStyle } from '../Supplier.styles'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

/**
 * @typedef SupplierModalProps
 * @prop { () => void} onCreateSupplier
 * @prop { () => void} onEditSupplier
 * @prop { boolean } isVisible
 * @prop { () => void} toggleHidden
 * @prop { Record<string, string> } i18n
 * @prop { Record<string, string> } supplierData
 * @prop { boolean } loading
 */
/** @type { React.FC<SupplierModalProps> } */
export const SupplierModal = ({
  onCreateSupplier,
  isVisible = false,
  toggleHidden,
  i18n,
  loading = false,
  supplierData,
  onEditSupplier
}) => {
  const isEditing = Boolean(supplierData)

  const {
    basicRegistrationFieldsInputs,
    addressFieldsInputs,
    contactDetailsFieldsInputs,
    form,
    onSubmit,
    onInputChange,
    onInputBlur,
    formErrorsTranslated
  } = useInputsFormSuppliers({ i18n, supplierData, isEditing })

  const handleSubmit = useCallback((formData) => {
    if (supplierData) {
      onEditSupplier(formData)
    } else {
      onCreateSupplier(formData)
    }
    toggleHidden()
  }, [
    onCreateSupplier,
    onEditSupplier,
    supplierData,
    toggleHidden
  ])

  const tabs = useMemo(() => {
    const baseTabs = [
      {
        id: 'contact-details',
        component: (
          <SupplierContactDetailsForm
            formData={form} onInputChange={onInputChange}
            onInputBlur={onInputBlur} contactDetailsFieldsInputs={contactDetailsFieldsInputs} errors={formErrorsTranslated}
          />
        ),
        label: i18n.contactDetailsMenuTitle
      },
      {
        id: 'address',
        component: (
          <SupplierAddressForm
            formData={form} onInputChange={onInputChange}
            onInputBlur={onInputBlur} addressFieldsInputs={addressFieldsInputs} errors={formErrorsTranslated}
          />
        ),
        label: i18n.addressMenuTitle
      }
    ]

    if (form?.id) {
      baseTabs.push({
        id: 'bank-accounts',
        component: (
          <SupplierBankAccountsModalList supplierId={form?.id} isModalView={true} buttonLabel={i18n.listSupplierBankAccountsLabel} />
        ),
        label: i18n.bankAccountMenuTitle
      })
    }

    return baseTabs
  }, [
    form,
    onInputChange,
    onInputBlur,
    contactDetailsFieldsInputs,
    formErrorsTranslated,
    i18n.contactDetailsMenuTitle,
    i18n.addressMenuTitle,
    i18n.listSupplierBankAccountsLabel,
    i18n.bankAccountMenuTitle,
    addressFieldsInputs]
  )

  return (
    <Modal
      title={loading ? '' : !isEditing ? i18n.modalNewSupplierTitle : i18n.modalEditSupplierTitle}
      isVisible={isVisible}
      onCloseClick={toggleHidden}
    >
      {loading
        ? (
          <LoadingAnimation css={loadingContainer} />
        )
        : (
          <>
            <SupplierFormContainer>
              <FormsInputs
                inputs={basicRegistrationFieldsInputs}
                formErrors={formErrorsTranslated}
                formValues={form}
                onInputChange={onInputChange}
                onInputBlur={onInputBlur}
              />
            </SupplierFormContainer>
            <MenuTabsPanel
              tabs={tabs}
              initTab='contact-details'
            />
            <SupplierFormButtons>
              <GhostButton onClick={toggleHidden}>
                {i18n.cancelButton}
              </GhostButton>
              <Button
                type='button'
                disabled={loading}
                onClick={onSubmit(handleSubmit)}
              >
                {!isEditing ? i18n.createButton : i18n.editButton}
              </Button>
            </SupplierFormButtons>
          </>
        )}
    </Modal>
  )
}

/**
 * @typedef SupplierContactDetailsFormProps
 * @prop { Record<string, string> } formData
 * @prop { () => void} onInputChange
 * @prop { () => void} onInputBlur
 * @prop { Record<string, string> } contactDetailsFieldsInputs
 * @prop { Record<string, string> } errors
 */
/** @type { React.FC<SupplierContactDetailsFormProps> } */
const SupplierContactDetailsForm = ({
  formData, onInputChange, onInputBlur, contactDetailsFieldsInputs, errors
}) => {
  return (
    <>
      <SupplierFormContainer>
        <FormsInputs
          inputs={contactDetailsFieldsInputs}
          formErrors={errors}
          formValues={formData}
          onInputChange={onInputChange}
          onInputBlur={onInputBlur}
        />
      </SupplierFormContainer>
    </>
  )
}

/**
 * @typedef SupplierAddressFormProps
 * @prop { Record<string, string> } formData
 * @prop { () => void} onInputChange
 * @prop { () => void} onInputBlur
 * @prop { Record<string, string> } addressFieldsInputs
 * @prop { Record<string, string> } errors
 */
/** @type { React.FC<SupplierAddressFormProps> } */
const SupplierAddressForm = ({
  formData, onInputChange, onInputBlur, addressFieldsInputs, errors
}) => {
  return (
    <>
      <SupplierFormContainer>
        <FormsInputs
          inputs={addressFieldsInputs}
          formErrors={errors}
          formValues={formData}
          onInputChange={onInputChange}
          onInputBlur={onInputBlur}
        />
      </SupplierFormContainer>
    </>
  )
}

const SupplierBankAccountsModalList = ({
  supplierId, isModalView, buttonLabel
}) => {
  const { push } = useHistory()

  const goToListSupplierBankAccounts = useCallback(
    (supplierId) => push(`/expense-revenue-dashboard/suppliers/${supplierId}/supplier-bank-accounts`), [push]
  )
  return (
    <>
      <CardButton css={cardButtonStyle} onClick={() => { goToListSupplierBankAccounts(supplierId) }}>
        <BankCardIcon css={cardIcon} />
        <span css={labelStyle}>{buttonLabel}</span>
      </CardButton>
      <SupplierBankAccountsList supplierId={supplierId} isModalView={isModalView} />
    </>
  )
}
