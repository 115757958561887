import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'
import { EventsProcessorsService } from 'Core/Service/eventsProcessors'

export const useGetAutomationRulesSimulations = () => {
  const [loading, setLoading] = useState(false)
  const [automationRulesSimulationsData, setAutomationRulesSimulationsData] = useState()
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const getAutomationRulesSimulations = useCallback((eventProcessorId, matchedEventId) => {
    setLoading(true)
    return EventsProcessorsService.getAutomationRulesSimulations(eventProcessorId, matchedEventId)
      .then(setAutomationRulesSimulationsData)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorWhenFetchingSimulationsAutomationRules
        })
      })
      .finally(() => setLoading(false))
  }, [
    addToast,
    i18n?.errorWhenFetchingSimulationsAutomationRules
  ])

  return {
    getAutomationRulesSimulations,
    loading,
    automationRulesSimulationsData
  }
}
