import styled from '@emotion/styled'

export const EventProcessorAutomationRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  .safe_overflow__container {
    max-width: 15rem;
  }

  .border {
    padding: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid var(--clr-gray8);
  }

  .container__table {
    max-height: 18.75rem;
    overflow: auto;

    thead {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
    }

    .operation_type {
      white-space: normal;
    }
  }

  .wrapper_btns {
    display: flex;
    gap: 1.25rem;
  }
`
