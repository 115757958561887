import { endDateMustBeAfterOrEqualStartDate } from 'Shared/utils/yup/date/endDateMustBeAfterOrEqualStartDate'
import { startDateMustBeLessThanOrEqualEndDate } from 'Shared/utils/yup/date/startDateMustBeLessThanOrEqualEndDate'

import {
  date as yupDate,
  string as yupString,
  array as yupArray
} from 'yup'

const startDate = {
  label: 'Data inicial',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data inicial',
  className: 'start_date',
  value: 'startDate',
  type: 'date',
  schema: yupDate()
    .typeError('dateFormat')
    .required('dateRequired')
    .test(
      'startDateMustBeLessThanOrEqualEndDate',
      'startDateMustBeLessThanOrEqualEndDate',
      startDateMustBeLessThanOrEqualEndDate
    )
}

const endDate = {
  label: 'Data final',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data final',
  className: 'end_date',
  value: 'endDate',
  type: 'date',
  schema: yupDate()
    .typeError('dateFormat')
    .required('dateRequired')
    .test(
      'endDateMustBeAfterOrEqualStartDate',
      'endDateMustBeAfterOrEqualStartDate',
      endDateMustBeAfterOrEqualStartDate
    )
}

const status = {
  label: 'Status',
  placeholder: 'Selecione os status',
  className: 'status',
  value: 'status',
  type: 'multiSelect',
  withFilter: false,
  hasSelectAll: true,
  schema: yupArray().of(yupString())
}

const destinationBankAccount = (bankAccountsOptions = []) => ({
  label: 'Conta de Destino',
  placeholder: 'Selecione a conta',
  className: 'destination_bank_account',
  value: 'destinationBankAccountId',
  type: 'select',
  options: bankAccountsOptions,
  schema: yupString().optional()
})

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  startDate,
  endDate,
  status,
  destinationBankAccount,
  getSchema
}
