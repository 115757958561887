import { useState } from 'react'
import { ExpenseService } from 'Core/Service/expense'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesHooksTranslator } from '../i18n/ExpensesHooksI18n'
import { UpdateBatchExpenseReqBody } from 'Core/Types/Expense'
import { BackendErrorFormat } from 'Core/Types/Error'

export const useUpdateBatchExpense = () => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(ExpensesHooksTranslator)

  const onUpdateBatchExpenses = async (updateBatchExpenseReqBody: UpdateBatchExpenseReqBody) => {
    setLoading(true)

    return ExpenseService.updateExpenses(
      updateBatchExpenseReqBody
    )
      .then(() => {
        addToast(
          {
            defaultMessage: i18n?.accountsPayableUpdatedSuccessfully,
            typeToast: 'success'
          }
        )
        return true
      })
      .catch((errors: BackendErrorFormat) => {
        console.error(errors?.data)
        addToast(
          {
            dictTranslation: i18n?.apiCodesMappingForExpense,
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorUpdatingAccountsPayable
          }
        )
        return false
      })
      .finally(() => setLoading(false))
  }

  return {
    onUpdateBatchExpenses,
    loading
  }
}
