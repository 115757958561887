/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { SupplierFilterContainer } from './SupplierFilter.styles'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { useInputsFilterSuppliers } from '../hooks/useInputsFilterSuppliers'

/**
 * @typedef SupplierFilterProps
 * @prop { (filters: Record<string, string>) => void } onFilterSubmit
 * @prop { Record<string, string> } filters
 * @prop { boolean } isLoading
 * @prop { Array<Record<string, string>> } enabledOptions
 * @prop { string } buttonLabel
 */
/** @type { React.FC<SupplierFilterProps> } */
export const SupplierFilter = (
  {
    onFilterSubmit,
    filters,
    isLoading,
    enabledOptions,
    buttonLabel
  }
) => {
  const {
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useInputsFilterSuppliers(
    filters,
    enabledOptions
  )

  return (
    <SupplierFilterContainer>
      <FormsInputs
        inputs={inputsValid}
        formErrors={{}}
        formValues={form}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      >
        <button
          type='button'
          disabled={!!isLoading}
          onClick={onSubmit(onFilterSubmit)}
          className='suppliers_filters__btn'
        >
          {buttonLabel}
        </button>
      </FormsInputs>
    </SupplierFilterContainer>
  )
}
