import { billingCore } from './Billing'
import { ExpenseModel } from '../Models/expense'
import { ExpenseParser } from 'Core/Parser/expense'
import { convertToFormData } from '@bonitour/common-functions'
import {
  ExpenseAllowedStatus,
  ExpenseParsed,
  GetExpensesParams,
  GetExpensesParsed,
  PayloadCreateExpense,
  PayloadUpdateExpense,
  SimulateRecurrenceParams,
  GetSimulateRecurrenceExpenseRes,
  GetExpensesForgottenResParsed,
  ExpenseAuditsResParsed,
  Expense,
  ExportExpensesParams,
  UpdateBatchExpenseReqBody
} from 'Core/Types/Expense'
import { User } from 'Core/Types/User'
import { AxiosResponse } from 'axios'

export const ExpenseService = {
  list: async ({ filters, pagination }: GetExpensesParams): Promise<GetExpensesParsed> => {
    return await billingCore.expenseDomain.list(ExpenseModel.list({ filters, pagination })).then(ExpenseParser.list)
  },
  create: async (expense: PayloadCreateExpense, user: User) => {
    const payload = ExpenseModel.create(expense, user)
    return await billingCore.expenseDomain.create(convertToFormData(payload))
  },
  getById: async (expenseId: string): Promise<ExpenseParsed> => {
    return await billingCore.expenseDomain.get(expenseId).then(ExpenseParser.singleExpense)
  },
  edit: async (
    expense: PayloadUpdateExpense,
    expenseId: string,
    currentExpenseData: ExpenseParsed,
    user: User
  ) => {
    const payload = ExpenseModel.update(expense, currentExpenseData, user)
    return await billingCore.expenseDomain.update(convertToFormData(payload), expenseId)
  },
  alloweStatus: async (expenseId: string): Promise<ExpenseAllowedStatus[]> => {
    return await billingCore.expenseDomain.getAllowedStatus(expenseId)
  },
  getDownloadReceipt: async (expenseId: string): Promise<AxiosResponse<ArrayBuffer>> => {
    return await billingCore.expenseDomain.getDownloadReceipt(expenseId)
  },
  getSimulateRecurrenceExpense: async (params: SimulateRecurrenceParams): Promise<GetSimulateRecurrenceExpenseRes> => {
    return await billingCore.expenseDomain.getSimulateRecurrenceExpense(
      ExpenseModel.getSimulateRecurrenceExpense(params)
    ).then(ExpenseParser.getSimulateRecurrenceExpense)
  },
  getExpensesForgotten: async (): Promise<GetExpensesForgottenResParsed> => {
    return await billingCore.expenseDomain.getExpensesForgotten().then(
      ExpenseParser.getExpensesForgotten
    )
  },
  deleteReceipts: async (receiptsIds: string[], expenseId: string): Promise<void> => {
    const payload = ExpenseModel.deleteReceipts(receiptsIds)
    return await billingCore.expenseDomain.deleteReceipts(payload, expenseId)
  },
  getExpenseAudits: async (expenseId: string): Promise<ExpenseAuditsResParsed> => {
    return await billingCore.expenseDomain.getExpenseAudits({}, expenseId).then(
      ExpenseParser.getExpenseAudits
    )
  },
  exportExpenses: async ({ filters, i18n }: ExportExpensesParams) => {
    return await billingCore.expenseDomain.list(
      ExpenseModel.export({ filters })
    ).then(
      ({ expenses }: { expenses: Expense[] }) => ExpenseParser.exportCsvExpenses(expenses, i18n, filters)
    )
  },
  updateExpenses: async (updateBatchExpenseReqBody: UpdateBatchExpenseReqBody) => {
    return await billingCore.expenseDomain.updateExpenses(
      convertToFormData(ExpenseModel.updateExpenses(updateBatchExpenseReqBody))
    )
  }
}
