import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const EventsProcessorsFiltersContainer = styled.div`
  .form__inputs {
    display: flex;
    width: 100%;
    gap: 1.25rem;
    background-color: var(--clr-gray8);
    padding: 1.25rem;
    border-radius: 0.625rem;
    align-items: end;
    margin-bottom: 1.875rem;
  }

  @media(max-width: ${BREAK_POINTS.smallTablet}) {
    .form__inputs {
      flex-direction: column;
    }
  }
`

export const CreateEditEventProcessorContainer = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;

  .form__inputs {
    display: flex;
    width: 100%;
    gap: 1.25rem;
    flex-wrap: wrap;
  }
`
