/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo } from 'react'
import {
  MovementsCardContainer
} from './MovementsCard.styles'
import { ValuesCard } from 'Shared/components/ValuesCard/ValuesCard'

/**
 * @typedef MovementsCardProps
 * @prop { { totalReceivable: number, totalToPay: number, balance?: number } } balanceMoves
 * @prop { Record<string, string> } [customText]
 */

/** @type { React.FC<MovementsCardProps> } */
export const MovementsCard = ({
  balanceMoves,
  customText = {}
}) => {
  // TODO: Default values will be removed in the cards financial agenda refactoring task
  const valuesCardOptions = useMemo(() => {
    return [
      {
        text: customText?.totalReceivable || 'Valor bruto',
        value: balanceMoves.totalReceivable
      },
      {
        text: customText?.totalToPay || 'Taxas totais',
        value: balanceMoves.totalToPay
      },
      {
        text: customText?.NetValue || 'Valor líquido',
        value: balanceMoves.balance
      }
    ]
  }, [
    balanceMoves.balance,
    balanceMoves.totalReceivable,
    balanceMoves.totalToPay,
    customText
  ])

  return (
    <MovementsCardContainer>
      <h3>Totalizadores</h3>
      <ValuesCard
        valuesCardOptions={valuesCardOptions}
      />
    </MovementsCardContainer>
  )
}
