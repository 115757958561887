import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const stylishCardSearch = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border: none;
  background-color: ${colors.gray8};
  padding: 20px;
`

export const wrapColums = css`
  flex-wrap: wrap;
`

export const button = css`
  margin-top: 20px;
  width: 100%;
`
