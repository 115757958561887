/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsTranslator } from './i18n/EventsI18n'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'
import { EventsBody } from './EventsBody'

export const Events = () => {
  const { push } = useHistory()

  const i18n = useTranslator(EventsTranslator)

  const onReturnToDashboard = () => push('/dashboard')

  useFlaggedRoute('billing-events')

  return (
    <>
      <PageTitle
        title={i18n?.pageTitleEvents}
        onClickReturn={onReturnToDashboard}
      />
      <EventsBody />
    </>
  )
}
