import { useCallback } from 'react'
import { useToast as componentsUseToast } from '@bonitour/components'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ToastTranslator } from './i18n/ToastI18n'

export const useToast = () => {
  const { add } = componentsUseToast()

  const { billingApiCodesMappingCommons } = useTranslator(ToastTranslator)

  const addToast = useCallback(({
    msgs = [],
    typeToast = 'danger',
    defaultMessage = '',
    dictTranslation = {},
    extra_data = null
  }) => {
    const msgsArray = Array.isArray(msgs)
      ? msgs?.length ? msgs : [defaultMessage]
      : [msgs || defaultMessage]

    const translatedMsgs = msgsArray.map(
      msg => billingApiCodesMappingCommons[msg] || msg
    ).map(msg => {
      if (typeof dictTranslation[msg] === 'function') {
        if (typeof extra_data === 'number') {
          return dictTranslation[msg](extra_data)
        }
      }

      return dictTranslation[msg] || msg
    })

    translatedMsgs.forEach(msgToast => {
      add(msgToast, typeToast)
    })
  }, [add, billingApiCodesMappingCommons])

  return {
    addToast
  }
}
