import React from 'react'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { useBankTransfersFilters } from '../hooks/inputs/useBankTransfersFilters'
import { BankTransfersFiltersContainer } from './BankTransfersFilters.styles'
import { BankTransfersCommonParams } from 'Core/Types/BankTransfer'
import { Button } from '@bonitour/components'

interface BankTransfersFiltersProps {
  isLoading: boolean
  handleFiltersChange: (filters: BankTransfersCommonParams) => void
  filters: BankTransfersCommonParams
  buttonLabel: string
}

export const BankTransfersFilters = ({
  isLoading,
  handleFiltersChange,
  filters,
  buttonLabel
}: BankTransfersFiltersProps): React.ReactElement => {
  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useBankTransfersFilters(
    filters
  )

  return (
    <BankTransfersFiltersContainer>
      <FormsInputs
        inputs={inputsValid}
        formValues={form}
        formErrors={errors}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      >
        <Button
          type='button'
          disabled={!!isLoading}
          onClick={onSubmit(handleFiltersChange)}
        >
          {buttonLabel}
        </Button>
      </FormsInputs>
    </BankTransfersFiltersContainer>
  )
}
