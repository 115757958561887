import styled from '@emotion/styled'

export const MenuTabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: 0.9375rem;
  overflow: hidden;

  color: var(--clr-gray3);

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  nav,
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }

  .menu_tab_nav {
    width: 100%;
    padding-bottom: 0.625rem;
    border-bottom: 3px solid var(--clr-gray7);

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    ul {
      width: max-content;
    }
  }

  a, button {
    color: inherit;
    font-weight: 700;
    font-size: 1.125rem;
    text-decoration: none;
    white-space: nowrap;
  }

  li {
    position: relative;
    transition: color 200ms ease-in-out;
  }

  li.selected {
    color: var(--clr-primary);
  }

  li:hover {
    color: var(--clr-primary);
  }

  .underline {
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 100vw;
    background-color: var(--clr-primary);
    bottom: -0.75rem;
    left: 0;
  }

  .wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .items {
    color: var(--clr-gray3);
    font-weight: 700;
  }

  .items.selected {
    color: var(--clr-primary);
  }
`
