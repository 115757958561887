import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const PageCreateEditEventProcessorContainer = styled.div`
  .section__header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-direction: row;
  }

  .load {
    width: 100%;
    min-height: 3.75rem;
  }

  .container__title {
    color: var(--clr-gray3);
    font-weight: 700;
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
  }

  .container__subtitle {
    color: var(--clr-gray3);
    font-weight: 600;
    font-size: 0.875rem;
  }

`

export const customToggle = css`
  border: 1px solid var(--clr-gray8);
  border-radius: 0.625rem;
  padding: 0.625rem;
  height: 2.5rem;
  background-color: var(--clr-white);
  margin: 1.625rem 0px 1.75rem 0;

  label {
    margin: 0;
    width: 2.875rem;
  }

`
