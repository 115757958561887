import { css } from '@emotion/core'

export const batchExpenseUpdateModalContainer = css`
  
  .batch_expense_update_load {
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: var(--clr-gray11);
    opacity: 0.5;
    border-radius: 1rem;
  }

  .batch_expense_update_hidden {
    display: none;
  }
`
