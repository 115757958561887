/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { string as yupString } from 'yup'

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

const bankName = {
  label: 'Banco',
  className: 'bank_name',
  value: 'bankName',
  type: 'text',
  schema: yupString(),
  disabled: true,
  typeWrapper: 'inputFormGroup'
}

const branchNumber = {
  label: 'Agência',
  className: 'branch_number',
  value: 'branchNumber',
  type: 'text',
  schema: yupString(),
  disabled: true,
  typeWrapper: 'inputFormGroup'
}

const accountNumber = {
  label: 'Conta',
  className: 'account_number',
  value: 'accountNumber',
  type: 'text',
  schema: yupString(),
  disabled: true,
  typeWrapper: 'inputFormGroup'
}

const enabled = (enabledOptions = []) => ({
  label: 'Estado',
  className: 'enabled',
  value: 'enabled',
  type: 'select',
  options: enabledOptions,
  schema: yupString(),
  disabled: true
})

const accountablePlans = (accountablePlansOptions = []) => ({
  label: 'Plano de Conta',
  className: 'accountable_plan',
  value: 'accountablePlanId',
  placeholder: 'Selecione a conta',
  type: 'select',
  options: accountablePlansOptions,
  schema: yupString().optional().nullable()
})

export {
  getSchema,
  bankName,
  branchNumber,
  accountNumber,
  enabled,
  accountablePlans
}
