import { css } from '@emotion/core'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const sectionValues = (numberColumns) => css`
  display: grid;
  grid-template-columns: repeat(${numberColumns}, 1fr);
  gap: 1.25rem;

  * {
    box-sizing: border-box;
  }

  .values_card__wrapper {
    border-radius: 0.625rem;
    background-color: var(--clr-gray14);
    padding: 1.25rem 1rem;
    border: 1px solid var(--clr-gray8);
    min-width: 14.75rem;
    width: 100%;
    height: 100%;
    white-space: nowrap;

    p {
      line-height: 1.5625rem;
      
      &.values_card__wrapper_text {
        font-size: 1rem;
        color: var(--clr-gray2)
      }

      &.values_card__wrapper_value {
        font-weight: 700;
        font-size: 1.2rem;
        color: var(--clr-gray1);
      }
    }
  }

  @media(max-width: ${BREAK_POINTS.tablet}) {
    grid-template-columns: 1fr;
    width: 100%;
  }

`
