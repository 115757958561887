import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const stylishCardSearch = css`
  border: none;
  background-color: ${colors.gray8};
  margin-bottom: 1em;
`

export const width100 = css`
  width: 100%;
`

export const buttonStyle = css`
  height: 40px;
  width: 100%;
  margin-top: auto;
`
