import React from 'react'
import { Base } from './Base'
import { Router } from './Router'
import { BrowserRouter } from 'react-router-dom'

import { routes } from 'Routes'
import { ErrorBoundary } from '@sentry/react'
import { ExternalIntegrations } from 'Shared/external/Integrations'

ExternalIntegrations()

export const App = (
  <ErrorBoundary>
    <BrowserRouter>
      <Base>
        <Router routes={routes} />
      </Base>
    </BrowserRouter>
  </ErrorBoundary>
)
