import { SafeDate } from '@binamik/js-providers'
import { MetaParser } from './Meta'
import { formatMoney } from '@binamik/js-functions'
import { csvGenerator } from 'Shared/utils/generatorCsv'

export const FullFinancialScheduleParser = {
  single: (data) => {
    const {
      id,
      date,
      reference_date,
      value,
      operation_type,
      origin_type,
      billing_account_id,
      installment,
      info,
      status,
      conciliated
    } = data?.flow_movements ?? data ?? {}

    const safePartnerName = Array.isArray(info?.partner_name) && info?.partner_name.length ? info?.partner_name.join(',') : (info?.partner_name || '')

    return {
      id,
      date: date ? new SafeDate(date) : '',
      referenceDate: reference_date || '',
      value: formatMoney(value) || 0,
      operationType: operation_type || 'error',
      originType: origin_type || '',
      billingAccountId: billing_account_id || '',
      installment: installment || 0,
      payFee: info.pay_fee || '',
      eventDate: info.event_date || '',
      cardNumber: info.card_number || '',
      clientName: info.client_name || '',
      partnerName: safePartnerName,
      antifraudFee: info.antifraud_fee || '',
      transactionFee: info.transaction_fee || '',
      reservationCode: info.reservation_code || '',
      brand: info?.brand || '',
      gateway: info?.gateway || '',
      mdrFee: info?.mdr_fee || 0,
      netAmount: info?.net_amount || 0,
      installments: info?.installments || 0,
      grossAmount: info?.gross_amount || 0,
      paymentMethod: info?.payment_method || '',
      proofOfSale: info?.proof_of_sale || '',
      authorizationCode: info?.authorization_code || '',
      creditcardOperationType: info?.creditcard_operation_type || '',
      bankName: info?.bank_name || '',
      ticketCode: info?.ticket_code || '',
      activityName: info?.activity_name || '',
      invoiceNumber: info?.invoice_number || '0',
      transactionType: info?.transaction_type || '',
      userName: info?.user_name || '',
      operatorName: info?.operator_name || '',
      expenseRevenueDescription: info?.expense_revenue_description || '',
      serviceName: info?.service_name || '',
      tipo: info?.tipo || '',
      infoStatus: info?.status || '',
      infoOperationType: info?.operation_type || '',
      description: info.description || '',
      status: status || 'error',
      conciliated: conciliated || '',
      label: info?.label || '',
      pos: info?.pos || '',
      originBankName: info?.origin_bank_name || '',
      destinationBankName: info?.destination_bank_name || ''
    }
  },
  getListFlowMovements: (data) => {
    const dataParser = data.flow_movements || []
    const metaParser = data.meta || {}

    return {
      data: dataParser.map(FullFinancialScheduleParser.single),
      meta: MetaParser.meta(metaParser)
    }
  },
  exportCsvMovements: (data, i18n) => {
    const flowMovements = data.flow_movements || []

    const flowMovementsParsed = flowMovements?.map(
      (
        flowMovement
      ) => {
        const safeDate = flowMovement?.date ? new SafeDate(flowMovement?.date).format(i18n.dateFormat) : '-'
        const safeValue = formatMoney(flowMovement?.value) || 0
        const safeConciliated = flowMovement?.conciliated ? i18n?.conciliate : i18n?.notConciliate
        const flowMovementParsed = FullFinancialScheduleParser.single(flowMovement)

        return {
          date: safeDate,
          description: i18n?.descriptionTranslator(flowMovementParsed),
          originType: i18n?.originTypeTranslator(flowMovement?.origin_type),
          value: safeValue,
          operationType: i18n?.flowMovementsOperationType[flowMovement?.operation_type || 'error'],
          status: i18n?.flowMovementsStatus[flowMovement?.status || 'error'],
          conciliated: safeConciliated
        }
      }
    )

    return csvGenerator(flowMovementsParsed, { headers: i18n.csvHeadersFlowMovements })
  }
}
