/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, GhostButton, Button, Select, Row, CheckboxInputGroup, Input, Column, useToast, HorizontalInputFormGroup } from '@bonitour/components'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useParams, useHistory } from 'react-router-dom'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { planTypeOptions } from '../Constants/CountPlan'
import { useForm } from '@bonitour/app-functions'
import { validationSchema } from './CountPlanForm.schema'
import { inputCard, marginBottom20, marginLeft10, inputStyle, containerFooterButtons, containerCreateButton, group } from './CountPlanForm.style'

export const CountPlanForm = ({ countPlan, submit }) => {
  const { id } = useParams()
  const { add } = useToast()
  const { push } = useHistory()
  const [isActiveSelected, setActiveSelected] = useState(true)

  const toggleActiveSelected = () => setActiveSelected(!isActiveSelected)
  const returnToCountPlan = () => push('/accounting-function/count-plan')

  useEffect(() => {
    setActiveSelected(countPlan?.enabled)
  }, [countPlan])

  const countPlanBase = useMemo(
    () =>
      countPlan || {
        code: '',
        name: '',
        planType: '',
        enabled: true,
        classification: ''
      },
    [countPlan]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(countPlanBase, validationSchema)

  const {
    code = countPlanBase.code,
    name = countPlanBase.name,
    planType = countPlanBase.planType,
    enabled = countPlanBase.enabled,
    classification = countPlanBase.classification
  } = form

  const onValidationError = useCallback(() => {
    add('Preencha corretamente o formulário')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onValidationSuccess = useCallback((data) => {
    submit(data)
      .then(() => {
        returnToCountPlan()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = useMemo(() => onSubmit(onValidationSuccess, onValidationError), [
    onSubmit,
    onValidationError,
    onValidationSuccess
  ])

  const handleEnabledSelected = useCallback(() => {
    onInputChange('enabled')(!isActiveSelected)
  }, [onInputChange, isActiveSelected])

  return (
    <>
      <PageTitle
        title={id ? 'Editar plano de Contas' : 'Criar Plano de Contas'}
        onClickReturn={returnToCountPlan}
      />
      <Card>
        <Row>
          <Column
            css={marginBottom20}
            desktop={6}
          >
            <Card css={inputCard}>
              <HorizontalInputFormGroup errorMessage={errors.code} label='Código' css={group}>
                <Input
                  type='number'
                  css={inputStyle}
                  value={code}
                  error={errors.code}
                  onChange={onInputChange('code')}
                  onBlur={onInputBlur('code')}
                />
              </HorizontalInputFormGroup>
            </Card>
          </Column>
          <Column css={marginBottom20} desktop={6}>
            <Card css={inputCard}>
              <HorizontalInputFormGroup errorMessage={errors.name} label='Nome' css={group}>
                <Input
                  css={inputStyle}
                  value={name}
                  error={errors.name}
                  onChange={onInputChange('name')}
                  onBlur={onInputBlur('name')}
                />
              </HorizontalInputFormGroup>
            </Card>
          </Column>
          <Column desktop={6}>
            <Card css={inputCard}>
              <HorizontalInputFormGroup errorMessage={errors.classification} label='Classificação' css={group}>
                <Input
                  css={inputStyle}
                  value={classification}
                  error={errors.classification}
                  onChange={onInputChange('classification')}
                  onBlur={onInputBlur('classification')}
                />
              </HorizontalInputFormGroup>
            </Card>
          </Column>
          <Column desktop={6}>
            <Card css={inputCard}>
              <HorizontalInputFormGroup errorMessage={errors.planType} label='Tipo' css={group}>
                <Select
                  css={inputStyle}
                  value={planType}
                  error={errors.planType}
                  onChange={onInputChange('planType')}
                  options={planTypeOptions}
                />
              </HorizontalInputFormGroup>
            </Card>
          </Column>
        </Row>
        <CheckboxInputGroup
          value={enabled}
          checked={isActiveSelected}
          onChange={handleEnabledSelected}
          onClick={toggleActiveSelected}
          css={marginLeft10}
        >
          Ativo
        </CheckboxInputGroup>
        <div css={containerFooterButtons}>
          <GhostButton onClick={returnToCountPlan}>Cancelar</GhostButton>
          <Button onClick={handleSubmit} css={containerCreateButton}>
            Salvar e sair
          </Button>
        </div>
      </Card>
    </>
  )
}
