import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsService } from 'Core/Service/eventsProcessors'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'

export const useDeleteEventProcessor = (fetchEventsProcessors) => {
  const { addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const onRemoveEventProcessor = useCallback((eventProcessorId) => {
    setIsLoading(true)
    return EventsProcessorsService.removeEventProcessor(eventProcessorId).then(
      () => {
        addToast({
          typeToast: 'success',
          defaultMessage: i18n?.successDeletedEventProcessor
        })
        fetchEventsProcessors()
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorRemoveEventProcessor
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }, [
    addToast,
    fetchEventsProcessors,
    i18n?.errorRemoveEventProcessor,
    i18n?.successDeletedEventProcessor
  ])

  return {
    onRemoveEventProcessor,
    isLoading
  }
}
