import { useCallback } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { SupplierService } from 'Core/Service/Supplier'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SuppliersHooksTranslator } from '../i18n/SuppliersHooksI18n'

export const useRemoveSupplier = (fetchSuppliers) => {
  const { addToast } = useToast()
  const i18n = useTranslator(SuppliersHooksTranslator)

  const removeSupplier = useCallback((supplierId) => {
    return SupplierService.remove(supplierId).then(() => {
      addToast({
        defaultMessage: i18n?.removeSupplier,
        typeToast: 'success'
      })
      fetchSuppliers()
    }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        dictTranslation: i18n?.apiCodesMappingForSupplier,
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorRemoveSupplier
      })
    }).finally(() => { })
  }, [addToast, fetchSuppliers, i18n])

  return {
    onRemoveSupplier: removeSupplier
  }
}
