import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const CashDepositFiltersContainer = styled.div`
  * {
    box-sizing: border-box;
  }

  .form__inputs {
    display: grid;
    align-items: end;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 1.25rem;
    background-color: var(--clr-gray12);
    padding: 1.25rem;
    border-radius: 0.625rem;
    margin-bottom: 1.875rem;

    .input__start_date,
    .input__end_date  {
      width: 100%;
    }
  }

  @media(max-width: ${BREAK_POINTS.tablet}) {
    grid-template-columns: repeat(1, 1fr);
  }
`
