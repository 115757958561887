import { SafeDate } from '@binamik/js-providers'
import {
  conciliationsAccountForecastsColumnsWidths,
  conciliationsGatewayPaymentsColumnsWidths,
  partialConciliationsAccountForecastsColumnsWidths,
  partialConciliationsGatewayPaymentsColumnsWidths
} from 'Domains/CreditCard/CreditCardConciliations/constants/creditCardConciliations'

export const CreditCardConciliationParser = {
  getCreditCardConciliations: (data, i18n) => {
    const conciliationsData = data?.table?.conciliated?.conciliations || []
    const partialConciliationsData = data.table?.conciliated?.partial_conciliations || []
    const notConciliationsData = data?.table?.not_conciliated || {}

    const conciliationsItems = data?.table?.conciliated?.conciliated_items || 0
    const partialConciliationsItems = data?.table?.conciliated?.partial_items || 0
    const notConciliationsItems = data?.table?.not_conciliated?.items || 0

    return {
      conciliationsItems,
      partialConciliationsItems,
      notConciliationsItems,
      conciliations: conciliationsData?.map(
        conciliation => CreditCardConciliationParser.single(
          {
            conciliation,
            i18n,
            externalMovementsColumnsWidths: conciliationsGatewayPaymentsColumnsWidths,
            internalMovementsColumnsWidths: conciliationsAccountForecastsColumnsWidths
          }
        )
      ),
      partialConciliations: partialConciliationsData?.map(
        conciliation => CreditCardConciliationParser.single(
          {
            conciliation,
            withValueDiference: true,
            i18n,
            internalMovementsColumnsWidths: partialConciliationsAccountForecastsColumnsWidths,
            externalMovementsColumnsWidths: partialConciliationsGatewayPaymentsColumnsWidths
          }
        )
      ),
      notConciliations: {
        gatewayPayments: notConciliationsData?.gateway_payments.map(
          gatewayPayment => CreditCardConciliationParser.gatewayPayment({ gatewayPayment, i18n })
        ),
        accountForecasts: notConciliationsData?.account_forecasts.map(
          accountForecast => CreditCardConciliationParser.accountForecast(
            {
              accountForecast,
              options: { align: 'right' },
              i18n
            }
          )
        )
      }
    }
  },
  single: (
    {
      conciliation,
      withValueDiference = false,
      i18n,
      externalMovementsColumnsWidths,
      internalMovementsColumnsWidths
    }
  ) => {
    const {
      id,
      date,
      user_name,
      value_diference,
      gateway_payments,
      account_forecasts,
      status_details,
      created_at
    } = conciliation ?? {}

    const safeStatusDetails = status_details || ''
    const regexStatusDetails = /divergent_value|divergent_date/g
    const hasShowStatusDetails = status_details !== 'ok'

    const safeDate = created_at ? new SafeDate(created_at) : new SafeDate()

    const dataArray = [
      {
        data: gateway_payments.map(gatewayPayment => CreditCardConciliationParser.gatewayPayment(
          { gatewayPayment, i18n }
        )),
        type: 'table',
        options: {
          colSpan: 4,
          arrayWidths: externalMovementsColumnsWidths
        }
      },
      {
        data: account_forecasts.map(accountForecast => CreditCardConciliationParser.accountForecast(
          {
            accountForecast,
            options: {
              align: 'right'
            },
            i18n
          }
        )),
        type: 'table',
        options: {
          colSpan: 5,
          arrayWidths: internalMovementsColumnsWidths
        }
      },
      {
        data: {
          userName: user_name,
          statusDetails: safeStatusDetails,
          createdAt: safeDate,
          regexStatusDetails
        },
        options: { hasShowStatusDetails },
        type: 'info'
      }
    ]

    if (withValueDiference) {
      dataArray.splice(1, 0, {
        data: value_diference,
        type: 'money',
        options: {
          removeValueNegative: true,
          safeOverflowClassName: 'safe_overflow_value_diference',
          classNameMoney: 'value_color__negative td_value_diference'
        }
      })
    }

    return {
      id,
      date,
      values: dataArray
    }
  },
  accountForecast: ({ accountForecast, options = {}, i18n }) => {
    const {
      id,
      date,
      value,
      info,
      installment,
      operation_type
    } = accountForecast ?? {}

    const reservationCode = info?.reservation_code || '-'
    const aut = (info?.authorization_code && info?.authorization_code !== '0') ? info?.authorization_code : '******'
    const doc = (info?.proof_of_sale && info?.proof_of_sale !== '0') ? info?.proof_of_sale : '******'

    const installments = info?.installments || ''

    const autDoc = `${aut}/${doc}`
    const parcel = installment && installments ? `${installment}/${installments}` : installment
    const brandParsed = `${i18n?.flagTranslator(info?.brand)} ${parcel}`

    const isDebit = operation_type?.includes('debit')
    const valueParsed = isDebit ? `-${value}` : value
    const classNameMoney = isDebit ? 'value_color__negative' : 'value_color__positive'

    return {
      id,
      values: [
        { data: date, type: 'date' },
        { data: autDoc, type: 'normal' },
        { data: reservationCode, type: 'safe-overflow', options },
        { data: brandParsed, type: 'safe-overflow', options },
        {
          data: valueParsed,
          type: 'money',
          options: {
            classNameMoney
          }
        }
      ]
    }
  },
  gatewayPayment: ({ gatewayPayment, i18n }) => {
    const {
      id,
      date,
      authorization_code,
      proof_of_sale,
      brand,
      installment,
      installments,
      net_amount,
      operation_type
    } = gatewayPayment ?? {}

    const aut = (authorization_code && authorization_code !== '0') ? authorization_code : '******'
    const doc = (proof_of_sale && proof_of_sale !== '0') ? proof_of_sale : '******'

    const autDoc = `${aut}/${doc}`

    const brandParsed = `${i18n?.flagTranslator(brand)} ${installment || '-'}/${installments || '-'}`

    const isDebit = operation_type?.includes('debit')
    const netAmount = isDebit ? `-${net_amount}` : net_amount
    const classNameMoney = isDebit ? 'value_color__negative' : 'value_color__positive'

    return {
      id,
      values: [
        { data: date, type: 'date' },
        { data: autDoc, type: 'normal' },
        { data: brandParsed, type: 'safe-overflow' },
        {
          data: netAmount,
          type: 'money',
          options: { classNameMoney }
        }
      ]
    }
  },
  postAutoCreditCardConciliation: ({ conciliated }) => {
    return conciliated
  },
  postManualCreditCardConciliation: ({ conciliation, i18n }) => {
    const isPartialConciliation = conciliation.status === 'partial'
    const externalMovementsColumnsWidths = isPartialConciliation
      ? partialConciliationsGatewayPaymentsColumnsWidths
      : conciliationsGatewayPaymentsColumnsWidths

    const internalMovementsColumnsWidths = isPartialConciliation
      ? partialConciliationsAccountForecastsColumnsWidths
      : conciliationsAccountForecastsColumnsWidths

    const newConciliation = CreditCardConciliationParser.single({
      conciliation,
      withValueDiference: isPartialConciliation,
      i18n,
      externalMovementsColumnsWidths,
      internalMovementsColumnsWidths
    })

    const result = isPartialConciliation ? {
      partialConciliation: newConciliation, conciliation: ''
    } : { conciliation: newConciliation, partialConciliation: '' }

    return result
  }
}
