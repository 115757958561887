/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { ExpenseRevenueTypeFilterSchema } from './ExpenseRevenueTypeFilter.schema'
import { stylishCardSearch, buttonStyle } from './ExpenseRevenueTypeFilter.styles'
import { selectExpenseRevenueStatusOptions, selectExpenseRevenueTypeOptions } from '../constants/expenseRevenueType'
import {
  Button,
  Input,
  Select,
  InputFormGroup,
  Card,
  useToast,
  Column,
  Row
} from '@bonitour/components'

export const ExpenseRevenueTypeFilter = ({ onSearch: emitOnSearch = identity, initialFilters }) => {
  const { add: addToast } = useToast()

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(initialFilters, ExpenseRevenueTypeFilterSchema)

  const onError = () => addToast('Preencha o formulário corretamente')

  const onSearch = () => onSubmit(emitOnSearch(form), onError)

  const { description = '', expenseRevenueType, enabled = '' } = form

  return (
    <Card css={stylishCardSearch}>
      <Row>
        <Column desktop={5} phone={12}>
          <InputFormGroup label='Descrição' errorMessage={errors.eventType}>
            <Input
              id='description'
              placeholder=''
              value={description}
              onChange={onInputChange('description')}
              onBlur={onInputBlur('description')}
            />
          </InputFormGroup>
        </Column>
        <Column desktop={2} phone={12}>
          <InputFormGroup label='Tipo'>
            <Select
              id='expenseRevenueType'
              value={expenseRevenueType}
              placeholder='Todos'
              onChange={onInputChange('expenseRevenueType')}
              options={selectExpenseRevenueTypeOptions}
            />
          </InputFormGroup>
        </Column>
        <Column desktop={2} phone={12}>
          <InputFormGroup label='Status'>
            <Select
              id='status'
              value={enabled}
              placeholder='Todos'
              onChange={onInputChange('enabled')}
              options={selectExpenseRevenueStatusOptions}
            />
          </InputFormGroup>
        </Column>
        <Column desktop={3} phone={12}>
          <Button css={buttonStyle} onClick={onSearch}>
            Pesquisar
          </Button>
        </Column>
      </Row>
    </Card>
  )
}
