import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const marginTop = css`
  margin-top: 25px;
`

export const cardButtonStyle = css`
  width: 160px;
`

export const labelStyle = css`
  color: ${colors.gray4};
  font-weight: lighter;
  font-size: 14px;
  text-align: center;
  padding: 8px 6px;
  line-height: 1.4;
`

export const selectAccountsPlan = css`
  .component-drawer-list {
    max-height: 8.4375rem;
  }
`
