import { css } from '@emotion/core'
import { iconButton } from 'Shared/styles/global'

export const cardButtonStyle = css`
  width: 10rem;
  padding: 0.625rem 1.25rem;
  min-width: 8.75rem;
  margin-bottom: 1.563rem;
`

export const labelStyle = css`
  color: var(--clr-gray4);
  font-weight: lighter;
  font-size: 0.875rem;
  text-align: center;
  padding: 0.5rem 0.375rem;
  line-height: 1.4;
`
export const wrapperButtons = css`
  display: flex;
  gap: 0.625rem;
  align-items: center;

  .b-font-edit-info, .b-font-trash, .b-font-bank-card {
    ${iconButton}
  }

  .b-font-bank-card {
    font-size: 1.7rem;
  }
`

export const supplierSafeOverflow = css`
  max-width: 20rem;
`

export const removeDialog = css`
  span {
    height: 1.875rem;
  }
`
