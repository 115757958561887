/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory, useParams } from 'react-router-dom'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useTranslator } from 'Shared/language/i18nHandler'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'
import { EventsBody } from './EventsBody'
import { useCallback, useMemo, useState } from 'react'
import { FloatingSelectBar } from 'Shared/components/FloatingSelectBar/FloatingSelectBar'
import { EventsProcessorsTranslator } from 'Domains/EventsProcessors/i18n/EventsProcessorsI18n'

export const PageSelectEvents = () => {
  const { push } = useHistory()
  const routerParams = useParams()

  const [selectedEventId, setSelectedEventId] = useState(routerParams?.eventId)

  const redirectPageEditEventProcessor = useCallback(() => {
    push(`/events-processors/edit/${routerParams?.eventProcessorId}/${selectedEventId}?section=2`)
  }, [
    push,
    routerParams?.eventProcessorId,
    selectedEventId
  ])

  const returnPageEditEventProcessor = useCallback(() => {
    push(`/events-processors/edit/${routerParams?.eventProcessorId}?section=2`)
  }, [push, routerParams?.eventProcessorId])

  useFlaggedRoute('billing-events-processors')

  const i18n = useTranslator(EventsProcessorsTranslator)

  const floatingSelectBarBtns = useMemo(() => {
    return [
      {
        label: i18n?.selectEvent,
        onClick: redirectPageEditEventProcessor,
        disabled: !selectedEventId
      }

    ]
  }, [i18n?.selectEvent, redirectPageEditEventProcessor, selectedEventId])

  const selectedItem = useMemo(
    () => (selectedEventId ? [selectedEventId] : []),
    [selectedEventId]
  )

  return (
    <>
      <PageTitle
        title={i18n?.pageTitleSelectAnEvent}
        onClickReturn={returnPageEditEventProcessor}
      />
      <EventsBody
        isSelectEvent
        handleSelectEventId={setSelectedEventId}
        selectedEventId={selectedEventId}
      />
      <FloatingSelectBar
        items={selectedItem}
        itemLabel={i18n?.selectedEvent}
        floatingSelectBarBtns={floatingSelectBarBtns}
      />
    </>
  )
}
