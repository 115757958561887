import { useMemo } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SupplierBankAccountService } from 'Core/Service/SupplierBankAccount'
import { SupplierBankAccountsHooksTranslator } from '../i18n/SupplierBankAccountsHooksI18n'
import { useQuery } from 'react-query'

export const useListSupplierBankAccounts = (supplierId) => {
  const { addToast } = useToast()

  const i18n = useTranslator(SupplierBankAccountsHooksTranslator)

  const {
    data: supplierBankAccountsList,
    isLoading,
    isFetching
  } = useQuery(
    ['supplierBankAccountList', supplierId],
    () =>
      SupplierBankAccountService.list(supplierId),
    {
      enabled: !!supplierId,
      onError: (errors) => {
        console.error(errors?.data)
        addToast({ msgs: errors?.data?.errors_msg, defaultMessage: i18n?.errorFecthingSupplierBankAccounts })
      },
      staleTime: 1000 * 60 * 10, // 10 min
      retry: 2,
      initialData: { supplierBankAccounts: [] },
      initialDataUpdatedAt: 0
    }
  )

  const listLoading = useMemo(() => isLoading || isFetching, [isFetching, isLoading])

  return {
    supplierBankAccounts: supplierBankAccountsList?.supplierBankAccounts,
    listLoading
  }
}
