/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useRef, useMemo } from 'react'
import { safeOverflowContainer } from './SafeOverflow.styles'

import { useElementBoundingClientRect } from 'Shared/hooks/useElementBoundingClientRect'

/**
 * @param {{
 * text: string;
 * alignment?: 'left' | 'center' | 'right';
 * customCss?: import('@emotion/react').SerializedStyles[]
 * className?: string
 * }} props
 */

export const SafeOverflow = ({
  text,
  alignment = 'left',
  customCss = [],
  className = ''
}) => {
  const safeContainerRef = useRef(null)
  const hintRef = useRef(null)

  const { width: containerWidth } =
      useElementBoundingClientRect(safeContainerRef)

  const { width: hintWidth } = useElementBoundingClientRect(hintRef, 'content')

  const isOverflowing = useMemo(
    () => containerWidth < hintWidth,
    [containerWidth, hintWidth]
  )

  return (
    <div
      css={[safeOverflowContainer, ...customCss]}
      className={`safe_overflow__container safe_overflow__align_${alignment} ${className}`}
      ref={safeContainerRef}
    >
      <span className='safe_overflow__text'>{text}</span>

      <span
        className={`safe_overflow__hint ${
          isOverflowing ? 'is_overflowing' : 'is_safe'
        }`}
        ref={hintRef}
      >
        {text}
      </span>
    </div>
  )
}
