import { BankAccountsModel } from 'Core/Models/bankAccounts'
import { billingCore } from './Billing'
import { BankAccountParser } from 'Core/Parser/bankAccount'

export const BankAccountService = {
  options () {
    return billingCore.bankAccountBillingDomain.list().then(BankAccountParser.options)
  },
  list (pagination) {
    return billingCore.bankAccountBillingDomain.listPaginated(BankAccountsModel.list(pagination)).then(BankAccountParser.list)
  },
  getById (bankAccountId) {
    return billingCore.bankAccountBillingDomain.get(bankAccountId).then(BankAccountParser.single)
  },
  edit (bankAccount, bankAccountId, user) {
    return billingCore.bankAccountBillingDomain.update(BankAccountsModel.edit(bankAccount, user), bankAccountId)
  },
  getBankAccountAudit (bankAccountId) {
    return billingCore.bankAccountBillingDomain.getBankAccountAudit({}, bankAccountId).then(BankAccountParser.getBankAccountAudit)
  }
}
