import React from 'react'
import { SimulatorRecurrencyContainer as Container } from './SimulatorRecurrency.styles'
import { useSimulateRecurrenceExpense } from '../Expenses/hooks/useSimulateRecurrenceExpense'
import { LoadingAnimation } from '@bonitour/components'
import { loadingAnimation } from 'Shared/styles/global'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesTranslator } from '../Expenses/i18n/ExpensesI18n'

export const SimulatorRecurrency = ({
  form,
  ...props
}) => {
  const { data, loading } = useSimulateRecurrenceExpense(form)

  const i18n = useTranslator(ExpensesTranslator)

  return (
    <Container
      {...props}
    >
      <h2 className='simulator__title'>
        {i18n?.simulationOfRecurringPayments}
      </h2>
      {
        loading ? (
          <LoadingAnimation customCss={[loadingAnimation]} />
        ) : (
          data?.dates?.length ? (
            <>
              <p className='simulator__subtitle'>
                {i18n?.datesOfEachPaymentFromThisCurrentOne}:
              </p>
              <div className='simulator__items_content'>
                <AnimatePresence>
                  {data?.dates.map((date, index) => (
                    <motion.div
                      key={`${index}${date}${data?.value}`}
                      className='wrapper__value_date'
                      {...props}
                    >
                      <motion.span className='simulator__item_date'>{index + 1}ª: <b>{date}</b></motion.span> -
                      <motion.span className='simulator__item_value'><b>{data?.value || 0.0}</b></motion.span>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
            </>
          ) : <p className='simulator__subtitle'>
            {i18n?.fillOuTheSimulationForm}
          </p>
        )}
    </Container>
  )
}
