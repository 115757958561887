import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const titleHeader = css`
  font-weight: 500;
`

export const cardBox = css`
  align-self: center;
  width: 100%;

  .info__forgotten {
    color: var(--clr-red3);
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 0.9375rem;
  }
`

export const marginTop = css`
  margin-top: 25px;
`

export const marginRight = css`
  margin-right: 20px;
`
export const sectionHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const customValueCard = css`
  .section__value {
    background-color: ${colors.white};
  }
  
`
