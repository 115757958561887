import { ExpenseRevenueService } from 'Core/Service/ExpenseRevenues'
import { useState } from 'react'
import { useUser } from 'Shared/contexts/User'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesRevenuesTypesHooksTranslator } from '../i18n/ExpensesRevenuesTypesHooksI18n'

export const useChangeExpenseRevenues = (fetchExpenseRevenues, setVisibilityModal, setVisibilityDialog) => {
  const { addToast } = useToast()
  const [loading, setLoading] = useState(false)
  const { user } = useUser()

  const i18n = useTranslator(ExpensesRevenuesTypesHooksTranslator)

  const editExpenseRevenues = async (data, filters, pagination) => {
    setLoading(true)
    return ExpenseRevenueService.edit(data, data.id, user)
      .then(() => {
        setVisibilityModal(false)
        addToast(
          {
            defaultMessage: i18n?.typeOfRevenueExpenseUpdatedSuccessfully,
            typeToast: 'success'
          }
        )
        fetchExpenseRevenues({ filters, pagination })
      })
      .catch(errors => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorWhenUpdatingRevenueExpenseType
          }
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const createExpenseRevenues = async (data, filters, pagination) => {
    setLoading(true)
    return ExpenseRevenueService.create(data, user)
      .then(() => {
        setVisibilityModal(false)
        addToast(
          {
            defaultMessage: i18n?.typeOfRevenueExpenseSuccessfullyRegistered,
            typeToast: 'success'
          }
        )
        fetchExpenseRevenues({ filters, pagination })
      })
      .catch(errors => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorWhenRegisteringTypeOfRevenueExpense
          }
        )
      }
      )
      .finally(() => {
        setLoading(false)
      })
  }

  const removeExpenseRevenue = async (id, filters, pagination) => {
    setLoading(true)
    return ExpenseRevenueService.remove(id)
      .then(() => {
        setVisibilityDialog(false)
        addToast(
          { defaultMessage: i18n?.typeOfRevenueExpenseSuccessfullyRemoved, typeToast: 'success' }
        )
        fetchExpenseRevenues({ filters, pagination })
      })
      .catch(errors => {
        console.error(errors?.data)
        addToast(
          {
            dictTranslation: i18n?.apiCodesMappingForExpenseRevenue,
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorRemovingRevenueExpenseType
          }
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    onEditExpenseRevenue: editExpenseRevenues,
    onCreateExpenseRevenue: createExpenseRevenues,
    onRemoveExpenseRevenue: removeExpenseRevenue,
    loading
  }
}
