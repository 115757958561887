import React, { useCallback, useState } from 'react'
import {
  DownloadContainer as Container,
  DonwloadImgContainer,
  DownloadImg,
  DownloadBtn,
  DownloadImgContent,
  IconWrapper
} from './DownloadReceipts.styles'
import { Modal } from 'Shared/components/Modal/Modal'
import { ArrowDownIcon, EyeIcon } from '@bonitour/components'
import { browserDownloadFromSrcImg } from 'Shared/utils/browserDownloadFromSrcImg'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesTranslator } from '../Expenses/i18n/ExpensesI18n'
import { ReceiptParser } from 'Core/Types/Expense'

interface DownloadReceiptsProps {
  receipts: ReceiptParser[]
}

export const DownloadReceipts = ({
  receipts = []
}: DownloadReceiptsProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = useCallback(() => setIsOpen(v => !v), [])

  const onDownloadReceipts = useCallback((downloadReceipts) => {
    if (!downloadReceipts?.length) return

    downloadReceipts.forEach(({ id, src, name }) => {
      browserDownloadFromSrcImg(id, src, name)
    })
  }, [])

  const i18n = useTranslator(ExpensesTranslator)

  if (!receipts.length) return

  return (
    <Container>
      <button
        type='button'
        onClick={toggleModal}
      >
        <ArrowDownIcon />
      </button>
      { /* @ts-expect-error will be adjusted in bonitour-components */}
      <Modal
        title={i18n?.downloadYouReceipts}
        isOpen={isOpen}
        onClose={toggleModal}
        maxWidth='50'
      >
        <DonwloadImgContainer numberColumn={receipts?.length}>
          {receipts.map(
            ({ id, src, name }) =>
              <DownloadImgContent key={id}>
                <DownloadImg
                  src={src}
                  alt={name}
                />
                <IconWrapper>
                  <button
                    className='circle__icons'
                    type='button'
                    onClick={() => onDownloadReceipts([{ id, src, name }])}>
                    <ArrowDownIcon className='icon icon__arrow_down'/>
                  </button>
                  <a className='circle__icons'href={src} target='_blank' rel="noreferrer">
                    <EyeIcon className='icon'/>
                  </a>
                </IconWrapper>
                <p className='name__img'>{name}</p>
              </DownloadImgContent>
          )}
        </DonwloadImgContainer>
        <DownloadBtn
          type='button'
          onClick={() => onDownloadReceipts(receipts)}
        >
          {i18n?.downloadAllReceitps}
        </DownloadBtn>
      </Modal>
    </Container>
  )
}
