import dayjs from 'dayjs'

export const FullFinancialSchedule = {
  getListFlowMovementsParams: (filters = {}) => {
    const {
      startDate,
      endDate,
      currentPage,
      perPage,
      exportFormat,
      canceled,
      description,
      originType
    } = filters

    const searchParams = {
      start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined,
      end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
      page: currentPage,
      per_page: perPage,
      export: exportFormat,
      canceled,
      description: description || undefined,
      origin_type: originType || undefined
    }

    return searchParams
  },
  getListFlowMovements: (filters = {}) => {
    return FullFinancialSchedule.getListFlowMovementsParams(filters)
  },
  getListFlowMovementsExport: (filters = {}) => {
    return {
      ...FullFinancialSchedule.getListFlowMovementsParams(filters),
      page: undefined,
      per_page: undefined
    }
  }
}
