import React from 'react'
import {
  string as yupString,
  number as yupNumber
} from 'yup'
import { Tooltip } from 'Shared/components/Tooltip'
import { ReactComponent as CircleInfoIcon } from 'Shared/svgs/circleInfoIcon.svg'

// TODO add this component to formInputs
const InfoTooltip = ({
  title,
  info
}) => {
  return (
    <>
      <span>{title}</span>
      <div className='circle__info'>
        <CircleInfoIcon />
        <Tooltip
          verticalPosition='top'
          horizontalPosition='center'
          tooltipClass='tooltip__conciliated'
          width="auto"
        >
          {info}
        </Tooltip>
      </div>
    </>
  )
}

const forgottenExpenses = {
  label: (
    <InfoTooltip
      title='Contas a pagar'
      info='Número de dias anteriores a data atual em que se procura contas agendadas não pagas.'
    />
  ),
  className: 'forgotten_expenses',
  value: 'forgottenExpenses',
  type: 'suffix',
  typeValue: 'number',
  typeWrapper: 'inputFormGroup',
  suffix: 'dias',
  schema: yupNumber()
    .min(1, 'mustBeGreaterThanOrEqual1')
    .max(31, 'mustBeLessThanOrEqual31'),
  maskFn: (val = '') => Number(val.replace(/[^0-9,.]/g, ''))
}

const forgottenRevenues = {
  label: (
    <InfoTooltip
      title='Contas a receber'
      info='Número de dias anteriores a data atual em que se procura contas agendadas não recebidas.'
    />
  ),
  className: 'forgotten_revenues',
  value: 'forgottenRevenues',
  type: 'suffix',
  typeValue: 'number',
  typeWrapper: 'inputFormGroup',
  suffix: 'dias',
  schema: yupNumber()
    .min(1, 'mustBeGreaterThanOrEqual1')
    .max(31, 'mustBeLessThanOrEqual31'),
  maskFn: (val = '') => Number(val.replace(/[^0-9,.]/g, ''))
}

const maxRecurrences = {
  label: (
    <InfoTooltip
      title='Pagamentos recorrentes'
      info='Número máximo de pagamento recorrentes que podem ser criados.'
    />
  ),
  className: 'max_recurrences',
  value: 'maxRecurrences',
  type: 'suffix',
  typeValue: 'number',
  typeWrapper: 'inputFormGroup',
  suffix: 'vezes',
  schema: yupNumber()
    .min(12, 'mustBeGreaterThanOrEqual12')
    .max(240, 'mustBeLessThanOrEqual240'),
  maskFn: (val = '') => Number(val.replace(/[^0-9,.]/g, ''))
}

const expenseTradeId = (expenseRevenueTypes = []) => ({
  label: 'Tipo de despesas do Trade Turístico',
  placeholder: 'Selecione um tipo de despesa ou receita',
  className: 'expense_trade_id',
  value: 'expenseTradeId',
  type: 'select',
  options: expenseRevenueTypes,
  schema: yupString().optional()
})

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  forgottenExpenses,
  forgottenRevenues,
  maxRecurrences,
  expenseTradeId,
  getSchema
}
