
export const ExpenseRevenueModel = {
  cleanPayloadAccordingStatus: (data) => {
    const isScheduled = data.status === 'scheduled'

    const newData = isScheduled
      ? Object.keys(data).filter(
        key => ['payment_method', 'bank_account_id'].indexOf(key) === -1
      ).reduce((obj, key) => {
        obj[key] = data[key]
        return obj
      }, {}
      )
      : data

    return newData
  },
  checkStatusSend: (status, isExpense = false) => {
    const arrayCheck = isExpense
      ? ['scheduled', 'canceled', 'payed', 'refunded']
      : ['scheduled', 'canceled', 'received', 'refunded']

    const containAll = arrayCheck.every((val) =>
      status?.includes(val)
    )

    return containAll
  }
}
