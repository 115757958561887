import React from 'react'
import { useForm } from '@bonitour/app-functions'
import { useBankAccountInputs } from '../hooks/inputs/useBankAccountInputs'
import { BankAccountFormActions } from './__BankAccountsFormActions'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { BankAccountContainer } from './BankAccounts.style'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankAccountsTranslator } from '../i18n/BankAccountsI18n'

const BASE_FORM_VAL = {}

/**
 * @typedef BankAccountModalProps
 * @prop { () => void } onCancel
 * @prop { () => void } onSubmit
 * @prop { Record<string, string>[] } currentFormData
 * @prop { Record<string, string>[] } accountablePlanOptions
 */

/** @type { React.FC<BankAccountModalProps> } */
export const BankAccountForm = ({
  onCancel,
  onSubmit,
  currentFormData,
  accountablePlanOptions
}) => {
  const i18n = useTranslator(BankAccountsTranslator)

  const { inputsValid, validationSchema } = useBankAccountInputs(
    accountablePlanOptions,
    i18n.bankAccountEnabledOptions
  )

  const {
    form,
    errors,
    onSubmit: formSubmitHook,
    utils: { onInputChange, onInputBlur }
  } = useForm(currentFormData || BASE_FORM_VAL, validationSchema)

  return (
    <BankAccountContainer>
      <FormsInputs
        inputs={inputsValid}
        formValues={form}
        formErrors={errors}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      />

      <BankAccountFormActions
        onSubmit={formSubmitHook(onSubmit)}
        onClose={onCancel}
        saveButton={i18n.bankAccountSaveButton}
        cancelButton={i18n.bankAccountCancelButton}
      />
    </BankAccountContainer>
  )
}
