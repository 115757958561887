/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableContainer,
  EditInfoIcon,
  TrashIcon,
  BankCardIcon
} from '@bonitour/components'
import { CircleStatus } from 'Shared/styles/global'
import { Audit } from 'Shared/components/Audit/Audit'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { statusLabelColor } from '../constants/supplier'
import { supplierSafeOverflow, wrapperButtons } from '../Supplier.styles'
import { useSupplierAudit } from '../hooks/useSupplierAudit'
import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'

/**
 * @typedef SuppliersTableHeaderProps
 * @prop { string[] } columns
 */
/** @type { React.FC<SuppliersTableHeaderProps> } */
const SuppliersTableHeader = ({ columns }) => {
  return (
    <TableHeader>
      <TableHeaderRow>
        {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
      </TableHeaderRow>
    </TableHeader>
  )
}

/**
 * @typedef SuppliersTableBodyProps
 * @prop { boolean } loading
 * @prop { Record<string, string>[] } suppliers
 * @prop { Record<string, string> } i18n
 * @prop { (id: string) => void } onEdit
 * @prop { (id: string) => void } onDialogRemoveSupplier
 */
/** @type { React.FC<SuppliersTableBodyProps> } */
const SuppliersTableBody = ({ loading, suppliers, i18n, onEdit, onDialogRemoveSupplier }) => {
  const {
    fetchSupplierAudit,
    datas,
    loading: auditLoading
  } = useSupplierAudit()

  const { push } = useHistory()

  const goToListSupplierBankAccounts = useCallback(
    ({ id: supplierId }) => push(`/expense-revenue-dashboard/suppliers/${supplierId}/supplier-bank-accounts/`), [push]
  )

  return (
    <TableBody loading={loading}>
      {suppliers.map(
        ({
          id,
          corporateName,
          fancyName,
          supplierDocument,
          enabled
        }) => (
          <TableBodyRow key={id}>
            <TableBodyCell>
              <SafeOverflow
                text={corporateName}
                customCss={supplierSafeOverflow.styles}
              />
            </TableBodyCell>
            <TableBodyCell>
              <SafeOverflow
                text={fancyName}
                customCss={supplierSafeOverflow.styles}
              />
            </TableBodyCell>
            <TableBodyCell>
              {supplierDocument}
            </TableBodyCell>
            <TableBodyCell>
              <CircleStatus statusColor={statusLabelColor[enabled].color}>
                {i18n.supplierStatusCodesMapped[enabled].label}
              </CircleStatus>
            </TableBodyCell>
            <TableBodyCell css={wrapperButtons}>
              <EditInfoIcon onClick={() => onEdit(id)}/>
              <TrashIcon onClick={() => onDialogRemoveSupplier(id)}/>
              <Audit
                documentId={id}
                auditDatas={datas}
                auditLoading={auditLoading}
                fetchAuditDatas={fetchSupplierAudit}
                modalTitle={i18n.modalAuditSupplierTitle}
                i18n={i18n.auditLabels}
              />
              <BankCardIcon
                onClick={() => goToListSupplierBankAccounts({ id, corporateName })}
              />
            </TableBodyCell>
          </TableBodyRow>
        ))}
    </TableBody>
  )
}

/**
 * @typedef SuppliersListProps
 * @prop { boolean } isLoading
 * @prop { Record<string, string>[] } suppliers
 * @prop { Record<string, string> } i18n
 * @prop { (id: string) => void } onEdit
 * @prop { (id: string) => void } onDialogRemoveSupplier
 */
/** @type { React.FC<SuppliersListProps> } */
export const SuppliersList = ({ suppliers, isLoading, i18n, onEdit, onDialogRemoveSupplier }) => {
  return (
    <>
      <TableContainer>
        <SuppliersTableHeader columns={i18n.supplierTableColumnsTitles} />
        <SuppliersTableBody
          loading={isLoading}
          suppliers={suppliers}
          i18n={i18n}
          onEdit={onEdit}
          onDialogRemoveSupplier={onDialogRemoveSupplier}
        />
      </TableContainer>
    </>

  )
}
