/* eslint-disable camelcase */
import { unformatMoney } from '@bonitour/common-functions'
import { metaModel } from './Meta'
import dayjs from 'dayjs'
import { ExpenseRevenueModel } from './expenseRevenue'

export const RevenueModel = {
  list: ({ filters, pagination }) => {
    return {
      searchParams: RevenueSearchModel(filters),
      pagination: metaModel(pagination)
    }
  },
  create (payload) {
    const {
      date,
      referenceDate,
      expenseRevenueId,
      bankAccountId,
      paymentMethod,
      serviceName,
      value,
      observation,
      status,
      userId,
      userName
    } = payload ?? {}

    return ExpenseRevenueModel.cleanPayloadAccordingStatus({
      date: date ? dayjs(date).format('YYYY-MM-DD') : undefined,
      reference_date: referenceDate ? dayjs(referenceDate).format('YYYY-MM-DD') : undefined,
      expense_revenue_id: expenseRevenueId,
      service_name: serviceName,
      value: unformatMoney(value),
      observation,
      status,
      bank_account_id: bankAccountId,
      payment_method: paymentMethod,
      user_id: userId,
      user_name: userName
    })
  },
  update (revenue) {
    const {
      id,
      date,
      referenceDate,
      expenseRevenueId,
      value,
      paymentMethod,
      status,
      observation,
      userId,
      serviceName,
      bankAccountId,
      userName
    } = revenue ?? {}

    return ExpenseRevenueModel.cleanPayloadAccordingStatus({
      id,
      date,
      reference_date: referenceDate,
      expense_revenue_id: expenseRevenueId,
      value: unformatMoney(value),
      payment_method: paymentMethod,
      status,
      observation,
      user_id: userId,
      service_name: serviceName,
      bank_account_id: bankAccountId,
      user_name: userName
    })
  }
}

export const RevenueSearchModel = (filterParams = {}) => {
  const {
    startDate,
    endDate,
    status,
    serviceName,
    expenseRevenueId
  } = filterParams

  const res = {
    start_date: startDate && dayjs(startDate).format('YYYY-MM-DD'),
    end_date: endDate && dayjs(endDate).format('YYYY-MM-DD'),
    status,
    service_name: serviceName,
    expense_revenue_id: expenseRevenueId
  }

  if (ExpenseRevenueModel.checkStatusSend(status)) {
    delete res.status
  }

  return res
}
