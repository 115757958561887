/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { defineExpenseRevenueType, expenseRevenueTypeListColumnsTitles } from '../constants/expenseRevenueType'
import { wrapperButtons } from './ExpenseRevenueTypeList.styles'
import {
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  EditInfoIcon,
  TrashIcon
} from '@bonitour/components'
import { Audit } from 'Shared/components/Audit/Audit'
import { useExpenseRevenueAudit } from '../hooks/useExpenseRevenueAudit'
import { CircleStatus } from 'Shared/styles/global'
import { statusLabelColor } from 'Domains/ExpenseRevenue/constants/expenseRevenue'
import { useCallback } from 'react'

const ExpenseRevenueTypeListTableHeader = ({ columns }) => (
  <TableHeader>
    <TableHeaderRow>
      {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
    </TableHeaderRow>
  </TableHeader>
)

const ExpenseRevenueTypeListTableBody = ({ expenseRevenues, loading, onModalEditExpenseRevenue, onDialogRemoveExpenseRevenue, i18n }) => {
  const {
    datas,
    loading: auditLoading,
    fetchExpenseRevenueAudit
  } = useExpenseRevenueAudit()

  const enabledLabel = useCallback(enabled => {
    return enabled ? 'enabled' : 'disabled'
  }, [])

  return (
    <>
      <TableBody loading={loading}>
        {expenseRevenues?.map((data) => (
          <TableBodyRow key={data?.id}>
            <TableBodyCell>{data?.description}</TableBodyCell>
            <TableBodyCell>{defineExpenseRevenueType[data?.expenseRevenueType]}</TableBodyCell>
            <TableBodyCell>
              <CircleStatus
                statusColor={statusLabelColor[enabledLabel(data?.enabled)].color}>
                {statusLabelColor[enabledLabel(data?.enabled)].label}
              </CircleStatus>
            </TableBodyCell>
            <TableBodyCell css={wrapperButtons}>
              <EditInfoIcon
                onClick={() => onModalEditExpenseRevenue(data)}
              />
              <TrashIcon
                onClick={() => onDialogRemoveExpenseRevenue(data?.id)}
              />
              <Audit
                documentId={data?.id}
                auditDatas={datas}
                auditLoading={auditLoading}
                fetchAuditDatas={fetchExpenseRevenueAudit}
                modalTitle={i18n.auditModalTitle}
                i18n={i18n.auditLabels}
              />
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </TableBody>
    </>
  )
}

export const ExpenseRevenueTypeList = ({ expenseRevenues = [], loading, onModalEditExpenseRevenue, onDialogRemoveExpenseRevenue, i18n }) => {
  return (
    <TableContainer>
      <ExpenseRevenueTypeListTableHeader columns={expenseRevenueTypeListColumnsTitles} />
      <ExpenseRevenueTypeListTableBody
        expenseRevenues={expenseRevenues}
        loading={loading}
        onModalEditExpenseRevenue={onModalEditExpenseRevenue}
        onDialogRemoveExpenseRevenue={onDialogRemoveExpenseRevenue}
        i18n={i18n}
      />
    </TableContainer>
  )
}
