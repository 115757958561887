import { SafeDate } from '@binamik/js-providers'

type StartAndEndDateMustDifferProps = {
  ctx: { parent: { startDate: string | Date, isStoneAccount: boolean } }
}

export const startAndEndDateMustDiffer = (
  value: string | Date,
  ctx: StartAndEndDateMustDifferProps['ctx']
) => {
  if (!value || !ctx?.parent?.startDate || !ctx?.parent?.isStoneAccount) {
    return true
  }

  const startDate = new SafeDate(ctx?.parent?.startDate).isoDate
  const endDate = new SafeDate(value).isoDate

  return startDate !== endDate
}
