import { metaModel } from './Meta'
import dayjs from 'dayjs'

export const FinancialScheduleModel = {
  list: ({ filters = {}, pagination = {} }) => {
    const {
      startDate,
      endDate,
      reservationCode,
      partnerName,
      proofOfSale,
      brand,
      creditCardOperationType,
      gateway
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined,
      end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
      reservation_code: reservationCode,
      partner_name: partnerName,
      proof_of_sale: proofOfSale,
      brand,
      creditcard_operation_type: creditCardOperationType,
      gateway
    }

    return { searchParams, pagination: meta }
  },
  export: ({ filters = {}, pagination = {} }) => {
    const {
      startDate,
      endDate,
      exportFormat,
      reservationCode,
      partnerName,
      brand,
      creditCardOperationType,
      proofOfSale,
      gateway
    } = filters

    const meta = metaModel(pagination)

    const searchParams = {
      gateway,
      brand,
      start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined,
      end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
      export: exportFormat,
      reservation_code: reservationCode,
      partner_name: partnerName,
      creditcard_operation_type: creditCardOperationType,
      proof_of_sale: proofOfSale
    }

    return { searchParams, pagination: meta }
  }
}
