import { useCallback, useState } from 'react'
import { EventsProcessorsService } from 'Core/Service/eventsProcessors'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'

export const useGetEventProcessor = () => {
  const [loading, setLoading] = useState(false)
  const [eventProcessorData, setEventProcessorData] = useState()
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const fetchEventProcessor = useCallback((eventProcessorId) => {
    setLoading(true)
    return EventsProcessorsService.getEventProcessor(eventProcessorId)
      .then((data) => {
        setEventProcessorData(data)
      }).catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorFectingEventProcessor
        })
      })
      .finally(() => setLoading(false))
  }, [addToast, i18n?.errorFectingEventProcessor])

  return {
    fetchEventProcessor,
    eventProcessorData,
    loading
  }
}
