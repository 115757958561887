
interface GetOptionsWithExtrasProps {
  options: { value: string, label: string }[]
  includeExtraOption: string
}

export const getOptionsWithExtras = (
  { options, includeExtraOption }: GetOptionsWithExtrasProps
) => {
  const extraOption = includeExtraOption === 'all'
    ? [{ value: '', label: 'Todos' }]
    : includeExtraOption === 'none'
      ? [{ value: '', label: 'Nenhum' }]
      : []

  return [...extraOption, ...options]
}
