
export const ConciliationsInputsTranslator = (
  dictionary
) => {
  const labels = {
    startDate: {
      label: dictionary?.conciliation_startDate_inputLabel,
      placeholder: dictionary?.conciliation_startDate_placeholderLabel
    },
    endDate: {
      label: dictionary?.conciliation_endDate_inputLabel,
      placeholder: dictionary?.conciliation_endDate_placeholderLabel
    },
    bankAccountId: {
      label: dictionary?.conciliation_bankAccountId_inputLabel,
      placeholder: dictionary?.conciliation_bankAccountId_placeholderLabel
    },
    acquirer: {
      label: dictionary?.common_acquirer_label,
      placeholder: dictionary?.conciliation_selectAcquirer_placeholderLabel,
      options: {
        braspag: dictionary?.common_braspag_label,
        cielo: dictionary?.common_cielo_label
      }
    }
  }

  return {
    inputs: labels
  }
}
