import React, { useCallback, useState } from 'react'

interface CounterProps {
  mensagem: string;
}

const Counter = ({ mensagem }: CounterProps) => {
  const [contador, setContador] = useState(0)

  const incrementarContador = useCallback(
    () => {
      setContador(contador + 1)
    }, [contador]
  )

  return (
    <div>
      <h1>{mensagem}</h1>
      <p>Contador: {contador}</p>
      <button type='button' onClick={incrementarContador}>Incrementar
      </button>
    </div>
  )
}

const TesteComponent: React.FC = () => {
  return (
    <Counter mensagem='Qty Frutas' />
  )
}

export default TesteComponent
