import {
  string as yupString,
  array as yupArray,
  date as yupDate
} from 'yup'
import { endDateMustBeAfterOrEqualStartDate } from 'Shared/utils/yup/date/endDateMustBeAfterOrEqualStartDate'
import { startDateMustBeLessThanOrEqualEndDate } from 'Shared/utils/yup/date/startDateMustBeLessThanOrEqualEndDate'

const startDate = {
  label: 'Data inicial',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data inicial',
  className: 'start_date',
  value: 'startDate',
  type: 'date',
  schema: yupDate()
    .typeError('dateFormat')
    .required('dateRequired')
    .test(
      'startDateMustBeLessThanOrEqualEndDate', 'startDateMustBeLessThanOrEqualEndDate', startDateMustBeLessThanOrEqualEndDate
    )
}

const endDate = {
  label: 'Data final',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Data final',
  className: 'end_date',
  value: 'endDate',
  type: 'date',
  schema: yupDate()
    .typeError('dateFormat')
    .required('dateRequired')
    .test(
      'endDateMustBeAfterOrEqualStartDate', 'endDateMustBeAfterOrEqualStartDate', endDateMustBeAfterOrEqualStartDate
    )
}

const description = {
  label: 'Descrição',
  typeWrapper: 'inputFormGroup',
  placeholder: 'Buscar por palavra chave ou descrição',
  className: 'description',
  value: 'description',
  type: 'text',
  schema: yupString()
}

const originType = {
  label: 'Tipo de lançamento',
  className: 'origin_type',
  value: 'originType',
  type: 'multiSelect',
  hasSelectAll: true,
  schema: yupArray().of(yupString())
}

const getSchema = (formInputs) =>
  formInputs.reduce((acc, input) => {
    acc[input.value] = input.schema
    return acc
  }, {})

export {
  originType,
  description,
  startDate,
  endDate,
  getSchema
}
