/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { button, stylishCardSearch, wrapColums } from './FilterCountPlan.styles'
import { planTypeOptions, enabledOptions } from '../Constants/CountPlan'
import { countPlanFilterSchema } from './FilterCountPlan.schema'
import {
  Button,
  Input,
  Select,
  Card,
  useToast,
  Column,
  InputFormGroup,
  flexCenteredSpaceBetweenRow
} from '@bonitour/components'

export const FilterCountPlan = ({ onSearch: emitOnSearch = identity, initialFilters }) => {
  const { add: addToast } = useToast()

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(initialFilters, countPlanFilterSchema)

  const onSearch = () => onSubmit(emitOnSearch(form), onError)
  const onError = () => addToast('Preencha o formulário corretamente')

  const { name = '', classification = '', level = '', planType = '', enabled = '' } = form

  return (
    <Card css={stylishCardSearch}>
      <div css={[flexCenteredSpaceBetweenRow, wrapColums]}>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Nome'>
            <Input
              value={name}
              error={errors.name}
              onChange={onInputChange('name')}
              onBlur={onInputBlur('name')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Classificação'>
            <Input
              value={classification}
              error={errors.classification}
              onChange={onInputChange('classification')}
              onBlur={onInputBlur('classification')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2}>
          <InputFormGroup label='Nível'>
            <Input
              value={level}
              error={errors.level}
              onChange={onInputChange('level')}
              onBlur={onInputBlur('level')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2}>
          <InputFormGroup label='Tipo'>
            <Select
              placeholder='Todos'
              options={planTypeOptions}
              value={planType}
              error={errors.planType}
              onChange={onInputChange('planType')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2}>
          <InputFormGroup label='Ativo'>
            <Select
              placeholder='Todos'
              options={enabledOptions}
              value={enabled}
              error={errors.enabled}
              onChange={onInputChange('enabled')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <Button css={button} onClick={onSearch}>
            Pesquisar
          </Button>
        </Column>
      </div>
    </Card>
  )
}
