import { useCallback, useState } from 'react'
import { BankTransfersService } from 'Core/Service/bankTransfers'
import { useToast } from 'Shared/hooks/useToast'
import { PostBankTransferPayload } from 'Core/Types/BankTransfer'
import { BankTransfersHooksTranslator } from '../i18n/BankTransfersHooksI18n'
import { useTranslator } from 'Shared/language/i18nHandler'

export const useCreateBankTransfer = () => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(BankTransfersHooksTranslator)

  const createBankTransfer = useCallback(async (createBankTransferPayload: PostBankTransferPayload) => {
    setLoading(true)
    return await BankTransfersService.postBankTransfer(createBankTransferPayload).then(
      () => {
        addToast({
          typeToast: 'success',
          defaultMessage: i18n?.transferBetweenAccountCreatedSuccess
        })
        return true
      }).catch(errors => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorCreatingTransferBetweenAccount,
        dictTranslation: i18n?.apiCodesMappingForBankTransfers
      })
      return false
    }).finally(() => {
      setLoading(false)
    })
  }, [
    addToast,
    i18n?.errorCreatingTransferBetweenAccount,
    i18n?.transferBetweenAccountCreatedSuccess,
    i18n?.apiCodesMappingForBankTransfers
  ])

  return {
    createBankTransfer,
    loadingCreateBankTransfer: loading
  }
}
