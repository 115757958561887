import { useCallback, useState } from 'react'
import { ExpenseRevenueService } from 'Core/Service/ExpenseRevenues'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesRevenuesTypesHooksTranslator } from '../i18n/ExpensesRevenuesTypesHooksI18n'

export const useExpenseRevenueAudit = () => {
  const { addToast } = useToast()
  const [datas, setDatas] = useState([])
  const [loading, setLoading] = useState(false)

  const i18n = useTranslator(ExpensesRevenuesTypesHooksTranslator)

  const fetchExpenseRevenueAudit = useCallback((expenseRevenueId) => {
    if (!expenseRevenueId) return
    setLoading(true)
    return ExpenseRevenueService.getExpenseRevenueAudit(expenseRevenueId)
      .then(setDatas)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorLoadingReveueExpenseTypeHistory
        })
      }).finally(() => {
        setLoading(false)
      })
  }, [addToast, i18n?.errorLoadingReveueExpenseTypeHistory])

  return {
    fetchExpenseRevenueAudit,
    datas,
    loading
  }
}
