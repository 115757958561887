/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableContainer,
  EditInfoIcon
} from '@bonitour/components'
import { CircleStatus, wrapperButtons } from 'Shared/styles/global'
import { Audit } from 'Shared/components/Audit/Audit'
import { statusLabelColor } from '../constants/bankAccount'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { useBankAccountAudit } from '../hooks/useBankAccountAudit'
import { identity } from 'Shared/utils/functions'

/**
 * @typedef BankAccountsTableHeaderProps
 * @prop { string[] } columns
 */
/** @type { React.FC<BankAccountsTableHeaderProps> } */
const BankAccountsTableHeader = ({ columns }) => {
  return (
    <TableHeader>
      <TableHeaderRow>
        {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
      </TableHeaderRow>
    </TableHeader>
  )
}

/**
 * @typedef BankAccountsTableBodyProps
 * @prop { boolean } loading
 * @prop { Record<string, string>[] } bankAccounts
 * @prop { Record<string, string> } i18n
 */
/** @type { React.FC<BankAccountsTableBodyProps> } */
const BankAccountsTableBody = ({ loading, bankAccounts, i18n, onEdit }) => {
  const {
    fetchBankAccountAudit,
    datas,
    loading: auditLoading
  } = useBankAccountAudit()

  return (
    <TableBody loading={loading}>
      {bankAccounts.map(
        ({
          id,
          bankName,
          branchNumber,
          accountNumber,
          enabled
        }) => (
          <TableBodyRow key={id}>
            <TableBodyCell>
              <SafeOverflow
                text={bankName}
                customCss={'max-width: 20rem'}
              />
            </TableBodyCell>
            <TableBodyCell>{branchNumber}</TableBodyCell>
            <TableBodyCell>{accountNumber}</TableBodyCell>
            <TableBodyCell>
              <CircleStatus statusColor={statusLabelColor[enabled].color}>
                {i18n.bankAccountStatusCodesMapped[enabled].label}
              </CircleStatus>
            </TableBodyCell>
            <TableBodyCell css={wrapperButtons}>
              <EditInfoIcon onClick={() => onEdit(id)} />
              <Audit
                documentId={id}
                auditDatas={datas}
                auditLoading={auditLoading}
                fetchAuditDatas={fetchBankAccountAudit}
                modalTitle={i18n.modalAuditBankAccountTitle}
                i18n={i18n.auditLabels}
              />
            </TableBodyCell>
          </TableBodyRow>
        ))}
    </TableBody>
  )
}

/**
 * @typedef BankAccountsListProps
 * @prop { boolean } isLoading
 * @prop { Record<string, string>[] } bankAccounts
 * @prop { Record<string, string> } i18n
 * @prop { (id: string) => void } onEdit
 */
/** @type { React.FC<BankAccountsListProps> } */
export const BankAccountsList = ({ bankAccounts, isLoading, i18n, onEdit = identity }) => {
  return (
    <>
      <TableContainer>
        <BankAccountsTableHeader columns={i18n.bankAccountTableColumnsTitles} />
        <BankAccountsTableBody
          loading={isLoading}
          bankAccounts={bankAccounts}
          i18n={i18n}
          onEdit={onEdit}
        />
      </TableContainer>
    </>

  )
}
