interface ConfigurationsHooksTranslatorRes {
  updatedConfiguration: string,
  errorWhenSearchingForConfigurations: string,
  errorUpdatingConfiguration: string,
  apiCodesMappingForConfigurations: {
    'configuration::forgotten_expenses::exceeds_limit': string,
    'configuration::forgotten_revenues::exceeds_limit': string,
    'configuration::max_recurrences::exceeds_limit': string
  },
}

export const ConfigurationsHooksTranslator = (
  dictionary
): ConfigurationsHooksTranslatorRes => {
  const apiCodesMappingForConfigurations = {
    'configuration::forgotten_expenses::exceeds_limit': dictionary?.configuration_forgottenExpensesExceedsLimit_label,
    'configuration::forgotten_revenues::exceeds_limit': dictionary?.configuration_forgottenRevenuesExceedsLimit_label,
    'configuration::max_recurrences::exceeds_limit': dictionary?.configuration_maxRecurrencesExceedsLimit_label
  }

  const updatedConfiguration = dictionary?.configuration_updatedConfiguration_toast
  const errorWhenSearchingForConfigurations = dictionary?.configuration_errorWhenSearchingForConfigurations_toast
  const errorUpdatingConfiguration = dictionary?.configuration_errorUpdatingConfiguration_toast

  return {
    updatedConfiguration,
    errorWhenSearchingForConfigurations,
    errorUpdatingConfiguration,
    apiCodesMappingForConfigurations
  }
}
