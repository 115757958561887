import { SafeDate } from '@binamik/js-providers'
import {
  BANK_CONCILIATION,
  BILLING_BANK_TRANSFER,
  BILLING_EXPENSE,
  BILLING_REVENUE,
  DESCRIPTION,
  FINANCIAL_RESERVATION_PAYMENT,
  TICKET_SELLER_GUIDE_PAYMENT_BATCH
} from 'Constants/originType'
import {
  conciliationsAccountMovementsWidths,
  conciliationsBankStatementsColumnsWidths,
  partialConciliationsAccountMovementsWidths,
  partialConciliationsBankStatementsColumnsWidths
} from 'Domains/BankAction/BankConciliations/constants/bankConciliations'
import { BankStatementsParser } from './bankStatements'

export const BankConciliationParser = {
  getBankConciliations: (data) => {
    const conciliationsData = data?.table?.conciliated?.conciliations || []
    const partialConciliationsData = data.table?.conciliated?.partial_conciliations || []
    const notConciliationsData = data?.table?.not_conciliated || {}

    const conciliationsItems = data?.table?.conciliated?.conciliated_items || 0
    const partialConciliationsItems = data?.table?.conciliated?.partial_items || 0
    const notConciliationsItems = data?.table?.not_conciliated?.items || 0

    return {
      conciliationsItems,
      partialConciliationsItems,
      notConciliationsItems,
      conciliations: conciliationsData?.map(
        conciliation => BankConciliationParser.single(
          {
            conciliation,
            externalMovementsColumnsWidths: conciliationsBankStatementsColumnsWidths,
            internalMovementsColumnsWidths: conciliationsAccountMovementsWidths
          }
        )
      ),
      partialConciliations: partialConciliationsData?.map(
        conciliation => BankConciliationParser.single(
          {
            conciliation,
            withValueDiference: true,
            externalMovementsColumnsWidths: partialConciliationsBankStatementsColumnsWidths,
            internalMovementsColumnsWidths: partialConciliationsAccountMovementsWidths
          }
        )
      ),
      notConciliations: {
        bankStatements: notConciliationsData?.bank_statements.map(
          bankStatement => BankConciliationParser.bankStatement({ bankStatement })
        ),
        accountMovements: notConciliationsData?.account_movements.map(
          accountMovement => BankConciliationParser.accountMovement(
            {
              accountMovement,
              options: { align: 'right' }
            }
          )
        )
      }
    }
  },
  single: (
    {
      conciliation,
      withValueDiference = false,
      externalMovementsColumnsWidths,
      internalMovementsColumnsWidths
    }
  ) => {
    const {
      id,
      date,
      user_name,
      value_diference,
      bank_statements,
      account_movements,
      status_details,
      created_at
    } = conciliation ?? {}

    const safeStatusDetails = status_details || ''
    const regexStatusDetails = /divergent_value|divergent_date/g
    const hasShowStatusDetails = status_details !== 'ok'

    const safeDate = created_at ? new SafeDate(created_at) : new SafeDate()

    const dataArray = [
      {
        data: bank_statements.map(bankStatement => BankConciliationParser.bankStatement(
          { bankStatement }
        )),
        type: 'table',
        options: {
          colSpan: 4,
          arrayWidths: externalMovementsColumnsWidths
        }
      },
      {
        data: account_movements.map(accountMovement => BankConciliationParser.accountMovement(
          { accountMovement, options: { align: 'right' } }
        )),
        type: 'table',
        options: {
          colSpan: 3,
          arrayWidths: internalMovementsColumnsWidths
        }
      },
      {
        data: {
          userName: user_name,
          statusDetails: safeStatusDetails,
          createdAt: safeDate,
          regexStatusDetails
        },
        options: { hasShowStatusDetails },
        type: 'info'
      }
    ]

    if (withValueDiference) {
      dataArray.splice(1, 0, {
        data: value_diference,
        type: 'money',
        options: {
          removeValueNegative: true,
          safeOverflowClassName: 'safe_overflow_value_diference',
          classNameMoney: 'value_color__negative td_value_diference'
        }
      })
    }

    return {
      id,
      date,
      values: dataArray
    }
  },
  accountMovement: ({ accountMovement, options = {} }) => {
    const {
      id,
      date,
      value,
      info,
      origin_type,
      operation_type
    } = accountMovement ?? {}

    const isDebit = operation_type.includes('debit')

    const {
      label,
      origin_bank_name,
      destination_bank_name,
      description,
      invoice_number,
      partner_name,
      status,
      service_name,
      expense_revenue_description,
      payment_method,
      gateway,
      reservation_code,
      operation_type: infoOperationType
    } = info

    const safePartnerName = Array.isArray(partner_name) && partner_name.length ? partner_name.join(',') : (partner_name || '')

    const accountMovementParsed = {
      label: label || '',
      originBankName: origin_bank_name || '',
      destinationBankName: destination_bank_name || '',
      partnerName: safePartnerName,
      invoiceNumber: invoice_number || '',
      operationType: operation_type,
      originType: [
        BILLING_BANK_TRANSFER,
        TICKET_SELLER_GUIDE_PAYMENT_BATCH,
        BILLING_REVENUE,
        BILLING_EXPENSE,
        FINANCIAL_RESERVATION_PAYMENT
      ].includes(origin_type) ? origin_type : DESCRIPTION,
      type: BANK_CONCILIATION,
      description: description || '',
      infoStatus: status || '',
      serviceName: service_name || '',
      expenseRevenueDescription: expense_revenue_description || '',
      paymentMethod: payment_method || '',
      gateway: gateway || '',
      reservationCode: reservation_code || '',
      infoOperationType: infoOperationType || ''
    }

    return {
      id,
      values: [
        { data: date, type: 'date' },
        { data: accountMovementParsed, type: 'safe-overflow', options: { ...options, isDescriptionTranslated: true } },
        {
          data: isDebit ? `-${value}` : value,
          type: 'money',
          options: {
            classNameMoney: isDebit ? 'value_color__negative' : 'value_color__positive'
          }
        }
      ]
    }
  },
  bankStatement: ({ bankStatement }) => {
    const {
      id,
      date,
      transaction_description,
      document_number,
      value
    } = bankStatement ?? {}

    return {
      id,
      values: [
        { data: date, type: 'date' },
        {
          data: BankStatementsParser.bankStatementDescription(transaction_description),
          type: 'safe-overflow',
          options: { isDescriptionTranslated: true }
        },
        { data: document_number, type: 'safe-overflow' },
        {
          data: value,
          type: 'money',
          options: { classNameMoney: value.includes('-') ? 'value_color__negative' : 'value_color__positive' }
        }
      ]
    }
  },
  postAutoBankConciliation: ({ conciliated }) => {
    return conciliated
  },
  postManualBankConciliation: (conciliation) => {
    const isPartialConciliation = conciliation.status === 'partial'
    const externalMovementsColumnsWidths = isPartialConciliation
      ? partialConciliationsBankStatementsColumnsWidths
      : conciliationsBankStatementsColumnsWidths

    const internalMovementsColumnsWidths = isPartialConciliation
      ? partialConciliationsAccountMovementsWidths
      : conciliationsAccountMovementsWidths

    const newConciliation = BankConciliationParser.single({
      conciliation,
      withValueDiference: isPartialConciliation,
      externalMovementsColumnsWidths,
      internalMovementsColumnsWidths
    })

    const result = isPartialConciliation ? {
      partialConciliation: newConciliation, conciliation: ''
    } : { conciliation: newConciliation, partialConciliation: '' }

    return result
  }
}
