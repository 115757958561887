import { pluralize } from 'Shared/language/utils/dictionaryUtils'
import { templatePluralizeWithTemplateString } from 'Shared/language/utils/functions'

export interface BankTransfersTranslatorRes {
  modalAuditExpenseTitle: string
  auditLabels: {
    value: string
    service_name: string
    payment_method: string
    date: string
    taxes: string
    reference_date: string
    observation: string
    status: string
    payment_day: string
    expense_revenue_type: string
    bank_account_name: string
    expense_revenue_name: string
    accountable_plan_name: string
  }
  accountsPayable: string
  newAccount: string
  newRecurringAccount: string
  accountPayableScheduledUnpaid: (qty: number) => string
  typeOfExpense: string
  productAndService: string
  supplier: string
  selectASupplier: string
  payment: string
  paymentMethod: string
  registrationDate: string
  selectPaymentDate: string
  selectReferenctDate: string
  selectExpenseType: string
  paymentDayOfTheMonth: string
  selectThePaymentDayOfTheMonth: string
  bankFee: string
  recurrenceType: string
  informationOnTypesOfRecurrences: string
  recurringPayment: string
  paymentAmountWillBeTheSameAsTheSchedulingAmount: string
  installment: string
  appointmentCostPerRepetition: string
  selectTheTypeOfRecurrence: string
  numberOfRepetitions: string
  fillInNumberOfRepetitions: string
  changeInformation: string
  replicateChange: string
  applyChangesToOtherRecurringPayments: string
  doNotReplicateChanges: string
  theEditOnlyAffectsTheCurrentPayment: string
  selectTheChange: string
  addedReceipt: (qty: number) => string
  receipts: string
  addOrRemoveReceitps: string
  dragTheImageOrClickToAddMaximum1Mb: string
  simulationOfRecurringPayments: string
  datesOfEachPaymentFromThisCurrentOne: string
  amountOfEachPayment: string
  downloadYouReceipts: string
  downloadAllReceitps: string
  total: string
  none: string
  bankAccount: string
  valueMustBeANumber: string
  fillInTheValue: string
  expensesColumnsTitles: string[]
  dateFormat: string
  searchButton: string
  accountPayableRegistration: string
  editPayableAccount: string
  register: string
  save: string
  fillOuTheSimulationForm: string
  recurringPayableAccountRegistration: string
  editRecurringPayableAccountRegistration: string
  exit: string
  viewPayableAccount: string
  onTheLastDay: (qty: number) => string
  editBatch: string
  selectedPayableAccount: (qty: number) => string
  editPayablesInBatch: string
  updateExpensesColumnsTitles: string[]
  value: string
  totalBatchAccounts: string
}

export const ExpensesTranslator = (
  dictionary, locale
): BankTransfersTranslatorRes => {
  const modalAuditExpenseTitle = dictionary?.expense_auditModalTitle_label

  const auditLabels = {
    value: dictionary?.audit_value_label,
    service_name: dictionary?.audit_serviceName_label,
    payment_method: dictionary?.audit_paymentMethod_label,
    date: dictionary?.audit_date_label,
    taxes: dictionary?.audit_taxes_label,
    reference_date: dictionary?.audit_referenceDate_label,
    observation: dictionary?.audit_observation_label,
    status: dictionary?.audit_status_label,
    payment_day: dictionary?.audit_paymentDay_label,
    expense_revenue_type: dictionary?.audit_expenseRevenueType_label,
    bank_account_name: dictionary?.audit_bankAccountName_label,
    expense_revenue_name: dictionary?.audit_expenseName_label,
    accountable_plan_name: dictionary?.audit_accountablePlanName_label,
    external_origin_type: dictionary?.audit_ivoiceType_label
  }

  const accountsPayable = dictionary?.expense_accountsPayable_label
  const newAccount = dictionary?.expense_newAccount_label
  const newRecurringAccount = dictionary?.expense_newRecurringAccount_label

  const accountPayableScheduledUnpaid = (qty: number) => {
    return templatePluralizeWithTemplateString(
      {
        qty,
        locale,
        dictKey: dictionary?.expense_accountPayableScheduledUnpaid_label,
        keyReplaced: 'FORGOTTEN'
      }
    )
  }

  const onTheLastDay = (qty: number) => {
    return templatePluralizeWithTemplateString(
      {
        qty,
        locale,
        dictKey: dictionary?.expense_onTheLastDay_label,
        keyReplaced: 'DAYS_AGO'
      }
    )
  }

  const typeOfExpense = dictionary?.expense_typeOfExpense_label
  const productAndService = dictionary?.expense_productAndService_label
  const supplier = dictionary?.expense_supplier_label
  const selectASupplier = dictionary?.expense_selectASupplier_label
  const payment = dictionary?.expense_payment_label
  const paymentMethod = dictionary?.expense_paymentMethod_label
  const registrationDate = dictionary?.expense_registrationDate_label
  const selectPaymentDate = dictionary?.expense_selectPaymentDate_label
  const selectReferenctDate = dictionary?.expense_selectReferenctDate_label
  const selectExpenseType = dictionary?.expense_selectExpenseType_label
  const paymentDayOfTheMonth = dictionary?.expense_paymentDayOfTheMonth_label
  const selectThePaymentDayOfTheMonth = dictionary?.expense_selectThePaymentDayOfTheMonth_label
  const bankFee = dictionary?.expense_bankFee_label
  const recurrenceType = dictionary?.expense_recurrenceType_label
  const informationOnTypesOfRecurrences = dictionary?.expense_informationOnTypesOfRecurrences_label
  const recurringPayment = dictionary?.expense_recurringPayment_label
  const paymentAmountWillBeTheSameAsTheSchedulingAmount = dictionary?.expense_paymentAmountWillBeTheSameAsTheSchedulingAmount_label
  const installment = dictionary?.expense_installment_label
  const appointmentCostPerRepetition = dictionary?.expense_appointmentCostPerRepetition_label
  const selectTheTypeOfRecurrence = dictionary?.expense_selectTheTypeOfRecurrence_label
  const numberOfRepetitions = dictionary?.expense_numberOfRepetitions_label
  const fillInNumberOfRepetitions = dictionary?.expense_fillInNumberOfRepetitions_label
  const changeInformation = dictionary?.expense_changeInformation_label
  const replicateChange = dictionary?.expense_replicateChange_label
  const applyChangesToOtherRecurringPayments = dictionary?.expense_applyChangesToOtherRecurringPayments_label
  const doNotReplicateChanges = dictionary?.expense_doNotReplicateChanges_label
  const theEditOnlyAffectsTheCurrentPayment = dictionary?.expense_theEditOnlyAffectsTheCurrentPayment_label
  const selectTheChange = dictionary?.expense_selectTheChange_label

  const addedReceipt = (qty: number) => {
    return templatePluralizeWithTemplateString(
      {
        qty,
        locale,
        dictKey: dictionary?.expense_addedReceipt_label,
        keyReplaced: 'Number'
      }
    )
  }

  const receipts = dictionary?.expense_receipts_label
  const addOrRemoveReceitps = dictionary?.expense_addOrRemoveReceitps_label
  const dragTheImageOrClickToAddMaximum1Mb = dictionary?.expense_dragTheImageOrClickToAddMaximum1Mb_label
  const simulationOfRecurringPayments = dictionary?.expense_simulationOfRecurringPayments_label
  const datesOfEachPaymentFromThisCurrentOne = dictionary?.expense_datesOfEachPaymentFromThisCurrentOne_label
  const amountOfEachPayment = dictionary?.expense_amountOfEachPayment_label
  const downloadYouReceipts = dictionary?.expense_downloadYouReceipts_label
  const downloadAllReceitps = dictionary?.expense_downloadAllReceitps_label

  const total = dictionary?.common_total_label
  const none = dictionary?.common_none_label
  const bankAccount = dictionary?.common_bankAccount_label
  const valueMustBeANumber = dictionary?.common_valueMustBeANumber_label
  const fillInTheValue = dictionary?.common_fillInTheValue_label

  const expensesColumnsTitles = [
    dictionary?.expense_payment_label,
    dictionary?.expense_supplier_label,
    dictionary?.expense_typeOfExpense_label,
    dictionary?.expense_productAndService_label,
    dictionary?.common_value_label,
    dictionary?.common_status_label,
    dictionary?.common_user_label,
    dictionary?.common_actions_label
  ]

  const dateFormat = dictionary?.common_dateFormat_template
  const searchButton = dictionary?.common_searchButton_label

  const accountPayableRegistration = dictionary?.expense_accountPayableRegistration_label
  const editPayableAccount = dictionary?.expense_editPayableAccount_label
  const register = dictionary?.common_register_label
  const save = dictionary?.common_save_label
  const fillOuTheSimulationForm = dictionary?.expense_fillOuTheSimulationForm_label
  const recurringPayableAccountRegistration = dictionary?.expense_recurringPayableAccountRegistration_label
  const editRecurringPayableAccountRegistration = dictionary?.expense_editRecurringPayableAccountRegistration_label
  const exit = dictionary?.common_exit_label
  const viewPayableAccount = dictionary?.expense_viewPayableAccount_label

  const editBatch = dictionary?.expense_editBatch_label
  const selectedPayableAccount = (qty: number) => {
    return pluralize(
      dictionary?.expense_selectedPayableAccount_label,
      qty,
      locale
    )
  }
  const editPayablesInBatch = dictionary?.expense_editPayablesInBatch_label
  const updateExpensesColumnsTitles = [
    dictionary?.expense_typeOfExpense_label,
    dictionary?.expense_productAndService_label,
    dictionary?.common_value_label
  ]

  const value = dictionary?.common_value_label
  const totalBatchAccounts = dictionary?.expense_totalBatchAccounts_label

  return {
    modalAuditExpenseTitle,
    auditLabels,
    accountsPayable,
    newAccount,
    newRecurringAccount,
    accountPayableScheduledUnpaid,
    typeOfExpense,
    productAndService,
    supplier,
    selectASupplier,
    payment,
    paymentMethod,
    registrationDate,
    selectPaymentDate,
    selectReferenctDate,
    selectExpenseType,
    paymentDayOfTheMonth,
    selectThePaymentDayOfTheMonth,
    bankFee,
    recurrenceType,
    informationOnTypesOfRecurrences,
    recurringPayment,
    paymentAmountWillBeTheSameAsTheSchedulingAmount,
    installment,
    appointmentCostPerRepetition,
    selectTheTypeOfRecurrence,
    numberOfRepetitions,
    fillInNumberOfRepetitions,
    changeInformation,
    replicateChange,
    applyChangesToOtherRecurringPayments,
    doNotReplicateChanges,
    theEditOnlyAffectsTheCurrentPayment,
    selectTheChange,
    addedReceipt,
    receipts,
    addOrRemoveReceitps,
    dragTheImageOrClickToAddMaximum1Mb,
    simulationOfRecurringPayments,
    datesOfEachPaymentFromThisCurrentOne,
    amountOfEachPayment,
    downloadYouReceipts,
    downloadAllReceitps,
    total,
    none,
    bankAccount,
    valueMustBeANumber,
    fillInTheValue,
    expensesColumnsTitles,
    dateFormat,
    searchButton,
    accountPayableRegistration,
    editPayableAccount,
    register,
    save,
    fillOuTheSimulationForm,
    recurringPayableAccountRegistration,
    editRecurringPayableAccountRegistration,
    exit,
    viewPayableAccount,
    onTheLastDay,
    editBatch,
    selectedPayableAccount,
    editPayablesInBatch,
    updateExpensesColumnsTitles,
    value,
    totalBatchAccounts
  }
}
