import { css } from '@emotion/core'
import { colors } from '@bonitour/components'
import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const stylishCardSearch = css`
  border: none;
  background-color: ${colors.gray8};
  margin-bottom: 20px;
  padding: 1em;

  .start_date,
  .end_date {
    width: 100%;
  }
`

export const width100 = css`
  width: 100%;
`

export const buttonStyle = css`
  height: 40px;
  width: 100%;
  margin-top: auto;
`

export const marginTop = css`
  margin-top: 15px;
`

export const FullFinancialFilterContainer = styled.div`
  .form__inputs {
    display: grid;
    justify-content: flex-end;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.625rem;
    padding: 1.25rem;
    border-radius: 0.625rem;
    background-color: var(--clr-gray8);

    .input__start_date,
    .input__end_date {
      width: 100%;
    }

    .full_financial_filter__btn {
      height: 2.5625rem;
      margin-top: 1.25rem; 
      width: 100%;
    }
  }


  @media(max-width: ${BREAK_POINTS.tablet}) {
    .form__inputs {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`
