/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, CloseIcon, GhostButton, UploadIcon, colors, flexColumnCentered, flexRow, flexCenteredSpaceBetweenRow, ProgressBar } from '@bonitour/components'
import { bold, border, labelStyle, iconStyle, marginTop10, marginRight20, paddingHorizontal, width60, marginBottom40 } from './ModalContent.style'

export const ModalContent = ({ onClose = () => {} }) => {
  const renderSituationFileService = () => {
    const uploading = true
    if (!uploading) {
      return (
        <>
          <small css={[labelStyle(15, colors.gray1), bold]}>Arquivo CSV</small>
          <div css={[border, flexColumnCentered, marginTop10]}>
            <UploadIcon css={iconStyle(30, colors.gray3)} />
            <small css={[labelStyle(14, colors.gray3, 15, 0), bold]}>Arraste o arquivo ou clique para adicionar</small>
          </div>
          <div css={[marginTop10, flexColumnCentered]}>
            <small css={labelStyle(14, colors.gray5, 20, 20)}>Arquivo validado com sucesso!</small>
            <div css={flexRow}>
              <GhostButton onClick={onClose} css={[marginRight20, paddingHorizontal]}>Voltar</GhostButton>
              <Button css={paddingHorizontal}>Validar</Button>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div css={[flexCenteredSpaceBetweenRow, width60]}>
            <div>
              <small css={[labelStyle(13, colors.gray3, 15, 0, 0, 5), bold]}>planodecontas.csv</small>
              <small css={[labelStyle(10, colors.gray5, 15, 0, 0, 0), bold]}>completo</small>
            </div>
            <CloseIcon css={iconStyle(14, colors.gray5)} />
          </div>
          <div css={[width60, marginBottom40]}>
            <ProgressBar fillPercentage='75' background={colors.indigoGradient1} />
          </div>
          <span css={[labelStyle(14, colors.green2, 15, 0), marginBottom40]}>Arquivo validado com sucesso!</span>
          <div css={flexRow}>
            <GhostButton onClick={onClose} css={[marginRight20, paddingHorizontal]}>Voltar</GhostButton>
            <Button css={paddingHorizontal}>Validar</Button>
          </div>
        </>
      )
    }
  }

  return (
    <div css={flexColumnCentered}>
      <span css={[labelStyle(15, colors.gray5, 40, 20), bold]}>Formatação do arquivo <span css={[labelStyle(15, colors.gray3), bold]}>CSV</span>:</span>
      <span css={[labelStyle(15, colors.gray1, 0, 10), bold]}>Código;Classificação;Nome do Plano de Contas</span>
      <small css={[labelStyle(13, colors.gray5, 0, 40)]}>O Nível e o Tipo do Plano de Contas serão determinados automaticamente.</small>
      {renderSituationFileService()}
    </div>
  )
}
