
import { useHistory, useLocation } from 'react-router-dom'
import { getQueryParams } from '@bonitour/common-functions'
import { useCallback, useMemo } from 'react'

type UseQueryReturnType<T extends Record<string, any>> = [
  T,
  {
    changeQueryParam: (key: keyof T, value: T[keyof T]) => void;
    removeQueryParam: (key: keyof T) => void;
  },
  T
]

const stringifyQueryParams = (params: any) => {
  return new URLSearchParams(params).toString()
}

export const useQuery = <T extends Record<string, any>>(): UseQueryReturnType<T> => {
  const { push } = useHistory()
  const { search = '' } = useLocation()

  const queryParams = useMemo(() => getQueryParams(search) as T, [search])

  const changeQueryParam = useCallback((key: keyof T, value: T[keyof T]) => {
    const newParams = { ...queryParams, [key]: encodeURIComponent(String(value)) }
    push({ search: stringifyQueryParams(newParams) })
  }, [push, queryParams])

  const removeQueryParam = useCallback((key: keyof T) => {
    const newParams = { ...queryParams }
    delete newParams[key]
    push({ search: stringifyQueryParams(newParams) })
    return `?${stringifyQueryParams(newParams)}`
  }, [push, queryParams])

  return [
    queryParams,
    { changeQueryParam, removeQueryParam },
    search
  ]
}
