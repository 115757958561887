import { head, tail } from '@bonitour/common-functions'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from './useQuery'
import { SafeDate } from '@binamik/js-providers'

export const useParamsFilter = <T extends Record<string, any>>(
  defaultFilters: T = {} as Partial<T> as T
): [T, (filters: T) => void] => {
  const history = useHistory()
  const [params] = useQuery<T>()

  const pushQueryParams = useCallback((filters: T) => {
    const queryParams = Object.entries(filters).reduce((acc, item) => {
      const key = head(item)
      const value = tail(item)

      if (!value) {
        return acc
      }

      if (Array.isArray(value)) {
        return acc.concat(
          value.reduce(
            (acc, item) => acc.concat(`${key}[]=${encodeURIComponent(item)}&`)
            , ''
          )
        )
      }

      if (value instanceof Date) {
        return acc.concat(`${key}=${new SafeDate(value).isoDate}&`)
      }

      return acc.concat(`${key}=${encodeURIComponent(value)}&`)
    }, '')

    history.push({ search: `?${queryParams.slice(0, -1)}` })
  }, [history])

  const changeParams = (filters: T) => pushQueryParams(filters)

  useEffect(() => {
    if (Object.keys(defaultFilters)?.length === 0) return
    if (Object.keys(params)?.length) return

    changeParams(defaultFilters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilters])

  return [
    params,
    changeParams
  ]
}
