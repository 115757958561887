import { billingCore } from './Billing'
import { ReceivablesModel } from 'Core/Models/receivables'
import { ReceivablesParser } from 'Core/Parser/Receivables'
import { convertToFormData } from 'Shared/utils/api'
import {
  DeleteGatewayPaymentsParams,
  DeleteGatewayPaymentsResponse
} from '../Types/Receivable'

export const ReceivablesService = {
  list ({ filters }) {
    return billingCore.gatewayPaymentsDomain.list(ReceivablesModel.list({ filters })).then(ReceivablesParser.list)
  },
  import ({ startDate, endDate }) {
    return billingCore.gatewayPaymentsImportDomain.importGatewayPayments(ReceivablesModel.import({ startDate, endDate })).then(ReceivablesParser.import)
  },
  export ({ filters, i18n }) {
    return billingCore.gatewayPaymentsDomain.list(ReceivablesModel.export({ filters })).then(
      (data) => ReceivablesParser.exportCsvReceivables(data, i18n, filters)
    )
  },
  importFile ({ file }) {
    const payload = convertToFormData(ReceivablesModel.importFile({ file }))
    return billingCore.gatewayPaymentsImportDomain.importGatewayPaymentsFile(payload).then(ReceivablesParser.import)
  },
  deleteGatewayPayments: async (
    params: DeleteGatewayPaymentsParams
  ): Promise<DeleteGatewayPaymentsResponse> => {
    return await billingCore.gatewayPaymentsDomain.deleteGatewayPayments(
      ReceivablesModel.deleteGatewayPayments(params)
    ).then(ReceivablesParser.deleteGatewayPayments)
  }
}
