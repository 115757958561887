import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'
import { motion } from 'framer-motion'

export const SimulatorRecurrencyContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  border-radius: 0.5rem;
  padding: 1.25rem;
  background-color: var(--clr-white);
  width: 500px;
  position: relative;

  * {
    box-sizing: border-box;
  }

  .simulator__title {
    color: var(--clr-gray2);
    font-weight: 700;
    font-size: 18px;
  }

  .simulator__subtitle {
    color: var(--clr-gray3);
    font-size: 16px;
    font-weight: 500;
  }

  .simulator__items_content {
    padding: 0.5rem;
    margin: 0.625rem 0;
    display: grid;
    align-content: flex-start;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
    max-height: 560px;
    height: 100%;
    overflow-y: auto;

    .wrapper__value_date {
      display: flex;
      justify-content: center;
      gap: 0.625rem;
      padding: 0.5rem;
      border-radius: var(--round-element-radius, 100%);
      box-shadow: 0px 0px 6px #0000001a;
      border: 1px solid var(--clr-gray6);

      b {
        font-weight: 500;
      }
    }
  }

  @media(max-width: ${BREAK_POINTS.tablet}) {
    width: 100%;
   
    .simulator__items_content {
      grid-template-columns: repeat(2, 1fr);
      max-height: none;
      overflow-y: none;
    }
  }

  @media(max-width: ${BREAK_POINTS.smallTablet}) {
    .simulator__items_content {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`
