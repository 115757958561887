import { useCallback, useState } from 'react'
import { exportCsv } from '@bonitour/common-functions'
import { FinancialScheduleService } from 'Core/Service/FinancialSchedule'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { CreditcardMovementHooksTranslator } from '../i18n/CreditcardMovementsHooksI18n'

export const useExportMoves = () => {
  const { addToast } = useToast()
  const [movesExportedData, setMovesExportedData] = useState([])

  const i18n = useTranslator(CreditcardMovementHooksTranslator)

  const [initialReceivablesFilters] = useState({
    exportFormat: 'csv',
    page: 1,
    perPage: 10
  })

  const fetchExportMoves = useCallback((filters) => {
    return FinancialScheduleService.export({ filters: { ...initialReceivablesFilters, ...filters } }).then((data) => {
      setMovesExportedData(data)
      exportCsv('movimentacoes', data)
    }).catch(errors => {
      console.errors(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorWhenExportingFinancialCards
      })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    fetchExportMoves,
    movesExportedData
  }
}
