import { AuditParser } from './audit'

/* eslint-disable camelcase */
export const BankAccountParser = {
  single: (bankAccount) => {
    const {
      id,
      account_number: accountNumber,
      account_type: accountType,
      bank_account_infos: bankAccountInfos,
      bank_name: bankName,
      bank_number: bankNumber,
      billing_account_id: billingAccountId,
      branch_number: branchNumber,
      company_id: companyId,
      enabled,
      event_id: eventId,
      origin_system: originSystem,
      user_id: userId,
      payment_methods: paymentMethods,
      accountable_plan_id: accountablePlanId
    } = bankAccount?.bank_account ?? bankAccount ?? {}
    return {
      id,
      accountNumber,
      accountType,
      bankAccountInfos,
      bankName,
      bankNumber,
      billingAccountId,
      branchNumber,
      companyId,
      enabled: enabled ? 'enabled' : 'disabled',
      eventId,
      originSystem,
      userId,
      paymentMethods,
      accountablePlanId
    }
  },
  list: ({ bank_accounts: bankAccounts = [], meta = {} }) => {
    return {
      bankAccounts: bankAccounts.map(BankAccountParser.single),
      meta
    }
  },
  options: ({ bank_accounts: bankAccounts = [] }) => {
    return bankAccounts.map(
      (
        {
          id: value,
          bank_name: label,
          payment_methods: paymentMethods,
          suported_file_formats: suportedFileFormats,
          bank_number: bankNumber
        }
      ) => (
        {
          value,
          label,
          paymentMethods,
          suportedFileFormats,
          bankNumber
        }
      ))
  },
  getBankAccountAudit: (data) => {
    const fieldsToFilter = [
      'payment_methods', 'account_number', 'origin_system', 'bank_number', 'bank_name',
      'branch_number', 'origin_system', 'enabled', 'account_type'
    ]

    return AuditParser.getAudits({ data, fieldsToFilter })
  }
}
