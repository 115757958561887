/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, Fragment } from 'react'
import { formatMoney, formatDate } from '@bonitour/common-functions'
import { ExpanderDetails } from './ExpanderDetails/ExpanderDetails'
import {
  sizeIcon,
  collapsableIconOpen,
  fontColor,
  customLinkWidth,
  iconInfoStyle,
  internCustomLinkWidth,
  borderLeftFill,
  borderRightFill,
  borderCenterFill,
  tableBodyContainer
} from './FinancialMovements.styles'
import circleInfoIcon from 'Shared/svgs/circleInfoIcon.svg'
import {
  TableBody,
  TableBodyRow,
  TableBodyCell,
  SortRightIcon,
  Logo
} from '@bonitour/components'
import { FinancialScheduleParser } from 'Core/Parser/FinancialSchedule'
import { CircleStatus } from 'Shared/styles/global'
import { Tooltip } from 'Shared/components/Tooltip'

export const FinancialScheduleTableBody = (
  {
    data,
    loading,
    toggleVisibleModal,
    setModalData
  }
) => {
  const [indexDetail,
    setIndexDetail] = useState([])

  const toggleExpander = (index) => {
    if (indexDetail.includes(index)) {
      const newArray = indexDetail.filter((arrayIndex) => arrayIndex !== index)
      setIndexDetail(newArray)
    } else {
      setIndexDetail([...indexDetail,
        index])
    }
  }

  const isExpanded = (index) => indexDetail.includes(index)

  const onClickDetail = (index) => () => toggleExpander(index)

  const onClickOpenModal = (partnerName,
    clientName) => {
    setModalData(partnerName?.length ? partnerName : clientName)
    toggleVisibleModal()
  }

  const isRefund = (creditcardOperationType) => creditcardOperationType === 'refund'

  return (
    <TableBody loading={loading}>
      {data.map(({
        id,
        date,
        authorizationCode,
        proofOfSale,
        reservationCode,
        partnerName,
        operationType = 'error',
        value,
        installment,
        installments,
        brand,
        status,
        clientName,
        creditcardOperationType,
        transactionDate,
        transactionFee,
        antifraudFee,
        mdrFee,
        payFee,
        netAmount,
        grossAmount,
        conciliated,
        label
      }, index) => (
        <Fragment key={id}>
          <TableBodyRow css={[tableBodyContainer]}>
            <TableBodyCell css={isRefund(creditcardOperationType) && borderLeftFill}>
              <SortRightIcon onClick={onClickDetail(index)} css={[sizeIcon, isExpanded(index) && collapsableIconOpen]} />
              {formatDate(date)}
            </TableBodyCell>
            <TableBodyCell css={isRefund(creditcardOperationType) && borderCenterFill}><span>{reservationCode}</span></TableBodyCell>
            <TableBodyCell css={[customLinkWidth, isRefund(creditcardOperationType) && borderCenterFill]}>
              <div css={internCustomLinkWidth}>
                {partnerName && Array.isArray(partnerName) ? partnerName?.join(', ') : partnerName}
                {!partnerName?.length && clientName}
              </div>
              <Logo onClick={() => onClickOpenModal(partnerName, clientName)} css={iconInfoStyle} src={circleInfoIcon} />
            </TableBodyCell>
            <TableBodyCell
              css={
                [
                  fontColor(
                    FinancialScheduleParser.flowMovementsOperationType[operationType].color
                  ),
                  isRefund(creditcardOperationType) && borderCenterFill]
              }
            >{FinancialScheduleParser.flowMovementsOperationType[operationType].label}
            </TableBodyCell>
            <TableBodyCell css={isRefund(creditcardOperationType) && borderCenterFill}>{authorizationCode}</TableBodyCell>
            <TableBodyCell css={isRefund(creditcardOperationType) && borderCenterFill}>{proofOfSale}</TableBodyCell>
            <TableBodyCell css={
              [
                fontColor(FinancialScheduleParser.flowMovementsOperationType[operationType].color),
                isRefund(creditcardOperationType) && borderCenterFill]
            }
            >
              {formatMoney(value)}
            </TableBodyCell>
            <TableBodyCell css={isRefund(creditcardOperationType) && borderRightFill}>
              <CircleStatus
                statusColor={conciliated ? '--clr-blue1' : '--clr-gray7'}
                className='circle__conciliated'
              >
                <Tooltip
                  verticalPosition='center'
                  horizontalPosition='left'
                  tooltipClass='tooltip__conciliated'
                >
                  {conciliated ? 'Conciliado' : 'Não conciliado'}
                </Tooltip>
              </CircleStatus>
            </TableBodyCell>
          </TableBodyRow>
          {indexDetail.includes(index) &&
            <TableBodyCell colSpan='100%'>
              <ExpanderDetails
                key={index}
                installment={installment}
                installments={installments}
                brand={brand}
                status={status}
                creditcardOperationType={creditcardOperationType}
                transactionDate={transactionDate}
                transactionFee={transactionFee}
                antifraudFee={antifraudFee}
                mdrFee={mdrFee}
                payFee={payFee}
                netAmount={netAmount}
                grossAmount={grossAmount}
                label={label}
              />
            </TableBodyCell>}
        </Fragment>
      ))}
    </TableBody>
  )
}
