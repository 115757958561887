import { useCallback, useEffect, useState, useRef } from 'react'
import { FinancialScheduleService } from 'Core/Service/FinancialSchedule'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { CreditcardMovementHooksTranslator } from '../i18n/CreditcardMovementsHooksI18n'

export const useListMoves = () => {
  const { addToast } = useToast()
  const [moveFilters, setMoveFilters] = useState({
    reservationCode: '',
    partnerName: '',
    proofOfSale: '',
    brand: '',
    creditCardOperationType: '',
    gateway: ''
  })
  const [loading, setLoading] = useState(false)
  const [moveData, setMoveData] = useState([])

  const { initStatePagination, perPageOptions } = usePaginationOptions()

  const initPagination = useRef(false)

  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false)

  const [pagination, setPagination] = useState(initStatePagination)

  const i18n = useTranslator(CreditcardMovementHooksTranslator)

  const fetchCreditcardMovements = useCallback(({ filters, pagination }) => {
    setLoading(true)
    return FinancialScheduleService.list({ filters, pagination }).then((data) => {
      setMoveData(data)
    }).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorLoadingTheFinancialCardsSchedule
      })
    }).finally(() => {
      setLoading(false)
    })
  }, [addToast, i18n?.errorLoadingTheFinancialCardsSchedule])

  useEffect(() => {
    if (!(
      moveFilters.startDate &&
      moveFilters.endDate &&
      moveFilters.gateway
    )) return

    !isUpdatingFilters && fetchCreditcardMovements({
      pagination,
      filters: {
        ...moveFilters
      }
    })
  }, [pagination, moveFilters, fetchCreditcardMovements, isUpdatingFilters])

  const handlePagination = useCallback((currentPage, pageLimit) => {
    if (!initPagination.current) {
      initPagination.current = true
      return
    }

    setPagination({ currentPage, perPage: pageLimit })
  }, [])

  const onSearch = useCallback((filters) => {
    setMoveFilters((values) => ({ ...values, ...filters }))

    setIsUpdatingFilters(true)

    setPagination({ currentPage: 1, perPage: pagination.perPage })
    setTimeout(() => setIsUpdatingFilters(false))
  }, [pagination.perPage, setMoveFilters])

  return {
    moveFilters,
    onSearch,
    handlePagination,
    moveData,
    meta: moveData?.meta || {},
    loading,
    perPageOptions,
    pagination
  }
}
