
import { css } from '@emotion/core'
import { colors, flexCenteredSpaceBetweenRow } from '@bonitour/components'

export const inputCard = css`
  background-color: ${colors.gray13};
  border: none;
  width: 100%;
  ${flexCenteredSpaceBetweenRow};
  padding-right: 80px;
`

export const marginBottom20 = css`
  margin-bottom: 20px;
`

export const marginLeft10 = css`
  margin-left: 10px;
`

export const inputStyle = css`
  width: 70%;
`

export const containerFooterButtons = css`
  margin: 50px 0 30px 0;
`

export const containerCreateButton = css`
  margin-left: 20px;
`

export const group = css`
  justify-content: space-around;
`
