import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { SupplierService } from 'Core/Service/Supplier'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SuppliersHooksTranslator } from '../i18n/SuppliersHooksI18n'

export const useEditSupplier = (fetchSuppliers) => {
  const [editLoading, setEditLoading] = useState(false)
  const [supplier, setSupplier] = useState()
  const { addToast } = useToast()
  const i18n = useTranslator(SuppliersHooksTranslator)

  const fetchSupplier = useCallback((supplierId) => {
    setEditLoading(true)
    SupplierService.getByIdForEdit(supplierId)
      .then(setSupplier)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorGetByIdSupplier
        })
      }).finally(() => { setEditLoading(false) })
  }, [addToast, i18n])

  const editSupplier = useCallback((params) => {
    const { id: supplierId } = params
    setEditLoading(true)
    return SupplierService.edit(params, supplierId).then(() => {
      addToast({
        defaultMessage: i18n?.editSupplier,
        typeToast: 'success'
      })
      fetchSuppliers()
    }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorEditSupplier
      })
    }).finally(() => {
      setEditLoading(false)
    })
  }, [addToast, fetchSuppliers, i18n])

  return {
    onEditSupplier: editSupplier,
    fetchSupplier,
    setSupplier,
    supplier,
    editLoading
  }
}
