import { billingCore } from './Billing'
import { ConditionModel } from '../Models/condition'
import { ConditionParser } from 'Core/Parser/condition'

export const ConditionService = {
  getConditions (eventProcessorId) {
    return billingCore.conditionDomain.getConditions(
      eventProcessorId
    ).then(ConditionParser.getConditions)
  },
  createCondition (payload, eventProcessorId) {
    return billingCore.conditionDomain.createCondition(
      ConditionModel.conditionPayload(payload),
      eventProcessorId
    ).then(ConditionParser.conditionSingle)
  },
  getCondition (eventProcessorId, conditionId) {
    return billingCore.conditionDomain.getCondition(
      eventProcessorId, conditionId
    ).then(ConditionParser.conditionSingle)
  },
  updateCondition (
    payload, eventProcessorId, conditionId
  ) {
    return billingCore.conditionDomain.updateCondition(
      ConditionModel.conditionPayload(payload),
      eventProcessorId,
      conditionId
    )
  },
  removeCondition (eventProcessorId, conditionId) {
    return billingCore.conditionDomain.removeCondition(eventProcessorId, conditionId)
  }
}
