import { metaModel } from './Meta'

export const SuppliersModel = {
  list: ({ filters, pagination }) => {
    const {
      corporateName,
      fancyName,
      enabled
    } = filters || {}

    return {
      searchParams: {
        corporate_name: corporateName,
        fancy_name: fancyName,
        enabled: enabled === '' ? undefined : enabled
      },
      pagination: metaModel(pagination)
    }
  },
  payload: (payload) => {
    const {
      basicRegistration_enabled,
      basicRegistration_supplierType,
      basicRegistration_corporateName,
      basicRegistration_supplierDocument,
      basicRegistration_fancyName,
      basicRegistration_municipalRegistration,
      address_address,
      address_neighborhood,
      address_cityName,
      address_state,
      address_country,
      address_zipCode,
      contactDetails_emails,
      contactDetails_phoneNumbers,
      contactDetails_contactNames,
      contactDetails_observation
    } = payload ?? {}
    const zipCodeWithoutMask = address_zipCode?.replace(/\D/g, '')
    const supplierDocumentWithoutMask = basicRegistration_supplierDocument?.replace(/\D/g, '')

    return {
      enabled: basicRegistration_enabled === 'enabled',
      supplier_type: basicRegistration_supplierType,
      corporate_name: basicRegistration_corporateName,
      supplier_document: supplierDocumentWithoutMask || undefined,
      fancy_name: basicRegistration_fancyName || undefined,
      municipal_registration: basicRegistration_municipalRegistration || undefined,
      address: address_address || undefined,
      neighborhood: address_neighborhood || undefined,
      city_name: address_cityName || undefined,
      state: address_state || undefined,
      country: address_country || undefined,
      zip_code: zipCodeWithoutMask || undefined,
      emails: contactDetails_emails || undefined,
      phone_numbers: contactDetails_phoneNumbers || undefined,
      contact_names: contactDetails_contactNames || undefined,
      observation: contactDetails_observation || undefined
    }
  }
}
