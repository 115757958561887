
export const SuppliersFormErrorsTranslator = (
  dictionary
) => {
  const labels = {
    basicRegistration_corporateName: {
      corporateNameRequired: dictionary?.supplier_corporateNameRequired_formErrorLabel
    },
    basicRegistration_supplierType: {
      supplierTypeRequired: dictionary?.supplier_supplierTypeRequired_formErrorLabel
    },
    basicRegistration_supplierDocument: {
      supplierDocumentMaxCharacters: dictionary?.supplier_supplierDocumentMaxCharacters_formErrorLabel
    },
    address_zipCode: {
      zipCodeMaxCharacters: dictionary?.supplier_zipCodeMaxCharacters_formErrorLabel
    },
    address_state: {
      stateMaxCharacters: dictionary?.supplier_stateMaxCharacters_formErrorLabel
    }
  }

  return {
    errors: labels
  }
}
