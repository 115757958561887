import React, { useMemo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { cardBox, customValueCard, ExpenseSectionHeaderContainer } from './Expenses.styles'
import { cardIcon } from 'Shared/styles/global'
import { useGetExpenses } from './hooks/useGetExpenses'
import { ValuesCard } from 'Shared/components/ValuesCard/ValuesCard'
import { useExpenseRevenuesOptions } from '../ExpenseRevenueType/hooks/useExpenseRevenuesOptions'
import { useSuppliersOptions } from '../Suppliers/hooks/useSupplierOptions'
import { ExpensesTranslator } from './i18n/ExpensesI18n'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesList } from './List/ExpensesList'
import { Card, Label, PaginationBar, PlusIcon } from '@bonitour/components'
import { CardButton } from 'Shared/components/CardButton'
import { ExpensesRevenuesFilters } from '../Filters/ExpensesRevenuesFilters'
import { useBankAccountsOptions } from 'Domains/BankAction/BankAccounts/hooks/useBankAccountsOptions'
import { useQuery } from 'Shared/hooks/useQuery'
import { ActionButtons, exportBtn } from 'Shared/components/ExportFile/ActionButtons'
import { FloatingSelectBar } from 'Shared/components/FloatingSelectBar/FloatingSelectBar'
import { BatchExpenseUpdateModal } from './BatchExpenseUpdateModal/BatchExpenseUpdateModal'
import { useTranslatedExpensesStatus } from './i18n/hooks/useTranslatedExpensesStatus'
import { ExpenseParsed } from 'Core/Types/Expense'
import { calculateValuesExpenses } from './utils/calculateValuesExpenses'

export const Expenses = (): React.Component => {
  const { push } = useHistory()

  const [_queryParams, _fn, search] = useQuery()

  const {
    handleFiltersChange,
    filters,
    pagination,
    expenses,
    meta,
    loading,
    newPerPageOptions,
    expForgottenData,
    handleOnPageChange,
    handleOnPerPageChange,
    fetchExportExpenses,
    fetchExpenses
  } = useGetExpenses()

  const { expenseRevenuesOptions } = useExpenseRevenuesOptions(true)
  const { suppliersData } = useSuppliersOptions()

  const { total = 0 } = meta ?? {}

  const onReturnToDashboard = () => push('/expense-revenue-dashboard')

  const i18n = useTranslator(ExpensesTranslator)

  const { expensesStatusOptionsTranslated } = useTranslatedExpensesStatus()

  const valuesCardOptions = useMemo(
    () => [{ text: i18n?.total, value: total }]
    , [i18n?.total, total]
  )

  const redirectToCreateExpense = useCallback(() => {
    return push(`/expense-revenue-dashboard/expenses/create${search}`)
  }, [push, search])

  const redirectToCreateExpenseRecurrency = useCallback(() => {
    return push(`/expense-revenue-dashboard/expenses-recurrency/create${search}`)
  }, [push, search])

  const {
    bankAccountsOptions
  } = useBankAccountsOptions()

  const btns = useMemo(() => {
    return [
      exportBtn(fetchExportExpenses, !expenses?.length)
    ]
  }, [
    expenses?.length,
    fetchExportExpenses
  ])

  const [expenseToUpdate, setExpenseToUpdate] = useState<ExpenseParsed[]>([])
  const [showBatchUpdateModal, setShowBatchUpdateModal] = useState(false)

  const toggleBatchUpdateModal = useCallback(
    () => setShowBatchUpdateModal(isVisible => !isVisible)
    , [])

  const floatingSelectBarBtns = useMemo(() => {
    return [
      {
        label: i18n?.editBatch,
        onClick: () => setShowBatchUpdateModal(true),
        disabled: false
      }

    ]
  }, [i18n?.editBatch])

  const { formattedMoney } = calculateValuesExpenses(expenseToUpdate)

  return (
    <>
      <PageTitle
        title={i18n?.accountsPayable}
        onClickReturn={onReturnToDashboard}
      />
      <ExpenseSectionHeaderContainer>
        <section className='wrapper_buttons'>
          <CardButton onClick={redirectToCreateExpense}>
            <PlusIcon css={cardIcon} />
            <Label>{i18n?.newAccount}</Label>
          </CardButton>

          <CardButton onClick={redirectToCreateExpenseRecurrency}>
            <PlusIcon css={cardIcon} />
            <Label>{i18n?.newRecurringAccount}</Label>
          </CardButton>
        </section>
        <ValuesCard
          valuesCardOptions={valuesCardOptions}
          customCss={[customValueCard]}
        />
      </ExpenseSectionHeaderContainer>
      <Card customCss={[cardBox]}>
        <ExpensesRevenuesFilters
          isLoading={loading}
          handleFiltersChange={handleFiltersChange}
          filters={filters}
          expenseRevenuesOptions={expenseRevenuesOptions}
          suppliersOptions={suppliersData}
          statusOptions={expensesStatusOptionsTranslated}
          typeExpenseOrRevenueLabel={i18n?.typeOfExpense}
          buttonLabel={i18n?.searchButton}
          bankAccountsOptions={bankAccountsOptions}
          isExpense
        />

        <section className='wrapper__info_action_btn'>
          {expForgottenData?.forgotten > 0 &&
            <p className='info__forgotten'>
              {i18n?.accountPayableScheduledUnpaid(expForgottenData?.forgotten)}
              {i18n?.onTheLastDay(expForgottenData?.daysAgo)}
            </p>
          }

          <ActionButtons
            btns={btns}
          />
        </section>

        <ExpensesList
          loading={loading}
          expenses={expenses}
          expenseToUpdate={expenseToUpdate}
          setExpenseToUpdate={setExpenseToUpdate}
        />
        <PaginationBar
          currentPage={pagination.page}
          perPage={pagination.perPage}
          totalPages={pagination.totalPages}
          totalElements={pagination.totalItems}
          onPageChange={handleOnPageChange}
          onPerPageChange={handleOnPerPageChange}
          perPageOptions={newPerPageOptions}
        />
      </Card>
      <FloatingSelectBar
        items={expenseToUpdate}
        itemLabel={i18n?.selectedPayableAccount(expenseToUpdate?.length)}
        floatingSelectBarBtns={floatingSelectBarBtns}
        isVisible={false}
        options={
          {
            totalTransactionsAmount: formattedMoney,
            isDynamicTotalizerEnabled: true
          }
        }
      />
      <BatchExpenseUpdateModal
        showBatchUpdateModal={showBatchUpdateModal}
        toggleBatchUpdateModal={toggleBatchUpdateModal}
        selectedExpenses={expenseToUpdate}
        fetchExpenses={fetchExpenses}
        setExpenseToUpdate={setExpenseToUpdate}
      />
    </>
  )
}
