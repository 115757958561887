/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FullFinancialFilterContainer } from './FinancialScheduleFilter.styles'
import {
  Button
} from '@bonitour/components'
import { useFullFinancialFiltersInputs } from '../hooks/inputs/useFullFinancialFiltersInputs'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { ActionButtons, exportBtn } from 'Shared/components/ExportFile/ActionButtons'

/**
 * @typedef FullFinancialFiltersProps
 * @prop { (filters: Record<string, string>) => void } onFilterSubmit
 * @prop { boolean } isLoading
 * @prop { string } btnLabel
 */

/** @type { React.FC<FullFinancialFiltersProps> } */
export const FullFinancialFilters = (
  {
    onFilterSubmit,
    isLoading,
    btnLabel,
    handleExport,
    isLoadingExport
  }
) => {
  const {
    inputsValid,
    form,
    errors,
    onInputChange,
    onInputBlur,
    onSubmit
  } = useFullFinancialFiltersInputs()

  return (
    <>
      <FullFinancialFilterContainer>
        <FormsInputs
          inputs={inputsValid}
          formErrors={errors}
          formValues={form}
          onInputChange={onInputChange}
          onInputBlur={onInputBlur}
        >
          <Button
            className='full_financial_filter__btn'
            type='button'
            disabled={!!isLoading}
            onClick={onSubmit(onFilterSubmit)}
          >
            {btnLabel}
          </Button>
        </FormsInputs>
      </FullFinancialFilterContainer>

      <ActionButtons
        btns={[exportBtn(onSubmit(handleExport), isLoadingExport)]}
      />
    </>

  )
}
