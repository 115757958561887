import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const titleHeader = css`
  font-weight: 500;
`

export const listCard = css`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .wrapper__totalizers {
    display: flex;
    gap: 0.625rem;
  }
`

export const width100 = css`
  width: 100%;
`

export const marginRight = css`
  margin-right: 1em;
`

export const marginBottom = css`
  margin-bottom: 2em;
`

export const valueCardPrice = css`
  font-size: 22px;
  color: ${colors.gray1};
  font-weight: 500;
`

export const textCardPrice = css`
  font-size: 12px;
  color: ${colors.gray4};
`

export const cardPriceStyle = css`
  background-color: ${colors.gray14};
  width: 50%;
  padding: 16px;
`
