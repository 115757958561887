import { useMemo } from 'react'

export const useTranslatedFormulasDescriptions = (
  fields,
  translatedDescriptionsMap
) => {
  const translatedDescriptions = useMemo(() => {
    return fields.map(field => ({
      ...field,
      desc: translatedDescriptionsMap?.[field.method || field?.operation]?.label || field?.desc
    }))
  }, [fields, translatedDescriptionsMap])

  return translatedDescriptions
}
