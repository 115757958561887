
export const ConciliationsFormErrorsTranslator = (
  dictionary
) => {
  const labels = {
    startDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_startDateRequired_formErrorLabel,
      startDateMustBeLessThanOrEqualEndDate: dictionary?.common_startDateMustBeLessThanOrEqualEndDate_label
    },
    endDate: {
      dateFormat: dictionary?.conciliation_dateFormat_formErrorLabel,
      dateRequired: dictionary?.conciliation_endDateRequired_formErrorLabel,
      endDateMustBeAfterOrEqualStartDate: dictionary?.conciliation_endDateMustBeAfterOrEqualStartDate_formErrorLabel
    },
    bankAccountId: {
      bankAccountRequired: dictionary?.conciliation_bankAccountRequired_formErrorLabel
    }
  }

  return {
    errors: labels
  }
}
