
export const EventsProcessorsInputsTranslator = (dictionary, _locale) => {
  const labels = {
    name: {
      label: dictionary?.event_processor_processorName_inputLabel,
      placeholder: dictionary?.event_processor_enterProcessorName_placeholderLabel
    },
    fieldName: {
      label: dictionary?.event_processor_entryConditionField_inputLabel,
      placeholder: dictionary?.event_processor_selectInputConditionField_placeholderLabel
    },
    fieldValue: {
      label: dictionary?.event_processor_entryConditionValue_inputLabel,
      placeholder: dictionary?.event_processor_selectInputConditionValue_placeholderLabel
    },
    status: {
      label: dictionary?.event_processor_status_inputLabel,
      placeholder: dictionary?.event_processor_selectTheStatus_placeholderLabel,
      options: {
        '': dictionary?.common_all_label,
        enabled: dictionary?.common_statusActive_label,
        disabled: dictionary?.common_statusInactive_label
      }
    },
    description: {
      label: dictionary?.event_processor_description_inputLabel,
      placeholder: dictionary?.event_processor_enterDescription_placeholderLabel
    },
    value: {
      label: dictionary?.event_processor_value_inputLabel,
      placeholder: dictionary?.event_processor_enterTheValue_placeholderLabel
    },
    comparisonType: {
      label: dictionary?.event_processor_for_inputLabel,
      placeholder: dictionary?.event_processor_selectTheComparator_placeholderLabel,
      options: {
        equals: dictionary?.event_processor_equal_label,
        less_than: dictionary?.event_processor_lessThan_label,
        greater_than: dictionary?.event_processor_greaterThan_label,
        in: dictionary?.event_processor_in_label,
        not_equal: dictionary?.event_processor_notEqual_label,
        not_in: dictionary?.event_processor_notIn_label
      }
    },
    automationRuleAction: {
      label: dictionary?.event_processor_action_inputLabel,
      placeholder: dictionary?.event_processor_selectTheAction_placeholderLabel,
      options: {
        MoveAccountForecastWithFormula: dictionary?.event_processor_moveAccountForecastWithFormula_label,
        MoveAccountWithFormula: dictionary?.event_processor_moveAccountWithFormula_label,
        MoveAccountForecastWithFormulaForRefund: dictionary?.event_processor_moveAccountForecastWithFormulaForRefund_label,
        UpdateAccountForecastWithFormula: dictionary?.event_processor_updateAccountForecastWithFormula_label,
        UpdateAccountMovement: dictionary?.event_processor_updateAccountMovement_label,
        AccelerateInstallmentsForRefund: dictionary?.event_processor_accelerateInstallmentsForRefund_label,
        UpdateTicketStatus: dictionary?.event_processor_updateTicketStatus_label,
        AnalyzeInvoicePartialPayment: dictionary?.event_processor_analyzeInvoicePartialPayment_label,
        CreateUpdateBankAccount: dictionary?.event_processor_createUpdateBankAccount_label,
        MoveBankAccount: dictionary?.event_processor_moveBankAccount_label,
        InitBilling: dictionary?.event_processor_initBilling_label,
        UpdateBilling: dictionary?.event_processor_updateBilling_label,
        DeleteBilling: dictionary?.event_processor_deleteBilling_label
      }
    },
    operationType: {
      label: dictionary?.event_processor_operationType_inputLabel,
      placeholder: dictionary?.event_processor_selectTheTypeOfOperation_placeholderLabel,
      options: {
        credit: dictionary?.common_credit_label,
        debit: dictionary?.common_debit_label
      }
    },
    dateWithFormula: {
      label: dictionary?.event_processor_date_inputLabel,
      placeholder: dictionary?.event_processsor_enterTheDateFormula_placeholderLabel
    },
    referenceDateWithFormula: {
      label: dictionary?.event_processor_referenceDate_inputLabel,
      placeholder: dictionary?.event_processor_enterTheReferenceDateFormula_placeholderLabel
    },
    automationRuleStatus: {
      label: dictionary?.event_processor_status_inputLabel,
      placeholder: dictionary?.event_processor_selectTheStatus_placeholderLabel,
      options: {
        waiting: dictionary?.event_processor_automationRuleWaitingStatus_label,
        need_confirmation: dictionary?.event_processor_automationRuleNeedConfirmationStatus_label,
        processed: dictionary?.event_processor_automationRuleProcessedStatus_label,
        billed: dictionary?.event_processor_automationRuleBilledStatus_label,
        canceled: dictionary?.event_processor_automationRuleCanceledStatus_label,
        done: dictionary?.event_processor_automationRuleDoneStatus_label
      }
    },
    accountId: {
      label: dictionary?.event_processor_account_inputLabel,
      placeholder: dictionary?.event_processor_selectTheAccount_placeholderLabel,
      options: {
        cash_flow: dictionary?.event_processor_cashFlow_label
      }
    },
    accountFormula: {
      label: dictionary?.event_processor_accountViaFormula_inputLabel,
      placeholder: dictionary?.event_processor_selectTheAccountFormula_placeholderLabel,
      options: {
        'get_bank_account(conta_bancaria_id,company_id)': dictionary?.event_processor_getEventBankAccount_label,
        'get_accountable_plan_from_bank_account(conta_bancaria_id,company_id)': dictionary?.event_processor_getAccountablePlanFromBankAccount_label,
        'get_account_from_expense_revenue(tipo_receita_id,company_id)': dictionary?.event_processor_getAccountblePlanFromEventRevenue_label,
        'get_account_from_expense_revenue(tipo_despesa_id,company_id)': dictionary?.event_processor_getAccountblePlanFromEventExpense_label
      }
    },
    iterationField: {
      label: dictionary?.event_processor_iterationField_inputLabel,
      placeholder: dictionary?.event_processor_enterTheIterationField_placeholderLabel
    }
  }
  return {
    inputs: labels
  }
}
