import { MetaParser } from './Meta'
import { EventParser } from './event'

export const EventsProcessorParser = {
  getEventProcessorSingle: (eventProcessor) => {
    const {
      id,
      name,
      conditions,
      status,
      processor_type,
      description
    } = eventProcessor?.events_processor ?? eventProcessor ?? {}
    return {
      id,
      name,
      conditions: conditions?.map(EventsProcessorParser.conditions),
      status,
      description,
      processorType: processor_type
    }
  },
  getEventsProcessors: ({ events_processors, meta }) => {
    return {
      events_processors: events_processors
        .map(EventsProcessorParser.getEventProcessorSingle)
        .filter(condition => condition?.processorType !== 'internal'),
      meta: MetaParser.meta(meta)
    }
  },
  conditions: (condition) => {
    const {
      field,
      comparison_type,
      value
    } = condition ?? {}

    return {
      field,
      comparisonType: comparison_type,
      value
    }
  },
  matchedEvents: ({ events }) => {
    return events?.map((matchedEvent) => {
      return {
        id: matchedEvent?.id,
        eventRequestDetails: EventParser.getEventRequestDetails(matchedEvent)
      }
    })
  },
  entryCondition: (condition) => {
    const {
      id,
      field,
      value,
      comparison_type: comparisonType
    } = condition

    return {
      id,
      field,
      value,
      comparisonType
    }
  },
  entryConditions: ({ conditions }) => {
    return conditions.map(EventsProcessorParser.entryCondition)
  },
  getUniqueConditions: ({ events_processor }) => {
    const {
      unique_conditions,
      events_processors_similar
    } = events_processor ?? {}

    return {
      uniqueConditions: unique_conditions,
      eventsProcessorsSimilar: events_processors_similar?.map(
        EventsProcessorParser.getEventProcessorSingle
      )
    }
  },
  getAutomationRulesSimulations: ({ table }) => {
    const {
      'valid?': isValid,
      result
    } = table

    const results = result?.map((automationRule) => {
      const safeAccountName = typeof automationRule?.account_name === 'string' ? automationRule?.account_name : automationRule?.account_formula

      const safeOperationTypeName = typeof automationRule?.operation_type_name === 'object' ? '-' : automationRule?.operation_type_name
      const safeStatus = typeof automationRule?.status === 'object' ? '-' : automationRule?.status

      return {
        id: automationRule?.id,
        actionName: automationRule?.action_name,
        date: automationRule?.date,
        value: automationRule?.value || '-',
        operationTypeName: safeOperationTypeName,
        status: safeStatus,
        accountName: safeAccountName || '-'
      }
    })

    return {
      isValid,
      results
    }
  },
  automationRuleSingle: (automationRule) => {
    const {
      id,
      action,
      operation_type,
      value,
      date,
      account_name,
      account_formula
    } = automationRule

    const safeAccountName = typeof account_name === 'string' ? account_name : account_formula

    return {
      id,
      action,
      operationType: operation_type,
      value,
      date,
      accountName: safeAccountName || '-'
    }
  },
  getAutomationRules: ({ automation_rules }) => {
    return automation_rules?.map(EventsProcessorParser.automationRuleSingle)
  },
  getAutomationRuleDataForInputs: ({ automation_rule }) => {
    const {
      action,
      operation_type,
      date,
      reference_date,
      account_id,
      account_formula,
      status,
      value,
      iteration_field
    } = automation_rule ?? {}

    return {
      automationRuleAction: action,
      operationType: operation_type || undefined,
      dateWithFormula: date || undefined,
      referenceDateWithFormula: reference_date || undefined,
      accountId: account_id || undefined,
      accountFormula: account_formula || undefined,
      automationRuleStatus: status || undefined,
      value: value || undefined,
      iterationField: iteration_field || undefined
    }
  },
  getAutomationRuleActions: ({ actions }) => {
    return actions?.map((action) => {
      return {
        value: action, label: action
      }
    })
  },
  getAutomationRuleAccounts: ({ accounts }) => {
    return EventsProcessorParser.addDefaultItemToArray(accounts)
  },
  getAutomationRuleAccountFormulas: ({ account_formulas }) => {
    return EventsProcessorParser.addDefaultItemToArray(account_formulas)
  },
  addDefaultItemToArray: (array) => {
    return [{ value: 'remove', label: 'Selecione' }, ...array]
  }
}
