import { useCallback, useEffect, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { SupplierService } from 'Core/Service/Supplier'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SuppliersHooksTranslator } from '../i18n/SuppliersHooksI18n'

export const useGetSupplier = (supplierId) => {
  const [supplier, setSupplier] = useState()
  const { addToast } = useToast()
  const i18n = useTranslator(SuppliersHooksTranslator)

  const fetchSupplier = useCallback(() => {
    SupplierService.getById(supplierId)
      .then(setSupplier)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorGetByIdSupplier
        })
      }).finally(() => {})
  }, [addToast, i18n, supplierId])

  useEffect(() => {
    fetchSupplier()
  }, [fetchSupplier])

  return {
    supplier
  }
}
