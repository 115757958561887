import { useCallback, useState } from 'react'
import { BankTransfersService } from 'Core/Service/bankTransfers'
import { useToast } from 'Shared/hooks/useToast'
import { PatchBankTransferPayload } from 'Core/Types/BankTransfer'
import { BankTransfersHooksTranslator } from '../i18n/BankTransfersHooksI18n'
import { useTranslator } from 'Shared/language/i18nHandler'

export const useUpdateBankTransfer = () => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(BankTransfersHooksTranslator)

  const updateBankTransfer = useCallback(
    async (updateBankTransferPayload: PatchBankTransferPayload, bankTransferId: string) => {
      setLoading(true)
      return await BankTransfersService.patchBankTransfer(updateBankTransferPayload, bankTransferId).then(
        () => {
          addToast({
            typeToast: 'success',
            defaultMessage: i18n?.transferBetweenAccountUpdatedSuccess
          })
          return true
        }).catch(errors => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorUpdatingAccountTransfer,
          dictTranslation: i18n?.apiCodesMappingForBankTransfers
        })
        return false
      }).finally(() => {
        setLoading(false)
      })
    }, [
      addToast,
      i18n?.apiCodesMappingForBankTransfers,
      i18n?.errorUpdatingAccountTransfer,
      i18n?.transferBetweenAccountUpdatedSuccess
    ])

  return {
    updateBankTransfer,
    loadingUpdateBankTransfer: loading
  }
}
