/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Card, colors } from '@bonitour/components'
import { css, jsx } from '@emotion/core'
import { useHistory, useLocation } from 'react-router-dom'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { ImportFailureList } from './List/ImportFailureList'

export const colorRed = css`
  color: ${colors.red1};
`

export const messageFailure = css`
  padding-bottom: 24px;
`

export const ImportFailure = () => {
  const { goBack } = useHistory()
  const { state: { fails, startDate, endDate } } = useLocation()

  const onClickReturn = () => goBack()

  return (
    <>
      <PageTitle
        title='Falhas na importação'
        onClickReturn={onClickReturn}
      />
      <Card>
        <div css={messageFailure}>
          <small><small css={colorRed}>{fails?.length}</small> Recebiveis apresentam falha na importação</small>
        </div>
        <ImportFailureList
          fails={fails}
          startDate={startDate}
          endDate={endDate}
        />
      </Card>
    </>
  )
}
