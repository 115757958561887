/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Modal, LoadingAnimation } from '@bonitour/components'
import { useCallback, useMemo } from 'react'
import { RevenueForm } from '../Revenues/Form/RevenueForm'
import { loadingAnimation } from 'Shared/styles/global'
import { customModal } from '../Expenses/Expenses.styles'
import { useBankAccountsOptions } from '../../BankAction/BankAccounts/hooks/useBankAccountsOptions'

/**
 * @typedef ExpenseRevenueModalProps
 * @prop { Record<string, string>[] } expenseRevenuesOptions
 * @prop { Record<string, string>[] } initialStatusOptions
 * @prop { Record<string, string>[] } allowedStatusOptions
 * @prop { Record<string, string> } expenseRevenueData
 * @prop { () => void } toggleHidden
 * @prop { (formData: Record<string, string>) => Promise<void> } onCreate
 * @prop { (formData: Record<string, string>) => Promise<void> } onEdit
 * @prop { boolean } isVisible
 * @prop { boolean } loading
 * @prop { () => void } fetchRevenues
 */

/** @type { React.FC<ExpenseRevenueModalProps> } */
export const ExpenseRevenueModal = ({
  isVisible = false,
  toggleHidden,
  onCreate,
  expenseRevenueData,
  loading = false,
  onEdit,
  allowedStatusOptions,
  initialStatusOptions,
  expenseRevenuesOptions,
  fetchRevenues
}) => {
  const isEdit = useMemo(() => Boolean(expenseRevenueData), [expenseRevenueData])

  const { bankAccountsOptions } = useBankAccountsOptions()

  const handleCreateAndEdit = useCallback(
    async (formData) => {
      const isSuccess = isEdit ? await onEdit(formData) : await onCreate(formData)

      if (isSuccess) {
        toggleHidden()
        fetchRevenues()
      }
    },
    [fetchRevenues, isEdit, onCreate, onEdit, toggleHidden]
  )

  const title = useMemo(() => {
    return `${isEdit ? 'Editar' : 'Cadastrar'} contas a receber`
  }, [isEdit])

  return (
    <Modal
      title={loading ? '' : title}
      isVisible={isVisible}
      onCloseClick={toggleHidden}
      customCss={[customModal]}
    >

      {loading
        ? (
          <LoadingAnimation css={loadingAnimation} />
        )
        : (
          <RevenueForm
            onCancel={toggleHidden}
            handleCreateAndEdit={handleCreateAndEdit}
            currentFormData={expenseRevenueData}
            bankAccountOptions={bankAccountsOptions}
            statusOptions={isEdit ? allowedStatusOptions : initialStatusOptions}
            expenseRevenuesOptions={expenseRevenuesOptions}
          />
        )}

    </Modal>
  )
}
