import { colors } from '@bonitour/components'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const ModalContent = styled.div`
    * {
        box-sizing: border-box;
    }

    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;

    .modal__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute;

        width: 100%;
        max-width: 27.9375rem;
        max-height: calc(100% - 2rem);
        box-sizing: border-box;
        border-radius: 0.625rem;
        padding: 1.875rem;
        background: ${colors.white};

        z-index: 4;
        opacity: 1;
        transform: translateY(0);
        overflow-y: auto;

        .close-modal-button {
            --close-modal-button-size: 1.875rem;
            height: var(--close-modal-button-size);
            width: var(--close-modal-button-size);
            min-width: var(--close-modal-button-size);
            min-height: var(--close-modal-button-size);
            border-radius: 100vw;
            top: calc(var(--close-modal-button-size) / 2);
            right: calc(var(--close-modal-button-size) / 2);

            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;

            background: ${colors.indigo2};;
            color: ${colors.white};
            margin-left: 0.625rem;
        }

        .modal__info {
            .modal__info_title {
                font-size: 1.5rem;
                font-weight: 700;
                color: ${colors.gray1};
                margin-bottom: 1.25rem;
                margin-right: 0.625rem;
            }
        }

    }

`

export const ButtonUpload = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18.75rem;
    border-radius: var(--round-element-radius, 1.25rem);
    border: 1px solid ${colors.indigo1};
    background-color: white;
    align-self: flex-end;
    height: 2.563rem;
    cursor: pointer;

    span {
        color: ${colors.indigo1};
        font-size: 0.875rem;
        font-weight: 600;
    }

    i {
        margin-right: 0.625rem;
        color: ${colors.indigo1};
        font-size: 1.5rem;
        font-weight: 600;
    }
`

export const ModalUploadBg = styled.button`
  background-color: #000;
  opacity: 0.3;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  outline: none;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 3;
`
export const customUploadInline = css`
  min-height: 8.625rem;
  border: 2px dashed ${colors.gray8};

  span {
    border-radius: 0.625rem;
    padding: 0.625rem;
    color: white;
    background-color: ${colors.indigo2};
    font-weight: 700;
    width: 9.375rem;

    i {
        margin-right: 0.625rem;
        font-size: 1.5rem;
        font-weight: 700;
        color: white;
    }
  }
`
