import { useMemo } from 'react'

export const useSortedInputOptions = (inputs) => {
  const sortedInputsOptions = useMemo(() => inputs?.map((input) => {
    const options = input?.orderBy?.forEach((order) => {
      let optionsOdered = input?.options

      if (order === 'alphabeticalASC') {
        optionsOdered = input?.options.sort(
          (a, b) => {
            const labelA = a.label.toUpperCase()
            const labelB = b.label.toUpperCase()

            if (labelA < labelB) {
              return -1
            }
            if (labelA > labelB) {
              return 1
            }
            return 0
          }
        )
      }

      return optionsOdered
    }) || input?.options

    return {
      ...input,
      options
    }
  }), [inputs])

  return sortedInputsOptions
}
