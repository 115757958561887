import React, { useCallback } from 'react'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { SupplierBankAccountFormButtons, SupplierBankAccountFormContainer } from './SupplierBankAccountModal.style'
import { useSupplierBankAccountInputs } from '../hooks/inputs/useSupplierBankAccountInputs'
import { LoadingAnimation, Modal } from '@bonitour/components'
import { loadingAnimation } from 'Shared/styles/global'
import { FormActions } from 'Shared/components/Form/__FormActions'
import { useTranslatedFormErrors } from 'Shared/language/hooks/inputs/useTranslatedFormErrors'
import { SupplierBankAccountsFormErrorsTranslator } from '../i18n/SupplierBankAccountsFormErrorsI18n'

/**
 * @typedef SupplierBankAccountModalProps
 * @prop { () => void} toggleHidden
 * @prop { () => void} onCreateSupplierBankAccount
 * @prop { boolean } loading
 * @prop { boolean } isVisible
 * @prop { Record<string, string> } i18n
 * @prop { () => void} onEditSupplierBankAccount
 * @prop { Record<string, string> } supplierBankAccountData
 * @prop { string } supplierId
 */
/** @type { React.FC<SupplierBankAccountModalProps> } */
export const SupplierBankAccountModal = ({
  toggleHidden,
  onCreateSupplierBankAccount,
  loading,
  isVisible,
  i18n,
  onEditSupplierBankAccount,
  supplierBankAccountData,
  supplierId
}) => {
  const {
    inputsValid,
    form,
    errors,
    onSubmit,
    onInputChange,
    onInputBlur
  } = useSupplierBankAccountInputs(
    i18n.supplierBankAccountTypeOptions,
    i18n.supplierBankAccountEnabledOptions,
    supplierBankAccountData
  )
  const isEditing = Boolean(supplierBankAccountData)

  const handleSubmit = useCallback(() => {
    if (supplierBankAccountData) {
      onEditSupplierBankAccount(form, supplierId)
    } else {
      onCreateSupplierBankAccount(form)
    }
    toggleHidden()
  }, [
    onEditSupplierBankAccount,
    supplierBankAccountData,
    onCreateSupplierBankAccount,
    toggleHidden,
    form,
    supplierId
  ])

  const formErrorsTranslated = useTranslatedFormErrors(errors, SupplierBankAccountsFormErrorsTranslator)

  return (
    <Modal
      title={loading ? '' : !isEditing ? i18n.modalCreateSupplierBankAccountTitle : i18n.modalEditSupplierBankAccountTitle}
      isVisible={isVisible}
      onCloseClick={toggleHidden}
    >

      {loading
        ? (
          <LoadingAnimation css={loadingAnimation} />
        )
        : (
          <>
            <SupplierBankAccountFormContainer>
              <FormsInputs
                inputs={inputsValid}
                formValues={form}
                formErrors={formErrorsTranslated}
                onInputChange={onInputChange}
                onInputBlur={onInputBlur}
              />
            </SupplierBankAccountFormContainer>
            <SupplierBankAccountFormButtons>
              <FormActions
                onSubmit={onSubmit(handleSubmit)}
                onClose={toggleHidden}
              />
            </SupplierBankAccountFormButtons>
          </>
        )}

    </Modal>
  )
}
