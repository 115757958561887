export const SupplierBankAccountsInputsTranslator = (
  dictionary
) => {
  const labels = {
    bankName: {
      label: dictionary?.supplier_bank_account_bankName_inputLabel,
      placeholder: dictionary?.supplier_bank_account_bankName_placeholderLabel
    },
    branch: {
      label: dictionary?.supplier_bank_account_branch_inputLabel,
      placeholder: dictionary?.supplier_bank_account_branch_placeholderLabel
    },
    enabled: {
      label: dictionary?.supplier_bank_account_enabled_inputLabel,
      placeholder: dictionary?.supplier_bank_account_enabled_placeholderLabel
    },
    accountNumber: {
      label: dictionary?.supplier_bank_account_accountNumber_inputLabel,
      placeholder: dictionary?.supplier_bank_account_accountNumber_placeholderLabel
    },
    accountType: {
      label: dictionary?.supplier_bank_account_accountType_inputLabel,
      placeholder: dictionary?.supplier_bank_account_accountType_placeholderLabel
    }
  }

  return {
    inputs: labels
  }
}
