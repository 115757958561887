/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableContainer,
  EditInfoIcon,
  TrashIcon
} from '@bonitour/components'
import { CircleStatus } from 'Shared/styles/global'
import { Audit } from 'Shared/components/Audit/Audit'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { statusLabelColor } from '../../constants/supplier'
import { wrapperButtons } from '../../Supplier.styles'
import { useTranslator } from 'Shared/language/i18nHandler'
import { SupplierBankAccountsTranslator } from '../i18n/SupplierBankAccountsI18n'
import { useListSupplierBankAccounts } from '../hooks/useListSupplierBankAccounts'
import { useSupplierBankAccountAudit } from '../hooks/useSupplierBankAccountAudit'

/**
 * @typedef SupplierBankAccountsTableHeaderProps
 * @prop { string[] } columns
 */
/** @type { React.FC<SupplierBankAccountsTableHeaderProps> } */
const SupplierBankAccountsTableHeader = ({ columns }) => {
  return (
    <TableHeader>
      <TableHeaderRow>
        {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
      </TableHeaderRow>
    </TableHeader>
  )
}

/**
 * @typedef SupplierBankAccountsTableBodyProps
 * @prop { boolean } loading
 * @prop { Record<string, string>[] } supplierBankAccounts
 * @prop { Record<string, string> } i18n
 * @prop { string } supplierId
 * @prop { boolean } isModalView
 * @prop { (id: string) => void } onEdit
 * @prop { (id: string) => void } onDialogRemoveSupplierBankAccount
 */
/** @type { React.FC<SupplierBankAccountsTableBodyProps> } */
const SupplierBankAccountsTableBody = ({ loading, supplierBankAccounts, i18n, supplierId, isModalView, onEdit, onDialogRemoveSupplierBankAccount }) => {
  const {
    fetchSupplierAudit,
    data,
    loading: auditLoading
  } = useSupplierBankAccountAudit()

  return (
    <TableBody loading={loading}>
      {supplierBankAccounts.map(
        ({
          id,
          bankName,
          branch,
          accountNumber,
          enabled
        }) => (
          <TableBodyRow key={id}>
            <TableBodyCell>
              <SafeOverflow text={bankName} />
            </TableBodyCell>
            <TableBodyCell>
              {branch}
            </TableBodyCell>
            <TableBodyCell>
              <SafeOverflow text={accountNumber} />
            </TableBodyCell>
            <TableBodyCell>
              <CircleStatus statusColor={statusLabelColor[enabled].color}>
                {i18n.supplierBankAccountStatusCodesMapped[enabled].label}
              </CircleStatus>
            </TableBodyCell>
            { !isModalView &&
              <TableBodyCell css={wrapperButtons}>
                <EditInfoIcon onClick={() => onEdit(supplierId, id)}/>
                <TrashIcon onClick={() => onDialogRemoveSupplierBankAccount(id)}/>
                <Audit
                  documentId={id}
                  params={ { supplierId } }
                  auditDatas={data}
                  auditLoading={auditLoading}
                  fetchAuditDatas={fetchSupplierAudit}
                  modalTitle={i18n.modalAuditSupplierBankAccountTitle}
                  i18n={i18n.auditLabels}
                />
              </TableBodyCell>
            }
          </TableBodyRow>
        ))}
    </TableBody>
  )
}

/**
 * @typedef SupplierBankAccountsListProps
 * @prop { boolean } isModalView
 * @prop { string } supplierId
 * @prop { (id: string) => void } onEdit
 * @prop { (id: string) => void } onDialogRemoveSupplierBankAccount
 */
/** @type { React.FC<SupplierBankAccountsListProps> } */
export const SupplierBankAccountsList = ({ supplierId, isModalView, onEdit, onDialogRemoveSupplierBankAccount }) => {
  const {
    supplierBankAccounts,
    listLoading
  } = useListSupplierBankAccounts(supplierId)
  const i18n = useTranslator(SupplierBankAccountsTranslator)
  const columns = isModalView ? i18n.supplierBankAccountTableColumnsTitlesModalView : i18n.supplierBankAccountTableColumnsTitles

  return (
    <>
      <TableContainer>
        <SupplierBankAccountsTableHeader columns={columns} />
        <SupplierBankAccountsTableBody
          loading={listLoading}
          supplierBankAccounts={supplierBankAccounts}
          i18n={i18n}
          supplierId={supplierId}
          isModalView={isModalView}
          onEdit={onEdit}
          onDialogRemoveSupplierBankAccount={onDialogRemoveSupplierBankAccount}
        />
      </TableContainer>
    </>

  )
}
