/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useRef } from 'react'
import { useClickOutside } from '@bonitour/app-functions'
import { Manager, Reference, Popover, UserThumb } from '@bonitour/components'
import { useUser } from 'Shared/contexts/User'
import { useCompany } from 'Shared/contexts/Company'
import { UserOptions } from './UserOptions'

const clickable = css`
  cursor: pointer;
`

const popoverStyle = css`
  .component-popover-arrow {
    right: 1.25rem;
  }
`

export const User = () => {
  const reference = useRef()
  const { user } = useUser()
  const { company } = useCompany()
  const [isVisible, setVisibility] = useClickOutside(reference)
  const { name, avatar } = user
  const { image } = company

  const toggleVisibility = () => setVisibility(visibility => !visibility)
  return (
    <div ref={reference}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div css={clickable} ref={ref} onClick={toggleVisibility}>
              <UserThumb name={name} avatar={avatar} companyImage={image} />
            </div>
          )}
        </Reference>
        {isVisible && (
          <Popover position='bottom-end' customCss={[popoverStyle]}>
            <UserOptions />
          </Popover>
        )}
      </Manager>
    </div>
  )
}
