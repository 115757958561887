import { useTranslatedOriginTypes } from 'Shared/language/hooks/useTranslatedOriginTypes'

export const FullFinancialFiltersInputsTranslator = (dictionary) => {
  const { originTypeOptionsTranslated } = useTranslatedOriginTypes({ dictionary })

  const labels = {
    description: {
      label: dictionary?.common_description_label,
      placeholder: dictionary?.flow_movement_searchByKeywordOrDescription_label
    },
    originType: {
      label: dictionary?.flow_movement_releaseType_label,
      options: originTypeOptionsTranslated
    }
  }

  const translatedOptions = {
    originType: originTypeOptionsTranslated
  }

  return {
    inputs: labels,
    translatedOptions
  }
}
