/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Modal, LoadingAnimation } from '@bonitour/components'
import { useCallback } from 'react'
import { BankAccountForm } from '../Form/BankAccountForm'
import { loadingAnimation } from 'Shared/styles/global'
import { useAccountsPlanOptions } from 'Domains/AccountingFunction/CountPlan/hooks/useAccountsPlanOptions'

/**
 * @typedef BankAccountModalProps
 * @prop { () => void } toggleHidden
 * @prop { boolean } isVisible
 * @prop { boolean } loading
 * @prop { Record<string, string>[] } bankAccountData
 * @prop { string } editModalTitleBankAccounts
 * @prop { () => void } onEdit
 */

/** @type { React.FC<BankAccountModalProps> } */
export const BankAccountModal = ({
  isVisible = false,
  toggleHidden,
  bankAccountData,
  loading = false,
  editModalTitleBankAccounts,
  onEdit
}) => {
  const { accountsPlanOptions } = useAccountsPlanOptions()

  const handleSubmit = useCallback((formData) => {
    onEdit(formData)
    toggleHidden()
  }, [onEdit, toggleHidden])

  return (
    <Modal
      title={editModalTitleBankAccounts}
      isVisible={isVisible}
      onCloseClick={toggleHidden}
    >

      {loading
        ? (
          <LoadingAnimation css={loadingAnimation} />
        )
        : (
          <BankAccountForm
            onCancel={toggleHidden}
            onSubmit={handleSubmit}
            currentFormData={bankAccountData}
            accountablePlanOptions={accountsPlanOptions}
          />
        )}

    </Modal>
  )
}
