import { useState, useCallback, useEffect } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { CreditCardConciliationService } from 'Core/Service/creditCardConciliation'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConciliationsTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsI18n'
import { ConciliationsHooksTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsHooksI18n'

export const useListCreditCardConciliations = () => {
  const { addToast } = useToast()
  const [isLoadingCreditCardConciliations, setIsLoadingCreditCardConciliations] = useState(false)
  const [filters, setFilters] = useState(
    { acquirer: 'braspag' }
  )
  const [creditCardConciliationsData, setCreditCardConciliationsData] = useState({
    conciliations: [],
    partialConciliations: [],
    notConciliations: {},
    conciliationsItems: 0,
    partialConciliationsItems: 0,
    notConciliationsItems: 0
  })

  const i18n = useTranslator(ConciliationsTranslator)

  const i18nHooks = useTranslator(ConciliationsHooksTranslator)

  const fetchCreditCardConciliations = useCallback(() => {
    setIsLoadingCreditCardConciliations(true)
    return CreditCardConciliationService.getCreditCardConciliations(filters, i18n).then(
      (data) => {
        setCreditCardConciliationsData(data)
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18nHooks?.errorLoadingConciliations
      })
    }).finally(() => {
      setIsLoadingCreditCardConciliations(false)
    })
  }, [addToast, filters, i18n, i18nHooks?.errorLoadingConciliations])

  const handleFilters = useCallback((filters) => {
    setFilters(filters)
  }, [])

  const handleOnCreditCardConciliation = useCallback(({ options }) => {
    const partialConciliation = Object.keys(options?.partialConciliation).length ? [options?.partialConciliation] : []
    const conciliation = Object.keys(options?.conciliation).length ? [options?.conciliation] : []

    setCreditCardConciliationsData((oldStates) => {
      return {
        partialConciliationsItems: oldStates?.partialConciliationsItems,
        conciliationsItems: oldStates?.conciliationsItems,
        notConciliationsItems: oldStates?.notConciliationsItems,
        conciliations: [...conciliation, ...oldStates?.conciliations].sort(
          (a, b) => a?.date?.localeCompare(b?.date) || a?.id.localeCompare(b?.id)
        ),
        partialConciliations: [...partialConciliation, ...oldStates?.partialConciliations].sort(
          (a, b) => a?.date?.localeCompare(b?.date) || a?.id.localeCompare(b?.id)
        ),
        notConciliations: {
          gatewayPayments: oldStates?.notConciliations?.gatewayPayments.filter(({ id }) => !options?.externalMovementsIds?.includes(id)),
          accountForecasts: oldStates?.notConciliations?.accountForecasts.filter(({ id }) => !options?.internalMovementsIds?.includes(id))
        },
        totalConciliatedGatewayPayments: oldStates?.totalConciliatedGatewayPayments,
        totalNotConciliatedGatewayPayments: oldStates?.totalNotConciliatedGatewayPayments,
        totalNotConciliatedAccountForecasts: oldStates?.totalNotConciliatedAccountForecasts
      }
    })
  }, [])

  useEffect(() => {
    if (
      !filters?.startDate ||
      !filters?.endDate
    ) return

    fetchCreditCardConciliations()
  }, [
    fetchCreditCardConciliations,
    filters?.endDate,
    filters?.startDate
  ])

  return {
    isLoadingCreditCardConciliations,
    handleFilters,
    conciliationsData: creditCardConciliationsData?.conciliations,
    partialConciliations: creditCardConciliationsData?.partialConciliations,
    notConciliationsData: creditCardConciliationsData?.notConciliations,
    conciliationsItems: creditCardConciliationsData?.conciliationsItems,
    partialConciliationsItems: creditCardConciliationsData?.partialConciliationsItems,
    notConciliationsItems: creditCardConciliationsData?.notConciliationsItems,
    filters,
    fetchCreditCardConciliations,
    handleOnCreditCardConciliation
  }
}

// Event.create(
//   event_type: 'cartao_credito_split',
//   company_id: '0d374a74-ddbd-4253-abc5-fe4f8f739a34',
//   status: 'pending',
//   event_request:
//     {
//       "aut": "B86421",
//       "doc": "B75310",
//       "info": {
//         "card_number": "**** **** **** 2345",
//         "client_name": "Teste Staging",
//         "partner_name": ["Atrativo 1", "Atrativo 3"],
//         "reservation_code": "2FTTB3W0SQL1"
//       },
//       "tipo": "cartao_credito_split",
//       "user_id": "4f5f7b6c-7873-4335-9819-dd90659949ab",
//       "bandeira": "visa",
//       "parcelas": 10,
//       "taxa_pay": 1000,
//       "descricao": "Igor teste",
//       "operadora": "braspag",
//       "company_id": "0d374a74-ddbd-4253-abc5-fe4f8f739a34",
//       "multiponto": false,
//       "valor_guia": 500,
//       "origin_type": "Financial::ReservationPayment",
//       "taxa_cartao": 1000,
//       "taxa_agencia": 1000,
//       "valor_agencia": 1000,
//       "data_pagamento": "2024-08-05",
//       "pay_payment_id": "b7c025bb-4ee4-4425-9b27-d811df80f964",
//       "taxa_operadora": 1000,
//       "taxa_antifraude": 1000,
//       "valor_pagamento": 100000,
//       "origin_id": "fa612537-0942-41e3-8550-3593f388igor"
//     }
// )

// Event.create(
//   event_type: 'cancelamento_cartao_credito_split',
//   company_id: '0d374a74-ddbd-4253-abc5-fe4f8f739a34',
//   status: 'pending',
//   event_request:
//     {
//       "aut": "B86421",
//       "doc": "B75310",
//       "info": {
//         "card_number": "**** **** **** 2345",
//         "partner_name": ["Atrativo 1", "Atrativo 3"],
//         "reservation_code": "2FTTB3W0SQL1"
//       },
//       "tipo": "cancelamento_cartao_credito_split",
//       "bandeira": "visa",
//       "taxa_pay": 1000,
//       "descricao": "FGD44GF Cliente de Teste",
//       "operadora": "braspag",
//       "company_id": "0d374a74-ddbd-4253-abc5-fe4f8f739a34",
//       "origin_type": "Financial::ReservationRefund",
//       "valor_agencia": 1000,
//       "parcelas_venda": 10,
//       "taxa_operadora": 1000,
//       "taxa_antifraude": 1000,
//       "valor_cancelado": 1000,
//       "data_cancelamento": "2024-08-05",
//       "taxa_cartao_cancelada": 1000,
//       "reservation_payment_id": "ga612537-0942-41e3-8550-3593f388igor",
//       "taxa_cancelamento_agencia": 1000,
//       "origin_id": "7da13923-0936-490e-bce0-994d5ce5igor"
//     }
// )
