const appendData = (key, data, formData) => {
  if (data === undefined || data === null) {
    return
  }
  if (data instanceof Array) {
    data.forEach(item => appendData(`${key}[]`, item, formData))
    return
  } else if (data instanceof File) {
    formData.append(key, data)
    return
  } else if (data instanceof Object) {
    Object.entries(data).forEach(([extraKey, item]) => appendData(`${key}[${extraKey}]`, item, formData))
    return
  }
  formData.append(key, data)
}

export const convertToFormData = data => {
  const formData = new FormData()
  Object.entries(data).forEach(([key, data]) => appendData(key, data, formData))
  return formData
}
