import styled from '@emotion/styled'

export const MatchedEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`

export const MatchedEventsBody = styled.div`
  .matched_events__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    max-height: 1000px;
    overflow-y: auto;
    padding: 1.25rem 0.625rem 1.25rem 0;
    min-height: 3.75rem;

    > div {
      margin: 0;
      margin-right: 0;
    }

    label {
      margin: 0 0 0 0.625rem;
      width: 100%;
    }
  }

  .different_max_height {
    max-height: 15.625rem;
  }
  
  .matched_events__empty_message {
    text-align: center;
    color: var(--clr-gray4);
    font-weight: 600;
  }
`
