import { billingCore } from './Billing'
import { FullFinancialSchedule } from '../Models/fullFinancialSchedule'
import { FullFinancialScheduleParser } from '../Parser/FullFinancialSchedule'
const { fullFinancialScheduleDomain } = billingCore

export const FullFinancialScheduleService = {
  getListFlowMovements (filters) {
    return fullFinancialScheduleDomain.getListFlowMovements(
      FullFinancialSchedule.getListFlowMovements(filters)
    ).then(
      FullFinancialScheduleParser.getListFlowMovements
    )
  },
  getListFlowMovementsExport (filters, i18nForCsv) {
    return fullFinancialScheduleDomain.getListFlowMovements(
      FullFinancialSchedule.getListFlowMovementsExport(filters)
    ).then((data) => FullFinancialScheduleParser.exportCsvMovements(data, i18nForCsv))
  }
}
