import { useState, useEffect, useCallback, useMemo } from 'react'
import { BankAccountService } from 'Core/Service/BankAccount'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankAccountsHooksTranslator } from '../i18n/BankAccountsHooksI18n'

export const useBankAccountsOptions = () => {
  const { addToast } = useToast()
  const [bankAccountsOptions, setBankAccountsOptions] = useState([])
  const [selectedBankAccountId, setSelectedBankAccountId] = useState('')

  const i18n = useTranslator(BankAccountsHooksTranslator)

  const fetchBankAccounts = useCallback(() => {
    return BankAccountService.options()
      .then(setBankAccountsOptions)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorLoadBankAccountOptions
        })
      })
  }, [addToast, i18n?.errorLoadBankAccountOptions])

  const selectedBankAccount = useMemo(() => {
    if (!selectedBankAccountId) return
    const filteredBankAccount = bankAccountsOptions.find(({ value }) => value === selectedBankAccountId)

    if (!filteredBankAccount) {
      return ''
    }

    const safeFilteredBankAccount = (
      filteredBankAccount?.suportedFileFormats?.length > 1
        ? filteredBankAccount?.suportedFileFormats.join(', ')
        : filteredBankAccount?.suportedFileFormats
    )
    return safeFilteredBankAccount
  }, [bankAccountsOptions, selectedBankAccountId])

  useEffect(() => {
    fetchBankAccounts()
  }, [fetchBankAccounts])

  const bankAccountsOptionsWithAll = useMemo(() => {
    const options = bankAccountsOptions?.map(({ value, label }) => {
      return {
        value,
        label
      }
    })

    options.unshift({ value: '', label: i18n?.all })
    return options
  }, [bankAccountsOptions, i18n?.all])

  const paymentMethodsOptions = useCallback((bankAccountId) => {
    const buildPaymentMethods = (bankAccountsOptions.find(
      ({ value }) => value === bankAccountId) || {}
    ).paymentMethods || {}

    return Object.keys(buildPaymentMethods).map((paymentMethod) => ({
      value: paymentMethod,
      label: i18n?.paymentMethodMapped(paymentMethod)
    }))
  }, [bankAccountsOptions, i18n])

  return {
    bankAccountsOptions,
    setSelectedBankAccountId,
    selectedBankAccount,
    paymentMethodsOptions,
    bankAccountsOptionsWithAll
  }
}
