import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { PageCreateEditExpenseRecurrencyContainer } from './PageCreateEditExpenseRecurrency.styles'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useParams } from 'react-router-dom'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { FormActions } from 'Shared/components/Form/__FormActions'
import { useCreateExpense } from '../hooks/useCreateExpense'
import { useUpdateExpense } from '../hooks/useUpdateExpense'
import { PayloadCreateExpense, PayloadUpdateExpense } from 'Core/Types/Expense'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesTranslator } from '../i18n/ExpensesI18n'
import { useExpenseRecurrencyInputs } from '../hooks/inputs/useExpenseRecurrencyInputs'
import { SimulatorRecurrency } from 'Domains/ExpenseRevenue/SimulatorRecurrency/SimulatorRecurrency'
import { useQuery } from 'Shared/hooks/useQuery'
import { AnimatePresence, motion } from 'framer-motion'
import { expensesStatus } from 'Domains/ExpenseRevenue/constants/expensesStatus'

const animationProps = {
  layout: true,
  initial: {
    opacity: 0,
    scaleY: 0
  },
  animate: { opacity: 1, y: 0, scaleY: 1 },
  transition: { duration: 0.2 },
  exit: {
    opacity: 0,
    scaleY: 0
  }
}

export const PageCreateEditExpenseRecurrency = () => {
  const { push } = useHistory()
  const routerParams = useParams()
  const [_queryParams, _fn, search] = useQuery()

  const isCreate = useMemo(
    () => routerParams?.[0] === 'create',
    [routerParams]
  )

  const expenseId = useMemo(
    () => routerParams?.expenseId, [routerParams?.expenseId]
  )

  const recurrenceId = useMemo(
    () => routerParams?.recurrenceId, [routerParams?.recurrenceId]
  )

  const returnToListExpenses = useCallback(
    () => push(`/expense-revenue-dashboard/expenses${search}`), [push, search]
  )

  const {
    onCreateExpense,
    loading: loadingCreateExpense
  } = useCreateExpense(
    {
      recurrence: true
    }
  )

  const {
    fetchExpense,
    expense,
    loading: loadingUpdateExpense,
    onUpdateExpense
  } = useUpdateExpense(
    {
      recurrenceId
    }
  )

  const {
    inputsValid,
    form,
    errors,
    onSubmit,
    onInputChange,
    onInputBlur
  } = useExpenseRecurrencyInputs(
    {
      currentFormData: expense,
      expenseId
    }
  )

  useEffect(() => {
    if (!expenseId || expense) return

    fetchExpense(expenseId)
  }, [expenseId, expense, fetchExpense])

  const expenseOperationStrategy = useMemo(() => ({
    create: onCreateExpense,
    update: onUpdateExpense
  }), [onCreateExpense, onUpdateExpense])

  const handleCreateEditExpenseRecurrency = useCallback(
    async (currentForm: PayloadUpdateExpense & PayloadCreateExpense
    ) => {
      const operationType = isCreate ? 'create' : 'update'

      const isSuccess = await expenseOperationStrategy[operationType](currentForm)

      if (isSuccess) {
        returnToListExpenses()
      }
    },
    [expenseOperationStrategy, isCreate, returnToListExpenses]
  )

  const i18n = useTranslator(ExpensesTranslator)

  const isPayedOrRefunded = useMemo(
    () => [expensesStatus.payed, expensesStatus.refunded].includes(form?.status),
    [form?.status]
  )

  return (
    <>
      <PageTitle
        title={
          isCreate
            ? i18n?.recurringPayableAccountRegistration
            : i18n?.editRecurringPayableAccountRegistration
        }
        onClickReturn={returnToListExpenses}
      />

      <PageCreateEditExpenseRecurrencyContainer
        onReset={returnToListExpenses}
        onSubmit={e => e.preventDefault()}
      >
        <motion.div className='wrapper__inputs_simulator'>
          <AnimatePresence initial={false}>
            <>
              <FormsInputs
                inputs={inputsValid}
                formValues={form}
                formErrors={errors}
                onInputChange={onInputChange}
                onInputBlur={onInputBlur}
                animationProps={animationProps}
              />

              {
                !isPayedOrRefunded && (
                  <SimulatorRecurrency
                    form={form}
                    {...animationProps}
                  />
                )
              }
            </>
          </AnimatePresence>
        </motion.div>

        <section className='form__actions'>
          <FormActions
            onSubmit={onSubmit(handleCreateEditExpenseRecurrency)}
            onClose={returnToListExpenses}
            successBtnLabel={isCreate ? i18n?.register : i18n?.save}
            disabled={loadingUpdateExpense || loadingCreateExpense}
          />
        </section>
      </PageCreateEditExpenseRecurrencyContainer>

    </>
  )
}
