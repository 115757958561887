import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const ConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid var(--clr-gray8);

  .form__inputs {
    display: flex;
    gap: 0.625rem;
    padding: 0.625rem;
    background-color: var(--clr-gray14);
    border-radius: 0.625rem;

    .input_container__field,
    .input_container__value,
    .input__comparison_type {
      display: flex;
      gap: 0.625rem;
      flex-direction: row;
      align-items: center;
    }

    .input__field,
    .input__value {
      width: 18.75rem;
    }

    .input__field {
      &:read-only {
        background-color: var(--clr-white);
        color: var(--clr-gray3);
        -webkit-text-fill-color: var(--clr-gray3);
      }
    }

    label {
      white-space: nowrap;
      margin: 0;
    }

    .condition__btn {
      background-color: var(--clr-white);
      color: var(--clr-primary);
      border: 1px solid var(--clr-primary);
    }

    .b-font-trash {
      cursor: pointer;
      font-size: 1.375rem;
      -webkit-text-stroke-width: 0.5px;
      -webkit-text-stroke-color: var(--clr-gray3);
    }
  }

  .unique_conditions__wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    border: 1px solid var(--clr-yellow1);
    background-color: var(--clr-orange5);
    width: 32.5rem;
    padding: 0.625rem;
    border-radius: 0.625rem;
    
    .unique_conditions__warning_circle {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--clr-yellow1);
      height: 2.1875rem;
      width: 2.1875rem;
      border-radius: 100vw;

      i {
        color: var(--clr-white);
      }
    }
    
    .unique_conditions__message {
      color: var(--clr-yellow1);
      font-weight: 700;
      width: 28.3125rem;
      font-size: 0.875rem;
      
      span {
        position: relative;
      }

      button {
        color: var(--clr-indigo2);
        font-weight: 700;
        text-decoration: underline;
        margin-left: 0.3125rem;
      }

      .unique_conditions__tooltip {
        min-width: 25rem;
        pointer-events: initial;
        cursor: initial;
        text-align: left;

        h3 {
          color: var(--clr-gray3);
          font-weight: 700;
          margin-bottom: 0.625rem;
        }

        .unique_conditions__wrapper_names {
          display: flex;
          flex-direction: column;
          gap: 0.625rem;
          max-height: 9.375rem;
          overflow-y: auto;
          padding-bottom: 0.625rem;

          p {
            color: var(--clr-gray4);
            font-weight: 600;
          }
        } 

        
      }
    }
  }

  @media(max-width: ${BREAK_POINTS.smallTablet}) {
    .form__inputs {
      flex-direction: column;
    }
  }
`
