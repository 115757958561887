const cleanString = (str, options = {}) => {
  const safeStr = options.trimString ? str.trim() : str

  return options.removeEmpty && !safeStr ? undefined : safeStr
}

export function cleanPayload (
  payload,
  options = {}
) {
  const payloadType = typeof payload

  const {
    removeNull = true,
    cleanArray = false,
    removeEmptyObjects = true,
    removeEmptyArrays = true
  } = options

  switch (payloadType) {
  case 'string':
    return cleanString(payload, options)

  case 'number':
    return payload

  case 'bigint':
    return payload

  case 'boolean':
    return payload

  case 'undefined':
  case 'function':
  case 'symbol':
    return undefined

  default:
    break
  }

  if (payload === null) return removeNull ? undefined : payload

  if (payload instanceof Date) return payload

  if (Array.isArray(payload)) {
    const cleanedArray = cleanArray
      ? (payload
        .map(el => cleanPayload(el, options))
        .filter(el => el !== undefined))
      : payload

    return removeEmptyArrays && (cleanedArray).length === 0
      ? undefined
      : cleanedArray
  }

  if (payloadType === 'object' && payload) {
    const payloadKeys = Object.keys(payload)

    if (removeEmptyObjects && payloadKeys.length === 0) {
      return undefined
    }

    const cleanedPayload = Object.keys(payload).reduce((acc, key) => {
      const el = payload[key]
      const cleanEl = cleanPayload(el, options)

      if (cleanEl !== undefined) {
        acc[key] = cleanEl
      }

      return acc
    }, {})

    return cleanedPayload
  }

  return undefined
}
