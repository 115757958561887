/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { NotConciliatedListContainer } from './List.styles'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConciliationsTranslator } from '../i18n/ConciliationsI18n'
import { BaseMessageInfo } from './BaseMessageInfo'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BaseTable } from './BaseTable'
import { FloatingSelectBar } from 'Shared/components/FloatingSelectBar/FloatingSelectBar'
import { calculateFinancialMovements, calculateTotalMovements } from '../utils/calculateTotalMovements'

/**
 * @typedef ColGroupComponentProps
 * @prop { string[] } arrayWidths
 */

/** @type { React.FC<ColGroupComponentProps> } */
const ColGroupComponent = ({ arrayWidths }) => {
  return (
    <colgroup>
      <col span={1} style={{ width: '3rem' }}/>
      {arrayWidths?.map((value, idx) => (
        <col key={idx} span={1} style={{ width: value }}/>
      ))}
    </colgroup>
  )
}

/**
 * @typedef NotConciliationListProps
 * @prop { { bankStatements: Record<string, string>[], accountMovements:Record<string, string>[] } } notConciliationsData
 * @prop { Record<string, string> } filters
 * @prop {() => void }  fetchConciliations
 * @prop {(externalMovementsIds: string[], internalMovementsIds: string[], conciliated: Record<string, string>) => void }  handleOnConciliation
 * @prop {{
 *  tableTitleExternalMovements: string
 *  isBankConciliationsTabNot: boolean
 *  internalMovementsColumnsTitles: string[]
 *  externalMovementsColumnsTitles: string[]
 *  externalMovementsColumnsWidths: string[]
 *  internalMovementsColumnsWidths: string[]
 *  isLoading: boolean
 *  isLoadingAutoConciliation: boolean
 *  isLoadingManualConciliation: boolean
 * }} options
 * @prop { (params: Record<string, string>) => void } createAutoConciliation
 * @prop { (params: Record<string, string>) => void } createManualConciliation
 */

/** @type { React.FC<NotConciliationListProps> } */
export const NotConciliationList = (
  {
    internalMovements,
    externalMovements,
    options,
    filters,
    handleOnConciliation,
    createAutoConciliation,
    createManualConciliation
  }
) => {
  const [externalMovementsIds, setExternalMovementsIds] = useState([])
  const [internalMovementsIds, setInternalMovementsIds] = useState([])

  const {
    tableTitleExternalMovements,
    isBankConciliationsTabNot,
    internalMovementsColumnsTitles,
    internalMovementsColumnsWidths,
    externalMovementsColumnsTitles,
    externalMovementsColumnsWidths,
    isLoading,
    isLoadingAutoConciliation,
    isLoadingManualConciliation
  } = options

  useEffect(() => {
    setExternalMovementsIds(
      (oldState) => oldState.filter(
        selectedId => externalMovements?.some(({ id }) => id === selectedId)
      )
    )
    setInternalMovementsIds((oldState) => oldState.filter(
      selectedId => internalMovements?.some(({ id }) => id === selectedId)
    ))
  }, [externalMovements, internalMovements])

  const i18n = useTranslator(ConciliationsTranslator)

  const handleCreateManualConciliation = useCallback(() => {
    createManualConciliation({ ...filters, externalMovementsIds, internalMovementsIds }).then(
      (
        { partialConciliation, conciliation }
      ) => {
        if (partialConciliation || conciliation) {
          handleOnConciliation(
            {
              options: {
                externalMovementsIds,
                internalMovementsIds,
                partialConciliation,
                conciliation
              }
            }
          )
        }
      })
  }, [
    internalMovementsIds,
    externalMovementsIds,
    createManualConciliation,
    filters,
    handleOnConciliation
  ])

  const conciliationBtnsLabels = useMemo(() => {
    return [
      {
        label: i18n?.makeManualConciliation,
        onClick: handleCreateManualConciliation,
        isLoading: isLoadingManualConciliation,
        disabled: (!internalMovementsIds?.length || !externalMovementsIds?.length)
      },
      {
        label: i18n?.makeAutomaticConciliation,
        onClick: () => createAutoConciliation(filters),
        isLoading: isLoadingAutoConciliation
      }
    ]
  }, [
    internalMovementsIds?.length,
    externalMovementsIds?.length,
    createAutoConciliation,
    filters,
    handleCreateManualConciliation,
    i18n?.makeAutomaticConciliation,
    i18n?.makeManualConciliation,
    isLoadingAutoConciliation,
    isLoadingManualConciliation
  ])

  const items = useMemo(
    () => externalMovementsIds.concat(internalMovementsIds),
    [externalMovementsIds, internalMovementsIds]
  )

  const totalMoneyExternalMovements = useMemo(
    () => calculateTotalMovements(externalMovements, externalMovementsIds)
    , [externalMovements, externalMovementsIds]
  )

  const totalMoneyInternalMovements = useMemo(
    () => calculateTotalMovements(internalMovements, internalMovementsIds)
    , [internalMovements, internalMovementsIds]
  )

  const totalMoney = useMemo(
    () => calculateFinancialMovements(totalMoneyExternalMovements, totalMoneyInternalMovements)
    , [totalMoneyExternalMovements, totalMoneyInternalMovements]
  )

  return (
    <>
      <NotConciliatedListContainer
        isBankConciliationsTabNot={isBankConciliationsTabNot}
      >
        {!externalMovements?.length &&
      !internalMovements?.length ? (
            <BaseMessageInfo
              isLoading={isLoading}
              title={i18n?.noItemsToConciliation}
              subTitle={i18n?.fillInFieldsFromTheStartBar}
            />
          )
          : (
            <>
              <div className='content_table__titles'>
                <p>{tableTitleExternalMovements}</p>
                <p>{i18n?.tableTitleSystemReleases}</p>
              </div>
              <div className='wrapper_tables'>
                <div className='content_table'>
                  <BaseTable
                    datas={externalMovements}
                    isLoading={isLoading}
                    columnsTitles={externalMovementsColumnsTitles}
                    colGroupComponent={<ColGroupComponent arrayWidths={externalMovementsColumnsWidths}/>}
                    i18n={i18n}
                    selectedIds={externalMovementsIds}
                    setSelectedIds={setExternalMovementsIds}
                  />
                </div>

                <div className='content_table'>
                  <BaseTable
                    datas={internalMovements}
                    isLoading={isLoading}
                    columnsTitles={internalMovementsColumnsTitles}
                    colGroupComponent={<ColGroupComponent arrayWidths={internalMovementsColumnsWidths}/>}
                    i18n={i18n}
                    selectedIds={internalMovementsIds}
                    setSelectedIds={setInternalMovementsIds}
                  />
                </div>
              </div>
            </>
          )
        }
      </NotConciliatedListContainer>
      { !externalMovements?.length &&
        !internalMovements?.length ? (
          null
        ) : (
          <FloatingSelectBar
            items={items}
            itemLabel={i18n?.itemSelected(items?.length)}
            floatingSelectBarBtns={conciliationBtnsLabels}
            options={
              {
                totalOutgoingTransactionsAmount: totalMoneyExternalMovements,
                totalIncomingTransactionsAmount: totalMoneyInternalMovements,
                totalTransactionsAmount: totalMoney,
                outgoingTransactionsLabel: tableTitleExternalMovements,
                incomingTransactionsLabel: i18n?.tableTitleSystemReleases,
                isDynamicTotalizerEnabled: true
              }
            }
          />
        )
      }
    </>
  )
}
