import styled from '@emotion/styled'

import {
  TooltipContainer,
  TooltipContent
} from './styles'

export const ModalTooltipContainer = styled(TooltipContainer)`
  z-index: 11;
  pointer-events: inherit;
  transition: none;
  ${props => props.verticalPosition !== 'center' && '--translateYVar: 0px;'}

  .modal__close_btn {
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;

    --modal-close-btn-size: 1.125rem;

    display: none;
    align-items: center;
    justify-content: center;
    height: 1.5em;
    width: 1.5em;
    min-width: 1.5em;
    background-color: var(--clr-indigo2);
    border-radius: 9in;

    color: var(--clr-white);
    font-size: var(--modal-close-btn-size);

    z-index: 10;

    &:hover {
      background-color: var(--clr-indigo1);
    }
  }

  .modal__inner_content {
    margin-top: 0rem;
  }

  @media (max-width: ${props => props.modalBreakpoint}) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    ${props => props.verticalPosition !== 'center' && '--translateYVar: 0px;'}

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .modal__tooltip_box::before {
      display: none;
    }

    .modal__close_btn {
      display: flex;
    }

    .modal__inner_content {
      margin-top: 0.5rem;
      max-height: calc(100vh - 8rem);
    }
  }

  .modal__close_bg {
    position: absolute;
    top: -2.75rem;
    border-radius: 0.625rem;
    opacity: 0;

    @media (max-width: ${props => props.modalBreakpoint}) {
      opacity: 0.75;
      border-radius: 0;
    }
  }

  &.center .modal__close_bg {
    left: -2.75rem;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: ${props => props.modalBreakpoint}) {
      left: 0;
      height: 100vh;
      transform: none;
    }
  }
`

export const ModalTooltipContent = styled(TooltipContent)``

export const CloseBg = styled.button`
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.75;
  border: 0;
  cursor: pointer;
`
