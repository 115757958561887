import { useState, useCallback } from 'react'
import { BankStatementsService } from 'Core/Service/BankStatement'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankStatementsHooksTranslator } from '../i18n/BankStatementsHooksI18n'

export const useGetImportedBankStatements = () => {
  const { addToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [imported, setImported] = useState([])

  const i18n = useTranslator(BankStatementsHooksTranslator)

  const onGetImportedBankStatements = useCallback((bankAccountId) => {
    setIsLoading(true)
    return BankStatementsService.getImported({ bankAccountId }).then(
      setImported
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorFetchingImportedBankStatements
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }, [addToast, i18n])

  return {
    imported,
    isLoadingImported: isLoading,
    onGetImportedBankStatements
  }
}
