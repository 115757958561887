import { useState, useCallback, useRef, useEffect } from 'react'
import { BankAccountService } from 'Core/Service/BankAccount'
import { useToast } from 'Shared/hooks/useToast'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { BankAccountsHooksTranslator } from '../i18n/BankAccountsHooksI18n'
import { useTranslator } from 'Shared/language/i18nHandler'

export const useListBankAccounts = () => {
  const [bankAccountsList, setBankAccountsList] = useState({ bankAccounts: [], meta: {} })
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()
  const { initStatePagination, perPageOptions } = usePaginationOptions()
  const initPagination = useRef(false)
  const [pagination, setPagination] = useState(initStatePagination)

  const i18n = useTranslator(BankAccountsHooksTranslator)

  const handlePagination = useCallback((currentPage, perPage) => {
    if (!initPagination.current) {
      initPagination.current = true
      return
    }

    setPagination({
      currentPage,
      perPage
    })
  }, [])

  const fetchListBankAccount = useCallback(() => {
    setLoading(true)
    return BankAccountService.list(
      pagination
    ).then((data) => {
      setBankAccountsList(data)
    }).catch((errors) => {
      console.error(errors?.data)
      addToast(
        {
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorLoadingBankAccountList
        }
      )
    }).finally(() => {
      setLoading(false)
    })
  }, [pagination, addToast, i18n?.errorLoadingBankAccountList])

  useEffect(() => {
    if (
      !pagination.currentPage ||
      !pagination.perPage
    ) return

    fetchListBankAccount()
  }, [fetchListBankAccount, pagination])

  return {
    bankAccounts: bankAccountsList?.bankAccounts,
    meta: bankAccountsList?.meta,
    loading,
    perPageOptions,
    handlePagination,
    pagination,
    fetchListBankAccount
  }
}
