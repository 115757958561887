/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Card
} from '@bonitour/components'
import {
  sectionListTitle
} from './BankStatements.style'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { BankStatementsList } from './List/BankStatementsList'
import { useCallback } from 'react'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'
import { useHistory } from 'react-router-dom'
import { useBankAccountsOptions } from '../BankAccounts/hooks/useBankAccountsOptions'
import { BankStatementImport } from './BankStatementImport/BankStatementImport'

export const BankStatements = () => {
  const {
    bankAccountsOptions,
    setSelectedBankAccountId,
    selectedBankAccount
  } = useBankAccountsOptions()

  useFlaggedRoute('billing-bank-statements')

  const { push } = useHistory()
  const onReturnToDashboard = useCallback(() => push('/bank-action'), [push])

  return (
    <>
      <PageTitle
        title='Extratos Bancários'
        onClickReturn={onReturnToDashboard}
      />
      <BankStatementImport
        bankAccountsOptions={bankAccountsOptions}
        setSelectedBankAccountId={setSelectedBankAccountId}
        selectedBankAccount={selectedBankAccount}
      />
      <Card>
        <h2 css={[sectionListTitle]}>Buscar Extratos bancários</h2>
        <BankStatementsList
          bankAccountsOptions={bankAccountsOptions}
        />
      </Card>
    </>
  )
}
