import styled from '@emotion/styled'

export const BankStatementImportContainer = styled.div`
  * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  padding: 1.25rem 1.875rem;
  margin-bottom: 1.875rem;
  background-color: var( --clr-white);
  padding: 1.875rem;
  border-radius: 0.625rem;

  .form__inputs  {
    display: flex;
    gap: 0.625rem;
    align-items: end;
    width: 100%;
    margin-bottom: 0.625rem;

    .upload_formats {
      height: 2.5rem;
      display: flex;
      align-items: center;
    }

    .is__file {
      background-color: var(--clr-gray9);
    }

    .input_container__start_date,
    .input_container__end_date,
    .input__bank_account,
    .input_container__file {
      flex: 1;
    }

    .section__upload_child {
      flex: 1;
    }

    .input__start_date,
    .input__end_date,
    .input__file {
      width: 100%;
    }
  }

  .btn__upload {
    width: 16.25rem;
  }

  .wrapper_infos {
    display: flex;
    gap: 1.25rem;
  }

  .load {
    width: 100%;
  }
`

export const BtnDrawer = styled.button`
  * {
    box-sizing: border-box;
  }

  display: flex;
  align-items: center;
  gap: 0.3125rem;
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;

  .btn_drawer_title {
    font-size: 1.125rem;
    font-weight: 700;
    color: var(--clr-gray3);
  }

  .b-font-angle-up {
    transition: transform 200ms ease;
    transform: rotate(${({ isOpen }) => isOpen ? '179' : '0'}deg);
    color: var(--clr-indigo1);
    font-size: 1.5rem;
  }
`

export const ImportReviewContainer = styled.section`
  * {
    box-sizing: border-box;
  }

  button {
    background: none;
    border: none;
    padding: none;
  }

  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: ${({ isLoadUpload }) => isLoadUpload ? 'center' : 'initial'};
  gap: 0.625rem;
  background-color: var(${({ hasBeenUploaded }) => hasBeenUploaded ? '--clr-green5' : '--clr-orange5'});
  border-radius: 0.625rem;
  padding: 1.25rem;
  justify-content: center;

  .import_review_infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;

    .highlight {
      color: var(--clr-gray3);
      font-weight: 600;
      margin-right: 0.3125rem;
    }

    .import_review_transaction_ignoreds {
      color: var(--clr-red2);
      
      .highlight {
        color: var(--clr-red2);
      }
    }

    .import_review_details {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;

      .import_review_details_wrapper {
        display: flex;
        flex-direction: column;
        max-height: 300px;
        padding: 0.625rem 1.25rem;
        overflow-y: auto;
        gap: 1.25rem;
      }

      .import_review_details_content {
        padding: 0.625rem;
        box-shadow: 0px 0px 0.375rem #0000001a;
        border-radius: 0.625rem;
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
      }

      .import_review_details_title,
      .import_review_details_subtitle {
        color: var(--clr-gray3);
        font-weight: 700;
        font-size: 0.875rem;
      }

      .b-font-angle-up {
        font-size: 1rem;
        color: var(--clr-gray3);
      }
    }
  }
  
`

export const LatestImportedBankStatementsContainer = styled.section`
  * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  border-radius: 0.625rem;
  box-shadow: 0px 0px 0.375rem #0000001a;
  justify-content: ${({ isLoadingImported }) => isLoadingImported ? 'center' : 'initial'};

  .latest_imported_bank_statements__wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(5, 1fr);
    gap: 1.25rem;
  }

  span {
    font-weight: 700;
    color: var(--clr-gray3);
  }
`

export const BankStatmentInfoHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;

  .bank_statement_info_header_circle {
    display: flex;
    justify-content: center;
    border-radius: 100vw;
    height: 1.875rem;
    width: 1.875rem;
    color: white;
    background-color: var(${({ newBackgroundColor }) => newBackgroundColor || '--clr-gray3'});
  }

  .bank_statement_info_header_title {
    color: var(${({ newColor }) => newColor || '--clr-gray3'});
    font-weight: 700;
  }
`
