import { useMemo } from 'react'

import {
  date,
  status,
  bankAccounts,
  paymentMethods,
  getSchema,
  expenseItems,
  receiptsBatchExpense
} from '../../../Form/__BaseInputs'
import { useForm } from '@bonitour/app-functions'
import { BatchExpenseInputsTranslator } from '../../i18n/ExpensesInputsI18n'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { useBankAccountsOptions } from 'Domains/BankAction/BankAccounts/hooks/useBankAccountsOptions'
import { ExpenseParsed } from 'Core/Types/Expense'
import { expensesStatus } from 'Domains/ExpenseRevenue/constants/expensesStatus'
import { useExpenseStatusOptions } from '../expenseStatusOptions/useExpenseStatusOptions'

const BASE_FORM_VAL = {}

interface useBatchExpenseUpdateInputsProps {
  selectedExpenses: ExpenseParsed[]
}

export const useBatchExpenseUpdateInputs = ({
  selectedExpenses
}: useBatchExpenseUpdateInputsProps) => {
  const {
    bankAccountsOptions,
    paymentMethodsOptions
  } = useBankAccountsOptions()

  const {
    expensesStatusOptions
  } = useExpenseStatusOptions()

  const inputs = useTranslatedInputs(
    [
      date,
      status(
        expensesStatusOptions(
          [
            expensesStatus.payed
          ]
        )),
      bankAccounts(bankAccountsOptions),
      paymentMethods,
      receiptsBatchExpense,
      expenseItems
    ], BatchExpenseInputsTranslator
  )

  const schema = useMemo(
    () => getSchema(inputs),
    [inputs]
  )

  const defaultData = useMemo(
    () => {
      return {
        status: expensesStatus?.payed,
        expenseItems: selectedExpenses.map(
          (expense) => {
            return {
              value: expense?.value,
              id: expense?.id
            }
          }
        )
      }
    }
    , [selectedExpenses]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(defaultData || BASE_FORM_VAL, schema)

  const newInputsValid = useMemo(() => inputs?.filter(({ value }) => {
    return value !== 'expenseItems'
  }).map(input => {
    if (input?.value === 'paymentMethod') {
      input.options = paymentMethodsOptions(form?.bankAccountId)
    }

    if (input?.value === 'status') {
      input.readOnly = true
    }

    return input
  }), [form?.bankAccountId, inputs, paymentMethodsOptions])

  return {
    validationSchema: schema,
    inputsValid: newInputsValid,
    form,
    errors,
    onSubmit,
    onInputChange,
    onInputBlur
  }
}
