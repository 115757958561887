
export const CREDIT = 'credit'
export const DEBIT = 'debit'
export const CASH = 'cash'
export const PIX = 'pix'
export const BANKTRANSFER = 'bank_transfer'
export const BANKBILLET = 'bank_billet'
export const PARTNERSHIPACCOUNTCREDIT = 'partnership_account_credit'
export const PAY_PIX = 'pay_pix'
export const PAYMENT = 'payment'
