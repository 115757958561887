/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useCallback, useMemo } from 'react'
import { MenuTabsPanel } from 'Shared/components/MenuTabs/MenuTabsPanel'
import { useTranslator } from 'Shared/language/i18nHandler'
import { useListCreditCardConciliations } from './hooks/useListCreditCardConciliations'
import {
  conciliationsAccountForecastsColumnsWidths,
  conciliationsGatewayPaymentsColumnsWidths,
  notConciliationsAccountForecastsColumnsWidths,
  notConciliationsGatewayPaymentsColumnsWidths,
  partialConciliationsAccountForecastsColumnsWidths,
  partialConciliationsGatewayPaymentsColumnsWidths
} from './constants/creditCardConciliations'
import { useHistory } from 'react-router-dom'
import { ConciliationsTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsI18n'
import { NotConciliationList } from 'Shared/components/Conciliations/List/NotConciliationList'
import { ConciliationList } from 'Shared/components/Conciliations/List/ConciliationList'
import { ConciliationsFilters } from 'Shared/components/Conciliations/Form/ConciliationsFilters'
import { useCreateAutoCreditCardConciliation } from './hooks/useCreateAutoCreditCardConciliation'
import { useCreateManualCreditCardConciliation } from './hooks/useCreateManualCreditCardConciliation'
import { useDeleteCreditCardConciliation } from './hooks/useDeleteCreditCardConciliations'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'

export const CreditCardConciliations = () => {
  useFlaggedRoute('billing-creditcard-conciliations')

  const {
    isLoadingCreditCardConciliations,
    handleFilters,
    conciliationsData,
    partialConciliations,
    notConciliationsData,
    filters,
    handleOnCreditCardConciliation,
    fetchCreditCardConciliations,
    conciliationsItems,
    partialConciliationsItems,
    notConciliationsItems
  } = useListCreditCardConciliations()

  const i18n = useTranslator(ConciliationsTranslator)

  const {
    createAutoCreditCardConciliation,
    isLoadingAutoCreditCardConciliation
  } = useCreateAutoCreditCardConciliation(fetchCreditCardConciliations)

  const {
    createManualCreditCardConciliation,
    isLoadingManualCreditCardConciliation
  } = useCreateManualCreditCardConciliation()

  const {
    deleteCreditCardConciliations,
    isLoadingDeleteCreditCardConciliations
  } = useDeleteCreditCardConciliation(fetchCreditCardConciliations)

  const tabs = useMemo(() => {
    return [
      {
        id: 'not-conciliations',
        component: (
          <NotConciliationList
            internalMovements={notConciliationsData?.accountForecasts}
            externalMovements={notConciliationsData?.gatewayPayments}
            options={
              {
                isLoading: isLoadingCreditCardConciliations,
                isLoadingAutoConciliation: isLoadingAutoCreditCardConciliation,
                isLoadingManualConciliation: isLoadingManualCreditCardConciliation,
                tableTitleExternalMovements: i18n?.tableTitleReceivablesReleases,
                internalMovementsColumnsTitles: i18n?.tableAccountForecastsColumnsTitles,
                internalMovementsColumnsWidths: notConciliationsAccountForecastsColumnsWidths,
                externalMovementsColumnsTitles: i18n?.tableGatewayPaymentsColumnsTitles,
                externalMovementsColumnsWidths: notConciliationsGatewayPaymentsColumnsWidths
              }
            }
            filters={filters}
            handleOnConciliation={handleOnCreditCardConciliation}
            createAutoConciliation={createAutoCreditCardConciliation}
            createManualConciliation={createManualCreditCardConciliation}
          />
        ),
        label: i18n?.tabsTitleNotConciliations,
        items: notConciliationsItems
      },
      {
        id: 'partial-conciliations',
        component: (
          <ConciliationList
            conciliationsData={partialConciliations}
            filters={filters}
            handleOnConciliation={handleOnCreditCardConciliation}
            options={
              {
                isLoading: isLoadingCreditCardConciliations,
                isLoadingDeleteConciliations: isLoadingDeleteCreditCardConciliations,
                isCreditCardConciliationsTabPartial: true,
                tableTitleExternalMovements: i18n?.tableTitleReceivablesReleases,
                internalMovementsColumnsTitles: i18n?.tableAccountForecastsColumnsTitles,
                internalMovementsColumnsWidths: partialConciliationsAccountForecastsColumnsWidths,
                externalMovementsColumnsTitles: i18n?.tableGatewayPaymentsColumnsTitles,
                externalMovementsColumnsWidths: partialConciliationsGatewayPaymentsColumnsWidths
              }
            }
            deleteConciliations={deleteCreditCardConciliations}
          />
        ),
        label: i18n?.tabsTitlePartiallyConciliations,
        items: partialConciliationsItems
      },
      {
        id: 'conciliations',
        component: (
          <ConciliationList
            conciliationsData={conciliationsData}
            filters={filters}
            handleOnConciliation={handleOnCreditCardConciliation}
            options={
              {
                isLoading: isLoadingCreditCardConciliations,
                isLoadingDeleteConciliations: isLoadingDeleteCreditCardConciliations,
                isCreditCardConciliationsTabConciliation: true,
                tableTitleExternalMovements: i18n?.tableTitleReceivablesReleases,
                internalMovementsColumnsTitles: i18n?.tableAccountForecastsColumnsTitles,
                internalMovementsColumnsWidths: conciliationsAccountForecastsColumnsWidths,
                externalMovementsColumnsTitles: i18n?.tableGatewayPaymentsColumnsTitles,
                externalMovementsColumnsWidths: conciliationsGatewayPaymentsColumnsWidths
              }
            }
            deleteConciliations={deleteCreditCardConciliations}
          />
        ),
        label: i18n?.tabsTitleConciliations,
        items: conciliationsItems
      }
    ]
  }, [
    notConciliationsData?.accountForecasts,
    notConciliationsData?.gatewayPayments,
    isLoadingCreditCardConciliations,
    i18n?.tableTitleReceivablesReleases,
    i18n?.tableAccountForecastsColumnsTitles,
    i18n?.tableGatewayPaymentsColumnsTitles,
    i18n?.tabsTitleNotConciliations,
    i18n?.tabsTitlePartiallyConciliations,
    i18n?.tabsTitleConciliations,
    filters,
    handleOnCreditCardConciliation,
    partialConciliations,
    conciliationsData,
    createAutoCreditCardConciliation,
    isLoadingAutoCreditCardConciliation,
    createManualCreditCardConciliation,
    isLoadingManualCreditCardConciliation,
    isLoadingDeleteCreditCardConciliations,
    deleteCreditCardConciliations,
    conciliationsItems,
    partialConciliationsItems,
    notConciliationsItems
  ])

  const { push } = useHistory()

  const onReturnCreditCardDashboard = useCallback(() => {
    push('/credit-card')
  }, [push])

  return (
    <>
      <PageTitle
        title={i18n?.pageTitleCreditCardConciliations}
        onClickReturn={onReturnCreditCardDashboard}
      />
      <ConciliationsFilters
        onFilterSubmit={handleFilters}
        filters={filters}
        isLoading={isLoadingCreditCardConciliations}
        buttonLabel={i18n?.listButton}
      />

      <MenuTabsPanel
        tabs={tabs}
        initTab='not-conciliations'
      />
    </>
  )
}
