import { useCallback, useState } from 'react'
import { EventsProcessorsService } from 'Core/Service/eventsProcessors'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'

export const useCreateEventsProcessor = () => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const onCreateEventProcessor = useCallback((data) => {
    setLoading(true)
    return EventsProcessorsService.createEventProcessor(data)
      .then((data) => {
        addToast({
          defaultMessage: i18n?.sucessRegisteredEventProcessor,
          typeToast: 'success'
        })
        return data
      }).catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorRegisteringdEventProcessor
        })
      })
      .finally(() => setLoading(false))
  }, [
    addToast,
    i18n?.errorRegisteringdEventProcessor,
    i18n?.sucessRegisteredEventProcessor
  ])

  return {
    onCreateEventProcessor,
    loading
  }
}
