import styled from '@emotion/styled'

export const PageCreateEditAutomationRuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  gap: 1.25rem;

  * {
    box-sizing: border-box;
  }

  .wrapper__inputs_infos {
    display: flex;
    gap: 1.25rem;
  }


  .section__inputs {
    border-radius: 0.625rem;
    width: 100%;
    background-color: var(--clr-white);
    padding: 1.25rem;
    margin-bottom: 0.625rem;
  }

  .form__inputs {
    display: flex;
    flex-direction: column;
    gap: 0.625rem
  }

  .section__infos {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;

    > div {
      border-radius: 0.625rem;
      background-color: var(--clr-white);
      padding: 1.25rem;
    }
  }

  .wrapper__btns {
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
  }
`
