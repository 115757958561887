import { useCallback, useEffect, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'
import { EventsProcessorsService } from 'Core/Service/eventsProcessors'

export const useGetAutomationRuleAccounts = () => {
  const [loading, setLoading] = useState(false)
  const [automationRuleAccountsData, setAutomationRuleAccountsData] = useState()
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const getAutomationRuleAccounts = useCallback(() => {
    setLoading(true)
    return EventsProcessorsService.getAutomationRuleAccounts()
      .then(setAutomationRuleAccountsData)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorFetchingAccountsForAutomationRule
        })
      })
      .finally(() => setLoading(false))
  }, [addToast, i18n?.errorFetchingAccountsForAutomationRule])

  useEffect(() => {
    if (automationRuleAccountsData) return
    getAutomationRuleAccounts()
  }, [automationRuleAccountsData, getAutomationRuleAccounts])

  return {
    loading,
    automationRuleAccountsData
  }
}
