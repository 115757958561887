/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useCallback } from 'react'
import { ReactComponent as CircleInfoIcon } from 'Shared/svgs/circleInfoIcon.svg'
import { Portal } from 'Shared/components/Portal/Portal'
import { InfoTooltipContainer, ModalBg, ButtonWrapper, ModalContent } from './InfoTooltip.styles'
import { CloseIcon } from '@bonitour/components'

export const InfoTooltip = ({ title = '', info = [] }) => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleIsVisible = useCallback(() => setIsVisible(v => !v), [])

  return (
    <>
      <ButtonWrapper type='button' onClick={toggleIsVisible}>
        <CircleInfoIcon />
      </ButtonWrapper>
      {isVisible
        ? (
          <Portal selector='__MODALS_PORTAL__'>
            <InfoTooltipContainer>
              <ModalContent>
                <section className='modal__header'>
                  <h2 className='modal__title'>{title}</h2>
                  <button
                    type='button'
                    onClick={toggleIsVisible}
                    className='close-modal-button'
                  >
                    <CloseIcon />
                  </button>
                </section>

                <section className='modal__info'>
                  {info?.map(({ optionTitle, optionInfo }) => (
                    <div key={optionTitle}>
                      <h3 className='modal__option_title'>{optionTitle}:</h3>
                      <p>{optionInfo}</p>
                    </div>
                  ))}
                </section>
              </ModalContent>
              <ModalBg
                type='button' onClick={toggleIsVisible}
              />
            </InfoTooltipContainer>
          </Portal>
        ) : null}
    </>
  )
}
