import styled from '@emotion/styled'
import { colors } from '@bonitour/components'

export const LabelWithInfoContainer = styled.div`
    margin-left: 0.3125rem;

    * {
        box-sizing: border-box;
    }

    display: flex;
    align-items: center;
    justify-items: end;

    .label__info {
        font-weight: 700;
        color: ${colors.gray2};
        font-size: 0.875rem;
        margin-right: 0.3125rem;
    }

    
`
