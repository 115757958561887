import { useState, useCallback, useEffect, useRef } from 'react'
import { BankStatementsService } from 'Core/Service/BankStatement'
import { usePaginationOptions } from 'Shared/hooks/usePaginationOptions'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankStatementsHooksTranslator } from '../i18n/BankStatementsHooksI18n'

export const useListBankStatements = () => {
  const { addToast } = useToast()
  const [isloading, setIsLoading] = useState(false)

  const { initStatePagination, perPageOptions } = usePaginationOptions()

  const [filters, setFilters] = useState(initStatePagination)
  const [bankStatementsData, setBankStatementsData] = useState({
    bankStatements: [],
    meta: {}
  })

  const initPagination = useRef(false)

  const i18n = useTranslator(BankStatementsHooksTranslator)

  const fetchBankStatements = useCallback(() => {
    setIsLoading(true)
    const searchParams = { ...filters }
    const pagination = { currentPage: filters?.currentPage, perPage: filters?.perPage }
    return BankStatementsService.fetchBankStatements(searchParams, pagination).then(
      (data) => {
        setBankStatementsData(data)
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorFetchingBankStatements
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }, [addToast, filters, i18n])

  const onSearch = useCallback((filters) => {
    setFilters(
      (oldFilters) => ({ ...oldFilters, ...filters })
    )
  }, [])

  const handleFilters = useCallback((filters) => {
    onSearch({ ...filters, currentPage: 1 })
  }, [onSearch])

  const handlePagination = useCallback((currentPage, perPage) => {
    if (!initPagination.current) {
      initPagination.current = true
      return
    }

    onSearch({ currentPage, perPage })
  }, [onSearch])

  useEffect(() => {
    if (
      !filters.startDate ||
      !filters.endDate ||
      !filters.bankAccountId ||
      !filters.currentPage ||
      !filters.perPage
    ) return

    fetchBankStatements()
  }, [fetchBankStatements, filters])

  return {
    isloading,
    handleFilters,
    handlePagination,
    meta: bankStatementsData?.meta,
    bankStatementsData: bankStatementsData?.bankStatements,
    filters,
    perPageOptions,
    fetchBankStatements
  }
}
