import { billingCore } from './Billing'
import { BankStatementModel } from 'Core/Models/bankStatement'
import { convertToFormData } from '@bonitour/common-functions'
import { BankStatementsParser } from 'Core/Parser/bankStatements'

export const BankStatementsService = {
  uploadBankStatementsFile ({ uploadParams }) {
    const payload = convertToFormData(BankStatementModel.bankStatementsFileImport(uploadParams))
    return billingCore.bankStatementsDomain.bankStatementsFileImport(
      payload
    ).then(BankStatementsParser.bankStatementsImport)
  },
  fetchBankStatements (searchParams, pagination) {
    const payload = BankStatementModel.fetchBankStatements(searchParams, pagination)
    return billingCore.bankStatementsDomain.list(
      payload
    ).then(BankStatementsParser.fetchBankStatements)
  },
  removeBankStatements (params) {
    const payload = BankStatementModel.removeBankStatements(params)
    return billingCore.bankStatementsDomain.remove(
      payload
    ).then(BankStatementsParser.removeBankStatements)
  },
  getImported (params) {
    const payload = BankStatementModel.importedBankStatements(params)
    return billingCore.bankStatementsDomain.getImported(payload).then(BankStatementsParser.importedBankStatements)
  },
  uploadBankStatements: (params) => {
    return billingCore.bankStatementsDomain.bankStatementsImport(
      BankStatementModel.bankStatementsImport(params)
    ).then(BankStatementsParser.bankStatementsImport)
  }
}
