import { useTranslatedFlags } from 'Shared/language/hooks/useTranslatedFlags'

interface FinancialScheduleTranslatorRes {
  flagTranslator: (flag: string) => string
}

export const FinancialScheduleTranslator = (
  dictionary
): FinancialScheduleTranslatorRes => {
  const { flagTranslator } = useTranslatedFlags({ dictionary })

  return {
    flagTranslator
  }
}
