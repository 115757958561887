/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import circleInfoIcon from 'Shared/svgs/circleInfoIcon.svg'
import { formatMoney, formatDate } from '@bonitour/common-functions'
import {
  containerContent,
  labelContent,
  titleContent,
  uppercase,
  IconStatus,
  columnStyle,
  cardStyle,
  iconInfoStyle,
  tooltipStyle,
  upperRowStyle,
  flex
} from './ExpanderDetails.styles'
import {
  Card,
  flexRowCentered,
  Row,
  Logo,
  Manager,
  Reference,
  LightTooltip
} from '@bonitour/components'
import { FinancialScheduleParser } from 'Core/Parser/FinancialSchedule'
import { useTranslator } from 'Shared/language/i18nHandler'
import { FinancialScheduleTranslator } from 'Domains/FinancialSchedule/i18n/FinancialSchedulei18n'

export const ExpanderDetails = ({
  installment,
  installments,
  brand,
  status,
  creditcardOperationType,
  transactionDate,
  transactionFee,
  antifraudFee,
  mdrFee,
  payFee,
  netAmount,
  grossAmount,
  label
}) => {
  const [isVisible, setVisibility] = useState(false)

  const i18n = useTranslator(FinancialScheduleTranslator)

  const toggleVisibility = () => setVisibility(!isVisible)

  const defineCreditcardOperationType = {
    sale: 'Venda',
    refund: 'Estorno'
  }

  const RenderContent = ({ title, value, titleUppercase = false }) => {
    return (
      <div css={[containerContent, flex]}>
        <span css={[titleContent, titleUppercase && uppercase]}>{title}</span>
        <span css={flexRowCentered}>{value}</span>
      </div>
    )
  }

  const SympleData = ({ text }) => <small css={labelContent}>{text}</small>

  const RenderStatus = ({ status = 'error' }) => {
    return (
      <div css={flexRowCentered}>
        <IconStatus statusColor={FinancialScheduleParser.flowMovementsStatus[status].color} />
        <span css={labelContent}>{FinancialScheduleParser.flowMovementsStatus[status].label}</span>
      </div>
    )
  }

  const TooltipContent = () => {
    return (
      <div css={tooltipStyle}>
        <small>As taxas são descontadas sobre o valor bruto</small>
      </div>
    )
  }

  return (
    <>
      <Row css={upperRowStyle}>
        <RenderContent
          title='Parcela'
          value={<SympleData text={installment + ' de ' + installments} />}
        />
        <RenderContent
          title='Status'
          value={<RenderStatus status={status} />}
        />
      </Row>
      <Card css={[cardStyle, flex]}>
        <div css={[columnStyle, flex]}>
          <RenderContent
            title='Tipo de transação'
            value={<SympleData text={defineCreditcardOperationType[`${creditcardOperationType}`]} />}
          />
          <RenderContent
            title='Data da transação'
            value={<SympleData text={formatDate(transactionDate)} />}
          />
          <RenderContent
            title='Bandeira do cartão'
            value={<SympleData text={i18n?.flagTranslator(label || brand)} />}
          />
        </div>
        <div css={[columnStyle, flex]}>
          <RenderContent
            title='Taxa transacional'
            value={<SympleData text={formatMoney(transactionFee)} />}
          />
          <RenderContent
            title='Taxa antifraude'
            value={<SympleData text={formatMoney(antifraudFee)} />}
          />
          <RenderContent
            title='Taxa MDR'
            value={<SympleData text={formatMoney(mdrFee)} />}
          />
          <RenderContent
            title='Taxa Pay/Xpert'
            value={<SympleData text={formatMoney(payFee)} />}
          />
        </div>
        <div css={[columnStyle, flex]}>
          <RenderContent
            title='Valor líquido'
            value={<SympleData text={formatMoney(netAmount)} />}
          />
          <RenderContent
            title='Valor bruto'
            value={<SympleData text={formatMoney(grossAmount)} />}
          />
        </div>
        <Manager>
          <Reference>
            {({ ref }) => (
              <div ref={ref} css={flex}>
                <Logo onClick={toggleVisibility} css={iconInfoStyle} src={circleInfoIcon} />
              </div>
            )}
          </Reference>
          {isVisible && <LightTooltip position='top'><TooltipContent /></LightTooltip>}
        </Manager>
      </Card>
    </>
  )
}
