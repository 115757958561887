import { MetaParser } from './Meta'

export const CountPlanParser = {
  single: (countPlan) => {
    const {
      id,
      name,
      classification,
      plan_type: planType,
      code,
      level,
      enabled
    } = countPlan?.countPlan ?? countPlan ?? {}

    return {
      id,
      name,
      classification,
      planType,
      level,
      enabled,
      code
    }
  },
  list: ({ meta, accountable_plans: accountablePlans = [] }) => {
    return {
      countPlans: accountablePlans.map(CountPlanParser.single),
      meta: MetaParser.meta(meta)
    }
  },
  listWithoutPagination: ({ accountable_plans: accountablePlans = [] }) => {
    return accountablePlans.map(CountPlanParser.single)
  }
}
