
export const TitleMenusTranslator = (
  dictionary
) => {
  const menuTitleEvents = dictionary?.event_menuTitleEvents_label
  const menuTitleEventsProcessors = dictionary?.event_processor_menuTitleEventsProcessors_label
  const menuTitleCards = dictionary?.creditcard_dashboard_menuTitleCards_label
  const menuTitleBankActions = dictionary?.bank_action_dashboard_menuTitleBankActions_label
  const titleAccountingsFunctions = dictionary?.accounting_function_dashboard_titleAccountingsFunctions_label
  const menuTitleConfigurations = dictionary?.configuration_dashboard_menuTitleConfigurations_label

  return {
    menuTitleEvents,
    menuTitleEventsProcessors,
    menuTitleCards,
    menuTitleBankActions,
    titleAccountingsFunctions,
    menuTitleConfigurations
  }
}
