/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ListContainer } from './List.styles'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConciliationsTranslator } from '../i18n/ConciliationsI18n'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BaseMessageInfo } from './BaseMessageInfo'
import { BaseTable } from './BaseTable'
import { FloatingSelectBar } from 'Shared/components/FloatingSelectBar/FloatingSelectBar'

const ColGroupComponent = ({
  internalMovementsColumnsWidths,
  externalMovementsColumnsWidths,
  isPartialConciliation
}) => {
  return (
    <colgroup>
      <col span={1} style={{ width: '3rem' }}/>
      {externalMovementsColumnsWidths?.map((value, idx) => (
        <col key={idx} span={1} style={{ width: value }}/>
      ))}
      {isPartialConciliation && <col span={1} style={{ width: '6.0rem' }}/>}
      {internalMovementsColumnsWidths?.map((value, idx) => (
        <col key={idx} span={1} style={{ width: value }}/>
      ))}
      <col span={1} style={{ width: '2.8rem' }}/>
    </colgroup>
  )
}

/**
 * @typedef ConciliationListProps
 * @prop { Record<string, string>[] } conciliationsData
 * @prop { Record<string, string> } filters
 * @prop {{
 *  tableTitleExternalMovements: string
 *  internalMovementsColumnsTitles: string[]
 *  externalMovementsColumnsTitles: string[]
 *  externalMovementsColumnsWidths: string[]
 *  internalMovementsColumnsWidths: string[]
 *  isCreditCardConciliationsTabPartial: boolean
 *  isBankConciliationsTabPartial: boolean
 *  isBankConciliationsTabConciliation: boolean
 *  isCreditCardConciliationsTabConciliation: boolean
 *  isLoading: boolean
 *  isLoadingDeleteConciliations: boolean
 * }} options
 * @prop { (params: Record<string, string>) => void } deleteConciliations
 */

/** @type { React.FC<ConciliationListProps> } */
export const ConciliationList = (
  {
    conciliationsData,
    filters,
    options,
    deleteConciliations
  }
) => {
  const [conciliationsIds, setConciliationsIds] = useState([])

  const i18n = useTranslator(ConciliationsTranslator)

  const {
    tableTitleExternalMovements,
    internalMovementsColumnsTitles,
    externalMovementsColumnsTitles,
    externalMovementsColumnsWidths,
    internalMovementsColumnsWidths,
    isCreditCardConciliationsTabPartial,
    isBankConciliationsTabPartial,
    isBankConciliationsTabConciliation,
    isCreditCardConciliationsTabConciliation,
    isLoading,
    isLoadingDeleteConciliations
  } = options

  const isPartialConciliation = useMemo(
    () => isBankConciliationsTabPartial || isCreditCardConciliationsTabPartial,
    [isBankConciliationsTabPartial, isCreditCardConciliationsTabPartial]
  )

  const newColumnsTiltes = useMemo(() => {
    const columns = [
      ...externalMovementsColumnsTitles,
      ...internalMovementsColumnsTitles,
      ''
    ]

    if (isPartialConciliation) {
      columns.splice(4, 0, '')
    }

    return columns
  }, [
    externalMovementsColumnsTitles,
    internalMovementsColumnsTitles,
    isPartialConciliation
  ])

  useEffect(() => {
    setConciliationsIds(
      (oldState) => oldState.filter(
        selectedId => conciliationsData?.some(({ id }) => id === selectedId)
      )
    )
  }, [conciliationsData])

  const handleDeleteConciliations = useCallback(() => {
    deleteConciliations({ ...filters, conciliationsIds })
  }, [conciliationsIds, deleteConciliations, filters])

  const conciliationBtnsLabels = useMemo(() => {
    return [
      {
        label: i18n?.deleteConciliation(conciliationsIds?.length),
        onClick: handleDeleteConciliations,
        isLoading: isLoadingDeleteConciliations,
        disabled: !conciliationsIds?.length
      }

    ]
  }, [conciliationsIds?.length, handleDeleteConciliations, i18n, isLoadingDeleteConciliations])

  return (
    <>
      <ListContainer
        isCreditCardConciliationsTabPartial={isCreditCardConciliationsTabPartial}
        isBankConciliationsTabPartial={isBankConciliationsTabPartial}
        isBankConciliationsTabConciliation={isBankConciliationsTabConciliation}
        isCreditCardConciliationsTabConciliation={isCreditCardConciliationsTabConciliation}
      >
        {!conciliationsData.length
          ? (
            <BaseMessageInfo
              isLoading={isLoading}
              title={i18n?.noConciliationItems}
              subTitle={i18n?.fillInFieldsFromTheStartBar}
            />
          )
          : (
            <>
              <div className='content_table__titles'>
                <p>{tableTitleExternalMovements}</p>
                <p>{i18n?.tableTitleSystemReleases}</p>
              </div>
              <div className='content_table'>
                <BaseTable
                  datas={conciliationsData}
                  isLoading={isLoading}
                  colGroupComponent={
                    <ColGroupComponent
                      internalMovementsColumnsWidths={internalMovementsColumnsWidths}
                      externalMovementsColumnsWidths={externalMovementsColumnsWidths}
                      isPartialConciliation={isPartialConciliation}
                    />
                  }
                  columnsTitles={newColumnsTiltes}
                  i18n={i18n}
                  selectedIds={conciliationsIds}
                  setSelectedIds={setConciliationsIds}
                />
              </div>
            </>
          )
        }
      </ListContainer>
      {!conciliationsData.length ? (
        null
      ) : (
        <FloatingSelectBar
          items={conciliationsIds}
          itemLabel={i18n?.itemSelected(conciliationsIds?.length)}
          floatingSelectBarBtns={conciliationBtnsLabels}
        />
      )
      }
    </>
  )
}
