/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { formatMoney } from '@bonitour/common-functions'
import { sectionValues } from './ValueCard.styles'
import { useMemo } from 'react'
import { LoadingAnimation } from '@bonitour/components'

/**
 * @typedef ValuesCardProps
 * @prop { { text: string | any, value: string | any }[] } valuesCardOptions
 * @prop { import('@emotion/react').SerializedStyles[] } [customCss]
 */

/** @type { React.FC<ValuesCardProps> } */
export const ValuesCard = ({ valuesCardOptions = [], customCss = [] }) => {
  const numberColumns = useMemo(() => valuesCardOptions.length === 1 ? 1 : 3, [valuesCardOptions.length])

  return (
    <>
      <div className='values_card__container' css={[sectionValues(numberColumns), ...customCss]}>
        {valuesCardOptions.map(({ text, value, isLoading = false }) => (
          <section className='values_card__wrapper' key={text + value}>
            <p className='values_card__wrapper_text'>{text || '-'}</p>
            {isLoading ? <LoadingAnimation size={20}/> : (
              <p className='values_card__wrapper_value'>
                {formatMoney(value || 0)}
              </p>
            )}

          </section>

        ))}
      </div>
    </>
  )
}
