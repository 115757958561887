
export const CreditCardDashboardTranslator = (
  dictionary
) => {
  const receivables = dictionary?.creditcard_dashboard_receivables_label
  const creditCardConciliations = dictionary?.creditcard_dashboard_creditCardConciliations_label

  return {
    receivables,
    creditCardConciliations
  }
}
