import { useCallback, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsHooksTranslator } from '../i18n/EventsProcessorsHooksI18n'
import { ConditionService } from 'Core/Service/condition'

export const useCreateCondition = () => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const i18n = useTranslator(EventsProcessorsHooksTranslator)

  const createCondition = useCallback(
    (
      payload, eventProcessorId
    ) => {
      setLoading(true)
      return ConditionService.createCondition(payload, eventProcessorId)
        .then((data) => {
          addToast({
            typeToast: 'success',
            defaultMessage: i18n?.conditionAddedToProcessor
          })
          return data
        })
        .catch((errors) => {
          console.error(errors?.data)
          addToast({
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorAddingConditionInProcessor
          })
          throw errors
        })
        .finally(() => setLoading(false))
    }, [
      addToast,
      i18n?.conditionAddedToProcessor,
      i18n?.errorAddingConditionInProcessor
    ])

  return {
    createCondition,
    loading
  }
}
