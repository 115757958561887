import React, { Fragment } from 'react'
import { ToastProvider } from '@bonitour/components'
import { css } from '@emotion/core'
import { PortalsContainer } from 'Shared/components/Portal/PortalsContainer'
import { GlobalStyle } from 'Shared/styles/global'
import 'reset-css'
import '@bonitour/font/style.css'
import { QueryClient } from 'Shared/contexts/QueryClient'

// avoding bug https://github.com/yannickcr/eslint-plugin-react/issues/2156
global.React = { Fragment }

const toastPosition = css`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999999;
`

export const Base = ({ children }) => (
  <QueryClient>
    <ToastProvider cssStyle={toastPosition}>
      <PortalsContainer>
        {children}
      </PortalsContainer>
      <GlobalStyle />
    </ToastProvider>
  </QueryClient>
)
