/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, ConfirmDialog, PlusIcon } from '@bonitour/components'
import { useTranslator } from 'Shared/language/i18nHandler'
import { CardButton } from 'Shared/components/CardButton'
import { cardIcon } from 'Shared/styles/global'
import { cardButtonStyle, labelStyle } from '../Supplier.styles'
import { SupplierBankAccountsList } from './List/SupplierBankAccountsList'
import { useHistory, useParams } from 'react-router-dom'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { SupplierBankAccountsTranslator } from './i18n/SupplierBankAccountsI18n'
import { useGetSupplier } from '../hooks/useGetSupplier'
import { SupplierBankAccountModal } from './Form/SupplierBankAccountModal'
import { useCallback, useMemo, useState } from 'react'
import { useCreateSupplierBankAccount } from './hooks/useCreateSupplierBankAccount'
import { useEditSupplierBankAccount } from './hooks/useEditSupplierBankAccount'
import { useRemoveSupplierBankAccount } from './hooks/useRemoveSupplierBankAccount'

export const SupplierBankAccounts = () => {
  const onReturnToDashboard = () => push('/expense-revenue-dashboard/suppliers')
  const routerParams = useParams()
  const { push } = useHistory()
  const [isVisibleModal, setVisibilityModal] = useState(false)
  const supplierId = useMemo(() => routerParams?.supplierId, [routerParams?.supplierId])
  const { supplier } = useGetSupplier(supplierId)
  const i18n = useTranslator(SupplierBankAccountsTranslator)
  const { onCreateSupplierBankAccount, createLoading } = useCreateSupplierBankAccount(supplierId)
  const [supplierBankAccountIdToRemove, setSupplierBankAccountIdToRemove] = useState(null)
  const {
    onEditSupplierBankAccount,
    editLoading,
    supplierBankAccount,
    fetchSupplierBankAccount,
    setSupplierBankAccount
  } = useEditSupplierBankAccount()

  const toggleModal = useCallback(() => {
    setVisibilityModal(!isVisibleModal)
    setSupplierBankAccount(null)
  }, [isVisibleModal, setSupplierBankAccount])

  const onEdit = useCallback((supplierId, supplierBankAccountId) => {
    fetchSupplierBankAccount(supplierId, supplierBankAccountId)
    toggleModal()
  }, [fetchSupplierBankAccount, toggleModal])

  const closeDialog = useCallback(() => {
    setSupplierBankAccountIdToRemove(null)
  }, [])

  const onDialogRemoveSupplierBankAccount = useCallback((id) => {
    setSupplierBankAccountIdToRemove(id)
  }, [])

  const { onRemoveSupplierBankAccount } = useRemoveSupplierBankAccount()

  const onValidationRemoveSuccess = useCallback((supplierBankAccountId) => {
    onRemoveSupplierBankAccount(supplierBankAccountId, supplierId)
    closeDialog()
  }, [onRemoveSupplierBankAccount, supplierId, closeDialog])

  return (
    <>
      {isVisibleModal &&
        <SupplierBankAccountModal
          toggleHidden={toggleModal}
          isVisible={isVisibleModal}
          onCreateSupplierBankAccount={onCreateSupplierBankAccount}
          loading={createLoading || editLoading}
          i18n={i18n}
          onEditSupplierBankAccount={onEditSupplierBankAccount}
          supplierBankAccountData={supplierBankAccount}
          supplierId={supplierId}
        />
      }
      <ConfirmDialog
        title={i18n.removeDialogTitle}
        isVisible={Boolean(supplierBankAccountIdToRemove)}
        message={i18n.removeDialogMessage}
        successCallback={() => onValidationRemoveSuccess(supplierBankAccountIdToRemove)}
        cancelCallback={closeDialog}
      />
      <PageTitle
        title={`${i18n.pageTitleSupplierBankAccount} ${supplier?.corporateName}`}
        onClickReturn={onReturnToDashboard}
      />
      <CardButton css={cardButtonStyle} onClick={toggleModal}>
        <PlusIcon css={cardIcon} />
        <span css={labelStyle}>{i18n.newSupplierBankAccountButton}</span>
      </CardButton>
      <Card>
        <SupplierBankAccountsList
          supplierId={supplierId}
          onEdit={onEdit}
          onDialogRemoveSupplierBankAccount={onDialogRemoveSupplierBankAccount}
        />
      </Card>
    </>
  )
}
