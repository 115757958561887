import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const width100 = css`
  max-width: 100%;
`

export const labelStyle = css`
  color: ${colors.primary};
  margin-right: 10px;
  cursor: pointer;
`

export const iconSize = css`
  font-size: 22px;
`

export const hidden = css`
  display: none;
`
