import { billingCore } from './Billing'
import { BankTransfersModel } from 'Core/Models/bankTransfers'
import { BankTransfersParser } from 'Core/Parser/bankTransfers'
import {
  GetBankTransferParsed,
  GetBankTransfersParams,
  PostBankTransferPayload,
  PatchBankTransferPayload,
  PostBankTransferRes,
  PatchBankTransferRes,
  BankTransferParsed,
  BankTransferAuditsResParsed
} from '../Types/BankTransfer'

export const BankTransfersService = {
  getBankTransfers: async (filters: GetBankTransfersParams): Promise<GetBankTransferParsed> => {
    return await billingCore.bankTransferDomain.getBankTransfers(
      BankTransfersModel.getBankTransfers(filters)
    ).then(BankTransfersParser.getBankTransfers)
  },
  postBankTransfer: async (createBankTransferPayload: PostBankTransferPayload): Promise<PostBankTransferRes> => {
    return await billingCore.bankTransferDomain.postBankTransfer(
      BankTransfersModel.postBankTransfer(createBankTransferPayload)
    )
  },
  patchBankTransfer: async (updateBankTransferPayload: PatchBankTransferPayload, bankTransferId: string): Promise<PatchBankTransferRes> => {
    return await billingCore.bankTransferDomain.patchBankTransfer(
      BankTransfersModel.patchBankTransfer(updateBankTransferPayload), bankTransferId
    )
  },
  getBankTransfer: async (
    bankTransferId: string
  ): Promise<BankTransferParsed> => {
    return await billingCore.bankTransferDomain.getBankTransfer(
      bankTransferId
    ).then(BankTransfersParser.getBankTransfer)
  },
  getBankTransferAudits: async (
    bankTransferId: string
  ): Promise<BankTransferAuditsResParsed> => {
    return await billingCore.bankTransferDomain.getBankTransferAudits(
      bankTransferId
    ).then(BankTransfersParser.getBankTransferAudits)
  }
}
