import { useMemo } from 'react'
import { dictionary } from './dictionary'

const languages = {
  pt: 'pt_BR',
  pt_br: 'pt_BR',
  'pt-br': 'pt_BR',

  en: 'en_US',
  en_us: 'en_US',
  'en-us': 'en_US',

  es: 'es_ES',
  es_es: 'es_ES',
  'es-es': 'es_ES'
}

export const getLangCode = (locale) => {
  const lowerCasedLocale = locale?.toLowerCase() || ''
  return languages[lowerCasedLocale] || languages.en
}

export const matchDictionary = (fn, locale) => {
  const languageCode = getLangCode(locale)
  const langDictionary = dictionary[languageCode]

  return fn(langDictionary, locale)
}

export const useTranslator = (fn) => {
  const locale = 'pt'

  const translation = useMemo(() => matchDictionary(fn, locale), [fn, locale])

  return translation
}
