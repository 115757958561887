/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { formatMoney, formatDate, identity } from '@bonitour/common-functions'
import {
  EditInfoIcon,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow
} from '@bonitour/components'

import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'

import { useListRevenueAudits } from '../hooks/useListRevenueAudits.js'
import { Audit } from 'Shared/components/Audit/Audit.jsx'
import { CircleStatus, ValueColor, wrapperButtons } from 'Shared/styles/global.js'
import { statusLabelColor } from 'Domains/ExpenseRevenue/constants/expenseRevenue.js'
import { useTranslator } from 'Shared/language/i18nHandler.js'
import { RevenuesTranslator } from '../i18n/RevenuesI18n.js'

const RevenuesListingTableHeader = ({ columns = [] }) => (
  <TableHeader>
    <TableHeaderRow>
      {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
    </TableHeaderRow>
  </TableHeader>
)

const RevenuesListingTableBody = ({ revenues, loading, onEdit }) => {
  const {
    datas,
    fetchListRevenueAudits,
    loading: auditLoaging
  } = useListRevenueAudits()
  const i18n = useTranslator(RevenuesTranslator)

  return (
    <TableBody loading={loading}>
      {revenues.map(({ id, date, expenseRevenue, serviceName, status, value, userName }) => (
        <TableBodyRow key={id}>
          <TableBodyCell>{date ? formatDate(date) : '-'}</TableBodyCell>
          <TableBodyCell><SafeOverflow text={expenseRevenue?.description || '-'} /></TableBodyCell>
          <TableBodyCell><SafeOverflow text={serviceName || '-'} alignment='center' /></TableBodyCell>
          <TableBodyCell>
            <ValueColor statusColor={statusLabelColor[status].color}>
              {value ? formatMoney(value) : '-'}
            </ValueColor>
          </TableBodyCell>
          <TableBodyCell>
            <CircleStatus statusColor={statusLabelColor[status].color}>
              {statusLabelColor[status].label}
            </CircleStatus>
          </TableBodyCell>
          <TableBodyCell><SafeOverflow text={userName || '-'} alignment='center' /></TableBodyCell>
          <TableBodyCell css={wrapperButtons}>
            <EditInfoIcon onClick={() => onEdit(id)} />
            <Audit
              auditDatas={datas}
              fetchAuditDatas={fetchListRevenueAudits}
              documentId={id}
              auditLoading={auditLoaging}
              modalTitle={i18n.modalAuditRevenueTitle}
              i18n={i18n.auditLabels}
            />
          </TableBodyCell>
        </TableBodyRow>
      ))}
    </TableBody>
  )
}

export const RevenuesList = ({
  columns = [],
  revenues = [],
  loading = false,
  onEdit = identity
}) => {
  return (
    <>
      <TableContainer>
        <RevenuesListingTableHeader columns={columns} />
        <RevenuesListingTableBody
          revenues={revenues}
          loading={loading}
          onEdit={onEdit}
        />
      </TableContainer>
    </>
  )
}
