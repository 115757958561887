import { metaModel } from './Meta'

export const ExpenseRevenuesModel = {
  list: ({ filters, pagination }) => {
    return {
      searchParams: ExpenseRevenueSearchModel(filters),
      pagination: metaModel(pagination)
    }
  },
  create (payload, user) {
    const {
      description,
      expenseRevenueType,
      enabled,
      userId,
      accountPlanId
    } = payload ?? {}

    return {
      description,
      expense_revenue_type: expenseRevenueType,
      enabled,
      user_id: userId,
      accountable_plan_id: accountPlanId,
      user_name: ExpenseRevenuesModel.safeUser(user)
    }
  },
  edit (payload, user) {
    const {
      id,
      description,
      expenseRevenueType,
      enabled,
      userId,
      accountPlanId
    } = payload ?? {}

    return {
      id,
      description,
      expense_revenue_type: expenseRevenueType,
      enabled,
      user_id: userId,
      accountable_plan_id: accountPlanId,
      user_name: ExpenseRevenuesModel.safeUser(user)
    }
  },
  safeUser (user = {}) {
    const safeUserName = user?.name || ''
    return safeUserName
  }
}

export const ExpenseRevenueSearchModel = (filterParams = {}) => {
  const {
    description,
    enabled,
    expenseRevenueType,
    userId
  } = filterParams

  return {
    description,
    enabled,
    expense_revenue_type: expenseRevenueType,
    user_id: userId
  }
}
