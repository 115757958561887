
import { BankConciliationParser } from 'Core/Parser/bankConciliation'
import { billingCore } from './Billing'
import { BankConciliationModel } from 'Core/Models/bankConciliation'

export const BankConciliationService = {
  getBankConciliations (filters) {
    return billingCore.bankConciliationDomain.getBankConciliations(
      BankConciliationModel.getBankConciliations(filters)
    ).then(BankConciliationParser.getBankConciliations)
  },
  postAutoBankConciliation (params, user) {
    return billingCore.bankConciliationDomain.postAutoBankConciliation(
      BankConciliationModel.postAutoBankConciliation(params, user)
    ).then(BankConciliationParser.postAutoBankConciliation)
  },
  postManualBankConciliation (params, user) {
    return billingCore.bankConciliationDomain.postManualBankConciliation(
      BankConciliationModel.postManualBankConciliation(params, user)
    ).then(BankConciliationParser.postManualBankConciliation)
  },
  deleteBankConciliations (params, user) {
    return billingCore.bankConciliationDomain.deleteBankConciliations(
      BankConciliationModel.deleteBankConciliations(params, user)
    )
  }
}
