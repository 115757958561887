import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const FloatingSelectBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
  position: sticky;
  background-color: var(--clr-white);
  bottom: 0;
  padding: 0.625rem 1.25rem;
  margin: 1.25rem min(calc((-100vw + 77.5rem) / 2), -1.25rem) -0.9375rem;
  box-shadow: 0px 0px 6px #a3a0a0;

  .floating_select_bar__section_btns {
    display: flex;
    gap: 1.25rem;

    .floating_select_bar__btn {
      min-width: 270px;
      padding: 0.625rem 1.25rem;
      background-color: var(--clr-indigo1);
      border-radius: var(--round-element-radius, 1.25rem);
      color: var(--clr-white);
      font-weight: 700;

      &:disabled {
        background-color: var(--clr-gray6);
        cursor: default;
      }

      .floating_select_bar__load {
        width: 100%;
      }
    }
  }

  .floating_wrapper__counts {
    display: flex;

    .floating_select_bar__section_item {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      font-weight: 700;

      .section_item_count {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100vw;
        width: 1.875rem;
        height: 1.875rem;
        color: var(--clr-indigo1);
        background-color: var(--clr-indigo3);
      }

      p {
        color: var(--clr-gray3);
      }

      padding: 0 2rem;
      border-right: 2px solid var(--clr-gray6);

      .section_item_money {
        &__positive {
          color: var(--clr-green2)
        }

        &__negative {
          color: var(--clr-red2)
        }
      }

      :first-of-type {
        padding: 0 2rem 0 0;
      }

      :last-of-type {
        border-right: none;
      }
    }

  }
 

  @media(max-width: ${BREAK_POINTS.smallTablet}) {
    flex-direction: column;

    .floating_select_bar__section_btns {
      width: 100%;
      flex-direction: column;
    }
  }

  @media(max-width: ${BREAK_POINTS.desktop}), (max-width: ${BREAK_POINTS.hdDesktop}) {
    gap: 0.3125rem;
    padding: 0.625rem;

    .floating_wrapper__counts {
      .floating_select_bar__section_item {
        flex-direction: column;
        gap: 0.3125rem;
        padding: 0 0.625rem;
        align-items: flex-start;

        &:first-of-type {
          align-items: center;
          flex-direction: row;
          padding: 0 0.625rem 0 0;
        }
      }
    }

    .floating_select_bar__section_btns {  
      gap: 0.625rem;

      .floating_select_bar__btn {
        min-width: 200px;
        padding: 0.625rem 0.3125rem;
      }
    }
  }
`
