
import { useCallback } from 'react'
import { ExpenseService } from 'Core/Service/expense'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpensesHooksTranslator } from '../i18n/ExpensesHooksI18n'
import { BackendErrorFormat } from 'Core/Types/Error'

export const useDeleteReceipts = () => {
  const { addToast } = useToast()

  const i18n = useTranslator(ExpensesHooksTranslator)

  const onDeleteReceipts = useCallback(async (receiptsIds: string[], expenseId: string) => {
    return await ExpenseService.deleteReceipts(receiptsIds, expenseId)
      .catch((errors: BackendErrorFormat) => {
        console.error(errors?.data)
        addToast(
          {
            msgs: errors?.data?.errors_msg,
            defaultMessage: i18n?.errorDeletingReceipts
          }
        )
      })
  }, [addToast, i18n?.errorDeletingReceipts])

  return {
    onDeleteReceipts
  }
}
