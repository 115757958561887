/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, ConfirmDialog, PaginationContainer, PlusIcon } from '@bonitour/components'
import { CardButton } from 'Shared/components/TitleMenus/CardButton'
import { useUser } from 'Shared/contexts/User'
import { cardButton, cardIcon } from 'Shared/styles/global'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useListExpenseRevenuesType } from './hooks/useListExpenseRevenuesType'
import { useChangeExpenseRevenues } from './hooks/useChangeExpenseRevenue'
import { cardButtonStyle, labelStyle, marginTop } from './ExpenseRevenueType.styles'
import { ExpenseRevenueTypeFilter } from './Filter/ExpenseRevenueTypeFilter'
import { ExpenseRevenueTypeList } from './List/ExpenseRevenueTypeList'
import { RegisterExpenseRevenuesModal } from './RegisterExpenseRevenuesModal/RegisterExpenseRevenuesModal'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ExpenseRevenueTypesTranslator } from './i18n/ExpensesRevenuesTypesI18n'
import { useAccountsPlanOptions } from '../../AccountingFunction/CountPlan/hooks/useAccountsPlanOptions'

export const ExpenseRevenueType = () => {
  const { push } = useHistory()
  const { user } = useUser()
  const [isVisibleModal, setVisibilityModal] = useState(false)
  const [isVisibleDialog, setVisibilityDialog] = useState(false)
  const [expenseRevenueData, setExpenseRevenueData] = useState()
  const [defaultValues, setDefaultValues] = useState({})
  const [idExpenseRevenueToRemove, setIdExpenseRevenueToRemove] = useState()
  const i18n = useTranslator(ExpenseRevenueTypesTranslator)

  const toggleModal = useCallback(() => setVisibilityModal(!isVisibleModal), [isVisibleModal])
  const toggleDialog = useCallback(() => setVisibilityDialog(!isVisibleDialog), [isVisibleDialog])

  const {
    expenseRevenues = [],
    handlePagination,
    meta,
    loading,
    filters,
    pagination,
    handleFilters,
    fetchExpenseRevenues,
    perPageOptions
  } = useListExpenseRevenuesType()

  const { accountsPlanOptions } = useAccountsPlanOptions()

  const {
    onEditExpenseRevenue,
    onCreateExpenseRevenue,
    onRemoveExpenseRevenue,
    loading: changeExpenseRevenueLoading
  } = useChangeExpenseRevenues(fetchExpenseRevenues, setVisibilityModal, setVisibilityDialog)

  const { current: initialFilters } = useRef(filters)

  const onReturnToDashboard = () => push('/expense-revenue-dashboard')

  const { count = 0, last = 1 } = meta ?? {}

  const onModalEditExpenseRevenue = useCallback((data) => {
    setExpenseRevenueData(data)
    setDefaultValues({
      accountPlanId: data?.accountPlanId || '',
      description: data?.description || '',
      expenseRevenueType: data?.expenseRevenueType || '',
      enabled: data?.enabled,
      userId: data?.userId,
      id: data?.id
    })
    toggleModal()
  }, [toggleModal])

  const onModalCreateExpenseRevenue = useCallback(() => {
    setExpenseRevenueData(undefined)
    setDefaultValues({
      description: '',
      expenseRevenueType: '',
      enabled: true,
      userId: user.id
    })
    toggleModal()
  }, [toggleModal, user])

  const onDialogRemoveExpenseRevenue = useCallback((id) => {
    setIdExpenseRevenueToRemove(id)
    toggleDialog()
  }, [toggleDialog])

  const onValidationRemoveSuccess = useCallback((id) => {
    onRemoveExpenseRevenue(id, filters, pagination)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>

      {isVisibleModal &&
        <RegisterExpenseRevenuesModal
          toggleHidden={toggleModal}
          isVisible={isVisibleModal}
          expenseRevenueId={expenseRevenueData?.id}
          defaultValues={defaultValues}
          onEditExpenseRevenue={onEditExpenseRevenue}
          onCreateExpenseRevenue={onCreateExpenseRevenue}
          filters={filters}
          pagination={pagination}
          loading={changeExpenseRevenueLoading}
          accountsPlanOptions={accountsPlanOptions}
        />}
      <ConfirmDialog
        title='Excluir'
        isVisible={isVisibleDialog}
        message='Você realmente deseja excluir esse tipo de Receita/Despesa?'
        successCallback={() => onValidationRemoveSuccess(idExpenseRevenueToRemove)}
        cancelCallback={toggleDialog}
      />
      <PageTitle
        title='Tipo de Despesa/Receita'
        onClickReturn={onReturnToDashboard}
      />
      <CardButton onClick={onModalCreateExpenseRevenue} css={[cardButton, cardButtonStyle]}>
        <PlusIcon css={cardIcon} />
        <span css={labelStyle}>Cadastrar novo tipo de despesa ou receita</span>
      </CardButton>
      <Card css={marginTop}>
        <ExpenseRevenueTypeFilter
          onSearch={handleFilters}
          initialFilters={initialFilters}
        />
        <PaginationContainer
          total={count}
          pagesQuantity={last}
          onPagination={handlePagination}
          currentPage={pagination.currentPage}
          pageLimitDefault={pagination.perPage}
          pageLimitOptions={perPageOptions}
          isOpenDrawer
        >
          <ExpenseRevenueTypeList
            expenseRevenues={expenseRevenues}
            onModalEditExpenseRevenue={onModalEditExpenseRevenue}
            onDialogRemoveExpenseRevenue={onDialogRemoveExpenseRevenue}
            loading={loading}
            i18n={i18n}
          />
        </PaginationContainer>
      </Card>
    </>
  )
}
