export const BankAccountsTranslator = (
  dictionary
) => {
  const pageTitleBankAccount = dictionary?.bank_account_pageTitleBankAccounts_label
  const modalAuditBankAccountTitle = dictionary?.bank_account_auditModalTitle_label
  const editModalTitleBankAccounts = dictionary?.bank_account_editModalTitle_label

  const bankAccountTableColumnsTitles = [
    dictionary?.bank_account_tableColumnTitleBankName_label,
    dictionary?.bank_account_tableColumnTitleBranchNumber_label,
    dictionary?.bank_account_tableColumnTitleAccountNumber_label,
    dictionary?.bank_account_tableColumnTitleStatus_label,
    dictionary?.bank_account_tableColumnTitleAction_label
  ]

  const bankAccountStatusCodesMapped = {
    enabled: { label: dictionary?.bank_account_statusValueEnabled_label },
    disabled: { label: dictionary?.bank_account_statusValueDisabled_label }
  }

  const bankAccountEnabledOptions = [
    { value: 'enabled', label: dictionary?.bank_account_statusValueEnabled_label },
    { value: 'disabled', label: dictionary?.bank_account_statusValueDisabled_label }
  ]

  const bankAccountSaveButton = dictionary?.common_save_label
  const bankAccountCancelButton = dictionary?.common_cancel_label

  const auditLabels = {
    accountable_plan_name: dictionary?.audit_accountablePlanName_label,
    branch: dictionary?.audit_branch_label,
    account_type: dictionary?.audit_accountType_label,
    account_number: dictionary?.audit_accountNumber_label
  }

  return {
    pageTitleBankAccount,
    modalAuditBankAccountTitle,
    bankAccountTableColumnsTitles,
    bankAccountStatusCodesMapped,
    bankAccountEnabledOptions,
    bankAccountSaveButton,
    bankAccountCancelButton,
    editModalTitleBankAccounts,
    auditLabels
  }
}
