/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, Label, PaginationContainer, PlusIcon } from '@bonitour/components'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useListEventsProcessors } from './hooks/useListEventsProcessors'
import { EventsProcessorsList } from './List/EventsProcessorsList'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsTranslator } from './i18n/EventsProcessorsI18n'
import { EventsProcessorsFilters } from './Form/EventsProcessorsFilters'
import { CardButton } from 'Shared/components/TitleMenus/CardButton'
import { cardButton, cardIcon, labelCardButton } from 'Shared/styles/global'
import { EventsProcessorsContainer } from './EventsProcessors.styles'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const EventsProcessors = () => {
  const {
    eventsProcessorsDatas,
    meta,
    isLoading,
    handleFilters,
    handlePagination,
    perPageOptions,
    filters,
    fetchEventsProcessors,
    pagination
  } = useListEventsProcessors()

  const { count = 0, last = 1 } = meta ?? {}

  const { push } = useHistory()

  useFlaggedRoute('billing-events-processors')

  const i18n = useTranslator(EventsProcessorsTranslator)

  return (
    <EventsProcessorsContainer>
      <PageTitle
        title={i18n?.pageTitleEventsProcessors}
      />
      <CardButton
        className='btn_create'
        onClick={() => push('events-processors/create')}
        css={cardButton}>
        <PlusIcon css={cardIcon} />
        <Label css={labelCardButton}>{i18n?.newProcessor}</Label>
      </CardButton>
      <Card>
        <EventsProcessorsFilters
          filters={filters}
          onFilterSubmit={handleFilters}
          buttonLabel={i18n?.searchButton}
          isLoading={isLoading}
        />
        <PaginationContainer
          total={count}
          currentPage={pagination.currentPage}
          pageLimitDefault={pagination.perPage}
          pagesQuantity={last}
          onPagination={handlePagination}
          pageLimitOptions={perPageOptions}
          isOpenDrawer
        >
          <EventsProcessorsList
            eventsProcessorsData={eventsProcessorsDatas}
            isLoading={isLoading}
            fetchEventsProcessors={fetchEventsProcessors}
          />
        </PaginationContainer>
      </Card>
    </EventsProcessorsContainer>
  )
}
