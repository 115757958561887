/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Card, Label, PaginationContainer, PlusIcon } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useRef, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { CardButton } from 'Shared/components/TitleMenus/CardButton'
import { cardBox, marginTop, sectionHeader, customValueCard } from './Revenues.styles'
import { ExpensesRevenuesFilters } from '../Filters/ExpensesRevenuesFilters'
import { RevenuesList } from './List/RevenuesList'
import { cardButton, cardIcon, labelCardButton } from 'Shared/styles/global'
import { revenueInitialStatusOptions, revenuesListColumnsTitles } from '../constants/expenseRevenue'
import { ExpenseRevenueModal } from '../Modal/ExpenseRevenueModal'
import { useListRevenues } from './hooks/useListRevenues'
import { useCreateRevenue } from './hooks/useCreateRevenue'
import { useRevenueAllowedStatus } from './hooks/useRevenueAllowedStatus'
import { useEditRevenue } from './hooks/useEditRevenue'
import { ValuesCard } from 'Shared/components/ValuesCard/ValuesCard'
import { useExpenseRevenuesOptions } from '../ExpenseRevenueType/hooks/useExpenseRevenuesOptions'
import { revenueStatusOptionsSearch } from 'Constants/revenuesStatus'

export const Revenues = () => {
  const { push } = useHistory()
  const {
    handleFilters,
    filters,
    pagination,
    revenues,
    meta,
    loading,
    handlePagination,
    fetchRevenues,
    perPageOptions,
    revenueForgottenData
  } = useListRevenues()

  const { onCreateRevenue } = useCreateRevenue()
  const {
    fetchRevenue,
    revenue,
    loading: editLoading,
    onEditRevenue,
    setRevenue
  } = useEditRevenue()
  const { fetchAllowedStatus, allowedStatusOptions } = useRevenueAllowedStatus()

  const { expenseRevenuesOptions } = useExpenseRevenuesOptions()

  const [isBillVisible, setBillVisibility] = useState(false)
  const onBillVisible = () => setBillVisibility(true)
  const onBillHidden = () => {
    setBillVisibility(false)
    setRevenue(null)
  }
  const onEdit = (id) => {
    onBillVisible()
    fetchRevenue(id)
    fetchAllowedStatus(id)
  }

  const { current: initialFilters } = useRef(filters)

  const { count = 0, last = 1, total = 0 } = meta ?? {}

  const onReturnToDashboard = () => push('/expense-revenue-dashboard')

  const valuesCardOptions = useMemo(() => [{ text: 'Total', value: total }], [total])

  // palliative solution until added to the translation
  const forgottenInfo = useMemo(() => {
    const { forgotten, daysAgo } = revenueForgottenData || {}

    if (forgotten !== undefined && daysAgo !== undefined) {
      const scheduledAccountReceivable = forgotten > 1 ? 'Contas a Receber agendadas e não pagas' : 'Conta a Receber agendada e não paga'
      const timePeriod = daysAgo > 1 ? `nos últimos ${daysAgo} dias` : 'no último dia'

      return `Há ${forgotten} ${scheduledAccountReceivable} ${timePeriod}`
    }

    return ''
  }, [revenueForgottenData])

  return (
    <>
      <PageTitle
        title='Contas a Receber'
        onClickReturn={onReturnToDashboard}
      />
      <div css={[sectionHeader]}>
        <CardButton onClick={onBillVisible} css={cardButton}>
          <PlusIcon css={cardIcon} />
          <Label css={labelCardButton}>Nova conta</Label>
        </CardButton>
        <ValuesCard
          valuesCardOptions={valuesCardOptions}
          customCss={[customValueCard]}
        />
      </div>
      <Card css={[cardBox, marginTop]}>
        <ExpensesRevenuesFilters
          isLoading={loading}
          handleFiltersChange={handleFilters}
          filters={initialFilters}
          expenseRevenuesOptions={expenseRevenuesOptions}
          statusOptions={revenueStatusOptionsSearch}
          typeExpenseOrRevenueLabel='Tipo de receita'
          buttonLabel='Pesquisar'
        />
        {revenueForgottenData?.forgotten > 0 &&
          <p className='info__forgotten'>
            {forgottenInfo}
          </p>}
        <PaginationContainer
          total={count}
          currentPage={pagination.currentPage}
          pageLimitDefault={pagination.perPage}
          pagesQuantity={last}
          onPagination={handlePagination}
          pageLimitOptions={perPageOptions}
          isOpenDrawer
        >
          <RevenuesList
            loading={loading}
            columns={revenuesListColumnsTitles}
            revenues={revenues}
            onEdit={onEdit}
          />
        </PaginationContainer>
      </Card>
      {isBillVisible
        ? (
          <ExpenseRevenueModal
            toggleHidden={onBillHidden}
            isVisible
            onCreate={onCreateRevenue}
            expenseRevenueData={revenue}
            loading={editLoading}
            onEdit={onEditRevenue}
            fetchRevenues={fetchRevenues}
            allowedStatusOptions={allowedStatusOptions}
            initialStatusOptions={revenueInitialStatusOptions}
            expenseRevenuesOptions={expenseRevenuesOptions}
          />
        )
        : null}
    </>
  )
}
