/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { formatMoney } from '@binamik/js-functions'
import { SafeDate } from '@binamik/js-providers'
import {
  Checkbox,
  InfoIcon,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableContainer
} from '@bonitour/components'
import { jsx } from '@emotion/core'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { Fragment } from 'react'
import { Tooltip } from 'Shared/components/Tooltip'

/**
 * @typedef TableBodyOptions
 * @prop { boolean } [withCheckBox]
 * @prop { Record<string, string> } i18n
 * @prop {(id: string) => void } handleOnSelectRow
 * @prop { (id: string) => void } selectedId
 * @prop { string } id
 * @prop { boolean } disabled
 */

/**
 * @typedef BaseTableBodyProps
 * @prop { Record<string, string>[] } datas
 * @prop { TableBodyOptions } tableBodyOptions
 */

/** @type { React.FC<BaseTableBodyProps> } */
export const BaseTableBody = (
  {
    datas,
    tableBodyOptions = {
      withCheckBox: false
    }
  }
) => {
  return (
    <>
      <TableBodyRow>
        {tableBodyOptions?.withCheckBox ? (
          <TableBodyCell>
            <Checkbox
              disabled={tableBodyOptions?.disabled || false}
              className='checkbox'
              onChange={() => tableBodyOptions?.handleOnSelectRow(tableBodyOptions?.id)}
              checked={tableBodyOptions?.selectedId(tableBodyOptions?.id)}
            />
          </TableBodyCell>
        ) : null
        }
        {datas?.map(
          (
            { data, type, options }, idx
          ) => (
            <Fragment key={idx}>
              {type === 'normal'
                ? (
                  <TableBodyCell>
                    {data}
                  </TableBodyCell>
                )
                : type === 'safe-overflow' ? (
                  <TableBodyCell>
                    <SafeOverflow
                      text={
                        options?.isDescriptionTranslated
                          ? tableBodyOptions?.i18n?.descriptionTranslator(data)
                          : data
                      }
                      alignment={options?.align}
                      className={options?.safeOverflowClassName}
                    />
                  </TableBodyCell>
                ) : type === 'money' ? (
                  <TableBodyCell
                    className={
                      `value_color ${options?.classNameMoney}`
                    }
                  >
                    {options?.removeValueNegative ? (
                      <SafeOverflow
                        text={data !== '0.0' ? formatMoney(data) : ''}
                        alignment='center'
                        className={options?.safeOverflowClassName}
                      />
                    ) : (
                      <SafeOverflow
                        text={formatMoney(data)}
                        alignment='center'
                        className={options?.safeOverflowClassName}
                      />
                    )}
                  </TableBodyCell>
                ) : type === 'date' ? (
                  <TableBodyCell>
                    {data ? new SafeDate(data).format(tableBodyOptions?.i18n?.dateFormat || 'DD/MM/YYYY') : ''}
                  </TableBodyCell>
                ) : type === 'table' ? (
                  <TableBodyCell colSpan={options?.colSpan}>
                    <TableContainer>
                      <colgroup>
                        {options?.arrayWidths?.map(
                          (value, idx) => (
                            <col key={idx} span={1} style={{ width: value }}/>
                          )
                        )}
                      </colgroup>
                      <TableBody>
                        {data.map(({ id, values }) => (
                          <BaseTableBody
                            key={id}
                            datas={values}
                            tableBodyOptions={{
                              i18n: tableBodyOptions?.i18n
                            }}
                          />
                        ))}
                      </TableBody>
                    </TableContainer>
                  </TableBodyCell>
                ) : type === 'info' ? (
                  <TableBodyCell>
                    <div className='container_info'>
                      <InfoIcon />
                      <Tooltip
                        verticalPosition='center'
                        horizontalPosition='left'
                        tooltipClass='tooltip__conciliation'
                      >
                        <p>{tableBodyOptions?.i18n?.conciliationBy}: {data.userName}</p>
                        {options?.hasShowStatusDetails &&
                           <p>
                             {tableBodyOptions?.i18n?.details}: {
                               tableBodyOptions?.i18n?.statusDetails(data?.statusDetails, data?.regexStatusDetails)
                             }
                           </p>
                        }
                        <p>
                          {tableBodyOptions?.i18n?.data}: {data.createdAt.format(tableBodyOptions?.i18n?.dateWithHourTemplate)}
                        </p>
                      </Tooltip>
                    </div>
                  </TableBodyCell>
                ) : null}
            </Fragment>
          ))}
      </TableBodyRow>
    </>
  )
}
