/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useMemo, useState } from 'react'
import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TrashIcon,
  TableContainer,
  PaginationContainer
} from '@bonitour/components'
import { listContainer } from './BankStatementsList.styles'
import { SafeOverflow } from 'Shared/components/SafeOverflow/SafeOverflow'
import { ConfirmDialog } from 'Shared/components/ConfirmDialog/ConfirmDialog'
import { BankStatementsFilters } from '../Filters/BankStatementsFilters'
import { useRemoveBankStatements } from '../hooks/useRemoveBankStatements'
import { useListBankStatements } from '../hooks/useListBankStatements'
import { BankStatementsFiltersSchema } from '../Filters/BankStatementsFilters.schema'
import { useForm } from '@bonitour/app-functions'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankStatementsTranslator } from '../i18n/BankStatementsI18n'

const BASE_FORM = {}

const BankStatementsTableHeader = () => {
  const columns = useMemo(() => ['Data', 'Histórico', 'Nº de Documento', 'Valor', 'Saldo'], [])

  return (
    <TableHeader>
      <TableHeaderRow>
        {columns.map((item) => <TableHeaderCell key={item}>{item}</TableHeaderCell>)}
      </TableHeaderRow>
    </TableHeader>
  )
}

/**
 * @typedef BankStatementsTableBodyProps
 * @prop { boolean } loading
 * @prop { Record<string, string> } bankStatement
 * @prop { Record<string, string> } i18n
 */

/** @type { React.FC<BankStatementsTableBodyProps> } */
const BankStatementsTableBody = ({ loading, bankStatements, i18n }) => {
  return (
    <TableBody loading={loading}>
      {bankStatements.map(
        ({
          id,
          value,
          documentNumber,
          balance,
          date,
          parsedBankStatementDescription
        }) => (
          <TableBodyRow key={id}>
            <TableBodyCell>{date}</TableBodyCell>
            <TableBodyCell>
              <SafeOverflow
                text={i18n?.descriptionTranslator(parsedBankStatementDescription)}
                className='transaction_description__column'
              />
            </TableBodyCell>
            <TableBodyCell>
              <SafeOverflow
                text={documentNumber || '-'}
                alignment='center'
                className='document_number__column'
              />
            </TableBodyCell>
            <TableBodyCell
              className={`value_color ${value.includes('-') ? 'value_color__negative' : 'value_color__positive'}`}
            >
              {value || '-'}
            </TableBodyCell>
            <TableBodyCell>
              {balance || '-'}
            </TableBodyCell>
          </TableBodyRow>
        ))}
    </TableBody>
  )
}

/**
 * @typedef BankStatementsListProps
 * @prop { Array<Record<string, string>> } bankAccountsOptions
 */

/** @type { React.FC<BankStatementsListProps> } */
export const BankStatementsList = ({
  bankAccountsOptions
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isMessageSelectPeriod, setIsMessageSelectPeriod] = useState(true)

  const {
    form,
    errors,
    onSubmit,
    setForm,
    utils: { onInputBlur, onInputChange }
  } = useForm(BASE_FORM, BankStatementsFiltersSchema)

  const {
    isloading,
    handleFilters,
    handlePagination,
    meta,
    bankStatementsData,
    filters,
    perPageOptions,
    fetchBankStatements
  } = useListBankStatements()

  const { count, last } = meta ?? {}

  const {
    onRemoveBankStatements
  } = useRemoveBankStatements(fetchBankStatements)

  const toggleModal = useCallback(() => {
    setIsOpenModal((isOpen) => !isOpen)
  }, [])

  const handleRemoveBankStatements = useCallback(() => {
    onRemoveBankStatements(form)
    setIsOpenModal(false)
    setForm({})
  }, [form, onRemoveBankStatements, setForm])

  const handleChangeFilters = useCallback((filters) => {
    handleFilters(filters)
    setIsMessageSelectPeriod(false)
  }, [handleFilters])

  const i18n = useTranslator(BankStatementsTranslator)

  return (
    <>
      <BankStatementsFilters
        bankAccountsOptions={bankAccountsOptions}
        onFilterSubmit={handleChangeFilters}
        form={form}
        onInputBlur={onInputBlur}
        onInputChange={onInputChange}
        onSubmit={onSubmit}
        errors={errors}
      />
      <div css={listContainer}>
        <section className='section__header'>
          <h3>Extratos encontrados no período</h3>
          <button className='button__delete' onClick={onSubmit(toggleModal)}>
            <TrashIcon className='button__icon' />
            <span className='button__text'>Excluir</span>
          </button>
        </section>

        {
          isMessageSelectPeriod
            ? <p className='message__select_period'>Selecione um período para visualizar os extratos bancários</p>
            : (
              <PaginationContainer
                total={count}
                pagesQuantity={last}
                currentPage={filters.currentPage}
                pageLimitDefault={filters.perPage}
                onPagination={handlePagination}
                pageLimitOptions={perPageOptions}
                isOpenDrawer
              >
                <TableContainer>
                  <BankStatementsTableHeader />
                  <BankStatementsTableBody
                    bankStatements={bankStatementsData}
                    loading={isloading}
                    i18n={i18n}
                  />
                </TableContainer>
              </PaginationContainer>
            )
        }

        <ConfirmDialog
          isVisible={isOpenModal}
          onClose={toggleModal}
          title='Tem certeza que deseja excluir os extratos?'
          subtitle='Após a confirmação, não tem como rever a ação.'
          onSuccess={handleRemoveBankStatements}
        />
      </div>
    </>

  )
}
