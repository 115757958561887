export const SupplierBankAccountsModel = {
  payload: (payload) => {
    const {
      bankName,
      branch,
      accountNumber,
      accountType,
      enabled
    } = payload ?? {}

    return {
      bank_name: bankName,
      branch,
      account_number: accountNumber,
      account_type: accountType,
      enabled: enabled === 'enabled'
    }
  }
}
