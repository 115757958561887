import { useTranslatedPaymentMethods } from 'Shared/language/hooks/useTranslatedPaymentMethods'

export const BankAccountsHooksTranslator = (
  dictionary,
  _locale
) => {
  const errorLoadBankAccountOptions = dictionary?.bank_account_errorLoadBankAccountOptions_toast
  const errorLoadingBankAccountList = dictionary?.bank_account_errorLoadingBankAccountList_toast
  const errorLoadingBankAccount = dictionary?.bank_account_errorLoadingBankAccount_toast
  const errorUpdatingBankAccount = dictionary?.bank_account_errorUpdatingBankAccount_toast
  const errorLoadingBankAccountHistory = dictionary?.bank_account_errorLoadingBankAccountHistory_toast
  const bankAccountHasBeenUpdatedSuccessfully = dictionary?.bank_account_bankAccountHasBeenUpdatedSuccessfully_toast
  const all = dictionary?.common_all_label

  const { paymentMethodMapped } = useTranslatedPaymentMethods({ dictionary })

  return {
    errorLoadBankAccountOptions,
    errorLoadingBankAccountList,
    errorLoadingBankAccount,
    errorUpdatingBankAccount,
    errorLoadingBankAccountHistory,
    bankAccountHasBeenUpdatedSuccessfully,
    all,
    paymentMethodMapped
  }
}
