import { colors } from '@bonitour/components'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const titleHeader = css`
  font-weight: 500;
`

export const cardBox = css`
  align-self: center;
  width: 100%;
  margin-top: 1.5625rem;

  .wrapper__info_action_btn {
    display: flex;
    align-items: center;
    margin-bottom: 0.9375rem;

    .info__forgotten {
      width: 100%;
      color: var(--clr-red3);
      font-size: 1.125rem;
      font-weight: 700;
    }

  }
`

export const marginRight = css`
  margin-right: 20px;
`

export const ExpenseSectionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;

  .wrapper_buttons {
    display: flex;
    gap: 1.25rem;

    .expense_create__btn {
      min-height: 6.25rem;
    }
  }
`

export const customValueCard = css`
  .section__value {
    background-color: ${colors.white};
  }

`

export const customModal = css`
  min-height: 28.125rem;
`

export const customModalRecurrency = css`
> div {
    max-width: 90.625rem;
  }
`

export const expenseTableV2Container = css`
  .TableRow {
    .TableCellWrapper {
      display: flex;
    }

    .TableCell {
      overflow: initial;
      max-width: 100%;
    }

    color: var(--clr-gray3);

    .link__service_name {
      text-decoration: underline;

      .safe_overflow__service_name {
        span {
          text-decoration: underline;
        }
      }
    }

    .highlight__eye {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        width: 0.375rem;
        height: 0.375rem;
        background-color: var(--clr-indigo1);
        border-radius: 100vw;
      }
    }
  }

  .checkbox {
    border: 2px solid var(--clr-gray7);

    :disabled {
      opacity: 0.4;
      background-color: var(--clr-gray10);
      pointer-events: none;
    }
  }

  .empty_result {
    color: var(--clr-gray3);
    font-size: 1.125rem;
  }
`
