import { css } from '@emotion/react'

export const tableV2Container = css`
  .TableRow {
    .TableCell {
      overflow: initial;
    }
    color: var(--clr-gray3);

    .safe_overflow__container {
      max-width: 13rem;
    }

    .safe_overflow__user_name {
      max-width: 8rem;
    }
  }

  .empty_result {
    color: var(--clr-gray3);
    font-size: 1.125rem;
  }
`
