import styled from '@emotion/styled'

export const EventProcessorEntryConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  * {
    box-sizing: border-box;
  }

  .entry_conditions__btns_wrapper {
    display: flex;
    gap: 1.25rem;
  }
`
