/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FormsInputs } from '../../../../Shared/components/Form/__FormInputs'
import { CreateEditEventProcessorContainer } from '../../Form/EventsProcessors.styles'
import { useCreateEditEventProcessorInputs } from '../../hooks/inputs/useCreateEditEventProcessorInputs'
import { FormActions } from 'Shared/components/Form/__FormActions'
import { useTranslator } from 'Shared/language/i18nHandler'
import { EventsProcessorsTranslator } from '../../i18n/EventsProcessorsI18n'

/**
 * @typedef EventProcessorNameAndDescriptionProps
 * @prop { (filters: Record<string, string>) => void } handleSubmit
 * @prop { boolean } isLoading
 * @prop { () => void } onClose
 * @prop { Record<string, string> } eventProcessorData
*/

/** @type { React.FC<EventProcessorNameAndDescriptionProps> } */
export const EventProcessorNameAndDescription = (
  {
    handleSubmit,
    onClose,
    isLoading,
    eventProcessorData
  }
) => {
  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useCreateEditEventProcessorInputs(
    eventProcessorData
  )

  const i18n = useTranslator(EventsProcessorsTranslator)

  return (
    <CreateEditEventProcessorContainer>
      <FormsInputs
        inputs={inputsValid}
        formErrors={errors}
        formValues={form}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      />
      <FormActions
        onSubmit={onSubmit(handleSubmit)}
        onClose={onClose}
        successBtnLabel={i18n?.saveAndContinue}
        disabled={!!isLoading}
      />
    </CreateEditEventProcessorContainer>
  )
}
