import styled from '@emotion/styled'

export const SupplierFilterContainer = styled.div`
  * {
    box-sizing: border-box;
  }

  .form__inputs {
    display: flex;
    align-items: end;
    width: 100%;
    gap: 1.25rem;
    background-color: var(--clr-gray8);
    padding: 1.25rem;
    border-radius: 0.625rem;
    margin-bottom: 1.875rem;

    .input__enabled  {
      max-width: 12rem;
      width: 100%;
    }

    .suppliers_filters__btn {
      display: flex;
      max-width: 12rem;
      align-items: center;
      justify-content: center;
      color: var(--clr-white);
      background-color: var(--clr-indigo1);
      font-weight: 700;
      width: 100%;
      height: 2.5rem;
      padding: 1.25rem;
      border-radius: 1.25rem;
      border: 1px solid var(--clr-indigo1);
    }

  }

`
