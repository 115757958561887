
export const SupplierBankAccountsHooksTranslator = (
  dictionary,
  _locale
) => {
  const errorFecthingSupplierBankAccounts = dictionary?.supplier_bank_account_errorFecthingSupplierBankAccounts_toast
  const createSupplierBankAccount = dictionary?.supplier_bank_account_createSupplierBankAccount_toast
  const errorCreateSupplierBankAccount = dictionary?.supplier_bank_account_errorCreateSupplierBankAccount_toast
  const editSupplierBankAccount = dictionary?.supplier_bank_account_editSupplierBankAccount_toast
  const errorEditSupplierBankAccount = dictionary?.supplier_bank_account_errorEditSupplierBankAccount_toast
  const removeSupplierBankAccount = dictionary?.supplier_bank_account_removeSupplierBankAccount_toast
  const errorRemoveSupplierBankAccount = dictionary?.supplier_bank_account_errorRemoveSupplierBankAccount_toast

  return {
    errorFecthingSupplierBankAccounts,
    createSupplierBankAccount,
    errorCreateSupplierBankAccount,
    editSupplierBankAccount,
    errorEditSupplierBankAccount,
    removeSupplierBankAccount,
    errorRemoveSupplierBankAccount
  }
}
