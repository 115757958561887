/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { CardButton } from './CardButton'
import { useClickOutside } from '@bonitour/app-functions'
import {
  resetLink,
  cardIcon,
  labelCardButton,
  angleDownStyle,
  linkColor,
  cardItems,
  cardItemIcon,
  TitleMenusContainer
} from './TitleMenus.styles'
import {
  Label,
  flexHorizontallyCentered,
  AngleDownIcon,
  Manager,
  Reference,
  Popover,
  Row,
  ScheduleIcon,
  FinancialTransactionIcon,
  CashierIcon,
  BankCardIcon,
  CashbackIcon,
  CogIcon
} from '@bonitour/components'
import { useTranslator } from 'Shared/language/i18nHandler'
import { TitleMenusTranslator } from './i18n/TitleMenusI18n'
import { CustomFeatureEnabler } from '../CustomFeatureEnabler/CustomFeatureEnabler'

export const CardItemRow = ({ to = '', children }) => (
  <NavLink to={to} css={resetLink}>
    <Row css={linkColor}>
      <div css={flexHorizontallyCentered}>{children}</div>
    </Row>
  </NavLink>
)

export const CardItem = ({ title, icon: Icon = null, children, ...other }) => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)
  const toggleVisibility = () => setVisibility(visibility => !visibility)

  return (
    <div ref={reference} {...other}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} onClick={toggleVisibility} className='card_item__content'>
              <CardButton>
                <div css={[flexHorizontallyCentered, cardItemIcon]}>
                  {Icon && <Icon css={cardIcon} />}
                  <AngleDownIcon css={angleDownStyle} />
                </div>
                <Label css={labelCardButton}>{title}</Label>
              </CardButton>
            </div>
          )}
        </Reference>
        {isVisible && (
          <Popover position='bottom' css={cardItems}>
            {children}
          </Popover>
        )}
      </Manager>
    </div>
  )
}

export const TitleMenus = () => {
  const i18n = useTranslator(TitleMenusTranslator)

  return (
    <TitleMenusContainer>
      <CardItem title='Minhas agendas' icon={ScheduleIcon}>
        <CardItemRow to='/financial-schedule'>Agenda Financeira Cartões</CardItemRow>
        <CustomFeatureEnabler featureKey='billing-full-financial-schedule'>
          <CardItemRow to='/full-financial-schedule'>Agenda Financeira Completa</CardItemRow>
        </CustomFeatureEnabler>
      </CardItem>

      <CustomFeatureEnabler featureKey='billing-events'>
        <NavLink to='/events' css={resetLink}>
          <CardButton>
            <CashierIcon css={cardIcon} />
            <Label css={labelCardButton}>{i18n?.menuTitleEvents}</Label>
          </CardButton>
        </NavLink>
      </CustomFeatureEnabler>

      <CustomFeatureEnabler featureKey='billing-events-processors'>
        <NavLink to='/events-processors' css={resetLink}>
          <CardButton>
            <CogIcon css={cardIcon} />
            <Label css={labelCardButton}>{i18n?.menuTitleEventsProcessors}</Label>
          </CardButton>
        </NavLink>
      </CustomFeatureEnabler>

      <NavLink to='/accounting-function' css={resetLink}>
        <CardButton>
          <BankCardIcon css={cardIcon} />
          <Label css={labelCardButton}>{i18n?.titleAccountingsFunctions}</Label>
        </CardButton>
      </NavLink>

      <NavLink to='/credit-card' css={resetLink}>
        <CardButton>
          <BankCardIcon css={cardIcon} />
          <Label css={labelCardButton}>{i18n?.menuTitleCards}</Label>
        </CardButton>
      </NavLink>

      <NavLink to='/bank-action' css={resetLink}>
        <CardButton>
          <CashbackIcon css={cardIcon} />
          <Label css={labelCardButton}>{i18n?.menuTitleBankActions}</Label>
        </CardButton>
      </NavLink>

      <NavLink to='/expense-revenue-dashboard' css={resetLink}>
        <CardButton>
          <FinancialTransactionIcon css={cardIcon} />
          <Label css={labelCardButton}>Contas a Pagar e a Receber</Label>
        </CardButton>
      </NavLink>

      <NavLink to='/configuration' css={resetLink}>
        <CardButton>
          <CogIcon css={cardIcon} />
          <Label css={labelCardButton}>{i18n?.menuTitleConfigurations}</Label>
        </CardButton>
      </NavLink>
    </TitleMenusContainer>
  )
}
