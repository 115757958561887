import { css } from '@emotion/core'
import { iconButton } from 'Shared/styles/global'

export const wrapperButtons = css`
  display: flex;
  gap: 0.625rem;

  .b-font-edit-info, .b-font-trash {
    ${iconButton}
  }
`
