import { useState, useCallback, useEffect } from 'react'
import { RevenueService } from 'Core/Service/revenue'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { RevenuesHooksTranslator } from '../i18n/RevenuesHooksI18n'

export const useRevenuesForgotten = () => {
  const { addToast } = useToast()
  const [data, setData] = useState(
    {
      forgotten: 0,
      daysAgo: 0
    }
  )

  const i18n = useTranslator(RevenuesHooksTranslator)

  const fetchRevenuesForgotten = useCallback(() => {
    return RevenueService.getRevenuesForgotten()
      .then(setData)
      .catch((errors) => {
        console.error(errors?.data)
        addToast({
          msgs: errors?.data?.errors_msg,
          defaultMessage: i18n?.errorLoadingForgottenReceivables
        })
      })
  }, [addToast, i18n?.errorLoadingForgottenReceivables])

  useEffect(() => {
    fetchRevenuesForgotten()
  }, [fetchRevenuesForgotten])

  return {
    data,
    setData
  }
}
