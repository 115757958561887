import React, { useCallback, useMemo, useState } from 'react'
import { BankTransfersList } from './BankTransfersList/BankTransfersList'
import { PageTitle } from 'Shared/components/PageTitle/PageTitle'
import { useHistory } from 'react-router-dom'
import { useFlaggedRoute } from 'Shared/hooks/useFlaggedRoute'
import { useGetBankTransfers } from './hooks/useGetBankTransfers'
import { Card, Label, PaginationBar, PlusIcon } from '@bonitour/components'
import { BankTransfersFilters } from './Form/BankTransfersFilters'
import { useTranslator } from 'Shared/language/i18nHandler'
import { BankTransfersTranslator } from './i18n/BankTransfersI18n'
import { cardIcon, wrapperFiltersList } from 'Shared/styles/global'
import { CardButton } from 'Shared/components/CardButton'
import { BankTransferModal } from './Modal/BankTransferModal'
import { useCreateBankTransfer } from './hooks/useCreateBankTransfer'
import { useGetBankTransfer } from './hooks/useGetBankTransfer'
import { useUpdateBankTransfer } from './hooks/useUpdateBankTransfer'

export const BankTransfers = (): React.Component => {
  useFlaggedRoute('billing-bank-transfers')

  const { push } = useHistory()

  const onReturnCreditCardDashboard = useCallback(() => {
    push('/bank-action')
  }, [push])

  const {
    filters,
    handleFiltersChange,
    handleOnPageChange,
    handleOnPerPageChange,
    pagination,
    bankTransfers,
    loading,
    newPerPageOptions,
    fetchBankTransfers
  } = useGetBankTransfers()

  const i18n = useTranslator(BankTransfersTranslator)

  const [isVisible, setIsVisible] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const toggleModal = useCallback(() => {
    setIsVisible(visible => !visible)
  }, [])

  const {
    createBankTransfer,
    loadingCreateBankTransfer
  } = useCreateBankTransfer()

  const {
    getBankTransfer,
    getBankTransferData,
    loadingBankTransfer,
    setGetBankTransferData
  } = useGetBankTransfer()

  const {
    updateBankTransfer,
    loadingUpdateBankTransfer
  } = useUpdateBankTransfer()

  const showModalAndGetBankTransferData = useCallback((bankTransferId: string) => {
    toggleModal()
    getBankTransfer(bankTransferId)
    setIsEdit(true)
  }, [getBankTransfer, toggleModal])

  const isLoading = useMemo(
    () => loadingUpdateBankTransfer || loadingBankTransfer || loadingCreateBankTransfer
    , [loadingBankTransfer, loadingCreateBankTransfer, loadingUpdateBankTransfer]
  )

  const showModalAndCreateBankTransfer = useCallback(() => {
    toggleModal()
    setGetBankTransferData(null)
    setIsEdit(false)
  }, [setGetBankTransferData, toggleModal])

  return (
    <>
      <PageTitle
        title={i18n?.transferBetweenBankAccount}
        onClickReturn={onReturnCreditCardDashboard}
      />
      <CardButton onClick={showModalAndCreateBankTransfer}>
        <PlusIcon css={cardIcon} />
        <Label>{i18n?.newTransfer}</Label>
      </CardButton>
      <Card customCss={[wrapperFiltersList]}>
        <BankTransfersFilters
          handleFiltersChange={handleFiltersChange}
          filters={filters}
          isLoading={loading}
          buttonLabel={i18n?.searchButton}
        />
        <BankTransfersList
          bankTransfers={bankTransfers}
          isLoading={loading}
          filters={filters}
          showModalAndGetBankTransferData={showModalAndGetBankTransferData}
        />
        <PaginationBar
          currentPage={pagination.page}
          perPage={pagination.perPage}
          totalPages={pagination.totalPages}
          totalElements={pagination.totalItems}
          onPageChange={handleOnPageChange}
          onPerPageChange={handleOnPerPageChange}
          perPageOptions={newPerPageOptions}
        />
      </Card>

      <BankTransferModal
        isVisible={isVisible}
        toggleHidden={toggleModal}
        isLoading={isLoading}
        createBankTransfer={createBankTransfer}
        updateBankTransfer={updateBankTransfer}
        bankTransferData={getBankTransferData}
        isEdit={isEdit}
        i18n={i18n}
        fetchBankTransfers={fetchBankTransfers}
      />
    </>
  )
}
