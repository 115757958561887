import React from 'react'
import { FormsInputs } from 'Shared/components/Form/__FormInputs'
import { Button } from '@bonitour/components'
import { ExpenseRevenuesFiltersContainer } from './ExpensesRevenuesFilters.styles'
import { useExpensesRevenuesFilters } from '../hooks/inputs/useExpensesRevenuesFilters'
import { ExpensesCommonParams } from 'Core/Types/Expense'

interface ExpenseRevenueFiltersProps {
  isLoading: boolean
  handleFiltersChange: (filters: ExpensesCommonParams) => void
  filters: ExpensesCommonParams
  buttonLabel: string
  expenseRevenuesOptions: { label: string, value: string }[]
  suppliersOptions: { label: string, value: string }[]
  statusOptions: { label: string, value: string }[]
  bankAccountsOptions?: { label: string, value: string }[]
  typeExpenseOrRevenueLabel: string
  isExpense: boolean
}

export const ExpensesRevenuesFilters = ({
  isLoading,
  handleFiltersChange,
  filters,
  expenseRevenuesOptions,
  suppliersOptions,
  statusOptions,
  typeExpenseOrRevenueLabel,
  bankAccountsOptions,
  buttonLabel,
  isExpense
}: ExpenseRevenueFiltersProps): React.ReactElement => {
  const {
    errors,
    form,
    onInputChange,
    onInputBlur,
    inputsValid,
    onSubmit
  } = useExpensesRevenuesFilters(
    {
      filters,
      expenseRevenuesOptions,
      suppliersOptions,
      statusOptions,
      typeExpenseOrRevenueLabel,
      isExpense,
      bankAccountsOptions
    }
  )

  return (
    <ExpenseRevenuesFiltersContainer>
      <FormsInputs
        inputs={inputsValid}
        formValues={form}
        formErrors={errors}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
      >
        <Button
          type='button'
          disabled={!!isLoading}
          onClick={onSubmit(handleFiltersChange)}
        >
          {buttonLabel}
        </Button>
      </FormsInputs>

    </ExpenseRevenuesFiltersContainer>
  )
}
