import { useCallback, useEffect, useState } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConfigurationsService } from 'Core/Service/configurations'
import { ConfigurationParsed } from 'Core/Types/Configuration'
import { UserStore } from 'Core/Store/User'
import { ConfigurationsHooksTranslator } from '../i18n/ConfigurationHooksI18n'

export const useGetConfiguration = () => {
  const [getConfigurationData, setConfigurationData] = useState<ConfigurationParsed>()
  const { addToast } = useToast()

  const i18n = useTranslator(ConfigurationsHooksTranslator)

  const getConfiguration = useCallback(async () => {
    const configurationId = UserStore?.user?.company?.id
    return await ConfigurationsService.getConfiguration(configurationId).then(
      setConfigurationData
    ).catch(errors => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.errorWhenSearchingForConfigurations
      })
    }).finally(() => {})
  }, [addToast, i18n])

  useEffect(() => {
    getConfiguration()
  }, [getConfiguration])

  return {
    getConfigurationData,
    getConfiguration
  }
}
