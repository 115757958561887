import { useMemo } from 'react'
import { useTranslatedInputs } from 'Shared/language/hooks/inputs/useTranslatedInputs'
import { accountNumber, accountType, bankName, branch, enabled, getSchema } from '../../Form/__SupplierBankAccountsBaseInputs'
import { SupplierBankAccountsInputsTranslator } from '../../i18n/SupplierBankAccountsInputsI18n'
import { useForm } from '@bonitour/app-functions'
const BASE_FORM_VAL = {}

export const useSupplierBankAccountInputs = (
  accountTypeOptions,
  enabledOptions,
  supplierBankAccountData
) => {
  const inputsVals = useTranslatedInputs([
    bankName,
    branch,
    accountNumber,
    accountType(accountTypeOptions),
    enabled(enabledOptions)
  ], SupplierBankAccountsInputsTranslator)

  const schema = useMemo(
    () => getSchema(inputsVals),
    [inputsVals]
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(supplierBankAccountData || BASE_FORM_VAL, schema)

  return {
    inputsValid: inputsVals,
    form,
    errors,
    onSubmit,
    onInputChange,
    onInputBlur
  }
}
