import { useState, useCallback } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { useUser } from 'Shared/contexts/User'
import { BankConciliationService } from 'Core/Service/bankConciliation'
import { ConciliationsHooksTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsHooksI18n'

export const useCreateAutoBankConciliation = (fetchBankConciliations) => {
  const { addToast } = useToast()
  const [isLoadingAutoBankConciliation, setIsLoadingAutoBankConciliation] = useState(false)

  const { user } = useUser()

  const i18n = useTranslator(ConciliationsHooksTranslator)

  const createAutoBankConciliation = useCallback((params) => {
    setIsLoadingAutoBankConciliation(true)
    return BankConciliationService.postAutoBankConciliation(params, user).then(
      (conciliations) => {
        addToast({
          defaultMessage: i18n?.automaticConciliation(conciliations),
          typeToast: 'success'
        })
        fetchBankConciliations()
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.conciliationCreateAutomicError
      })
    }).finally(() => {
      setIsLoadingAutoBankConciliation(false)
    })
  }, [addToast, fetchBankConciliations, i18n, user])

  return {
    isLoadingAutoBankConciliation,
    createAutoBankConciliation
  }
}
