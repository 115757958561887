import styled from '@emotion/styled'
import { BREAK_POINTS } from 'Shared/styles/mediaQueries'

export const MovementsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.875rem;
  background-color: var(--clr-white);
  border-radius: 0.625rem;
  margin-bottom: 1.875rem;

  * {
    box-sizing: border-box;
  }

  @media(max-width: ${BREAK_POINTS.tablet}) {
    flex-direction: column;
  }
`
