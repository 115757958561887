import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { motion } from 'framer-motion'

import { depth } from 'Shared/styles/utils/depthBuilder'

export const displayTooltipOnHover = (
  tooltipClass
) => {
  return css`
    &:hover,
    &:focus-within,
    &:active {
      .${tooltipClass} {
        opacity: 1;
        pointer-events: inherit;
        --translateYVar: 0px;
      }
    }
  `
}

const filterProps = {
  shouldForwardProp (prop) {
    return ![
      'isActive',
      'verticalPosition',
      'horizontalPosition',
      'width'
    ].includes(prop)
  }
}

export const TooltipContainer = styled(
  motion.div,
  filterProps
)`
  position: absolute;
  z-index: 2;
  opacity: 0;
  transition: all 300ms ease-in-out;
  pointer-events: none;

  ${props => {
    const { horizontalPosition, verticalPosition } = props
    const isAbove = verticalPosition === 'top'
    const isCenteredHorizontally = horizontalPosition === 'center'
    const isCenteredVertically = verticalPosition === 'center'
    return css`
      ${horizontalPosition === 'left' &&
      `right: ${isCenteredVertically ? '100%' : '-1rem'};`}
      ${horizontalPosition === 'right' &&
      `left: ${isCenteredVertically ? '100%' : '-1rem'};`}
      ${horizontalPosition === 'center' && 'left: 50%;'}


      --translateXVar: ${isCenteredHorizontally ? '-50%' : '0px'};
      --translateYVar: ${isCenteredVertically
    ? '-50%'
    : isAbove
      ? '-5px'
      : '5px'};

      transform: translateY(var(--translateYVar))
        translateX(var(--translateXVar));

      padding: ${isCenteredVertically
    ? '2px 0.75rem'
    : isAbove
      ? '2px 2px 1rem 2px'
      : '1rem 2px 2px 2px'};
      ${!isCenteredVertically
    ? `${isAbove ? 'bottom' : 'top'}: 100%;`
    : 'top: 50%;'}

      ${!props.isActive && 'display: none'};
    `
  }}
`

export const TooltipContent = styled.div`
  ${depth(8, 'normal')}

  position: relative;
  background: var(--clr-white);
  padding: 1.25rem;
  border-radius: 0.675rem;
  z-index: 3;

  min-width: ${({ width }) => width || 'auto'};

  &::before {
    content: '';
    position: absolute;
    background: var(--clr-white);
    border-radius: 0.25rem 0px 0.5rem 0px;
    width: 1rem;
    height: 1rem;
    z-index: 8;
    box-shadow: -2px -2px 4px -2px rgba(0, 0, 0, 0.12),
      0 -3px 3px -3px rgba(0, 0, 0, 0.08);

    ${props => {
    const { horizontalPosition, verticalPosition } = props
    const isAbove = verticalPosition === 'top'
    const isLeft = horizontalPosition === 'left'
    const isCenteredHorizontally = horizontalPosition === 'center'
    const isCenteredVertically = verticalPosition === 'center'

    return !isCenteredVertically
      ? css`
            ${isAbove ? 'bottom' : 'top'}: -0.45rem;
            ${horizontalPosition === 'left' && 'right: 1rem;'}
            ${horizontalPosition === 'right' && 'left: 1rem;'}
        ${horizontalPosition === 'center' && 'left: 50%;'}

        transform-origin: center;
            transform: translateX(${isCenteredHorizontally ? '-50%' : '0px'})
              rotate(${isAbove ? '225' : '45'}deg);
          `
      : css`
            ${isCenteredHorizontally && 'content: none;'}
            top: 50%;
            ${horizontalPosition === 'left' && 'right: -0.45rem;'}
            ${horizontalPosition === 'right' && 'left: -0.45rem;'}

            transform-origin: center;
            transform: translateY(-50%) rotate(${isLeft ? '135' : '-45'}deg);
          `
  }}
  }
`
