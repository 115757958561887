import { useState, useCallback } from 'react'
import { useToast } from 'Shared/hooks/useToast'
import { useTranslator } from 'Shared/language/i18nHandler'
import { ConciliationsHooksTranslator } from 'Shared/components/Conciliations/i18n/ConciliationsHooksI18n'
import { CreditCardConciliationService } from 'Core/Service/creditCardConciliation'

export const useDeleteCreditCardConciliation = (fetchCreditCardConciliations) => {
  const { addToast } = useToast()
  const [isLoadingDeleteCreditCardConciliations, setIsLoadingDeleteCreditCardConciliations] = useState(false)

  const i18n = useTranslator(ConciliationsHooksTranslator)

  const deleteCreditCardConciliations = useCallback((params) => {
    setIsLoadingDeleteCreditCardConciliations(true)
    return CreditCardConciliationService.deleteCreditCardConciliations(params).then(
      () => {
        addToast({
          defaultMessage: i18n?.deletedConciliation(params?.conciliationsIds?.length),
          typeToast: 'success'
        })
        fetchCreditCardConciliations()
        return true
      }
    ).catch((errors) => {
      console.error(errors?.data)
      addToast({
        msgs: errors?.data?.errors_msg,
        defaultMessage: i18n?.deleteConciliationsError
      })
      return false
    }).finally(() => {
      setIsLoadingDeleteCreditCardConciliations(false)
    })
  }, [addToast, fetchCreditCardConciliations, i18n])

  return {
    isLoadingDeleteCreditCardConciliations,
    deleteCreditCardConciliations
  }
}
